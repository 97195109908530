import { PagesPath } from "../PagesPath";
// import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
// import settings_active from '@mui/icons-material/Settings';
import school from "../assets/school.svg";
import school_orange from "../assets/school_orange.svg";

export const EducationConfigurationJson = {
  id: 3,
  name: "Education Configuration",
  key: "EducationConfiguration",
  iconurl: school,
  activeiconurl: school_orange,
  defaulturl: PagesPath.EducationConfiguration,
};
