import { axiosRequests } from "../AxiosRequest";

function getProgramsList() {
  
  return axiosRequests.get("/EducationProgram/GetEducationProgramDetails");
  // return axiosRequests.get("/Members");
}

export const ProgramDataAgent = {
    getProgramsList: getProgramsList,
};