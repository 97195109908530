import React, { useState, useEffect } from "react";
import {
    Typography,
    Box,
    Chip,
    Stack,
    CardContent,
    Card,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Checkbox,
    ListItemText,
    FormHelperText,
    Button,
    Grid,
    FormControlLabel,
    Radio,
    RadioGroup,
    FormLabel,
} from "@mui/material";
import { COLOR, Styles } from "../../Styles";
import RecertificationPageHeader from "./RecertificationPageHeader";
import { checkUserScorecardAccess } from "../../component/common/userAuthorization/checkUserAccess";
import NoAccessPage from "../../component/common/noAccess/NoAccessPage";
import { axiosRequests } from "../../app/api/AxiosRequest";
import PowerBiCustomDashboard from "../../component/common/powerbi/PowerBiCustomDashboard";
import DataGridCustom from "../../component/common/datagrid/DataGridCustom";
import CustomPaginationSlot from "../../component/common/datagrid/CustomPaginationSlot";
import Loader from "../../component/common/loader/Loader";
const RecertificationReportPage = () => {
    const [scorecardObj, setScorecardObj] = useState([]);
    const [dashboardId, setDashboardId] = useState("");
    const [groupId, setGroupId] = useState("");
    const [scoreCardFileName, setScoreCardFileName] = useState("");
    const [scoreCardId, setScoreCardId] = useState("");
    const [scoreCardType, setScoreCardType] = useState("");
    const [scoreCardURL, setScoreCardURL] = useState("");
    const RecertificationID = 9;
    const [accessObj] = useState(checkUserScorecardAccess(RecertificationID));
    const [reportListData, setReportListData] = useState([]);
    const [sortModel, setSortModel] = useState([{ field: 'description', sort: 'asc' }])
    const [pageCount, setPageCount] = useState(0);
    const [isListLoading, setIsListLoading] = useState(false);
    const[isGridDisplay, setIsGridDisplay]= useState(false);
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [count, setCount] = useState("0");
    const [payerDropdownData, setPayerDropdownData] = useState([]);
    const [selectedPayerName, setSelectedPayerName] = useState([]);
    const isAllPayersSelected =
        payerDropdownData.length > 0 &&
        selectedPayerName.length === payerDropdownData.length;

    const [productDropdownData, setProductDropdownData] = useState([]);
    const [selectedProductName, setSelectedProductName] = useState([]);
    const isAllProductsSelected =
        productDropdownData.length > 0 &&
        selectedProductName.length === productDropdownData.length;

    const [marketNameDropdownData, setMarketNameDropdownData] = useState([]);
    const [selectedMarketName, setSelectedMarketName] = useState([]);
    const isAllMarketsSelected =
        marketNameDropdownData.length > 0 &&
        selectedMarketName.length === marketNameDropdownData.length;

    const [regionNameDropdownData, setRegionNameDropdownData] = useState([]);
    const [selectedRegionName, setSelectedRegionName] = useState([]);
    const isAllRegionsSelected =
        regionNameDropdownData.length > 0 &&
        selectedRegionName.length === regionNameDropdownData.length;
    const [isLoadingRegionName, setIsLoadingRegionName] = useState(true);

    const [practiceNameDropdownData, setPracticeNameDropdownData] = useState([]);
    const [selectedPracticeName, setSelectedPracticeName] = useState([]);
    const isAllPracticeSelected =
        practiceNameDropdownData.length > 0 &&
        selectedPracticeName.length === practiceNameDropdownData.length;
    const [isLoadingPracticeName, setIsLoadingPracticeName] = useState(true);
    
    const columns = [
        {
            field: "description",
            headerName: "Description",
            flex: 2,
        },

        {
            field: "jan",
            headerName: "2024-01",
            flex: 1,
        },
        {
            field: "feb",
            headerName: "2024-02",
            flex: 1,
        },
        {
            field: "mar",
            headerName: "2024-03",
            flex: 1,
        },
        {
            field: "apr",
            headerName: "2024-04",
            flex: 1,
        },
        {
            field: "may",
            headerName: "2024-05",
            flex: 1,
        },
        {
            field: "jun",
            headerName: "2024-06",
            flex: 1,
        }
    ];

    // const reportList = [
    //     {
    //         description: "Member Months",
    //         jan: "160424",
    //         feb: "165186",
    //         mar: "180312",
    //         apr: "170862",
    //         may: "165148",
    //         jun: "161368"
    //     },

    //     {
    //         description: "Active Recertification",
    //         jan: "6564",
    //         feb: "5842",
    //         mar: "7475",
    //         apr: "6727",
    //         may: "5617",
    //         jun: "2912"
    //     },

    //     {
    //         description: "% Active Recert",
    //         jan: "4.1%",
    //         feb: "3.5%",
    //         mar: "4.1%",
    //         apr: "3.9%",
    //         may: "3.4%",
    //         jun: "1.8%"
    //     },

    //     {
    //         description: "Recertified",
    //         jan: "713",
    //         feb: "1239",
    //         mar: "1852",
    //         apr: "1830",
    //         may: "1873",
    //         jun: "2115"
    //     },

    //     {
    //         description: "% Recertified",
    //         jan: "26.3%",
    //         feb: "18.9%",
    //         mar: "31.7%",
    //         apr: "24.5%",
    //         may: "27.8%",
    //         jun: "37.7%"
    //     },


    //     {
    //         description: "Target",
    //         jan: "95.0%",
    //         feb: "95.0%",
    //         mar: "95.0%",
    //         apr: "95.0%",
    //         may: "95.0%",
    //         jun: "95.0%"
    //     },


    //     {
    //         description: "Variance to Target",
    //         jan: "68.7%",
    //         feb: "76.1%",
    //         mar: "63.3%",
    //         apr: "63.3%",
    //         may: "63.3%",
    //         jun: "63.3%"
    //     }
    // ]

    useEffect(() => {
        if (scorecardObj && scorecardObj.length > 0) {
            scorecardObj.map((dObj) => {
                setDashboardId(dObj.dashboardId);
                setGroupId(dObj.groupId);
                setScoreCardFileName(dObj.scoreCardFileName);
                setScoreCardId(dObj.scoreCardId);
                setScoreCardType(dObj.scoreCardName);
                setScoreCardURL(dObj.scoreCardURL);
            })
        }
    }, [scorecardObj]);

    // useEffect(() => {
    //     axiosRequests
    //         .get(
    //             `/UserAccess/GetScorecardDetails`
    //         )
    //         .then((resp) => {
    //             if (resp || resp.length > 0) {
    //                 let obj = [];
    //                 resp.map((scorecard) => {
    //                     if (scorecard.scoreCardId === RecertificationID) {
    //                         obj.push(scorecard)
    //                     }
    //                 });
    //                 setScorecardObj(obj);
    //             }
    //         });
    // }, []);

    useEffect(() => {
        const loadData = async () => {
            var selectedPayerIDs,
                selectedProductIDs,
                selectedMarketIDs,
                selectedRegionIDs,
                selectedPracticeIDs;

            await axiosRequests
                .get("/Recertification/GetPayersList")
                .then((resp) => {
                    setCount("1");
                    setPayerDropdownData(resp);
                    selectedPayerIDs = resp.map((item) => item.payerId);
                    setSelectedPayerName(selectedPayerIDs);
                });

            await axiosRequests
                .get(`/Recertification/GetProductList?PayerIDs=${selectedPayerIDs.join(
                    ","
                )}`
                )
                .then((resp) => {
                    setCount("2");
                    setProductDropdownData(resp);
                    selectedProductIDs = resp.map((item) => item.productId);
                    setSelectedProductName(selectedProductIDs);
                });

            await axiosRequests
                .get(`/Recertification/GetMarketList?PayerIDs=${selectedPayerIDs.join(
                    ","
                )}&ProductIDs=${selectedProductIDs.join(",")}`
                )
                .then((resp) => {
                    setCount("3");
                    setMarketNameDropdownData(resp);
                    selectedMarketIDs = resp.map((item) => item.marketId);
                    setSelectedMarketName(selectedMarketIDs);
                });

            await axiosRequests
                .get(
                    `/Recertification/GetSubMarketList?MarketIDs=${selectedMarketIDs.join(
                        ","
                    )}`
                )
                .then((resp) => {
                    setCount("4");
                    selectedRegionIDs = resp.map((item) => item.id);
                    setSelectedRegionName(selectedRegionIDs);
                    setIsLoadingRegionName(false);
                    setRegionNameDropdownData(resp);
                });

            await axiosRequests
                .get(
                    `/Recertification/GetPracticeList?MarketIDs=${selectedMarketIDs.join(
                        ","
                    )}&SubMarketIDs=${selectedRegionIDs.join(",")}`
                )
                .then((resp) => {
                    setCount("5");
                    selectedPracticeIDs = resp.map((item) => item.practiceId);
                    setPracticeNameDropdownData(resp);
                    setIsLoadingPracticeName(false);
                    setSelectedPracticeName(selectedPracticeIDs);
                });
        };
        loadData();
    }, []);

    useEffect(() => {
        const formData = {
            Year: "2024",
            Payer: selectedPayerName.join(","),
            Product: selectedProductName.join(","),
            Market: selectedMarketName.join(","),
            subMarket: selectedRegionName.join(","),
            practice: selectedPracticeName.join(",")
        };
        setIsListLoading(true);
        if (formData &&
            formData.Payer != '' &&
            formData.Product != '' &&
            formData.Market != '' &&
            formData.subMarket != '' &&
            formData.practice != '' && count == "5"
        ) {
            
            axiosRequests
                .post("/Recertification/GetRecertificationReportDataList", formData)
                .then((resp) => {
                    let obj = [];
                    if (resp[0] && resp[0]?.description != undefined) {
                        obj.push(resp[0]);
                        setReportListData(obj);
                        setIsGridDisplay(true);
                        let rowCount = 1;//sdohMemberList?.count;
                        let numberOfPages = Math.ceil((rowCount / 10));
                        setTotalRows(rowCount);
                        setPageCount(numberOfPages);
                        setIsListLoading(false);
                    }
                });
        }
        else{
            setReportListData([]);
        }
    }, [count])

    function getRowId(row) {
        return row.description + "_" + row.jan + "_" + row.feb + "_" + row.mar + "_" + row.apr + "_" + row.may;//ourId + "_" + row.practiceName + "_" + "_" + row.name + "_" + row.submittedDate; //+ "_" + row.ourID; //+ "_" + row.profileId;
    }

    const getRegionList = (marketNames) => {
        axiosRequests
            .get(
                `/Recertification/GetSubMarketList?marketIDs=${marketNames.join(
                    ","
                )}`
            )
            .then((resp) => {
                setRegionNameDropdownData(resp);
                setIsLoadingRegionName(false);
                var IDs = resp.map((item) => item.id);
                var selected = selectedRegionName.filter((item) => IDs.includes(item));
                setSelectedRegionName(selected);
                if (selected && selected.length > 0) {
                    getPracticeList(selectedMarketName, selected);
                } else {
                    setPracticeNameDropdownData([]);
                    setIsLoadingPracticeName(true);
                    setSelectedPracticeName([]);
                }
            });
    };

    const getProductList = (payerNames) => {
        axiosRequests
            .get(
                `/Recertification/GetProductList?payerIDs=${payerNames.join(
                    ","
                )}`
            )
            .then((resp) => {
                setProductDropdownData(resp);
                var IDs = resp.map((item) => item.id);
                var selected = selectedProductName.filter((item) => IDs.includes(item));
                setSelectedProductName(selected);
                if (selected && selected.length > 0) {
                    getMarketList(selectedPayerName, selected);
                } else {
                    setPracticeNameDropdownData([]);
                    setIsLoadingPracticeName(true);
                    setSelectedPracticeName([]);

                    setSelectedMarketName([]);
                    setSelectedRegionName([]);
                    setMarketNameDropdownData([]);
                    setSelectedPracticeName([]);
                    setRegionNameDropdownData([]);
                    setIsLoadingRegionName(true);
                    setIsLoadingPracticeName(true);
                }
            });
    };

    const getPracticeList = (marketNames, regionNames) => {
        axiosRequests
            .get(
                `/Recertification/GetPracticeList?marketIDs=${marketNames.join(
                    ","
                )}&subMarketIDs=${regionNames.join(",")}`
            )
            .then((resp) => {
                setPracticeNameDropdownData(resp);
                setIsLoadingPracticeName(false);
                var IDs = resp.map((item) => item.practiceId);
                var selected = selectedPracticeName.filter((item) =>
                    IDs.includes(item)
                );
                setSelectedPracticeName(selected);
            });
    };

    const getMarketList = (payerNames, productNames) => {
        axiosRequests
            .get(
                `/Recertification/GetMarketList?payerIDs=${payerNames.join(
                    ","
                )}&productIDs=${productNames.join(",")}`
            )
            .then((resp) => {
                setMarketNameDropdownData(resp);
                var IDs = resp.map((item) => item.marketId);
                var selected = selectedMarketName.filter((item) =>
                    IDs.includes(item)
                );
                setSelectedMarketName(selected);
                if (selected && selected.length > 0) {
                    getRegionList(selected);
                }

                else {
                    setPracticeNameDropdownData([]);
                    setIsLoadingPracticeName(true);
                    setSelectedPracticeName([]);

                    setSelectedRegionName([]);
                    setSelectedPracticeName([]);
                    setRegionNameDropdownData([]);
                    setIsLoadingRegionName(true);
                    setIsLoadingPracticeName(true);
                }

            });
    };

    const handleClearAllSections = ()=>{
        setSelectedPayerName([]);
        setSelectedProductName([]);
        setSelectedMarketName([]);
        setSelectedRegionName([]);
        setSelectedPracticeName([]);

        setProductDropdownData([]);
        setMarketNameDropdownData([]);
        setRegionNameDropdownData([]);
        setPracticeNameDropdownData([]);
        setReportListData([]);
        setIsGridDisplay(false);
    }

    const handlePayerNameChange = (e) => {
        var value = e.target.value;
        if (value[value.length - 1] === "all") {
            var payerIDs = payerDropdownData.map((item) => item.payerId);
            var selectedValues =
                selectedPayerName.length === payerDropdownData.length
                    ? []
                    : payerDropdownData.map((item) => item.payerId);
            setSelectedPayerName(selectedValues);
            if (selectedValues && selectedValues.length > 0) {
                getProductList(selectedValues);
            } else {
                setSelectedProductName([]);
                setSelectedMarketName([]);
                setSelectedRegionName([]);
                setProductDropdownData([]);
                setMarketNameDropdownData([]);
                setSelectedPracticeName([]);
                setRegionNameDropdownData([]);
                setIsLoadingRegionName(true);
                setIsLoadingPracticeName(true);
            }
            return;
        }
        setSelectedPayerName(typeof value === "string" ? value.split(",") : value);
        if (!value || value.length === 0) {
            setSelectedProductName([]);
            setSelectedMarketName([]);
            setSelectedRegionName([]);
            setProductDropdownData([]);
            setMarketNameDropdownData([]);
            setSelectedPracticeName([]);
            setRegionNameDropdownData([]);
            setIsLoadingRegionName(true);
            setIsLoadingPracticeName(true);
        } else getProductList(value);
    };

    const handleProductChange = (e) => {
        var value = e.target.value;
        if (value[value.length - 1] === "all") {
            var productIDs = productDropdownData.map((item) => item.productId);
            var selectedValues =
                selectedProductName.length === productDropdownData.length
                    ? []
                    : productDropdownData.map((item) => item.productId);
            setSelectedProductName(selectedValues);
            if (selectedValues && selectedValues.length > 0) {
                getMarketList(selectedPayerName, selectedValues);
            } else {
                setSelectedMarketName([]);
                setSelectedRegionName([]);
                setMarketNameDropdownData([]);
                setSelectedPracticeName([]);
                setRegionNameDropdownData([]);
                setIsLoadingRegionName(true);
                setIsLoadingPracticeName(true);
            }
            return;
        }
        setSelectedProductName(typeof value === "string" ? value.split(",") : value);
        if (!value || value.length === 0) {
            setSelectedMarketName([]);
            setSelectedRegionName([]);
            setMarketNameDropdownData([]);
            setSelectedPracticeName([]);
            setRegionNameDropdownData([]);
            setIsLoadingRegionName(true);
            setIsLoadingPracticeName(true);
        } else getMarketList(selectedPayerName, value);
    };

    const handleMarketNameChange = (e) => {
        var value = e.target.value;
        if (value[value.length - 1] === "all") {
            var marketIDs = marketNameDropdownData.map((item) => item.marketId);
            var selectedValues =
                selectedMarketName.length === marketNameDropdownData.length
                    ? []
                    : marketNameDropdownData.map((item) => item.marketId);
            setSelectedMarketName(selectedValues);
            if (selectedValues && selectedValues.length > 0) {
                getRegionList(selectedValues);
            } else {
                setSelectedRegionName([]);
                setSelectedPracticeName([]);
                setRegionNameDropdownData([]);
                setPracticeNameDropdownData([]);
                setIsLoadingRegionName(true);
                setIsLoadingPracticeName(true);
            }
            return;
        }
        setSelectedMarketName(typeof value === "string" ? value.split(",") : value);
        if (!value || value.length === 0) {
            setSelectedRegionName([]);
            setSelectedPracticeName([]);
            setRegionNameDropdownData([]);
            setPracticeNameDropdownData([]);
            setIsLoadingRegionName(true);
            setIsLoadingPracticeName(true);
        } else getRegionList(value);
    };

    const handleRegionNameChange = (e) => {
        var value = e.target.value;
        if (value[value.length - 1] === "all") {
            var regionIDs = regionNameDropdownData.map((item) => item.id);
            var selectedValues =
                selectedRegionName.length === regionNameDropdownData.length
                    ? []
                    : regionNameDropdownData.map((item) => item.id);
            setSelectedRegionName(selectedValues);
            if (selectedValues && selectedValues.length > 0) {
                getPracticeList(selectedMarketName, selectedValues);
            } else {
                setSelectedPracticeName([]);
                setIsLoadingPracticeName(true);
            }
            return;
        }
        setSelectedRegionName(typeof value === "string" ? value.split(",") : value);
        if (!value || value.length === 0) {
            setSelectedPracticeName([]);
            setIsLoadingPracticeName(true);
        } else getPracticeList(selectedMarketName, value);
    };

    const handlePracticeNameChange = (e) => {
        var value = e.target.value;
        if (value[value.length - 1] === "all") {
            var regionIDs = regionNameDropdownData.map((item) => item.practiceId);
            var selectedValues =
                selectedPracticeName.length === practiceNameDropdownData.length
                    ? []
                    : practiceNameDropdownData.map((item) => item.practiceId);
            setSelectedPracticeName(selectedValues);
            if (!selectedValues || selectedValues.length === 0) {
            } else {
            }
            return;
        }
        if (!value || value.length === 0) {
        } else {
        }
        setSelectedPracticeName(
            typeof value === "string" ? value.split(",") : value
        );
    };

    const SubmitReport = () => {
        const formData = {
            Year: "2024",
            Payer: selectedPayerName.join(","),
            Product: selectedProductName.join(","),
            Market: selectedMarketName.join(","),
            subMarket: selectedRegionName.join(","),
            practice: selectedPracticeName.join(",")
        };
        if (formData &&
            formData.Payer != '' &&
            formData.Product != '' &&
            formData.Market != '' &&
            formData.subMarket != '' &&
            formData.practice != ''
        ) {
            setIsListLoading(true);
            axiosRequests
                .post("/Recertification/GetRecertificationReportDataList", formData)
                .then((resp) => {
                    let obj = [];
                    if (resp[0] && resp[0]?.description != undefined) {
                        obj.push(resp[0]);
                        setReportListData(obj);
                        setIsGridDisplay(true);
                        let rowCount = 1;//sdohMemberList?.count;
                        let numberOfPages = Math.ceil((rowCount / 10));
                        setTotalRows(rowCount);
                        setPageCount(numberOfPages);
                        setIsListLoading(false);
                    }
                });
        }
        else{
            setReportListData([]);
        }
    }
    return (
        <Box sx={{width:"98%"}}>
            <RecertificationPageHeader />
            <Box sx={{ paddingTop: 2 }}>

                <Grid container >
                    <Grid item xs={12} md={12} alignContent="center">
                        {/* Filter section starts */}
                        <Stack direction="row" spacing={2}>

                            <FormControl
                                className="spacingClass"
                                required
                                color="orangeTheme"
                                sx={{
                                    maxWidth: "160px",
                                    minWidth: "160px",
                                    backgroundColor: "#fff",
                                    borderRadius: "6px",
                                }}
                                size="small"
                            >
                                <InputLabel id="product-name-select-small-label">Payer</InputLabel>

                                <Select
                                    required
                                    multiple
                                    value={selectedPayerName}
                                    className="productNameDropdown"
                                    labelId="product-name-select-small-label"
                                    id="product-name-select-small"
                                    onChange={(e) => handlePayerNameChange(e)}
                                    renderValue={(selected) => `${selected.length} Selected`}
                                    label="Market"
                                >
                                    <MenuItem value="all">
                                        <Checkbox
                                            color="orangeTheme"
                                            checked={isAllPayersSelected}
                                            indeterminate={
                                                selectedPayerName.length > 0 &&
                                                selectedPayerName.length < payerDropdownData.length
                                            }
                                        />

                                        <ListItemText primary="Select All">Select All </ListItemText>
                                    </MenuItem>
                                    {payerDropdownData.map((item) => (
                                        <MenuItem
                                            className="dropdownText"
                                            key={item.payerId}
                                            value={item.payerId}
                                        >
                                            <Checkbox
                                                style={{ fontSize: "14px" }}
                                                color="orangeTheme"
                                                checked={selectedPayerName.indexOf(item.payerId) > -1}
                                            />
                                            <ListItemText
                                                style={{ fontSize: "14px" }}
                                                primary={item.payerName}
                                            />
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText style={{ height: "0px" }}>
                                    {/* {productNameErrorText} */}
                                </FormHelperText>
                            </FormControl>

                            <FormControl
                                className="spacingClass"
                                required
                                color="orangeTheme"
                                sx={{
                                    maxWidth: "160px",
                                    minWidth: "160px",
                                    backgroundColor: "#fff",
                                    borderRadius: "6px",
                                }}
                                size="small"
                            >
                                <InputLabel id="product-name-select-small-label">Product</InputLabel>

                                <Select
                                    required
                                    multiple
                                    value={selectedProductName}
                                    className="productNameDropdown"
                                    labelId="product-name-select-small-label"
                                    id="product-name-select-small"
                                    onChange={(e) => handleProductChange(e)}
                                    renderValue={(selected) => `${selected.length} Selected`}
                                    label="Market"
                                >
                                    <MenuItem value="all">
                                        <Checkbox
                                            color="orangeTheme"
                                            checked={isAllProductsSelected}
                                            indeterminate={
                                                selectedProductName.length > 0 &&
                                                selectedProductName.length < productDropdownData.length
                                            }
                                        />

                                        <ListItemText primary="Select All">Select All </ListItemText>
                                    </MenuItem>
                                    {productDropdownData.map((item) => (
                                        <MenuItem
                                            className="dropdownText"
                                            key={item.productId}
                                            value={item.productId}
                                        >
                                            <Checkbox
                                                style={{ fontSize: "14px" }}
                                                color="orangeTheme"
                                                checked={selectedProductName.indexOf(item.productId) > -1}
                                            />
                                            <ListItemText
                                                style={{ fontSize: "14px" }}
                                                primary={item.productName}
                                            />
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText style={{ height: "0px" }}>
                                    {/* {productNameErrorText} */}
                                </FormHelperText>
                            </FormControl>

                            <FormControl
                                className="spacingClass"
                                required
                                color="orangeTheme"
                                sx={{
                                    maxWidth: "160px",
                                    minWidth: "160px",
                                    backgroundColor: "#fff",
                                    borderRadius: "6px",
                                }}
                                size="small"
                            >
                                <InputLabel id="product-name-select-small-label">Market</InputLabel>

                                <Select
                                    required
                                    multiple
                                    value={selectedMarketName}
                                    className="productNameDropdown"
                                    labelId="product-name-select-small-label"
                                    id="product-name-select-small"
                                    onChange={(e) => handleMarketNameChange(e)}
                                    renderValue={(selected) => `${selected.length} Selected`}
                                    label="Market"
                                >
                                    <MenuItem value="all">
                                        <Checkbox
                                            color="orangeTheme"
                                            checked={isAllMarketsSelected}
                                            indeterminate={
                                                selectedMarketName.length > 0 &&
                                                selectedMarketName.length < marketNameDropdownData.length
                                            }
                                        />

                                        <ListItemText primary="Select All">Select All </ListItemText>
                                    </MenuItem>
                                    {marketNameDropdownData.map((item) => (
                                        <MenuItem
                                            className="dropdownText"
                                            key={item.marketId}
                                            value={item.marketId}
                                        >
                                            <Checkbox
                                                style={{ fontSize: "14px" }}
                                                color="orangeTheme"
                                                checked={selectedMarketName.indexOf(item.marketId) > -1}
                                            />
                                            <ListItemText
                                                style={{ fontSize: "14px" }}
                                                primary={item.marketName}
                                            />
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText style={{ height: "0px" }}>
                                    {/* {productNameErrorText} */}
                                </FormHelperText>
                            </FormControl>

                            <FormControl
                                className="spacingClass"
                                required
                                color="orangeTheme"
                                sx={{
                                    maxWidth: "160px",
                                    minWidth: "160px",
                                    backgroundColor: "#fff",
                                    borderRadius: "6px",
                                }}
                                // disabled={isLoadingRegionName}
                                size="small"
                            >
                                <InputLabel id="product-name-select-small-label">Region</InputLabel>
                                <Select
                                    required
                                    multiple
                                    value={selectedRegionName}
                                    className="productNameDropdown"
                                    labelId="product-name-select-small-label"
                                    id="product-name-select-small"
                                    onChange={(e) => handleRegionNameChange(e)}
                                    renderValue={(selected) => `${selected.length} Selected`}
                                    label="Region"
                                >
                                    <MenuItem value="all">
                                        <Checkbox
                                            color="orangeTheme"
                                            checked={isAllRegionsSelected}
                                            indeterminate={
                                                selectedRegionName.length > 0 &&
                                                selectedRegionName.length < regionNameDropdownData.length
                                            }
                                        />
                                        <ListItemText primary="Select All">Select All </ListItemText>
                                    </MenuItem>
                                    {regionNameDropdownData.map((item) => (
                                        <MenuItem
                                            className="dropdownText"
                                            key={item.id}
                                            value={item.id}
                                        >
                                            <Checkbox
                                                style={{ fontSize: "14px" }}
                                                color="orangeTheme"
                                                checked={selectedRegionName.indexOf(item.id) > -1}
                                            />
                                            <ListItemText
                                                style={{ fontSize: "14px" }}
                                                primary={item.regionName}
                                            />
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText style={{ height: "0px" }}>
                                    {/* {productNameErrorText} */}
                                </FormHelperText>
                            </FormControl>

                            <FormControl
                                className="spacingClass"
                                required
                                color="orangeTheme"
                                sx={{
                                    maxWidth: "160px",
                                    minWidth: "160px",
                                    borderRadius: "6px",
                                }}
                                // disabled={isLoadingPracticeName}
                                size="small"
                            >
                                <InputLabel id="product-name-select-small-label">
                                    Practice
                                </InputLabel>
                                <Select
                                    required
                                    multiple
                                    value={selectedPracticeName}
                                    className="productNameDropdown"
                                    labelId="product-name-select-small-label"
                                    id="product-name-select-small"
                                    onChange={(e) => handlePracticeNameChange(e)}
                                    renderValue={(selected) => `${selected.length} Selected`}
                                    label="Practice"
                                >
                                    <MenuItem value="all">
                                        <Checkbox
                                            color="orangeTheme"
                                            checked={isAllPracticeSelected}
                                            indeterminate={
                                                selectedPracticeName.length > 0 &&
                                                selectedPracticeName.length <
                                                practiceNameDropdownData.length
                                            }
                                        />
                                        <ListItemText primary="Select All">Select All </ListItemText>
                                    </MenuItem>
                                    {practiceNameDropdownData.map((item) => (
                                        <MenuItem
                                            className="dropdownText"
                                            key={item.practiceId}
                                            value={item.practiceId}
                                        >
                                            <Checkbox
                                                style={{ fontSize: "14px" }}
                                                color="orangeTheme"
                                                checked={selectedPracticeName.indexOf(item.practiceId) > -1}
                                            />
                                            <ListItemText
                                                style={{ fontSize: "14px" }}
                                                primary={item.practiceName}
                                            />
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText style={{ height: "0px" }}>
                                    {/* {productNameErrorText} */}
                                </FormHelperText>
                            </FormControl>

                        </Stack>
                        {/* Filter section Ends */}
                    </Grid>
                    
                </Grid>
                <Grid container>
                <Grid item xs={12} md={12} justifyContent="end" alignContent="end">
                        <Stack
                            direction="row"
                            spacing={1}
                            style={{ justifyContent: "end" }}>
                            {/* <Button
                                sx={{
                                    textTransform: "none",
                                    color: "#111827",
                                    border: "1px solid",
                                    borderColor: "#000",
                                    textAlign: "center",
                                    leadingTrim: "both",
                                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                                    fontSize: "12px",
                                    fontStyle: Styles.FONT_STYLE_NORMAL,
                                    fontWeight: "500",
                                    lineHeight: "22px",
                                    "&.MuiButton-root:hover": {
                                        borderColor: "#000",
                                        border: "1px solid #000",
                                    },
                                }}
                                variant="outlined"
                                onClick={SubmitReport}
                            >
                                Submit
                            </Button> */}

                            <Button
                                    style={{
                                        textTransform: "none",
                                        color: "white",
                                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                                    fontSize: "12px",
                                    fontStyle: Styles.FONT_STYLE_NORMAL,
                                    fontWeight: "500",
                                    lineHeight: "22px",
                                    }}
                                    variant="contained"
                                    color="orangeTheme"
                                    disableElevation
                                    onClick={SubmitReport}
                                >
                                    Apply Filter
                                </Button>
                                <Button
                                    style={{
                                        textTransform: "none",
                                        borderColor: "black",
                                        color: "black",
                                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                                    fontSize: "12px",
                                    fontStyle: Styles.FONT_STYLE_NORMAL,
                                    fontWeight: "500",
                                    lineHeight: "22px",
                                    }}
                                    variant="outlined"
                                    onClick={handleClearAllSections}
                                >
                                    Clear Filter
                                </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
            {/* {!accessObj.hasAccess ? (
                <NoAccessPage></NoAccessPage>
            ) : ( */}
            {
                isListLoading ? (
                    <Loader isLoading={isListLoading} />
                ) : (
                    <Box >
                        <Grid container>
                            <Grid item xs={12}
                            >
                                <div style={{ display: "grid" }}>
                                {
                isGridDisplay ? (
                    <DataGridCustom
                                    sortingOrder={['asc', 'desc']}
                                    columns={columns}
                                    sortingMode="server"
                                    rows={reportListData}
                                    sortModel={sortModel}
                                    getRowId={getRowId}
                                    loading={isListLoading}
                                    // onSortModelChange={(e) => handleSortModelChange(e)}
                                    slots={{
                                        pagination: CustomPaginationSlot,
                                        // props:{setPage: setPage}
                                    }}
                                    slotProps={{
                                        pagination: {
                                            setPage: setPage,
                                            paginationModel: { count: pageCount, page: page, totalRows: totalRows },
                                            colorTheme: 'orangeTheme',
                                            className: 'pageNumber'
                                        }
                                    }}
                                />
                ) : (
                                <span></span>
                            )}
                            </div>
                            </Grid>
                        </Grid>
                    </Box>
                )}
        </Box>
    )
}

export default RecertificationReportPage;