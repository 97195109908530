import React, { useEffect, useState, useCallback } from "react";
import { useAppDispatch } from "../../../app/store";
import { Link, useNavigate } from "react-router-dom";
// import { Box } from "@mui/material";
import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Loader from "../../../component/common/loader/Loader";
import { useSelector } from "react-redux";
// import DataGridCustom from "../../component/common/datagrid/DataGridCustom";
import Fab from "@mui/material/Fab";
// import { Styles } from "../../Styles";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { axiosRequests } from "../../../app/api/AxiosRequest";
import DataGridCustom from "../../../component/common/datagrid/DataGridCustom";
// import { getUsersList } from "./usersListSlice";
import { PagesPath } from "../../../PagesPath";
import { getUserRoleMappingList } from "./userRoleMappingSlice";
import UserRoleMappingModal from "./UserRoleMappingModal";
import DataGridHeader from "../../../component/common/datagrid/DataGridHeader";
import { COLOR, Styles } from "../../../Styles";
import { Search } from "@mui/icons-material";

const UserRoleMappingTable = () => {
  const [rowUpdated, setRowUpdated] = useState(false);
  const [userRoleMappingModal, setUserRoleMappingModal] = useState(false);
  const [userRoleMappingRowData, setUserRoleMappingRowData] = useState(false);
  const [roleDropdownData, setRoleDropdownData] = useState([]);
  const [isPracticeAdmin, setIsPracticeAdmin] = useState(false);

  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoading, userRoleMappingData } = useSelector(
    (state) => state.userRoleMappingData
  );

  const getUserRoleMappingListData = useCallback(async () => {
    const response = await dispatch(getUserRoleMappingList());
  }, [dispatch]);

  const handleEditClick = (rowData) => {
    setUserRoleMappingRowData(rowData);
    setUserRoleMappingModal(true);
  };
  function closeUserRoleMappingModal() {
    // setSelectedRow(row);
    setUserRoleMappingModal(false);
  }

  const getRowId = (row) => {
    return row.id;
  };

  useEffect(() => {
    getUserRoleMappingListData();
  }, [getUserRoleMappingListData]);

  useEffect(() => {
    if (userRoleMappingData) {
      let filtered;
      if (searchText) {
        filtered = userRoleMappingData.filter((item) =>
          (item.firstName + " " + item.lastName)
            .toLowerCase()
            .includes(searchText.toLowerCase())
        );
        setFilteredData(filtered);
      } else {
        setFilteredData(userRoleMappingData);
      }
    }
  }, [searchText, userRoleMappingData]);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("userAccess"))) {
      var practiceAdmin = JSON.parse(
        localStorage.getItem("userAccess")
      ).roleType;
      if (parseInt(practiceAdmin) === 2) {
        setIsPracticeAdmin(true);
      }
    }
  }, []);

  useEffect(() => {
    if (rowUpdated) {
      getUserRoleMappingListData();
      setRowUpdated(false);
    }
  }, [rowUpdated]);

  useEffect(() => {
    axiosRequests.get("/UserRoleMapping/GetRoleDetails").then((resp) => {
      if (isPracticeAdmin) {
        resp = resp.filter(
          (item) =>
            (item.roleType === 3 ||
              item.roleType === 2 ||
              item.roleType === 4 || item.roleType === 5) &&
            parseInt(item.practiceAdminID) ===
              parseInt(JSON.parse(localStorage.getItem("userAccess")).id)
        );
      }
      setRoleDropdownData(resp);
    });
  }, [userRoleMappingModal, isPracticeAdmin]);

  const columns = [
    {
      field: "userName",
      headerName: "User Name",
      flex: 1,
      valueGetter: (params) => params.row.firstName + " " + params.row.lastName,
      renderCell: (params) => {
        return <div>{params.row.firstName + " " + params.row.lastName}</div>;
      },
    },
    {
      field: "userEmail",
      headerName: "Contact Email",
      flex: 1,
    },
    {
      field: "roleId",
      headerName: "Mapped Roles",
      flex: 1,

      renderCell: (params) => {
        var combinedRoleNames = "";
        if (params.row.roleId) {
          var roleIds = params.row.roleId.split(",");
          const roleNames = roleIds.map((roleId) => {
            const role = roleDropdownData.find(
              (r) => r.roleId === parseInt(roleId)
            );
            return role ? role.roleName : "";
          });
          combinedRoleNames = roleNames.filter((item) => item !== "").join(",");
        } else combinedRoleNames = params.row.roleId;
        return combinedRoleNames ? (
          <Tooltip title={combinedRoleNames}>
            <span>{combinedRoleNames}</span>
          </Tooltip>
        ) : (
          ""
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <strong>
            <Link
              style={{
                textDecoration: "none",
                underline: "hover",
                color: "inherit",
              }}
            >
              <EditIcon onClick={() => handleEditClick(params.row)}></EditIcon>
            </Link>
          </strong>
        );
      },
    },
  ];

  return (
    <div>
      <Stack direction="row" spacing={1}></Stack>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <Grid item>
          <DataGridCustom
            columns={columns}
            rows={filteredData}
            getRowId={getRowId}
            loading={isLoading}
            slots={{
              toolbar: DataGridHeader,
            }}
            slotProps={{
              toolbar: {
                headerText: "",
                fontSize: "",
                marginTop: "",
                marginBottom: "",
                height: "56px",
                children: (
                  <div
                    style={{
                      fontFamily: Styles.FONT_FAMILY_POPPINS,
                      fontSize: "16px",
                      width: "100%",
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginLeft: "5px",
                    }}
                  >
                    <Stack direction="row" spacing={1}>
                      <Typography
                        style={{
                          fontFamily: Styles.FONT_FAMILY_POPPINS,
                          fontSize: "16px",
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      >
                        Total User Role Mappings
                      </Typography>
                      <Chip
                        color="orangeTheme"
                        variant="contained"
                        style={{ color: "#fff" }}
                        label={userRoleMappingData.length}
                      ></Chip>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{ marginLeft: "auto" }}
                      >
                        <TextField
                          InputProps={{
                            style: { height: "38px", alignItems: "center" },
                            endAdornment: (
                              <InputAdornment position="end">
                                <Link
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                    //cursor: "pointer",
                                  }}
                                >
                                  <Search
                                    fontSize="small"
                                    sx={{
                                      color: COLOR.ORANGE,
                                      position: "inherit",
                                    }}
                                  />
                                </Link>
                              </InputAdornment>
                            ),
                          }}
                          label="Search User"
                          id="outlined-size-small"
                          onChange={(e) => handleSearchChange(e)}
                          style={{
                            width: "293px",
                          }}
                          color="orangeTheme"
                          size="small"
                        />
                      </Stack>
                    </Stack>
                  </div>
                ),
              },
            }}
          />
        </Grid>
      )}
      <UserRoleMappingModal
        isOpen={userRoleMappingModal}
        onClose={closeUserRoleMappingModal}
        rowData={userRoleMappingRowData}
        roleDropdownData={roleDropdownData}
        setRowUpdated={setRowUpdated}
      ></UserRoleMappingModal>
    </div>
  );
};

export default UserRoleMappingTable;
