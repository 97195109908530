import React, { useEffect } from "react";
import {
  Button,
  TextField,
  Modal,
  Box,
  Grid,
  Backdrop,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Styles } from "../../Styles";
import { axiosRequests } from "../../app/api/AxiosRequest";
import { useNavigate } from "react-router-dom";
import { PagesPath } from "../../PagesPath";
const DeleteEventModal = ({ isOpen, onClose, deleteData, setIsDeleted }) => {
  const handleConfirmClick = () => {
    axiosRequests
      .delete(`/EducationEvent/DeleteEducationEvent?id=${deleteData.id}`)
      .then((resp) => {
        setIsDeleted(true);
        onClose();
      });
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      slotProps={{ backdrop: { onClick: null } }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "25%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "530px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          pt: 1,
          height: "auto",
        }}
      >
        <h3
          style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
        >
          Delete Event Confirmation{" "}
          <span>
            <CloseIcon onClick={onClose} style={{ float: "right" }} />
          </span>
        </h3>
        <hr
          style={{
            width: "auto",
            opacity: "0.3",
            marginTop: "-10px",
            marginBottom: "14px",
          }}
        />
        <Typography style={{ fontSize: "16px", fontWeight: "400" }}>
          Are you sure to delete "
          <span style={{ fontWeight: 600 }}>{deleteData.title}</span>"?
        </Typography>
        <hr style={{ width: "auto", opacity: "0.3", marginTop: "12px" }} />
        <Grid
          container
          spacing={6}
          style={{ marginLeft: "-20px", marginTop: "-39px" }}
        >
          <Grid item xs={5}></Grid>
          <Grid item xs={3}>
            <Button
              style={{
                borderColor: "black",
                color: "black",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
                marginLeft: "23px",
              }}
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={3}>
            <Button
              style={{ color: "white", fontFamily: Styles.FONT_FAMILY_POPPINS }}
              variant="contained"
              color="orangeTheme"
              onClick={handleConfirmClick}
              disableElevation
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default DeleteEventModal;
