import React from "react";
import PageHeader from "../../component/common/header/PageHeader";
//import familyMemberProfileImage from "../../../assets/profileMemberPage.svg";
import familyMemberProfileImage from "../../assets/profileMemberPage.svg";
// import FamilyMembersProfilePage from "../../pages/familyMembersProfilePage/FamilyMembersProfilePage";


const FamilyMemberProfileHeader = () => {
  const title = "Family Member Profile";
  return (
    <>
      <PageHeader title={title} imgSrc={familyMemberProfileImage} />
    </>
  );
};

export default FamilyMemberProfileHeader;
