import { Avatar, Chip } from "@mui/material";
import { format, isSameDay } from "date-fns";

const CustomDayCellWrapper = ({ value }) => {
  const isCurrentDay = isSameDay(new Date(), value);

  return (
    <div className="rbc-day-bg">
      {isCurrentDay ? (
        <Avatar
          sx={{
            width: 24,
            height: 24,
            top: "2px",
            color: "#fff",
            backgroundColor: "#DC4F34",
          }}
        >
          {" "}
          <div style={{ fontSize: "14px" }}>{format(value, "d")}</div>
        </Avatar>
      ) : (
        <span>{format(value, "d")}</span>
      )}
    </div>
  );
};

export default CustomDayCellWrapper;
