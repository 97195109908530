import { BrowserAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import {
  Box,
  Button,
  CssBaseline,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import React from "react";
import Heart_beat from "../../assets/Heart_beat.svg";
import cinq_2 from "../../assets/ciinq_ 2.png";
import palliative_care1 from "../../assets/palliative_care1.svg";
import palliative_care2 from "../../assets/palliative_care2.svg";
import palliative_care3 from "../../assets/palliative_care3.svg";
import palliative_care4 from "../../assets/palliative_care4.svg";

import background from "../../assets/login_background_image.jpg";
import { authenticationScope } from "../../authConfig";
const Login = () => {
  const { instance, accounts, inProgress } = useMsal();
  const scopes = authenticationScope;

  const paperStyle = {
    width: "1366px",
    height: "768px",
    top: "1617px",
    left: "399px",
  };
  const avatarStyle = { backgroundColor: "#1bbd7e" };
  const btnstyle = { margin: "8px 0" };

  const handleLogin = async () => {
    try {
      var response = await instance.loginPopup({
        scopes: scopes,
      });
      //localStorage.setItem("accessToken", response.idToken);
    } catch (e) {
      if (e instanceof BrowserAuthError) console.log(e);
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7),
        rgba(0, 0, 0, 0.6)),url(${background})`,
          backgroundRepeat: "no-repeat",

          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          msOverflowY: "hidden",
          display: "flex",
        }}
      >
        <Grid container>
          <CssBaseline />

          <Grid
            item
            xs={false}
            width={808}
            sm={4}
            md={7}
            sx={
              {
                //display:'flex'
              }
            }
          >
            <h1
              style={{
                width: "513.12px",
                height: "56.98px",
                color: "#f37021",
                marginTop: "247px",
                marginLeft: "80px",
                fontSize: "48px",
                fontFamily: "Sans-serif",
                lineHeight: "56.92px",
                fontWeight: "700",
                textAlign: "left",
              }}
            >
              Seven Pillar Platform
            </h1>
            <Box
              component="img"
              sx={{
                height: "19px",
                width: "81px",
                marginLeft: "572px",

                marginTop: "-65px",
                position: "absolute",
              }}
              src={Heart_beat}
            />

            <h4
              style={{
                width: "633px",
                height: "38.98px",
                color: "#ffffff",
                marginTop: "2.28px",
                marginLeft: "80px",
                fontSize: "26px",
                fontFamily: "Sans-serif",
                lineHeight: "39px",
                fontWeight: "400",
              }}
            >
              Delivering Care Where you live, That's our calling
            </h4>
            <Grid
              sx={{
                width: "80%",
                marginLeft: "8%",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="outlined"
                style={{
                  position: "relative",
                  textTransform: "math-auto",
                  width: "285px",
                  height: "80px",
                  color: "#212121",
                  marginLeft: "2%",
                  borderColor: "#2E2E2E",
                  color: "#ffffff",

                  backgroundColor: "#212121",
                  opacity: "0.7",
                  fontFamily: "Poppins",
                  fontWeight: "500px",
                  borderRadius: "15.81px",
                }}
              >
                <img
                  src={palliative_care1}
                  style={{ padding: "8px", marginLeft: "-60px" }}
                ></img>
                <br />
                <p>Closing Care Gaps</p>
              </Button>
              <Button
                variant="outlined"
                style={{
                  position: "relative",
                  textTransform: "math-auto",
                  width: "285px",
                  height: "80px",
                  color: "#212121",
                  marginLeft: "2%",
                  borderColor: "#2E2E2E",
                  color: "#ffffff",
                  backgroundColor: "#212121",
                  opacity: "0.7",
                  fontFamily: "Poppins",
                  fontWeight: "500px",
                  textAlign: "left",
                  borderRadius: "15.81px",
                }}
              >
                <img
                  src={palliative_care2}
                  style={{ textAlign: "left", padding: "5px" }}
                ></img>
                <p>Clinical And Operational Optimization</p>
              </Button>
              <br />
              <Button
                variant="outlined"
                style={{
                  position: "relative",
                  textTransform: "math-auto",
                  width: "285px",
                  height: "80px",
                  color: "#212121",
                  marginLeft: "2%",
                  marginTop: "5%",
                  borderColor: "#2E2E2E",
                  color: "#ffffff",
                  backgroundColor: "#212121",
                  opacity: "0.7",
                  fontFamily: "Poppins",
                  fontWeight: "500px",
                  borderRadius: "15.81px",
                }}
              >
                <img src={palliative_care3} style={{ padding: "8px" }}></img>
                <p
                // style={{
                //   position: "absolute",
                //   fontSize: "12px",
                //   top: "38px",
                // }}
                >
                  Proactive & Preventive Care
                </p>
              </Button>
              <Button
                variant="outlined"
                style={{
                  position: "relative",
                  textTransform: "math-auto",
                  width: "285px",
                  height: "80px",
                  color: "#212121",
                  marginLeft: "2%",
                  marginTop: "5%",
                  borderColor: "#2E2E2E",
                  color: "#ffffff",
                  backgroundColor: "#212121",
                  opacity: "0.7",
                  fontFamily: "Poppins",
                  fontWeight: "500px",
                  borderRadius: "15.81px",
                }}
              >
                <img src={palliative_care4} style={{ padding: "8px" }}></img>
                <p style={{ marginRight: "78px" }}>Drive Down Costs</p>
              </Button>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
            sx={{ background: "none", boxShadow: "none" }}
          >
            <Box
              sx={{
                my: 8,
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                top: "270px",
                width: "420px",
                //right: "878px",
                height: "399px",
                backgroundColor: "#212121",
                opacity: "0.7",
                left: "876px",
              }}
              style={{ padding: "8%", marginTop: "40%", marginLeft: "97px" }}
            >
              <Box
                component="img"
                sx={{
                  top: 2302,
                  marginleft: "-4px",
                  height: "43px",
                  width: "148px",
                  gap: 4,
                }}
                src={cinq_2}
              />

              {/* <Typography
                style={{ marginTop: "7%", marginBottom: "3%" }}
                component="div"
                variant="h4"
                fontFamily="Poppins"
                fontSize="30px semibold"
                lineHeight="43.2px"
              >
                Welcome Back
              </Typography>
              <Typography
                component="div"
                variant="h10"
                fontFamily="Poppins"
                fontSize="18px"
                lineHeight="43.2px"
              >
                Please enter your details
              </Typography> */}

              <Box
                component="form"
                noValidate
                sx={{ mt: 1, marginTop: "65px" }}
              >
                <label style={{ color: "#ffffff", marginLeft: "108px" }}>
                  Login ID
                </label>

                <TextField
                  className="Textbox"
                  // style={{
                  //  width:'88%',
                  // }}
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  placeholder="Enter email"
                />

                <Button
                  type="button"
                  style={{
                    textTransform: "math-auto",
                    width: "100.5%",
                    marginTop: "35px",
                    color: "#ffffff",
                  }}
                  variant="contained"
                  sx={{
                    mt: 3,
                    mb: 3,
                    borderRadius: "23px",
                    backgroundColor: "#F37021",
                  }}
                  onClick={handleLogin}
                >
                  Log in
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Login;
