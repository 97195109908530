import { Box, Tab } from "@mui/material";
import EducationConfigurationHeader from "./EducationConfigurationHeader";
import { Styles } from "../../Styles";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import NoAccessPage from "../../component/common/noAccess/NoAccessPage";
import ProgramsTable from "./ProgramsConfiguration/ProgramsTable";
import EventsDisplay from "./EventsConfiguration/EventDisplay";

const EducationConfiguration = () => {
  const [value, setValue] = useState("1");
  const [isGlobalAdmin, setIsGlobalAdmin] = useState(false);
  const [isPracticeAdmin, setIsPracticeAdmin] = useState(false);
  let { state } = useLocation();
  const handleChange = (event, newValue) => {
    localStorage.setItem("educationTabValue", newValue);
    if (state) {
      state = { value: newValue };
    } //state.value=newValue;
    setValue(newValue);
  };
  useEffect(() => {
    var globalAdmin = localStorage.getItem("globalAdmin");
    if (parseInt(globalAdmin) === 1) {
      setIsGlobalAdmin(true);
    }
    if (JSON.parse(localStorage.getItem("userAccess"))) {
      var practiceAdmin = JSON.parse(
        localStorage.getItem("userAccess")
      ).roleType;
      if (parseInt(practiceAdmin) === 2) {
        setIsPracticeAdmin(true);
      }
    }
  }, []);
  useEffect(() => {
    if (state) {
      setValue(localStorage.getItem("educationTabValue"));
    }
  }, []);
  return (
    <Box sx={{ width: "100%", p: { xs: 2, sm: 2 } }}>
      <EducationConfigurationHeader />
      {!isGlobalAdmin ? (
        <NoAccessPage></NoAccessPage>
      ) : (
        <TabContext value={value}>
          <div>
            <Box>
              <TabList
                value={value}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#dc4f34",
                  },
                }}
                onChange={handleChange}
              >
                <Tab
                  label="Programs"
                  value="1"
                  className={
                    value === "1"
                      ? "custom-tab-class Mui-selected"
                      : "custom-tab-class"
                  }
                  sx={{
                    textAlign: "left",
                    letterSpacing: "1px",
                    color: "#000",
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    textTransform: "none",
                  }}
                />
                <Tab
                  label="Events"
                  value="2"
                  className={
                    value === "2"
                      ? "custom-tab-class Mui-selected"
                      : "custom-tab-class"
                  }
                  sx={{
                    textAlign: "left",
                    letterSpacing: "1px",
                    color: "#000",
                    p: 0,
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    textTransform: "none",
                  }}
                />
              </TabList>
            </Box>
          </div>
          <TabPanel value="1">
            <ProgramsTable />
          </TabPanel>
          <TabPanel
            className="eventsClass"
            style={{ padding: "16px" }}
            value="2"
          >
            <EventsDisplay />
          </TabPanel>
        </TabContext>
      )}
    </Box>
  );
};

export default EducationConfiguration;
