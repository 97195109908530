import UserAdminConsoleHeader from "./UserAdminConsoleHeader";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { saveAs } from "file-saver";
import { Box, Button, Grid } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import VBCPerfomanceHeader from "../vbcPerfomance/VBCPerfomanceHeader";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Styles } from "../../Styles";
import { axiosRequests } from "../../app/api/AxiosRequest";
import Loader from "../../component/common/loader/Loader";
import Users from "./Users";
import UserRoles from "./UserRoles";
import RoleMapping from "./RoleMapping";
import UserRolesMapping from "./UserRolesMapping";
import { useLocation } from "react-router-dom";
import NoAccessPage from "../../component/common/noAccess/NoAccessPage";

const UserAdminConsole = () => {
  const [value, setValue] = React.useState("1");
  const [isGlobalAdmin, setIsGlobalAdmin] = useState(false);
  const [isPracticeAdmin, setIsPracticeAdmin] = useState(false);
  let { state } = useLocation();
  const handleChange = (event, newValue) => {
    localStorage.setItem("tabValue", newValue);
    if (state) {
      state = { value: newValue };
    } //state.value=newValue;
    setValue(newValue);
  };
  useEffect(() => {
    var globalAdmin = localStorage.getItem("globalAdmin");
    if (parseInt(globalAdmin) === 1) {
      setIsGlobalAdmin(true);
    }
    if (JSON.parse(localStorage.getItem("userAccess"))) {
      var practiceAdmin = JSON.parse(
        localStorage.getItem("userAccess")
      ).roleType;
      if (parseInt(practiceAdmin) === 2) {
        setIsPracticeAdmin(true);
      }
    }
  }, []);
  useEffect(() => {
    if (state) {
      setValue(localStorage.getItem("tabValue"));
    }
  }, []);
  return (
    <Box sx={{ width: "100%", p: { xs: 2, sm: 2 } }}>
      <UserAdminConsoleHeader></UserAdminConsoleHeader>
      {!isGlobalAdmin && !isPracticeAdmin ? (
        <NoAccessPage></NoAccessPage>
      ) : (
        <TabContext value={value}>
          <div>
            <Box>
              <TabList
                value={value}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#dc4f34",
                  },
                }}
                onChange={handleChange}
              >
                <Tab
                  label="Users"
                  value="1"
                  className={
                    value === "1"
                      ? "custom-tab-class Mui-selected"
                      : "custom-tab-class"
                  }
                  sx={{
                    textAlign: "left",
                    letterSpacing: "1px",
                    color: "#000",
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    textTransform: "none",
                  }}
                />
                <Tab
                  label="User Roles"
                  value="2"
                  className={
                    value === "2"
                      ? "custom-tab-class Mui-selected"
                      : "custom-tab-class"
                  }
                  sx={{
                    textAlign: "left",
                    letterSpacing: "1px",
                    color: "#000",
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    textTransform: "none",
                  }}
                />
                <Tab
                  label="Role Mapping"
                  value="3"
                  className={
                    value === "3"
                      ? "custom-tab-class Mui-selected"
                      : "custom-tab-class"
                  }
                  sx={{
                    textAlign: "left",
                    letterSpacing: "1px",
                    color: "#000",
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    textTransform: "none",
                  }}
                />
                <Tab
                  label="User Roles Mapping"
                  value="4"
                  className={
                    value === "4"
                      ? "custom-tab-class Mui-selected"
                      : "custom-tab-class"
                  }
                  sx={{
                    textAlign: "left",
                    letterSpacing: "1px",
                    color: "#000",
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    textTransform: "none",
                  }}
                />
              </TabList>
            </Box>
          </div>
          <TabPanel value="1">
            <Users />
          </TabPanel>
          <TabPanel value="2">
            <UserRoles />
          </TabPanel>
          <TabPanel value="3">
            <RoleMapping />
          </TabPanel>
          <TabPanel value="4">
            <UserRolesMapping />
          </TabPanel>
        </TabContext>
      )}
    </Box>
  );
};

export default UserAdminConsole;
