
import React, { useState } from "react";
import PageHeader from "../../component/common/header/PageHeader";
const ReportPackagePageHeader = () => {
    const title = "Report Package";
    const sevenPillarTitle = "Practice VBC Revenue & Growth";
    return (
        <>
            <PageHeader title={title} sevenPillarTitle={sevenPillarTitle}/>
            
        </>
    );
};

export default ReportPackagePageHeader;
