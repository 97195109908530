import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { useMsal } from "@azure/msal-react";
import { msalConfig } from "../../../authConfig";
import { Styles } from "../../../Styles";
import { LoginAgent } from "../../../app/api/login/LoginAgent";

const Logout = () => {
  const { instance, accounts, inProgress } = useMsal();

  const handleLogout = async () => {
    try {
      await LoginAgent.auditSignOut();
      await instance.logoutPopup({
        mainWindowRedirectUri: msalConfig.auth.postLogoutRedirectUri,
        postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
      });

      //localStorage.removeItem("accessToken");
    } catch (e) {
      console.log(e.message);
    }
  };

  return (
    <Box>
      <IconButton
        size="small"
        edge="end"
        aria-label="logout"
        aria-haspopup="true"
        onClick={handleLogout}
        color="inherit"
        sx={{ color: "#DC4F34" }}
      >
        <Stack sx={{ alignItems: "center" }}>
          <PowerSettingsNewIcon
            sx={{ alignItems: "center", alignContent: "center" }}
            size="small"
          />
          <Typography
            sx={{
              // textAlign: "left",
              // font: "normal normal medium 14px/17px Inter",
              // letterSpacing: "0px",
              // color: "#111827",
              // opacity: "1",

              color: Styles.FONT_COLOR,
              leadingTrim: "both",
              textEdge: "cap",
              fontFamily: Styles.FONT_FAMILY_POPPINS,
              fontSize: "12px",
              fontStyle: Styles.FONT_STYLE_NORMAL,
              fontWeight: "500",
              lineHeight: "normal",
            }}
          >
            Logout
          </Typography>
        </Stack>
      </IconButton>
    </Box>
  );
};

export default Logout;
