
import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import ClinicalProfileExcelLinkPageHeader from "./ClinicalProfileExcelLinkPageHeader";
import ScorecardComponent from "../../component/common/scorecard/scorecard";
const ClinicalProfileExcelLinkPage = () => {
    const [clinicalProfileID,setClinicalProfileID]=useState(63);
    return (
        <Box >
            <ClinicalProfileExcelLinkPageHeader />
            <ScorecardComponent ScoreId={clinicalProfileID} />
        </Box>
    );
}

export default ClinicalProfileExcelLinkPage;