import { Box, Button, Grid, Modal, Stack, Typography } from "@mui/material";
import { Styles } from "../../../Styles";
import CloseIcon from '@mui/icons-material/Close';
import { format } from "date-fns";

const UserDetailsModal = ({ isOpen, onClose, rowData }) =>{
    if(!rowData) return null;
    return (
        <Modal open={isOpen} onClose={onClose} slotProps={{backdrop:{onClick:null}}}>
        <Box sx={{
                position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', width: '578px',
                bgcolor: 'background.paper', boxShadow: 24, p: 4, pt: 1, height: '330px'
            }}>
                <h3 style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: '16px' }}>User Details<span><CloseIcon onClick={onClose} style={{float:'right'}}/></span></h3>
                <hr style={{width:'522px',opacity:'0.3',marginTop:'-11px', marginBottom:'5px'}}/>
                <Stack direction='column' spacing={1}>
                    <Typography style={{fontWeight: 500}}>User ID: {rowData.userId}</Typography>
                    <Typography style={{fontWeight: 500}}>Name: {rowData.userName}</Typography>
                    <Typography style={{fontWeight: 500}}>DOB: {(rowData.dateofbirth)?format(new Date(rowData.dateofbirth), "dd MMM yyyy"): '-'}</Typography>
                </Stack>
                <h4 style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: '16px' }}>Contact</h4>
                <hr style={{width:'522px',opacity:'0.3',marginTop:'-19px', marginBottom:'5px'}}/>
                <Stack direction='column' spacing={1}>
                    <Stack direction='row' spacing={6}>
                    <Typography style={{fontWeight: 500}}>Phone: {rowData.contactNumber}</Typography>
                    <Typography style={{fontWeight: 500}}>Email: {rowData.email}</Typography>
                    </Stack>
                    <Typography style={{fontWeight: 500}}>Address: {(rowData.address)?rowData.address: '-'}</Typography>
                    {/* <Typography style={{fontWeight: 500}}>Name: {rowData.userName}</Typography> */}
                </Stack>
                <Grid container spacing={6} style={{marginLeft: '-41px', marginTop:'-39px'}}>
                    <Grid item xs={7}></Grid>
                    <Grid item xs={2}>
                    {/* <Button style={{borderColor: 'black', color:'black', fontFamily: Styles.FONT_FAMILY_POPPINS}} variant="outlined" onClick={onClose}>Cancel</Button> */}
                    </Grid>
                    <Grid item xs={2}>
                    <Button style={{borderColor: 'black', color:'black', fontFamily: Styles.FONT_FAMILY_POPPINS}} variant="outlined" onClick={onClose}>Cancel</Button>
                    {/* <Button style={{color:'white', fontFamily: Styles.FONT_FAMILY_POPPINS}} variant="contained" color="orangeTheme" onClick={() => exportPDF()} disableElevation>Confirm</Button> */}
                    </Grid>
                </Grid>
            </Box>
    </Modal>
    )
    
}

export default UserDetailsModal;