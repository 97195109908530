import React from "react";
import PageHeader from "../../../component/common/header/PageHeader";

const AddUserFormHeader = ({pageTitle}) => {
    const title = "";
     const sevenPillarTitle = "Users";
  return (
    <>
   
      <PageHeader title={title} sevenPillarTitle={pageTitle}/>
      
    </>
  );
};

export default AddUserFormHeader;
