import { BannerConfigJson } from "./BannerConfigJson";
import { EducationConfigurationJson } from "./EducationConfiguration";
import { HealthHomeEducationConfigurationJson } from "./HealthHomeEducationConfiguration";
import { UserAdminConsoleJson } from "./UserAdminConsoleJson";

export const sevenpillarsAdmin = [
  UserAdminConsoleJson,
  BannerConfigJson,
  EducationConfigurationJson,
  HealthHomeEducationConfigurationJson,
];
