import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import cinq_1 from "../../../assets/cinq_1.png";
import { styled } from "@mui/system";
import { Grid } from "@mui/material";

const StyledPDFHeader = styled(Grid)({
  display: "flex",
  padding: "8px 16px",
  justifyContent: "space-between",
  alignItems: "center",
  border: "1px solid  #E0E0E0",
});
const StyledHeaderImage = styled(Grid)({
  width: "120px",
  height: "25px",
  background: `url(${cinq_1}) lightgray 0% / cover no-repeat`,
});
const PDFHeader = () => {
  return <StyledPDFHeader>
    <StyledHeaderImage/>
  </StyledPDFHeader>;
};

export default PDFHeader;
