import React from "react";
import Searchbox from "../../component/common/searchbox/Searchbox";
import PageHeader from "../../component/common/header/PageHeader";

const TrainingProgramsHeader = ({ title="Training Programs", searchValue, setSearchValue }) => {
  //const title = {title};
  const sevenPillarTitle = "Health Home Education";
  return (
    <>
      <PageHeader
        title={title}
        sevenPillarTitle={sevenPillarTitle}
      >
        <div style={{ marginLeft: "auto", float: "right" }}>
          <Searchbox
            cursor="default"
            defaultValue={searchValue}
            handleChange={(e) => setSearchValue(e.target.value)}
            label="Search Program"
          ></Searchbox>
        </div>
      </PageHeader>
    </>
  );
};

export default TrainingProgramsHeader;
