import IPAlertToolFromTexting from "../../features/IPAlertToolFromTexting/IPAlertToolFromTexting";

const IPAlertToolFromTextingPage = () =>{
    return(
        <>
            <IPAlertToolFromTexting></IPAlertToolFromTexting>
        </>
    )
}

export default IPAlertToolFromTextingPage;