import React from "react";
import PageHeader from "../../component/common/header/PageHeader";
import { TextField } from "@mui/material";
import Searchbox from "../../component/common/searchbox/Searchbox";

const MedicalAdherenceToolHeader = () => {
  const title = "Medication Adherence Action Lists";
  const sevenPillarTitle = "Avoidable Inpatient Stays";
  return (
    <>
      <PageHeader title={title} sevenPillarTitle={sevenPillarTitle}>
        <div style={{ marginLeft: "auto", float: "right" }}>
        
        </div>
       
      </PageHeader>
    </>
  );
};

export default MedicalAdherenceToolHeader;

