import RoleMappingTable from "./Role Mapping/RoleMappingTable";

const RoleMapping = () => {
    return( 
        
                
            <RoleMappingTable ></RoleMappingTable> 
        
    )
    
}

export default RoleMapping;