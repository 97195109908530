import { PagesPath } from "../PagesPath";
// import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
// import settings_active from '@mui/icons-material/Settings';
import healthHome from "../assets/health-home.svg";
import healthHome_orange from "../assets/health-home-orange.svg";

export const HealthHomeEducationConfigurationJson = {
  id: 4,
  name: "Health Home Education Configuration",
  key: "HealthHomeEducationConfig",
  iconurl: healthHome,
  activeiconurl: healthHome_orange,
  defaulturl: PagesPath.HealthHomeEducationConfiguration,
};
