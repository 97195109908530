import { axiosRequests } from "../AxiosRequest";
function getMembersList(formData) {
  if ( formData && formData?.market != undefined)
  return axiosRequests.post('/SDOH/GetSDOHPendingMembersDetails', formData);
}

function getMemberDetails(market,Submarket,Practice,memberId,submittedDate) {
  let formData={
    // TinMarket:market,
    // TinSubMarket:Submarket,
    // Practice:Practice,
    memberID:memberId,
    submittedDate:submittedDate
  }
  return axiosRequests.post('/SDOH/GetSDOHMembersDetails', formData);

  // return axiosRequests.get(`/SDOH/GetSDOHMembersDetails?TinMarket=${market}&TinSubMarket=${Submarket}&Practice=${Practice}&memberID=${memberId}`);
}


function getCohortList(formData){
  if ( formData != undefined)
  return axiosRequests.post('/Cohort/getCohortList', formData);
}

function getCohortMemberList(formData){
  if ( formData && formData?.payers != undefined)
  return axiosRequests.post('/Cohort/GetCOHORTMembersList', formData);
}

function EditCOHORTMembersList(formData){
  if ( formData && formData?.payers != undefined)
    return axiosRequests.post('/Cohort/EditCOHORTMembersList', formData);
}
function GetCOHORTMemberEventsList(formData){
  if ( formData && formData?.payer != undefined)
    return axiosRequests.post('/Cohort/GetCOHORTMemberEventsList', formData);
}


function GetCohortMembersDetails(ourId){
  return axiosRequests.get(
    `/Cohort/GetCohortMembersDetails?OurId=${ourId}`
  );
}
function GetClinicalHeathDetails(ourId){
  return axiosRequests.get(
    `/Cohort/GetClinicalHeathDetails?OurId=${ourId}`
  );
}
function GetCohortViewDetails(cohortId){
  return axiosRequests.get(
    `/Cohort/GetCohortViewDetails?CohortId=${cohortId}`
  );
}
function GetCOHORTMemberIPEventsList(formData){
  if ( formData && formData?.payer != undefined)
    return axiosRequests.post('/Cohort/GetCOHORTMemberIPEventsList', formData);
}

function GetCOHORTMembersCostDetails(formData){
  if ( formData && formData?.payer != undefined)
    return axiosRequests.post('/Cohort/GetCOHORTMembersCostDetails', formData);
}

function getCheckList() {
  return axiosRequests.get(`/SDOH/GetNewAssesments`);
}

function getRaceList() {
  return axiosRequests.get(`/SDOH/GetRaceList`);
}

function getEthnicityList(){
  return axiosRequests.get(`/SDOH/GetEthnicityList`);
}

function getLanguageList(){
  return axiosRequests.get(`/SDOH/GetLanguageList`);
}

function getStatusList(formData){
  // let formData={
  //   TinMarket:market,
  //   TinSubMarket:Submarket,
  //   Practice:Practice
  // }
  // if ( formData && formData?.TinMarket != undefined)
  // return axiosRequests.post('/SDOH/GetSDOHPendingMembersDetailsCount', formData);
  if ( formData && formData?.market != undefined)
  return axiosRequests.post('/SDOH/GetSDOHMembersList', formData);
}

function getChartsData(market,Submarket,Practice){
  let formData={
    TinMarket:market,
    TinSubMarket:Submarket,
    Practice:Practice
  }
  return axiosRequests.post('/SDOH/GetSDOHRiskDetails', formData);
  // return axiosRequests.get(
  //   `/SDOH/GetSDOHRiskDetails?TinMarket=${market}&TinSubMarket=${Submarket}&Practice=${Practice}`
  // );
}
// 
function getPieChartsData(market,Submarket,Practice,pcp){
  let formData={
    TinMarket:market,
    TinSubMarket:Submarket,
    Practice:Practice,
    Pcp:pcp
  }
  return axiosRequests.post('/SDOH/GetSDOHMembersScoreDetails', formData);
  // return axiosRequests.get(
  //   `/SDOH/GetSDOHMembersScoreDetails?TinMarket=${market}&TinSubMarket=${Submarket}&Practice=${Practice}`
  // );
}

// function GetCohortMemberListView(cohortId){
//   return axiosRequests.get(
//     `/Cohort/GetCohortMemberListView?CohortDetailId=${cohortId}`
//   );
// }

function GetCohortMemberListView(formData) {
  return axiosRequests.post('/Cohort/GetCohortMemberListView', formData);
};

function getMarkets() {
  return axiosRequests.get(`/SDOH/GetMarketDetails`);
}

function GetUserConfigFilterList() {
  return axiosRequests.get(`/SDOH/GetUserConfigDetails`);
}

function GetSubMarketDetails({marketId}) {
  return axiosRequests.get(
    `/SDOH/GetSubMarketDetails?MarketId=${marketId}`
  );
}
function GetPracticeDetails({marketId,subMarketId}) {
  return axiosRequests.get(
    `/SDOH/GetPracticeDetails?MarketId=${marketId}&SubMarketId=${subMarketId}`
  );
}

function GetPracticeLazyDetails(market,Submarket,sortColumn, sortOrder,page,search) {
  return axiosRequests.get(`/SDOH/GetPracticeDetailsLazyLoading?pageNumber=${page}&rowsPerPage=10&sortColumn=${sortColumn}&sortOrder=${sortOrder}&market=${market}&Submarket=${Submarket}&SearchValue=${search}`);
}


function getStatusDetails(filter){
  return axiosRequests.get(
    `/SDOH/GetSDOHPendingMembersDetailsCount?TinMarket=${filter.marketId}&TinSubMarket=${filter.subMarketId}Practice=${filter.practiceId}`
  );
}

function exportSDOHMembersAssessmentDetails(formData) {
  return axiosRequests.downloadFilewithBody(`/SDOH/ExportSDOHMembersAssessmentDetails`, formData);
}

export const SDOHAssessmentTrackingAgent = {
  getMarkets: getMarkets,
  GetSubMarketDetails:GetSubMarketDetails,
  getMembersList:getMembersList,
  getStatusList:getStatusList,
  getChartsData:getChartsData,
  getPieChartsData:getPieChartsData,
  GetPracticeDetails:GetPracticeDetails,
  GetPracticeLazyDetails:GetPracticeLazyDetails,
  getStatusDetails:getStatusDetails,
  getMemberDetails:getMemberDetails,
  getCheckList:getCheckList,
  GetUserConfigFilterList:GetUserConfigFilterList,
  getRaceList:getRaceList,
  getEthnicityList:getEthnicityList,
  getLanguageList:getLanguageList,
  exportSDOHMembersAssessmentDetails:exportSDOHMembersAssessmentDetails,
  getCohortMemberList:getCohortMemberList,
  GetCOHORTMemberEventsList:GetCOHORTMemberEventsList,
  GetCOHORTMemberIPEventsList:GetCOHORTMemberIPEventsList,
  GetCOHORTMembersCostDetails:GetCOHORTMembersCostDetails,
  getCohortList:getCohortList,
  GetCohortMemberListView:GetCohortMemberListView,
  GetCohortMembersDetails:GetCohortMembersDetails,
  GetClinicalHeathDetails:GetClinicalHeathDetails,
  GetCohortViewDetails:GetCohortViewDetails,
  EditCOHORTMembersList:EditCOHORTMembersList,
};
