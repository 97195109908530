import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../../../app/api/Agent";

const initialState = {
  isLoading: false,
  trainingDocumentsData: [],
  errorMessage: "",
};

export const getTrainingDocumentsList = createAsyncThunk(
  "trainingDocumentsData",
  async () => {
    const response =
      await agent.HealthHomeEducationAgent.getTrainingDocumentsList();
    return response;
  }
);

const getTrainingDocumentsListSlice = createSlice({
    name: "trainingDocumentsData",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getTrainingDocumentsList.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getTrainingDocumentsList.fulfilled, (state, action) => {
          state.isLoading = false;
          state.trainingDocumentsData = action.payload;
        })
        .addCase(getTrainingDocumentsList.rejected, (state, action) => {
          state.isLoading = false;
          state.trainingDocumentsData = [];
          state.errorMessage = action.error.message;
        });
    },
  });

  export default getTrainingDocumentsListSlice.reducer;
