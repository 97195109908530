import { PagesPath } from "../../../PagesPath"
import Searchbox from "../../../component/common/searchbox/Searchbox"
import Events from "../../events/Events"
import AddIcon from "@mui/icons-material/Add";
import { Button, Stack } from "@mui/material";
import { Link } from "react-router-dom";

const EventsDisplay = () =>{
    return(
        <div sx={{p:0}}>
            <Events disableHeader={true} disableAddEvent={false}/>
        </div>
        
    )
}

export default EventsDisplay