export const Styles = {
  FONT_FAMILY_14_20: "normal normal normal 14px/20px Poppins",
  FONT_FAMILY_14_24: "normal normal normal 14px/24px Poppins",
  FONT_FAMILY_14_20_BOLD: "normal normal 600 14px/20px Poppins",
  FONT_FAMILY_18_20: "normal normal normal 18px/20px Poppins",
  FONT_COLOR: "#FFF",
  FONT_FAMILY_POPPINS: "Poppins",
  FONT_FAMILY_GREYCLIFFREG:"GreycliffCFRegular",
  FONT_FAMILY_GREYCLIFFMED:"GreycliffCFMedium",
  FONT_FAMILY_GREYCLIFFBLD:"GreycliffCFBold",
  FONT_STYLE_NORMAL: "normal",
};

export const COLOR = {
  ORANGE: "#F37021",
  FONT_ORANGE: "#DC4F34",
  FONT_BLACK: "#333",
  FONT_ORANGE_ERROR: "#D9434F"
};
