import UserRoleMappingTable from "./User Role Mapping/UserRoleMappingTable";

const UserRolesMapping = () => {
    return( 
        
                
            <UserRoleMappingTable></UserRoleMappingTable> 
        
    )
    
}

export default UserRolesMapping;