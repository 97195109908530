import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessToken: "",
};

const authorizationSlice = createSlice({
  name: "authorization",
  initialState,
  reducers: {},
});

export default authorizationSlice.reducer;
