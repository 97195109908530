import { PagesPath } from "../PagesPath";
import person from '../assets/person.svg';
import person_active from '../assets/person_active.svg';

export const UserAdminConsoleJson = {
    id: 1,
    name: "Admin Console",
    key: "UserAdminConsole",
    iconurl: person,
    activeiconurl: person_active,
    defaulturl: PagesPath.UserAdminConsoleURL,
  };