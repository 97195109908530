import { useEffect, useLayoutEffect, useState } from "react";
import { axiosRequests } from "../../../app/api/AxiosRequest";
import Loader from "../loader/Loader";
// import { useNavigate } from "react-router-dom";
import { PagesPath } from "../../../PagesPath";

const UserAuthorization = ({ children }) => {
  // const navigate = useNavigate();
  const [noAccessState, setNoAccessState] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const removeDuplicates = (array) => {
    var uniq = [...new Set(array)];
    return uniq;
  };

  const AdminRoleValue = 1;
  const PraticeAdminRoleValue = 2;
  const LearningUserRoleValue = 4;
  const HealthHomeUserRoleValue = 5;

  useLayoutEffect(() => {
    //axiosRequests
    // .get(
    //   // `/UserAccess/GetUserAccessDetails?Email=vedika.gujar.learning@gmail.com`
    //   `/UserAccess/GetUserAccessDetails?Email=nidhi.practice.admin@gmail.com`
    // )
    axiosRequests
      .get(
        `/UserAccess/GetUserAccessDetails?Email=${localStorage.getItem(
          "emailAddress"
        )}`
      )
      .then((resp) => {
        if (!resp || resp.length <= 0) {
          localStorage.setItem("noAccess", 1);
          localStorage.setItem("userFullName", "");
          localStorage.setItem("globalAdmin", 0);
          localStorage.setItem("practiceAdmin", 0);
          localStorage.setItem("learningUser", 0);
          localStorage.setItem("healthHomeUser", 0);
          setIsLoading(false);
          // setNoAccessState(true);
        } else {
          if (resp[0].isAuditor) {
            localStorage.setItem("isAuditor", 1);
          } else {
            localStorage.setItem("isAuditor", 0);
          }
          if (resp[0].hasHealthHomeAccess) {
            localStorage.setItem("hasHealthHomeAccess", 1);
          } else {
            localStorage.setItem("hasHealthHomeAccess", 0);
          }
          var roles = resp[0].roleType;
          localStorage.setItem("globalAdmin", 0);
          localStorage.setItem("noAccess", 0);
          localStorage.setItem("practiceAdmin", 0);
          localStorage.setItem("learningUser", 0);
          localStorage.setItem("healthHomeUser", 0);
          localStorage.setItem("userFullName", "");
          localStorage.setItem(
            "userFullName",
            resp[0].firstName + " " + resp[0].lastName
          );
          if (
            roles.split(",").some((item) => parseInt(item) === AdminRoleValue) // is global admin
            // .indexOf(1) >= 0
          ) {
            localStorage.setItem("globalAdmin", 1);
            localStorage.setItem("noAccess", 0);
            localStorage.setItem("practiceAdmin", 0);
            localStorage.setItem("learningUser", 0);
            localStorage.setItem("healthHomeUser", 0);
            const combinedSet = resp.reduce((prevValue, currValue) => {
              const existing = prevValue.find(
                (item) => item.id === currValue.id
              );
              if (existing) {
                existing.payerId += `,${currValue.payerId}`;
                existing.productId += `,${currValue.productId}`;
                existing.marketId += `,${currValue.marketId}`;
                existing.regionId += `,${currValue.regionId}`;
                existing.practiceId += `,${currValue.practiceId}`;
                existing.viewTools += `,${currValue.viewTools}`; //[1,2,3]
                existing.editTools += `,${currValue.editTools}`; //[2,3,4]
                existing.viewScoreCard += `,${currValue.viewScoreCard}`; //[4,5,6]
                existing.roleType += `,${currValue.roleType}`;
              } else {
                prevValue.push(currValue);
              }
              return prevValue;
            }, []);

            combinedSet[0].payerId = removeDuplicates(
              combinedSet[0].payerId.split(",")
            ).join(",");
            combinedSet[0].productId = removeDuplicates(
              combinedSet[0].productId.split(",")
            ).join(",");
            combinedSet[0].marketId = removeDuplicates(
              combinedSet[0].marketId.split(",")
            ).join(",");
            combinedSet[0].regionId = removeDuplicates(
              combinedSet[0].regionId.split(",")
            ).join(",");
            combinedSet[0].practiceId = removeDuplicates(
              combinedSet[0].practiceId.split(",")
            ).join(",");
            combinedSet[0].viewTools = removeDuplicates(
              combinedSet[0].viewTools.split(",")
            ).join(",");
            combinedSet[0].editTools = removeDuplicates(
              combinedSet[0].editTools.split(",")
            ).join(",");
            combinedSet[0].viewScoreCard = removeDuplicates(
              combinedSet[0].viewScoreCard.split(",")
            ).join(",");
            combinedSet[0].roleType = removeDuplicates(
              combinedSet[0].roleType.split(",")
            ).join(",");
            localStorage.setItem("userAccess", JSON.stringify(combinedSet[0]));
            setIsLoading(false);
          } else if (
            !roles.split(",").some((item) => parseInt(item) === AdminRoleValue) // is not global admin
            // .indexOf(1) <= -1
          ) {
            localStorage.setItem("globalAdmin", 0);
            localStorage.setItem("noAccess", 0);
            if (
              roles
                .split(",")
                .some((item) => parseInt(item) === PraticeAdminRoleValue) //is pratice admin
              //.indexOf(2) >= 0
            ) {
              localStorage.setItem("practiceAdmin", 1);
            } else if (
              roles
                .split(",")
                .some((item) => parseInt(item) === HealthHomeUserRoleValue)
            ) {
              localStorage.setItem("practiceAdmin", 0);
              localStorage.setItem("learningUser", 0);
              localStorage.setItem("healthHomeUser", 1);
            } else if (
              !roles
                .split(",")
                .some((item) => parseInt(item) === LearningUserRoleValue) //is not learning user
            ) {
              localStorage.setItem("practiceAdmin", 0);
              localStorage.setItem("learningUser", 0);
              localStorage.setItem("healthHomeUser", 0);
            } else {
              localStorage.setItem("learningUser", 1);
              localStorage.setItem("healthHomeUser", 0);
              // localStorage.setItem("noAccess", 1);
            }
            // localStorage.setItem(
            //   "userFullName",
            //   resp[0].firstName + " " + resp[0].lastName
            // );
            const combinedSet = resp.reduce((prevValue, currValue) => {
              const existing = prevValue.find(
                (item) => item.id === currValue.id
              );
              if (existing) {
                existing.payerId += `,${currValue.payerId}`;
                existing.productId += `,${currValue.productId}`;
                existing.marketId += `,${currValue.marketId}`;
                existing.regionId += `,${currValue.regionId}`;
                existing.practiceId += `,${currValue.practiceId}`;
                existing.viewTools += `,${currValue.viewTools}`; //[1,2,3]
                existing.editTools += `,${currValue.editTools}`; //[2,3,4]
                existing.viewScoreCard += `,${currValue.viewScoreCard}`; //[4,5,6]
                existing.roleType += `,${currValue.roleType}`;
              } else {
                prevValue.push(currValue);
              }
              return prevValue;
            }, []);

            combinedSet[0].payerId = removeDuplicates(
              combinedSet[0].payerId.split(",")
            ).join(",");
            combinedSet[0].productId = removeDuplicates(
              combinedSet[0].productId.split(",")
            ).join(",");
            combinedSet[0].marketId = removeDuplicates(
              combinedSet[0].marketId.split(",")
            ).join(",");
            combinedSet[0].regionId = removeDuplicates(
              combinedSet[0].regionId.split(",")
            ).join(",");
            combinedSet[0].practiceId = removeDuplicates(
              combinedSet[0].practiceId.split(",")
            ).join(",");
            combinedSet[0].viewTools = removeDuplicates(
              combinedSet[0].viewTools.split(",")
            ).join(",");
            combinedSet[0].editTools = removeDuplicates(
              combinedSet[0].editTools.split(",")
            ).join(",");
            combinedSet[0].viewScoreCard = removeDuplicates(
              combinedSet[0].viewScoreCard.split(",")
            ).join(",");
            combinedSet[0].roleType = removeDuplicates(
              combinedSet[0].roleType.split(",")
            ).join(",");
            localStorage.setItem("userAccess", JSON.stringify(combinedSet[0]));
            setIsLoading(false);

            // if (parseInt(localStorage.getItem("learningUser")) === 1)
            //   //navigate(PagesPath.Programs_URL);
            //   window.location.href = PagesPath.Programs_URL;
          }
        }
      });
  }, []);
  return (
    <div>
      {isLoading ? <Loader isLoading={isLoading} /> : <div>{children}</div>}
    </div>
  );
};

export default UserAuthorization;
