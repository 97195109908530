import React, { useEffect, useLayoutEffect, useState } from "react";
import Header from "./header/Header";
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link, NavLink, Navigate, Outlet } from "react-router-dom";
import MiniDrawer from "./miniDrawer/MiniDrawer";
import MiniDrawerAdmin from "./miniDrawer/MiniDrawerAdmin";
import NoAccessPage from "./noAccess/NoAccessPage";
import Loader from "./loader/Loader";
import { PagesPath } from "../../PagesPath";

const SevenPillarAdminLayout = () => {
  const isLearningUser = parseInt(localStorage.getItem("learningUser")) === 1;
  const [noAccess, setNoAccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useLayoutEffect(() => {
    var noAccess = localStorage.getItem("noAccess");
    if (parseInt(noAccess) === 1) {
      setNoAccess(true);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, []);
  return (
    <>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : isLearningUser ? (
        <Navigate to={PagesPath.Programs_URL} />
      ) : (
        <div>
          {noAccess ? (
            <NoAccessPage></NoAccessPage>
          ) : (
            <div>
              <MiniDrawerAdmin />
              <Box
                component="main"
                className="content"
                sx={{
                  background: "#FAFAFA 0% 0% no-repeat padding-box",
                  opacity: 1,
                  width: "100%",
                  flexGrow: 1,
                  paddingLeft: "64px",
                }}
              >
                <Toolbar sx={{ minHeight: "30px" }} />
                <Outlet></Outlet>
              </Box>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SevenPillarAdminLayout;
