
import React, { useEffect, useState, useCallback } from 'react';
import {
    Box, Grid, Stack, Typography, Button, Radio, RadioGroup, FormGroup, FormControlLabel, FormControl,
    FormLabel, Checkbox, Slider, Accordion, AccordionSummary, AccordionDetails
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppDispatch } from "../../app/store";
import { useSelector } from "react-redux";
import { Styles } from "../../Styles";
import "../sdohNewAssessmentForm/SDOHAssessmentFormCSS.css";
import Loader from "../../component/common/loader/Loader";
import { useNavigate } from "react-router-dom";
import { PagesPath } from "../../PagesPath";
import { getCheckList } from "../sdohAssessmentDashboard/slices/SDOHMembersListSlice";
import { getAssessmentArray,getShowAssessmentDetailPage,getShowDashboardPage } from "../../component/common/sdohAssessmentFilter/SDOHAssessmentFilterDetailsSlice";
const SDOHNewAssessmentForm_Step2 = (props) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    let [assessmentArr, setAssessmentArr] = useState([]);//assessmentArrary
    let { checklist } = useSelector(
        (state) => state.sdohMemberList
    );

    const { assessmentStore } = useSelector(
        (state) => state.filterDetails
    );

    useEffect(() => {
        // if( checklist && checklist.length>0)
        setAssessmentArr(checklist);
    }, [checklist])

    useEffect(() => {
        // if( checklist && checklist.length>0)
        if (assessmentStore && assessmentStore?.assessmentArr != undefined &&
            assessmentStore?.assessmentArr.length >0
        )
        setAssessmentArr(assessmentStore.assessmentArr);
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [props.isDisabledMode])

    // let [assessmentDetails, setAssessmentDetails] = useState([]);
    let [isLoading, setIsLoading] = useState(false);
    const handleSliderChange = (event, detailAssessment) => {
        let arr = [...assessmentArr];
        arr = arr.map((detail) => {
            return {
                ...detail, questions: detail?.questions.map((assessmentDtl) => {
                    if (assessmentDtl.question === detailAssessment.question) {
                        return {
                            ...assessmentDtl, selectedValue: event.target.value, isAnswerEmpty: false
                        }
                    }
                    return assessmentDtl;
                })
            };
        });
        setAssessmentArr(arr);
    }

    const handleCheckboxChange = (event, detailAssessment) => {
        let isChecked = false;
        let selectedCheckboxValue = ""
        let arr = [...assessmentArr];

        selectedCheckboxValue = ""

        if (event === "None of the above") {
            arr = arr.map((detail) => {
                return {
                    ...detail, questions: detail?.questions.map((assessmentDtl) => {
                        return {
                            ...assessmentDtl, answers: assessmentDtl?.answers.map((answers) => {
                                if (answers.answer === event) {
                                    if (answers.checked)
                                        return {
                                            ...answers, checked: false
                                        }
                                    else
                                        return {
                                            ...answers, checked: true
                                        }
                                }
                                else {
                                    return {
                                        ...answers, checked: false
                                    }
                                }
                                return answers;
                            })
                        };
                    })
                };
            });
            setAssessmentArr(arr);
        }
        else {
            arr = arr.map((detail) => {
                return {
                    ...detail, questions: detail?.questions.map((assessmentDtl) => {
                        return {
                            ...assessmentDtl, answers: assessmentDtl?.answers.map((answers) => {
                                if (answers?.answer === event) {
                                    if (answers?.checked)
                                        return {
                                            ...answers, checked: false
                                        }
                                    else
                                        return {
                                            ...answers, checked: true
                                        }
                                }
                                else {
                                    if (answers.answer === "None of the above") {
                                        return {
                                            ...answers, checked: false
                                        }
                                    }
                                }
                                return answers;
                            })

                        };

                    })
                };
            });
            setAssessmentArr(arr);
        }

        setTimeout(() => {
            let arr1 = [...arr];
            selectedCheckboxValue = ""
            isChecked = false;
            arr1 = arr1.map((detail) => {
                return {
                    ...detail, questions: detail?.questions.map((assessmentDtl) => {
                        if (assessmentDtl.type === 'Checkbox') {
                            assessmentDtl?.answers.map((answers) => {
                                if (answers.answer === event && answers?.checked) {
                                    if (answers.checked) {
                                        isChecked = true;
                                        selectedCheckboxValue = answers.answer;
                                    }
                                }
                                else if (answers?.checked) {
                                    isChecked = true;
                                }
                            })
                            return {
                                ...assessmentDtl, selectedValue: selectedCheckboxValue, isAnswerEmpty: !isChecked
                            }
                        }
                        else {
                            return {
                                ...assessmentDtl
                            }
                        }
                    })

                };
            });
            setAssessmentArr(arr1);
        }, 200)
    }


    const handleRadioChange = (event, detailAssessment) => {
        let arr = [...assessmentArr];
        arr = arr.map((detail) => {
            return {
                ...detail, questions: detail?.questions.map((assessmentDtl) => {
                    if (assessmentDtl.question === detailAssessment.question) {
                        return {
                            ...assessmentDtl, selectedValue: event, isAnswerEmpty: false
                        }
                    }
                    return assessmentDtl;
                })
            };
        });
        setAssessmentArr(arr);
    };

    const handleBack = (e) => {
        let hasError = false;
        props.onBackClick();
    }

    const handleFinishForm = (e) => {
        let hasError = false;
        let isScrollToField = false;
        let scrollToField = "";
        let isFieldRequiredError = false;

        let isChecked = false;
        let selectedCheckboxValue = "";
        let arr1 = [...assessmentArr];
            selectedCheckboxValue = null;
            isChecked = false;
            arr1 = arr1.map((detail) => {
                return {
                    ...detail, questions: detail?.questions.map((assessmentDtl) => {
                        if (assessmentDtl.type === 'Checkbox') {
                            assessmentDtl?.answers.map((answers) => {
                                if (answers?.checked) {
                                    if (answers.checked) {
                                        isChecked = true;
                                        selectedCheckboxValue = answers.answer;
                                    }
                                }
                                else if (answers?.checked) {
                                    isChecked = true;
                                }
                            })
                            return {
                                ...assessmentDtl, selectedValue: selectedCheckboxValue, isAnswerEmpty: !isChecked
                            }
                        }
                        else {
                            return {
                                ...assessmentDtl
                            }
                        }
                    })

                };
            });
            setAssessmentArr(arr1);

        //isAnswerEmpty
        let arr = [...arr1];//assessmentArr];
        let isQuestionNotFilled = true;
        arr = arr.map((detail) => {
            return {
                ...detail, questions: detail?.questions.map((assessmentDtl) => {
                    if (assessmentDtl.selectedValue == null && assessmentDtl.type != 'Drag') {
                        isFieldRequiredError = true;
                        if (!isScrollToField) {
                            scrollToField = assessmentDtl?.id;
                            isScrollToField = true;
                        }

                        return {
                            ...assessmentDtl, isAnswerEmpty: true
                        }
                    }
                    else {
                        return {
                            ...assessmentDtl, isAnswerEmpty: false
                        }
                    }
                })
            };
        });
        if (isScrollToField) {
            window.scrollTo(0, 0);
            // scrollTo(scrollToField);
        }
        setAssessmentArr(arr);
        setTimeout(() => {
            if (!isFieldRequiredError) {
                // console.log("arr",arr);
                getAssessmentArrayData(arr);
                props.onDataChange();
            }
        }, 200)

    }

    const getAssessmentArrayData = useCallback(async (assessmentArr) => {
        await dispatch(
            getAssessmentArray({ assessmentArr })
        );
    }, [dispatch]);

    const onClose = () => {
        // var idUrl = PagesPath.SDOHAssessmentDashboard_URL;
        // navigate(idUrl, { searchValue: "" });
        if(props.handleAssessmentCancel){
            props.handleAssessmentCancel();
        }
        else{
        dispatch(getShowAssessmentDetailPage(false));
        dispatch(getShowDashboardPage(true));
        }
    }

    useEffect(() => {
        setIsLoading(false);
        let assDetails = [];
        getCheckListData();
        // setAssessmentDetails(assDetails);
        window.scrollTo(0, 0);
    }, [])


    const getCheckListData = useCallback(async () => {
        await dispatch(
            getCheckList()
        );
    }, [dispatch]);

    const marks = [
        {
            value: 1,
            label: 'Never',
        },
        {
            value: 2,
            label: 'Rarely',
        },
        {
            value: 3,
            label: 'Sometimes',
        },
        {
            value: 4,
            label: 'Fairly Often',
        },
        {
            value: 5,
            label: 'Frequently',
        },
    ];

    function valuetext(value) {
        return value;//`${value}°C`;
    }

    const scrollTo = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const headerHeight = 0;
            // document.querySelector(".home-header")?.clientHeight || 0;
            const offsetTop =
                element.getBoundingClientRect().top + window.pageYOffset - headerHeight;
            window.scrollTo({
                top: offsetTop,
                behavior: "smooth",
            });
        }
    };

    return (
        <Box sx={{ p: { xs: 2, sm: 2 } }}>
            <Grid container>
                {
                    isLoading ? (
                        <Loader isLoading={isLoading} />
                    ) : (
                        <Grid item xs={12} >
                            <div style={{ padding: 10 }}>
                                {/* {assessmentLoad} */}
                                {
                                    (assessmentArr ? assessmentArr.map(detail =>
                                        <Accordion defaultExpanded
                                            key={detail.category}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1-content"
                                                id="panel1-header"

                                                sx={{
                                                    borderBottom: '1px solid rgba(0, 0, 0, .125)',
                                                    minHeight: '45px !important',
                                                    '& .Mui-expanded': {
                                                        margin: '0 !important',
                                                    },
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        textAlign: "left",
                                                        letterSpacing: "1px",
                                                        color: "#111827B8",
                                                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                        fontWeight: "500",
                                                        lineHeight: "17px",
                                                        fontSize: "14px",
                                                        paddingRight: 2

                                                    }}
                                                >
                                                    {detail.category}
                                                </Typography>
                                            </AccordionSummary>

                                            <AccordionDetails >
                                                <span>{detail?.questions.map(detailAssessment =>
                                                    <Grid container sx={{
                                                        marginTop: "10px",
                                                        border:
                                                            (detailAssessment?.isAnswerEmpty ?
                                                                "1px solid #D32F2F" : "")
                                                    }} key={detailAssessment.question}>
                                                        <FormControl sx={{ paddingTop: 2, paddingLeft: 2, }} >
                                                            <FormLabel id="demo-radio-buttons-group-label">
                                                                <Typography
                                                                    sx={{
                                                                        textAlign: "left",
                                                                        letterSpacing: "1px",
                                                                        color: "#111827B8",
                                                                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                                        fontWeight: "500",
                                                                        lineHeight: "17px",
                                                                        fontSize: "14px",
                                                                        paddingTop: 1
                                                                    }}
                                                                >
                                                                    {detailAssessment.question} <span style={{ color: '#D32F2F' }}>*</span>
                                                                </Typography>
                                                            </FormLabel>

                                                            {(detailAssessment.type == 'Radio') ? (
                                                                <RadioGroup
                                                                    className={(props.isDisabledMode) ? "disabledAccordian" : ""}
                                                                    style={{
                                                                        padding: 10,
                                                                        color: "orangeTheme",
                                                                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                                        fontWeight: "400",
                                                                        lineHeight: "24px",
                                                                        fontSize: "16px",
                                                                        letterSpacing: "0.15px",
                                                                    }}
                                                                    key={detailAssessment.question}
                                                                    // defaultValue={(detailAssessment.defaultValue != "") ? detailAssessment.defaultValue : ""}
                                                                    sx={{
                                                                        '& .Mui-checked': {
                                                                            color: '#333 !important',
                                                                        },
                                                                    }}
                                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                                    name="radio-buttons-group"
                                                                    // value={detailAssessment.selectedValue}
                                                                    //   onChange={handleRadioChange}
                                                                    onChange={(e) => handleRadioChange(e.target.value, detailAssessment)}
                                                                >
                                                                    {detailAssessment.answers.map(detailChecklist =>
                                                                        <FormControlLabel value={detailChecklist.answer}
                                                                            control={<Radio />} color="orangeTheme"
                                                                            key={detailChecklist.answer}
                                                                            label={detailChecklist.answer} />
                                                                    )}

                                                                </RadioGroup>
                                                            ) : (<span></span>)}

                                                            {(detailAssessment.type == 'Checkbox') ? (
                                                                <FormGroup
                                                                    className={(props.isDisabledMode) ? "disabledAccordian" : ""}
                                                                    style={{
                                                                        padding: 10,
                                                                        color: "#000000DE",
                                                                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                                        fontWeight: "400",
                                                                        lineHeight: "24px",
                                                                        fontSize: "16px",
                                                                        letterSpacing: "0.15px",
                                                                    }}

                                                                    sx={{
                                                                        '& .Mui-checked': {
                                                                            color: '#333 !important',
                                                                        },
                                                                    }}
                                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                                    name="radio-buttons-group"
                                                                >
                                                                    {detailAssessment.answers.map(detailChecklist =>

                                                                        <FormControlLabel value={detailChecklist.answer}
                                                                            control={<Checkbox
                                                                                style={{ fontSize: "14px" }}
                                                                                color="orangeTheme"
                                                                                checked={detailChecklist?.checked ? detailChecklist?.checked : false}
                                                                                onChange={(e) => handleCheckboxChange(e.target.value, detailAssessment)}
                                                                            />} color="orangeTheme"
                                                                            key={detailChecklist.answer} label={detailChecklist.answer}

                                                                        />
                                                                    )}

                                                                </FormGroup>
                                                            ) : (<span></span>)}

                                                            {(detailAssessment.type == 'Drag') ? (

                                                                <Box sx={{ width: "95%", padding: 2 }}>
                                                                    <Slider
                                                                        aria-label="Custom marks"
                                                                        defaultValue={0}
                                                                        color="orangeTheme"
                                                                        getAriaValueText={valuetext}
                                                                        step={1}
                                                                        min={1}
                                                                        max={5}
                                                                        valueLabelDisplay="auto"
                                                                        marks={marks}
                                                                        onChange={(e) => handleSliderChange(e, detailAssessment)}
                                                                        sx={{
                                                                            '& .MuiSlider-markLabel': {
                                                                                paddingLeft: 4,
                                                                            },
                                                                        }}
                                                                    />
                                                                </Box>
                                                            ) : <span></span>}
                                                        </FormControl>
                                                        {detailAssessment?.isAnswerEmpty ? <div style={{ fontSize: '12px', color: '#D32F2F', paddingLeft: '10px' }}><span style={{ fontWeight: 'bold' }}>{detailAssessment.question} </span>is Required</div> : null}
                                                    </Grid>
                                                )}</span>
                                            </AccordionDetails>
                                        </Accordion>

                                    ) : <span />)



                                }
                            </div>
                        </Grid>
                    )}
            </Grid>

            <Stack
                direction="column"
                sx={{
                    marginTop: 4,
                }}
            ></Stack>
            <div style={{
                display:
                    (props.isDisabledMode || !props.isButtonsReqd) ? 'none' : 'block'
            }}>
                <Grid container justifyContent="left">
                    <Grid item xs={12}>
                        <Button style={{ color: 'white', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="contained" color="orangeTheme" disableElevation onClick={handleFinishForm}>Finish And Preview</Button>
                        &nbsp;
                        <Button style={{ borderColor: 'black', color: 'black', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="outlined" onClick={handleBack}>Back</Button>
                        &nbsp;
                        <Button style={{ borderColor: 'black', color: 'black', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="outlined" onClick={onClose}>Cancel</Button>
                    </Grid>
                </Grid>
            </div>

            <div style={{
                display:
                    (!props.isDisabledMode || !props.isButtonsReqd) ? 'none' : 'block'
            }}>
                <Grid container spacing={1} justifyContent="left">
                    <Grid item xs={12}>
                        <Button style={{ color: 'white', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="contained" color="orangeTheme" disableElevation onClick={handleFinishForm}>Submit</Button>
                        &nbsp;
                        <Button style={{ borderColor: 'black', color: 'black', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="outlined" onClick={handleBack}>Back</Button>
                        &nbsp;
                        <Button style={{ borderColor: 'black', color: 'black', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="outlined" onClick={onClose}>Cancel</Button>
                    </Grid>
                </Grid>
            </div>

        </Box>
    );
}

export default SDOHNewAssessmentForm_Step2;