import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

const Loader = ({ isLoading }) => {
  return (
    <Backdrop
      invisible
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={isLoading}
    >
      <CircularProgress
        color="inherit"
        size={100}
        sx={{ color: "#11182799" }}
      ></CircularProgress>
    </Backdrop>
  );
};

export default Loader;
