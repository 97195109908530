import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import React from "react";
import "./activeGapClosuresListCSS.css";
import { FormControl, FormHelperText } from "@mui/material";
import { Styles } from "../../Styles";

const VisitDateField = ({
  visitDate,
  handleVisitDateChange,
  error,
  errorHelperText,
}) => {
  return (
    <FormControl
      required
      color="orangeTheme"
      error={error}
      style={{ width: "inherit", height: "40px" }}
      size="small"
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label="Visit Date"
          value={visitDate}
          maxDate={new Date()}
          disableFuture
          color="orangeTheme"
          slotProps={{
            textField: {
              className: "visitDateField",
              color: "orangeTheme",
            },
          }}
          onChange={handleVisitDateChange}
        />
      </LocalizationProvider>
      <FormHelperText>
        <b>{errorHelperText}</b>
      </FormHelperText>
    </FormControl>
  );
};

export default VisitDateField;
