import React, { useEffect, useState } from "react";
import { Box, Grid, Stack, Typography, FormGroup, FormControlLabel } from "@mui/material";
import { alpha, styled } from '@mui/material/styles';
import { useSelector } from "react-redux";
import { red, orange, green } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import { Styles } from "../../Styles";
import { BarChart } from '@mui/x-charts/BarChart';
import Loader from "../../component/common/loader/Loader";
const SDOHBarChart = () => {
    const [displayBarChartSection, SetDisplayBarChartSection] = useState(false);
    const [xLabels, setXLabels] = useState([]);
    const [pData, setpData] = useState([]);
    const [pDataTemp, setpDataTemp] = useState([]);
    const [uData, setuData] = useState([]);
    const [uDataTemp, setuDataTemp] = useState([]);
    const [xData, setxData] = useState([]);
    const [xDataTemp, setxDataTemp] = useState([]);

    const { isLoading, barChartData } = useSelector(
        (state) => state.filterDetails
    );
    
    const { activeStatus } = useSelector(
        (state) => state.filterDetails.activeStatus
    );

    useEffect(() => {
        if (activeStatus != undefined) {
            if (activeStatus?.category == "Completed") {
                if (barChartData && barChartData.length>0) 
                    SetDisplayBarChartSection(true);
                else
                    SetDisplayBarChartSection(false);
            }
            else {
                SetDisplayBarChartSection(false);
            }
        }
        else {
            SetDisplayBarChartSection(false);
        }
    }, [activeStatus])

    useEffect(() => {
        let xLabelsObj = [];
            let pDataObj = [];
            let uDataObj = [];
            let xDataObj = [];
        if (barChartData && barChartData.length>0) {
            barChartData.map((chart) => {
                xLabelsObj.push(chart.categoryName)
                chart?.category.map((chartData) => {
                    if (chartData && chartData?.riskName === 'High Risk')
                        pDataObj.push(chartData?.riskCount);
                    if (chartData && chartData?.riskName === 'Low Risk')
                        uDataObj.push(chartData?.riskCount)
                    if (chartData && chartData?.riskName === 'Medium Risk')
                        xDataObj.push(chartData?.riskCount)
                });
            });
            setTimeout(()=>{
                setXLabels(xLabelsObj);
                setpData(pDataObj);
                setpDataTemp(pDataObj);
                setuData(uDataObj);
                setuDataTemp(uDataObj);
                setxData(xDataObj);
                setxDataTemp(xDataObj);
            })
            if (activeStatus?.category == "Completed") {
                SetDisplayBarChartSection(true);
            }
            else{
                SetDisplayBarChartSection(false);
            }
        }
    }, [barChartData])

    const RedSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: "#D32F2F",//"#EC6B6B",//red[600],
            label: "test",
            name: "test",

            '&:hover': {
                backgroundColor: alpha("#D32F2F", theme.palette.action.hoverOpacity),
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: "#D32F2F",//red[600],
        },
    }));

    const label = { inputProps: { 'aria-label': 'Color switch demo' } };

    const OrangeSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: "#FFCD3D",//orange[600],
            '&:hover': {
                backgroundColor: alpha("#FFCD3D", theme.palette.action.hoverOpacity),
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: "#FFCD3D",//orange[600],
        },
    }));

    const GreenSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: "#59A96A",//green[600],
            '&:hover': {
                backgroundColor: alpha("#59A96A", theme.palette.action.hoverOpacity),
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: "#59A96A",//green[600],
        },
    }));

    const [checkedRed, setCheckedRed] = React.useState(true);
    const [checkedOrange, setCheckedOrange] = React.useState(true);
    const [checkedGreen, setCheckedGreen] = React.useState(true);
    const handleRedChange = (event) => {
        setCheckedRed(event.target.checked);
    };
    const handleOrangeChange = (event) => {
        setCheckedOrange(event.target.checked);
    };
    const handleGreenChange = (event) => {
        setCheckedGreen(event.target.checked);
    };
    useEffect(() => {
        if (!checkedRed) {
            setpData([]);
        }
        else {
            setpData(pDataTemp);
        }
    }, [checkedRed])

    useEffect(() => {
        if (!checkedOrange) {
            setuData([]);
        }
        else {
            setuData(uDataTemp);
        }
    }, [checkedOrange])

    useEffect(() => {
        if (!checkedGreen) {
            setxData([]);
        }
        else {
            setxData(xDataTemp);
        }
    }, [checkedGreen])

    return (
        <Box sx={{ p: { xs: 2, sm: 2 } }}>
            {isLoading ? (
                <Loader isLoading={isLoading} />
            ) : (
                <div sx={{ display: (displayBarChartSection === true) ? "" : "none" }}>
                    <Stack
                        direction="column"
                        spacing={3}
                    >
                        <Typography
                            sx={{
                                textAlign: "left",
                                letterSpacing: "1px",
                                color: "rgba(0, 0, 0, 0.87)",
                                fontFamily: Styles.FONT_FAMILY_POPPINS,
                                fontSize: "16px",
                                fontWeight: "400",
                            }}
                        >
                            SDOH Risk Analysis
                        </Typography>
                        <div style={{ height: "380px", width: "115%" }}>
                            <BarChart
                                sx={{
                                    marginLeft: "-40px",
                                    marginTop: "-10%"
                                }}
                                slotProps={{ legend: { hidden: true } }}
                                series={[
                                    { color: '#D32F2F', data: pData, label: 'At Risk', id: 'pvId', stack: 'total' }, //#D32F2F
                                    { color: '#FFCD3D', data: uData, label: 'Some Risk', id: 'xvId', stack: 'total' },
                                    { color: '#59A96A', data: xData, label: 'Not at Risk', id: 'uvId', stack: 'total' },
                                ]}
                                xAxis={[{
                                    data: xLabels, scaleType: 'band',
                                    categoryGapRatio: 0.7,
                                    textAnchor: 'middle',
                                }]}
                                leftAxis={{
                                    disableTicks: true,
                                    // labelStyle: {
                                    //     fontSize: 0,
                                    // },
                                    // tickLabelStyle: {
                                    //     fontSize: 0,
                                    // },
                                }}
                                bottomAxis={{
                                    disableTicks: true,
                                }}
                            />
                        </div>
                    </Stack>

                    <Grid container spacing={2}>
                        <Grid item xs={12} >
                            <Stack
                                direction="row"
                                spacing={3}
                                sx={{
                                    alignContent: "left",
                                    alignItems: "left",
                                    borderRadius: "4px",
                                    justifyContent: "end",
                                    marginRight: "4%",
                                    marginTop: "-10%"
                                }}
                            >
                                <FormGroup
                                    sx={{ position: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                    <FormControlLabel control={<RedSwitch {...label}
                                        checked={checkedRed}
                                        onChange={handleRedChange}
                                    />} label="At risk" labelPlacement="start" />
                                    <FormControlLabel control={<OrangeSwitch {...label}
                                        checked={checkedOrange}
                                        onChange={handleOrangeChange}
                                    />} label="Some risk" labelPlacement="start" />
                                    <FormControlLabel control={<GreenSwitch {...label}
                                        checked={checkedGreen}
                                        onChange={handleGreenChange}
                                    />} label="Not at risk" labelPlacement="start" />
                                </FormGroup>
                            </Stack>
                        </Grid>
                    </Grid>
                </div>
            )}
        </Box>

    );

}
export default SDOHBarChart;