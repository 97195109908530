import { Box, Stack } from "@mui/material";
import { useState } from "react";
import ERAndIpTextingTool from "../../assets/ERAndIpTextingTool.svg";
import ImageBlockForToolsAndScorecardsLandingPage from "../../component/common/imageBlock/ImageBlockForToolsAndScorecardsLandingPage";
import NoAccessPage from "../../component/common/noAccess/NoAccessPage";
import { checkUserToolAccess } from "../../component/common/userAuthorization/checkUserAccess";
import { ER_ALERT_CLOSURE_URL } from "../../webConfig";
import ERAlertClosureFromTextingHeader from "./ERAlertClosureFromTextingHeader";

const VerticalLine = () => {
  return (
    <div
      style={{
        borderLeft: "1px solid rgba(86, 86, 86, 0.5)",
        height: "20px",
      }}
    />
  );
};

const StackCustom = ({ children }) => {
  return (
    <Stack
      direction="row"
      spacing={2.5}
      sx={{
        alignItems: "center",
        justifyContent: "center",
        alignSelf: "stretch",
      }}
    >
      {children}
    </Stack>
  );
};

const ERAlertClosureFromTexting = () => {
  const ERAlertToolTexting = 31;
  const [accessObj] = useState(checkUserToolAccess(ERAlertToolTexting));

  return (
    <Box
      sx={{
        width: "100%",
        //p: { xs: 2, sm: 2 },
      }}
    >
      <ERAlertClosureFromTextingHeader />
      {!accessObj.hasAccess ? (
        <NoAccessPage></NoAccessPage>
      ) : (
        <Stack marginTop="31px" spacing={3}>
          <StackCustom>
            {/* <VerticalLine /> */}
            <ImageBlockForToolsAndScorecardsLandingPage
              imageWidth="37px"
              imageHeight="43px"
              img={ERAndIpTextingTool}
              text={"ER Alert Tool from Texting"}
              description="ER Discharge Auto Assessments"
              url={ER_ALERT_CLOSURE_URL.ERAlertClosure}
            />
            {/* <VerticalLine /> */}
          </StackCustom>
        </Stack>
      )}
    </Box>
  );
};

export default ERAlertClosureFromTexting;
