import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Styles } from "../../Styles";
import CloseIcon from "@mui/icons-material/Close";
import cinq_1 from "../../assets/cinq_1.png";
import { axiosRequests } from "../../app/api/AxiosRequest";

const DerosteringAuditorModal = ({
  isOpen,
  onClose,
  rowData,
  forceRefresh,
  setForceRefresh,
  auditorAction = undefined,
  setAuditorAction = undefined,
}) => {
  const [derosterStatusDropdownData, setDerosterStatusDropdownData] = useState(
    []
  );
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedStatusError, setSelectedStatusError] = useState("");
  useEffect(() => {
    if (isOpen) {
      setSelectedStatus("");
      setSelectedStatusError("");
      axiosRequests.get("/Deroster/GetDerosteringStatus").then((resp) => {
        setDerosterStatusDropdownData(resp);
      });
    }
  }, [isOpen]);

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
    setSelectedStatusError("");
  };

  const handleSubmit = () => {
    let hasError = false;
    if (!selectedStatus || selectedStatus === "") {
      setSelectedStatusError("Status is required");
      hasError = true;
    }
    if (hasError) return;
    let requestBody = {
      ourId: rowData.map((row) => parseInt(row.ourID)),
      derosteringStatusId: selectedStatus,
    };
    console.log("Request Body is ", requestBody);
    axiosRequests
      .post("/Deroster/AuditorStatusUpdate", requestBody)
      .then((resp) => {
        console.log(resp);
        setAuditorAction(true);
        setForceRefresh(forceRefresh + 1);
        onClose();
      });
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      slotProps={{ backdrop: { onClick: null } }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "678px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          pt: 1,
          height: "auto",
        }}
      >
        <h3
          style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
        >
          Bulk Status Update
          <span>
            <CloseIcon onClick={onClose} style={{ float: "right" }} />
          </span>
        </h3>
        <hr
          style={{
            width: "auto",
            opacity: "0.3",
            marginTop: "-8px",
            // marginBottom: "-4px",
          }}
        />
        <Stack direction="column" spacing={2}>
          <img
            style={{
              width: "129px",
              height: "29px",
              margin: "0 auto",
              display: "block",
            }}
            src={cinq_1}
          ></img>
          <Typography
            style={{
              color: "rgba(17, 24, 39, 0.72)",
              fontFamily: Styles.FONT_FAMILY_POPPINS,
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "18px",
            }}
          >
            Are you sure you want to change the state for below members?
          </Typography>
          <List
            style={{
              maxHeight: "60px",
              overflowY: "scroll",
              marginTop: "3px",
            }}
          >
            {rowData.map((row, index) => (
              <ListItem key={index}>
                <ListItemText
                  style={{ marginTop: index === 0 ? "-20px" : "-20px" }}
                >
                  <b>{index + 1 + ". " + row.patientName}</b>
                </ListItemText>
              </ListItem>
            ))}
          </List>
          <FormControl
            required
            color="orangeTheme"
            sx={{ width: "inherit", width: "303px" }}
            size="small"
            error={!!selectedStatusError}
          >
            <InputLabel id="facility-name-select-small-label">
              De-roster Status
            </InputLabel>

            <Select
              required
              value={selectedStatus}
              onChange={(e) => handleStatusChange(e)}
              labelId="facility-name-select-small-label"
              id="facility-name-select-small"
              label="De-roster Status"
            >
              {derosterStatusDropdownData.map((status, index) => (
                <MenuItem key={index} value={status.statusId}>
                  {status.statusDescription}
                </MenuItem>
              ))}
            </Select>

            <FormHelperText>{selectedStatusError}</FormHelperText>
          </FormControl>
          <hr
            style={{
              width: "auto",
              opacity: "0.3",
            }}
          />
          <Stack direction="row" spacing={2} style={{ marginLeft: "auto" }}>
            <Button
              style={{
                textTransform: "none",
                borderColor: "black",
                color: "black",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
              }}
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              style={{
                textTransform: "none",
                color: "white",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
              }}
              onClick={handleSubmit}
              variant="contained"
              color="orangeTheme"
              disableElevation
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default DerosteringAuditorModal;
