import { Box, Typography } from "@mui/material";
import NoAccessImage from "../../../assets/NoAccessImage.png"

const NoAccessPage = () => {
    return (
        <Box sx={{ width: "100%", p: { xs: 2, sm: 2 } }}>
            <center>
            <Typography style={{fontSize: '34px', alignItems: 'center'}}>
                Oops ! You Don’t Have Access To This Page
            </Typography>
            <img src={NoAccessImage} alt="No Access"></img>
            </center>
        </Box>
    )
}

export default NoAccessPage;