import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  List,
  IconButton,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import cinq_1 from "../../assets/cinq_1.png";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Styles } from "../../Styles";
import VisitDateField from "./VisitDateField";
import { useEffect, useState } from "react";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { format } from "date-fns";
import { axiosRequests } from "../../app/api/AxiosRequest";
import SearchCodes from "./SearchCodes";

const SDOHCodingSuspectsStateUpdate = ({
  isOpen,
  onClose,
  rowId,
  title,
  ourId,
  forceUpdate,
  setForceUpdate,
  rows = [],
  memberDetail,
  payerName,
}) => {
  const [selectedSDOHCodingSuspectState, setSelectedSDOHCodingSuspectState] =
    useState("");
  const [showCommentsSection, setShowCommentsSection] = useState(false);
  const [icd10Codes, setIcd10Codes] = useState([]);
  const [selectedValueError, setSelectedValueError] = useState("");
  const [isDisclaimerChecked, setIsDisclaimerChecked] = useState(false);
  const [disclaimerError, setDisclaimerError] = useState("");
  const [comments, setComments] = useState("");
  const [visitDate, setVisitDate] = useState(new Date());
  const [visitDateError, setVisitDateError] = useState("");
  const [commentsError, setCommentsError] = useState("");
  const handleSDOHCodingSuspectStateChange = (e) => {
    setSelectedSDOHCodingSuspectState(e.target.value);
    setSelectedValueError("");
    if (e.target.value != "") {
      setShowCommentsSection(true);
    }
  };

  const handleVisitDateChange = (e) => {
    setVisitDate(e);
    setVisitDateError("");
  };
  const handleCommentsChange = (e) => {
    setComments(e.target.value);
    setCommentsError("");
  };
  const handleDisclaimerCheckChange = (e) => {
    setIsDisclaimerChecked(e.target.checked);
    setDisclaimerError("");
  };
  const handleSave = () => {
    let hasError = false;
    if (
      !selectedSDOHCodingSuspectState ||
      (selectedSDOHCodingSuspectState && selectedSDOHCodingSuspectState === "")
    ) {
      setSelectedValueError("Current state is required.");
      hasError = true;
    }
    if (!comments || comments === "") {
      setCommentsError("Comment is required");
      hasError = true;
    }
    if (!isDisclaimerChecked) {
      setDisclaimerError("Please accept this checkbox before proceeding");
      hasError = true;
    }
    if (visitDate && visitDate > new Date()) {
      setVisitDateError("Invalid Visit Date");
      hasError = true;
    }
    if (hasError) return;
    let rowIDsToUpdate = [];
    if (rowId) rowIDsToUpdate.push(rowId);
    else if (rows && rows.length > 0) {
      let rowIDArray = rows.map((item) => item.id);
      rowIDsToUpdate.push(...rowIDArray);
    }
    var formData = new FormData();
    rowIDsToUpdate.forEach((id) => {
      formData.append("SDOHCodingSuspectIds", id);
    });
    formData.append("ourId", ourId);
    formData.append("visitDate", format(visitDate, "yyyy/MM/dd"));

    formData.append("comments", comments);
    formData.append("Icd10Codes", icd10Codes.join(","));
    formData.append("PayorName", payerName);
    if (selectedSDOHCodingSuspectState.toLowerCase() === "deny") {
      formData.append("status", "Reject");
      axiosRequests
        .post("ActiveGapClosure/RejectStatusSDOHCodingSuspect", formData)
        .then((resp) => {
          // setForceUpdate(forceUpdate + 1);
          if (resp) {
            setForceUpdate(forceUpdate + 1);
            onClose();
          }
        });
    } else {
      formData.append("status", "Accept");
      axiosRequests
        .post("ActiveGapClosure/AcceptStatusSDOHCodingSuspect", formData)
        .then((resp) => {
          // setForceUpdate(forceUpdate + 1);
          if (resp) {
            setForceUpdate(forceUpdate + 1);
            onClose();
          }
        });
    }
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      slotProps={{ backdrop: { onClick: null } }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "678px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          pt: 1,
          height: "auto",
        }}
      >
        <h3
          style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
        >
          SDOH Recapture Codes State Change{" "}
          <span>
            <CloseIcon onClick={onClose} style={{ float: "right" }} />
          </span>
        </h3>
        <hr
          style={{
            width: "auto",
            opacity: "0.3",
            marginTop: "-8px",
            // marginBottom: "-4px",
          }}
        />
        <Stack direction="column" spacing={2}>
          <img
            style={{
              width: "129px",
              height: "29px",
              margin: "0 auto",
              display: "block",
            }}
            src={cinq_1}
          ></img>
          {!rows ||
            (rows.length < 2 && (
              <Typography>
                Are you sure you want to change the state for SDOH Coding
                Suspect - “{title ? title : rows[0].sdohCode}”?
              </Typography>
            ))}
          {rows && rows.length > 1 && (
            <>
              <Typography>
                Are you sure you want to change the states for below SDOH coding
                suspects?
              </Typography>
              <List
                style={{
                  maxHeight: "60px",
                  overflowY: "scroll",
                  marginTop: "3px",
                }}
              >
                {rows.map((row, index) => (
                  <ListItem key={row.id}>
                    <ListItemText
                      style={{ marginTop: index === 0 ? "-20px" : "-20px" }}
                    >
                      <b>{row.sdohCode}</b>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </>
          )}
          <Stack
            direction="row"
            spacing={2}
            style={{
              width: "99%",
              marginTop: rows && rows.length > 0 ? "12px" : "",
            }}
          >
            <FormControl
              required
              color="orangeTheme"
              sx={{ width: "inherit" }}
              size="small"
              error={!!selectedValueError}
            >
              <InputLabel id="facility-name-select-small-label">
                SDOH Coding Suspect State
              </InputLabel>

              <Select
                required
                value={selectedSDOHCodingSuspectState}
                onChange={(e) => handleSDOHCodingSuspectStateChange(e)}
                labelId="facility-name-select-small-label"
                id="facility-name-select-small"
                label="SDOH Coding Suspect State"
              >
                <MenuItem value="Accept">Accept</MenuItem>
                <MenuItem value="Deny">Reject</MenuItem>
              </Select>
              {selectedValueError && (
                <FormHelperText>{selectedValueError}</FormHelperText>
              )}
            </FormControl>
            <VisitDateField
              error={!!visitDateError}
              errorHelperText={visitDateError}
              visitDate={visitDate}
              handleVisitDateChange={handleVisitDateChange}
            />
          </Stack>
          {showCommentsSection && (
            <Stack direction="column" spacing={2}>
              <TextField
                style={{
                  width: "auto !important",
                }}
                id="outlined-multiline-static"
                label="Comments"
                value={comments}
                error={!!commentsError}
                helperText={commentsError}
                onChange={(e) => handleCommentsChange(e)}
                multiline
                className="denyComments"
                color="orangeTheme"
                rows={3}
                variant="outlined"
              />
              {selectedSDOHCodingSuspectState.toLowerCase() === "deny" && (
                <SearchCodes
                  memberDetail={memberDetail}
                  payerName={payerName}
                  setCodesArray={setIcd10Codes}
                  source={"sdohGrid"}
                />
              )}
              <Typography>
                <b>Date:</b> {format(new Date(), "dd MMM yyyy")}
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={isDisclaimerChecked}
                      color="orangeTheme"
                      onChange={(e) => handleDisclaimerCheckChange(e)}
                    />
                  }
                  label="By clicking here, I declare that the documents added
            are true and accurate to the best of my knowledge, information and
            belief."
                />
              </FormGroup>
              {disclaimerError && (
                <FormHelperText style={{ color: "#DC4f3b" }}>
                  <b>{disclaimerError}</b>
                </FormHelperText>
              )}
            </Stack>
          )}
          <hr
            style={{
              width: "auto",
              opacity: "0.3",
            }}
          />
          <Stack direction="row" spacing={2} style={{ marginLeft: "auto" }}>
            <Button
              style={{
                textTransform: "none",
                borderColor: "black",
                color: "black",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
              }}
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              style={{
                textTransform: "none",
                color: "white",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
              }}
              onClick={handleSave}
              variant="contained"
              color="orangeTheme"
              disableElevation
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default SDOHCodingSuspectsStateUpdate;
