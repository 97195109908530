import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  Typography,
  FormControl,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  getShowAssessmentDetailPage,
  getShowDashboardPage,
} from "../../component/common/sdohAssessmentFilter/SDOHAssessmentFilterDetailsSlice";
import AssessmentCompleteStep from "../../assets/AssessmentCompleteStep.svg";
import { axiosRequests } from "../../app/api/AxiosRequest";
import { Styles } from "../../Styles";
import { useAppDispatch } from "../../app/store";
import {
  getRaceList,
  getEthnicityList,
  getLanguageList,
} from "../sdohAssessmentDashboard/slices/SDOHMembersListSlice";
import SelectOutlined from "../../component/common/select/SelectOutlined";
import PopupConfirmationModal from "../sdohAssessmentDashboard/popupConfirmationModal";
const SDOHNewAssessmentForm_Step1_About = (props) => {
  const dispatch = useAppDispatch();
  const [oldContactNumber, setOldContactNumber] = React.useState("");
  const [oldEmailAddress, setOldEmailAddress] = React.useState("");
  const [oldRaceId, setOldRaceId] = React.useState("");
  const [raceId, setRaceId] = React.useState("");
  const [raceIdErrorMessage, setRaceIdErrorMessage] = React.useState("");
  const [oldEthnicityId, setOldEthnicityId] = React.useState("");
  const [ethnicityId, setEthnicityId] = React.useState("");
  const [ethnicityIdErrorMessage, setEthnicityIdErrorMessage] =
    React.useState("");
  const [oldLanguageId, setOldLanguageId] = React.useState("");
  const [languageId, setLanguageId] = React.useState("");
  const [languageIdErrorMessage, setLanguageIdErrorMessage] =
    React.useState("");

  const { memberDetail } = useSelector((state) => state.filterDetails);

  const { isLoading, raceList, ethnicityList, languageList, errorMessage } =
    useSelector((state) => state.sdohMemberList);

  const getRaceListData = useCallback(async () => {
    await dispatch(getRaceList());
  }, [dispatch]);

  useEffect(() => {
    getRaceListData();
  }, [getRaceListData]);

  const getEthnicityListData = useCallback(async () => {
    await dispatch(getEthnicityList());
  }, [dispatch]);

  useEffect(() => {
    getEthnicityListData();
  }, [getEthnicityListData]);

  const getLanguageListData = useCallback(async () => {
    await dispatch(getLanguageList());
  }, [dispatch]);

  useEffect(() => {
    getLanguageListData();
  }, [getLanguageListData]);

  const [message, setMessage] = useState("");
  const [information, setInformation] = useState("");
  const [popupConfirmationModalOpen, SetPopupConfirmationModalOpen] = useState(false);
  const [popupConfirmationModalData, setPopupConfirmationModalData] = useState({});
  const [memberName, setMemberName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const phoneRegex = /\b\d{10}\b/;
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [email, setEmail] = React.useState("");

  const [isLanguageEmpty, setIsLanguageEmpty] = useState();
  const [isEthnicityIdEmpty, setIsEthnicityIdEmpty] = useState();
  const [isRaceIdEmpty, setIsRaceIdEmpty] = useState();
  const onClose = () => {
    if (props.handleAssessmentCancel) {
      props.handleAssessmentCancel();
    }
    else {
      dispatch(getShowAssessmentDetailPage(false));
      dispatch(getShowDashboardPage(true));
    }
  };

  useEffect(() => {
    axiosRequests
      .get(
        `/SDOH/GetMemberEmailID?ourId=${props.ourId ? props.ourId : memberDetail.ourId
        }`
      )
      .then((resp) => {
        if (resp != undefined) {
          console.log("resp", resp);
          setMemberName(resp?.memberName);
          setContactNumber(resp?.contactNumber);
          setEmailAddress(resp?.emailAddress);
          setEmail(resp?.emailAddress);
          setOldContactNumber(resp?.contactNumber);
          setOldEmailAddress(resp?.emailAddress);
          setOldRaceId(resp?.race);
          setOldEthnicityId(resp?.ethnicity);
          setOldLanguageId(resp?.language);
        }
      });
  }, [memberDetail]);

  const handleDecline = () => {
    let hasError = false;
    if ((raceId == undefined || raceId == "") && (oldRaceId == undefined || oldRaceId == "")) {
      hasError = true;
      setIsRaceIdEmpty(true);
    }
    if ((ethnicityId == undefined || ethnicityId == "") && (oldEthnicityId == undefined || oldEthnicityId == "")) {
      hasError = true;
      setIsEthnicityIdEmpty(true);
    }
    if ((languageId == undefined || languageId == "") && (oldLanguageId == undefined || oldLanguageId == "")) {
      hasError = true;
      setIsLanguageEmpty(true);
    }

    if (!hasError) {
      if (memberDetail.ourId != undefined) {
        setMessage(`Are you sure to decline SDOH Assessment?`);
        setInformation(`Decline SDOH Confirmation`);
        setMemberName(memberDetail?.memberName);
        setPopupConfirmationModalData(memberDetail);
        SetPopupConfirmationModalOpen(true);
      }
    }
  }

  function closePopupModal() {
    SetPopupConfirmationModalOpen(false);
  }

  const handleDeclineCall = () => {
    SetPopupConfirmationModalOpen(false);
    const formData = {
      id: -1,
      OurId: popupConfirmationModalData?.ourId,
      DeclineMode: "Offline",
      DeclineComments: "",
      formStatus: props.formStatus
    };

    axiosRequests.post(`/SDOH/DeclineSDOHMember`, formData).then((resp) => {
      setTimeout(() => {
        window.location.reload();
      }, 200)
    })
  }

  const handleStartAssessment = (e) => {
    let hasError = false;
    if ((raceId == undefined || raceId == "") && (oldRaceId == undefined || oldRaceId == "")) {
      hasError = true;
      setIsRaceIdEmpty(true);
    }
    if ((ethnicityId == undefined || ethnicityId == "") && (oldEthnicityId == undefined || oldEthnicityId == "")) {
      hasError = true;
      setIsEthnicityIdEmpty(true);
    }
    if ((languageId == undefined || languageId == "") && (oldLanguageId == undefined || oldLanguageId == "")) {
      hasError = true;
      setIsLanguageEmpty(true);
    }

    if (!hasError) {
      const formData = {
        OurId: (props.ourId) ? props.ourId : memberDetail.ourId,
        oldContactNumber: oldContactNumber,
        oldEmailAddress: oldEmailAddress,
        OldRace: oldRaceId,
        OldEthnicity: oldEthnicityId,
        OldLanguage: oldLanguageId,

        ContactNumber: contactNumber,
        EmailAddress: emailAddress,
        Race: raceId,
        Ethnicity: ethnicityId,
        Language: languageId
      };

      axiosRequests.post(`/SDOH/UpdateSDOHMemberDemographyDetail`, formData).then((resp) => {
        console.log("demography-resp", resp);
        props.onDataChange();
      })
    }

  }

  useEffect(() => {
    if (ethnicityId != "") {
      setIsEthnicityIdEmpty(false);
    }
  }, [ethnicityId])

  useEffect(() => {
    if (raceId != "") {
      setIsRaceIdEmpty(false);
    }
  }, [raceId])

  useEffect(() => {
    if (languageId != "") {
      setIsLanguageEmpty(false);
    }
  }, [languageId])

  const [emailError, setEmailError] = React.useState("");
  const [contactNumberError, setContactNumberError] = React.useState("");

  const handleContactNumberChange = (e) => {
    let hasError = false;
    setContactNumber(e.target.value);
    setContactNumberError("");
    if (!phoneRegex.test(contactNumber)) {
      setContactNumberError("Contact Number is Invalid");
      hasError = true;
    }
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailAddress(e.target.value);
    setEmailError("");
    let hasError = false;
    if (!emailRegex.test(email)) {
      setEmailError("Email is Invalid");
      hasError = true;
    }
  };
  return (
    // <Grid container style={{ justifyContent: 'center' }}>
    //     <Grid item xs={9} sx={{
    //         height: "450px",
    //         border: "1px solid rgba(0,0,0,.12)",
    //         backgroundColor: "white",//#F8F8F8;//#C6D2BE;
    //         marginTop: "0.7%",
    //     }}>
    <Box sx={{
      p: { xs: 2, sm: 2 }
    }}>
      <Grid container sx={{ marginTop: 2 }} style={{ justifyContent: 'center' }}>
        <Grid item xs={9}
          sx={{
            minHeight: "100%",
            marginLeft: "1%",
            boxShadow: "0px 3px 46px 0px rgba(0, 0, 0, 0.12)",
            border: "1px solid rgba(0,0,0,.12)",
            backgroundColor: "white",
          }}>
          <Box sx={{ p: { xs: 2, sm: 2 } }}>
            <h3
              style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
            >
              Member Details
            </h3>
            <Stack spacing={2} sx={{ marginTop: "20px" }}>
              <Grid container spacing={2}>
                {/* <Grid item xs={4.5}>
                                    <Typography sx={{ fontSize: "14px" }}>
                                        <b>First Name :</b> {memberDetail.firstName}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4.5}>
                                    <Typography sx={{ fontSize: "14px" }}>
                                        <b>Last Name :</b> {memberDetail.lastName}
                                    </Typography>
                                </Grid> */}
                <Grid item xs={12}>
                  <Typography sx={{ fontSize: "14px" }}>
                    <b>Name :</b> {(memberName) ? memberName : memberDetail.memberName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={4.5}>
                  <Typography sx={{ fontSize: "14px" }}>
                    <b>Phone :</b> {contactNumber}
                  </Typography>
                </Grid>
                <Grid item xs={4.5}>
                  <Typography sx={{ fontSize: "14px" }}>
                    <b>Email :</b> {emailAddress}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={4.5}>
                  <Typography sx={{ fontSize: "14px" }}>
                    <b>Race :</b> {oldRaceId}
                  </Typography>
                </Grid>
                <Grid item xs={4.5}>
                  <Typography sx={{ fontSize: "14px" }}>
                    <b>Ethnicity :</b> {oldEthnicityId}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={4.5}>
                  <Typography sx={{ fontSize: "14px" }}>
                    <b>Language :</b> {oldLanguageId}
                  </Typography>
                </Grid>
              </Grid>

              {/* <Grid container spacing={2}>
                <Grid item xs={5}>
                  <TextField
                    required
                    label="Email"
                    id="outlined-size-small"
                    onChange={handleEmailChange}
                    helperText={emailError}
                    error={!!emailError}
                    value={email}
                    style={{
                      width: "100%",
                      height: "39px",
                      borderRadius: "6px",
                      backgroundColor: "#fff",
                    }}
                    color="orangeTheme"
                    size="small"
                  />
                </Grid>

                                <Grid item xs={5}>
                                    <TextField
                                        label="Contact Number"
                                        id="outlined-size-small"
                                        onChange={handleContactNumberChange}
                                        helperText={contactNumberError}
                                        error={!!contactNumberError}
                                        value={contactNumber}
                                        style={{
                                            width: "100%",
                                            height: "39px",
                                            borderRadius: "6px",
                                            backgroundColor: "#fff",
                                        }}
                                        color="orangeTheme"
                                        size="small"
                                    />
                                </Grid>
                            </Grid>  */}
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  {/* <SelectOutlined
                                        isLoading={isLoading}
                                        id="race"
                                        label="Race"
                                        value={raceId}
                                        errorText={raceIdErrorMessage}
                                        onChange={(newValue) => {
                                            setRaceId(newValue.target.value);
                                        }}
                                        listData={raceList.map((data) => ({
                                            id: data.id,
                                            value: data.description,
                                        }))}
                                        sx={{ width: "610px" }}
                                    /> */}
                  <FormControl
                    error={raceIdErrorMessage}
                    color="orangeTheme"
                    sx={{ minWidth: "100%", maxWidth: "100%" }}
                    size="small"
                  >
                    <InputLabel id="race_label">Race</InputLabel>
                    <Select
                      defaultValue=""
                      required
                      value={raceId}
                      disabled={isLoading}
                      labelId="race_label"
                      id="race"
                      onChange={(newValue) => {
                        setRaceId(newValue.target.value);
                      }}
                      label="Race"
                      sx={{
                        width: "100%",
                        border:
                          (isRaceIdEmpty ?
                            "1px solid #D32F2F" : "")
                      }}
                    >
                      {isLoading ? (
                        <MenuItem value="">
                          <CircularProgress size={24} />
                        </MenuItem>
                      ) : null}
                      <MenuItem
                        key={0}
                        value={'I choose not to Answer'}
                        name={0}
                      >
                        I choose not to Answer
                      </MenuItem>
                      {raceList.map((item) => (

                        <MenuItem
                          key={item.id}
                          value={item.description}
                          name={item.id}
                          sx={{
                            display:
                              (item.description === 'I choose not to Answer') ? 'none' : 'block'
                          }}
                        >
                          {item.description}
                        </MenuItem>
                      ))}
                    </Select>
                    {isRaceIdEmpty ? <div style={{ fontSize: '12px', color: '#D32F2F', paddingLeft: '10px' }}><span style={{ fontWeight: 'bold' }}>Race </span>is Required</div> : null}
                    {/* {errorText ? <FormHelperText>{errorText}</FormHelperText> : null} */}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  {/* <SelectOutlined
                                        isLoading={isLoading}
                                        id="ethnicity"
                                        label="Ethnicity"
                                        value={ethnicityId}
                                        errorText={ethnicityIdErrorMessage}
                                        onChange={(newValue) => {
                                            setEthnicityId(newValue.target.value);
                                        }}
                                        listData={ethnicityList.map((data) => ({
                                            id: data.id,
                                            value: data.description,
                                        }))}
                                        sx={{ width: "610px" }}
                                    /> */}
                  <FormControl
                    error={ethnicityIdErrorMessage}
                    color="orangeTheme"
                    sx={{ minWidth: "100%", maxWidth: "100%" }}
                    size="small"
                  >
                    <InputLabel id="ethnicity_label">Ethnicity</InputLabel>
                    <Select
                      defaultValue=""
                      required
                      value={ethnicityId}
                      disabled={isLoading}
                      labelId="ethnicity_label"
                      id="ethnicity"
                      onChange={(newValue) => {
                        setEthnicityId(newValue.target.value);
                      }}
                      label="Ethnicity"
                      sx={{
                        width: "100%",
                        border:
                          (isEthnicityIdEmpty ?
                            "1px solid #D32F2F" : "")
                      }}
                    >
                      {isLoading ? (
                        <MenuItem value="">
                          <CircularProgress size={24} />
                        </MenuItem>
                      ) : null}
                      <MenuItem
                        key={0}
                        value={'I choose not to Answer'}
                        name={0}

                      >
                        I choose not to Answer
                      </MenuItem>
                      {ethnicityList.map((item) => (
                        <MenuItem
                          key={item.id}
                          value={item.description}
                          name={item.id}
                          sx={{
                            display:
                              (item.description === 'I choose not to Answer') ? 'none' : 'block'
                          }}
                        >
                          {item.description}
                        </MenuItem>
                      ))}
                    </Select>
                    {isEthnicityIdEmpty ? <div style={{ fontSize: '12px', color: '#D32F2F', paddingLeft: '10px' }}><span style={{ fontWeight: 'bold' }}>Ethnicity </span>is Required</div> : null}
                    {/* {errorText ? <FormHelperText>{errorText}</FormHelperText> : null} */}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  {/* <SelectOutlined
                                        isLoading={isLoading}
                                        id="language"
                                        label="Language"
                                        value={languageId}
                                        errorText={languageIdErrorMessage}
                                        onChange={(newValue) => {
                                            setLanguageId(newValue.target.value);
                                        }}
                                        listData={languageList.map((data) => ({
                                            id: data.id,
                                            value: data.description,
                                        }))}
                                        sx={{ width: "610px" }}
                                    /> */}

                  <FormControl
                    error={languageIdErrorMessage}
                    color="orangeTheme"
                    sx={{ minWidth: "100%", maxWidth: "100%" }}
                    size="small"
                  >
                    <InputLabel id="language_label">Language</InputLabel>
                    <Select
                      defaultValue=""
                      required
                      value={languageId}
                      disabled={isLoading}
                      labelId="language_label"
                      id="language"
                      onChange={(newValue) => {
                        setLanguageId(newValue.target.value);
                      }}
                      label="Language"
                      sx={{
                        width: "100%",
                        border:
                          (isLanguageEmpty ?
                            "1px solid #D32F2F" : "")
                      }}
                    >
                      {isLoading ? (
                        <MenuItem value="">
                          <CircularProgress size={24} />
                        </MenuItem>
                      ) : null}
                      <MenuItem
                        key={0}
                        value={'I choose not to Answer'}
                        name={0}
                      >
                        I choose not to Answer
                      </MenuItem>
                      {languageList.map((item) => (
                        <MenuItem key={item.id} value={item.description} name={item.id}
                          sx={{
                            display:
                              (item.description === 'I choose not to Answer') ? 'none' : 'block'
                          }}>
                          {item.description}
                        </MenuItem>
                      ))}
                    </Select>
                    {isLanguageEmpty ? <div style={{ fontSize: '12px', color: '#D32F2F', paddingLeft: '10px' }}><span style={{ fontWeight: 'bold' }}>Language </span>is Required</div> : null}
                    {/* {errorText ? <FormHelperText>{errorText}</FormHelperText> : null} */}
                  </FormControl>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Stack
        direction="column"
        sx={{
          marginTop: 4,
        }}
      ></Stack>
      <div>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Stack direction="row" spacing={.5}>
              <Button style={{ color: 'white', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="contained" color="orangeTheme" disableElevation onClick={handleStartAssessment}>Start Assessment</Button>
              &nbsp;
              {/* <Button style={{
                borderColor: 'rgb(243, 112, 33)', color: 'black', fontFamily: Styles.FONT_FAMILY_POPPINS,
                display:
                      (props.formStatus == 'Declined') ? 'none' : 'block'
              }}
                variant="outlined" onClick={handleDecline}>Decline</Button>
              &nbsp; */}
              <Button style={{ borderColor: 'black', color: 'black', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="outlined" onClick={onClose}>Cancel</Button>
            </Stack>
          </Grid>
        </Grid>
      </div>
      <PopupConfirmationModal
        isOpen={popupConfirmationModalOpen}
        onClose={closePopupModal}
        message={message}
        rowData={popupConfirmationModalData}
        memberName={memberName}
        information={information}
        handleDecline={handleDeclineCall}
      ></PopupConfirmationModal>
    </Box>

  );
}
export default SDOHNewAssessmentForm_Step1_About;
