import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button, Grid } from "@mui/material";
import Tab from "@mui/material/Tab";
import React, { useEffect, useRef, useState } from "react";
import { Styles } from "../../Styles";
import { axiosRequests } from "../../app/api/AxiosRequest";
import Loader from "../../component/common/loader/Loader";
import BannerSettingsHeader from "./BannerSettingsHeader";
import NoAccessPage from "../../component/common/noAccess/NoAccessPage";
// import BannerContent from "./BannerContent";

const BannerComponent = () => {
  //const fs = require('fs');
  const [contentEdit, setContentEdit] = useState(false);
  const divRef = useRef(null);
  const [value, setValue] = React.useState("1");
  const [showSave, setShowSave] = React.useState(false);
  const [showEdit, setShowEdit] = React.useState(true);
  const [bannerText, setBannerText] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isGlobalAdmin, setIsGlobalAdmin] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEditClick = () => {
    setShowSave(true);
    setShowEdit(false);
    setContentEdit(true);
    setTimeout(() => {
      divRef.current.focus();
    }, 10);
  };

  useEffect(() => {
    axiosRequests.get("/Banner").then((resp) => {
      setBannerText(resp);
      setIsLoading(false);
    });
  }, []);
  useEffect(() => {
    var globalAdmin = localStorage.getItem("globalAdmin");
    if (parseInt(globalAdmin) === 1) {
      setIsGlobalAdmin(true);
    }
  }, []);

  const handleSaveClick = () => {
    var bannerText;
    var divContent;
    setShowEdit(true);
    setShowSave(false);
    setContentEdit(false);

    setTimeout(() => {
      divContent = divRef.current.innerText;
      bannerText = divContent.split("\n");
      bannerText = bannerText.filter((item) => item !== "");
      axiosRequests.post("/Banner", bannerText).then((resp) => {
        console.log(resp);
      });
    }, 10);
  };

  const handleCancelClick = () => {
    // setIsEditing(false);
    setShowEdit(true);
    setShowSave(false);
    setContentEdit(false);
  };

  return (
    <Box sx={{ width: "100%", p: { xs: 2, sm: 2 } }}>
      <BannerSettingsHeader></BannerSettingsHeader>
      {!isGlobalAdmin ? (
        <NoAccessPage></NoAccessPage>
      ) : (
        <TabContext value={value}>
          <div>
            <Box>
              <TabList
                value={value}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#dc4f34",
                  },
                }}
                onChange={handleChange}
              >
                <Tab
                  label="Banner Message"
                  value="1"
                  className={
                    value === "1"
                      ? "custom-tab-class Mui-selected"
                      : "custom-tab-class"
                  }
                  sx={{
                    textAlign: "left",
                    letterSpacing: "1px",
                    color: "#000",
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                  }}
                />
              </TabList>
            </Box>
          </div>
          {isLoading ? (
            <Loader isLoading={isLoading} />
          ) : (
            <TabPanel value="1">
              Banner Messages<sup>*</sup>
              <div style={{ width: "1226px", height: "450px" }}>
                <div
                  style={{
                    border: "1px solid black",
                    width: "inherit",
                    height: "inherit",
                  }}
                  className={contentEdit ? "editable editableDiv" : ""}
                  ref={divRef}
                  contentEditable={contentEdit}
                  suppressContentEditableWarning={true}
                >
                  <ul>
                    {bannerText.map((name, index) => {
                      return <li key={index}>{name}</li>;
                    })}
                  </ul>
                </div>
              </div>
              {showEdit && (
                <Grid
                  container
                  spacing={2}
                  style={{ marginLeft: "-10px", marginTop: "0px" }}
                >
                  <Grid item xs={9}></Grid>
                  <Grid item xs={1}>
                    {/* <Button style={{ borderColor: 'black', color: 'black', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="outlined" >Cancel</Button> */}
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      style={{
                        color: "white",
                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                        textTransform: "none",
                      }}
                      variant="contained"
                      color="orangeTheme"
                      onClick={handleEditClick}
                      disableElevation
                    >
                      Edit
                    </Button>
                  </Grid>
                </Grid>
              )}
              {showSave && (
                <Grid
                  container
                  spacing={2}
                  style={{ marginLeft: "-10px", marginTop: "0px" }}
                >
                  <Grid item xs={9}></Grid>
                  <Grid item xs={1}>
                    <Button
                      style={{
                        borderColor: "black",
                        color: "black",
                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                        textTransform: "none",
                      }}
                      variant="outlined"
                      onClick={handleCancelClick}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      style={{
                        color: "white",
                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                        textTransform: "none",
                      }}
                      variant="contained"
                      color="orangeTheme"
                      onClick={handleSaveClick}
                      disableElevation
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              )}
            </TabPanel>
          )}
        </TabContext>
      )}
    </Box>
  );
};

export default BannerComponent;
