import { Check } from "@mui/icons-material";
import { Alert, Snackbar } from "@mui/material";
import React from "react";

const SnackbarAlert = ({ message, open, handleClose }) => {
  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        key={"bottom" + "right"}
      >
        <Alert
          icon={<Check fontSize="inherit" />}
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SnackbarAlert;
