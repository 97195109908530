import { PagesPath } from "../PagesPath";
// import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
// import settings_active from '@mui/icons-material/Settings';
import settings from "../assets/settings.svg";
import settings_active from "../assets/settings_active.svg";

export const BannerConfigJson = {
  id: 2,
  name: "Banner Configuration",
  key: "BannerSetting",
  iconurl: settings,
  activeiconurl: settings_active,
  defaulturl: PagesPath.BannerComponentURL,
};
