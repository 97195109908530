import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/Agent";

const initialState = {
  isLoading: false,
  derosteringTrackingList: [],
  derosteringTrackingListCount: 0,
  errorMessage: "",
};

export const getDerosteringTrackingList = createAsyncThunk(
  "derosteringTrackingList",
  async ({ search, sortColumn, sortOrder, page }) => {
    var response = {};
    response = await agent.DerosteringTrackingAgent.getDerosteringTracking(
      search,
      sortColumn,
      sortOrder,
      page
    );
    // response.list = await agent.DerosteringTrackingAgent.getDerosteringTracking(
    //   search,
    //   sortColumn,
    //   sortOrder,
    //   page
    // );
    // response.count =
    //   await agent.DerosteringTrackingAgent.getDerosteringTrackingCount(search);
     return response;
  }
);

const derosteringTrackingSlice = createSlice({
  name: "derosteringTracking",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDerosteringTrackingList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getDerosteringTrackingList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.derosteringTrackingList = action.payload.list;
        state.derosteringTrackingListCount = action.payload.count;
      })
      .addCase(getDerosteringTrackingList.rejected, (state, action) => {
        state.isLoading = false;
        state.derosteringTrackingList = [];
        state.derosteringTrackingListCount = 0;
        state.errorMessage = action.error.message;
      });
  },
});

export default derosteringTrackingSlice.reducer;
