import React, { useEffect, useState, useCallback } from "react";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./RoleMappingFormCSS.css";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { axiosRequests } from "../../../app/api/AxiosRequest";
import { PagesPath } from "../../../PagesPath";
import { Styles } from "../../../Styles";
import AddUserFormHeader from "../Users/AddUserFormHeader";
import Loader from "../../../component/common/loader/Loader";

const RoleMappingForm = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [roleNameDropdownData, setRoleNameDropdownData] = useState([]);
  const [isLoadingRoleName, setIsLoadingRoleName] = useState(true);
  const [selectedRoleName, setSelectedRoleName] = useState("");
  const [roleNameErrorText, setRoleNameErrorText] = useState("");

  const [payerNameDropdownData, setPayerNameDropdownData] = useState([]);
  const [selectedPayerName, setSelectedPayerName] = useState([]);
  const [isLoadingPayerName, setIsLoadingPayerName] = useState(true);
  const [payerNameErrorText, setPayerNameErrorText] = useState("");

  const [productNameDropdownData, setProductNameDropdownData] = useState([]);
  const [selectedProductName, setSelectedProductName] = useState([]);
  const [isLoadingProductName, setIsLoadingProductName] = useState(true);
  const [productNameErrorText, setProductNameErrorText] = useState("");

  const [marketRegionData, setMarketRegionData] = useState([]);

  const [marketDropdownData, setMarketDropdownData] = useState([]);
  const [selectedMarketName, setSelectedMarketName] = useState([]);
  const [isLoadingMarketName, setIsLoadingMarketName] = useState(true);
  const [marketNameErrorText, setMarketNameErrorText] = useState("");

  const [regionDropdownData, setRegionDropdownData] = useState([]);
  const [selectedRegionName, setSelectedRegionName] = useState([]);
  const [isLoadingRegionName, setIsLoadingRegionName] = useState(true);
  const [regionNameErrorText, setRegionNameErrorText] = useState("");

  const [practiceNameDropdownData, setPracticeNameDropdownData] = useState([]);
  const [selectedPracticeName, setSelectedPracticeName] = useState([]);
  const [isLoadingPracticeName, setIsLoadingPracticeName] = useState(true);
  const [practiceNameErrorText, setPracticeNameErrorText] = useState("");

  const [toolsScoreCardData, setToolsScorecardData] = useState([]);
  const [expanded, setExpanded] = useState(null);

  const [toolsViewAccess, setToolsViewAccess] = useState([]);
  const [toolsEditAccess, setToolsEditAccess] = useState([]);
  const [scorecardViewAccess, setScorecardViewAccess] = useState([]);

  const [isPracticeAdmin, setIsPracticeAdmin] = useState(false);

  const [payerAccessList, setPayerAccessList] = useState(
    JSON.parse(localStorage.getItem("userAccess"))
      ? JSON.parse(localStorage.getItem("userAccess")).payerId
      : ""
  );

  const [productAccessList, setProductAccessList] = useState(
    JSON.parse(localStorage.getItem("userAccess"))
      ? JSON.parse(localStorage.getItem("userAccess")).productId
      : ""
  );
  const [marketAccessList, setMarketAccessList] = useState(
    JSON.parse(localStorage.getItem("userAccess"))
      ? JSON.parse(localStorage.getItem("userAccess")).marketId
      : ""
  );
  const [regionAccessList, setRegionAccessList] = useState(
    JSON.parse(localStorage.getItem("userAccess"))
      ? JSON.parse(localStorage.getItem("userAccess")).regionId
      : ""
  );
  const [practiceAccessList, setPracticeAccessList] = useState(
    JSON.parse(localStorage.getItem("userAccess"))
      ? JSON.parse(localStorage.getItem("userAccess")).practiceId
      : ""
  );

  var hasError = false;

  let { state } = useLocation();

  const navigate = useNavigate();

  const isAllPayersSelected =
    payerNameDropdownData.length > 0 &&
    selectedPayerName.length === payerNameDropdownData.length;

  const isAllProductsSelected =
    productNameDropdownData.length > 0 &&
    selectedProductName.length === productNameDropdownData.length;
  const isAllMarketsSelected =
    marketDropdownData.length > 0 &&
    selectedMarketName.length === marketDropdownData.length;
  const isAllRegionsSelected =
    regionDropdownData.length > 0 &&
    selectedRegionName.length === regionDropdownData.length;
  const isAllPracticesSelected =
    practiceNameDropdownData.length > 0 &&
    selectedPracticeName.length === practiceNameDropdownData.length;
  const practiceAdminRole = JSON.parse(localStorage.getItem("userAccess"))
    ? parseInt(JSON.parse(localStorage.getItem("userAccess")).roleType) === 2
      ? true
      : false
    : false;

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("userAccess"))) {
      var practiceAdmin = JSON.parse(
        localStorage.getItem("userAccess")
      ).roleType;
      if (parseInt(practiceAdmin) === 2) {
        setIsPracticeAdmin(true);
      }
    }
  }, []);
  useEffect(() => {
    if (isPracticeAdmin || practiceAdminRole) {
      setPayerAccessList(
        JSON.parse(localStorage.getItem("userAccess")).payerId
      );
      setProductAccessList(
        JSON.parse(localStorage.getItem("userAccess")).productId
      );
      setMarketAccessList(
        JSON.parse(localStorage.getItem("userAccess")).marketId
      );
      setRegionAccessList(
        JSON.parse(localStorage.getItem("userAccess")).regionId
      );
      setPracticeAccessList(
        JSON.parse(localStorage.getItem("userAccess")).practiceId
      );
    }
  }, [isPracticeAdmin]);

  useEffect(() => {
    axiosRequests.get("/RoleMapping/GetRoleMappingList").then((resp) => {
      if (isPracticeAdmin || practiceAdminRole) {
        resp = resp.filter(
          (item) =>
            (parseInt(item.practiceAdminID) ===
              JSON.parse(localStorage.getItem("userAccess")).id &&
              parseInt(item.roleType) === 3) ||
            parseInt(item.roleType) === 2
        );
      }
      setRoleNameDropdownData(
        resp.filter((item) => parseInt(item.roleId) !== 1)
      );
      setIsLoadingRoleName(false);
    });
  }, [isPracticeAdmin]);

  useEffect(() => {
    axiosRequests.get("/RoleMapping/GetPayerMappingList").then((resp) => {
      if (isPracticeAdmin || practiceAdminRole) {
        const payerAccessListArray = payerAccessList.split(",").map(Number);
        resp = resp.filter((item) =>
          payerAccessListArray.includes(item.payerId)
        );
      }
      setPayerNameDropdownData(resp);
      setIsLoadingPayerName(false);
    });
  }, [payerAccessList]);

  useEffect(() => {
    axiosRequests
      .get("/RoleMapping/GetToolsandScorecardMapping")
      .then((resp) => {
        setToolsScorecardData(resp);
        setIsLoading(false);
      });
  }, []);

  const handleRoleNameChange = (e) => {
    setSelectedRoleName(e.target.value);
    checkIfRoleNameExists(e.target.value);
  };

  const checkIfRoleNameExists = (roleId) => {
    if (state.mappedRoleIDs) {
      if (state.mappedRoleIDs.indexOf(roleId) > -1) {
        setRoleNameErrorText("Role Mapping already exists");
        hasError = true;
      } else {
        setRoleNameErrorText("");
      }
    }
  };

  const getProductList = (payerId) => {
    axiosRequests
      .get(`/RoleMapping/GetProductMappingList?PayerId=${payerId.join(",")}`)
      .then((resp) => {
        if (isPracticeAdmin || practiceAdminRole) {
          const productAccessListArray = productAccessList
            .split(",")
            .map(Number);
          resp = resp.filter((item) =>
            productAccessListArray.includes(item.productId)
          );
        }
        setProductNameDropdownData(resp);
        var IDs = resp.map((item) => item.productId);
        var selectedProduct = selectedProductName.filter((item) =>
          IDs.includes(item)
        );
        setSelectedProductName(selectedProduct);
        if (selectedProduct && selectedProduct.length > 0) {
          getMarketList(payerId, selectedProduct);
          if (selectedRegionName && selectedRegionName.length > 0) {
            getPracticeList(
              payerId,
              selectedProduct,
              selectedMarketName,
              selectedRegionName
            );
          } else {
            // setSelectedRegionName([]);
            // setSelectedPracticeName([]);
            setPracticeNameDropdownData([]);
            setIsLoadingPracticeName(true);
          }
        } else {
          setRegionDropdownData([]);
          setIsLoadingRegionName(true);
          setSelectedRegionName([]);
          setPracticeNameDropdownData([]);
          setIsLoadingPracticeName(true);
          setSelectedPracticeName([]);
        }
      });
  };

  const handlePayerNameChange = (e) => {
    var value = e.target.value;
    if (value[value.length - 1] === "all") {
      var payerIDs = payerNameDropdownData.map((item) => item.payerId);
      var selectedValues =
        selectedPayerName.length === payerNameDropdownData.length
          ? []
          : payerNameDropdownData.map((item) => item.payerId);
      setSelectedPayerName(selectedValues);
      if (selectedValues && selectedValues.length > 0) {
        setIsLoadingProductName(false);
        getProductList(payerIDs);
      } else {
        setProductNameDropdownData([]);
        setMarketDropdownData([]);
        setSelectedMarketName([]);
        setRegionDropdownData([]);
        setSelectedRegionName([]);
        setPracticeNameDropdownData([]);
        setSelectedProductName([]);
        setSelectedPracticeName([]);
        setIsLoadingProductName(true);
        setIsLoadingMarketName(true);
        setIsLoadingRegionName(true);
        setIsLoadingPracticeName(true);
      }
      return;
    }
    setSelectedPayerName(typeof value === "string" ? value.split(",") : value);
    if (value && value.length > 0) {
      setIsLoadingProductName(false);
      getProductList(value);
    } else {
      setProductNameDropdownData([]);
      setMarketDropdownData([]);
      setSelectedMarketName([]);
      setRegionDropdownData([]);
      setSelectedRegionName([]);
      setPracticeNameDropdownData([]);
      setSelectedPracticeName([]);
      setSelectedProductName([]);
      setIsLoadingProductName(true);
      setIsLoadingMarketName(true);
      setIsLoadingRegionName(true);
      setIsLoadingPracticeName(true);
    }
    setPayerNameErrorText("");
  };

  const getMarketList = (payerId, productId) => {
    axiosRequests
      .get(
        `/RoleMapping/GetMarketMappingList?PayerId=${payerId.join(
          ","
        )}&ProductId=${productId.join(",")}`
      )
      .then((resp) => {
        if (isPracticeAdmin || practiceAdminRole) {
          const marketAccessListArray = marketAccessList.split(",").map(Number);
          resp = resp.filter((item) =>
            marketAccessListArray.includes(item.marketId)
          );
        }
        setMarketDropdownData(resp);
        var IDs = resp.map((item) => item.marketId);
        var selected = selectedMarketName.filter((item) => IDs.includes(item));
        setSelectedMarketName(selected);
        if (selected && selected.length > 0) {
          getRegionList(payerId, productId, selected);
          if (selectedRegionName && selectedRegionName.length > 0) {
            getPracticeList(payerId, productId, selected, selectedRegionName);
          } else {
            // setSelectedRegionName([]);
            // setSelectedPracticeName([]);
            setPracticeNameDropdownData([]);
            setIsLoadingPracticeName(true);
          }
        } else {
          setRegionDropdownData([]);
          setIsLoadingRegionName(true);
          setSelectedRegionName([]);
          setPracticeNameDropdownData([]);
          setIsLoadingPracticeName(true);
          setSelectedPracticeName([]);
        }
      });
  };

  const handleProductNameChange = (e) => {
    var value = e.target.value;
    if (value[value.length - 1] === "all") {
      var productIDs = productNameDropdownData.map((item) => item.productId);
      var selectedValues =
        selectedProductName.length === productNameDropdownData.length
          ? []
          : productNameDropdownData.map((item) => item.productId);
      setSelectedProductName(selectedValues);
      if (selectedValues && selectedValues.length > 0) {
        setIsLoadingMarketName(false);
        getMarketList(selectedPayerName, productIDs);
      } else {
        setMarketDropdownData([]);
        setSelectedMarketName([]);
        setRegionDropdownData([]);
        setSelectedRegionName([]);
        setPracticeNameDropdownData([]);
        setSelectedPracticeName([]);
        setIsLoadingMarketName(true);
        setIsLoadingRegionName(true);
        setIsLoadingPracticeName(true);
      }

      return;
    }
    setSelectedProductName(
      typeof value === "string" ? value.split(",") : value
    );
    if (value && value.length > 0) {
      setIsLoadingMarketName(false);
      getMarketList(selectedPayerName, value);
    } else {
      setMarketDropdownData([]);
      setSelectedMarketName([]);
      setRegionDropdownData([]);
      setSelectedRegionName([]);
      setPracticeNameDropdownData([]);
      setSelectedPracticeName([]);
      setIsLoadingMarketName(true);
      setIsLoadingRegionName(true);
      setIsLoadingPracticeName(true);
    }

    setProductNameErrorText("");
  };

  const getRegionList = (payerId, productId, marketId) => {
    axiosRequests
      .get(
        `/RoleMapping/GetSubMarketMappingList?PayerId=${payerId.join(
          ","
        )}&ProductId=${productId.join(",")}&MarketId=${marketId}`
      )
      .then((resp) => {
        if (isPracticeAdmin || practiceAdminRole) {
          const regionAccessListArray = regionAccessList.split(",").map(Number);
          resp = resp.filter((item) =>
            regionAccessListArray.includes(item.subMarketId)
          );
        }
        setRegionDropdownData(resp);
        var IDs = resp.map((item) => item.subMarketId);
        var selected = selectedRegionName.filter((item) => IDs.includes(item));
        setSelectedRegionName(selected);
        if (selected && selected.length > 0) {
          getPracticeList(payerId, productId, selectedMarketName, selected);
        } else {
          setPracticeNameDropdownData([]);
          setIsLoadingPracticeName(true);
          setSelectedPracticeName([]);
        }
      });
  };
  const handleMarketNameChange = (e) => {
    var value = e.target.value;
    if (value[value.length - 1] === "all") {
      var marketIDs = marketDropdownData.map((item) => item.marketId);
      var selectedValues =
        selectedMarketName.length === marketDropdownData.length
          ? []
          : marketDropdownData.map((item) => item.marketId);
      setSelectedMarketName(selectedValues);
      if (selectedValues && selectedValues.length > 0) {
        setIsLoadingRegionName(false);
        getRegionList(selectedPayerName, selectedProductName, marketIDs);
      } else {
        setRegionDropdownData([]);
        setSelectedRegionName([]);
        setIsLoadingRegionName(true);
        setPracticeNameDropdownData([]);
        setSelectedPracticeName([]);
        setIsLoadingPracticeName(true);
      }

      return;
    }
    setSelectedMarketName(typeof value === "string" ? value.split(",") : value);
    if (value && value.length > 0) {
      setIsLoadingRegionName(false);
      getRegionList(selectedPayerName, selectedProductName, value);
    } else {
      setRegionDropdownData([]);
      setSelectedRegionName([]);
      setIsLoadingRegionName(true);
      setPracticeNameDropdownData([]);
      setSelectedPracticeName([]);
      setIsLoadingPracticeName(true);
    }

    setMarketNameErrorText("");
  };

  const handlePracticeNameChange = (e) => {
    var value = e.target.value;
    if (value[value.length - 1] === "all") {
      setSelectedPracticeName(
        selectedPracticeName.length === practiceNameDropdownData.length
          ? []
          : practiceNameDropdownData.map((item) => item.practiceId)
      );
      return;
    }
    setSelectedPracticeName(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
    setPracticeNameErrorText("");
  };

  const getPracticeList = (payerId, productId, marketId, regionId) => {
    axiosRequests
      .get(
        `/RoleMapping/GetPracticeMappingList?PayerId=${payerId.join(
          ","
        )}&ProductId=${productId.join(",")}&MarketId=${marketId.join(
          ","
        )}&RegionId=${regionId.join(",")}`
      )
      .then((resp) => {
        if (isPracticeAdmin || practiceAdminRole) {
          const practiceAccessListArray = practiceAccessList
            .split(",")
            .map(Number);
          resp = resp.filter((item) =>
            practiceAccessListArray.includes(item.practiceId)
          );
        }
        setPracticeNameDropdownData(resp);
        var IDs = resp.map((item) => item.practiceId);
        var selected = selectedPracticeName.filter((item) =>
          IDs.includes(item)
        );
        setSelectedPracticeName(selected);
      });
  };

  const handleRegionNameChange = (e) => {
    var value = e.target.value;
    if (value[value.length - 1] === "all") {
      var regionIDs = regionDropdownData.map((item) => item.subMarketId);
      var selectedValues =
        selectedRegionName.length === regionDropdownData.length
          ? []
          : regionDropdownData.map((item) => item.subMarketId);
      setSelectedRegionName(selectedValues);
      if (selectedValues && selectedValues.length > 0) {
        getPracticeList(
          selectedPayerName,
          selectedProductName,
          selectedMarketName,
          regionIDs
        );
        setIsLoadingPracticeName(false);
      } else {
        setSelectedPracticeName([]);
        setPracticeNameDropdownData([]);
        setIsLoadingPracticeName(true);
      }

      return;
    }
    setSelectedRegionName(typeof value === "string" ? value.split(",") : value);
    if (value && value.length > 0) {
      getPracticeList(
        selectedPayerName,
        selectedProductName,
        selectedMarketName,
        value
      );
      setIsLoadingPracticeName(false);
    } else {
      setPracticeNameDropdownData([]);
      setSelectedPracticeName([]);
      setPracticeNameDropdownData([]);
    }

    setPracticeNameErrorText("");
  };

  useEffect(() => {
    if (state && state.value) {
      setSelectedRoleName(state.value[0].roleID);
      setSelectedPayerName(
        state.value[0].payerId.split(",").map(function (x) {
          return parseInt(x, 10);
        })
      );

      var productName = state.value[0].productId.split(",").map(function (x) {
        return parseInt(x, 10);
      });

      axiosRequests
        .get(
          `/RoleMapping/GetProductMappingList?PayerId=${state.value[0].payerId}`
        )
        .then((resp) => {
          if (isPracticeAdmin || practiceAdminRole) {
            const productAccessListArray = productAccessList
              .split(",")
              .map(Number);
            resp = resp.filter((item) =>
              productAccessListArray.includes(item.productId)
            );
          }
          setProductNameDropdownData(resp);
          setSelectedProductName(productName);
          setIsLoadingProductName(false);
        });

      var marketName = state.value[0].marketId.split(",").map(function (x) {
        return parseInt(x, 10);
      });

      axiosRequests
        .get(
          `/RoleMapping/GetMarketMappingList?PayerId=${state.value[0].payerId}&ProductId=${state.value[0].productId}`
        )
        .then((resp) => {
          if (isPracticeAdmin || practiceAdminRole) {
            const marketAccessListArray = marketAccessList
              .split(",")
              .map(Number);
            resp = resp.filter((item) =>
              marketAccessListArray.includes(item.marketId)
            );
          }
          setMarketDropdownData(resp);
          setSelectedMarketName(marketName);
          setIsLoadingMarketName(false);
        });

      var regionName = state.value[0].regionId.split(",").map(function (x) {
        return parseInt(x, 10);
      });

      axiosRequests
        .get(
          `/RoleMapping/GetSubMarketMappingList?PayerId=${state.value[0].payerId}&ProductId=${state.value[0].productId}&MarketId=${state.value[0].marketId}`
        )
        .then((resp) => {
          if (isPracticeAdmin || practiceAdminRole) {
            const regionAccessListArray = regionAccessList
              .split(",")
              .map(Number);
            resp = resp.filter((item) =>
              regionAccessListArray.includes(item.subMarketId)
            );
          }
          setRegionDropdownData(resp);
          setSelectedRegionName(regionName);
          setIsLoadingRegionName(false);
        });

      var practiceName = state.value[0].practiceId.split(",").map(function (x) {
        return parseInt(x, 10);
      });

      axiosRequests
        .get(
          `/RoleMapping/GetPracticeMappingList?PayerId=${state.value[0].payerId}&ProductId=${state.value[0].productId}&MarketId=${state.value[0].marketId}&RegionId=${state.value[0].regionId}`
        )
        .then((resp) => {
          if (isPracticeAdmin || practiceAdminRole) {
            const practiceAccessListArray = practiceAccessList
              .split(",")
              .map(Number);
            resp = resp.filter((item) =>
              practiceAccessListArray.includes(item.practiceId)
            );
          }
          setPracticeNameDropdownData(resp);
          setSelectedPracticeName(practiceName);
          setIsLoadingPracticeName(false);
        });

      if (state.value[0].viewTools && state.value[0].viewTools !== "") {
        setToolsViewAccess(
          state.value[0].viewTools.split(",").map(function (x) {
            return parseInt(x, 10);
          })
        );
      } else {
        setToolsViewAccess([]);
      }

      if (state.value[0].editTools && state.value[0].editTools !== "") {
        setToolsEditAccess(
          state.value[0].editTools.split(",").map(function (x) {
            return parseInt(x, 10);
          })
        );
      } else {
        setToolsEditAccess([]);
      }

      if (
        state.value[0].viewScorecards &&
        state.value[0].viewScorecards != ""
      ) {
        setScorecardViewAccess(
          state.value[0].viewScorecards.split(",").map(function (x) {
            return parseInt(x, 10);
          })
        );
      } else {
        setScorecardViewAccess([]);
      }
    }
  }, []);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const handleToolsViewToggleChange = (pillarId, toolId, checked) => {
    if (checked) {
      setToolsViewAccess([...toolsViewAccess, toolId]);
    } else {
      setToolsViewAccess(toolsViewAccess.filter((item) => !(item === toolId)));
    }
  };

  const handleToolsEditToggleChange = (pillarId, toolId, checked) => {
    if (checked) {
      setToolsEditAccess([...toolsEditAccess, toolId]);
    } else {
      setToolsEditAccess(toolsEditAccess.filter((item) => !(item === toolId)));
    }
  };

  const handleScorecardViewToggleChange = (pillarId, scorecardId, checked) => {
    if (checked) {
      setScorecardViewAccess([...scorecardViewAccess, scorecardId]);
    } else {
      setScorecardViewAccess(
        scorecardViewAccess.filter((item) => !(item === scorecardId))
      );
    }
  };

  const handleFormSubmit = () => {
    //let hasError = false;

    if (!selectedRoleName) {
      setRoleNameErrorText("Role Name is Required");
      hasError = true;
    }

    if (!selectedProductName || selectedProductName.length <= 0) {
      setProductNameErrorText("Product Name is Required");
      hasError = true;
    }

    if (!selectedMarketName || selectedMarketName.length <= 0) {
      setMarketNameErrorText("Market Name is Required");
      hasError = true;
    }

    if (!selectedRegionName || selectedRegionName.length <= 0) {
      setRegionNameErrorText("Region Name is Required");
      hasError = true;
    }

    if (!selectedPracticeName || selectedPracticeName.length <= 0) {
      setPracticeNameErrorText("Practice Name is Required");
      hasError = true;
    }
    if (roleNameErrorText) {
      hasError = true;
    }

    if (hasError) return;

    const formData = {
      RoleId: selectedRoleName,
      PayerId: selectedPayerName,
      ProductId: selectedProductName,
      marketId: selectedMarketName,
      regionId: selectedRegionName,
      practiceId: selectedPracticeName,
      viewTools: toolsViewAccess,
      editTools: toolsEditAccess,
      viewScorecards: scorecardViewAccess,
    };

    axiosRequests
      .post("/RoleMapping/AddUpdateRoleMapping", formData)
      .then((resp) => {
        if (resp === 1) {
          navigate(PagesPath.UserAdminConsoleURL, { state: { value: "3" } });
        }
      });
  };
  const handleBackClick = () => {
    navigate(PagesPath.UserAdminConsoleURL, { state: { value: "3" } });
  };
  return (
    <div>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <Box sx={{ width: "100%", p: { xs: 2, sm: 2 } }}>
          <AddUserFormHeader pageTitle={"Role Mapping"}></AddUserFormHeader>
          <Stack direction="row" spacing={1}>
            <ArrowBackOutlinedIcon
              onClick={() => handleBackClick()}
              style={{
                fontSize: "25px",
                fontWeight: "600",
                marginTop: "2px",
                width: "32px",
              }}
            />
            <Typography style={{ fontSize: "20px", fontWeight: "600" }}>
              Add Role Mappings
            </Typography>
          </Stack>
          <div
            style={{
              width: "1270px",
              height: "auto",
              background: "rgba(226, 226, 226, 0.30)",
              //backgroundColor: "#E2E2E2",
              padding: "16px",
            }}
          >
            <Grid container spacing={2} style={{ marginBottom: "16px" }}>
              <Grid item xs={6}>
                <FormControl
                  required
                  color="orangeTheme"
                  sx={{
                    maxWidth: "303px",
                    minWidth: "303px",
                    backgroundColor: "#fff",
                    borderRadius: "6px",
                  }}
                  disabled={isLoadingRoleName}
                  size="small"
                  error={!!roleNameErrorText}
                >
                  <InputLabel id="role-name-select-small-label">
                    Role Name
                  </InputLabel>

                  <Select
                    required
                    value={selectedRoleName}
                    className="roleNameDropdown"
                    labelId="role-name-select-small-label"
                    id="role-name-select-small"
                    onChange={(e) => handleRoleNameChange(e)}
                    label="Select Role"
                  >
                    {roleNameDropdownData.map((item) => (
                      <MenuItem
                        className="dropdownText"
                        key={item.roleId}
                        value={item.roleId}
                      >
                        {item.roleName}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText style={{ height: "0px" }}>
                    {roleNameErrorText}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Typography
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "rgba(17, 24, 39, 0.72)",
                padding: "10px",
              }}
            >
              Payer, Product, Market, Region Mapping (Select One or More Payer,
              Product, Market, Region)
            </Typography>
            <Grid container spacing={2} style={{ marginBottom: "16px" }}>
              <Grid item xs={3}>
                <FormControl
                  className="spacingClass"
                  required
                  color="orangeTheme"
                  sx={{
                    maxWidth: "303px",
                    minWidth: "303px",
                    backgroundColor: "#fff",
                    borderRadius: "6px",
                  }}
                  error={!!payerNameErrorText}
                  disabled={isLoadingPayerName}
                  size="small"
                >
                  <InputLabel id="product-name-select-small-label">
                    Payer Name
                  </InputLabel>

                  <Select
                    required
                    multiple
                    value={selectedPayerName}
                    className="payerNameDropdown"
                    labelId="payer-name-select-small-label"
                    id="payer-name-select-small"
                    onChange={(e) => handlePayerNameChange(e)}
                    renderValue={(selected) =>
                      `${selected.length} Payers Selected`
                    }
                    label="Select Payer"
                  >
                    <MenuItem value="all">
                      <Checkbox
                        color="orangeTheme"
                        checked={isAllPayersSelected}
                        indeterminate={
                          selectedPayerName.length > 0 &&
                          selectedPayerName.length <
                            payerNameDropdownData.length
                        }
                      />

                      <ListItemText primary="Select All">
                        Select All{" "}
                      </ListItemText>
                    </MenuItem>
                    {payerNameDropdownData.map((item) => (
                      <MenuItem
                        className="dropdownText"
                        key={item.payerId}
                        value={item.payerId}
                      >
                        <Checkbox
                          style={{ fontSize: "14px" }}
                          color="orangeTheme"
                          checked={selectedPayerName.indexOf(item.payerId) > -1}
                        />
                        <ListItemText
                          style={{ fontSize: "14px" }}
                          primary={item.payerName}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText style={{ height: "0px" }}>
                    {payerNameErrorText}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl
                  className="spacingClass"
                  required
                  color="orangeTheme"
                  sx={{
                    maxWidth: "303px",
                    minWidth: "303px",
                    backgroundColor: "#fff",
                    borderRadius: "6px",
                  }}
                  error={!!productNameErrorText}
                  disabled={isLoadingProductName}
                  size="small"
                >
                  <InputLabel id="product-name-select-small-label">
                    Product Name
                  </InputLabel>

                  <Select
                    required
                    multiple
                    value={selectedProductName}
                    className="productNameDropdown"
                    labelId="product-name-select-small-label"
                    id="product-name-select-small"
                    onChange={(e) => handleProductNameChange(e)}
                    renderValue={(selected) =>
                      `${selected.length} Products Selected`
                    }
                    label="Select Product"
                  >
                    <MenuItem value="all">
                      <Checkbox
                        color="orangeTheme"
                        checked={isAllProductsSelected}
                        indeterminate={
                          selectedProductName.length > 0 &&
                          selectedProductName.length <
                            productNameDropdownData.length
                        }
                      />

                      <ListItemText primary="Select All">
                        Select All{" "}
                      </ListItemText>
                    </MenuItem>
                    {productNameDropdownData.map((item) => (
                      <MenuItem
                        className="dropdownText"
                        key={item.productId}
                        value={item.productId}
                      >
                        <Checkbox
                          style={{ fontSize: "14px" }}
                          color="orangeTheme"
                          checked={
                            selectedProductName.indexOf(item.productId) > -1
                          }
                        />
                        <ListItemText
                          style={{ fontSize: "14px" }}
                          primary={item.productName}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText style={{ height: "0px" }}>
                    {productNameErrorText}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: "16px" }}>
              <Grid item xs={3}>
                <FormControl
                  className="spacingClass"
                  required
                  color="orangeTheme"
                  error={!!marketNameErrorText}
                  sx={{
                    maxWidth: "303px",
                    minWidth: "303px",
                    backgroundColor: "#fff",
                    borderRadius: "6px",
                  }}
                  disabled={isLoadingMarketName}
                  size="small"
                >
                  <InputLabel id="market-name-select-small-label">
                    Market Name
                  </InputLabel>

                  <Select
                    required
                    multiple
                    value={selectedMarketName}
                    className="marketNameDropdown"
                    labelId="market-name-select-small-label"
                    id="market-name-select-small"
                    onChange={(e) => handleMarketNameChange(e)}
                    renderValue={(selected) =>
                      `${selected.length} Markets Selected`
                    }
                    label="Select Market"
                  >
                    <MenuItem value="all">
                      <Checkbox
                        color="orangeTheme"
                        checked={isAllMarketsSelected}
                        indeterminate={
                          selectedMarketName.length > 0 &&
                          selectedMarketName.length < marketDropdownData.length
                        }
                      />

                      <ListItemText primary="Select All">
                        Select All{" "}
                      </ListItemText>
                    </MenuItem>
                    {marketDropdownData.map((item, index) => (
                      <MenuItem
                        className="dropdownText"
                        key={index}
                        value={item.marketId}
                      >
                        <Checkbox
                          style={{ fontSize: "14px" }}
                          color="orangeTheme"
                          checked={
                            selectedMarketName.indexOf(item.marketId) > -1
                          }
                        />
                        <ListItemText
                          style={{ fontSize: "14px" }}
                          primary={item.marketName}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText style={{ height: "0px" }}>
                    {marketNameErrorText}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl
                  className="spacingClass"
                  required
                  error={!!regionNameErrorText}
                  color="orangeTheme"
                  sx={{
                    maxWidth: "303px",
                    minWidth: "303px",
                    backgroundColor: "#fff",
                    borderRadius: "6px",
                  }}
                  disabled={isLoadingRegionName}
                  size="small"
                >
                  <InputLabel id="region-name-select-small-label">
                    Region Name
                  </InputLabel>

                  <Select
                    required
                    multiple
                    value={selectedRegionName}
                    className="regionNameDropdown"
                    labelId="region-name-select-small-label"
                    id="region-name-select-small"
                    onChange={(e) => handleRegionNameChange(e)}
                    renderValue={(selected) =>
                      `${selected.length} Regions Selected`
                    }
                    label="Select Region"
                  >
                    <MenuItem value="all">
                      <Checkbox
                        color="orangeTheme"
                        checked={isAllRegionsSelected}
                        indeterminate={
                          selectedRegionName.length > 0 &&
                          selectedRegionName.length < regionDropdownData.length
                        }
                      />

                      <ListItemText primary="Select All">
                        Select All{" "}
                      </ListItemText>
                    </MenuItem>
                    {regionDropdownData.map((item) => (
                      <MenuItem
                        className="dropdownText"
                        key={item.subMarketId}
                        value={item.subMarketId}
                      >
                        <Checkbox
                          style={{ fontSize: "14px" }}
                          color="orangeTheme"
                          checked={
                            selectedRegionName.indexOf(item.subMarketId) > -1
                          }
                        />
                        <ListItemText
                          style={{ fontSize: "14px" }}
                          primary={item.subMarketName}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText style={{ height: "0px" }}>
                    {regionNameErrorText}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ marginBottom: "16px" }}>
              <Grid item xs={6}>
                <FormControl
                  className="spacingClass"
                  required
                  error={!!practiceNameErrorText}
                  color="orangeTheme"
                  sx={{
                    maxWidth: "303px",
                    minWidth: "303px",
                    backgroundColor: "#fff",
                    borderRadius: "6px",
                  }}
                  disabled={isLoadingPracticeName}
                  size="small"
                >
                  <InputLabel id="practice-name-select-small-label">
                    Practice Name
                  </InputLabel>

                  <Select
                    required
                    multiple
                    value={selectedPracticeName}
                    className="practiceNameDropdown"
                    labelId="practice-name-select-small-label"
                    id="practice-name-select-small"
                    onChange={(e) => handlePracticeNameChange(e)}
                    renderValue={(selected) =>
                      `${selected.length} Practices Selected`
                    }
                    label="Select Practice"
                  >
                    <MenuItem value="all">
                      <Checkbox
                        color="orangeTheme"
                        checked={isAllPracticesSelected}
                        indeterminate={
                          selectedPracticeName.length > 0 &&
                          selectedPracticeName.length <
                            practiceNameDropdownData.length
                        }
                      />

                      <ListItemText primary="Select All">
                        Select All{" "}
                      </ListItemText>
                    </MenuItem>
                    {practiceNameDropdownData.map((item) => (
                      <MenuItem
                        className="dropdownText"
                        key={item.practiceId}
                        value={item.practiceId}
                      >
                        <Checkbox
                          style={{ fontSize: "14px" }}
                          color="orangeTheme"
                          checked={
                            selectedPracticeName.indexOf(item.practiceId) > -1
                          }
                        />
                        <ListItemText
                          style={{ fontSize: "14px" }}
                          primary={item.practiceName}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText style={{ height: "0px" }}>
                    {practiceNameErrorText}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            <Typography
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "rgba(17, 24, 39, 0.72)",
              }}
            >
              Seven Pillars
            </Typography>
            {toolsScoreCardData.map(
              (pillar, index) =>
                pillar.key && (
                  <Accordion
                    key={index}
                    expanded={expanded === `panel-${index}`}
                    onChange={handleAccordionChange(`panel-${index}`)}
                  >
                    <AccordionSummary
                      aria-controls={`panel-${index}-content`}
                      id={`panel-${index}-header`}
                      expandIcon={<ExpandMoreIcon />}
                    >
                      <Typography style={{ fontWeight: 500 }}>
                        {pillar.name}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>
                        <Typography>Tools Access Metric: </Typography>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead
                              style={{ background: "rgba(17, 24, 39, 0.07)" }}
                            >
                              <TableRow>
                                <TableCell>Tool Name</TableCell>
                                <TableCell>View Mode</TableCell>
                                <TableCell>Update Mode</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {pillar.tools.map(
                                (tool, i) =>
                                  tool.key && (
                                    <TableRow key={i}>
                                      <TableCell>{tool.name}</TableCell>
                                      <TableCell>
                                        <Switch
                                          checked={
                                            toolsViewAccess.indexOf(tool.id) >
                                            -1
                                          }
                                          onChange={(e) =>
                                            handleToolsViewToggleChange(
                                              pillar.id,
                                              tool.id,
                                              e.target.checked
                                            )
                                          }
                                          size="small"
                                          inputProps={{
                                            "aria-label": "controlled",
                                          }}
                                          color="orangeTheme"
                                          name={`tool-view-${i}`}
                                        ></Switch>
                                      </TableCell>
                                      <TableCell>
                                        <Switch
                                          checked={
                                            toolsEditAccess.indexOf(tool.id) >
                                            -1
                                          }
                                          onChange={(e) =>
                                            handleToolsEditToggleChange(
                                              pillar.id,
                                              tool.id,
                                              e.target.checked
                                            )
                                          }
                                          size="small"
                                          color="orangeTheme"
                                          inputProps={{
                                            "aria-label": "controlled",
                                          }}
                                          name={`tool-update-${i}`}
                                        ></Switch>
                                      </TableCell>
                                    </TableRow>
                                  )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <br />
                        <Typography>Scorecards Access Metric: </Typography>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead
                              style={{ background: "rgba(17, 24, 39, 0.07)" }}
                            >
                              <TableRow>
                                <TableCell>Scorecard Name</TableCell>
                                <TableCell>View Mode</TableCell>
                                {/* <TableCell>Update Mode</TableCell> */}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {pillar.scorecards.map(
                                (scorecard, i) =>
                                  scorecard.key && (
                                    <TableRow key={i}>
                                      <TableCell>{scorecard.name}</TableCell>
                                      <TableCell>
                                        <Switch
                                          checked={
                                            scorecardViewAccess.indexOf(
                                              scorecard.id
                                            ) > -1
                                          }
                                          onChange={(e) =>
                                            handleScorecardViewToggleChange(
                                              pillar.id,
                                              scorecard.id,
                                              e.target.checked
                                            )
                                          }
                                          size="small"
                                          color="orangeTheme"
                                          inputProps={{
                                            "aria-label": "controlled",
                                          }}
                                          name={`scorecard-view-${i}`}
                                        ></Switch>
                                      </TableCell>
                                      {/* <TableCell>
                                <Switch color="orangeTheme" name={`scorecard-update-${i}`}></Switch>
                              </TableCell> */}
                                    </TableRow>
                                  )
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                )
            )}
          </div>
          <Grid
            container
            spacing={6}
            style={{ marginTop: "-39px", marginLeft: "46px" }}
          >
            <Grid item xs={8}></Grid>
            <Grid item xs={1}>
              <Link
                to={PagesPath.UserAdminConsoleURL}
                key="AddRoleMapping"
                state={{ value: "3" }}
                style={{
                  textDecoration: "none",
                  underline: "hover",
                  color: "inherit",
                }}
              >
                <Button
                  style={{
                    borderColor: "black",
                    color: "black",
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                  }}
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Link>
            </Grid>
            <Grid item xs={1}>
              <Button
                style={{
                  color: "white",
                  fontFamily: Styles.FONT_FAMILY_POPPINS,
                }}
                variant="contained"
                color="orangeTheme"
                onClick={handleFormSubmit}
                disableElevation
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
};

export default RoleMappingForm;
