import ERAlertClosureFromTexting from "../../features/ERAlertClosureFromTexting/ERAlertClosureFromTexting";

const ERAlertClosureFromTextingPage = () =>{
    return(
        <>
            <ERAlertClosureFromTexting></ERAlertClosureFromTexting>
        </>
    )
}

export default ERAlertClosureFromTextingPage;