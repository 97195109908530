import React, { useState } from "react";
// import { Box } from "@mui/material";
import { Button, Stack } from "@mui/material";
import PageHeader from "../../component/common/header/PageHeader";
import NewPatientVolAlign from "./NewPatientVolAlign";
import AddIcon from "@mui/icons-material/Add";
import { COLOR, Styles } from "../../Styles";
import Searchbox from "../../component/common/searchbox/Searchbox";
// import { display } from "html2canvas/dist/types/css/property-descriptors/display";
const VoluntaryAlignmentTrackerHeader = ({
  accessObj,
  // isEditAccess = false,
  updateSearch,
}) => {
  const title = "Voluntary Alignment Scheduling & Tracking";
  const sevenPillarTitle = "Practice VBC Revenue & Growth";

  const [search, setSearch] = React.useState(null);

  const handleSearch = (value) => {
    setSearch(value);
    //updateSearch(value);
  };

  const [isNewPatientModalOpen, setIsNewPatientModalOpen] = useState(false);
  const openModal = () => {
    setIsNewPatientModalOpen(true);
  };

  function closeModal() {
    // setSelectedRow(row);
    setIsNewPatientModalOpen(false);
  }
  return (
    <>
      <PageHeader title={title} sevenPillarTitle={sevenPillarTitle}>
        <Stack
          direction="row"
          spacing={2}
          style={{
            marginLeft: "auto",

            float: "right",
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Searchbox
            sx={{
              display: !accessObj || !accessObj.hasAccess ? "none" : "",
            }}
            defaultValue={search}
            label="Search Member"
            handleChange={(e) => handleSearch(e.target.value)}
            onClick={() => updateSearch(search)}
          />
          <Button
            disabled={!accessObj || !accessObj.isEditAccess}
            sx={{
              textTransform: "none",
              color: "#111827",
              border: "1px solid",
              borderColor: COLOR.ORANGE,
              textAlign: "center",
              leadingTrim: "both",
              fontFamily: Styles.FONT_FAMILY_POPPINS,
              fontSize: "12px",
              fontStyle: Styles.FONT_STYLE_NORMAL,
              fontWeight: "500",
              lineHeight: "22px",
              "&.MuiButton-root:hover": {
                borderColor: COLOR.ORANGE,
                border: "1px solid #F37021",
                //   borderRadius: "4px",
              },
            }}
            variant="outlined"
            color="orangeTheme"
            onClick={() => openModal()}
          >
            <AddIcon style={{ paddingTop: "5px", marginTop: "-4px" }} />
            New Patient Enrollment
          </Button>
        </Stack>
      </PageHeader>
      <NewPatientVolAlign
        isOpen={isNewPatientModalOpen}
        onClose={closeModal}
      ></NewPatientVolAlign>
    </>
  );
};

export default VoluntaryAlignmentTrackerHeader;
