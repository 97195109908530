import React, { useEffect, useState, useCallback, useRef } from "react";
import { Box, Button, Modal, Stack, Grid, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { COLOR, Styles } from "../../../../Styles";
import agent from "../../../../app/api/Agent";
import { format } from "date-fns";
import { alpha, styled } from '@mui/material/styles';
import Loader from "../../../../component/common/loader/Loader";
const MemberDetailsModal = ({ isOpen, onClose, rowData, handleAddToCohort, startDateValue, 
    endDateValue, Preview 
    // ,ipaFlags, riskCats, evtTy
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [memberCostDetails, setMemberCostDetails] = useState({});
    const [memberEventsList, setMemberEventsList] = useState([]);
    const { admissionTrendsDetails } = useSelector(
        (state) => state.filterDetails
    );

    const { cohortDetails, cohortFilterDetails } = useSelector(
        (state) => state.filterDetails
    );

    const { modalFilterDetails } = useSelector(
        (state) => state.filterDetails
    );

    // useEffect(()=>{
    // },[memberCostDetails])
    useEffect(() => {
        setMemberCostDetails({});
        if (isOpen === true) {
            setIsLoading(true);
            let formData = {
                ourId: rowData ? rowData.ourId : 0,
                payer: rowData ? rowData.payer : '',
                Submarket: rowData ? rowData.region : '',
                Practice: rowData ? rowData.practiceName : '',
                StartDate: admissionTrendsDetails?.startDate,
                EndDate: admissionTrendsDetails?.endDate,
                RiskCategories : modalFilterDetails?.riskCats,
                IpaFlags :modalFilterDetails?.ipaFlags,
                EventTypes : modalFilterDetails?.evtTy
            }
            agent.SDOHAssessmentTrackingAgent.GetCOHORTMembersCostDetails(formData
            )
                .then((response) => {
                    if (response != undefined &&
                        response?.ourId != undefined
                    ) {
                        setMemberCostDetails(response);
                        if (response?.eventsListDetails != undefined && 
                            response?.eventsListDetails.length > 0){
                                setMemberEventsList(response?.eventsListDetails);
                            }
                            else
                            setMemberEventsList([]);
                    }
                    else {
                        setMemberCostDetails({});
                    }

                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                    console.log("exc-GetCOHORTMemberCostDetails", err);
                });
        }
    }, [admissionTrendsDetails])
    useEffect(() => {
        // if (isOpen === true) getEventDetailsData();
        var riskCategoryNamesArr = "";
        var ipaFlagNamesArr = "";
        var eventTypeNamesArr = "";
        if (isOpen === true && Preview == 1) {
            setIsLoading(true);
            // if (modalFilterDetails?.riskCats && modalFilterDetails?.riskCats!= ""){
            //     riskCategoryNamesArr = modalFilterDetails?.riskCats.map((item) => item.riskCategory);
            // }
            
            // if (modalFilterDetails?.ipaFlags && modalFilterDetails?.ipaFlags!= ""){
            //         ipaFlagNamesArr = modalFilterDetails?.ipaFlags.map((item) => item.ipaFlag);
            // }

            // if (modalFilterDetails?.evtTy && modalFilterDetails?.evtTy!= ""){
            //         eventTypeNamesArr = modalFilterDetails?.evtTy.map((item) => item.eventType);
            // }
            
            let formData = {
                ourId: rowData ? rowData.ourId : 0,
                payer: rowData ? rowData.payer : '',
                Submarket: rowData ? rowData.region : '',
                Practice: rowData ? rowData.practiceName : '',
                StartDate: cohortFilterDetails?.eventStartDate,
                EndDate: cohortFilterDetails?.eventEndDate,
                RiskCategories : modalFilterDetails?.riskCats,
                IpaFlags :modalFilterDetails?.ipaFlags,
                EventTypes : modalFilterDetails?.evtTy
            }
            agent.SDOHAssessmentTrackingAgent.GetCOHORTMembersCostDetails(formData
            )
                .then((response) => {
                    if (response != undefined &&
                        response?.ourId != undefined
                    ) {
                        setMemberCostDetails(response);
                        if (response?.eventsListDetails != undefined && 
                            response?.eventsListDetails.length > 0){
                                setMemberEventsList(response?.eventsListDetails);
                            }
                            else
                            setMemberEventsList([]);
                    }
                    else {
                        setMemberCostDetails({});
                    }
                    setIsLoading(false);
                })
                .catch((err) => {
                    setIsLoading(false);
                    console.log("exc-GetCOHORTMemberCostDetails", err);
                });
        }
    }, [isOpen])

    function formatToCostPrice(number) {
        // Convert number to string
        if (number != undefined) {
            const numberStr = number.toString();

            // Match digits and decimal point using regex
            const matches = numberStr.match(/^(\d+)(?:\.(\d+))?$/);

            if (matches) {
                // Get the integer part and fractional part (if exists)
                const integerPart = (+matches[1]).toLocaleString();
                const fractionalPart = matches[2] ? matches[2].padEnd(2, '0') : '00';

                // Format the number in account balance format -- ₹
                const formattedBalance = `$ ${integerPart}`; //.${fractionalPart}
                return formattedBalance;
            } else {
                return "Invalid input";
            }
        }

    }


    if (!rowData) return;

    const OutlinedButton = styled(Button)(() => ({
        borderRadius: "4px",
        padding: "6px",
        justifyContent: "center",
        color: COLOR.BLACK,
        borderColor: COLOR.BLACK,
        fontFamily: Styles.FONT_FAMILY_POPPINS,
        fontStyle: "normal",
        textTransform: "capitalize",
    }));

    const ContainedButton = styled(Button)(() => ({
        variant: "contained",
        borderRadius: "4px",
        padding: "6px",
        background: COLOR.PRIMARY_ORANGE,
        justifyContent: "center",
        color: COLOR.WHITE,
        fontFamily: Styles.FONT_FAMILY_POPPINS,
        fontStyle: "normal",
        textTransform: "none",
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
        ":hover": {
            background: COLOR.SECONDARY_ORANGE,
        },
    }));

    const DefaultTypography = styled(Typography)({
        color: "#000",
        fontFamily: Styles.FONT_FAMILY_POPPINS,
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
    });

    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            slotProps={{ backdrop: { onClick: null } }}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "40%",
                    left: "50%",
                    transform: "translate(-50%,-40%)",
                    width: "778px",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    pt: 1,
                    height: "600px",
                    overflow: "auto"
                }}
            >
                <h3
                    style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
                >
                    Member Details
                    <span>
                        <CloseIcon onClick={onClose} style={{ float: "right" }} />
                    </span>
                </h3>
                <hr
                    style={{
                        width: "auto",
                        opacity: "0.3",
                        marginTop: "-11px",
                        marginBottom: "5px",
                    }}
                />
                {
                    isLoading ? (
                        <Loader isLoading={isLoading} />
                    ) : (
                        <>
                            <Stack spacing={2} >

                                <Grid container >
                                    <Grid item xs={4}>
                                        <Typography style={{
                                            fontWeight: 400, fontSize: "14px", lineHeight: "21px"
                                        }}
                                        >
                                            {/* <b> */}
                                            Medicare ID :
                                            {/* </b>  */}
                                            {rowData.insuranceId ? rowData.insuranceId : "-"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{ fontWeight: 400, fontSize: "14px", lineHeight: "21px" }}>
                                            {/* <b> */}
                                            Member Name :
                                            {/* </b>  */}
                                            {rowData.memberName}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{ fontWeight: 400, fontSize: "14px", lineHeight: "21px" }}>
                                            {/* <b> */}
                                            DOB :
                                            {/* </b>  */}
                                            {rowData.memberDateOfBirth
                                                ? format(new Date(rowData.memberDateOfBirth), "dd MMM yyyy")
                                                : ""}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container >
                                    <Grid item xs={4}>
                                        <Typography style={{ fontWeight: 400, fontSize: "14px", lineHeight: "21px" }}>
                                            {/* <b> */}
                                            Region :
                                            {/* </b>  */}
                                            {rowData.region ? rowData.region : "-"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{ fontWeight: 400, fontSize: "14px", lineHeight: "21px" }}>
                                            {/* <b> */}
                                            Payer :
                                            {/* </b>  */}
                                            {rowData.payer ? rowData.payer : "-"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{ fontWeight: 400, fontSize: "14px", lineHeight: "21px" }}>
                                            {/* <b> */}
                                            Practice :
                                            {/* </b>  */}
                                            {rowData.practiceName ? rowData.practiceName : "-"}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container >
                                    <Grid item xs>
                                        <Typography style={{ fontWeight: 400, fontSize: "14px", lineHeight: "21px" }}>
                                            {/* <b> */}
                                            Chronic Conditions :
                                            {/* </b>  */}
                                            {rowData.chronicCondition ? rowData.chronicCondition : "-"}
                                        </Typography>
                                    </Grid>
                                </Grid>


                                <Grid container >
                                    <Typography style={{
                                        fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                        color: "#212121"
                                    }}>
                                        <b>Event Details </b>
                                    </Typography>
                                </Grid>

                                <hr
                                    style={{
                                        width: "auto",
                                        opacity: "0.3",
                                        marginTop: "4px",
                                    }}
                                />
                                <Grid container >
                                    <Typography style={{
                                        fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                        color: "#DC4F34"
                                    }}>
                                        <b>Total Events :</b> {rowData.totalEvents ? rowData.totalEvents : "-"}
                                    </Typography>
                                </Grid>
                                <Grid container sx={{ display: "flex", alignItems: "center" }}>
                                    {memberEventsList?.map((detail) => {
                                        const { eventType, eventCount } =
                                            detail;
                                        return (
                                            <Grid item xs={4}>
                                                <Typography style={{ fontWeight: 400, fontSize: "14px", lineHeight: "21px" }}>
                                                    {eventType} : {eventCount ? eventCount : "-"}
                                                </Typography>
                                            </Grid>
                                        );
                                    })}
                                </Grid>
                                {/* <Grid container >
                                    <Grid item xs={4}>
                                        <Typography style={{ fontWeight: 400, fontSize: "14px", lineHeight: "21px" }}>
                                            IP  : &nbsp;
                                            {rowData.totalIPEvents ? rowData.totalIPEvents : "-"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{ fontWeight: 400, fontSize: "14px", lineHeight: "21px" }}>
                                            ED : &nbsp;
                                            {memberCostDetails?.ed}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{ fontWeight: 400, fontSize: "14px", lineHeight: "21px" }}>
                                            OBS : &nbsp; 0
                                        </Typography>
                                    </Grid>

                                </Grid> */}

                                {/* <Grid container >
                                    <Grid item xs={4}>
                                        <Typography style={{ fontWeight: 400, fontSize: "14px", lineHeight: "21px" }}>
                                            IP Days : &nbsp;
                                            {memberCostDetails?.total_IP_Days}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{ fontWeight: 400, fontSize: "14px", lineHeight: "21px" }}>
                                            SNF Events : &nbsp;
                                            {memberCostDetails?.total_SNF_Days}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{ fontWeight: 400, fontSize: "14px", lineHeight: "21px" }}>
                                            Unplanned Readmissions : &nbsp;
                                            {memberCostDetails?.unplanned_Readmits}
                                        </Typography>
                                    </Grid>
                                </Grid> */}
                                {/* <Grid container >
                                    <Grid item xs={4}>
                                        <Typography style={{ fontWeight: 400, fontSize: "14px", lineHeight: "21px" }}>
                                            All Cause Discharges : &nbsp;
                                            {memberCostDetails?.unplanned_Discharges}
                                        </Typography>
                                    </Grid>
                                </Grid> */}
                                <Grid container >
                                    <Typography style={{
                                        fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                        color: "#212121"
                                    }}>
                                        <b>Cost Details </b>
                                    </Typography>
                                </Grid>

                                <hr
                                    style={{
                                        width: "auto",
                                        opacity: "0.3",
                                        marginTop: "4px",
                                    }}
                                />
                                <Grid container >
                                    <Typography style={{
                                        fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                        color: "#DC4F34"
                                    }}>
                                        <b>Total Costs :</b> &nbsp;
                                        {formatToCostPrice(memberCostDetails?.totalCost)}
                                    </Typography>
                                </Grid>

                                <Grid container >
                                    <Grid item xs={4}>
                                        <Typography style={{ fontWeight: 400, fontSize: "14px", lineHeight: "21px" }}>
                                            Rx Cost : &nbsp;
                                            {formatToCostPrice(memberCostDetails?.rx_Costs)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{ fontWeight: 400, fontSize: "14px", lineHeight: "21px" }}>
                                            Professional Cost : &nbsp;
                                            {formatToCostPrice(memberCostDetails?.professional_Costs)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{ fontWeight: 400, fontSize: "14px", lineHeight: "21px" }}>
                                            OP Cost : &nbsp;
                                            {formatToCostPrice(memberCostDetails?.oupatient_Costs)}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container >
                                    <Grid item xs={4}>
                                        <Typography style={{ fontWeight: 400, fontSize: "14px", lineHeight: "21px" }}>
                                            Hospice Cost : &nbsp;
                                            {formatToCostPrice(memberCostDetails?.hospice_Costs)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{ fontWeight: 400, fontSize: "14px", lineHeight: "21px" }}>
                                            Home health Cost : &nbsp;
                                            {formatToCostPrice(memberCostDetails?.home_Health_Costs)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Typography style={{ fontWeight: 400, fontSize: "14px", lineHeight: "21px" }}>
                                            SNF Cost : &nbsp;
                                            {formatToCostPrice(memberCostDetails?.snF_Costs)}
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid container >
                                    <Typography style={{
                                        fontWeight: 400, fontSize: "14px", lineHeight: "21px",
                                    }}>
                                        Inpatient Cost : &nbsp;
                                        {formatToCostPrice(memberCostDetails?.inpatient_Costs)}
                                    </Typography>
                                </Grid>
                                <hr
                                    style={{
                                        width: "auto",
                                        opacity: "0.3",
                                        marginTop: "16px",
                                    }}
                                />

                            </Stack>

                            <Stack direction="row" spacing={2} style={{ marginTop: '8px' }}>

                                <Grid container spacing={1}>
                                    <Grid item xs={12} sx={{ margintTop: "25px", display: "flex", justifyContent: "flex-end" }} >
                                        {/* <Button style={{ borderColor: 'black', color: 'black', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="outlined" onClick={onClose}>Cancel</Button>
                                <Button style={{ color: 'white', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="contained" color="orangeTheme" disableElevation onClick={handleNext}>Next</Button> */}
                                        {/* <Button style={{ borderColor: 'black', color: 'black', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="outlined" onClick={handleBack}>Back</Button> */}

                                        <Stack direction="row" spacing={2} sx={{ justifyContent: "end", paddingTop: "7px", paddingBottom: "7px" }}>
                                            <OutlinedButton
                                                onClick={onClose}
                                                variant="text"
                                                size="small"
                                            >
                                                <DefaultTypography>Close</DefaultTypography>
                                            </OutlinedButton>

                                            <Button style={{
                                                color: 'white', fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                display:
                                                    (Preview === 1) ? 'none' : 'block'
                                            }}
                                                variant="contained" color="orangeTheme" disableElevation onClick={handleAddToCohort}>Add To Cohort</Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Stack>

                        </>
                    )}
            </Box>
        </Modal>
    );
};

export default MemberDetailsModal;