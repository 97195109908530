import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Switch,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import React, { useEffect, useState } from "react";
import DerosteringTrackingUpdateDeroster from "../derosteringTracking/DerosteringTrackingUpdateDeroster";
import { axiosRequests } from "../../app/api/AxiosRequest";
import { Styles } from "../../Styles";
import MemberDetailsPDF from "./PDFExport/MemberDetailsPDF";
import html2pdf from "html2pdf.js";

const StyledMemberDemographicSection = styled(Grid)({
  display: "flex",
  padding: "12px",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",
  alignSelf: "stretch",
});

const StyledMemberNameSection = styled(Grid)({
  display: "flex",
  alignItems: "center",
  gap: "16px",
  alignSelf: "stretch",
});
const StyledRiskScore = styled(Typography)({
  display: "flex",
  padding: "4px",
  alignItems: "flex-start",
  gap: "16px",
  borderRadius: "4px",
  border: "1px solid #FBD4BC",
  background: "#FEF8F7",
  alignSelf: "stretch",
  fontSize: "14px",
});
const MemberOtherDetailsSection = styled(Grid)({
  display: "flex",
  alignItems: "flex-start",
  alignSelf: "stretch",
});

const DOBDetailsSection = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",
  flex: "1 0 0",
});

const InsuranceIdSection = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",
  flex: "1 0 0",
  alignSelf: "stretch",
});

const UserActionSection = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "8px",
  alignSelf: "stretch",
});

const ExcludeMemberSection = styled(Grid)({
  display: "inline-flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "8px",
});

const MemberDemographicData = ({
  memberDetail,
  payerName,
  backAction,
  setShowMemberExcludedSnackbar,
  hideExcludeMember,
  hideUserActions = false,
  isAuditor = false,
  auditStatusId = 1,
  priority = undefined,
}) => {
  const ExcludeReasons = [
    { id: 1, value: "Member Died Prior to Calendar Year" },
    { id: 2, value: "Practice Requesting Member to be De-rostered" },
  ];

  const [showPDFTemplate, setShowPDFTemplate] = useState(false);
  const [showExcludeSection, setShowExcludeSection] = useState(false);
  const [excludeReasonValue, setExcludeReasonValue] = useState(0);
  const [excludeReasonError, setExcludeReasonError] = useState("");
  const [openDerosteringModal, setOpenDerosteringModal] = useState(false);
  const [derosterMember, setDerosterMember] = useState(false);
  const [memberExcluded, setMemberExcluded] = useState(false);
  const [rowData, setRowData] = useState({});

  const handlePDFDownload = () => {
    //setShowPDFTemplate(true);
    const element = document.getElementById("pdf-data");
   const options = {
      html2canvas: {scale: 3, dpi:200},
      margin: 1,
      jsPDF: {format: 'letter', orientation: 'landscape'}
    }
    html2pdf().from(element).set(options).save(`${memberDetail.ourId}.pdf`);
  };

  const hanldeToggleExcludeSection = () => {
    setExcludeReasonError("");
    setExcludeReasonValue(0);
    setShowExcludeSection(!showExcludeSection);
  };

  const handleExcludeReasonChange = (e) => {
    setExcludeReasonValue(e.target.value);
    setExcludeReasonError("");
  };

  const saveExcludeAPI = (requestBody) => {
    setShowMemberExcludedSnackbar(false);
    axiosRequests
      .post("/ActiveGapClosure/ExcludeMemberFromPAF", requestBody)
      .then((resp) => {
        setMemberExcluded(true);
        setShowMemberExcludedSnackbar(true);
        backAction();
      });
  };

  const handleSubmit = () => {
    let hasError = false;
    if (!excludeReasonValue || excludeReasonValue === 0) {
      setExcludeReasonError("Please select a reason");
      hasError = true;
    }
    if (hasError) return;
    let requestBody;
    if (parseInt(excludeReasonValue) === 1) {
      requestBody = {
        ourId: memberDetail.ourId,
        exclusionReasonId: excludeReasonValue,
        payorName: payerName,
      };
      saveExcludeAPI(requestBody);
    } else if (parseInt(excludeReasonValue) === 2) {
      let row = {
        payorContractType: memberDetail.productName,
        ourID: memberDetail.ourId,
        payorName: payerName,
      };
      setRowData(row);
      setOpenDerosteringModal(true);
    }
  };
  useEffect(() => {
    if (derosterMember) {
      let requestBody = {
        ourId: memberDetail.ourId,
        exclusionReasonId: excludeReasonValue,
        payorName: payerName,
      };
      saveExcludeAPI(requestBody);
      setDerosterMember(false);
    }
  }, [derosterMember]);
  const onClose = () => {
    setOpenDerosteringModal(false);
  };

  useEffect(() => {
    if (showPDFTemplate) {
      handlePDFDownload();
      setShowPDFTemplate(false);
    }
  }, [showPDFTemplate]);
  const handleShowPdfTemplate = () => {
    setShowPDFTemplate(true);
  };
  return (
    <StyledMemberDemographicSection>
      <StyledMemberNameSection>
        <Typography sx={{ fontSize: "14px" }}>
          <b>Name: </b> {memberDetail.memberName}
        </Typography>
        <StyledRiskScore>
          {/* <Typography style={{ alignSelf: "stretch", fontSize: "14px" }}> */}
          <b>Risk Score: </b>
          {memberDetail.riskScore ? memberDetail.riskScore : "-"}
          {/* </Typography> */}
        </StyledRiskScore>
          <StyledRiskScore>
          {/* <Typography style={{ alignSelf: "stretch", fontSize: "14px" }}> */}
          <b>Priority: </b>
          {priority ? priority : "-"}
          {/* </Typography> */}
        </StyledRiskScore>
      </StyledMemberNameSection>
      <MemberOtherDetailsSection>
        <DOBDetailsSection>
          <Typography style={{ alignSelf: "stretch", fontSize: "14px" }}>
            <b>DOB:</b>{" "}
            {memberDetail.dob ? format(memberDetail.dob, "MMMM dd,yyyy") : "-"}
          </Typography>
          <Typography style={{ alignSelf: "stretch", fontSize: "14px" }}>
            <b>Address:</b> {memberDetail.address ? memberDetail.address : "-"}
          </Typography>
          <Typography style={{ alignSelf: "stretch", fontSize: "14px" }}>
            <b>Phone:</b> {memberDetail.phone ? memberDetail.phone : "-"}
          </Typography>
          <Typography style={{ alignSelf: "stretch", fontSize: "14px" }}>
            <b>Email:</b> {memberDetail.email ? memberDetail.email : "-"}
          </Typography>
        </DOBDetailsSection>
        <InsuranceIdSection>
          <Typography style={{ alignSelf: "stretch", fontSize: "14px" }}>
            <b>Insurance ID:</b>{" "}
            {memberDetail.insuranceId ? memberDetail.insuranceId : "-"}
          </Typography>
          <Typography style={{ alignSelf: "stretch", fontSize: "14px" }}>
            <b>Payer:</b> {payerName ? payerName : "-"}
          </Typography>
          <Typography style={{ alignSelf: "stretch", fontSize: "14px" }}>
            <b>Product:</b>{" "}
            {memberDetail.productName ? memberDetail.productName : "-"}
          </Typography>
          <Typography style={{ alignSelf: "stretch", fontSize: "14px" }}>
            <b>PCP Name:</b> {memberDetail.pcpName ? memberDetail.pcpName : "-"}
          </Typography>
        </InsuranceIdSection>
        {!memberExcluded && !hideUserActions && (
          <UserActionSection style={{ marginTop: "-11px" }}>
            <Button
              variant="outlined"
              color="orangeTheme"
              style={{
                textTransform: "none",
                marginLeft: "auto",
                fontWeight: 700,
              }}
              onClick={handleShowPdfTemplate}
            >
              <DownloadOutlinedIcon></DownloadOutlinedIcon>Download PDF
            </Button>
            {!hideExcludeMember && (
              <FormGroup style={{ marginLeft: "auto" }}>
                <FormControlLabel
                  color="orangeTheme"
                  control={
                    <Switch
                      color="orangeTheme"
                      size="small"
                      checked={showExcludeSection}
                      onChange={hanldeToggleExcludeSection}
                    />
                  }
                  label="Exclude Member"
                />
              </FormGroup>
            )}

            {showExcludeSection && (
              <ExcludeMemberSection>
                <FormControl error={!!excludeReasonError}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                    value={excludeReasonValue}
                    onChange={handleExcludeReasonChange}
                  >
                    {ExcludeReasons.map((item) => (
                      <FormControlLabel
                        value={item.id}
                        key={item.id}
                        control={<Radio size="small" color="orangeTheme" />}
                        label={item.value}
                      />
                    ))}
                  </RadioGroup>
                  <FormHelperText>{excludeReasonError}</FormHelperText>
                </FormControl>
                <Stack
                  direction="row"
                  spacing={2}
                  style={{ marginLeft: "auto" }}
                >
                  <Button
                    variant="text"
                    size="small"
                    color="blackTheme"
                    style={{
                      textTransform: "none",
                      fontFamily: Styles.FONT_FAMILY_POPPINS,
                    }}
                    onClick={hanldeToggleExcludeSection}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    color="orangeTheme"
                    style={{
                      textTransform: "none",
                      fontFamily: Styles.FONT_FAMILY_POPPINS,
                    }}
                    onClick={handleSubmit}
                  >
                    Confirm Exclude
                  </Button>
                </Stack>
              </ExcludeMemberSection>
            )}
          </UserActionSection>
        )}
        {memberExcluded && (
          <UserActionSection style={{ marginTop: "-11px" }}>
            <Typography>Member Excluded!!</Typography>
          </UserActionSection>
        )}
      </MemberOtherDetailsSection>
      {openDerosteringModal && (
        <DerosteringTrackingUpdateDeroster
          rowData={rowData}
          isOpen={openDerosteringModal}
          onClose={onClose}
          derosterMember={derosterMember}
          setDerosterMember={setDerosterMember}
        />
      )}
      {showPDFTemplate && <MemberDetailsPDF memberDetail={memberDetail} auditStatusId={auditStatusId} priority={priority}/>}
    </StyledMemberDemographicSection>
  );
};

export default MemberDemographicData;
