import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Modal,
  Stack,
  Typography,
} from "@mui/material";
import { addDays, format } from "date-fns";
import { Styles } from "../../Styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { axiosRequests } from "../../app/api/AxiosRequest";
import { useEffect, useState } from "react";

const AddDateModal = ({ isOpen, onClose, rowData, setCustomModalCancel }) => {
  const [prescriptionDate, setPrescriptionDate] = useState(null);
  const [prescriptionDateError, setPrescriptionDateError] = useState("");
  const handleSubmitDate = () => {
    if (!prescriptionDate || prescriptionDate === "") {
      setPrescriptionDateError("Date is Required");
    }
    const requestBody = {
      id: rowData.id,
      prescriptionDate: format(prescriptionDate, "yyyy-MM-dd"),
    };
    axiosRequests
      .post("/MedicationAdherence/UpdatePrescriptionDate", requestBody)
      .then((resp) => {
        setCustomModalCancel(false);
        onClose();
      });
  };
  const handlePrescriptionDateChange = (e) => {
    setPrescriptionDate(e);
    setPrescriptionDateError('');
  };
  const closeAddDateModal = () => {
    setCustomModalCancel(true);
    onClose();
  };
  useEffect(() => {
    setPrescriptionDate(null);
    setPrescriptionDateError("");
  }, [isOpen]);
  return (
    rowData && (
      <Modal
        open={isOpen}
        onClose={onClose}
        slotProps={{ backdrop: { onClick: null } }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "25%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            width: "600px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            pt: 1,
            height: "auto",
            // overflowY: "auto",
          }}
        >
          <h3
            style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
          >
            Add Prescription Date
            <span>
              <Close onClick={closeAddDateModal} style={{ float: "right" }} />
            </span>
          </h3>
          <hr
            style={{
              width: "530px",
              opacity: "0.3",
              marginTop: "-8px",
              // marginBottom: "-4px",
            }}
          />
          <Stack direction="column" sx={{ marginTop: "23px" }} spacing={2}>
            <Typography>
              Add the date when prescription was added for{" "}
              <b>"{rowData.memberName}"</b>
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <FormControl
                className="practiceNameClass"
                required
                color="orangeTheme"
                sx={{
                  maxWidth: "180px",
                  minWidth: "160px",
                  backgroundColor: "#fff",
                  borderRadius: "6px",
                }}
                size="small"
                error={!!prescriptionDateError}
              >
                <DatePicker
                  label="Date"
                  value={prescriptionDate}
                  minDate={addDays(new Date(1753, 0, 0), 1)}
                  //disableFuture
                  color="orangeTheme"
                  slotProps={{
                    textField: {
                      className: "dateFieldClass",
                      color: "orangeTheme",
                    },
                  }}
                  onChange={handlePrescriptionDateChange}
                />
                <FormHelperText style={{ height: "0px" }}>
                  {prescriptionDateError}
                </FormHelperText>
              </FormControl>
            </LocalizationProvider>
            <hr
              style={{
                width: "530px",
                opacity: "0.3",
                marginTop: "16px",
                // marginBottom: "-4px",
              }}
            />
            <Stack direction="row" spacing={2} style={{ marginLeft: "auto" }}>
              <Button
                style={{
                  textTransform: "none",
                  borderColor: "black",
                  color: "black",
                  fontFamily: Styles.FONT_FAMILY_POPPINS,
                }}
                variant="outlined"
                onClick={closeAddDateModal}
              >
                Cancel
              </Button>
              <Button
                style={{
                  textTransform: "none",
                  color: "white",
                  fontFamily: Styles.FONT_FAMILY_POPPINS,
                }}
                variant="contained"
                color="orangeTheme"
                disableElevation
                onClick={handleSubmitDate}
              >
                Add
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    )
  );
};

export default AddDateModal;
