import { axiosRequests } from "../AxiosRequest";

function getRoleMappingList() {
  
  return axiosRequests.get("/RoleMapping/GetRoleMappingCount");
  // return axiosRequests.get("/Members");
}

export const RoleMappingDataAgent = {
    getRoleMappingList: getRoleMappingList,
};