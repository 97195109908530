import React, { useEffect, useState } from "react";
import Header from "./header/Header";
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link, NavLink, Navigate, Outlet } from "react-router-dom";
import NoAccessPage from "./noAccess/NoAccessPage";
import { PagesPath } from "../../PagesPath";

const Layout = () => {
  const isLearningUser = parseInt(localStorage.getItem("learningUser")) === 1;
  const isHealthHomeUser =
    parseInt(localStorage.getItem("healthHomeUser")) === 1;

  const [noAccess, setNoAccess] = useState(false);
  useEffect(() => {
    var noAccess = localStorage.getItem("noAccess");
    if (parseInt(noAccess) === 1) {
      setNoAccess(true);
    }
  }, []);
  return (
    <>
      <div>
        <Header />
        <Box
          component="main"
          className="content"
          sx={{
            background: "#FAFAFA 0% 0% no-repeat padding-box",
            opacity: 1,
            width: "100%",
            flexGrow: 1,
          }}
        >
          <Toolbar sx={{ minHeight: "30px" }} />
          {isLearningUser ? (
            <Navigate to={PagesPath.Programs_URL} />
          ) : isHealthHomeUser ? (
            <Navigate to={PagesPath.HealthHomeTrainingPrograms_URL} />
          ) : noAccess ? (
            <NoAccessPage></NoAccessPage>
          ) : (
            <Outlet></Outlet>
          )}
          {/* {} */}
        </Box>
      </div>
    </>
  );
};

export default Layout;
