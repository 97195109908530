import { Box, Stack, Typography } from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import SDOHMemberDetails from "../sdohAssessmentDashboard/SDOHMemberDetails";

const SDOHViewAssessment = ({ backAction, ourId, submittedDate, memberRiskScore, providerName, practiceName }) => {
  return (
    <Box sx={{ width: "100%", p: { xs: 2, sm: 2 } }}>
      <Stack direction="column" spacing={0.2}>
        <Stack direction="row" spacing={1}>
          <ArrowBackOutlinedIcon
            onClick={backAction}
            style={{
              fontSize: "25px",
              fontWeight: "600",
              marginTop: "2px",
              width: "32px",
            }}
          />
          <Typography style={{ fontSize: "20px", fontWeight: "300" }}>
            Assessment : Member Details
          </Typography>
        </Stack>
        <SDOHMemberDetails
          source={"activeGapClosure"}
          ourId={ourId}
          submittedDate={submittedDate}
          memberRiskScore={memberRiskScore}
          providerName={providerName}
          practiceName={practiceName}
        ></SDOHMemberDetails>
      </Stack>
    </Box>
  );
};

export default SDOHViewAssessment;
