import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../../../app/api/Agent";

const initialState = {
  isLoading: false,
  trainingProgramsData: [],
  errorMessage: "",
};

export const getTrainingProgramsList = createAsyncThunk(
  "trainingProgramsData",
  async () => {
    const response =
      await agent.HealthHomeEducationAgent.getTrainingProgramsList();
    return response;
  }
);

const getTrainingProgramsListSlice = createSlice({
    name: "trainingProgramsData",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getTrainingProgramsList.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getTrainingProgramsList.fulfilled, (state, action) => {
          state.isLoading = false;
          state.trainingProgramsData = action.payload;
        })
        .addCase(getTrainingProgramsList.rejected, (state, action) => {
          state.isLoading = false;
          state.trainingProgramsData = [];
          state.errorMessage = action.error.message;
        });
    },
  });

  export default getTrainingProgramsListSlice.reducer;
