import React, { useState, useEffect, useCallback } from "react";
import Loader from "../../../component/common/loader/Loader";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../app/store";
import { Link, useNavigate } from "react-router-dom";
// import { Box } from "@mui/material";
import {
  Avatar,
  Button,
  Chip,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
// import DataGridCustom from "../../component/common/datagrid/DataGridCustom";
import Fab from "@mui/material/Fab";
// import { Styles } from "../../Styles";
import AddIcon from "@mui/icons-material/Add";
import { getUserRolesList } from "./userRolesListSlice";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { axiosRequests } from "../../../app/api/AxiosRequest";
import DataGridCustom from "../../../component/common/datagrid/DataGridCustom";
import { PagesPath } from "../../../PagesPath";
import DeleteConfirmation from "../DeleteConfirmation";
import { COLOR, Styles } from "../../../Styles";
import DataGridHeader from "../../../component/common/datagrid/DataGridHeader";
import { Search } from "@mui/icons-material";

const UserRolesTable = ({ setAddUserRolesFormDisplay }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowForModal, setSelectedRowForModal] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);
  const [filteredRolesData, setFilteredRolesData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const { isLoading, userRolesData } = useSelector(
    (state) => state.userRolesData
  );

  const getUserRolesListData = useCallback(async () => {
    const response = await dispatch(getUserRolesList());
  }, [dispatch]);
  useEffect(() => {
    getUserRolesListData();
  }, [getUserRolesListData]);

  useEffect(() => {
    getUserRolesListData();
    setIsDeleted(false);
  }, [isDeleted]);

  const openModal = (row) => {
    setSelectedRowForModal(row);
    setIsModalOpen(true);
  };

  function closeModal() {
    // setSelectedRow(row);
    setIsModalOpen(false);
  }

  const isRecordDeleted = () => {
    setIsDeleted(true);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (userRolesData) {
      let filtered;
      if (searchText) {
        filtered = userRolesData.filter((item) =>
          item.roleName.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredRolesData(filtered);
      } else {
        setFilteredRolesData(userRolesData);
      }
    }
  }, [searchText, userRolesData]);

  const columns = [
    {
      field: "roleName",
      headerName: "Role Title",
      flex: 1,
    },
    {
      field: "roleDescription",
      headerName: "Description",
      flex: 4,
    },
    {
      field: "type",
      headerName: "Type",
      flex: 1,
      renderCell: (params) => {
        if (params.row.type === 1) {
          return <Typography>Internal</Typography>;
        } else {
          return <Typography>External</Typography>;
        }
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <strong>
            <Link
              to={PagesPath.AddUserRolesURL}
              state={{ formState: params.row }}
              key="EditUserRoles"
              style={{
                textDecoration: "none",
                underline: "hover",
                color: "inherit",
                marginRight: "8px",
              }}
            >
              <EditIcon></EditIcon>
            </Link>
            <Link
              key="DeleteUserRoles"
              style={{
                textDecoration: "none",
                underline: "hover",
                color: "inherit",
              }}
            >
              <DeleteIcon onClick={() => openModal(params.row)}></DeleteIcon>
            </Link>
          </strong>
        );
      },
    },
  ];

  function getRowId(row) {
    return row.roleId; // + "_" + row.profileId;
  }
  return (
    <div>
      <Stack direction="row" spacing={1}>
        <div style={{ marginLeft: "auto", float: "right" }}>
          <Link
            to={PagesPath.AddUserRolesURL}
            key="AddUser"
            style={{
              textDecoration: "none",
              underline: "hover",
              color: "inherit",
            }}
          >
            <Button
              style={{
                textTransform: "none",
              }}
              variant="outlined"
              color="orangeTheme"
              onClick={() => setAddUserRolesFormDisplay(true)}
            >
              <AddIcon style={{ paddingTop: "5px", marginTop: "-4px" }} />
              Add User Role
            </Button>
          </Link>
        </div>
      </Stack>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <Grid item>
          <DataGridCustom
            columns={columns}
            rows={filteredRolesData}
            getRowId={getRowId}
            loading={isLoading}
            slots={{
              toolbar: DataGridHeader,
            }}
            slotProps={{
              toolbar: {
                headerText: "",
                fontSize: "",
                marginTop: "",
                marginBottom: "",
                height: "56px",
                children: (
                  <div
                    style={{
                      fontFamily: Styles.FONT_FAMILY_POPPINS,
                      fontSize: "16px",
                      width: "100%",
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginLeft: "5px",
                    }}
                  >
                    <Stack direction="row" spacing={1}>
                      <Typography
                        style={{
                          fontFamily: Styles.FONT_FAMILY_POPPINS,
                          fontSize: "16px",
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      >
                        Total User Roles
                      </Typography>
                      <Chip
                        color="orangeTheme"
                        variant="contained"
                        style={{ color: "#fff" }}
                        label={userRolesData.length}
                      ></Chip>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{ marginLeft: "auto" }}
                      >
                        <TextField
                          InputProps={{
                            style: { height: "38px", alignItems: "center" },
                            endAdornment: (
                              <InputAdornment position="end">
                                <Link
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                    //cursor: "pointer",
                                  }}
                                >
                                  <Search
                                    fontSize="small"
                                    sx={{
                                      color: COLOR.ORANGE,
                                      position: "inherit",
                                    }}
                                  />
                                </Link>
                              </InputAdornment>
                            ),
                          }}
                          label="Search User Roles"
                          id="outlined-size-small"
                          onChange={(e) => handleSearchChange(e)}
                          style={{
                            width: "293px",
                          }}
                          color="orangeTheme"
                          size="small"
                        />
                      </Stack>
                    </Stack>
                  </div>
                ),
              },
            }}
          />
        </Grid>
      )}
      <DeleteConfirmation
        isOpen={isModalOpen}
        onClose={closeModal}
        rowData={selectedRowForModal}
        modalFrom={"UserRoles"}
        isDeleted={isRecordDeleted}
      ></DeleteConfirmation>
    </div>
  );
};

export default UserRolesTable;
