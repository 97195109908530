import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import familyMembersProfileReducer from "../features/familyMembersProfile/familyMembersProfileSlice";
import authorizationReducer from "../features/authorization/authorizationSlice";
import familyMembersProfileSearchReducer from "../features/familyMembersProfile/familyMembersProfileSearchSlice";
import powerBiReducer from "../features/powerBi/powerBiDashboardSlice";
import powerBiReportReducer from "../features/powerBi/powerBiReportSlice";
import derosteringTrackingReducer from "../features/derosteringTracking/derosteringTrackingSlice";
import voluntaryAlignmentMembersListReducer from "../features/voluntaryAlignmentTracker/voluntaryAlignmentListSlice";
import getUsersListReducer from "../features/userAdminConsole/Users/usersListSlice";
import getUserRolesListReducer from "../features/userAdminConsole/User Roles/userRolesListSlice";
import derosteringReasonsReducer from "../features/derosteringTracking/payorContractTypeDerosterReasonsSlice";
import memberDetailsProfileReducer from "../features/derosteringTracking/memberDetailsProfileSlice";
import getRoleMappingListReducer from "../features/userAdminConsole/Role Mapping/roleMappingSlice";
import getUserRoleMappingListReducer from "../features/userAdminConsole/User Role Mapping/userRoleMappingSlice";
import downloadDerosteringMembersReducer from "../features/derosteringTracking/downloadDerosteringMembersSlice";
import pillarsToolsScorecardReducer from "../features/pillarsToolsScorecards/pillarsToolsScorecardsSlice";
import marketsListReducer from "../component/common/sdohAssessmentFilter/SDOHAssessmentMarketSlice";
import practiceListReducer from "../component/common/sdohAssessmentFilter/SDOHAssessmentPracticeSlice";
import filterDetailsReducer from "../component/common/sdohAssessmentFilter/SDOHAssessmentFilterDetailsSlice";
import sdohMemberListSliceReducer from "../features/sdohAssessmentDashboard/slices/SDOHMembersListSlice";
import sdohStatusListSliceReducer from "../features/sdohAssessmentDashboard/slices/SDOHMembersStatusSlice";
import sdohmemberDetailsReducer from "../features/sdohAssessmentDashboard/slices/SDOHMemberDetailsSlice";
import medicalAdherenceReducer from "../features/medicalAdherenceTool/MedicalAdherenceToolSlice";
import getProgramsListReducer from "../features/educationConfiguration/ProgramsConfiguration/ProgramsListSlice";
import getActiveGapClosuresReducer from "../features/activeGapClosuresList/Slices/activeGapClosuresListSlice";
import getTrainingProgramsListReducer from "../features/HealthHomeEducationConfiguration/TrainingPrograms/TrainingProgramsSlice";
import getTrainingDocumentsListReducer from "../features/HealthHomeEducationConfiguration/TrainingDocuments/TrainingDocumentsSlice";
export const store = configureStore({
  reducer: {
    familyMembersProfile: familyMembersProfileReducer,
    authorization: authorizationReducer,
    familyMembersProfileSearch: familyMembersProfileSearchReducer,
    powerBi: powerBiReducer,
    powerBiReport: powerBiReportReducer,
    derosteringTracking: derosteringTrackingReducer,
    voluntaryAlignmentMembersList: voluntaryAlignmentMembersListReducer,
    usersData: getUsersListReducer,
    userRolesData: getUserRolesListReducer,
    derosteringReasons: derosteringReasonsReducer,
    memberDetails: memberDetailsProfileReducer,
    roleMappingData: getRoleMappingListReducer,
    userRoleMappingData: getUserRoleMappingListReducer,
    downloadDerosteringMembers: downloadDerosteringMembersReducer,
    pillarsToolsScorecard: pillarsToolsScorecardReducer,
    filterDetails: filterDetailsReducer,
    sdohmemberDetails: sdohmemberDetailsReducer,
    sdohMemberList: sdohMemberListSliceReducer,
    sdohStatusList: sdohStatusListSliceReducer,
    marketsList: marketsListReducer,
    practiceList: practiceListReducer,
    medicalAdherence: medicalAdherenceReducer,
    programsData: getProgramsListReducer,
    activeGapClosures: getActiveGapClosuresReducer,
    trainingProgramsData: getTrainingProgramsListReducer,
    trainingDocumentsData: getTrainingDocumentsListReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// export type RootState = ReturnType;
export const useAppDispatch = () => useDispatch();

export default store;
