import React from "react";
import PageHeader from "../../component/common/header/PageHeader";

const ERAlertClosureFromTextingHeader = () => {
  const title = "ER Alert Closure From Texting";
  const sevenPillarTitle = "Non- Emergent ER";
  return (
    <>
      <PageHeader title={title} sevenPillarTitle={sevenPillarTitle} />
    </>
  );
};

export default ERAlertClosureFromTextingHeader;
