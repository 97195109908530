import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/Agent";

const initialState = {
  isLoading: false,
  derosteringMembers: {},
  errorMessage: "",
};

export const downloadDerosteringMembers = createAsyncThunk(
  "downloadDerosteringMembers",
  async () => {
    const response =
      await agent.DerosteringTrackingAgent.downloadDerosteringMembers();
    return response;
  }
);

const downloadDerosteringMembersSlice = createSlice({
  name: "downloadDerosteringMembers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadDerosteringMembers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(downloadDerosteringMembers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.derosteringMembers = action.payload;
      })
      .addCase(downloadDerosteringMembers.rejected, (state, action) => {
        state.isLoading = false;
        state.derosteringMembers = {};
        state.errorMessage = action.error.message;
      });
  },
});

export default downloadDerosteringMembersSlice.reducer;
