import React from "react";
import FamilyMemberProfile from "../../features/familyMembersProfile/FamilyMemberProfile";

const FamilyMembersProfilePage = () => {
  return (
    <>
      <FamilyMemberProfile />
    </>
  );
};

export default FamilyMembersProfilePage;
