import { axiosRequests } from "../AxiosRequest";

function getActiveGapClosuresList(requestBody) {
  return axiosRequests.post(
    "/ActiveGapClosure/GetActiveGapClosureMembersList",
    requestBody
  );
}

export const ActiveGapClosuresAgent = {
  getActiveGapClosuresList: getActiveGapClosuresList,
};
