import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterDetail: {},
  memberDetail:{},
  activeStatus:{},
  assessmentStore:{},
  memberId:"",
  cohortId:"",
  marketFiltered:[],
  subMarketFiltered:[],
  practiceFiltered:[],
  barChartData:[],
  pieChartData:[],
  statusList:[],
  pageReset:false,
  searchReset:false,
  isDisplayStatusSection:false,
  isDisplaySearchGrid:false,
  isSearchTriggered:false,
  displayBarChartSection:false,
  displayPieChartSection:false,
  showDashboardPage:true,
  showMemberDetailPage:false,
  showAssessmentDetailPage:false,
  isStatusListTrigger:false,
  isClearSections:false,
  isApplyFilter:false,
  newEmailAddress:"",
  selectedRows:[],
  cohortDetails:{},
  cohortFilterDetails:{},
  admissionTrendsDetails:{},
  gridSelectionModel:[],
  gridTempSelectionModel:[],
  gridTempSelectedRows:[],
  modalFilterDetails:{}
};

const filterDetailsSlice = createSlice({
  name: "filterDetails",
  initialState,
  reducers: {
    getFilterDetails: (state, action) => {
      state.filterDetail = action.payload;
    },
    getMemberDetails: (state, action) => {
      state.memberDetail = action.payload;
      state.errorMessage="";
    },
    getMemberId: (state, action) => {
      state.memberId = action.payload;
    },
    getCohortId: (state, action) => {
      state.cohortId = action.payload;
    },
    getActiveStatus: (state, action) => {
      state.activeStatus = action.payload;
    },
    getSelectedRows: (state, action) => {
      state.selectedRows = action.payload;
    },
    getCohortDetails: (state, action) => {
      state.cohortDetails = action.payload;
    },
    getCohortFilterDetails: (state, action) => {
      state.cohortFilterDetails = action.payload;
    },
    getGridSelectionModel: (state, action) => {
      state.gridSelectionModel = action.payload;
    },
    getGridTempSelectionModel: (state, action) => {
      state.gridTempSelectionModel = action.payload;
    },
    getGridTempSelectedRows: (state, action) => {
      state.gridTempSelectedRows = action.payload;
    },
    
    getAdmissionTrendsDetail:(state, action) => {
      state.admissionTrendsDetails = action.payload;
    },
    getDisplayStatusSection: (state, action) => {
      state.isDisplayStatusSection = action.payload;
    },
    getDisplaySearchGridSection: (state, action) => {
      state.isDisplaySearchGrid = action.payload;
    },
    getSearchTriggered: (state, action) => {
      state.isSearchTriggered = action.payload;
    },
    getAssessmentArray: (state, action) => {
      state.assessmentStore = action.payload;
    },
    getMarketsFilterDetails: (state, action) => {
      state.marketFiltered = action.payload;
    },
    getSubMarketsFilterDetails: (state, action) => {
      state.subMarketFiltered = action.payload;
    },
    getPracticesFilterDetails: (state, action) => {
      state.practiceFiltered = action.payload;
    },
    getDisplayBarChartSection: (state, action) => {
      state.displayBarChartSection = action.payload;
    },
    getDisplayPieChartSection: (state, action) => {
      state.displayPieChartSection = action.payload;
    },
    getBarChartDetailsData: (state, action) => {
      state.barChartData = action.payload;
    },
    getPieChartDetailsData: (state, action) => {
      state.pieChartData = action.payload;
    },
    getStatusDetailsData: (state, action) => {
      state.statusList = action.payload;
    },
    getShowDashboardPage:(state,action)=>{
      state.showDashboardPage = action.payload;
    },
    getShowMemberDetailPage:(state,action)=>{
      state.showMemberDetailPage = action.payload;
    },
    getShowAssessmentDetailPage:(state,action)=>{
      state.showAssessmentDetailPage = action.payload;
    },
    getpageResetDetail:(state,action)=>{
      state.pageReset = action.payload;
    },
    getsearchResetDetail:(state,action)=>{
      state.searchReset = action.payload;
    },
    getIsStatusListTrigger:(state,action)=>{
      state.isStatusListTrigger = action.payload;
    },
    getIsClearSections:(state,action)=>{
      state.isClearSections = action.payload;
    },
    getIsApplyFilter:(state,action)=>{
      state.isApplyFilter = action.payload;
    },
    getNewEmailAddress:(state,action)=>{
      state.newEmailAddress = action.payload;
    },
    getModalFilterDetails:(state,action)=>{
      state.modalFilterDetails = action.payload;
    },
    // getCohortDetails:(state,action)=>{
    //   state.cohortFilterDetails = action.payload;
    // },
  },
});

export default filterDetailsSlice.reducer;
export const { getFilterDetails, getMemberDetails, getActiveStatus, getAssessmentArray,
  getMemberId, getMarketsFilterDetails, getSubMarketsFilterDetails, getPracticesFilterDetails,
  getDisplayStatusSection, getDisplaySearchGridSection, getSearchTriggered,
  getDisplayBarChartSection, getDisplayPieChartSection, getBarChartDetailsData, getPieChartDetailsData,
  getStatusDetailsData, getShowDashboardPage, getShowMemberDetailPage, getShowAssessmentDetailPage,
  getpageResetDetail,getsearchResetDetail,getIsStatusListTrigger,getIsClearSections, getIsApplyFilter,
  getNewEmailAddress, getSelectedRows, getCohortDetails, getCohortFilterDetails,getAdmissionTrendsDetail,
  getGridSelectionModel, getGridTempSelectionModel, getGridTempSelectedRows, getCohortId, getModalFilterDetails
 } = filterDetailsSlice.actions;
