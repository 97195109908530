import { Box, Button, Chip, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { axiosRequests } from "../../app/api/AxiosRequest";
import { Styles } from "../../Styles";
import DataGridHeader from "../../component/common/datagrid/DataGridHeader";
import DataGridCustom from "../../component/common/datagrid/DataGridCustom";
import Loader from "../../component/common/loader/Loader";
import { saveAs } from "file-saver";
import { format } from "date-fns";

const ViewDocuments = ({ isOpen, onClose, ourId, rowId, source, detailId }) => {
  const [filesList, setFilesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [title] = useState(
    source === "MedicalCoding" ? "Recapture Codes" : "Open Quality Measure"
  );
  const download = (id) => {
    if (source === "OpenQuality") {
      axiosRequests
        .downloadFile(
          `ActiveGapClosure/DownloadOpenQualityMeasureFile?OpenQualityMeasureFileId=${id}`
        )
        .then((response) => {
          let filename = "";
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          let matches = filenameRegex.exec(
            response.headers["content-disposition"]
          );
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
          }
          let url = window.URL.createObjectURL(new Blob([response.data]));
          saveAs(url, filename);
          // setOpen(true);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (source === "MedicalCoding") {
      axiosRequests
        .downloadFile(
          `ActiveGapClosure/DownloadMedicalCodingSuspectFile?MedicalCodingSuspectId=${id}`
        )
        .then((response) => {
          let filename = "";
          let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          let matches = filenameRegex.exec(
            response.headers["content-disposition"]
          );
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "");
          }
          let url = window.URL.createObjectURL(new Blob([response.data]));
          saveAs(url, filename);
          // setOpen(true);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    if (source === "OpenQuality") {
      axiosRequests
        .get(
          `/ActiveGapClosure/GetOpenQualityMeasureFiles?OurId=${ourId}&OpenQualityMeasureId=${rowId}`
        )
        .then((resp) => {
          setFilesList(resp);
          setIsLoading(false);
        });
    } else if (source === "MedicalCoding") {
      axiosRequests
        .get(
          `/ActiveGapClosure/GetMedicalCodingSuspectFiles?OurId=${ourId}&MedicalCodingSuspectId=${rowId}&MedicalCodeSuspectDetailId=${detailId}`
        )
        .then((resp) => {
          setFilesList(resp);
          setIsLoading(false);
        });
    }
  }, []);
  const columnsReports = [
    {
      field: "fileName",
      headerName: "File Name",
      flex: 3,
    },
    {
      field: "createdOn",
      headerName: "Uploaded On",
      flex: 1,
      renderCell: (params) => {
        return <span>{format(params.row.createdOn, "MM/dd/yyyy")}</span>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            color="blackTheme"
            onClick={() => download(params.row.id)}
            sx={{ textTransform: "none" }}
            variant="outlined"
          >
            Download File
          </Button>
        );
      },
    },
  ];

  const columnsClaims = [
    {
      field: "fileName",
      headerName: "File Name",
      flex: 3,
    },
    {
      field: "createdOn",
      headerName: "Uploaded On",
      flex: 1,
      renderCell: (params) => {
        return <span>{format(params.row.createdOn, "MM/dd/yyyy")}</span>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <Button
            color="blackTheme"
            onClick={() => download(params.row.id)}
            sx={{ textTransform: "none" }}
            variant="outlined"
          >
            Download File
          </Button>
        );
      },
    },
  ];

  const getRowId = (row) => {
    return rowId.id + " " + row.fileName;
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      slotProps={{ backdrop: { onClick: null } }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "1100px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          pt: 1,
          height: "auto",
        }}
      >
        <h3
          style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
        >
          {title} Documents{" "}
          <span>
            <CloseIcon onClick={onClose} style={{ float: "right" }} />
          </span>
        </h3>
        <hr
          style={{
            width: "auto",
            opacity: "0.3",
            marginTop: "-8px",
            // marginBottom: "-4px",
          }}
        />
        {isLoading ? (
          <Loader isLoading={isLoading}></Loader>
        ) : (
          <DataGridCustom
            columns={columnsReports}
            rows={filesList.evidence}
            pageSize={2}
            getRowId={getRowId}
            loading={isLoading}
            sortingOrder={["asc", "desc"]}
            slots={{
              toolbar: DataGridHeader,
            }}
            slotProps={{
              toolbar: {
                headerText: "Supporting Documents",
                fontSize: "16px",
                marginTop: "auto",
                marginBottom: "auto",
                height: "56px",
                children: (
                  <div
                    style={{
                      fontFamily: Styles.FONT_FAMILY_POPPINS,
                      fontSize: "16px",
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginLeft: "5px",
                    }}
                  >
                    <Chip
                      color="orangeTheme"
                      variant="contained"
                      style={{ color: "#fff" }}
                      label={filesList.evidence.length}
                    ></Chip>
                  </div>
                ),
              },
            }}
          />
        )}
        {isLoading ? (
          <Loader isLoading={isLoading}></Loader>
        ) : (
          <DataGridCustom
            columns={columnsClaims}
            rows={filesList.claims}
            pageSize={2}
            getRowId={getRowId}
            loading={isLoading}
            sortingOrder={["asc", "desc"]}
            slots={{
              toolbar: DataGridHeader,
            }}
            slotProps={{
              toolbar: {
                headerText: "Claims Documents",
                fontSize: "16px",
                marginTop: "auto",
                marginBottom: "auto",
                height: "56px",
                children: (
                  <div
                    style={{
                      fontFamily: Styles.FONT_FAMILY_POPPINS,
                      fontSize: "16px",
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginLeft: "5px",
                    }}
                  >
                    <Chip
                      color="orangeTheme"
                      variant="contained"
                      style={{ color: "#fff" }}
                      label={filesList.claims.length}
                    ></Chip>
                  </div>
                ),
              },
            }}
          />
        )}
      </Box>
    </Modal>
  );
};

export default ViewDocuments;
