import React from "react";
import PageHeader from "../../component/common/header/PageHeader";

const UserAdminConsoleHeader = () => {
    const title = "User Management";
    const sevenPillarTitle = "User Configuration";
  return (
    <>
   
      <PageHeader title={title} disablePracticeAdminLink={true} sevenPillarTitle={sevenPillarTitle}/>
      
    </>
  );
};

export default UserAdminConsoleHeader;
