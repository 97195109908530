import { PagesPath } from "../PagesPath";
// import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
// import settings_active from '@mui/icons-material/Settings';
import school from "../assets/school.svg";
import school_active from "../assets/school_orange.svg";

export const ProgramJson = {
  id: 1,
  name: "Programs",
  key: "Programs",
  iconurl: school,
  activeiconurl: school_active,
  defaulturl: PagesPath.Programs_URL
};
