import React from "react";
import PowerBiCustomDashboard from "../../component/common/powerbi/PowerBiCustomDashboard";

const SpendSummary = () => {
  return (
    <section id="bi-report">
      <PowerBiCustomDashboard groupId="0bcbb78e-88cf-48a2-990f-f152b3b54c8a" dashboardId="2d348e19-95ba-400c-ae4e-bc0457bf40c0" />
    </section>
  );
};

export default SpendSummary;
