import React from "react";
import PageHeader from "../../component/common/header/PageHeader";

const CensusToolMarketHospitalAndPracticeHeader = () => {
  const title = "Daily Census Tool for IP";
  const sevenPillarTitle = "Avoidable Inpatient Stays";
  return (
    <>
      <PageHeader title={title} sevenPillarTitle={sevenPillarTitle} />
    </>
  );
};

export default CensusToolMarketHospitalAndPracticeHeader;
