import {
  Box,
  Button,
  IconButton,
  ListItem,
  ListItemText,
  Modal,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Styles } from "../../Styles";
import CloseIcon from "@mui/icons-material/Close";
import { axiosRequests } from "../../app/api/AxiosRequest";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Loader from "../../component/common/loader/Loader";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import { saveAs } from "file-saver";

const DetailTitle = styled(Typography)({
  overflow: "hidden",
  color: "#DC4F34",
  textOverflow: "ellipsis",
  fontFamily: "Poppins",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  letterSpacing: "0.15px",
});
const DetailDescription = styled(Typography)({
  color: "#000",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  letterSpacing: "0.014px",
  marginTop: "8px",
});

const HealthHomeDocumentDetailsModal = ({ isOpen, onClose, rowId }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [files, setFiles] = useState([]);
  const [section, setSection] = useState("");
  const [subSection, setSubSection] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isOpen && rowId > 0) {
      axiosRequests
        .get(
          `/TrainingDocument/GetTrainingDocumentDetailsById?trainingDocumentId=${rowId}`
        )
        .then((resp) => {
          console.log(resp);
          setSection(resp.section);
          setSubSection(resp.subSection);
          setTitle(resp.title);
          setDescription(resp.description);
          setFiles(resp.documents);
          setIsLoading(false);
          //setId(resp.id);
        });
    }
  }, []);

  const download = (id) => {
    axiosRequests
      .downloadFile(
        `TrainingDocument/DownloadTrainingDocumentByFileId?fileId=${id}`
      )
      .then((response) => {
        let filename = "";
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(
          response.headers["content-disposition"]
        );
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
        let url = window.URL.createObjectURL(new Blob([response.data]));
        saveAs(url, filename);
        // setOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      style={{ overflow: "initial" }}
      disableScrollLock
      slotProps={{ backdrop: { onClick: null } }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "678px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          pt: 1,
          height: "auto",
        }}
      >
        <h3
          style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
        >
          Training Document Details
          <span>
            <CloseIcon onClick={onClose} style={{ float: "right" }} />
          </span>
        </h3>
        <hr
          style={{
            width: "auto",
            opacity: "0.3",
            marginTop: "-10px",
            marginBottom: "14px",
          }}
        />
        {isLoading ? (
          <Loader isLoading={isLoading} />
        ) : (
          <>
            <DetailTitle>{title}</DetailTitle>
            <Stack direction="row" spacing={3}>
              <Typography
                style={{
                  fontSize: "12px",
                  fontFamily: Styles.FONT_FAMILY_POPPINS,
                }}
              >
                Section: {section}
              </Typography>
              <Typography
                style={{
                  fontSize: "12px",
                  fontFamily: Styles.FONT_FAMILY_POPPINS,
                }}
              >
                Sub Section: {subSection}
              </Typography>
            </Stack>
            <DetailDescription style={{ marginBottom: "8px" }}>
              {description}
            </DetailDescription>
            <div
              style={{
                maxHeight: "100px",
                overflowY: "scroll",
              }}
            >
              {files.map((file, index) => (
                <ListItem
                  key={index}
                  style={{
                    background: "#F1F1F1",
                    marginBottom: "8px",
                  }}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      style={{ marginTop: "0px" }}
                      onClick={() => download(file.id)}
                    >
                      <FileDownloadOutlinedIcon />
                    </IconButton>
                  }
                >
                  <Tooltip title={file.fileName}>
                    <ListItemText
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      primary={
                        <>
                          <TextSnippetOutlinedIcon
                            style={{ marginBottom: "-6px" }}
                          />{" "}
                          {file.fileName}
                        </>
                      }
                    ></ListItemText>
                  </Tooltip>
                </ListItem>
              ))}
            </div>
            <Stack
              direction="row"
              spacing={1}
              style={{ marginTop: "8px", justifyContent: "flex-end" }}
            >
              <Button
                style={{
                  borderColor: "black",
                  color: "black",
                  fontFamily: Styles.FONT_FAMILY_POPPINS,
                  marginLeft: "23px",
                  textTransform: "none",
                }}
                variant="text"
                onClick={onClose}
              >
                Close
              </Button>
              {/* <a href={url} target="_blank">
            <Button
              style={{
                color: "white",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
                textTransform: "none",
              }}
              variant="contained"
              color="orangeTheme"
              disableElevation
              onClick={() => onClose()}
            >
              Start Program
            </Button>
          </a> */}
            </Stack>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default HealthHomeDocumentDetailsModal;
