import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  memberDetail: {},
};

const memberDetailsSlice = createSlice({
  name: "memberDetails",
  initialState,
  reducers: {
    getMemberDetails: (state, action) => {
      state.memberDetail = action.payload;
    },
  },
});

export default memberDetailsSlice.reducer;
export const { getMemberDetails } = memberDetailsSlice.actions;
