import { models } from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../app/store";
import { getReportByGroupIdAndReportId } from "../../../features/powerBi/powerBiReportSlice";
import Loader from "../loader/Loader";
import { COLOR, Styles } from "../../../Styles";
import { Typography } from "@mui/material";

const PowerBiCustomReport = ({ groupId, reportId }) => {
  const dispatch = useAppDispatch();

  const { isLoading, data, errorMessage } = useSelector(
    (state) => state.powerBiReport
  );
  const getReportDetails = useCallback(async () => {
    const response = await dispatch(
      getReportByGroupIdAndReportId({
        groupId: groupId,
        reportId: reportId,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    getReportDetails();
  }, [getReportDetails]);

  const getCssBasedOnSize = () => {
    console.log('Width - '+window.innerWidth+' Height -'+ window.innerHeight+' Pixel - '+window.devicePixelRatio);
    var embeddedCSS = 'bi-embedded';
    try {
      if(window.innerHeight > 900)
        embeddedCSS = 'bi-embedHighPixel';
      else if(window.innerHeight > 700)
        embeddedCSS = 'bi-embedMediumPixel';
    }
    catch {
      embeddedCSS = 'bi-embedHighPixel';
    }
    return embeddedCSS;
  };  

  return (
    <>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : errorMessage !== "" ? (
        <Typography
          sx={{
            color: COLOR.FONT_ORANGE,
            textAlign: "center",
            leading: "both",
            fontFamily: Styles.FONT_FAMILY_POPPINS,
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "600",
            whiteSpace: "pre-line",
            lineHeight: "20px" /* 137.5% */,
          }}
        >
          {errorMessage}
        </Typography>
      ) : (
        <PowerBIEmbed
          style={{ innerHeight: "100%", outerHeight: "100%" }}
          // embedConfig={reportConfig}
          embedConfig={{
            type: "report", // Since we are reporting a BI report, set the type to report
            id: data.id, // Add the report Id here
            embedUrl: data.embedUrl, // Add the embed url here
            accessToken: data.accessToken,
            tokenType: models.TokenType.Aad, // Since we are using an Azure Active Directory access token, set the token type to Aad
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },               
              },
              layoutType: models.LayoutType.Custom,
              customLayout: {
                  displayOption: models.DisplayOption.FitToWidth // Default display option
              }
            },
          }}
          eventHandlers={
            new Map([
              [
                "loaded",
                function () {
                  console.log("Report loaded");
                },
              ],
              [
                "rendered",
                function () {
                  console.log("Report rendered");
                },
              ],
              [
                "error",
                function (event) {
                  console.log(event.detail);
                },
              ],
              // ["visualClicked", () => console.log("visual clicked")],
              // ["pageChanged", (event) => console.log(event)],
            ])
          }
           // cssClassName="bi-embedded"
           cssClassName={getCssBasedOnSize()}
          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport; // save report in window object
          }}
        />
      )}
    </>
  );
};

export default PowerBiCustomReport;
