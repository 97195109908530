import { Box, Typography } from "@mui/material";
import { Styles } from "../../../Styles";

const DataGridHeader = ({
  headerText,
  fontSize,
  marginTop,
  marginBottom,
  height,
  children,
}) => {
  return (
    <Box
      style={{
        width: "100%",
        height: height,
        display: "flex",
        paddingLeft: "5px",
        paddingRight: "5px",
      }}
    >
      <Typography
        style={{
          fontFamily: Styles.FONT_FAMILY_POPPINS,
          fontSize: fontSize,
          marginTop: marginTop,
          marginBottom: marginBottom,
        }}
      >
        {headerText}
      </Typography>

      {children}
    </Box>
  );
};

export default DataGridHeader;
