import {
  Box,
  Button,
  Grid,
  Modal,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Styles } from "../../Styles";
import { axiosRequests } from "../../app/api/AxiosRequest";

const DetailTitle = styled(Typography)({
  overflow: "hidden",
  color: "#DC4F34",
  textOverflow: "ellipsis",
  fontFamily: "Poppins",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  letterSpacing: "0.15px",
});
const DetailDescription = styled(Typography)({
  color: "#000",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  letterSpacing: "0.014px",
  marginTop: "8px",
});

const ProgramDetailsModal = ({ isOpen, onClose, name, description, url, id }) => {
  const handleOpenProgramClick = () => {
    axiosRequests.post(`/EducationProgram/UpdateActivityTracker?ActivityType=Programs&ActivityID=${id}`).then(resp => {
      console.log("Updated Succesfully");
    })
  }
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      style={{ overflow: "initial" }}
      disableScrollLock
      slotProps={{ backdrop: { onClick: null } }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "678px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          pt: 1,
          height: "auto",
        }}
      >
        <h3
          style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
        >
          Program Details
          <span>
            <CloseIcon onClick={onClose} style={{ float: "right" }} />
          </span>
        </h3>
        <hr
          style={{
            width: "auto",
            opacity: "0.3",
            marginTop: "-10px",
            marginBottom: "14px",
          }}
        />
        <DetailTitle>{name}</DetailTitle>
        <DetailDescription>{description}</DetailDescription>
        <Stack
          direction="row"
          spacing={1}
          style={{ marginTop: "8px", justifyContent: "flex-end" }}
        >
          <Button
            style={{
              borderColor: "black",
              color: "black",
              fontFamily: Styles.FONT_FAMILY_POPPINS,
              marginLeft: "23px",
            }}
            variant="outlined"
            onClick={onClose}
          >
            Cancel
          </Button>
          <a href={url} target="_blank">
            <Button
              style={{ color: "white", fontFamily: Styles.FONT_FAMILY_POPPINS }}
              variant="contained"
              color="orangeTheme"
              onClick={handleOpenProgramClick}
              disableElevation
            >
              Start Program
            </Button>
          </a>
        </Stack>
      </Box>
    </Modal>
  );
};

export default ProgramDetailsModal;
