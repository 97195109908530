import { Download } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { saveAs } from "file-saver";
import React from "react";
import { COLOR, Styles } from "../../Styles";
import agent from "../../app/api/Agent";
import PageHeader from "../../component/common/header/PageHeader";
import Searchbox from "../../component/common/searchbox/Searchbox";
import SnackbarAlert from "../../component/common/snackbarAlert/SnackbarAlert";

const DerosteringTrackingHeader = ({
  // isGlobalAdmin = false,
  updateSearch,
  accessObj,
  enableBulkUpdateButton = false,
  handleBulkUpdateClick = undefined,
}) => {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState(null);

  const handleSearch = (value) => {
    setSearch(value);
    //updateSearch(value);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const download = () => {
    agent.DerosteringTrackingAgent.downloadDerosteringMembers()
      .then((response) => {
        let filename = "";
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(
          response.headers["content-disposition"]
        );
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
        let url = window.URL.createObjectURL(new Blob([response.data]));
        saveAs(url, filename);
        setOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const title = "De-rostering Tracking";
  const sevenPillarTitle = "Practice VBC Revenue & Growth";
  return (
    <>
      <PageHeader title={title} sevenPillarTitle={sevenPillarTitle}>
        <Stack
          direction="row"
          spacing={2}
          style={{
            marginLeft: "auto",

            float: "right",
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Searchbox
            sx={{
              display: !accessObj || !accessObj.hasAccess ? "none" : "",
            }}
            defaultValue={search}
            label="Search Member"
            handleChange={(e) => handleSearch(e.target.value)}
            onClick={() => updateSearch(search)}
          />

          {!enableBulkUpdateButton && (
            <Button
              sx={{
                textTransform: "none",
                color: "#111827",
                border: "1px solid",
                borderColor: COLOR.ORANGE,
                textAlign: "center",
                leadingTrim: "both",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
                fontSize: "12px",
                fontStyle: Styles.FONT_STYLE_NORMAL,
                fontWeight: "500",
                lineHeight: "22px",
                display:
                  !accessObj ||
                  !accessObj.isGlobalAdmin ||
                  enableBulkUpdateButton
                    ? "none"
                    : "",
                "&.MuiButton-root:hover": {
                  borderColor: COLOR.ORANGE,
                  border: "1px solid #F37021",
                  //   borderRadius: "4px",
                },
              }}
              variant="outlined"
              color="orangeTheme"
              onClick={() => download()}
            >
              <Stack
                spacing={1}
                direction="row"
                sx={{
                  margin: 0,
                  padding: 0,
                  height: "100%",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Download
                  fontSize="small"
                  // sx={{ width: "24px", height: "24px" }}
                />
                <Typography
                  sx={{
                    color: "#343A40",
                    textAlign: "center",
                    leadingTrim: "both",
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "22px" /* 157.143% */,
                  }}
                >
                  Export Report
                </Typography>
              </Stack>
            </Button>
          )}
          {enableBulkUpdateButton && (
            <Button
              sx={{
                textTransform: "none",
                color: "#111827",
                border: "1px solid",
                borderColor: "#000",
                textAlign: "center",
                leadingTrim: "both",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
                fontSize: "12px",
                fontStyle: Styles.FONT_STYLE_NORMAL,
                fontWeight: "500",
                lineHeight: "22px",
                //display: !accessObj || !accessObj.isGlobalAdmin || enableBulkUpdateButton ? "none" : "",
                "&.MuiButton-root:hover": {
                  borderColor: "#000",
                  border: "1px solid #F37021",
                  //   borderRadius: "4px",
                },
              }}
              variant="outlined"
              color="orangeTheme"
              onClick={() => handleBulkUpdateClick()}
            >
              <Stack
                spacing={1}
                direction="row"
                sx={{
                  margin: 0,
                  padding: 0,
                  height: "100%",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "#343A40",
                    textAlign: "center",
                    leadingTrim: "both",
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "22px" /* 157.143% */,
                  }}
                >
                  Bulk Status Update
                </Typography>
              </Stack>
            </Button>
          )}
        </Stack>
      </PageHeader>
      <SnackbarAlert
        message="Report Downloaded Successfully."
        open={open}
        handleClose={handleClose}
      />
    </>
  );
};

export default DerosteringTrackingHeader;
