import React, { useEffect } from "react";
import { Chip, Grid, makeStyles, Stack, styled, Typography } from "@mui/material";
import html2pdf from "html2pdf.js";
import CohortMembersListPDF from "./CohortMembersListPDF";
const StyledPDFCanvas = styled(Grid)({
  width: "auto",
  height: "auto",
});

export const MultilineText = styled(Typography)({
  display: "-webkit-box",
  "-webkit-line-clamp": "2",
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "normal",
});
const PdfGenerator = ({ logoSrc, titleText, cohortId, memberDetail,downloadCount,onclose }) => {
  // var downloadCount = 0;
  const handlePDFDownload = () => {
    // const element = document.getElementById("pdf-data");
    // html2pdf().from(element).save(`${cohortId}.pdf`);

    if (downloadCount === 0) {
      const element = document.getElementById('pdf-data');
      // html2pdf(element, {
      //   // margin: 1,
      //   filename: `${cohortId}.pdf`,
      //   image: { type: 'jpeg', quality: 0.98 },
      //   html2canvas: { scale: 2 },
      //   pageBreak: { mode: 'css', after: '.break-page' },
      //   jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait' }
      // });
      
      const options = {
        html2canvas: {scale: 3, dpi:200},
        margin: 1,
        pageBreak: { mode: 'css', after: '.break-page' },
        jsPDF: {format: 'letter', orientation: 'landscape'}
      }
      html2pdf().from(element).set(options).save(`${cohortId}.pdf`);


      setTimeout(()=>{
        element.style.display="none";
        onclose();
      },10)

    }
  };

  useEffect(() => {
    handlePDFDownload();
    downloadCount++;
  }, [cohortId]);

  return (
    // style={{display:'none'}}
    <div id="pdf-data"  style={{ padding: "6px", width:"100%", paddingBottom: "24px"}}>
      {/* <StyledPDFCanvas id="pdf-data" style={{ padding: "6px", }}> */}
        {/* <PDFHeader /> */}
       
        <CohortMembersListPDF
          memberDetail={memberDetail}
        />

      {/* </StyledPDFCanvas> */}
    </div>
  );
}

export default PdfGenerator;