import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
  Link,
  Button,
  Stepper,
  Step,
  StepButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { COLOR, Styles } from "../../../Styles";
import back_arrow from "../../../assets/back_arrow.svg";
import { useAppDispatch } from "../../../app/store";
// import CreateCohortRegistryHeader from "./CreateCohortRegistryHeader";
import { checkUserToolAccess } from "../../../component/common/userAuthorization/checkUserAccess";
import NoAccessPage from "../../../component/common/noAccess/NoAccessPage";
import { useSelector } from "react-redux";
import { PagesPath } from "../../../PagesPath";
import CreateCohortRegistryHeader from "../CreateCohortRegistryManagement/CreateCohortRegistryHeader";
import CohortByImport_Step1 from "./CohortByImport_Step1";
import CohortByImport_Step2 from "./CohortByImport_Step2";
import CohortByImport_Step3 from "./CohortByImport_Step3";
import { axiosRequests } from "../../../app/api/AxiosRequest";
import { format } from "date-fns";

export function formatCost(number) {
  // Convert number to string
  if (number != undefined) {
    const numberStr = number.toString();

    // Match digits and decimal point using regex
    const matches = numberStr.match(/^(\d+)(?:\.(\d+))?$/);

    if (matches) {
      // Get the integer part and fractional part (if exists)
      const integerPart = (+matches[1]).toLocaleString();
      const fractionalPart = matches[2] ? matches[2].padEnd(2, "0") : "00";

      // Format the number in account balance format -- ₹
      const formattedBalance = `$ ${integerPart}`; //.${fractionalPart}
      return formattedBalance;
    } else {
      return "Invalid input";
    }
  }
}

const CreateCohortByImport = () => {
  const CohortRegistry = 39;
  let { state } = useLocation();
  const [accessObj] = useState(checkUserToolAccess(CohortRegistry));
  const [selectedRows, setSelectedRows] = useState([]);
  const [podName, setPODName] = useState("");
  const [cohortName, setCohortName] = useState("");
  const [cohortDescription, setCohortDescription] = useState("");
  const [cohortOwner, setCohortOwner] = useState("");
  const [cohortStartDate, setCohortStartDate] = useState(new Date());
  const [fileName, setFileName] = useState("");
  const [admissionTrendValue, setAdmissionTrendValue] = useState("1");
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [cohortId, setCohortId] = useState(0);
  const [isEditView, setIsEditView] = useState(false);
  const [preLoadedCohortData, setPreLoadedCohortData] = useState(false);
  const [isLoadingEditData, setIsLoadingEditData] = useState(false);
  const navigate = useNavigate();
  const backToHome = () => {
    var idUrl =
      cohortId > 0
        ? PagesPath.ViewCohortRegistry_URL
        : PagesPath.CohortRegistryManagement_URL;
    navigate(idUrl);
  };
  const steps = ["Filters", "Details", "Preview"]; //'About',
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState([]);
  const [isCustomDateRangeSelected, setIsCustomDateRangeSelected] =
    useState(false);
  const arr = [];

  const { memberId } = useSelector((state) => state.filterDetails);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  useEffect(() => {
    if (state && state.cohortId && state.cohortId > 0) {
      setCohortId(state.cohortId);
      setIsLoadingEditData(true);
    }
  }, [state]);

  useEffect(() => {
    if (cohortId > 0) {
      let requestBody = {
        cohortId: cohortId,
        startDate: state.startDate,
        endDate: state.endDate,
      };
      setIsEditView(true);
      axiosRequests
        .post(`/CohortImport/GetCohortMemberList`, requestBody)
        .then((resp) => {
          setPreLoadedCohortData(resp.cohortMembersDetails);
          setIsLoadingEditData(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoadingEditData(false);
        });
      axiosRequests
        .get(`/Cohort/GetCohortFilterDatas?CohortId=${cohortId}`)
        .then((resp) => {
          setPODName(resp[0].podName);
          setCohortName(resp[0].cohortName);
          setCohortDescription(resp[0].description);
          setCohortOwner(resp[0].cohortOwnerId);
          setCohortStartDate(new Date(resp[0].startDate));
          setIsCustomDateRangeSelected(resp[0].customDateRange);
          setAdmissionTrendValue(parseInt(resp[0].dateRange));
          if (resp[0].customDateRange) {
            setCustomStartDate(new Date(resp[0].fromDate));
            setCustomEndDate(new Date(resp[0].toDate));
          }
          setIsLoadingEditData(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoadingEditData(false);
        });
    }
  }, [cohortId]);

  const handleReset = () => {
    setActiveStep(0);
    setCompleted([]);
  };

  const handleNext = () => {
    for (let i = 0; i <= activeStep; i++) {
      arr.push(true);
    }
    setCompleted(arr);
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const stepStyle = {
    paddingTop: 2,
    paddingLeft: 15,
    paddingRight: 15,
    "& .Mui-active": {
      "&.MuiStepIcon-root": {
        color: "warning.main",
      },
      "& .MuiStepConnector-line": {
        borderColor: "warning.main",
      },
    },
    "& .Mui-completed": {
      "&.MuiStepIcon-root": {
        color: "#ed6c02",
      },
      "& .MuiStepConnector-line": {
        borderColor: "secondary.main",
      },
    },
    " .MuiStepIcon-text": {
      fill: "white !important",
    },
  };
  return (
    <Box>
      <CreateCohortRegistryHeader />
      {!accessObj.hasAccess ? (
        <NoAccessPage></NoAccessPage>
      ) : (
        <>
          <Grid container>
            <Grid item xs={12}>
              <Link
                onClick={backToHome}
                key="SDOHMemberDetail"
                state={{ searchNameValue: "" }}
                style={{
                  textDecoration: "none",
                  "&:hover": {
                    color: COLOR.ORANGE,
                  },
                }}
              >
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{
                    alignContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <img src={back_arrow} alt="" />
                  {!isEditView && (
                    <Typography
                      sx={{
                        textAlign: "left",
                        letterSpacing: "1px",
                        color: "#000",
                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                      }}
                    >
                      Create New Cohort
                    </Typography>
                  )}
                  {isEditView && (
                    <Typography
                      sx={{
                        textAlign: "left",
                        letterSpacing: "1px",
                        color: "#000",
                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                      }}
                    >
                      Edit Cohort
                    </Typography>
                  )}
                </Stack>
              </Link>
            </Grid>
          </Grid>
          <Grid container style={{ justifyContent: "center" }} spacing={1.5}>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                marginLeft: "1%",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
              }}
            >
              <Stack sx={{ width: "100%" }}>
                <Stepper nonLinear activeStep={activeStep} sx={stepStyle}>
                  {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                      {index < 3 ? (
                        <StepButton>{label}</StepButton>
                      ) : (
                        <StepButton>{label}</StepButton>
                      )}
                    </Step>
                  ))}
                </Stepper>
                {allStepsCompleted() ? (
                  <>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Stack direction="row" sx={{ pt: 2 }}>
                      <Stack sx={{ flex: "1 1 auto" }} />
                      <Button onClick={handleReset}>Reset</Button>
                    </Stack>
                  </>
                ) : (
                  <>
                    {
                      <div style={{ display: activeStep === 0 ? "" : "none" }}>
                        <CohortByImport_Step1
                          step={activeStep}
                          handleNextCall={handleNext}
                          setSelectedRows={setSelectedRows}
                          selectedRows={selectedRows}
                          handleCancelCall={backToHome}
                          setFileName={setFileName}
                          setCohortName={setCohortName}
                          setPODName={setPODName}
                          admissionTrendValue={admissionTrendValue}
                          setAdmissionTrendValue={setAdmissionTrendValue}
                          customStartDate={customStartDate}
                          setCustomStartDate={setCustomStartDate}
                          customEndDate={customEndDate}
                          setCustomEndDate={setCustomEndDate}
                          isCustomDateRangeSelected={isCustomDateRangeSelected}
                          setIsCustomDateRangeSelected={
                            setIsCustomDateRangeSelected
                          }
                          isEditView={isEditView}
                          cohortId={cohortId}
                          preLoadedCohortData={preLoadedCohortData}
                          setPreLoadedCohortData={setPreLoadedCohortData}
                        />
                      </div>
                    }
                    <div style={{ display: activeStep === 1 ? "" : "none" }}>
                      <CohortByImport_Step2
                        step={activeStep}
                        fileName={fileName}
                        handleNextCall={handleNext}
                        handleBackCall={handleBack}
                        isDisabledMode={false}
                        // ourId={ourId}
                        handleCancelCall={backToHome}
                        podName={podName}
                        setPODName={setPODName}
                        cohortName={cohortName}
                        setCohortName={setCohortName}
                        cohortDescription={cohortDescription}
                        setCohortDescription={setCohortDescription}
                        cohortOwner={cohortOwner}
                        setCohortOwner={setCohortOwner}
                        cohortStartDate={cohortStartDate}
                        setCohortStartDate={setCohortStartDate}
                      />
                    </div>
                    <div style={{ display: activeStep === 2 ? "" : "none" }}>
                      <CohortByImport_Step3
                        step={activeStep}
                        handleNextCall={handleNext}
                        handleBackCall={handleBack}
                        isDisabledMode={true}
                        handleCancelCall={backToHome}
                        podName={podName}
                        cohortName={cohortName}
                        cohortDescription={cohortDescription}
                        cohortOwner={cohortOwner}
                        cohortStartDate={cohortStartDate}
                        selectedRows={selectedRows}
                        admissionTrendValue={admissionTrendValue}
                        setAdmissionTrendValue={setAdmissionTrendValue}
                        customStartDate={customStartDate}
                        setCustomStartDate={setCustomStartDate}
                        customEndDate={customEndDate}
                        setCustomEndDate={setCustomEndDate}
                        isCustomDateRangeSelected={isCustomDateRangeSelected}
                        setIsCustomDateRangeSelected={
                          setIsCustomDateRangeSelected
                        }
                        isEditView={isEditView}
                        cohortId={cohortId}
                      />
                    </div>
                  </>
                )}
              </Stack>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default CreateCohortByImport;
