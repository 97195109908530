import React, { useEffect } from "react";
import PdfTemplate from "./PdfTemplate";
//  import { html2pdf } from "html2pdf.js";
 import html2pdf from 'html2pdf.js';
import { axiosRequests } from "../../app/api/AxiosRequest";

const PdfGenerator = ({logoSrc, titleText, formData, signatureImage}) => {
    var downloadCount = 0; // Prohibit Download to just 1 file for each request.
    const handleDownload = () => {
        if(downloadCount === 0){
            const element = document.getElementById('pdf-container');
        html2pdf().from(element).save(`${formData.mbinumber}.pdf`);
        
        }
        
    };
    useEffect(()=>{
        handleDownload();
        downloadCount++;
    },[]);

    return (
        <div style={{display:'none'}}>
            <PdfTemplate style={{display: 'none'}} logoSrc={logoSrc} titleText={titleText} formData={formData} signatureImage={signatureImage} />
            <div id='pdf-container'>
                {/* <h1>Hello</h1> */}
                <PdfTemplate logoSrc={logoSrc} titleText={titleText} formData={formData} signatureImage={signatureImage}></PdfTemplate>

            </div>
            {/* <button onClick={handleDownload}>Download Pdf</button> */}
        </div>
    )
}

export default PdfGenerator;