import React from "react";
import { COLOR } from "../../../Styles";
import { Tooltip } from "@mui/material";

const ImageSvgAdmin = ({ title, src, size, active, activeSrc }) => {
  return (
    <>
      <Tooltip title={title} placement="right">
        <img
          src={active ? activeSrc : src}
          style={{
            width: size === "small" ? "20px" : "20px",
            height: size === "small" ? "20px" : "20px",
            fill: active ? COLOR.ORANGE : "none",
            marginLeft: '11px'
          }}
        />
      </Tooltip>
    </>
  );
};

export default ImageSvgAdmin;
