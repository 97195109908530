import { Stack, Typography } from "@mui/material";
import React from "react";
import { COLOR, Styles } from "../../../Styles";

const ImageBlockForToolsAndScorecardsLandingPage = ({
  img,
  text,
  description,
  imageWidth,
  imageHeight,
  url,
}) => {
  return (
    <a
      href={url}
      style={{
        underline: "hover",
        color: "inherit",
        textDecoration: "none",
      }}
      //target="_blank"
    >
      <Stack
        width="164px"
        height="200px"
        spacing={1}
        justifyContent="center"
        alignItems="center"
        sx={{
          borderRadius: "10px",
          border: "1px solid #E95110",
          backgroundColor: "#FFF",
          p: 2,
        }}
      >
        <div
          style={{
            width: "60px !important",
            height: "60px !important",
            marginTop: "2px",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={img} alt="" width="100%" height="100%" />
        </div>
        <Typography
          sx={{
            color: COLOR.FONT_ORANGE,
            textAlign: "center",
            leading: "both",
            fontFamily: Styles.FONT_FAMILY_POPPINS,
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: "600",
            whiteSpace: "pre-line",
            lineHeight: "20px" /* 137.5% */,
          }}
        >
          {text}
        </Typography>
        <Typography
          sx={{
            color: COLOR.FONT_BLACK,
            textAlign: "center",
            leading: "both",
            fontFamily: Styles.FONT_FAMILY_POPPINS,
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "16px" /* 137.5% */,
          }}
        >
          {description}
        </Typography>
      </Stack>
    </a>
  );
};

export default ImageBlockForToolsAndScorecardsLandingPage;
