import React, { useEffect, useState } from "react";
import cinq_1 from "../../assets/cinq_1.png";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Styles } from "../../Styles";
import { axiosRequests } from "../../app/api/AxiosRequest";
import { saveAs } from "file-saver";
import { format } from "date-fns";
import DataGridHeader from "../../component/common/datagrid/DataGridHeader";
import DataGridCustom from "../../component/common/datagrid/DataGridCustom";
import Loader from "../../component/common/loader/Loader";

const AuditStatusModal = ({
  isOpen,
  onClose,
  rowData,
  forceUpdate,
  setForceUpdate,
}) => {
  const [filesList, setFilesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hideAuditSection, setHideAuditSection] = useState(true);
  const [selectedAuditStatus, setSelectedAuditStatus] = useState("1");
  const [auditStatusErrorText, setAuditStatusErrorText] = useState("");
  const [selectedAuditRejectionReason, setSelectedAuditRejectionReason] =
    useState([]);
  const [auditRejectionReasonArray, setAuditRejectionReasonArray] = useState(
    []
  );
  const [auditRejectionReasonErrorText, setAuditRejectionReasonErrorText] =
    useState("");
  const [auditRow, setAuditRow] = useState({});
  const AuditStatusArray = [
    { id: 1, auditStatus: "Accept" },
    { id: 2, auditStatus: "Reject" },
  ];

  // const AuditRejectionReasonArray = [
  //   { id: 1, rejectionReason: "No Signature on Visit" },
  //   { id: 2, rejectionReason: "No Date on Visit Document" },
  //   {
  //     id: 3,
  //     rejectionReason: "Documentation does not support one or more codes",
  //   },
  // ];
  useEffect(() => {
    axiosRequests
      .get("/ActiveGapClosure/GetAuditRejectReasons")
      .then((resp) => {
        setAuditRejectionReasonArray(resp);
      });
  }, []);
  const isAllRejectionReasonsSelected =
    auditRejectionReasonArray.length > 0 &&
    selectedAuditRejectionReason.length === auditRejectionReasonArray.length;
  const download = (id) => {
    axiosRequests
      .downloadFile(
        `ActiveGapClosure/DownloadMedicalCodingSuspectFile?MedicalCodingSuspectId=${id}`
      )
      .then((response) => {
        let filename = "";
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(
          response.headers["content-disposition"]
        );
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
        let url = window.URL.createObjectURL(new Blob([response.data]));
        saveAs(url, filename);
        // setOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (rowData.status.toLowerCase() === "accept")
      axiosRequests
        .get(
          `/ActiveGapClosure/GetMedicalCodingSuspectFiles?OurId=${rowData.ourId}&MedicalCodingSuspectId=${rowData.id}&medicalCodeSuspectDetailId=${rowData.medicalCodeSuspectDetailId}`
        )
        .then((resp) => {
          setFilesList(resp);
          setIsLoading(false);
        });
  }, []);
  const getRowId = (row) => {
    return rowData.id + " " + row.fileName;
  };

  const handleSave = () => {
    if (parseInt(selectedAuditStatus) === 1) {
      let requestBody = {
        ourId: rowData.ourId,
        medicalCodingSuspectId: rowData.id,
        medicalCodeSuspectDetailId: rowData.medicalCodeSuspectDetailId,
      };
      axiosRequests
        .post(
          "/ActiveGapClosure/AuditAcceptForMedicalCodingSuspect",
          requestBody
        )
        .then((resp) => {
          if (resp) {
            console.log("Submitted!!");
            setForceUpdate(forceUpdate + 1);
            onClose();
          }
        });
    } else {
      let hasError = false;
      if (
        selectedAuditRejectionReason &&
        selectedAuditRejectionReason.length === 0
      ) {
        setAuditRejectionReasonErrorText("Reasons are required");
        hasError = true;
      }
      if (hasError) return;
      let requestBody = {
        ourId: rowData.ourId,
        medicalCodingSuspectId: rowData.id,
        medicalCodeSuspectDetailId: rowData.medicalCodeSuspectDetailId,
        rejectReasons: selectedAuditRejectionReason.join(","),
      };
      axiosRequests
        .post(
          "/ActiveGapClosure/AuditRejectForMedicalCodingSuspect",
          requestBody
        )
        .then((resp) => {
          if (resp) {
            console.log("Submitted!!");
            setForceUpdate(forceUpdate + 1);
            onClose();
          }
        });
    }
  };

  const openAuditSection = (row) => {
    setAuditRow(row);
    setHideAuditSection(false);
  };
  const columnsReports = [
    {
      field: "fileName",
      headerName: "File Name",
      flex: 1,
    },
    {
      field: "createdOn",
      headerName: "Uploaded On",
      flex: 1,
      renderCell: (params) => {
        return <span>{format(params.row.createdOn, "MM/dd/yyyy")}</span>;
      },
    },
    {
      field: "visitDate",
      headerName: "Visit Date",
      flex: 1,
      renderCell: (params) => {
        return <span>{format(params.row.visitDate, "MM/dd/yyyy")}</span>;
      },
    },
    {
      field: "codeStatus",
      headerName: "Code Status",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            {hideAuditSection && (
              <Button
                color="blackTheme"
                onClick={() => openAuditSection(params.row)}
                sx={{ textTransform: "none" }}
                variant="outlined"
                size="small"
              >
                Perform Audit
              </Button>
            )}
            <Button
              color="blackTheme"
              onClick={() => download(params.row.id)}
              sx={{ textTransform: "none" }}
              variant="outlined"
              size="small"
            >
              Download
            </Button>
          </Stack>
        );
      },
    },
  ];
  const columnsClaims = [
    {
      field: "fileName",
      headerName: "File Name",
      flex: 1,
    },
    {
      field: "createdOn",
      headerName: "Uploaded On",
      flex: 1,
      renderCell: (params) => {
        return <span>{format(params.row.createdOn, "MM/dd/yyyy")}</span>;
      },
    },
    {
      field: "visitDate",
      headerName: "Visit Date",
      flex: 1,
      renderCell: (params) => {
        return <span>{format(params.row.visitDate, "MM/dd/yyyy")}</span>;
      },
    },
    {
      field: "codeStatus",
      headerName: "Code Status",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        return (
          <Stack direction="row" spacing={2}>
            <Button
              color="blackTheme"
              onClick={() => download(params.row.id)}
              sx={{ textTransform: "none" }}
              variant="outlined"
              size="small"
            >
              Download
            </Button>
          </Stack>
        );
      },
    },
  ];

  const handleAuditStatusChange = (e) => {
    setSelectedAuditStatus(e.target.value);
  };

  const handleAuditRejectionReasonChange = (e) => {
    setAuditRejectionReasonErrorText("");
    var value = e.target.value;
    if (value[value.length - 1] === "all") {
      var selectedValues =
        selectedAuditRejectionReason.length === auditRejectionReasonArray.length
          ? []
          : auditRejectionReasonArray.map((item) => item.rejectReasonId);
      setSelectedAuditRejectionReason(selectedValues);
      return;
    }
    setSelectedAuditRejectionReason(
      typeof value === "string" ? value.split(",") : value
    );
    // setSelectedAuditRejectionReason(e.target.value);
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      slotProps={{ backdrop: { onClick: null } }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "1200px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          pt: 1,
          height: "auto",
        }}
      >
        <h3
          style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
        >
          Audit for ICD10 code - “{rowData.icD10}”?
          <span>
            <CloseIcon onClick={onClose} style={{ float: "right" }} />
          </span>
        </h3>
        <hr
          style={{
            width: "auto",
            opacity: "0.3",
            marginTop: "-8px",
            // marginBottom: "-4px",
          }}
        />
        <Stack direction="column" spacing={2}>
          {/* <img
            style={{
              width: "129px",
              height: "29px",
              margin: "0 auto",
              display: "block",
            }}
            src={cinq_1}
          ></img> */}
          {/* <Typography style={{ fontFamily: Styles.FONT_FAMILY_POPPINS }}>
            Audit for ICD10 code - “{rowData.icD10}”?
          </Typography> */}
          {isLoading ? (
            <Loader isLoading={isLoading}></Loader>
          ) : (
            <>
              <DataGridCustom
                hideFooter={true}
                columns={columnsReports}
                rows={filesList.evidence}
                pageSize={2}
                getRowId={getRowId}
                loading={isLoading}
                sortingOrder={["asc", "desc"]}
                slots={{
                  toolbar: DataGridHeader,
                }}
                slotProps={{
                  toolbar: {
                    headerText: "Supporting Documents",
                    fontSize: "16px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    height: "56px",
                    children: (
                      <div
                        style={{
                          fontFamily: Styles.FONT_FAMILY_POPPINS,
                          fontSize: "16px",
                          marginTop: "auto",
                          marginBottom: "auto",
                          marginLeft: "5px",
                        }}
                      >
                        <Chip
                          color="orangeTheme"
                          variant="contained"
                          style={{ color: "#fff" }}
                          label={filesList.evidence.length}
                        ></Chip>
                      </div>
                    ),
                  },
                }}
              />

              {!hideAuditSection && (
                <Stack
                  direction="column"
                  spacing={2}
                  style={{
                    backgroundColor: "#FAFAFA",
                    borderRadius: "4px",
                    border: "1px solid #EDEDED",
                    padding: "16px",
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontFamily: Styles.FONT_FAMILY_POPPINS,
                    }}
                  >
                    Audit for Supporting Document - <b>{auditRow.fileName}</b>{" "}
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <FormControl
                      required
                      color="orangeTheme"
                      sx={{
                        maxWidth: "303px",
                        minWidth: "303px",
                        backgroundColor: "#fff",
                        borderRadius: "6px",
                      }}
                      size="small"
                      error={!!auditStatusErrorText}
                    >
                      <InputLabel id="role-name-select-small-label">
                        Audit Status
                      </InputLabel>

                      <Select
                        required
                        value={selectedAuditStatus}
                        className="roleNameDropdown"
                        labelId="role-name-select-small-label"
                        id="role-name-select-small"
                        onChange={(e) => handleAuditStatusChange(e)}
                        label="Audit Status"
                      >
                        {AuditStatusArray.map((item) => (
                          <MenuItem
                            className="dropdownText"
                            key={item.id}
                            value={item.id}
                          >
                            {item.auditStatus}
                          </MenuItem>
                        ))}
                      </Select>
                      {auditStatusErrorText && (
                        <FormHelperText style={{ height: "0px" }}>
                          {auditStatusErrorText}
                        </FormHelperText>
                      )}
                    </FormControl>
                    {parseInt(selectedAuditStatus) === 2 && (
                      <FormControl
                        required
                        color="orangeTheme"
                        sx={{
                          maxWidth: "303px",
                          minWidth: "303px",
                          backgroundColor: "#fff",
                          borderRadius: "6px",
                        }}
                        size="small"
                        error={!!auditRejectionReasonErrorText}
                      >
                        <InputLabel id="role-name-select-small-label">
                          Rejection Reason
                        </InputLabel>

                        <Select
                          required
                          multiple
                          value={selectedAuditRejectionReason}
                          className="payerNameDropdown"
                          labelId="payer-name-select-small-label"
                          id="payer-name-select-small"
                          onChange={(e) => handleAuditRejectionReasonChange(e)}
                          renderValue={(selected) =>
                            `${selected.length} Reasons Selected`
                          }
                          label="Rejection Reason"
                        >
                          <MenuItem value="all">
                            <Checkbox
                              color="orangeTheme"
                              checked={isAllRejectionReasonsSelected}
                              indeterminate={
                                selectedAuditRejectionReason.length > 0 &&
                                selectedAuditRejectionReason.length <
                                  auditRejectionReasonArray.length
                              }
                            />

                            <ListItemText primary="Select All">
                              Select All{" "}
                            </ListItemText>
                          </MenuItem>
                          {auditRejectionReasonArray.map((item) => (
                            <MenuItem
                              className="dropdownText"
                              key={item.rejectReasonId}
                              value={item.rejectReasonId}
                            >
                              <Checkbox
                                style={{ fontSize: "14px" }}
                                color="orangeTheme"
                                checked={
                                  selectedAuditRejectionReason.indexOf(
                                    item.rejectReasonId
                                  ) > -1
                                }
                              />
                              <ListItemText
                                style={{ fontSize: "14px" }}
                                primary={item.rejectReason}
                              />
                            </MenuItem>
                          ))}
                        </Select>
                        {auditRejectionReasonErrorText && (
                          <FormHelperText style={{ height: "0px" }}>
                            {auditRejectionReasonErrorText}
                          </FormHelperText>
                        )}
                      </FormControl>
                    )}
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Button
                      style={{
                        textTransform: "none",
                        color: "white",
                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                        color: "#F37021",
                      }}
                      onClick={handleSave}
                      variant="outlined"
                      color="orangeTheme"
                      size="small"
                      disableElevation
                    >
                      Save
                    </Button>
                    <Button
                      style={{
                        textTransform: "none",
                        borderColor: "black",
                        color: "black",
                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                      }}
                      variant="text"
                      size="small"
                      onClick={() => setHideAuditSection(true)}
                    >
                      Cancel
                    </Button>
                  </Stack>
                </Stack>
              )}
              <DataGridCustom
                hideFooter={true}
                columns={columnsClaims}
                rows={filesList.claims}
                pageSize={2}
                getRowId={getRowId}
                loading={isLoading}
                sortingOrder={["asc", "desc"]}
                slots={{
                  toolbar: DataGridHeader,
                }}
                slotProps={{
                  toolbar: {
                    headerText: "Claims Documents",
                    fontSize: "16px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    height: "56px",
                    children: (
                      <div
                        style={{
                          fontFamily: Styles.FONT_FAMILY_POPPINS,
                          fontSize: "16px",
                          marginTop: "auto",
                          marginBottom: "auto",
                          marginLeft: "5px",
                        }}
                      >
                        <Chip
                          color="orangeTheme"
                          variant="contained"
                          style={{ color: "#fff" }}
                          label={filesList.claims.length}
                        ></Chip>
                      </div>
                    ),
                  },
                }}
              />
            </>
          )}
        </Stack>
      </Box>
    </Modal>
  );
};

export default AuditStatusModal;
