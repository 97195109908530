import React, { useEffect, useState, useCallback } from "react";

import { useAppDispatch } from "../../../app/store";
import { Link, useNavigate } from "react-router-dom";
// import { Box } from "@mui/material";
import { Avatar, Button, Chip, Grid, Stack, Typography } from "@mui/material";
import Loader from "../../../component/common/loader/Loader";
import { useSelector } from "react-redux";
// import DataGridCustom from "../../component/common/datagrid/DataGridCustom";
import Fab from "@mui/material/Fab";
// import { Styles } from "../../Styles";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { axiosRequests } from "../../../app/api/AxiosRequest";
import DataGridCustom from "../../../component/common/datagrid/DataGridCustom";
import { PagesPath } from "../../../PagesPath";
import { getProgramsList } from "./ProgramsListSlice";
import DeleteProgramModal from "./DeleteProgramModal";
import Searchbox from "../../../component/common/searchbox/Searchbox";

const ProgramsTable = () => {
  const { isLoading, programsData } = useSelector(
    (state) => state.programsData
  );
  const [selectedRowForModal, setSelectedRowForModal] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [filteredPrograms, setFilteredPrograms] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useAppDispatch();

  const getProgramsListData = useCallback(async () => {
    const response = await dispatch(getProgramsList());
  }, [dispatch]);

  useEffect(() => {
    getProgramsListData();
  }, [getProgramsListData]);

  const openDeleteModal = (row) => {
    setSelectedRowForModal(row);
    setIsDeleteModalOpen(true);
  };

  function closeModal() {
    // setSelectedRow(row);
    setIsDeleteModalOpen(false);
  }

  const isRecordDeleted = () => {
    setIsDeleted(true);
  };

  useEffect(() => {
    if (isDeleted) {
      getProgramsListData();
      setIsDeleted(false);
    }
  }, [isDeleted]);

  const columns = [
    {
      field: "title",
      headerName: "Program Title",
      flex: 2,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 3,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <strong>
            <Link
              to={PagesPath.AddProgram}
              state={{ formState: params.row }}
              key="AddProgram"
              style={{
                textDecoration: "none",
                underline: "hover",
                color: "inherit",
                marginRight: "8px",
              }}
            >
              <EditIcon></EditIcon>
            </Link>
            <Link
              key="DeleteUser"
              style={{
                textDecoration: "none",
                underline: "hover",
                color: "inherit",
                marginRight: "8px",
              }}
            >
              <DeleteIcon
                onClick={() => openDeleteModal(params.row)}
              ></DeleteIcon>
            </Link>
          </strong>
        );
      },
    },
  ];

  useEffect(() => {
    if (programsData) {
      let filtered;
      if (searchValue) {
        filtered = programsData.filter(
          (item) =>
            item.title.toLowerCase().includes(searchValue.toLowerCase()) ||
            (item.description &&
              item.description
                .toLowerCase()
                .includes(searchValue.toLowerCase()))
        );
        setFilteredPrograms(filtered);
      } else {
        setFilteredPrograms(programsData);
      }
    }
  }, [searchValue, programsData]);

  return (
    <div>
      <Stack direction="row" spacing={1}>
        <div>Total Programs </div>
        <Avatar sx={{ width: 20, height: 20, top: "2px", color: "#000" }}>
          <div style={{ fontSize: "14px" }}>{programsData.length}</div>
        </Avatar>

        <Stack
          direction="row"
          spacing={1}
          style={{ marginLeft: "auto", float: "right" }}
        >
          <Searchbox
            cursor="default"
            defaultValue={searchValue}
            handleChange={(e) => setSearchValue(e.target.value)}
            label="Search Program"
          ></Searchbox>
          <Link
            to={PagesPath.AddProgram}
            key="AddProgram"
            style={{
              textDecoration: "none",
              underline: "hover",
              color: "inherit",
            }}
          >
            <Button
              style={{
                textTransform: "none",
              }}
              variant="outlined"
              color="orangeTheme"
            >
              <AddIcon style={{ paddingTop: "5px", marginTop: "-4px" }} />
              Add Program
            </Button>
          </Link>
        </Stack>
      </Stack>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <Grid item>
          <DataGridCustom
            columns={columns}
            rows={filteredPrograms}
            //getRowId={getRowId}
            loading={isLoading}
          />
        </Grid>
      )}
      <DeleteProgramModal
        isOpen={isDeleteModalOpen}
        onClose={closeModal}
        rowData={selectedRowForModal}
        isDeleted={isRecordDeleted}
      ></DeleteProgramModal>
    </div>
  );
};

export default ProgramsTable;
