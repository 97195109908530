import { Button, TextField, Modal, Box, Grid, CircularProgress, FormHelperText, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from 'react';
import { COLOR, Styles } from "../../Styles";
import cinq_1 from "../../assets/cinq_1.png";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import PdfGenerator from "./PDFGenerator";
import CloseIcon from '@mui/icons-material/Close';
import SignatureCanvas from 'react-signature-canvas';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import { axiosRequests } from "../../app/api/AxiosRequest";

const NewPatientVolAlign = ({ isOpen, onClose }) => {

    const modalRef = useRef(null);
    const [facilityNameDropdownData, setFacilityNameDropdownData] = useState([]);
    const [providerNameDropdownData, setProviderNameDropdownData] = useState([]);
    const [selectedFacilityName, setSelectedFacilityName] = useState("");
    const [selectedProviderName, setSelectedProviderName] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isDropdownDataSet, setIsDropdownDataSet] = useState(false);
    const [isProviderNameDropdownSet, setIsProviderNameDropdownSet] = useState(false);
    const [mbin, setMBIN] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [patientAddress, setPatientAddress] = useState('');
    const [patientCity, setPatientCity] = useState('');
    const [patientState, setPatientState] = useState('');
    const [patientZip, setPatientZip] = useState('');
    //const [formError, setFormError] = useState(false);
    const [mbinError, setMBINError] = useState('');
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [patientAddressError, setPatientAddressError] = useState('');
    const [patientCityError, setPatientCityError] = useState('');
    const [patientStateError, setPatientStateError] = useState('');
    const [patientZipError, setPatientZipError] = useState('');
    const [facilityNameError, setFacilityNameError] = useState('');
    const [providerNameError, setProviderNameError] = useState('');
    const [isSignBoxEmpty, setIsSignBoxEmpty] = useState(false);
    const [signBoxEmptyError, setSignBoxEmptyError] = useState('');
    const [showPdfGenerator, setShowPdfGenerator] = useState(false);
    const [sigImage, setSigImage] = useState(null);
    const [formData, setFormData] = useState({});
    const [firstDash, setFirstDash] = useState(false);
    const [secondDash, setSecondDash] = useState(false);

    const sigCanvas = useRef({});
    const clear = () => sigCanvas.current.clear();
    const marginBottomWithoutError = {marginBottom: '21px'};
    const marginBottomWithError = {marginBottom: '8px'}
    const mbiRegex = /\b[1-9][AC-HJKMNP-RT-Yac-hjkmnp-rt-y][AC-HJKMNP-RT-Yac-hjkmnp-rt-y0-9][0-9]-?[AC-HJKMNP-RT-Yac-hjkmnp-rt-y][AC-HJKMNP-RT-Yac-hjkmnp-rt-y0-9][0-9]-?[AC-HJKMNP-RT-Yac-hjkmnp-rt-y]{2}\d{2}\b$/m;
    const zipRegex = /\b\d{5}\b/;
    const cityRegex = /^[a-zA-Z ]*$/;
    const stateRegex = /^[a-zA-Z ]*$/;


    const handleFacilityNameHighlight = () =>{
        if(selectedFacilityName){
            let ele = document.getElementsByClassName('facilityNameDropdown')[0];
        ele.classList.remove('Mui-focused');
        ele=document.getElementById('facility-name-select-small-label');
        ele.classList.remove('Mui-focused');
        }
    }

    // All Input fields change functions start here //

    const handleFacilityNameChange = (e) => {
        const ele = document.getElementsByClassName('facilityNameDropdown')[0];
        ele.classList.remove('Mui-focused');
        setSelectedFacilityName(e.target.value);
        setSelectedProviderName('');
        setFacilityNameError('');
        setIsProviderNameDropdownSet(false);
    }

    const handleProviderNameChange = (e) => {
        let ele = document.getElementsByClassName('facilityNameDropdown')[0];
        ele.classList.remove('Mui-focused');
        ele=document.getElementById('facility-name-select-small-label');
        ele.classList.remove('Mui-focused');
        setSelectedProviderName(e.target.value);
        setProviderNameError('');
        // setSelectedProviderName('');
    }

    const handleMBINChange = (e) => {
        setMBIN(e.target.value);
        setMBINError('');
    }

    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
        setFirstNameError('');
    }

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
        setLastNameError('');
    }

    const handleAddressChange = (e) => {
        setPatientAddress(e.target.value);
        setPatientAddressError('');
    }

    const handleCityChange = (e) => {
        setPatientCity(e.target.value);
        setPatientCityError('');
    }

    const handleStateChange = (e) => {
        setPatientState(e.target.value);
        setPatientStateError('');
    }

    const handleZipChange = (e) => {
        setPatientZip(e.target.value);
        setPatientZipError('');
    }
//All Input Fields change functions end here

const formatMBIN = (event) =>{
    var value = event.target.value;
    let formattedValue = '';
    if(event.key !== 'Backspace' && event.key !== 'Delete'){
        for(let i=0;i<value.length;i++){
            if(i===4 && !firstDash) {
                formattedValue+='-';
                setFirstDash(true);
            }
             if(i===8 && !secondDash) {
                formattedValue+='-';
                setSecondDash(true);
             }
            formattedValue += value[i];
        }
        setMBIN(formattedValue);
    }
    if(event.key === 'Backspace' || event.key === 'Delete'){
        if(value.length < 9){
            setSecondDash(false);
        }
        if(value.length < 5){
            setFirstDash(false);
            setSecondDash(false);
        }
    }    
}

const handleSubmitForm = (e) =>{
    let hasError=false;
    let isSignEmpty=sigCanvas.current.isEmpty();
    if(!selectedFacilityName){
        setFacilityNameError('Facility Name is Required');
        hasError=true;
    }
    if(selectedFacilityName){
        let ele = document.getElementsByClassName('facilityNameDropdown')[0];
        ele.classList.remove('Mui-focused');
        ele=document.getElementById('facility-name-select-small-label');
        ele.classList.remove('Mui-focused');
    }

    if(!selectedProviderName){
        setProviderNameError('Provider Name is Required');
        hasError=true;
    }

    if(!mbin){
        setMBINError('MBI Number is required');
        hasError = true;
    }

    if(!mbiRegex.test(mbin)){
        setMBINError('Invalid MBI entered. Special characters @, &, $, #, ! etc. not allowed');
        hasError = true;
    }

    if(!firstName){
        setFirstNameError('First name is required');
        hasError = true;
    }
    if(!lastName){
        setLastNameError('Last name is required');
        hasError = true;
    }
    if(!patientAddress){
        setPatientAddressError('Address is required');
        hasError = true;
    }
    if(!patientCity){
        setPatientCityError('City is required');
        hasError = true;
    }
    if(!cityRegex.test(patientCity)){
        setPatientCityError('City is invalid');
        hasError = true;
    }
    if(!patientState){
        setPatientStateError('State is required');
        hasError = true;
    }
    if(!(stateRegex.test(patientState))){
        setPatientStateError('State is invalid');
        hasError = true;
    }
    if(!patientZip){
        setPatientZipError('Zip is required');
        hasError = true;
    }
    if(!zipRegex.test(patientZip)){
        setPatientZipError('Invalid Zip Entered');
        hasError = true;
    }
    if(isSignEmpty){
        setIsSignBoxEmpty(true);
        setSignBoxEmptyError('Signature is Required');
        hasError=true;
    }
    else if(!isSignEmpty){
        setIsSignBoxEmpty(false);
        setSignBoxEmptyError('');
        if(!hasError) hasError=false;
    }

    if (hasError) return;

    
    
    const formDataModal = {
        MBINumber: mbin,
        FirstName: firstName,
        LastName: lastName,
        PracticeName: selectedFacilityName,
        ProviderName: selectedProviderName,
        Address: patientAddress,
        City: patientCity,
        State: patientState,
        Zip: patientZip
    };
    const personName = firstName + ' ' + lastName;
    
    const signatureImage= sigCanvas.current.toDataURL();
    setSigImage(signatureImage);
    const form ={
        practiceName: selectedFacilityName,
        providerName: selectedProviderName,
        mbinumber: mbin,
        memberName: personName
    };
    setFormData(form);
    setShowPdfGenerator(true);
     setTimeout(()=>{
        axiosRequests.post('/VoluntaryAlignment/VolAlignmentNewPatient',formDataModal).then(resp => {
            if(resp===1){
                window.location.reload();
            }
        })
     },1000)
}


useEffect(()=>{
    if(onClose){

        //Reset Error State
        setMBINError('');
        setFirstNameError('');
        setLastNameError('');
        setFacilityNameError('');
        setProviderNameError('');
        setPatientAddressError('');
        setPatientCityError('');
        setPatientStateError('');
        setPatientZipError('');
        setSignBoxEmptyError('');

      

        //Reset Form State
        setMBIN('');
        setFirstName('');
        setLastName('');
        setSelectedFacilityName('');
        setSelectedProviderName('');
        setPatientAddress('');
        setPatientCity('');
        setPatientState('');
        setPatientZip('');

    }
},[isOpen])
    useEffect(() => {
        if (isOpen && !isDropdownDataSet) {
            var count = 0;
            setIsLoading(true);
            axiosRequests.get('/VoluntaryAlignment/GetPracticeService').then(response => response)
                .then(data => {
                    const uniqueData = Array.from(new Set(data.map(item => item.groupName))).map(groupName => data.find(item => item.groupName === groupName));
                    count++;
                    setFacilityNameDropdownData(uniqueData);
                    setIsLoading(false);
                    setIsDropdownDataSet(true);
                })
        }
    })

    useEffect(() => {
        if (isOpen && !isProviderNameDropdownSet && selectedFacilityName) {

            setIsLoading(true);
            axiosRequests.get(`/VoluntaryAlignment/GetProvidersService?groupName=${selectedFacilityName}`).then(response => response)
                .then(data => {
                    const uniqueData = Array.from(new Set(data.map(item => item.providerName))).map(providerName => data.find(item => item.providerName === providerName));

                    setProviderNameDropdownData(uniqueData);
                    setIsLoading(false);
                    setIsProviderNameDropdownSet(true);
                })
        }
    })


    return (
        <Modal open={isOpen} onClose={onClose} slotProps={{ backdrop: { onClick: null } }}>
            
            <div ref={modalRef} id="pdf-content">
            {/* <ModalClose/> */}
            {/* <div>x</div> */}
            
            <Box sx={{
                position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', width: '678px',
                bgcolor: 'background.paper', boxShadow: 24, p: 4, pt: 1, height: '657px'
            }}>
                <h3 style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: '16px' }}>Confirmation Of Main Doctor Or Other Healthcare Professional Form <span><CloseIcon onClick={onClose} style={{float:'right'}}/></span></h3>
                
                <img style={{ width: '129px', height: '29px', margin: '0 auto', display: 'block' }} src={cinq_1}></img>
                <h5>By signing below I am confirming that my main doctor or other healthcare professional - or the main place I go to for routine medical care - is chosen below.</h5>
               
                <Grid container spacing={2} style={marginBottomWithoutError}>

                    <Grid item xs={6}>
                        <TextField
                            required
                            label="MBI Number"
                            value={mbin}
                            id="outlined-size-small"
                            onKeyUp={(e)=>formatMBIN(e)}
                            error={!!mbinError}
                            helperText={''}
                            onChange={(e) => handleMBINChange(e)}
                            style={{
                                width: '293px',
                                height: '34px'
                            }}
                            color="orangeTheme"
                            size="small"
                        />
                    </Grid>

                </Grid>

                <Grid container spacing={2} style={marginBottomWithoutError}>

                    <Grid item xs={6}>
                        <TextField
                            required
                            label="First Name"
                            id="outlined-size-small"
                            defaultValue=""
                            error={!!firstNameError}
                            helperText={''}
                            onChange={(e) => handleFirstNameChange(e)}
                            style={{
                                width: '293px',
                                height: '34px'
                            }}
                            color="orangeTheme"
                            size="small"
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <TextField
                            required
                            label="Last Name"
                            id="outlined-size-small"
                            defaultValue=""
                            error={!!lastNameError}
                            helperText={''}
                            onChange={(e) => handleLastNameChange(e)}
                            style={{
                                width: '293px',
                                height: '34px'
                            }}
                            color="orangeTheme"
                            size="small"
                        />
                    </Grid>

                </Grid>


                {/* <Grid container spacing={2} style={(facilityNameError || providerNameError)? marginBottomWithoutError : marginBottomWithoutError}> */}
                <Grid container spacing={2} style={{marginBottom: '16px'}}>
                    <Grid item xs={6}>
                        
                        <FormControl error={!!facilityNameError} required color="orangeTheme" sx={{ maxWidth: '294px', minWidth: '294px' }} size="small">
                            <InputLabel id="facility-name-select-small-label">Facility Name</InputLabel>

                            <Select
                                required
                                value={selectedFacilityName}
                                disabled={isLoading}
                                className="facilityNameDropdown"
                                onBlur={handleFacilityNameHighlight}
                                labelId="facility-name-select-small-label"
                                id="facility-name-select-small"
                                onChange={(e) => handleFacilityNameChange(e)}
                                label="Facility Name"
                            >
                                {
                                    isLoading ? <MenuItem value=""><CircularProgress size={24} /></MenuItem> : null
                                }
                                {
                                    facilityNameDropdownData.map(item => (
                                        <MenuItem key={item.groupName} value={item.groupName}>
                                            {item.groupName}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                            {
                                facilityNameError ? <FormHelperText></FormHelperText> : null
                            }
                        </FormControl>

                    </Grid>
                    <Grid item xs={6}>
                        <FormControl error={!!providerNameError} required color="orangeTheme" sx={{ maxWidth: '294px', minWidth: '294px' }} size="small">
                            <InputLabel id="provider-name-select-small-label">Provider Name</InputLabel>

                            <Select
                                required
                                value={selectedProviderName}
                                disabled={isLoading}
                                
                                
                                labelId="provider-name-select-small-label"
                                id="provider-name-select-small"
                                onChange={(e) => handleProviderNameChange(e)}
                                label="Provider Name"
                            >
                                {
                                    isLoading ? <MenuItem value=""><CircularProgress size={24} /></MenuItem> : null
                                }
                                {
                                    providerNameDropdownData.map(item => (
                                        <MenuItem key={item.providerName} value={item.providerName}>
                                            {item.providerName}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                            {
                                providerNameError ? <FormHelperText></FormHelperText> : null
                            }
                        </FormControl>

                    </Grid>

                </Grid>

                <Grid container spacing={2} style={marginBottomWithoutError}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            label="Patient Address"
                            id="outlined-size-small"
                            defaultValue=""
                            error={!!patientAddressError}
                            helperText={''}
                            onChange={(e) => handleAddressChange(e)}
                            style={{
                                width: '610px',
                                height: '34px'
                            }}
                            color="orangeTheme"
                            size="small"
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={6} style={marginBottomWithoutError}>
                    
                    <Grid item xs={4}>
                        <TextField
                        fullWidth
                            required
                            label="City"
                            id="outlined-size-small"
                            error={!!patientCityError}
                            helperText={''}
                            onChange={(e) => handleCityChange(e)}
                            defaultValue=""
                            style={{
                                width: '170px',
                                height: '34px'
                            }}
                            color="orangeTheme"
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                        fullWidth
                            required
                            label="State"
                            error={!!patientStateError}
                            helperText={''}
                            onChange={(e) => handleStateChange(e)}
                            id="outlined-size-small"
                            defaultValue=""
                            style={{
                                width: '170px',
                                height: '34px'
                            }}
                            color="orangeTheme"
                            size="small"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                        fullWidth
                            required
                            inputProps={{maxLength: 5}}
                            label="Zip Code"
                           
                            error={!!patientZipError}
                            helperText={''}
                            onChange={(e) => handleZipChange(e)}
                            id="outlined-size-small"
                            defaultValue=""
                            style={{
                                width: '170px',
                                height: '34px'
                            }}
                            color="orangeTheme"
                            size="small"
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} >
                    
                    <Grid item style={{color: COLOR.FONT_ORANGE_ERROR, alignContent: 'center'}} xs={8}>
                    {(!!mbinError && mbin) && <Typography  style={{fontSize: '11px', fontWeight: '600'}}>
                        Invalid MBI entered. Special characters @, &, $, #, ! etc. not allowed
                    </Typography>}
                    {(!!patientZipError && patientZip) && <Typography  style={{fontSize: '11px', fontWeight: '600'}}>
                    Invalid Zip Code entered
                    </Typography>}
                    {(!!patientCityError && patientCity) && <Typography  style={{fontSize: '11px', fontWeight: '600'}}>
                    Invalid City entered
                    </Typography>}
                    {(!!patientStateError && patientState) && <Typography  style={{fontSize: '11px', fontWeight: '600'}}>
                    Invalid State entered
                    </Typography>}
                    </Grid>
                    {/* <Grid item xs={4}></Grid> */}
                    <Grid item xs={4} style={{marginLeft: '-13px'}}>
                    E-Signature: 
                        <SignatureCanvas ref={sigCanvas} penColor='#333333' backgroundColor= '#e2e2e2' velocityFilterWeight={0.3}
    canvasProps={{width: 204,height: 95, className: 'sigCanvas'}}/> <ReplayOutlinedIcon onClick={clear} style={{paddingLeft: '5px', marginLeft: '176px', marginBottom:'41px',marginTop:'-30px'}}/>
    
    
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{marginTop: '-59px'}}>
                    
                    <Grid item xs={4}>
                        
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4} style={{marginBottom: '-10px', marginLeft: '-10px'}}>
                            {isSignBoxEmpty ? <div style={{ fontSize: '12px', color:'#D32F2F'}}>{signBoxEmptyError}</div>: null}
    
                    </Grid>
                </Grid>




                <hr style={{width:'610px',opacity:'0.3',marginTop:'12px', marginBottom:'-4px'}}/>
                <Grid container spacing={6} style={{ marginLeft: '-31px', marginTop:'-39px' }}>
                    <Grid item xs={7}></Grid>
                    <Grid item xs={2}>
                        <Button style={{borderColor: 'black', color:'black', fontFamily: Styles.FONT_FAMILY_POPPINS}} variant="outlined" onClick={onClose}>Cancel</Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Button style={{color:'white', fontFamily: Styles.FONT_FAMILY_POPPINS}} variant="contained" color="orangeTheme" disableElevation onClick={handleSubmitForm}>Confirm</Button>
                    </Grid>
                </Grid>
            </Box>
            {showPdfGenerator && <PdfGenerator style={{display:'none'}} logoSrc={cinq_1} titleText={'Hello from Title'} formData={formData} signatureImage={sigImage}></PdfGenerator>}
            </div>
        </Modal>
    );

};

export default NewPatientVolAlign;