import React from "react";
import PageHeader from "../../component/common/header/PageHeader";
import { TextField } from "@mui/material";
import Searchbox from "../../component/common/searchbox/Searchbox";

const ProgramsHeader = ({searchValue, setSearchValue}) => {
  const title = "Programs";
  const sevenPillarTitle = "Provider Education";
return (
    <>
      <PageHeader
        disableEducation={true}
        title={title}
        sevenPillarTitle={sevenPillarTitle}
      >
        <div style={{ marginLeft: "auto", float: "right" }}><Searchbox cursor="default" defaultValue={searchValue} handleChange={(e)=>setSearchValue(e.target.value)} label="Search Program"></Searchbox></div>
      </PageHeader>
    </>
  );
};

export default ProgramsHeader;
