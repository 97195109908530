import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import HealthHomeFormsHeader from "../HealthHomeFormsHeader";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Styles } from "../../../Styles";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useLocation, useNavigate } from "react-router-dom";
import { PagesPath } from "../../../PagesPath";
import AddSectionModal from "./AddSectionModal";
import AddSubSectionModal from "./AddSubSectionModal";
import { axiosRequests } from "../../../app/api/AxiosRequest";

const StyledFormSection = styled(Grid)({
  display: "flex",
  minHeight: "482px",
  height: "autp",
  padding: "16px",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",
  alignSelf: "stretch",
  borderRadius: "5px",
  background: "rgba(226, 226, 226, 0.30)",
});

const StyledActionSection = styled(Grid)({
  display: "flex",
  padding: "16px 0px",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "16px",
  alignSelf: "stretch",
});

const AddEditTrainingDocument = () => {
  const [id, setId] = useState(0);
  const [filesFromResp, setFilesFromResp] = useState([]);
  const [files, setFiles] = useState([]);
  const [filesError, setFilesError] = useState("");
  const [filesToDelete, setFilesToDelete] = useState([]);
  const [section, setSection] = useState("");
  const [sectionDropdownData, setSectionDropdownData] = useState([]);
  const [subSectionDropdownData, setSubSectionDropdownData] = useState([]);
  const [subSection, setSubSection] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [sectionError, setSectionError] = useState("");
  const [subSectionError, setSubSectionError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [showAddSectionModal, setShowAddSectionModal] = useState(false);
  const [showAddSubSectionModal, setShowAddSubSectionModal] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(0);
  const navigate = useNavigate();
  let { state } = useLocation();

  const handleBackClick = () => {
    localStorage.setItem("healthHomeEducationTabValue", "2");
    navigate(PagesPath.HealthHomeEducationConfiguration, {
      state: { healthHomeEducationTabValue: "2" },
    });
  };
  const handleFileDelete = (fileToDelete) => {
    console.log(fileToDelete);
    if (filesFromResp && filesFromResp.length > 0 && fileToDelete.fileName) {
      var fileNames = files.map((file) => file.fileName);
      console.log(fileNames);
      setFilesToDelete((prev) => [...prev, fileToDelete.id]);
      setFilesFromResp((prevFiles) =>
        prevFiles.filter((file) => file.id !== fileToDelete.id)
      );
      // if (
      //   fileNames &&
      //   fileNames.length > 0 &&
      //   fileToDelete.fileName &&
      //   fileNames.includes(fileToDelete.fileName)
      // ) {
      //   console.log("YES");
      //   setFilesToDelete((prev) => [...prev, fileToDelete.id]);
      // }
    } else {
      const input = document.getElementById("training-document-file-input");
      document.getElementById("training-document-file-input").value = "";
      setFiles((prevFiles) =>
        prevFiles.filter((file) => file !== fileToDelete)
      );
    }
  };
  const handleFileChange = (e) => {
    setFilesError("");
    const newFiles = Array.from(e.target.files);
    setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    //setClaimsFilesError("");
  };

  const handleSectionChange = (e) => {
    setSection(e.target.value);
    setSubSection("");
    setSectionError("");
  };

  const handleSubSectionChange = (e) => {
    setSubSection(e.target.value);
    setSubSectionError("");
  };

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    setTitleError("");
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    setDescriptionError("");
  };

  const handleAddSectionModal = () => {
    setShowAddSectionModal(true);
  };

  const handleAddSubSectionModal = () => {
    setShowAddSubSectionModal(true);
  };

  const closeModal = () => {
    setShowAddSectionModal(false);
    setShowAddSubSectionModal(false);
  };

  useEffect(() => {
    axiosRequests.get("/TrainingDocument/GetSections").then((resp) => {
      setSectionDropdownData(resp);
    });
  }, [forceUpdate]);

  useEffect(() => {
    if (state && state.formState.id > 0) {
      axiosRequests
        .get(
          `/TrainingDocument/GetTrainingDocumentDetailsById?trainingDocumentId=${state.formState.id}`
        )
        .then((resp) => {
          console.log(resp);
          setSection(resp.sectionId);
          setSubSection(resp.subSectionId);
          setTitle(resp.title);
          setDescription(resp.description);
          setFilesFromResp(resp.documents);
          setId(resp.id);
        });
    }
  }, []);

  useEffect(() => {
    if (section && section !== "") {
      axiosRequests
        .get(`/TrainingDocument/GetSubSections?sectionId=${section}`)
        .then((resp) => {
          setSubSectionDropdownData(resp);
        });
    }
  }, [forceUpdate, section]);

  const handleSave = () => {
    let hasError = false;
    if (!section || section === "") {
      setSectionError("Section is Required");
      hasError = true;
    }
    if (!subSection || subSection === "") {
      setSubSectionError("Sub Section is Required");
      hasError = true;
    }
    if (!title || title === "") {
      setTitleError("Title is Required");
      hasError = true;
    }
    if (!description || description === "") {
      setDescriptionError("Description is Required");
      hasError = true;
    }
    if (
      (!files || files.length === 0) &&
      (!filesFromResp || filesFromResp.length === 0)
    ) {
      setFilesError("Atleast One Document is Required");
      hasError = true;
    }
    if (hasError) return;
    var formData = new FormData();
    formData.append("id", id);
    formData.append("sectionId", section);
    formData.append("subSectionId", subSection);
    formData.append("title", title);
    formData.append("description", description);
    files.forEach((file) => {
      formData.append("documents", file);
    });
    filesToDelete.forEach((file) => {
      formData.append("deletedDocumentIds", file);
    });

    console.log(formData);

    axiosRequests
      .postUpload("/TrainingDocument/AddUpdateTrainingDocument", formData)
      .then((resp) => {
        console.log(resp);
        if (resp.status === 200) {
          localStorage.setItem("healthHomeEducationTabValue", "2");
          navigate(PagesPath.HealthHomeEducationConfiguration, {
            state: { healthHomeEducationTabValue: "2" },
          });
        }
      });
  };
  return (
    <Box sx={{ width: "100%", p: { xs: 2, sm: 2 } }}>
      <HealthHomeFormsHeader pageTitle="Add Training Documents" />
      <Stack direction="row" spacing={1}>
        <ArrowBackOutlinedIcon
          onClick={() => handleBackClick()}
          style={{
            fontSize: "25px",
            fontWeight: "600",
            marginTop: "2px",
            width: "32px",
          }}
        />
        <Typography style={{ fontSize: "20px", fontWeight: "600" }}>
          Add Training Document
        </Typography>
      </Stack>
      <StyledFormSection>
        <Stack direction="column" spacing={3} style={{ width: "100%" }}>
          <Stack direction="row" spacing={2} style={{ marginLeft: "auto" }}>
            <Button
              variant="outlined"
              color="orangeTheme"
              onClick={handleAddSectionModal}
              style={{
                fontFamily: Styles.FONT_FAMILY_POPPINS,
                textTransform: "none",
                float: "right",
              }}
            >
              <AddOutlinedIcon />
              Add Section
            </Button>
            <Button
              variant="outlined"
              color="orangeTheme"
              onClick={handleAddSubSectionModal}
              style={{
                fontFamily: Styles.FONT_FAMILY_POPPINS,
                textTransform: "none",
                float: "right",
              }}
            >
              <AddOutlinedIcon />
              Add Sub Section
            </Button>
          </Stack>
          <FormControl
            required
            color="orangeTheme"
            sx={{
              maxWidth: "705px",
              minWidth: "705px",
              backgroundColor: "#fff",
              borderRadius: "6px",
            }}
            size="small"
            error={!!sectionError}
          >
            <InputLabel id="role-name-select-small-label">Section</InputLabel>

            <Select
              required
              value={section}
              className="roleNameDropdown"
              labelId="role-name-select-small-label"
              id="role-name-select-small"
              onChange={(e) => handleSectionChange(e)}
              label="Section"
            >
              {sectionDropdownData.map((item) => (
                <MenuItem
                  className="dropdownText"
                  key={item.id}
                  value={item.id}
                >
                  {item.title}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText style={{ height: "0px" }}>
              {sectionError}
            </FormHelperText>
          </FormControl>
          <FormControl
            required
            color="orangeTheme"
            sx={{
              maxWidth: "705px",
              minWidth: "705px",
              backgroundColor: "#fff",
              borderRadius: "6px",
            }}
            size="small"
            error={!!subSectionError}
          >
            <InputLabel id="role-name-select-small-label">
              Sub Section
            </InputLabel>

            <Select
              required
              value={subSection}
              disabled={subSectionDropdownData.length === 0}
              className="roleNameDropdown"
              labelId="role-name-select-small-label"
              id="role-name-select-small"
              onChange={(e) => handleSubSectionChange(e)}
              label="Sub Section"
            >
              {subSectionDropdownData.map((item) => (
                <MenuItem
                  className="dropdownText"
                  key={item.id}
                  value={item.id}
                >
                  {item.title}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText style={{ height: "0px" }}>
              {subSectionError}
            </FormHelperText>
          </FormControl>
          <TextField
            required
            label="Title"
            onChange={handleTitleChange}
            id="outlined-size-small"
            value={title}
            error={!!titleError}
            helperText={titleError}
            style={{
              width: "705px",
              height: "40px",
              borderRadius: "6px",
              backgroundColor: "#fff",
            }}
            color="orangeTheme"
            size="small"
          />
          <TextField
            id="outlined-multiline-static"
            label="Description"
            value={description}
            error={!!descriptionError}
            helperText={descriptionError}
            onChange={handleDescriptionChange}
            multiline
            className="roleDescription"
            color="orangeTheme"
            rows={5}
            variant="outlined"
          />
          <input
            type="file"
            multiple
            onChange={handleFileChange}
            style={{ display: "none" }}
            id="training-document-file-input"
          />
          <Button
            color="orangeTheme"
            sx={{ textTransform: "none", width: "190px", color: "#fff" }}
            variant="contained"
            onClick={() =>
              document.getElementById("training-document-file-input").click()
            }
          >
            <FileUploadOutlinedIcon
              style={{ marginTop: "auto", marginBottom: "auto", color: "#fff" }}
              color="orangeTheme"
            />
            Upload Document
          </Button>
          {filesError && (
            <FormHelperText style={{ color: "#DC4F3D" }}>
              {filesError}
            </FormHelperText>
          )}
          <Stack
            direction="column"
            spacing={1}
            style={{
              maxWidth: "705px",
              maxHeight: "100px",
              overflowY: "scroll",
            }}
          >
            {filesFromResp.map((file, index) => (
              <ListItem
                key={index}
                style={{
                  background: "#DEDEDE",
                }}
                secondaryAction={
                  <IconButton
                    edge="end"
                    style={{ marginTop: "0px" }}
                    onClick={() => handleFileDelete(file)}
                  >
                    <DeleteOutlineOutlinedIcon></DeleteOutlineOutlinedIcon>
                  </IconButton>
                }
              >
                <Tooltip title={file.fileName}>
                  <ListItemText
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    primary={file.fileName}
                  ></ListItemText>
                </Tooltip>
              </ListItem>
            ))}
            {files.map((file, index) => (
              <ListItem
                key={index}
                style={{
                  background: "#DEDEDE",
                }}
                secondaryAction={
                  <IconButton
                    edge="end"
                    style={{ marginTop: "0px" }}
                    onClick={() => handleFileDelete(file)}
                  >
                    <DeleteOutlineOutlinedIcon></DeleteOutlineOutlinedIcon>
                  </IconButton>
                }
              >
                <Tooltip title={file.name}>
                  <ListItemText
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                    primary={file.name}
                  ></ListItemText>
                </Tooltip>
              </ListItem>
            ))}
          </Stack>
        </Stack>
      </StyledFormSection>
      <StyledActionSection>
        <Button
          variant="text"
          color="blackTheme"
          onClick={handleBackClick}
          style={{
            fontFamily: Styles.FONT_FAMILY_POPPINS,
            textTransform: "none",
            float: "right",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="orangeTheme"
          onClick={handleSave}
          style={{
            fontFamily: Styles.FONT_FAMILY_POPPINS,
            textTransform: "none",
            float: "right",
          }}
        >
          Save
        </Button>
      </StyledActionSection>
      {showAddSectionModal && (
        <AddSectionModal
          isOpen={showAddSectionModal}
          onClose={closeModal}
          forceUpdate={forceUpdate}
          setForceUpdate={setForceUpdate}
        />
      )}
      {showAddSubSectionModal && (
        <AddSubSectionModal
          isOpen={showAddSubSectionModal}
          onClose={closeModal}
          forceUpdate={forceUpdate}
          setForceUpdate={setForceUpdate}
        />
      )}
    </Box>
  );
};

export default AddEditTrainingDocument;
