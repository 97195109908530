import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Button,
  Modal,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../app/store";
import { Styles } from "../../Styles";
import { axiosRequests } from "../../app/api/AxiosRequest";

import { getNewEmailAddress } from "../../component/common/sdohAssessmentFilter/SDOHAssessmentFilterDetailsSlice";
const SMSNotificationModal = ({
  isOpen,
  onClose,
  rowData,
  message,
  isSentButtonDisplay,
  memberName,
  handleSMSSend,
  contactNumber,
  setContactNumber,
}) => {
  const dispatch = useAppDispatch();
  const phoneRegex = /\b\d{10}\b/;
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = React.useState("");
  const [isSentEnable, setIsSentEnable] = useState(false);

  //const { newPhoneNumber } = useSelector((state) => state.filterDetails);

  //   useEffect(() => {
  //     // if(newEmailAddress != "")
  //     setPhoneNumber(newPhoneNumber);
  //   }, [newPhoneNumber]);

  const handlePhoneNumberChange = (e) => {
    let phoneNumber = e.target.value;
    setPhoneNumber(phoneNumber);
    setPhoneNumberError("");
    let hasError = false;
    if (phoneNumber == "") {
      setIsSentEnable(false);
      // dispatch(getNewEmailAddress(""));
    } else {
      if (!phoneRegex.test(phoneNumber)) {
        setPhoneNumberError("Phone Number is invalid");
        hasError = true;
        setIsSentEnable(false);
      } else {
        setIsSentEnable(true);
        // dispatch(getNewEmailAddress(emailAddress));
      }
    }
  };

  const closePopup = () => {
    //dispatch(getNewEmailAddress(emailAddress));
    setPhoneNumber("");
    setPhoneNumber("");
    onClose();
  };

  const handleSMSSent = () => {
    //dispatch(getNewEmailAddress(emailAddress));
    const formData = {
      OurId: rowData.ourId,
      oldPhoneNumber: "",
      phoneNumber:
        rowData.contactNumber && rowData.contactNumber !== ""
          ? rowData.contactNumber
          : phoneNumber,
    };

    axiosRequests
      .post(`/SDOH/UpdateSDOHMemberPhoneNumber`, formData)
      .then((resp) => {
        setContactNumber(formData.phoneNumber);
        //handleSMSSend();
      });
  };

  useEffect(() => {
    if (rowData.ourId && contactNumber) handleSMSSend();
  }, [contactNumber]);
  if (!rowData) return;
  return (
    <Modal
      open={isOpen}
      onClose={closePopup}
      slotProps={{ backdrop: { onClick: null } }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "578px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          pt: 1,
          height: "auto",
        }}
      >
        <h3
          style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
        >
          Information
          <span>
            <CloseIcon onClick={closePopup} style={{ float: "right" }} />
          </span>
        </h3>
        <hr
          style={{
            width: "auto",
            opacity: "0.3",
            marginTop: "-11px",
            marginBottom: "5px",
          }}
        />
        <Stack direction="column" sx={{ marginTop: "23px" }} spacing={2}>
          <Typography
            style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontWeight: 500 }}
          >
            {message}
          </Typography>
          <Grid container spacing={2}>
            <Grid
              item
              xs={9}
              sx={{ display: isSentButtonDisplay === false ? "" : "none" }}
            >
              <TextField
                required
                label="Contact Number"
                id="outlined-size-small"
                onChange={handlePhoneNumberChange}
                helperText={phoneNumberError}
                error={!!phoneNumberError}
                value={phoneNumber}
                style={{
                  fontFamily: Styles.FONT_FAMILY_POPPINS,
                  width: "100%",
                  height: "39px",
                  borderRadius: "6px",
                  backgroundColor: "#fff",
                }}
                color="orangeTheme"
                size="small"
              />
            </Grid>
          </Grid>
        </Stack>
        <hr
          style={{
            width: "auto",
            opacity: "0.3",
            marginTop: "16px",
          }}
        />
        <Stack direction="row" spacing={2} style={{ marginTop: "8px" }}>
          <Button
            style={{
              textTransform: "none",
              borderColor: "black",
              color: "black",
              fontFamily: Styles.FONT_FAMILY_POPPINS,
              marginLeft: "auto",
            }}
            variant="outlined"
            onClick={closePopup}
          >
            Close
          </Button>
          <Button
            style={{
              textTransform: "none",
              color: "white",
              fontFamily: Styles.FONT_FAMILY_POPPINS,
              display:
                isSentButtonDisplay === true || isSentEnable === true
                  ? ""
                  : "none",
            }}
            variant="contained"
            color="orangeTheme"
            disableElevation
            onClick={handleSMSSent}
          >
            Send
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};

export default SMSNotificationModal;
