import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/Agent";

const initialState = {
  isLoading: false,
  data: {},
  errorMessage: "",
};

export const getDashboardByGroupIdAndDashboardId = createAsyncThunk(
  "getDashboardByGroupIdAndDashboardId",
  async ({ groupId, dashboardId }) => {
    const response =
      await agent.PowerBiAgent.getDashboardByGroupIdAndDashboardId(
        groupId,
        dashboardId
      );
    return response;
  }
);

const powerBiSlice = createSlice({
  name: "powerBi",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardByGroupIdAndDashboardId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getDashboardByGroupIdAndDashboardId.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.data = action.payload;
          state.errorMessage="";
        }
      )
      .addCase(
        getDashboardByGroupIdAndDashboardId.rejected,
        (state, action) => {
          state.isLoading = false;
          state.data = {};
          state.errorMessage = action.error.message;
        }
      );
  },
});

export default powerBiSlice.reducer;
