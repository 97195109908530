import {
  Box,
  Button,
  Grid,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Styles } from "../../../Styles";
import { PagesPath } from "../../../PagesPath";
import { useLocation, useNavigate } from "react-router-dom";
import HealthHomeFormsHeader from "../HealthHomeFormsHeader";
import { axiosRequests } from "../../../app/api/AxiosRequest";

const StyledScreen = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  alignSelf: "stretch",
});

const StyledTitle = styled(Grid)({
  display: "flex",
  height: "46px",
  alignItems: "center",
  gap: "16px",
  alignSelf: "stretch",
});

const StyledFormSection = styled(Grid)({
  display: "flex",
  height: "482px",
  padding: "16px",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",
  alignSelf: "stretch",
  borderRadius: "5px",
  background: "rgba(226, 226, 226, 0.30)",
});

const StyledActionSection = styled(Grid)({
  display: "flex",
  padding: "16px 0px",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: "16px",
  alignSelf: "stretch",
});

const AddEditTrainingProgram = () => {
  const navigate = useNavigate();
  let { state } = useLocation();
  const [id, setId] = useState(0);
  const [programTitle, setProgramTitle] = useState("");
  const [programTitleError, setProgramTitleError] = useState("");
  const [programDescription, setProgramDescription] = useState("");
  const [programDescriptionError, setProgramDescriptionError] = useState("");
  const [programURL, setProgramURL] = useState("");
  const [programURLError, setProgramURLError] = useState("");
  const urlRegex =
    /^(https?:\/\/)?(www\.)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\/.*)?$/;

  const handleProgramTitleChange = (e) => {
    setProgramTitle(e.target.value);
    setProgramTitleError("");
  };
  const handleProgramDescriptionChange = (e) => {
    setProgramDescription(e.target.value);
    setProgramDescriptionError("");
  };
  const handleProgramURLChange = (e) => {
    setProgramURL(e.target.value);
    setProgramURLError("");
  };

  useEffect(() => {
    if (state && state.formState.id > 0) {
      setId(state.formState.id);
      setProgramTitle(state.formState.title);
      setProgramDescription(state.formState.description);
      setProgramURL(state.formState.url);
    }
  },[]);

  const handleBackClick = () => {
    localStorage.setItem("healthHomeEducationTabValue", "1");
    navigate(PagesPath.HealthHomeEducationConfiguration, {
      state: { healthHomeEducationTabValue: "1" },
    });
  };

  const handleSave = () => {
    let hasError = false;
    if (!programTitle || programTitle === "") {
      setProgramTitleError("Program Title is Required");
      hasError = true;
    }
    if (!programDescription || programDescription === "") {
      setProgramDescriptionError("Program Description is Required");
      hasError = true;
    }
    if (!programURL || programURL === "") {
      setProgramURLError("Program URL is Required");
      hasError = true;
    }
    if (programURL && programURL !== "" && !urlRegex.test(programURL)) {
      setProgramURLError("URL is invalid");
      hasError = true;
    }
    if (hasError) return;
    let requestBody = {
      id: id ? parseInt(id) : 0,
      title: programTitle,
      url: programURL,
      description: programDescription,
    };
    console.log("Request Body is ", requestBody);
    axiosRequests
      .post("/TrainingProgram/AddUpdateTrainingProgram", requestBody)
      .then((resp) => {
        if (resp === 1) {
          localStorage.setItem("healthHomeEducationTabValue", "1");
          navigate(PagesPath.HealthHomeEducationConfiguration, {
            state: { healthHomeEducationTabValue: "1" },
          });
        }
      });
  };
  return (
    <Box sx={{ width: "100%", p: { xs: 2, sm: 2 } }}>
      <HealthHomeFormsHeader pageTitle="Add Training Programs" />
      <Stack direction="row" spacing={1}>
        <ArrowBackOutlinedIcon
          onClick={() => handleBackClick()}
          style={{
            fontSize: "25px",
            fontWeight: "600",
            marginTop: "2px",
            width: "32px",
          }}
        />
        <Typography style={{ fontSize: "20px", fontWeight: "600" }}>
          Add Training Program
        </Typography>
      </Stack>
      <StyledFormSection>
        <Stack direction="column" spacing={4}>
          <TextField
            required
            label="Program Title"
            onChange={handleProgramTitleChange}
            id="outlined-size-small"
            value={programTitle}
            error={!!programTitleError}
            helperText={programTitleError}
            style={{
              width: "705px",
              height: "40px",
              borderRadius: "6px",
              backgroundColor: "#fff",
            }}
            color="orangeTheme"
            size="small"
          />
          <TextField
            required
            label="Program URL"
            onChange={handleProgramURLChange}
            id="outlined-size-small"
            value={programURL}
            error={!!programURLError}
            helperText={programURLError}
            style={{
              width: "705px",
              height: "40px",
              borderRadius: "6px",
              backgroundColor: "#fff",
            }}
            color="orangeTheme"
            size="small"
          />
          <TextField
            id="outlined-multiline-static"
            label="Program Description"
            value={programDescription}
            error={!!programDescriptionError}
            helperText={programDescriptionError}
            onChange={handleProgramDescriptionChange}
            multiline
            className="roleDescription"
            color="orangeTheme"
            rows={5}
            variant="outlined"
          />
        </Stack>
      </StyledFormSection>
      <StyledActionSection>
        <Button
          variant="text"
          color="blackTheme"
          onClick={handleBackClick}
          style={{
            fontFamily: Styles.FONT_FAMILY_POPPINS,
            textTransform: "none",
            float: "right",
          }}
        >
          Cancel
        </Button>
        <Button
          variant="outlined"
          color="orangeTheme"
          onClick={handleSave}
          style={{
            fontFamily: Styles.FONT_FAMILY_POPPINS,
            textTransform: "none",
            float: "right",
          }}
        >
          Save
        </Button>
      </StyledActionSection>
    </Box>
  );
};

export default AddEditTrainingProgram;
