import {
  Typography,
  Box,
  Chip,
  Stack,
  CardContent,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormHelperText,
  Button,
  Grid,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
} from "@mui/material";
import MedicalAdherenceToolHeader from "./MedicalAdherenceToolHeader";
import { useCallback, useEffect, useState } from "react";
import { axiosRequests } from "../../app/api/AxiosRequest";
import { COLOR, Styles } from "../../Styles";
import AddIcon from "@mui/icons-material/Add";
import Searchbox from "../../component/common/searchbox/Searchbox";
import { getMedicalAdherenceList } from "./MedicalAdherenceToolSlice";
import { useAppDispatch } from "../../app/store";
import { useSelector } from "react-redux";
import CustomPaginationSlot from "../../component/common/datagrid/CustomPaginationSlot";
import EditIcon from "@mui/icons-material/EditOutlined";
import Loader from "../../component/common/loader/Loader";
import DataGridCustom from "../../component/common/datagrid/DataGridCustom";
import {
  add,
  addDays,
  addMonths,
  endOfMonth,
  endOfWeek,
  format,
  startOfMonth,
  startOfWeek,
  sub,
  subDays,
} from "date-fns";
import MedicalAdherenceTimelineModal from "./MedicalAdherenceTimelineModal";
import DataGridHeader from "../../component/common/datagrid/DataGridHeader";
import "./MedicalAdherenceTool.css";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import AddDateModal from "./AddDateModal";
import { Link } from "react-router-dom";
import MedicalAdherenceMemberDetails from "./MedicalAdherenceMemberDetails";
import NoAccessPage from "../../component/common/noAccess/NoAccessPage";
import { checkUserToolAccess } from "../../component/common/userAuthorization/checkUserAccess";

const MedicalAdherenceTool = () => {
  const MedicalAdherenceToolID = 22;
  const [accessObj] = useState(checkUserToolAccess(MedicalAdherenceToolID));
  const [marketNameDropdownData, setMarketNameDropdownData] = useState([]);
  const [selectedMarketName, setSelectedMarketName] = useState([]);
  const [regionNameDropdownData, setRegionNameDropdownData] = useState([]);
  const [selectedRegionName, setSelectedRegionName] = useState([]);
  const [isLoadingRegionName, setIsLoadingRegionName] = useState(true);
  const [practiceNameDropdownData, setPracticeNameDropdownData] = useState([]);
  const [selectedPracticeName, setSelectedPracticeName] = useState([]);
  const [isLoadingPracticeName, setIsLoadingPracticeName] = useState(true);
  const [rxCategoryDropdownData, setRxCategoryDropdownData] = useState([]);
  const [selectedRxCategory, setSelectedRxCategory] = useState([]);
  const [isLoadingRxCategory, setIsLoadingRxCategory] = useState(true);
  const [adherenceStatusDropdownData, setAdherenceStatusDropdownData] =
    useState([]);
  const [showTimelineModal, setShowTimelineModal] = useState(false);
  const [showAddDateModal, setShowAddDateModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [dateFilter, setDateFilter] = useState("absFailDate");
  const [selectedAdherenceStatus, setSelectedAdherenceStatus] = useState([]);
  const [isLoadingAdhStatus, setIsLoadingAdhStatus] = useState(true);
  const [selectedTimelineValue, setSelectedTimelineValue] =
    useState("thisWeek");
  const [startDateValue, setStartDateValue] = useState("");
  const [endDateValue, setEndDateValue] = useState("");
  const [customModalCancel, setCustomModalCancel] = useState(false);
  const [page, setPage] = useState(0);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [searchMemberName, setSearchMemberName] = useState("");
  const [searchMember, setSearchMember] = useState("");
  const [activeStatus, setActiveStatus] = useState("Total");
  const [showDetailPage, setShowDetailPage] = useState(false);
  const [memberId, setMemberId] = useState(false);
  const [providerName, setProviderName] = useState("");
  const [practiceName, setPracticeName] = useState("");
  const [medAdherenceMeasure, setMedAdherenceMeasure] = useState("");
  const [risk, setRisk] = useState("");
  const [sortModel, setSortModel] = useState([]);
  const [isAllFiltersSelected, setIsAllFiltersSelected] = useState(false);
  const {
    isLoading,
    medicalAdherenceList,
    medicalAdherenceCount,
    errorMessage,
    medicalAdherenceListCount,
  } = useSelector((state) => state.medicalAdherence);
  const dispatch = useAppDispatch();
  const isAllMarketsSelected =
    marketNameDropdownData.length > 0 &&
    selectedMarketName.length === marketNameDropdownData.length;
  const isAllRegionsSelected =
    regionNameDropdownData.length > 0 &&
    selectedRegionName.length === regionNameDropdownData.length;
  const isAllPracticeSelected =
    practiceNameDropdownData.length > 0 &&
    selectedPracticeName.length === practiceNameDropdownData.length;
  const isAllRxCategorySelected =
    rxCategoryDropdownData.length > 0 &&
    selectedRxCategory.length === rxCategoryDropdownData.length;
  const isAllAdherenceStatusSelected =
    adherenceStatusDropdownData.length > 0 &&
    selectedAdherenceStatus.length === adherenceStatusDropdownData.length;
  //Initial Page Load Function start here

  useEffect(() => {
    const loadData = async () => {
      var selectedMarketIDs,
        selectedRegionIDs,
        selectedPracticeIDs,
        selectedRxCategoryIDs,
        selectedAdherenceStatusIDs;

      await axiosRequests
        .get("/MedicationAdherence/GetMarketList")
        .then((resp) => {
          setMarketNameDropdownData(resp);
          selectedMarketIDs = resp.map((item) => item.marketId);
          setSelectedMarketName(selectedMarketIDs);
        });

      await axiosRequests
        .get(
          `/MedicationAdherence/GetSubMarketList?marketIDs=${selectedMarketIDs.join(
            ","
          )}`
        )
        .then((resp) => {
          selectedRegionIDs = resp.map((item) => item.id);
          setSelectedRegionName(selectedRegionIDs);
          setIsLoadingRegionName(false);
          setRegionNameDropdownData(resp);
        });

      await axiosRequests
        .get(
          `/MedicationAdherence/GetPracticeList?marketIDs=${selectedMarketIDs.join(
            ","
          )}&subMarketIDs=${selectedRegionIDs.join(",")}`
        )
        .then((resp) => {
          selectedPracticeIDs = resp.map((item) => item.practiceId);
          setPracticeNameDropdownData(resp);
          setIsLoadingPracticeName(false);
          setSelectedPracticeName(selectedPracticeIDs);
        });

      await axiosRequests
        .get("/MedicationAdherence/GetRxCategoryList")
        .then((resp) => {
          selectedRxCategoryIDs = resp.map((item) => item.rxCategory);
          setRxCategoryDropdownData(resp);
          setIsLoadingRxCategory(false);
          setSelectedRxCategory(selectedRxCategoryIDs);
        });

      await axiosRequests
        .get("/MedicationAdherence/GetAdherenceStatusList")
        .then((resp) => {
          selectedAdherenceStatusIDs = resp.map((item) => item.adherenceStatus);
          setAdherenceStatusDropdownData(resp);
          setIsLoadingAdhStatus(false);
          setSelectedAdherenceStatus(selectedAdherenceStatusIDs);
        });
    };
    loadData();
  }, []);

  const getMedicalAdherenceListData = useCallback(
    async (requestBody) => {
      const response = await dispatch(getMedicalAdherenceList(requestBody));
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      selectedMarketName &&
      selectedMarketName.length > 0 &&
      selectedRegionName &&
      selectedRegionName.length > 0 &&
      selectedPracticeName &&
      selectedPracticeName.length > 0 &&
      selectedRxCategory &&
      selectedRxCategory.length > 0 &&
      selectedAdherenceStatus &&
      selectedAdherenceStatus.length > 0
    ) {
      var startDate = null,
        endDate = null,
        date = new Date(),
        dateFormatted = format(date, "yyyy-MM-dd");
      switch (selectedTimelineValue) {
        case "thisWeek":
          var options = { weekStartsOn: 0 };
          var startOfCurrentWeek = startOfWeek(date, options);
          var endOfCurrentWeek = endOfWeek(date, options);
          var startDate = format(startOfCurrentWeek, "yyyy-MM-dd");
          var endDate = format(endOfCurrentWeek, "yyyy-MM-dd");
          break;
        case "past14Days":
          var date14DaysBefore = format(subDays(date, 14), "yyyy-MM-dd");
          startDate = date14DaysBefore;
          endDate = dateFormatted;
          break;
        case "past7Days":
          var date7DaysBefore = format(subDays(date, 7), "yyyy-MM-dd");
          startDate = date7DaysBefore;
          endDate = dateFormatted;
          break;
        case "next2Weeks":
          var date2WeeksAfter = format(addDays(date, 14), "yyyy-MM-dd");
          startDate = dateFormatted;
          endDate = date2WeeksAfter;
          break;
        case "currentMonth":
          var startOfCurrentMonth = startOfMonth(date);
          var endOfCurrentMonth = endOfMonth(date);
          var startDate = format(startOfCurrentMonth, "yyyy-MM-dd");
          var endDate = format(endOfCurrentMonth, "yyyy-MM-dd");
          break;
        case "nextMonth":
          var nextMonthDate = addMonths(date, 1);
          var startNextMonth = startOfMonth(nextMonthDate);
          var endNextMonth = endOfMonth(nextMonthDate);
          var startDate = format(startNextMonth, "yyyy-MM-dd");
          var endDate = format(endNextMonth, "yyyy-MM-dd");
          break;
        case "next2Months":
          var nextMonthDate = addMonths(date, 1);
          var followingMonthDate = addMonths(date, 2);
          var startNextMonth = startOfMonth(nextMonthDate);
          var endFollowingMonth = endOfMonth(followingMonthDate);
          var startDate = format(startNextMonth, "yyyy-MM-dd");
          var endDate = format(endFollowingMonth, "yyyy-MM-dd");
          break; //DOUBT
        case "custom":
          var startDate = startDateValue;
          var endDate = endDateValue;
          break;
      }
      setIsAllFiltersSelected(true);
      const requestBody = {
        pageNumber: page,
        rowsPerPage: 5,
        sortColumn:
          !sortModel || sortModel.length === 0
            ? "ABSFailDate"
            : sortModel[0].field,
        sortOrder:
          !sortModel || sortModel.length === 0 ? "ASC" : sortModel[0].sort,
        marketIDs: selectedMarketName.join(","),
        subMarketIDs: selectedRegionName.join(","),
        practiceIDs: selectedPracticeName.join(","),
        rxCategory: selectedRxCategory.join(","),
        adherenceStatus: selectedAdherenceStatus.join(","),
        startDate: startDate,
        endDate: endDate,
        memberName: searchMember ? searchMember : "",
        dateFilter: dateFilter,
        activeStatus: activeStatus,
      };
      if (
        !showTimelineModal &&
        !customModalCancel &&
        !showAddDateModal &&
        page !== 0
      ) {
        getMedicalAdherenceListData(requestBody);
      } else {
        setCustomModalCancel(false);
      }
    } else {
      setIsAllFiltersSelected(false);
    }
  }, [getMedicalAdherenceListData, page, forceUpdate]);

  useEffect(() => {
    if (
      selectedMarketName &&
      selectedMarketName.length > 0 &&
      selectedRegionName &&
      selectedRegionName.length > 0 &&
      selectedPracticeName &&
      selectedPracticeName.length > 0 &&
      selectedRxCategory &&
      selectedRxCategory.length > 0 &&
      selectedAdherenceStatus &&
      selectedAdherenceStatus.length > 0
    ) {
      if (page === 1) {
        setForceUpdate((prev) => prev + 1);
      } else {
        setPage(1);
      }
    } else {
      setIsAllFiltersSelected(false);
      setPage(0);
    }
  }, [
    selectedMarketName,
    selectedRegionName,
    selectedPracticeName,
    selectedRxCategory,
    selectedAdherenceStatus,
    selectedTimelineValue,
    dateFilter,
    sortModel,
    showTimelineModal,
    showAddDateModal,
    activeStatus,
    searchMember,
  ]);

  //Initial Page Load Function End here

  //Data Table Column Initialization Start Here
  const columns = [
    {
      field: "memberId",
      headerName: "Member Id",
      renderCell: (params) => {
        return (
          <Link
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <div
              onClick={() =>
                viewMemberDetails(
                  params.row.ourId,
                  params.row.practiceName,
                  params.row.risk,
                  params.row.adherenceStatus
                )
              }
            >
              <u>{params.row.memberId}</u>
            </div>
          </Link>
        );
      },
    },
    {
      field: "memberName",
      headerName: "Member Name",
      width: 200,
    },
    // {
    //   field: "currentStatus",
    //   headerName: "Current Status",
    // }, // Commenting as this column is not available yet
    {
      field: "adherenceStatus",
      headerName: "Med Adherence Measure",
      width: 200,
      renderCell: (params) => {
        if (params.row.risk === "G") {
          return (
            <Chip
              color="success"
              variant="outlined"
              label={params.row.adherenceStatus}
            ></Chip>
          );
        } else if (params.row.risk === "Y") {
          return (
            <Chip
              color="warning"
              variant="outlined"
              label={params.row.adherenceStatus}
            ></Chip>
          );
        } else if (params.row.risk === "R") {
          return (
            <Chip
              variant="outlined"
              color="error"
              label={params.row.adherenceStatus}
            ></Chip>
          );
        } else {
          <Chip variant="outlined" label={params.row.adherenceStatus}></Chip>;
        }
      },
    },
    {
      field: "prescriptionDate",
      headerName: "Prescription Written On",
      type: "Date",
      width: 200,
      renderCell: (params) => {
        if (params.row.prescriptionDate) {
          return <div>{format(params.row.prescriptionDate, "dd/MM/yyyy")}</div>;
        } else
          return (
            <Stack direction="row" spacing={1}>
              <div
                style={{
                  display: !accessObj.isEditAccess ? "none" : "",
                }}
                onClick={() => openAddDateModal(params.row)}
              >
                <Button
                  color="blackTheme"
                  onClick={() => openAddDateModal(params.row)}
                  variant="text"
                >
                  <EditCalendarIcon style={{ marginTop: "-1px" }} />
                  <span style={{ marginLeft: "8px" }}>Add Date</span>
                </Button>
              </div>
            </Stack>
          );
      },
    },
    {
      field: "drugName",
      headerName: "Drug Name",
      width: 200,
    },
    {
      field: "rxCategory",
      headerName: "RX Category",
      width: 200,
    },
    {
      field: "daysSupplied",
      headerName: "Days Supplied",
      type: "number",
      width: 200,
    },
    {
      field: "lastRefillDate",
      headerName: "Last Refill Date",
      width: 200,
      type: "Date",
      renderCell: (params) => {
        return format(params.row.lastRefillDate, "dd-MM-yyyy");
      },
    },
    {
      field: "nextRefillDate",
      headerName: "Next Refill Date",
      type: "Date",
      width: 200,
      renderCell: (params) => {
        return format(params.row.nextRefillDate, "dd-MM-yyyy");
      },
    },
    {
      field: "maxRefillDate",
      headerName: "Max Refill Date",
      width: 200,
      type: "Date",
      renderCell: (params) => {
        return format(params.row.maxRefillDate, "dd-MM-yyyy");
      },
    },
    {
      field: "absoluteFailDate",
      headerName: "Absolute Fail Date",
      width: 200,
      type: "Date",
      renderCell: (params) => {
        return format(params.row.absoluteFailDate, "dd-MM-yyyy");
      },
    },
    {
      field: "pharmacyName",
      headerName: "Pharmacy Name",
      width: 200,
    },
    {
      field: "pharmacyContactNumber",
      headerName: "Pharmacy Contact",
      width: 200,
    },
    {
      field: "extendedSupplySuggested",
      headerName: "Extended Supply Suggested",
      width: 200,
    },
  ];

  //Data Table COlumn Initialization End Here

  //Data Import Functions Start Here
  useEffect(() => {
    axiosRequests.get("/MedicationAdherence/GetMarketList").then((resp) => {
      setMarketNameDropdownData(resp);
    });
  }, []);
  const getRegionList = (marketNames) => {
    axiosRequests
      .get(
        `/MedicationAdherence/GetSubMarketList?marketIDs=${marketNames.join(
          ","
        )}`
      )
      .then((resp) => {
        setRegionNameDropdownData(resp);
        setIsLoadingRegionName(false);
        var IDs = resp.map((item) => item.id);
        var selected = selectedRegionName.filter((item) => IDs.includes(item));
        setSelectedRegionName(selected);
        if (selected && selected.length > 0) {
          getPracticeList(selectedMarketName, selected);
        } else {
          setPracticeNameDropdownData([]);
          setIsLoadingPracticeName(true);
          setSelectedPracticeName([]);
        }
      });
  };
  const getPracticeList = (marketNames, regionNames) => {
    axiosRequests
      .get(
        `/MedicationAdherence/GetPracticeList?marketIDs=${marketNames.join(
          ","
        )}&subMarketIDs=${regionNames.join(",")}`
      )
      .then((resp) => {
        setPracticeNameDropdownData(resp);
        setIsLoadingPracticeName(false);
        var IDs = resp.map((item) => item.practiceId);
        var selected = selectedPracticeName.filter((item) =>
          IDs.includes(item)
        );
        setSelectedPracticeName(selected);
      });
  };
  useEffect(() => {
    axiosRequests.get("/MedicationAdherence/GetRxCategoryList").then((resp) => {
      setRxCategoryDropdownData(resp);
    });
  }, []);
  useEffect(() => {
    axiosRequests
      .get("/MedicationAdherence/GetAdherenceStatusList")
      .then((resp) => {
        setAdherenceStatusDropdownData(resp);
      });
  }, []);

  //Data Import FUnctions End Here

  //Change functions Start Here

  const viewMemberDetails = (
    memberId,
    practiceName,
    risk,
    adherenceMeasure
  ) => {
    setShowDetailPage(true);
    setMemberId(memberId);
    // setProviderName(providerName);
    setPracticeName(practiceName);
    setRisk(risk);
    setMedAdherenceMeasure(adherenceMeasure);
  };

  const handleClearFilterChange = () => {
    setSelectedMarketName([]);
    setSelectedRegionName([]);
    setIsLoadingRegionName(true);
    setSelectedPracticeName([]);
    setIsLoadingPracticeName(true);
    setSelectedRxCategory([]);
    setIsLoadingRxCategory(true);
    setSelectedAdherenceStatus([]);
    setIsLoadingAdhStatus(true);
    setSelectedTimelineValue("thisWeek");
    setDateFilter("absFailDate");
    setSearchMemberName("");
    setSearchMember("");
    setActiveStatus("Total");
    setSortModel([]);
    setPage(1);
  };

  const handleDateFilterRadioChange = (e) => {
    setDateFilter(e.target.value);
  };

  const handleSortModelChange = (sortModel) => {
    let sort = null;
    if (sortModel.length > 0) {
      const { field, sort: sortOrder } = sortModel[0];
      sort = `${field}:${sortOrder}`;
      if (sortOrder === "asc") {
        sort = `${field}:desc`;
      }
    }
    setSortModel(sortModel);
    setPage(1);
  };

  const handleMarketNameChange = (e) => {
    var value = e.target.value;
    if (value[value.length - 1] === "all") {
      var marketIDs = marketNameDropdownData.map((item) => item.marketId);
      var selectedValues =
        selectedMarketName.length === marketNameDropdownData.length
          ? []
          : marketNameDropdownData.map((item) => item.marketId);
      setSelectedMarketName(selectedValues);
      if (selectedValues && selectedValues.length > 0) {
        getRegionList(selectedValues);
      } else {
        setSelectedRegionName([]);
        setSelectedPracticeName([]);
        setSelectedRxCategory([]);
        setSelectedAdherenceStatus([]);
        setRegionNameDropdownData([]);
        setIsLoadingRegionName(true);
        setIsLoadingPracticeName(true);
        setIsLoadingAdhStatus(true);
        setIsLoadingRxCategory(true);
      }
      return;
    }
    setSelectedMarketName(typeof value === "string" ? value.split(",") : value);
    if (!value || value.length === 0) {
      setSelectedRegionName([]);
      setSelectedPracticeName([]);
      setSelectedRxCategory([]);
      setSelectedAdherenceStatus([]);
      setRegionNameDropdownData([]);
      setIsLoadingRegionName(true);
      setIsLoadingPracticeName(true);
      setIsLoadingAdhStatus(true);
      setIsLoadingRxCategory(true);
    } else getRegionList(value);
  };

  const handleRegionNameChange = (e) => {
    var value = e.target.value;
    if (value[value.length - 1] === "all") {
      var regionIDs = regionNameDropdownData.map((item) => item.id);
      var selectedValues =
        selectedRegionName.length === regionNameDropdownData.length
          ? []
          : regionNameDropdownData.map((item) => item.id);
      setSelectedRegionName(selectedValues);
      if (selectedValues && selectedValues.length > 0) {
        getPracticeList(selectedMarketName, selectedValues);
      } else {
        setSelectedPracticeName([]);
        setSelectedRxCategory([]);
        setSelectedAdherenceStatus([]);
        setIsLoadingPracticeName(true);
        setIsLoadingAdhStatus(true);
        setIsLoadingRxCategory(true);
      }
      return;
    }
    setSelectedRegionName(typeof value === "string" ? value.split(",") : value);
    if (!value || value.length === 0) {
      setSelectedPracticeName([]);
      setSelectedRxCategory([]);
      setSelectedAdherenceStatus([]);
      setIsLoadingPracticeName(true);
      setIsLoadingAdhStatus(true);
      setIsLoadingRxCategory(true);
    } else getPracticeList(selectedMarketName, value);
  };
  const handlePracticeNameChange = (e) => {
    var value = e.target.value;
    if (value[value.length - 1] === "all") {
      var regionIDs = regionNameDropdownData.map((item) => item.practiceId);
      var selectedValues =
        selectedPracticeName.length === practiceNameDropdownData.length
          ? []
          : practiceNameDropdownData.map((item) => item.practiceId);
      setSelectedPracticeName(selectedValues);
      if (!selectedValues || selectedValues.length === 0) {
        setSelectedRxCategory([]);
        setSelectedAdherenceStatus([]);
        setIsLoadingAdhStatus(true);
        setIsLoadingRxCategory(true);
      } else {
        setIsLoadingRxCategory(false);
      }
      return;
    }
    if (!value || value.length === 0) {
      setSelectedRxCategory([]);
      setSelectedAdherenceStatus([]);
      setIsLoadingAdhStatus(true);
      setIsLoadingRxCategory(true);
    } else {
      setIsLoadingRxCategory(false);
    }
    setSelectedPracticeName(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleRxCategoryChange = (e) => {
    var value = e.target.value;
    if (value[value.length - 1] === "all") {
      var rxCategory = regionNameDropdownData.map((item) => item.rxCategory);
      var selectedValues =
        selectedRxCategory.length === rxCategoryDropdownData.length
          ? []
          : rxCategoryDropdownData.map((item) => item.rxCategory);
      setSelectedRxCategory(selectedValues);
      if (!selectedValues || selectedValues.length === 0) {
        setSelectedAdherenceStatus([]);
        setIsLoadingAdhStatus(true);
      }
      setIsLoadingAdhStatus(false);
      return;
    }
    if (!value || value.length === 0) {
      setSelectedAdherenceStatus([]);
      setIsLoadingAdhStatus(true);
    } else {
      setIsLoadingAdhStatus(false);
    }
    setSelectedRxCategory(typeof value === "string" ? value.split(",") : value);
  };

  const handleAdherenceStatusChange = (e) => {
    var value = e.target.value;
    if (value[value.length - 1] === "all") {
      var adherenceStatus = regionNameDropdownData.map(
        (item) => item.adherenceStatus
      );
      var selectedValues =
        selectedAdherenceStatus.length === adherenceStatusDropdownData.length
          ? []
          : adherenceStatusDropdownData.map((item) => item.adherenceStatus);
      setSelectedAdherenceStatus(selectedValues);
      return;
    }
    setSelectedAdherenceStatus(
      typeof value === "string" ? value.split(",") : value
    );
  };

  //Change functions End Here

  //Modal Function Start Here
  const openTimelineModal = (row) => {
    setShowTimelineModal(true);
  };
  function closeModal() {
    setShowTimelineModal(false);
    //setShowAddDateModal(false);
  }
  const openAddDateModal = (row) => {
    setShowAddDateModal(true);
    setSelectedRow(row);
  };
  function closeAddDateModal() {
    //setShowTimelineModal(false);
    setShowAddDateModal(false);
  }

  //Modal FUnction End Here
  return !accessObj.hasAccess ? (
    <NoAccessPage></NoAccessPage>
  ) : !showDetailPage ? (
    <Box sx={{ width: "100%" }}>
      <MedicalAdherenceToolHeader />
      {/* Filter Section */}
      <Stack
        direction="column"
        style={{ marginTop: "18px", marginBottom: "10px" }}
        spacing={1}
      >
        {/* Filter section starts */}
        <Stack direction="row" spacing={2}>
          <FormControl
            className="spacingClass"
            required
            color="orangeTheme"
            sx={{
              maxWidth: "160px",
              minWidth: "160px",
              backgroundColor: "#fff",
              borderRadius: "6px",
            }}
            size="small"
          >
            <InputLabel id="product-name-select-small-label">Market</InputLabel>

            <Select
              required
              multiple
              value={selectedMarketName}
              className="productNameDropdown"
              labelId="product-name-select-small-label"
              id="product-name-select-small"
              onChange={(e) => handleMarketNameChange(e)}
              renderValue={(selected) => `${selected.length} Selected`}
              label="Market"
            >
              <MenuItem value="all">
                <Checkbox
                  color="orangeTheme"
                  checked={isAllMarketsSelected}
                  indeterminate={
                    selectedMarketName.length > 0 &&
                    selectedMarketName.length < marketNameDropdownData.length
                  }
                />

                <ListItemText primary="Select All">Select All </ListItemText>
              </MenuItem>
              {marketNameDropdownData.map((item) => (
                <MenuItem
                  className="dropdownText"
                  key={item.marketId}
                  value={item.marketId}
                >
                  <Checkbox
                    style={{ fontSize: "14px" }}
                    color="orangeTheme"
                    checked={selectedMarketName.indexOf(item.marketId) > -1}
                  />
                  <ListItemText
                    style={{ fontSize: "14px" }}
                    primary={item.marketName}
                  />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText style={{ height: "0px" }}>
              {/* {productNameErrorText} */}
            </FormHelperText>
          </FormControl>
          <FormControl
            className="spacingClass"
            required
            color="orangeTheme"
            sx={{
              maxWidth: "160px",
              minWidth: "160px",
              backgroundColor: "#fff",
              borderRadius: "6px",
            }}
            disabled={isLoadingRegionName}
            size="small"
          >
            <InputLabel id="product-name-select-small-label">Region</InputLabel>

            <Select
              required
              multiple
              value={selectedRegionName}
              className="productNameDropdown"
              labelId="product-name-select-small-label"
              id="product-name-select-small"
              onChange={(e) => handleRegionNameChange(e)}
              renderValue={(selected) => `${selected.length} Selected`}
              label="Region"
            >
              <MenuItem value="all">
                <Checkbox
                  color="orangeTheme"
                  checked={isAllRegionsSelected}
                  indeterminate={
                    selectedRegionName.length > 0 &&
                    selectedRegionName.length < regionNameDropdownData.length
                  }
                />

                <ListItemText primary="Select All">Select All </ListItemText>
              </MenuItem>
              {regionNameDropdownData.map((item) => (
                <MenuItem
                  className="dropdownText"
                  key={item.id}
                  value={item.id}
                >
                  <Checkbox
                    style={{ fontSize: "14px" }}
                    color="orangeTheme"
                    checked={selectedRegionName.indexOf(item.id) > -1}
                  />
                  <ListItemText
                    style={{ fontSize: "14px" }}
                    primary={item.regionName}
                  />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText style={{ height: "0px" }}>
              {/* {productNameErrorText} */}
            </FormHelperText>
          </FormControl>
          <FormControl
            className="spacingClass"
            required
            color="orangeTheme"
            sx={{
              maxWidth: "160px",
              minWidth: "160px",
              borderRadius: "6px",
            }}
            disabled={isLoadingPracticeName}
            size="small"
          >
            <InputLabel id="product-name-select-small-label">
              Practice
            </InputLabel>

            <Select
              required
              multiple
              value={selectedPracticeName}
              className="productNameDropdown"
              labelId="product-name-select-small-label"
              id="product-name-select-small"
              onChange={(e) => handlePracticeNameChange(e)}
              renderValue={(selected) => `${selected.length} Selected`}
              label="Practice"
            >
              <MenuItem value="all">
                <Checkbox
                  color="orangeTheme"
                  checked={isAllPracticeSelected}
                  indeterminate={
                    selectedPracticeName.length > 0 &&
                    selectedPracticeName.length <
                      practiceNameDropdownData.length
                  }
                />

                <ListItemText primary="Select All">Select All </ListItemText>
              </MenuItem>
              {practiceNameDropdownData.map((item) => (
                <MenuItem
                  className="dropdownText"
                  key={item.practiceId}
                  value={item.practiceId}
                >
                  <Checkbox
                    style={{ fontSize: "14px" }}
                    color="orangeTheme"
                    checked={selectedPracticeName.indexOf(item.practiceId) > -1}
                  />
                  <ListItemText
                    style={{ fontSize: "14px" }}
                    primary={item.practiceName}
                  />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText style={{ height: "0px" }}>
              {/* {productNameErrorText} */}
            </FormHelperText>
          </FormControl>
          <FormControl
            className="spacingClass"
            required
            color="orangeTheme"
            sx={{
              maxWidth: "160px",
              minWidth: "160px",
              // backgroundColor: "#fff",
              borderRadius: "6px",
            }}
            disabled={isLoadingRxCategory}
            size="small"
          >
            <InputLabel id="product-name-select-small-label">
              Rx Category
            </InputLabel>

            <Select
              required
              multiple
              value={selectedRxCategory}
              className="productNameDropdown"
              labelId="product-name-select-small-label"
              id="product-name-select-small"
              onChange={(e) => handleRxCategoryChange(e)}
              renderValue={(selected) => `${selected.length} Selected`}
              label="Rx Category"
            >
              <MenuItem value="all">
                <Checkbox
                  color="orangeTheme"
                  checked={isAllRxCategorySelected}
                  indeterminate={
                    selectedRxCategory.length > 0 &&
                    selectedRxCategory.length < rxCategoryDropdownData.length
                  }
                />

                <ListItemText primary="Select All">Select All </ListItemText>
              </MenuItem>
              {rxCategoryDropdownData.map((item) => (
                <MenuItem
                  className="dropdownText"
                  key={item.rxCategory}
                  value={item.rxCategory}
                >
                  <Checkbox
                    style={{ fontSize: "14px" }}
                    color="orangeTheme"
                    checked={selectedRxCategory.indexOf(item.rxCategory) > -1}
                  />
                  <ListItemText
                    style={{ fontSize: "14px" }}
                    primary={item.rxCategory}
                  />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText style={{ height: "0px" }}>
              {/* {productNameErrorText} */}
            </FormHelperText>
          </FormControl>
          <FormControl
            className="spacingClass"
            required
            color="orangeTheme"
            sx={{
              maxWidth: "160px",
              minWidth: "160px",
              borderRadius: "6px",
            }}
            disabled={isLoadingAdhStatus}
            size="small"
          >
            <InputLabel id="product-name-select-small-label">
              Adherence Status
            </InputLabel>

            <Select
              required
              multiple
              value={selectedAdherenceStatus}
              className="productNameDropdown"
              labelId="product-name-select-small-label"
              id="product-name-select-small"
              onChange={(e) => handleAdherenceStatusChange(e)}
              renderValue={(selected) => `${selected.length} Selected`}
              label="Adherence Status"
            >
              <MenuItem value="all">
                <Checkbox
                  color="orangeTheme"
                  checked={isAllAdherenceStatusSelected}
                  indeterminate={
                    selectedAdherenceStatus.length > 0 &&
                    selectedAdherenceStatus.length <
                      adherenceStatusDropdownData.length
                  }
                />

                <ListItemText primary="Select All">Select All </ListItemText>
              </MenuItem>
              {adherenceStatusDropdownData.map((item) => (
                <MenuItem
                  className="dropdownText"
                  key={item.adherenceStatus}
                  value={item.adherenceStatus}
                >
                  <Checkbox
                    style={{ fontSize: "14px" }}
                    color="orangeTheme"
                    checked={
                      selectedAdherenceStatus.indexOf(item.adherenceStatus) > -1
                    }
                  />
                  <ListItemText
                    style={{ fontSize: "14px" }}
                    primary={item.adherenceStatus}
                  />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText style={{ height: "0px" }}>
              {/* {productNameErrorText} */}
            </FormHelperText>
          </FormControl>
        </Stack>
        {/* Filter section Ends */}
        {/* Date filter (radio) Starts */}
        <Stack direction="row" spacing={2}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="user-type-group-label"
              name="user-type-buttons-group"
              value={dateFilter}
              onChange={handleDateFilterRadioChange}
            >
              <Typography
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginRight: "10px",
                }}
                id="demo-row-radio-buttons-group-label"
              >
                Date Filter By:
              </Typography>
              {/* <Typography>Date Filter By:</Typography> */}
              <FormControlLabel
                value="absFailDate"
                control={<Radio color="orangeTheme" />}
                label="Absolute Fail Date"
              />
              <FormControlLabel
                value="nextRefillDate"
                control={<Radio color="orangeTheme" />}
                label="Next Refill Date"
              />
            </RadioGroup>
          </FormControl>
        </Stack>
        {/* Date filter (radio) Ends */}
        {/* Date filter (Chip) Section Starts */}
        <Stack direction="row" spacing={1}>
          <Chip
            label="Past 2 Weeks"
            size="small"
            className="medAdheranceChip"
            variant="outlined"
            clickable={true}
            onClick={() => setSelectedTimelineValue("past14Days")}
            color={
              selectedTimelineValue === "past14Days" ? "orangeTheme" : "default"
            }
          />
          <Chip
            label="Past Week"
            size="small"
            className="medAdheranceChip"
            variant="outlined"
            clickable={true}
            onClick={() => setSelectedTimelineValue("past7Days")}
            color={
              selectedTimelineValue === "past7Days" ? "orangeTheme" : "default"
            }
          />
          <Chip
            label="This Week"
            size="small"
            className="medAdheranceChip"
            variant="outlined"
            clickable={true}
            onClick={() => setSelectedTimelineValue("thisWeek")}
            color={
              selectedTimelineValue === "thisWeek" ? "orangeTheme" : "default"
            }
          />
          <Chip
            label="Next 2 Weeks"
            size="small"
            variant="outlined"
            className="medAdheranceChip"
            clickable={true}
            onClick={() => setSelectedTimelineValue("next2Weeks")}
            color={
              selectedTimelineValue === "next2Weeks" ? "orangeTheme" : "default"
            }
          />
          <Chip
            label="Current Month"
            size="small"
            variant="outlined"
            clickable={true}
            onClick={() => setSelectedTimelineValue("currentMonth")}
            color={
              selectedTimelineValue === "currentMonth"
                ? "orangeTheme"
                : "default"
            }
          />
          <Chip
            label="Next Month"
            size="small"
            className="medAdheranceChip"
            variant="outlined"
            clickable={true}
            onClick={() => setSelectedTimelineValue("nextMonth")}
            color={
              selectedTimelineValue === "nextMonth" ? "orangeTheme" : "default"
            }
          />
          {/* <Chip
            label="Next 2 Months"
            size="small"
            className="medAdheranceChip"
            variant="outlined"
            clickable={true}
            onClick={() => setSelectedTimelineValue("next2Months")}
            color={
              selectedTimelineValue === "next2Months"
                ? "orangeTheme"
                : "default"
            }
          /> */}
          <Stack
            direction="row"
            spacing={1}
            style={{ marginLeft: "auto", float: "right" }}
          >
            <Button
              sx={{
                textTransform: "none",
                color: "#111827",
                border: "1px solid",
                borderColor: COLOR.ORANGE,
                textAlign: "center",
                leadingTrim: "both",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
                fontSize: "12px",
                fontStyle: Styles.FONT_STYLE_NORMAL,
                fontWeight: "500",
                lineHeight: "22px",
                "&.MuiButton-root:hover": {
                  borderColor: COLOR.ORANGE,
                  border: "1px solid #F37021",
                  //   borderRadius: "4px",
                },
              }}
              variant="outlined"
              color="orangeTheme"
              onClick={() => openTimelineModal()}
            >
              <AddIcon style={{ paddingTop: "5px", marginTop: "-4px" }} />
              Custom Timeline
            </Button>
            <Button
              sx={{
                display:
                  selectedMarketName && selectedMarketName.length > 0
                    ? ""
                    : "none",
                textTransform: "none",
                color: "#111827",
                border: "1px solid",
                borderColor: "#000",
                textAlign: "center",
                leadingTrim: "both",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
                fontSize: "12px",
                fontStyle: Styles.FONT_STYLE_NORMAL,
                fontWeight: "500",
                lineHeight: "22px",
                "&.MuiButton-root:hover": {
                  borderColor: "#000",
                  border: "1px solid #000",
                  //   borderRadius: "4px",
                },
              }}
              variant="outlined"
              onClick={handleClearFilterChange}
            >
              Clear All Filters
            </Button>
          </Stack>
        </Stack>
        <hr
          style={{
            width: "auto",
            opacity: "0.3",
            marginTop: "15px",
            marginBottom: "20px",
          }}
        />
        {/* Date filter (Chip) Section Ends */}
      </Stack>

      {/* Filter Section */}
      {isAllFiltersSelected ? (
        <Stack direction="column" spacing={0.5}>
          {/* Summary Cards Section Starts */}
          <Stack direction="row" spacing={1}>
            {isLoading ? (
              <Stack direction="column">
                <Stack
                  sx={{ marginLeft: "auto", marginRight: "auto" }}
                  direction="row"
                >
                  <Loader isLoading={isLoading} />
                </Stack>
              </Stack>
            ) : (
              medicalAdherenceCount.map((item, index) => (
                <Card
                  key={index}
                  variant="outlined"
                  sx={{
                    background:
                      item.countType === activeStatus
                        ? "rgba(243, 111, 33, 0.05)"
                        : "",
                  }}
                >
                  <CardContent
                    style={{ cursor: "pointer" }}
                    onClick={() => setActiveStatus(item.countType)}
                  >
                    <Stack direction="row" spacing={3}>
                      <Typography style={{ fontSize: "20px" }}>
                        {item.countValue}
                      </Typography>
                      <Typography style={{ fontSize: "20px" }}>
                        {item.countType}
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>
              ))
            )}
          </Stack>
          {/* Summary Cards Section Ends */}

          {/* Grid Section Starts */}
          <div style={{ display: "grid" }}>
            {isLoading ? (
              <Stack direction="column">
                <Stack
                  sx={{ marginLeft: "auto", marginRight: "auto" }}
                  direction="row"
                >
                  <Loader isLoading={isLoading} />
                </Stack>
              </Stack>
            ) : (
              // <Grid>
              <DataGridCustom
                columns={columns}
                rows={medicalAdherenceList}
                // getRowId={getRowId}
                loading={isLoading}
                sortingOrder={["asc", "desc"]}
                sortingMode="server"
                slots={{
                  toolbar: DataGridHeader,
                  pagination: CustomPaginationSlot,
                }}
                slotProps={{
                  toolbar: {
                    headerText: "Prescription Details",
                    fontSize: "16px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    height: "56px",
                    children: (
                      <div
                        style={{
                          fontFamily: Styles.FONT_FAMILY_POPPINS,
                          fontSize: "16px",
                          marginTop: "auto",
                          marginBottom: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <Searchbox
                          defaultValue={searchMemberName}
                          handleChange={(e) => {
                            setSearchMemberName(e.target.value);
                          }}
                          onClick={() => setSearchMember(searchMemberName)}
                          label="Search Member"
                        ></Searchbox>
                      </div>
                    ),
                  },
                  pagination: {
                    setPage: setPage,
                    paginationModel: {
                      count: Math.ceil(parseInt(medicalAdherenceListCount) / 5),
                      page: page,
                      totalRows: parseInt(medicalAdherenceListCount),
                    },
                    colorTheme: "orangeTheme",
                    className: "pageNumber",
                  },
                }}
                sortModel={sortModel}
                onSortModelChange={(e) => handleSortModelChange(e)}
              />
              // </Grid>
            )}
          </div>

          {/* Grid Section Ends */}
        </Stack>
      ) : (
        <Stack direction="column">
          <Stack
            sx={{ marginLeft: "auto", marginRight: "auto" }}
            direction="row"
          >
            <Typography>
              Please select the appropriate fields from filter to load the
              Medical Adherence data.
            </Typography>
          </Stack>
        </Stack>
      )}

      <MedicalAdherenceTimelineModal
        isOpen={showTimelineModal}
        onClose={closeModal}
        setStartDateValue={setStartDateValue}
        setEndDateValue={setEndDateValue}
        setSelectedTimelineValue={setSelectedTimelineValue}
        selectedTimelineValue={selectedTimelineValue}
        setCustomModalCancel={setCustomModalCancel}
      />
      <AddDateModal
        isOpen={showAddDateModal}
        onClose={closeAddDateModal}
        rowData={selectedRow}
        setCustomModalCancel={setCustomModalCancel}
      />
    </Box>
  ) : (
    <MedicalAdherenceMemberDetails
      memberId={memberId}
      practiceName={practiceName}
      risk={risk}
      medAdherenceMeasure={medAdherenceMeasure}
      backAction={() => setShowDetailPage(false)}
    ></MedicalAdherenceMemberDetails>
  );
};

export default MedicalAdherenceTool;
