import React from "react";
import PageHeader from "../../component/common/header/PageHeader";

const BannerSettingsHeader = () => {
    const title = "";
    const sevenPillarTitle = "Banner Configuration";
  return (
    <>
   
      <PageHeader title={title} sevenPillarTitle={sevenPillarTitle} />
      
    </>
  );
};

export default BannerSettingsHeader;
