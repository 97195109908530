import { CssBaseline, IconButton } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import cinqCareLogo from "../../../assets/cinqCareLogo_WhiteText.png";
import ToolbarContent from "../toolbar/ToolbarContent";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        elevation={1}
        sx={{
          background: "#212121 0% 0% no-repeat padding-box",
          borderBottom: "1px solid #E2E2E2",
          opacity: "1",
        }}
      >
        <Toolbar>
          <IconButton
            onClick={() => navigate("/")}
            disableFocusRipple={true}
            disableRipple={true}
            type="button"
          >
            <img
              src={cinqCareLogo}
              alt=""
              style={{ width: "114px", height: "36px", flexShrink: 0 }}
            />
          </IconButton>
          <ToolbarContent />
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
