import {
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import React, { useEffect, useState } from "react";
import { COLOR, Styles } from "../../../Styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import DataGridHeader from "../../../component/common/datagrid/DataGridHeader";
import DataGridCustom from "../../../component/common/datagrid/DataGridCustom";
import { axiosRequests } from "../../../app/api/AxiosRequest";
import { PagesPath } from "../../../PagesPath";
import { Link, useNavigate } from "react-router-dom";
import MemberDetailsModal from "../CreateCohortRegistryManagement/Popup/MemberDetailsModal";
import { getAdmissionTrendsDetail } from "../../../component/common/sdohAssessmentFilter/SDOHAssessmentFilterDetailsSlice";
import { useAppDispatch } from "../../../app/store";
import { format } from "date-fns";
import IPEventsDetailsModal from "../CreateCohortRegistryManagement/Popup/IPEventsDetailsModal";
import EventsDetailsModal from "../CreateCohortRegistryManagement/Popup/EventsDetailsModal";
import Loader from "../../../component/common/loader/Loader";
import { formatCost } from "./CreateCohortByImport";
import Searchbox from "../../../component/common/searchbox/Searchbox";
// import MemberDetailsModal from "../../activeGapClosuresList/memberDetailsModal";

const OutlinedButton = styled(Button)(() => ({
  borderRadius: "4px",
  padding: "6px",
  justifyContent: "center",
  color: COLOR.BLACK,
  borderColor: COLOR.BLACK,
  fontFamily: Styles.FONT_FAMILY_POPPINS,
  fontStyle: "normal",
  textTransform: "capitalize",
}));

const DefaultTypography = styled(Typography)({
  color: "#000",
  fontFamily: Styles.FONT_FAMILY_POPPINS,
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
});

const StyledFormArea = styled(Grid)({
  display: "flex",
  padding: "16px 8px",
  flexDirection: "column",
  alignItems: "left",
  gap: "16px",
  flex: "1 0 0",
  alignSelf: "stretch",
  borderRadius: "5px",
  background: "rgba(226, 226, 226, 0.30)",
});

const StyledSummaryView = styled(Grid)({
  display: "flex",
  padding: "16px",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: "16px",
  alignSelf: "stretch",
  borderRadius: "8px",
  border: "1px solid #E0E0E0",
  background: "#FAFAFA",
});

const StyledSummaryTitle = styled(Typography)({
  alignSelf: "stretch",
  color: "#DC4F34",
  leadingTrim: "both",
  textEdge: "cap",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "17px" /* 106.25% */,
});

const CohortByImport_Step3 = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [ipPopupConfirmationModalOpen, SetIpPopupConfirmationModalOpen] =
    useState(false);
  const [ipPopupConfirmationModalData, setIpPopupConfirmationModalData] =
    useState({});
  const [viewIpEventsPopupModalOpen, SetViewIpEventsPopupModalOpen] =
    useState(false);
  const [viewEventsPopupModalOpen, SetViewEventsPopupModalOpen] =
    useState(false);
  const [popupConfirmationModalOpen, SetPopupConfirmationModalOpen] =
    useState(false);
  const [popupConfirmationModalData, setPopupConfirmationModalData] = useState(
    {}
  );
  const [cohortOwnerDropdownValues, setCohortOwnerDropdownValues] = useState(
    []
  );
  const [isLoading, setIsLoading] = useState(true);
  const [disableSave, setDisableSave] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    axiosRequests.get("/cohort/GetCohortOwnerDetails").then((resp) => {
      setCohortOwnerDropdownValues(resp);
      setIsLoading(false);
    });
  }, []);
  useEffect(() => {
    if (props.selectedRows && props.selectedRows.length > 0) {
      let filtered;
      if (searchValue) {
        filtered = props.selectedRows.filter(
          (item) =>
            item.firstName.toLowerCase().includes(searchValue.toLowerCase()) ||
            (item.lastName &&
              item.lastName.toLowerCase().includes(searchValue.toLowerCase()))
        );
        setFilteredData(filtered);
      } else {
        setFilteredData(props.selectedRows);
      }
    }
  }, [searchValue, props.selectedRows]);
  const viewIPEventsDetails = (memberDtl) => {
    dispatch(
      getAdmissionTrendsDetail({
        startDate: format(props.customStartDate, "yyyy-MM-dd"),
        endDate: format(props.customEndDate, "yyyy-MM-dd"),
      })
    );
    setIpPopupConfirmationModalData(memberDtl);
    SetViewIpEventsPopupModalOpen(true);
  };
  const viewEventsDetails = (memberDtl) => {
    dispatch(
      getAdmissionTrendsDetail({
        startDate: format(props.customStartDate, "yyyy-MM-dd"),
        endDate: format(props.customEndDate, "yyyy-MM-dd"),
      })
    );
    setPopupConfirmationModalData(memberDtl);
    SetViewEventsPopupModalOpen(true);
  };
  const closeModal = () => {
    SetViewIpEventsPopupModalOpen(false);
    SetViewEventsPopupModalOpen(false);
    SetPopupConfirmationModalOpen(false);
  };

  const openMemberDetailsModal = (memberDtl) => {
    setPopupConfirmationModalData(memberDtl);
    dispatch(
      getAdmissionTrendsDetail({
        startDate: format(props.customStartDate, "yyyy-MM-dd"),
        endDate: format(props.customEndDate, "yyyy-MM-dd"),
      })
    );
    SetPopupConfirmationModalOpen(true);
  };
  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerClassName: "classMultilineHeader",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.firstName + " " + params.row.lastName}>
            {params.row.firstName + " " + params.row.lastName}
          </Tooltip>
        );
      },
    },
    {
      field: "insuranceId",
      headerName: "Insurance Id",
      flex: 1,
      headerClassName: "classMultilineHeader",
    },
    {
      field: "region",
      headerName: "Region",
      flex: 1,
      headerClassName: "classMultilineHeader",
    },
    {
      field: "payer",
      headerName: "Payer",
      flex: 1,
      headerClassName: "classMultilineHeader",
    },
    {
      field: "practiceName",
      headerName: "Practice",
      flex: 1,
      headerClassName: "classMultilineHeader",
    },
    {
      field: "memberDateOfBirth",
      headerName: "Date of Birth",
      flex: 1,
      headerClassName: "classMultilineHeader",
    },
    {
      field: "chronicCondition",
      headerName: "Chronic Conditions",
      flex: 1,
      headerClassName: "classMultilineHeader",
    },
    {
      field: "totalEvents",
      headerName: "Total Events",
      flex: 1,
      headerClassName: "classMultilineHeader",
      renderCell: (params) => {
        if (params.row.totalEvents != 0)
          return (
            //   <div style={{display:"flex", alignItems:"center"}}>
            <Link
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <div onClick={() => viewEventsDetails(params.row)}>
                <u>{params.row.totalEvents}</u>
              </div>
            </Link>
            //   </div>
          );
      },
    },
    {
      field: "totalIPEvents",
      headerName: "IP Events",
      flex: 1,
      headerClassName: "classMultilineHeader",
      renderCell: (params) => {
        if (params.row.totalIPEvents != 0)
          return (
            //   <div style={{display:"flex", alignItems:"center"}}>
            <Link
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <div onClick={() => viewIPEventsDetails(params.row)}>
                <u>{params.row.totalIPEvents}</u>
              </div>
            </Link>
            //   </div>
          );
      },
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      flex: 1,
      headerClassName: "classMultilineHeader",
      renderCell: (params) =>
        params.row.totalCost && formatCost(params.row.totalCost),
    },
    {
      field: "preview",
      headerName: "Preview",
      flex: 1,
      headerClassName: "classMultilineHeader",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "7px",
              marginLeft: "10px",
            }}
          >
            <Tooltip title="Show Member Details">
              <div onClick={() => openMemberDetailsModal(params.row)}>
                <VisibilityOutlinedIcon />
              </div>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  // const openMemberDetailsModal = (memberDtl) => {
  //   setPopupConfirmationModalData(memberDtl);
  //   SetPopupConfirmationModalOpen(true);
  // };

  const onClose = () => {
    SetPopupConfirmationModalOpen(false);
  };

  useEffect(() => {
    calculateTotals(props.selectedRows);
  }, []);

  function calculateTotals(data) {
    return data.reduce(
      (acc, item) => {
        acc.totalEvents += item.totalEvents;
        acc.totalIPEvents += item.totalIPEvents;
        acc.totalCost += item.totalCost;
        return acc;
      },
      {
        totalEvents: 0,
        totalIPEvents: 0,
        totalCost: 0,
      }
    );
  }

  const handleSave = () => {
    const formData = {
      // payerId: "",
      // productId: "",
      // regionId: "",
      // practiceId: "",
      // riskCategory: "",
      // ipaFlag: "",
      // eventType: "",
      dateRange: props.admissionTrendValue,
      customDateRange: props.isCustomDateRangeSelected,
      fromDate: props.customStartDate,
      toDate: props.customEndDate,
      auditStatus: props.cohortId > 0 ? "Pending For Approval" : "",
      podName: props.podName,
      cohortName: props.cohortName,
      description: props.cohortDescription,
      cohortOwner: props.cohortOwner.toString(),
      startDate: props.cohortStartDate,
      cohortMemberDetails: props.selectedRows,
      cohortId: props.cohortId > 0 ? props.cohortId.toString() : "0",
      userId: props.cohortOwner,
      IsImport: true,
    };
    setDisableSave(true);
    axiosRequests
      .post("/Cohort/AddCohortMemberDetails", formData)
      .then((resp) => {
        console.log("resp", resp);
        if (resp === 1) {
          setDisableSave(false);
          var idUrl = PagesPath.ViewCohortRegistry_URL;
          navigate(idUrl);
        }
      });
  };

  return isLoading ? (
    <Loader isLoading={isLoading}></Loader>
  ) : (
    <>
      <StyledFormArea>
        <Stack direction="column" spacing={3}>
          <TextField
            required
            label="POD Name"
            //onChange={handlePODNameChange}
            id="outlined-size-small"
            value={props.podName}
            disabled
            // error={!!podNameError}
            // helperText={podNameError}
            style={{
              width: "705px",
              height: "40px",
              borderRadius: "6px",
              backgroundColor: "#fff",
            }}
            color="orangeTheme"
            size="small"
          />
          <TextField
            required
            label="Cohort Name"
            //onChange={handleCohortNameChange}
            id="outlined-size-small"
            value={props.cohortName}
            disabled
            //error={!!cohortNameError}
            //helperText={cohortNameError}
            style={{
              width: "705px",
              height: "40px",
              borderRadius: "6px",
              backgroundColor: "#fff",
            }}
            color="orangeTheme"
            size="small"
          />
          <TextField
            id="outlined-multiline-static"
            label="Description"
            value={props.cohortDescription}
            //error={!!cohortDescriptionError}
            //helperText={cohortDescriptionError}
            //onChange={handleCohortDescriptionChange}
            multiline
            disabled
            className="roleDescription"
            color="orangeTheme"
            rows={5}
            variant="outlined"
          />
          <FormControl
            required
            color="orangeTheme"
            sx={{
              maxWidth: "705px",
              minWidth: "705px",
              backgroundColor: "#fff",
              borderRadius: "6px",
            }}
            size="small"
          >
            <InputLabel id="role-type-select-small-label">
              Cohort Owner
            </InputLabel>
            <Select
              disabled
              required
              value={props.cohortOwner}
              labelId="role-type-select-small-label"
              id="role-type-select-small"
              //onChange={(e) => handleCohortOwnerChange(e)}
              label="Cohort Owner"
            >
              {cohortOwnerDropdownValues.map((item) => (
                <MenuItem
                  className="dropdownText"
                  key={item.userId}
                  value={item.userId}
                >
                  {item.cohortName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <FormControl
              // className="practiceNameClass"
              //className={props.isDisabledMode ? "disabledAccordian" : ""}
              required
              disabled
              // disabled={props.isDisabledMode}
              color="orangeTheme"
              sx={{
                maxWidth: "300px",
                minWidth: "300px",
                backgroundColor: "#fff",
                borderRadius: "6px",
                //border: isStartDateEmpty ? "1px solid #D32F2F" : "",
              }}
              size="small"
            >
              <DatePicker
                label="Start Date"
                disabled
                value={props.cohortStartDate}
                color="orangeTheme"
                slotProps={{
                  textField: {
                    className: "dateFieldClass",
                    color: "orangeTheme",
                    //error: !!cohortStartDateError,
                  },
                }}
                //minDate={addDays(new Date(1753, 0, 0), 1)}
                //onChange={handleStartDateChange}
              />
            </FormControl>
          </LocalizationProvider>
          <StyledSummaryView>
            <StyledSummaryTitle>Cohort Summary</StyledSummaryTitle>
            <Typography style={{ fontFamily: Styles.FONT_FAMILY_POPPINS }}>
              Total Events: {calculateTotals(props.selectedRows).totalEvents}
            </Typography>
            <Typography style={{ fontFamily: Styles.FONT_FAMILY_POPPINS }}>
              Total IP Days: {calculateTotals(props.selectedRows).totalIPEvents}
            </Typography>
            <Typography style={{ fontFamily: Styles.FONT_FAMILY_POPPINS }}>
              Total Cost of Cohort:{" "}
              {calculateTotals(props.selectedRows).totalCost}
            </Typography>
            <Typography style={{ fontFamily: Styles.FONT_FAMILY_POPPINS }}>
              Start Date: {props.customStartDate}
            </Typography>
            <Typography style={{ fontFamily: Styles.FONT_FAMILY_POPPINS }}>
              End Date: {props.customEndDate}
            </Typography>
          </StyledSummaryView>
        </Stack>
      </StyledFormArea>
      <div style={{ marginTop: "16px", display: "grid", height: 680 }}>
        <DataGridCustom
          columns={columns}
          rows={filteredData}
          pageSize={10}
          className="pafGrid"
          //checkboxSelection={true}
          loading={false}
          sortingOrder={["asc", "desc"]}
          slots={{
            toolbar: DataGridHeader,
          }}
          //onSelectionModelChanged={(newSelection) => {
          //   handleSelectionChange(newSelection);
          // }}
          slotProps={{
            toolbar: {
              headerText: "",
              fontSize: "",
              marginTop: "",
              marginBottom: "",
              height: "56px",
              children: (
                <div
                  style={{
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    fontSize: "16px",
                    width: "100%",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginLeft: "5px",
                  }}
                >
                  <Stack direction="row" spacing={1}>
                    <Typography
                      style={{
                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                        fontSize: "16px",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      Member Details
                    </Typography>
                    <Chip
                      color="orangeTheme"
                      variant="contained"
                      style={{ color: "#fff" }}
                      label={props.selectedRows.length}
                    ></Chip>
                    <Stack
                      direction="row"
                      spacing={1}
                      style={{ marginLeft: "auto", float: "right" }}
                    >
                      <Searchbox
                        cursor="default"
                        defaultValue={searchValue}
                        handleChange={(e) => setSearchValue(e.target.value)}
                        label="Search Member"
                      ></Searchbox>
                    </Stack>
                  </Stack>
                </div>
              ),
            },
          }}
        />
      </div>
      <Grid container style={{ marginTop: "16px" }} spacing={1}>
        <Grid
          item
          xs={12}
          sx={{
            margintTop: "25px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{
              justifyContent: "end",
              paddingTop: "7px",
              paddingBottom: "7px",
              paddingRight: "10px",
            }}
          >
            <OutlinedButton
              onClick={props.handleCancelCall}
              variant="text"
              size="small"
            >
              <DefaultTypography>Cancel</DefaultTypography>
            </OutlinedButton>

            <Button
              style={{
                variant: "outlined",
                borderColor: COLOR.ORANGE,
                borderRadius: "4px",
                padding: "6px",
                justifyContent: "center",
                border: "1px solid #DC4F34",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
                fontStyle: "normal",
                textTransform: "none",
                boxShadow:
                  "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
                ":hover": {},
              }}
              onClick={props.handleBackCall}
            >
              <DefaultTypography style={{ color: COLOR.ORANGE }}>
                Back
              </DefaultTypography>
            </Button>

            <Button
              style={{
                color: "white",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
              }}
              variant="contained"
              color="orangeTheme"
              disableElevation
              disabled={disableSave}
              onClick={handleSave}
            >
              {props.cohortId === 0 ? "Save" : "Save and Send For Approval"}
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <IPEventsDetailsModal
        isOpen={viewIpEventsPopupModalOpen}
        onClose={closeModal}
        rowData={ipPopupConfirmationModalData}
        // startDateValue={format(customStartDate, "yyyy-MM-dd")}
        // endDateValue = {format(customEndDate, "yyyy-MM-dd")}
      ></IPEventsDetailsModal>
      <EventsDetailsModal
        isOpen={viewEventsPopupModalOpen}
        onClose={closeModal}
        rowData={popupConfirmationModalData}
        // startDateValue={format(customStartDate, "yyyy-MM-dd")}
        // endDateValue = {format(customEndDate, "yyyy-MM-dd")}
      ></EventsDetailsModal>
      <MemberDetailsModal
        isOpen={popupConfirmationModalOpen}
        onClose={closeModal}
        rowData={popupConfirmationModalData}
        Preview={1}
      ></MemberDetailsModal>
    </>
  );
};

export default CohortByImport_Step3;
