import {
  Button,
  Checkbox,
  Chip,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import DataGridCustom from "../../component/common/datagrid/DataGridCustom";
import OpenQualityMeasuresStateUpdate from "./openQualityMeasuresStateUpdate";
import DataGridHeader from "../../component/common/datagrid/DataGridHeader";
import { Styles } from "../../Styles";
import BaseCheckbox from "../../component/common/datagrid/BaseCheckbox";
import { useGridApiRef } from "@mui/x-data-grid";
import "./activeGapClosuresListCSS.css";
import { format } from "date-fns";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const OpenQualityMeasuresGrid = ({
  memberDetail,
  forceUpdate,
  setForceUpdate,
  handleViewDocumentsOpen,
  //onClose,
  isLoading,
  hideExcludeMember = false,
  isAuditor = false,
  auditStatusId = 1,
}) => {
  const [title, setTitle] = useState("");
  const [rowId, setRowId] = useState(null);
  const [isOpenQualityMeasuresModalOpen, setIsOpenQualityMeasuresModalOpen] =
    useState(false);
  const apiRef = useGridApiRef();
  const rows = useState(memberDetail.openQualityMeasures);
  const [selectedRows, setSelectedRows] = useState([]);
  const handleopenQualityMeasuresStateUpdateOpen = (measureTitle, rowId) => {
    if (rowId) {
      setTitle(measureTitle);
      setRowId(rowId);
    }
    //setOurId(ourId);
    setIsOpenQualityMeasuresModalOpen(true);
  };
  const handleSelectionChange = (selectionModel) => {
    const selectedIDs = new Set(selectionModel);
    const selectedData = rows[0].filter((row) => selectedIDs.has(row.id));
    setSelectedRows(selectedData);
  };
  const onClose = () => {
    setIsOpenQualityMeasuresModalOpen(false);
  };
  const columnsOpenQualityMeasure = [
    {
      field: "measureTitle",
      headerName: "Measure Title",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "visitDate",
      headerName: "Visit Date",
      flex: 1,
      renderCell: (params) => {
        if (params.row.visitDate) {
          return <span>{format(params.row.visitDate, "MM/dd/yyyy")}</span>;
        }
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => {
        if (params.row.status.toLowerCase() === "needed") {
          if (!selectedRows || selectedRows.length < 2) {
            return (
              <Tooltip
                onClick={() =>
                  handleopenQualityMeasuresStateUpdateOpen(
                    params.row.measureTitle,
                    params.row.id,
                    params.row.ourId
                  )
                }
                title={"Update Status"}
                style={{ cursor: "pointer" }}
              >
                <EditOutlinedIcon color="blackTheme" />
              </Tooltip>
            );
          }
        } else if (params.row.status.toLowerCase() === "completed") {
          return (
            <Tooltip
              onClick={() =>
                handleViewDocumentsOpen(params.row.id, "OpenQuality")
              }
              title={"View Documents"}
              style={{ cursor: "pointer" }}
            >
              <DescriptionOutlinedIcon color="blackTheme" />
            </Tooltip>
          );
        }
      },
    },
  ];

  const isRowSelectable = (params) => {
    return params.row.status.toLowerCase() === "needed";
  };
  return (
    <div style={{ marginTop: "16px", display: "grid", height: 426 }}>
      {
        <DataGridCustom
          columns={columnsOpenQualityMeasure}
          rows={memberDetail.openQualityMeasures}
          apiRef={apiRef}
          className="pafGrid"
          pageSize={5}
          isRowSelectable={isRowSelectable}
          checkboxSelection={true}
          onSelectionModelChanged={(newSelection) => {
            handleSelectionChange(newSelection);
          }}
          loading={isLoading}
          sortingOrder={["asc", "desc"]}
          slots={{
            toolbar: DataGridHeader,
            //baseCheckbox: BaseCheckbox,
          }}
          slotProps={{
            toolbar: {
              headerText: "",
              fontSize: "",
              marginTop: "",
              marginBottom: "",
              height: "56px",
              children: (
                // <Stack direction="row" spacing={2}>
                <div
                  style={{
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    fontSize: "16px",
                    width: "100%",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginLeft: "5px",
                  }}
                >
                  <Stack direction="row" spacing={1}>
                    <Typography
                      style={{
                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                        fontSize: "16px",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      Open Quality Measures
                    </Typography>
                    <Chip
                      color="orangeTheme"
                      variant="contained"
                      style={{ color: "#fff" }}
                      label={memberDetail.openQualityMeasures.length}
                    ></Chip>
                    <Stack
                      direction="row"
                      spacing={1}
                      style={{ marginLeft: "auto" }}
                    >
                      {selectedRows.length > 1 && (
                        <Button
                          variant="outlined"
                          color="blackTheme"
                          style={{
                            fontFamily: Styles.FONT_FAMILY_POPPINS,
                            textTransform: "none",
                            float: "right",
                          }}
                          onClick={handleopenQualityMeasuresStateUpdateOpen}
                        >
                          Bulk Status Update
                        </Button>
                      )}
                    </Stack>
                  </Stack>
                </div>

                // </Stack>
              ),
            },
          }}
        />
      }
      {isOpenQualityMeasuresModalOpen && (
        <OpenQualityMeasuresStateUpdate
          isOpen={isOpenQualityMeasuresModalOpen}
          onClose={onClose}
          title={title}
          rowId={rowId}
          rows={selectedRows}
          ourId={memberDetail.ourId}
          forceUpdate={forceUpdate}
          setForceUpdate={setForceUpdate}
        />
      )}
    </div>
  );
};

export default OpenQualityMeasuresGrid;
