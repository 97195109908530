import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import agent from "../../app/api/Agent";
import agent from "../../../app/api/Agent";

const initialState = {
  isLoading: false,
  usersData: [],
  errorMessage: "",
};

export const getUsersList = createAsyncThunk(
  "usersData",
  async () => {
    const response =
      await agent.UserDataAgent.getUsersList();
    return response;
  }
);

const getUsersListSlice = createSlice({
  name: "usersData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsersList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUsersList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.usersData = action.payload;
      })
      .addCase(getUsersList.rejected, (state, action) => {
        state.isLoading = false;
        state.usersData = [];
        state.errorMessage = action.error.message;
      });
  },
});

export default getUsersListSlice.reducer;
