import React, { useState } from "react";
// import { Box } from "@mui/material";
import { Button, Grid } from "@mui/material";
import DataGridCustom from "../../component/common/datagrid/DataGridCustom";
import Fab from '@mui/material/Fab';
import { Styles } from "../../Styles";
import AddIcon from "@mui/icons-material/Add";
import UsersTable from "./Users/UsersTable";
import AddUserForm from "./Users/AddUserForm";
import UserRolesTable from "./User Roles/UserRolesTable";
import AddUserRoleForm from "./User Roles/AddUserRoleForm";

const Users = () => {
    const [addUserRolesFormDisplay, setAddUserRolesFormDisplay] = React.useState(false);

    return (
        <>
            {
                
                <UserRolesTable setAddUserRolesFormDisplay={setAddUserRolesFormDisplay}></UserRolesTable> 
            }

        </>
    )

}

export default Users;