import { axiosRequests } from "../AxiosRequest";

function getVoluntaryAlignmentList(search, sortColumn, sortOrder, page = 1) {
  if (search !== undefined && search !== null && search.trim() !== "") {
  } else search = "";
  if (sortColumn === undefined) sortColumn = "";
  if (sortOrder === undefined) sortOrder = "";
  return axiosRequests.get(
    `/VoluntaryAlignment/GetVoluntaryAlignmentMembersList?memberName=${search}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&pageNumber=${page}`
  );
}

export const VoluntaryAlignmentAgent = {
  getVoluntaryAlignmentList: getVoluntaryAlignmentList,
};
