import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { Styles } from "../../Styles";
import { useState } from "react";
import ProgramDetailsModal from "./ProgramDetailsModal";
import { useNavigate } from "react-router-dom";
import { axiosRequests } from "../../app/api/AxiosRequest";

const StyledCard = styled(Card)({
  width: "430px",
  minHeight: "300px",
  maxHeight: "300px",
  display: "flex",
  backgroundColor: "rgba(243, 112, 33, 0.10)",
  fontFamily: Styles.FONT_FAMILY_POPPINS,
  flexDirection: "column",
  justifyContent: "space-between",
  borderRadius: '8px',
  '&:hover': {
    borderBottom: '3px solid var(--Color-Secondary, #FF9336)',
    background: 'rgba(243, 112, 33, 0.20)',
    },
});

const CardDescription = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontFamily: Styles.FONT_FAMILY_POPPINS,
  display: "-webkit-box",
  WebkitLineClamp: 4,
  WebkitBoxOrient: "vertical",
});

const ProgramNameDescriptionCard = ({ name, description, url,id }) => {
  const navigate = useNavigate();
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [detailsTitle, setDetailsTitle] = useState("");
  const [detailsDescription, setDetailsDescription] = useState("");

  const openDetailsModal = (name, description,id) => {
    setDetailsTitle(name);
    setDetailsDescription(description);
    setIsDetailsModalOpen(true);
  };

  const onClose = () => {
    setIsDetailsModalOpen(false);
  };
  const handleOpenProgramClick = () => {
    axiosRequests.post(`/EducationProgram/UpdateActivityTracker?ActivityType=Programs&ActivityID=${id}`).then(resp => {
      console.log("Updated Succesfully");
    })
  }

  return (
    <StyledCard>
      <CardContent>
        <Typography style={{color:'#DC4F34'}} variant="h6" component="div" gutterBottom>
          {name}
        </Typography>
        <CardDescription variant="body2" color="text.secondary">
          {description}
        </CardDescription>
      </CardContent>
      <Box display="flex" justifyContent="flex-start" p={2}>
        <Stack direction="row" spacing={1}>
          <Button
            size="small"
            style={{
              borderColor: "black",
              color: "black",
              fontFamily: Styles.FONT_FAMILY_POPPINS,
              textTransform: 'none'
            }}
            onClick={()=>openDetailsModal(name, description)}
            variant="outlined"
          >
            View Details
          </Button>
          <a href={url} target="_blank">
          <Button
            style={{ color: "#fff", fontFamily: Styles.FONT_FAMILY_POPPINS,
              textTransform: 'none' }}
            color="orangeTheme"
            variant="contained"
            size="small"
            onClick={handleOpenProgramClick}
            disableElevation
          >
            Start Program
          </Button>
          </a>
          
        </Stack>
      </Box>
      <ProgramDetailsModal
        isOpen={isDetailsModalOpen}
        onClose={onClose}
        name={detailsTitle}
        description={detailsDescription}
        url={url}
        id={id}
      ></ProgramDetailsModal>
    </StyledCard>
  );
};

export default ProgramNameDescriptionCard;
