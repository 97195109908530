
import React, { useEffect, useState } from "react";
import { Box, Grid, Stack, Divider, Typography, } from "@mui/material";
import { PieChart } from '@mui/x-charts/PieChart';
import { useSelector } from "react-redux";
import { Styles } from "../../Styles";
import "../../component/common/servenPillarMenu/style.css";
import Loader from "../../component/common/loader/Loader";
const SDOHPieChart = () => {
    const [riskScoreData, setRiskScoreData] = useState([]);
    const [genderData, setGenderData] = useState([]);
    const [ageData, setAgeData] = useState([]);
    const [ethnicityData, setEthnicityData] = useState([]);
    const [displayPieChartSection, SetDisplayPieChartSection] = useState(false);

    const { isLoading, pieChartData } = useSelector(
        (state) => state.filterDetails
    );
    const { activeStatus } = useSelector(
        (state) => state.filterDetails.activeStatus
    );

    useEffect(() => {
        if (activeStatus != undefined) {
            if (activeStatus?.category == "Completed") {
                if (pieChartData && pieChartData?.scoreDetails)
                    SetDisplayPieChartSection(true);
                else
                    SetDisplayPieChartSection(false);
            }
            else {
                SetDisplayPieChartSection(false);
            }
        }
        else {
            SetDisplayPieChartSection(false);
        }
    }, [activeStatus])

    useEffect(() => {
        if (pieChartData && pieChartData?.scoreDetails) {
            let dataRiskScore = [];
            let obj = {};
            pieChartData?.scoreDetails.map((risk) => {
                obj = {
                    color: risk?.riskColor,//"#EC6B6B",
                    value: risk?.riskCount,//11067,
                    label: risk?.riskScore//"High"
                };
                dataRiskScore.push(obj);
            });
            setRiskScoreData(dataRiskScore);
            if (activeStatus?.category == "Completed") {
                    SetDisplayPieChartSection(true);
            }
            else {
                SetDisplayPieChartSection(false);
            }
        }

        if (pieChartData && pieChartData?.genderInfo) {
            let dataGender = [];
            let obj = {};
            pieChartData?.genderInfo.map((gender) => {
                if (gender.gender === "Female" || gender.gender === "F") {
                    obj = {
                        color: gender.genderColor,//"#EC6B6B",
                        value: gender.genderCount,//11067,
                        label: "Female"//"High"
                    };
                }
                else if (gender.gender === "Male" || gender.gender === "M") {
                    obj = {
                        color: gender.genderColor,//"#EC6B6B",
                        value: gender.genderCount,//11067,
                        label: "Male"//"High"
                    };
                }
                else {
                    obj = {
                        color: gender.genderColor,//"#EC6B6B",
                        value: gender.genderCount,//11067,
                        label: "Other"//"High"
                    };
                }
                dataGender.push(obj);
            });
            setGenderData(dataGender);
        }

        if (pieChartData && pieChartData?.ageInfo) {
            let dataAge = [];
            let obj = {};
            pieChartData?.ageInfo.map((age) => {
                obj = {
                    color: age?.ageColor,//"#EC6B6B",
                    value: age?.ageCount,//risk?.ageRange,//11067,
                    label: age?.ageRange//"High"
                };
                dataAge.push(obj);
            });
            setAgeData(dataAge);
        }

        if (pieChartData && pieChartData?.ethnictyInfo) {
            let dataEthnicity = [];
            let obj = {};
            pieChartData?.ethnictyInfo.map((ethnicty) => {

                if (ethnicty?.ethnicity != '') {
                    obj = {
                        color: ethnicty.ethnicityColor,//"#EC6B6B",
                        value: ethnicty.ethnicityCount,//risk?.ageRange,//11067,
                        label: ethnicty.ethnicity//"High"
                    };
                    dataEthnicity.push(obj);
                    setEthnicityData(dataEthnicity);
                }
            });

        }


    }, [pieChartData]);
    const paletteAge = ['#5E3FBE', '#CBB6F8', '#E5DAFB', '#F4F0FD'];
    const paletteEthnicity = ['#F4E9BD', '#F9F5E5', '#EFDE96', '#EAD26E', '#C4AA41', '#E5C646'];

    const size = {
        width: 200,
        height: 200,
    };
    return (
        <Box sx={{ p: { xs: 4, sm: 2 }, }}>
            {isLoading ? (
                <Loader isLoading={isLoading} />
            ) : (
                <div style={{ display: (displayPieChartSection == true) ? "" : "none" }}>
                    <Stack direction="row" spacing={2} sx={{ marginLeft: 2, marginBottom: 2 }}>
                        <Stack
                            direction="column"
                            spacing={1}
                            sx={{
                                marginLeft: "2%",
                                marginTop: 5,
                            }}
                        >
                            <Typography
                                sx={{
                                    textAlign: "left",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    lineHeight: "30px",
                                    letterSpacing: "0.15px",
                                }}
                            >
                                Cumulative SDOH Risk Score & Demographics
                            </Typography>
                        </Stack>
                    </Stack>
                    <Grid container sx={{ marginTop: 1 }} >
                        <Grid item xs={5.7}
                            sx={{
                                height: "500px",
                            }}
                        >

                            <Typography
                                sx={{
                                    textAlign: "center",
                                    color: "rgba(102, 102, 102, 1)",
                                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0.15px",
                                }}
                            >
                                Risk Score
                            </Typography>

                            <PieChart
                                slotProps={{
                                    legend: {
                                        hidden: true,
                                        labelStyle: {
                                            fontSize: 10,
                                        },
                                        direction: 'column',
                                        position: {
                                            vertical: 'bottom', horizontal: 'middle'
                                        },
                                        padding: 0,
                                        marginLeft: "auto"
                                    },
                                }}
                                series={[
                                    {
                                        data: riskScoreData
                                    },
                                ]
                                }

                                sx={{
                                    marginTop: "-35%",
                                    marginLeft: "75%",
                                    display: "flex",
                                    justifyContent: "center"
                                    // '& .MuiChartsSurface-root':{
                                    //     backgroundColor:'red'
                                    // }   
                                }}

                                {...size}
                                colors={paletteAge}
                            />

                            <Stack
                                direction="row"
                                sx={{
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "space-around",
                                    display: "flex",
                                    marginTop: "-25%",
                                    marginBottom: "10%",
                                    flexWrap: "wrap"
                                }}
                            >
                                {riskScoreData?.map((row) => {
                                    const { label, color } =
                                        row;
                                    return (
                                        <Stack direction="row"
                                            sx={{
                                                display: "flex"
                                            }}
                                            key={label}
                                        >
                                            <div style={{
                                                borderRadius: '50%',
                                                width: '10px',
                                                height: '10px',
                                                alignContent: "center",
                                                backgroundColor: `${color}`
                                            }} ></div>
                                            <Typography
                                                sx={{
                                                    alignContent: "center",
                                                    color: "#2C3533",
                                                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                    fontSize: "10px",
                                                    fontWeight: "400",
                                                    lineHeight: "12.1px",
                                                    marginLeft: 1
                                                }}
                                            >
                                                {label}
                                            </Typography>
                                        </Stack>
                                    );

                                })}
                            </Stack>

                            <Typography
                                sx={{
                                    textAlign: "center",
                                    color: "rgba(102, 102, 102, 1)",
                                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0.15px",
                                }}
                            >
                                Gender
                            </Typography>

                            <PieChart
                                slotProps={{
                                    legend: {
                                        hidden: true,
                                        labelStyle: {
                                            fontSize: 10,
                                            fill: 'blue',
                                        },
                                        direction: 'row',
                                        position: {
                                            vertical: 'bottom', horizontal: 'middle'
                                        },
                                        padding: 0,
                                    },
                                }}
                                series={[
                                    {
                                        data: genderData,
                                    },
                                ]}

                                sx={{
                                    marginTop: "-35%",
                                    marginLeft: "70%",
                                }}

                                {...size}
                                colors={paletteEthnicity}
                            />

                            <Stack
                                direction="row"
                                sx={{
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "space-around",
                                    display: "flex",
                                    marginTop: "-30%",
                                    flexWrap: "wrap"
                                }}
                            >
                                {genderData?.map((row) => {
                                    const { label, color } =
                                        row;
                                    return (
                                        <Stack
                                            direction="row"
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                            key={label}
                                        >
                                            <div style={{
                                                borderRadius: '50%',
                                                width: '10px',
                                                height: '10px',
                                                alignContent: "center",
                                                backgroundColor: `${color}`
                                            }} ></div>
                                            <Typography
                                                sx={{
                                                    alignContent: "center",
                                                    color: "#2C3533",
                                                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                    fontSize: "10px",
                                                    fontWeight: "400",
                                                    lineHeight: "12.1px",
                                                    marginLeft: 1
                                                }}
                                            >
                                                {label}
                                            </Typography>
                                        </Stack>
                                    );

                                })}
                            </Stack>

                        </Grid>
                        <div style={{
                            width: '15px',
                            paddingLeft: "-10px"
                        }} >
                            <Divider
                                sx={{
                                    margin: 1.5,
                                    background: "black",
                                    height: "350px",
                                    color: "#E0E0E0"
                                }}
                                orientation="vertical"
                            />
                        </div>
                        <Grid item xs={5.7}
                            sx={{
                                height: "500px",
                            }}
                        >

                            <Typography
                                sx={{
                                    textAlign: "center",
                                    color: "rgba(102, 102, 102, 1)",
                                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0.15px",
                                }}
                            >
                                Age
                            </Typography>

                            <PieChart
                                slotProps={{
                                    legend: {
                                        hidden: true,
                                        labelStyle: {
                                            fontSize: 10,
                                        },
                                        direction: 'column',
                                        position: {
                                            vertical: 'bottom', horizontal: 'middle'
                                        },
                                        padding: 0,
                                        marginLeft: "auto"
                                    },
                                }}
                                series={[
                                    {
                                        data: ageData,
                                    },
                                ]
                                }

                                sx={{
                                    marginTop: "-35%",
                                    marginLeft: "70%",
                                    direction: "column"

                                }}

                                {...size}
                                colors={paletteAge}
                            />


                            <Stack
                                direction="row"
                                sx={{
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "space-around",
                                    display: "flex",
                                    marginTop: "-25%",
                                    marginBottom: "10%",
                                    marginLeft: "10px",
                                    flexWrap: "wrap"
                                }}
                            >

                                {ageData?.map((row) => {
                                    const { label, color } =
                                        row;
                                    return (
                                        <Stack direction="row"
                                            sx={{
                                                display: "flex"
                                            }}
                                            key={label}
                                        >
                                            <div style={{
                                                borderRadius: '50%',
                                                width: '10px',
                                                height: '10px',
                                                alignContent: "center",
                                                backgroundColor: `${color}`
                                            }} ></div>
                                            <Typography
                                                sx={{
                                                    alignContent: "center",
                                                    color: "#2C3533",
                                                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                    fontSize: "10px",
                                                    fontWeight: "400",
                                                    lineHeight: "12.1px",
                                                    marginLeft: 1
                                                }}
                                            >
                                                {label}
                                            </Typography>
                                        </Stack>
                                    );

                                })}
                            </Stack>

                            <Typography
                                sx={{
                                    textAlign: "center",
                                    color: "rgba(102, 102, 102, 1)",
                                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    lineHeight: "24px",
                                    letterSpacing: "0.15px",
                                }}
                            >
                                Ethnicity

                            </Typography>


                            <PieChart
                                slotProps={{
                                    legend: {
                                        hidden: true,
                                        labelStyle: {
                                            fontSize: 10,
                                            fill: 'blue',
                                        },
                                        direction: 'row',
                                        position: {
                                            vertical: 'bottom', horizontal: 'middle'
                                        },
                                        padding: 0,
                                    },
                                }}
                                series={[
                                    {
                                        data: ethnicityData,
                                    },
                                ]}

                                sx={{
                                    marginTop: "-35%",
                                    marginLeft: "70%",
                                }}

                                {...size}
                                colors={paletteEthnicity}
                            />


                            <Stack
                                direction="row"
                                sx={{
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "space-around",
                                    display: "flex",
                                    marginTop: "-30%",
                                    flexWrap: "wrap"
                                }}
                            >
                                {ethnicityData?.map((row) => {
                                    const { label, color } =
                                        row;
                                    return (
                                        <Stack
                                            direction="row"
                                            key={label}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <div style={{
                                                borderRadius: '50%',
                                                width: '10px',
                                                height: '10px',
                                                alignContent: "center",
                                                backgroundColor: `${color}`
                                            }} ></div>
                                            <Typography
                                                sx={{
                                                    alignContent: "center",
                                                    color: "#2C3533",
                                                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                    fontSize: "10px",
                                                    fontWeight: "400",
                                                    lineHeight: "12.1px",
                                                    marginLeft: 1
                                                }}
                                            >
                                                {label}
                                            </Typography>
                                        </Stack>
                                    );

                                })}
                            </Stack>

                        </Grid>
                    </Grid>
                </div>
            )}
        </Box>
    );
}
export default SDOHPieChart;