import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Styles } from "../../Styles";
import CloseIcon from "@mui/icons-material/Close";
import AuditTimelines from "./AuditTimelines";
import { axiosRequests } from "../../app/api/AxiosRequest";

const CodeDetailsModal = ({
  isOpen,
  onClose,
  rowData,
  source = "recaptureGrid",
}) => {
  const [auditData, setAuditData] = useState([]);

  useEffect(() => {
    if (isOpen) {
      let apiURL;
      if (source.toLowerCase() === "sdohgrid") {
        apiURL = `/ActiveGapClosure/GetSDOHCodingSuspectAuditHistory?OurId=${rowData.ourId}&MedicalCodingSuspectId=${rowData.id}`;
      } else {
        apiURL = `/ActiveGapClosure/GetMedicalCodingSuspectAuditHistory?OurId=${rowData.ourId}&MedicalCodingSuspectId=${rowData.id}`;
      }
      axiosRequests.get(apiURL).then((resp) => {
        setAuditData(resp);
      });
    }
  }, [isOpen]);
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      slotProps={{ backdrop: { onClick: null } }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "1000px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          pt: 1,
          maxHeight: "650px",
          height: "auto",
        }}
      >
        <h3
          style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
        >
          {source === "sdohGrid"
            ? "SDOH Code Details"
            : "Recapture Code Details"}
          <span>
            <CloseIcon onClick={onClose} style={{ float: "right" }} />
          </span>
        </h3>
        <hr
          style={{
            width: "auto",
            opacity: "0.3",
            marginTop: "-8px",
            // marginBottom: "-4px",
          }}
        />
        <Stack direction="column" spacing={2}>
          <Stack direction="row" spacing={2}>
            {source === "sdohGrid" ? (
              <Typography>
                <b>SDOH Code: </b> {rowData.sdohCode}
              </Typography>
            ) : (
              <Typography>
                <b>ICD10 Code: </b> {rowData.icD10}
              </Typography>
            )}
          </Stack>
          <Stack direction="row" spacing={2}>
            <Typography>
              <b>Status: </b>{" "}
              {rowData.status && rowData.status !== "" ? rowData.status : "-"}
            </Typography>
          </Stack>
          {/* <Stack direction="row" spacing={2}>
            <Typography>
              <b>Comment: </b>{" "}
              {rowData.comments && rowData.comments !== ""
                ? rowData.comments
                : "-"}
            </Typography>
          </Stack> */}
          <Stack direction="row" spacing={2}>
            {source === "sdohGrid" ? (
              <Typography>
                <b>SDOH Needs Identified: </b>{" "}
                {rowData.activeConditionDiagnosed &&
                rowData.activeConditionDiagnosed !== ""
                  ? rowData.activeConditionDiagnosed
                  : "-"}
              </Typography>
            ) : (
              <Typography>
                <b>Active Conditions Diagnosed: </b>{" "}
                {rowData.activeConditionDiagnosed &&
                rowData.activeConditionDiagnosed !== ""
                  ? rowData.activeConditionDiagnosed
                  : "-"}
              </Typography>
            )}
          </Stack>
          {source.toLowerCase() === "sdohgrid" && auditData.length > 0 ? (
            <Typography>
              <b>SDOH Code Audit History</b>
            </Typography>
          ) : source.toLowerCase() !== "sdohgrid" && auditData.length > 0 ? (
            <Typography>
              <b>ICD10 Code Audit History</b>
            </Typography>
          ) : (
            <Typography>
              <b>No Audit History available for this code</b>
            </Typography>
          )}
          {auditData.length > 0 && (
            <Stack direction="row" spacing={2}>
              <AuditTimelines auditData={auditData} source={source} />
            </Stack>
          )}
          <hr
            style={{
              width: "auto",
              opacity: "0.3",
              // marginTop: "-8px",
              // marginBottom: "-4px",
            }}
          />
          <Stack direction="row" style={{ marginLeft: "auto" }}>
            <Button
              variant="text"
              style={{ textTransform: "none" }}
              onClick={() => onClose()}
              color="blackTheme"
            >
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default CodeDetailsModal;
