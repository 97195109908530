import { Avatar, Stack, Typography } from "@mui/material";
import React from "react";
import { Styles } from "../../../Styles";

const UserProfile = () => {
  const roleName = () => {
    if (parseInt(localStorage.getItem("globalAdmin")) === 1)
      return "Global Administrator";
    else if (parseInt(localStorage.getItem("practiceAdmin")) === 1)
      return "Practice Administrator";
    else if (parseInt(localStorage.getItem("learningUser")) === 1)
      return "Learning User";
    else return "User";
  };

  return (
    <>
      <Avatar
        sx={{ bgcolor: "orange", marginRight: 1 }}
        style={{ justifyContent: "center", display: "flex" }}
      ></Avatar>
      <Stack
        spacing={1}
        sx={{ flexGrow: 0, marginRight: 1.5, alignItems: "left" }}
        alignItems="flex-end"
      >
        <Typography
          sx={{
            // textAlign: "left",
            // font: Styles.FONT_FAMILY_14_20,
            // letterSpacing: "0px",
            // color: Styles.FONT_COLOR,
            // opacity: "0.6",
            textAlign: "left",
            color: Styles.FONT_COLOR,
            fontFamily: Styles.FONT_FAMILY_POPPINS,
            fontSize: "10px",
            fontStyle: Styles.FONT_STYLE_NORMAL,
            fontWeight: "400",
            lineHeight: "normal",
          }}
        >
          {roleName() ?? ""}
        </Typography>
        <Typography
          sx={{
            textAlign: "left",
            color: Styles.FONT_COLOR,
            fontFamily: Styles.FONT_FAMILY_POPPINS,
            fontSize: "12px",
            fontStyle: Styles.FONT_STYLE_NORMAL,
            fontWeight: "500",
            lineHeight: "normal",
          }}
        >
          {localStorage.getItem("userFullName") ?? ""}
        </Typography>
      </Stack>
    </>
  );
};

export default UserProfile;
