import { TextField } from "@mui/material";
import React from "react";

const TextFieldOutlined = ({
  label,
  defaultValue,
  helperText,
  handleChange,
  readOnly,
}) => {
  return (
    <TextField
      InputProps={{
        readOnly: readOnly,
      }}
      required
      label={label}
      id="outlined-size-small"
      defaultValue={defaultValue ?? ""}
      error={!!helperText}
      helperText={helperText}
      onChange={(e) => handleChange(e)}
      style={{
        width: "293px",
        height: "34px",
      }}
      color="orangeTheme"
      size="small"
    />
  );
};

export default TextFieldOutlined;
