import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { Styles } from "../../Styles";
import { useEffect, useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { addDays, addMonths, addWeeks, format, subDays } from "date-fns";

const MedicalAdherenceTimelineModal = ({
  isOpen,
  onClose,
  setStartDateValue,
  setEndDateValue,
  setSelectedTimelineValue,
  setCustomModalCancel,
  selectedTimelineValue,
}) => {
  const [showDateFields, setShowDateFields] = useState(false);
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [customPeriodType, setCustomPeriodType] = useState("next");
  const [customPeriodValue, setCustomPeriodValue] = useState("7");
  const [customPeriodRangeType, setCustomPeriodRangeType] = useState("days");
  const [customStartDateError, setCustomStartDateError] = useState("");
  const [customEndDateError, setCustomEndDateError] = useState("");
  const [customPeriodValueError, setCustomPeriodValueError] = useState("");
  const [defaultValuesArr, setDefaultValuesArr] = useState({});

  useEffect(() => {
    if (selectedTimelineValue !== "custom") {
      setCustomStartDate(null);
      setCustomEndDate(null);
      setCustomPeriodValue("7");
      setCustomPeriodRangeType("days");
      setCustomPeriodType("next");
      setCustomStartDateError("");
      setCustomEndDateError("");
      setCustomPeriodValueError("");
      setShowDateFields(false);
    }
    else{
      if(defaultValuesArr.customStartDate && defaultValuesArr.customEndDate){
        setShowDateFields(true);
      }
      else {
        setShowDateFields(false);
      }
      setCustomStartDate(defaultValuesArr.customStartDate);
        setCustomEndDate(defaultValuesArr.customEndDate);
        setCustomPeriodType(defaultValuesArr.customPeriodType);
        setCustomPeriodValue(defaultValuesArr.customPeriodValue);
        setCustomPeriodRangeType(defaultValuesArr.customPeriodRangeType);
    }
  }, [isOpen]);

  //Change Functions Start Here
  const handleChange = () => {
    if (showDateFields) setShowDateFields(false);
    else setShowDateFields(true);
  };

  const handleCustomPeriodTypeChange = (e) => {
    setCustomPeriodType(e.target.value);
  };

  const handleCustomPeriodValueChange = (e) => {
    setCustomPeriodValue(e.target.value);
    setCustomPeriodValueError("");
  };

  const handleCustomPeriodRangeTypeChange = (e) => {
    setCustomPeriodRangeType(e.target.value);
  };

  const handleCustomStartDateChange = (e) => {
    setCustomStartDate(e);
    setCustomStartDateError("");
  };

  const handleCustomEndDateChange = (e) => {
    setCustomEndDate(e);
    setCustomEndDateError("");
  };

  //Change Functions End Here

  //Apply Function Starts Here
  const handleApplyFilter = () => {
    var hasError = false;
    if (showDateFields) {
      if (!customStartDate || customStartDate === "") {
        setCustomStartDateError("Start Date is Required");
        hasError = true;
      }
      if (!customEndDate || customEndDate === "") {
        setCustomEndDateError("End Date is Required");
        hasError = true;
      }
      if (
        customStartDate &&
        customStartDate < addDays(new Date(1753, 0, 0), 1)
      ) {
        setCustomStartDateError("Invalid Start Date");
        hasError = true;
      }
      if (customStartDate && customEndDate && customEndDate < customStartDate) {
        setCustomEndDateError("Invalid End Date");
        hasError = true;
      }
      if (hasError) return;
      setStartDateValue(format(customStartDate, "yyyy-MM-dd"));
      setEndDateValue(format(customEndDate, "yyyy-MM-dd"));
      setCustomModalCancel(false);
      var arr = {};
      arr.customStartDate = customStartDate;
      arr.customEndDate = customEndDate;
      arr.customPeriodType = "next";
      arr.customPeriodValue = "7";
      arr.customPeriodRangeType = "days";
      setDefaultValuesArr(arr);
      setSelectedTimelineValue("custom");
      onClose();
    } else {
      if (!customPeriodValue || customPeriodValue === "") {
        setCustomPeriodValueError("Range is Required");
        hasError = true;
      }
      if (hasError) return;
      var value = customPeriodValue;
      var date = new Date();
      if (customPeriodRangeType === "days") {
        if (customPeriodType === "previous") {
          var dateBefore = subDays(date, parseInt(value));
          setStartDateValue(format(dateBefore, "yyyy-MM-dd"));
          setEndDateValue(format(date, "yyyy-MM-dd"));
        } else if (customPeriodType === "next") {
          var dateAfter = addDays(date, parseInt(value));
          setEndDateValue(format(dateAfter, "yyyy-MM-dd"));
          setStartDateValue(format(date, "yyyy-MM-dd"));
        }
      } else if (customPeriodRangeType === "weeks") {
        if (customPeriodType === "previous") {
          var dateBefore = addWeeks(date, parseInt(value));
          setStartDateValue(format(dateBefore, "yyyy-MM-dd"));
          setEndDateValue(format(date, "yyyy-MM-dd"));
        } else if (customPeriodType === "next") {
          var dateAfter = addWeeks(date, parseInt(value));
          setEndDateValue(format(dateAfter, "yyyy-MM-dd"));
          setStartDateValue(format(date, "yyyy-MM-dd"));
        }
      } else if (customPeriodRangeType === "months") {
        if (customPeriodType === "previous") {
          var dateBefore = addMonths(date, parseInt(value));
          setStartDateValue(format(dateBefore, "yyyy-MM-dd"));
          setEndDateValue(format(date, "yyyy-MM-dd"));
        } else if (customPeriodType === "next") {
          var dateAfter = addMonths(date, parseInt(value));
          setEndDateValue(format(dateAfter, "yyyy-MM-dd"));
          setStartDateValue(format(date, "yyyy-MM-dd"));
        }
      }
      setCustomModalCancel(false);
      var arr = {};
      arr.customStartDate = null;
      arr.customEndDate = null;
      arr.customPeriodType = customPeriodType;
      arr.customPeriodValue = customPeriodValue;
      arr.customPeriodRangeType = customPeriodRangeType;
      setDefaultValuesArr(arr);
      setSelectedTimelineValue("custom");
      onClose();
    }
  };

  const closeTimelineModal = () => {
    setCustomModalCancel(true);
    onClose();
  };

  //Apply FUnction Ends Here
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      slotProps={{
        backdrop: { onClick: null },
        // root: { height: "auto", overflowY: "visible" },
      }}
      sx={{ height: "auto", overflowY: "auto" }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "25%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "600px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          pt: 1,
          height: "auto",
          // overflowY: "auto",
        }}
      >
        <h3
          style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
        >
          Custom Timeline
          <span>
            <Close onClick={closeTimelineModal} style={{ float: "right" }} />
          </span>
        </h3>
        <hr
          style={{
            width: "530px",
            opacity: "0.3",
            marginTop: "-8px",
            // marginBottom: "-4px",
          }}
        />
        <Stack direction="column" sx={{ marginTop: "23px" }} spacing={2}>
          {!showDateFields ? (
            <Stack direction="row" spacing={2}>
              <FormControl
                className="practiceNameClass"
                required
                color="orangeTheme"
                sx={{
                  maxWidth: "180px",
                  minWidth: "160px",
                  backgroundColor: "#fff",
                  borderRadius: "6px",
                }}
                size="small"
              >
                <InputLabel id="role-type-select-small-label">
                  Previous/Next
                </InputLabel>

                <Select
                  required
                  value={customPeriodType}
                  className="roleNameDropdown"
                  labelId="role-name-select-small-label"
                  id="role-name-select-small"
                  onChange={(e) => handleCustomPeriodTypeChange(e)}
                  label="Previous/Next"
                >
                  <MenuItem key={1} value={"next"}>
                    Next
                  </MenuItem>
                  <MenuItem key={2} value={"previous"}>
                    Previous
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                required
                label="Range"
                value={customPeriodValue}
                id="outlined-size-small"
                onChange={handleCustomPeriodValueChange}
                helperText={customPeriodValueError}
                error={!!customPeriodValueError}
                style={{
                  width: "180px",
                  height: "39px",
                  borderRadius: "6px",
                  backgroundColor: "#fff",
                }}
                color="orangeTheme"
                size="small"
              />
              <FormControl
                className="practiceNameClass"
                required
                color="orangeTheme"
                sx={{
                  maxWidth: "180px",
                  minWidth: "160px",
                  backgroundColor: "#fff",
                  borderRadius: "6px",
                }}
                //disabled={isLoadingRoleName}
                size="small"
                // error={!!roleNamseErrorText}
              >
                <InputLabel id="role-type-select-small-label">
                  Select Type
                </InputLabel>

                <Select
                  required
                  value={customPeriodRangeType}
                  //defaultValue={"weeks"}
                  className="roleNameDropdown"
                  labelId="role-name-select-small-label"
                  id="role-name-select-small"
                  onChange={(e) => handleCustomPeriodRangeTypeChange(e)}
                  label="Select Type"
                >
                  <MenuItem key={1} value={"days"}>
                    Days
                  </MenuItem>
                  <MenuItem key={2} value={"weeks"}>
                    Weeks
                  </MenuItem>
                  <MenuItem key={3} value={"months"}>
                    Months
                  </MenuItem>
                </Select>
              </FormControl>
            </Stack>
          ) : (
            <Stack direction="row" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormControl
                  className="practiceNameClass"
                  required
                  color="orangeTheme"
                  sx={{
                    maxWidth: "180px",
                    minWidth: "160px",
                    backgroundColor: "#fff",
                    borderRadius: "6px",
                  }}
                  size="small"
                >
                  <DatePicker
                    label="Start Date"
                    value={customStartDate}
                    color="orangeTheme"
                    slotProps={{
                      textField: {
                        className: "dateFieldClass",
                        color: "orangeTheme",
                        error: !!customStartDateError,
                      },
                    }}
                    minDate={addDays(new Date(1753, 0, 0), 1)}
                    onChange={handleCustomStartDateChange}
                  />
                  <FormHelperText style={{ height: "0px" }}>
                    {customStartDateError}
                  </FormHelperText>
                </FormControl>
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormControl
                  className="practiceNameClass"
                  required
                  color="orangeTheme"
                  sx={{
                    maxWidth: "180px",
                    minWidth: "160px",
                    backgroundColor: "#fff",
                    borderRadius: "6px",
                  }}
                  size="small"
                >
                  <DatePicker
                    label="End Date"
                    value={customEndDate}
                    disabled={!customStartDate ? true : false}
                    minDate={customStartDate ? customStartDate : null}
                    color="orangeTheme"
                    slotProps={{
                      textField: {
                        className: "dateFieldClass",
                        color: "orangeTheme",
                        error: !!customEndDateError,
                      },
                    }}
                    onChange={handleCustomEndDateChange}
                  />
                  <FormHelperText style={{ height: "0px" }}>
                    {customEndDateError}
                  </FormHelperText>
                </FormControl>
              </LocalizationProvider>
            </Stack>
          )}
          <Stack direction="row">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    onChange={handleChange}
                    checked={showDateFields ? true : false}
                    color="orangeTheme"
                  />
                }
                label="Set Custom Date Range"
              />
            </FormGroup>
          </Stack>
          <hr
            style={{
              width: "530px",
              opacity: "0.3",
              marginTop: "10px",
              // marginBottom: "-4px",
            }}
          />
          <Stack direction="row" spacing={2} style={{ marginLeft: "auto" }}>
            <Button
              style={{
                textTransform: "none",
                borderColor: "black",
                color: "black",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
              }}
              variant="outlined"
              onClick={closeTimelineModal}
            >
              Cancel
            </Button>
            <Button
              style={{
                textTransform: "none",
                color: "white",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
              }}
              variant="contained"
              color="orangeTheme"
              disableElevation
              onClick={handleApplyFilter}
            >
              Apply
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default MedicalAdherenceTimelineModal;
