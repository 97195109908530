export const checkUserToolAccess = (toolID) => {
  var userAccess = {
    hasAccess: false,
    isEditAccess: false,
    isGlobalAdmin: false,
    isPracticeAdmin: false,
    isLearningUser: false,
  };

  var globalAdmin = localStorage.getItem("globalAdmin");
  var practiceAdmin = localStorage.getItem("practiceAdmin");
  var learningUser = localStorage.getItem("learningUser");

  if (parseInt(globalAdmin) === 1) {
    userAccess.hasAccess = true;
    userAccess.isEditAccess = true;
    userAccess.isGlobalAdmin = true;
    return userAccess;
  } else {
    if (parseInt(practiceAdmin) === 1) {
      userAccess.isPracticeAdmin = true;
      userAccess.hasAccess = true;
      userAccess.isEditAccess = true;
      return userAccess;
    }
    if (parseInt(learningUser) === 1) {
      userAccess.isLearningUser = true;
      userAccess.hasAccess = false;
      userAccess.isEditAccess = false;
      return userAccess;
    }
    var viewTools, editTools;
    if (JSON.parse(localStorage.getItem("userAccess")).viewTools) {
      viewTools = JSON.parse(localStorage.getItem("userAccess"))
        .viewTools.split(",")
        .map((item) => parseInt(item));
    }

    if (JSON.parse(localStorage.getItem("userAccess")).editTools) {
      editTools = JSON.parse(localStorage.getItem("userAccess"))
        .editTools.split(",")
        .map((item) => parseInt(item));
    }

    if (!viewTools && !editTools) {
      return userAccess;
    }
    if (!editTools && viewTools) {
      if (viewTools.indexOf(toolID) <= -1) {
        return userAccess;
      } else {
        userAccess.hasAccess = true;
        return userAccess;
      }
    }
    if (!viewTools && editTools) {
      if (editTools.indexOf(toolID) <= -1) {
        return userAccess;
      } else {
        userAccess.hasAccess = true;
        userAccess.isEditAccess = true;
        return userAccess;
      }
    }
    if (viewTools && editTools) {
      if (editTools.indexOf(toolID) <= -1 && viewTools.indexOf(toolID) <= -1) {
        return userAccess;
      } else if (editTools.indexOf(toolID) >= 0) {
        userAccess.hasAccess = true;
        userAccess.isEditAccess = true;
        return userAccess;
      } else {
        userAccess.hasAccess = true;
        return userAccess;
      }
    }
  }
};

export const checkUserScorecardAccess = (scorecardID) => {
  var userAccess = {
    hasAccess: false,
    isGlobalAdmin: false,
    isPracticeAdmin: false,
    isLearningUser: false,
  };
  var globalAdmin = localStorage.getItem("globalAdmin");
  var practiceAdmin = localStorage.getItem("practiceAdmin");
  var learningUser = localStorage.getItem("learningUser");

  if (parseInt(globalAdmin) === 1) {
    userAccess.hasAccess = true;
    userAccess.isGlobalAdmin = true;
    return userAccess;
  } else {
    if (parseInt(practiceAdmin) === 1) {
      userAccess.isPracticeAdmin = true;
      userAccess.hasAccess = true;
      return userAccess;
    }
    if (parseInt(learningUser) === 1) {
      userAccess.isLearningUser = true;
      userAccess.hasAccess = false;
      userAccess.isEditAccess = false;
      return userAccess;
    }
    if (JSON.parse(localStorage.getItem("userAccess")).viewScoreCard) {
      var viewScorecards = JSON.parse(localStorage.getItem("userAccess"))
        .viewScoreCard.split(",")
        .map((item) => parseInt(item));
      if (!viewScorecards) {
        userAccess.hasAccess = false;
        return userAccess;
      }
      if (viewScorecards && viewScorecards.indexOf(scorecardID) <= -1) {
        userAccess.hasAccess = false;
        return userAccess;
      } else if (viewScorecards && viewScorecards.indexOf(scorecardID) >= 0) {
        userAccess.hasAccess = true;
        return userAccess;
      }
    } else {
      userAccess.hasAccess = false;
      return userAccess;
    }
  }
};
