import React from "react";
import PageHeader from "../../../component/common/header/PageHeader";

const SDOHMemberDetailsHeader = ({source=undefined}) => {
    const sevenPillarTitle = "Consumer Community Services";
    return (
        <>
            <PageHeader sevenPillarTitle={sevenPillarTitle}>
            </PageHeader>
        </>
    );
}

export default SDOHMemberDetailsHeader;