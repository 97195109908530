import React, { useEffect, useState, useCallback } from "react";
import {
    Box, Grid, Stack, Typography, Chip, Link, Divider, Button, FormControl,
    FormLabel, Accordion, AccordionSummary, AccordionDetails
} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useAppDispatch } from "../../app/store";
import { COLOR, Styles } from "../../Styles";
import { PagesPath } from "../../PagesPath";
import back_arrow from "../../assets/back_arrow.svg";
import agent from "../../app/api/Agent";
import SDOHMemberDetailsHeader from "./headers/SDOHMemberDetailsHeader";
import { getMemberDetails, getCheckList } from "./slices/SDOHMembersListSlice";
import Loader from "../../component/common/loader/Loader";
import "./SDOHAssessment.css";
import { checkUserToolAccess } from "../../component/common/userAuthorization/checkUserAccess";
import NoAccessPage from "../../component/common/noAccess/NoAccessPage";
import Download_Icon from "../../assets/Download_Icon.svg";
import DownloadIcon from "@mui/icons-material/Download";
import cinq_1 from "../../assets/cinq_1.png";
import PdfGenerator from "./PDFDownload/PDFGenerator";
const SDOHMemberDetails = ({ member, backToList, source=undefined, handleBack=undefined, 
    ourId=undefined, submittedDate = undefined, memberRiskScore = undefined, practiceName = undefined, providerName = undefined }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const AssessmentDashboardID = 57;
    const [accessObj] = useState(checkUserToolAccess(AssessmentDashboardID));
    const [memberDetail, setMemberDetail] = useState([]);
    const [assessmentQuestionsPreview, setAssessmentQuestionsPreview] = useState([]);
    const [sdohURL, setSDOHURL] = useState("");
    const [scoreBackgroundColor, setScoreBackgroundColor] = useState('');  //'#D32F2F'
    const [isLoading, setIsLoading] = useState(false);
    const [statusJson, setStatusJson] = useState([]);
    const [statusOrderJson, setStatusOrderJson] = useState([]);
    const [name, setName] = useState("");

    const [formData, setFormData] = useState({});
    const [showPdfGenerator, setShowPdfGenerator] = useState(false);

    const filter = useSelector(
        (state) => state.filterDetails.filterDetail
    );

    const getCheckListData = useCallback(async () => {
        await dispatch(
            getCheckList()
        );
    }, [dispatch]);

    const getMemberDetailsData = (filter, member) => {
        var submitDate;
        if(submittedDate){
            submitDate = format(new Date(submittedDate),"yyyy-MM-dd");
        }
        else{
            submitDate = member?.submittedDate != undefined ? format(new Date(member?.submittedDate), "yyyy-MM-dd")
            : ""
        }
        setIsLoading(true);
        let redirectURL = 'https://www.findhelp.org/';
        agent.SDOHAssessmentTrackingAgent.getMemberDetails(filter.marketName, filter.subMarketName,
            filter.practiceName, (ourId)?ourId:member.ourId, 
            submitDate
        )
            .then((response) => {
                if (response && response.memberId != undefined) {
                    setMemberDetail(response);
                    let infoJ = {};
                    let infoArr = [];
                    let highObj = [];
                    let mediumObj = [];
                    let lowObj = [];
                    let statusOrderObj = [];
                    if (response?.nameCount != undefined) {
                        let HighScore = 0;
                        response?.nameCount.map((status) => {
                            infoJ = {};
                            infoJ["label"] = status.name;
                            infoJ["value"] = status.nameCount;
                            if (HighScore < status.nameCount) {
                                HighScore = status.nameCount;
                            }
                            if (status?.name === 'Living situation' || status?.name === 'Living Situation') {
                                if (response?.zipCode != undefined) {
                                    infoJ["redirectURL"] = redirectURL + 'search/text?term=housing&postal=' + response?.zipCode + '&language=en';
                                }
                                if (status?.nameCount == 0) {
                                    infoJ["backgroundColor"] = "#59A96A0A";
                                    infoJ["borderColor"] = "#59A96A";  //border-bottom: 3px solid #D32F2F
                                    infoJ["hoverColor"] = "rgba(89, 169, 106, 0.2)"; //background: rgba(89, 169, 106, 0.2);
                                    lowObj.push(infoJ);
                                }
                                else if (status?.nameCount == 1) {
                                    infoJ["backgroundColor"] = "#FFFBF7";
                                    infoJ["borderColor"] = "#FFCD3D";
                                    infoJ["hoverColor"] = "rgba(255, 205, 61, 0.2)";
                                    mediumObj.push(infoJ);
                                }
                                else {
                                    infoJ["backgroundColor"] = "#FDF7F7";
                                    infoJ["borderColor"] = "#D32F2F";
                                    infoJ["hoverColor"] = "rgba(211, 47, 47, 0.2)";
                                    highObj.push(infoJ);
                                }
                            }
                            if (status?.name === 'Food') {
                                if (response?.zipCode != undefined) {
                                    infoJ["redirectURL"] = redirectURL + 'search/text?term=food&postal=' + response?.zipCode + '&language=en';
                                }
                                if (status?.nameCount == 0) {
                                    infoJ["backgroundColor"] = "#59A96A0A";
                                    infoJ["borderColor"] = "#59A96A";  //border-bottom: 3px solid #D32F2F
                                    infoJ["hoverColor"] = "rgba(89, 169, 106, 0.2)";
                                    lowObj.push(infoJ);
                                }
                                else if (status?.nameCount >= 1 && status?.nameCount <= 2) {
                                    infoJ["backgroundColor"] = "#FFFBF7";
                                    infoJ["borderColor"] = "#FFCD3D";
                                    infoJ["hoverColor"] = "rgba(255, 205, 61, 0.2)";
                                    mediumObj.push(infoJ);
                                }
                                else {
                                    infoJ["backgroundColor"] = "#FDF7F7";
                                    infoJ["borderColor"] = "#D32F2F";
                                    infoJ["hoverColor"] = "rgba(211, 47, 47, 0.2)";
                                    highObj.push(infoJ);
                                }
                            }
                            if (status?.name === 'Transportation') {
                                if (response?.zipCode != undefined) {
                                    infoJ["redirectURL"] = redirectURL + 'search/text?term=transit&postal=' + response?.zipCode + '&language=en';
                                }
                                if (status?.nameCount == 0) {
                                    infoJ["backgroundColor"] = "#59A96A0A";
                                    infoJ["borderColor"] = "#59A96A";  //border-bottom: 3px solid #D32F2F
                                    infoJ["hoverColor"] = "rgba(89, 169, 106, 0.2)";
                                    lowObj.push(infoJ);
                                }
                                else {
                                    infoJ["backgroundColor"] = "#FDF7F7";
                                    infoJ["borderColor"] = "#D32F2F";
                                    infoJ["hoverColor"] = "rgba(211, 47, 47, 0.2)";
                                    highObj.push(infoJ);
                                }
                            }
                            if (status?.name === 'Utilities') {
                                if (response?.zipCode != undefined) {
                                    infoJ["redirectURL"] = redirectURL + 'search/text?term=help-pay-for-utilities&postal=' + response?.zipCode + '&language=en';
                                }
                                if (status?.nameCount == 0) {
                                    infoJ["backgroundColor"] = "#59A96A0A";
                                    infoJ["borderColor"] = "#59A96A";  //border-bottom: 3px solid #D32F2F
                                    infoJ["hoverColor"] = "rgba(89, 169, 106, 0.2)";
                                    lowObj.push(infoJ);
                                }
                                else if (status?.nameCount == 1) {
                                    infoJ["backgroundColor"] = "#FFFBF7";
                                    infoJ["borderColor"] = "#FFCD3D";
                                    infoJ["hoverColor"] = "rgba(255, 205, 61, 0.2)";
                                    mediumObj.push(infoJ);
                                }
                                else {
                                    infoJ["backgroundColor"] = "#FDF7F7";
                                    infoJ["borderColor"] = "#D32F2F";
                                    infoJ["hoverColor"] = "rgba(211, 47, 47, 0.2)";
                                    highObj.push(infoJ);
                                }
                            }
                            if (status?.name === 'Safety') {
                                if (response?.zipCode != undefined) {
                                    infoJ["redirectURL"] = redirectURL + 'search/text?term=/personal-safety&postal=' + response?.zipCode + '&language=en';
                                }
                                if (status?.nameCount <= 6) {
                                    infoJ["backgroundColor"] = "#59A96A0A";
                                    infoJ["borderColor"] = "#59A96A";  //border-bottom: 3px solid #D32F2F
                                    infoJ["hoverColor"] = "rgba(89, 169, 106, 0.2)";
                                    lowObj.push(infoJ);
                                }
                                else if (status?.nameCount > 6 && status?.nameCount <= 10) {
                                    infoJ["backgroundColor"] = "#FFFBF7";
                                    infoJ["borderColor"] = "#FFCD3D";
                                    infoJ["hoverColor"] = "rgba(255, 205, 61, 0.2)";
                                    mediumObj.push(infoJ);
                                }
                                else {
                                    infoJ["backgroundColor"] = "#FDF7F7";
                                    infoJ["borderColor"] = "#D32F2F";
                                    infoJ["hoverColor"] = "rgba(211, 47, 47, 0.2)";
                                    highObj.push(infoJ);
                                }
                            }

                            infoArr.push(infoJ);
                            setStatusJson(infoArr);
                        });
                    }

                    if (response?.assesssment != undefined) {
                        setAssessmentQuestionsPreview(response?.assesssment)
                    }
                    let name = '';
                    let sCount = 1;
                    if (highObj.length > 0) {
                        highObj.map((high) => {
                            statusOrderObj.push(high);
                            if (high.value > 0 & sCount <= 3) {
                                sCount += 1;
                                if (name == '')
                                    name = high.label;
                                else
                                    name = name + ', ' + high.label;
                            }
                        });
                    }
                    if (mediumObj.length > 0) {
                        mediumObj.map((medium) => {
                            statusOrderObj.push(medium);
                            if (medium.value > 0 & sCount <= 3) {
                                sCount += 1;
                                if (name == '')
                                    name = medium.label;
                                else
                                    name = name + ', ' + medium.label;
                            }
                        });
                    }
                    if (lowObj.length > 0) {
                        lowObj.map((low) => {
                            statusOrderObj.push(low);
                            if (low.value > 0 & sCount <= 3) {
                                sCount += 1;
                                if (name == '')
                                    name = low.label;
                                else
                                    name = name + ', ' + low.label;
                            }
                        });
                    }
                    setName(name);

                    setStatusOrderJson(statusOrderObj);
                    if (member) {
                        if (member?.memberRiskScore != undefined) {
                            if (member?.memberRiskScore === "High") {
                                setScoreBackgroundColor("#D32F2F");
                            }
                            else if (member?.memberRiskScore === "Medium") {
                                setScoreBackgroundColor("#FFCD3D");//#fb8c00
                            }
                            else
                                setScoreBackgroundColor("#59A96A");
                        }
                    }
                    else if (source === "activeGapClosure"){
                        if (memberRiskScore === "High") {
                            setScoreBackgroundColor("#D32F2F");
                        }
                        else if (memberRiskScore === "Medium") {
                            setScoreBackgroundColor("#FFCD3D");//#fb8c00
                        }
                        else
                            setScoreBackgroundColor("#59A96A");
                    }
                }

                setIsLoading(false);
            })
            .catch((err) => {
                console.log("exc-getMemberDetailsData", err);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getMemberDetailsData(filter, member);
        getCheckListData();
    }, []);

    const openDashboardPage = () => {
        var idUrl = PagesPath.SDOHAssessmentDashboard_URL;
        navigate(idUrl, { searchValue: "" });
    };

    const infoJSON = [{
        Value: 4,
        Label: "Living Situation",
        // color:"#FFCD3D",
        // color:"#D32F2F",
        backgroundColor: "rgba(253, 247, 247, 1)",
        borderColor: "rgba(211, 47, 47, 1)"
    },
    {
        Value: 2,
        Label: "Food",
        backgroundColor: "rgba(255, 251, 247, 1)",
        borderColor: "rgba(255, 205, 61, 1)",
        color: "#FFFBF7",
        // color:"#D32F2F"
    },
    {
        Value: 1,
        Label: "Safety",
        color: "#D32F2F",
        backgroundColor: "rgba(253, 247, 247, 1)",
        borderColor: "rgba(211, 47, 47, 1)"
    },
    {
        Value: 3,
        Label: "Transportation",
        color: "#D32F2F",
        backgroundColor: "rgba(255, 251, 247, 1)",
        borderColor: "rgba(255, 205, 61, 1)",
        color: "#FFFBF7",
    },
    {
        Value: 2,
        Label: "Utilities",
        color: "#D32F2F",
        backgroundColor: "rgba(253, 247, 247, 1)",
        borderColor: "rgba(211, 47, 47, 1)"
    }];

    const TypographyLabel = ({ children }) => {
        return (
            <Typography
                sx={{
                    color: "#000",
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    lineHeight: "normal",
                }}
            >
                {children}
            </Typography>
        );
    };

    const TypographyDetail = ({ children }) => {
        return (
            <Typography
                sx={{
                    color: "#000",
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "300",
                    lineHeight: "normal",
                }}
            >
                {children}
            </Typography>
        );
    };
    const handleDownload = (e) => {
        
        const form = {
            memberId:(source&&source==="activeGapClosure")?ourId:member?.ourId,
            memberName: memberDetail?.memberName,
            dob: memberDetail?.dob,
            address: memberDetail?.address,
            practiceName: (source&&source==="activeGapClosure")?practiceName:member?.practiceName,
            gender: memberDetail.gender,
            phone: memberDetail.phone,
            providerName: (source&&source==="activeGapClosure")?providerName:member.providerName,
            ethinicity: memberDetail?.ethinicity,
            race: memberDetail?.race,
            language: memberDetail?.language,
            email: memberDetail.email,
            memberRiskScore: (source&&source==="activeGapClosure")?memberRiskScore:member?.memberRiskScore,
            scoreBackgroundColor: scoreBackgroundColor,
            name: name,//member?.name,
            statusOrderJson: statusOrderJson,
            submittedDate: (source&&source==="activeGapClosure")?submittedDate:member?.submittedDate,
            assessmentQuestionsPreview: assessmentQuestionsPreview
        }
        
        
        setFormData(form);
        setShowPdfGenerator(true);
    }
    return (
        <Box >
           {(!source || source!== "activeGapClosure") && (<SDOHMemberDetailsHeader />)}
            {!accessObj.hasAccess ? (
                <NoAccessPage></NoAccessPage>
            ) : (
                <>
                    {
                        isLoading ? (
                            <Loader isLoading={isLoading} />
                        ) : (
                            <>
                                <Grid container >
                                    {/* <Grid item xs={12}>
                                        <Link
                                            onClick={backToList} //() => openDashboardPage()
                                            key="SDOHMemberDetail"
                                            state={{ searchNameValue: "" }}
                                            style={{
                                                textDecoration: "none",
                                                "&:hover": {
                                                    color: COLOR.ORANGE,
                                                },
                                            }}
                                        >
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                sx={{
                                                    alignContent: "center",
                                                    alignItems: "center",
                                                    cursor: "pointer"
                                                }}
                                            >
                                                <img src={back_arrow} alt="" />
                                                <Typography
                                                    sx={{
                                                        textAlign: "left",
                                                        letterSpacing: "1px",
                                                        color: "#000",
                                                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                    }}
                                                >
                                                    SDOH Assessment
                                                </Typography>
                                            </Stack>
                                        </Link> 
                                    </Grid>*/}
                                    {(!source || source!== "activeGapClosure") && 
                                      (<Grid item xs={6} md={6} justifyContent="flex-end">
                                        <Stack direction="row" spacing={0.5} sx={{ alignItems: "center" }}>
                                            <Link
                                                onClick={backToList} //() => openDashboardPage()
                                                key="SDOHMemberDetail"
                                                state={{ searchNameValue: "" }}
                                                style={{
                                                    textDecoration: "none",
                                                    "&:hover": {
                                                        color: COLOR.ORANGE,
                                                    },
                                                }}
                                            >
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                    sx={{
                                                        alignContent: "center",
                                                        alignItems: "center",
                                                        cursor: "pointer"
                                                    }}
                                                >
                                                    <img src={back_arrow} alt="" />
                                                    <Typography
                                                        sx={{
                                                            textAlign: "left",
                                                            letterSpacing: "1px",
                                                            color: "#000",
                                                            fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                        }}
                                                    >
                                                        SDOH Assessment
                                                    </Typography>
                                                </Stack>
                                            </Link>
                                        </Stack>

                                    </Grid>)}
                                    {(!source || source !== "activeGapClosure") && (<Grid item xs={6} md={6} justifyContent="flex-end">
                                        <Stack direction="row" spacing={2} sx={{ justifyContent: "end" }}>
                                            <Button
                                                disabled={!accessObj || !accessObj.isEditAccess}
                                                sx={{
                                                    textTransform: "none",
                                                    color: "#DC4F34",
                                                    border: "1px solid",
                                                    borderColor: COLOR.ORANGE,
                                                    textAlign: "center",
                                                    leadingTrim: "both",
                                                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                    fontSize: "12px",
                                                    fontStyle: Styles.FONT_STYLE_NORMAL,
                                                    fontWeight: "500",
                                                    lineHeight: "22px",
                                                    "&.MuiButton-root:hover": {
                                                        borderColor: COLOR.ORANGE,
                                                        border: "1px solid #F37021",
                                                        //   borderRadius: "4px",
                                                    },
                                                }}
                                                variant="outlined"
                                                color="orangeTheme"
                                                onClick={() => handleDownload()}
                                            >
                                                {/* <DownloadIcon style={{ paddingTop: "5px", marginTop: "-4px" }} /> */}
                                                <img src={Download_Icon} alt="" />&nbsp;
                                                Download SDOH PDF
                                            </Button>
                                        </Stack>
                                    </Grid>)}
                                    {(source && source === "activeGapClosure") && (<>
                                        <Grid item xs={6} md={6} justifyContent="flex-end"></Grid>
                                        <Grid item xs={6} md={6} justifyContent="flex-end">
                                        <Stack direction="row" spacing={2} sx={{ justifyContent: "end" }}>
                                            <Button
                                                disabled={!accessObj || !accessObj.isEditAccess}
                                                sx={{
                                                    textTransform: "none",
                                                    color: "#DC4F34",
                                                    border: "1px solid",
                                                    borderColor: COLOR.ORANGE,
                                                    textAlign: "center",
                                                    leadingTrim: "both",
                                                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                    fontSize: "12px",
                                                    fontStyle: Styles.FONT_STYLE_NORMAL,
                                                    fontWeight: "500",
                                                    lineHeight: "22px",
                                                    "&.MuiButton-root:hover": {
                                                        borderColor: COLOR.ORANGE,
                                                        border: "1px solid #F37021",
                                                        //   borderRadius: "4px",
                                                    },
                                                }}
                                                variant="outlined"
                                                color="orangeTheme"
                                                onClick={() => handleDownload()}
                                            >
                                                {/* <DownloadIcon style={{ paddingTop: "5px", marginTop: "-4px" }} /> */}
                                                <img src={Download_Icon} alt="" />&nbsp;
                                                Download SDOH PDF
                                            </Button>
                                        </Stack>
                                    </Grid>
                                    </>)}
                                </Grid>
                                <Grid container sx={{ marginTop: 2 }}>
                                    <Grid item xs={12}
                                        sx={{
                                            minHeight: "100%",
                                            marginLeft: "1%",
                                            boxShadow: "0px 3px 46px 0px rgba(0, 0, 0, 0.12)",
                                            border: "1px solid rgba(0,0,0,.12)",
                                            backgroundColor: "white",
                                        }}>

                                        <Box sx={{ p: { xs: 2, sm: 2 } }}
                                        >
                                            <h3
                                                style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
                                            >
                                                Member Details
                                            </h3>

                                            <Stack spacing={2} sx={{ marginTop: "20px" }}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs>
                                                        <Typography sx={{ fontSize: "14px" }}>
                                                            <b>Name :</b> {memberDetail.memberName}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={3}>
                                                        <Typography sx={{ fontSize: "14px" }}>
                                                            <b>DOB :</b> {memberDetail?.dob
                                                                ? format(new Date(memberDetail?.dob), "dd MMM yyyy")
                                                                : ""}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                    <Typography sx={{ fontSize: "14px" }}>
                                                            <b>Gender :</b> {memberDetail.gender}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                    <Typography sx={{ fontSize: "14px" }}>
                                                            <b>Phone :</b> {memberDetail.phone}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={7}>
                                                        <Typography sx={{ fontSize: "14px" }}>
                                                            <b>Address :</b> {memberDetail.address}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Typography sx={{ fontSize: "14px" }}>
                                                            <b>Email :</b> {memberDetail.email}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={7}>
                                                        <Typography sx={{ fontSize: "14px" }}>
                                                            <b>Practice Name :</b> {(source&&source==="activeGapClosure")?
                                                            practiceName:member.practiceName}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Typography sx={{ fontSize: "14px" }}>
                                                            <b>Provider Name :</b> {(source&&source==="activeGapClosure")?
                                                            providerName:member.providerName}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid container spacing={2}>
                                                <Grid item xs={3}>
                                                        <Typography sx={{ fontSize: "14px" }}>
                                                            <b>Language : </b>
                                                            {memberDetail.language}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <Typography sx={{ fontSize: "14px" }}>
                                                            <b>Ethnicity : </b>
                                                            {memberDetail.ethinicity}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <Typography sx={{ fontSize: "14px" }}>
                                                            <b>Race : </b>
                                                            {memberDetail.race}
                                                        </Typography>
                                                    </Grid>
                                                    
                                                    {/*<Grid item xs={4.5}>
                                                         <Typography sx={{ fontSize: "14px" }}>
                                                                <b>Provider NPI:</b> {member.providerNPI}
                                                            </Typography> 
                                                    </Grid>*/}
                                                </Grid>

                                            </Stack>
                                        </Box>

                                        <Divider
                                            sx={{
                                                margin: 1.5,
                                                background: "#E0E0E0",
                                                color: "#E0E0E0"
                                            }}
                                            orientation="horizontal"
                                        />
                                        <Box sx={{ paddingLeft: { xs: 2, sm: 2 } }}>

                                            <Grid container>
                                                <Grid item xs={6} md={6} alignContent="center">
                                                    <h3 style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }} >
                                                        Highest SDOH Risk And Areas Of Concern
                                                    </h3>
                                                </Grid>
                                                <Grid item xs={6} md={6} alignContent="center">
                                                    {/* <Stack direction="row" marginRight={1} justifyContent="flex-end">
                                                        <a
                                                            style={{ textDecoration: "none", marginLeft: "-10px !important" }}
                                                            href={sdohURL}
                                                            target="_blank"
                                                        >
                                                            <Stack
                                                                direction="row"
                                                                spacing={2}
                                                                sx={{
                                                                    borderRadius: "4px",
                                                                    border: "1px solid #E95110",
                                                                    background: "#FFF",
                                                                    backgroundColor: "#FFF",
                                                                    p: 1,
                                                                }}
                                                            >
                                                                <Typography
                                                                    sx={{
                                                                        color: "#DC4F34",
                                                                        textAlign: "center",
                                                                        leading: "both",
                                                                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                                        fontSize: "16px",
                                                                        fontStyle: "normal",
                                                                        fontWeight: "500",
                                                                        lineHeight: "22px" ,
                                                                    }}
                                                                >
                                                                    SDOH Help
                                                                </Typography>
                                                            </Stack>
                                                        </a>
                                                    </Stack> */}
                                                </Grid>
                                            </Grid>

                                            <Grid container>
                                                <Grid item xs={12} >
                                                    <Stack spacing={2} sx={{ marginTop: "20px" }}>
                                                        <Stack direction="row" alignItems="baseline" spacing={2}>
                                                            <Stack direction="row" spacing={2} >
                                                                <TypographyLabel>SDOH Risk :</TypographyLabel>
                                                                <TypographyDetail>
                                                                    {(member?.memberRiskScore && scoreBackgroundColor != "")
                                                                        ?
                                                                        <Chip style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, width: "85px", backgroundColor: `${scoreBackgroundColor}`, marginTop: "-7px", color: 'white' }} label={member.memberRiskScore} variant="outlined" />
                                                                        : ""}
                                                                    {
                                                                        (memberRiskScore) ? (
                                                                            <Chip style={{ fontFamily: Styles.FONT_FAMILY_GREYCLIFFREG, width: "85px", backgroundColor: `${scoreBackgroundColor}`, marginTop: "-7px", color: 'white' }} label={memberRiskScore} variant="outlined" />
                                                                        ) : ""
                                                                    }
                                                                </TypographyDetail>
                                                            </Stack>

                                                            <Stack direction="row" spacing={2} marginRight={1}>
                                                                <TypographyLabel>Areas of Concern :</TypographyLabel>
                                                                <TypographyDetail>
                                                                    {name != "" ? name : ((member?.name) ? member?.name.substring(0, 120) : "")}
                                                                </TypographyDetail>
                                                            </Stack>
                                                        </Stack>

                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Box>

                                        <Box sx={{ p: { xs: 2, sm: 2 } }}>
                                            <h3 style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }} >
                                                Section wise Score
                                            </h3>

                                            <Grid container sx={{ marginTop: 2 }} component="div">
                                                {statusOrderJson?.map((row, index) => {
                                                    const { value, label, backgroundColor, borderColor, hoverColor, redirectURL } =
                                                        row;
                                                    return (
                                                        <Grid item xs={2.28} className="statusClass"
                                                            sx={{
                                                                alignContent: "center",
                                                                borderRadius: "4px",
                                                                height: "70px",
                                                                backgroundColor: `${backgroundColor}`,//,//"#FFCD3D",//`${color}`,//{{value>100?'#FFCD3D':'#FFFBF7'}},
                                                                marginRight: "1%",
                                                                borderBottom: `3px solid ${borderColor}`,//"3px solid rgba(255, 205, 61, 1)",
                                                                fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                                '&:hover': {
                                                                    backgroundColor: `${hoverColor}`,
                                                                },
                                                            }}
                                                            key={index}
                                                        >
                                                            <a
                                                                style={{ textDecoration: "none", marginLeft: "-10px !important" }}
                                                                href={redirectURL}
                                                                target="_blank"
                                                            >
                                                                <Stack
                                                                    direction="row"
                                                                    spacing={1.5}
                                                                    sx={{
                                                                        display: "flex",
                                                                        justifyContent: "space-around",
                                                                        alignItems: "center",
                                                                        // marginLeft:"1%"
                                                                    }}
                                                                >
                                                                    <Typography
                                                                        style={{
                                                                            color: "#212121",
                                                                            fontSize: "16px",
                                                                            fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                                            lineHeight: "30px",
                                                                            letterSpacing: "0.15px",
                                                                            fontWeight: "500",
                                                                        }}
                                                                    >
                                                                        {value}
                                                                    </Typography>
                                                                    <Typography
                                                                        style={{
                                                                            color: "#212121",
                                                                            fontSize: "16px",
                                                                            fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                                            lineHeight: "30px",
                                                                            letterSpacing: "0.15px",
                                                                            fontWeight: "500",
                                                                        }}
                                                                    >
                                                                        {label}
                                                                    </Typography>
                                                                    <div className="statusOverlay" >
                                                                        <ChevronRightIcon fontSize="medium" className="statusOverlayIcon"
                                                                            sx={{ display: "flex", color: `${borderColor}`, alignItems: "center" }} />
                                                                    </div>
                                                                </Stack>
                                                            </a>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </Box>

                                        <Box sx={{ p: { xs: 2, sm: 2 } }}>
                                            <Grid container>
                                                <Grid item xs={6} md={6} alignContent="center">
                                                    <h3 style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }} >
                                                        Assessment Results
                                                    </h3>
                                                </Grid>
                                                <Grid item xs={6} md={6} alignContent="center">
                                                    <Stack direction="row" spacing={2} marginRight={1} justifyContent="flex-end">
                                                        <TypographyLabel>Submitted Date :</TypographyLabel>
                                                        <TypographyDetail>
                                                            {member?.submittedDate
                                                                ? format(new Date(member?.submittedDate), "dd MMM yyyy")
                                                                : ""}
                                                                {submittedDate
                                                                ? format(new Date(submittedDate), "dd MMM yyyy")
                                                                : ""}
                                                        </TypographyDetail>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs={12} >
                                                    <div style={{ padding: 10 }}>
                                                        {
                                                            (assessmentQuestionsPreview ? assessmentQuestionsPreview?.map((detail, index) =>
                                                                <Accordion defaultExpanded key={index}>
                                                                    <AccordionSummary
                                                                        expandIcon={<ExpandMoreIcon />}
                                                                        aria-controls="panel1-content"
                                                                        id="panel1-header"
                                                                        sx={{
                                                                            borderBottom: '1px solid rgba(0, 0, 0, .125)',
                                                                            minHeight: '45px !important',
                                                                            '& .Mui-expanded': {
                                                                                margin: '0 !important',
                                                                            },
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            sx={{
                                                                                textAlign: "left",
                                                                                letterSpacing: "1px",
                                                                                color: "#111827B8",
                                                                                fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                                                fontWeight: "500",
                                                                                lineHeight: "17px",
                                                                                fontSize: "14px",
                                                                                paddingRight: 2

                                                                            }}
                                                                        >
                                                                            {detail.name}
                                                                        </Typography>
                                                                    </AccordionSummary>

                                                                    <AccordionDetails >
                                                                        <span>{detail?.assessmentDetails.map((detailAssessment, index) =>

                                                                            <FormControl sx={{ paddingTop: 2, paddingLeft: 2 }}
                                                                                key={index}>
                                                                                <FormLabel id="demo-radio-buttons-group-label">
                                                                                    <Grid container>
                                                                                        <Grid item xs={12}>
                                                                                            <Typography
                                                                                                sx={{
                                                                                                    textAlign: "left",
                                                                                                    letterSpacing: "1px",
                                                                                                    color: "#111827B8",
                                                                                                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                                                                    fontWeight: "500",
                                                                                                    lineHeight: "17px",
                                                                                                    fontSize: "14px",
                                                                                                    paddingTop: 1
                                                                                                }}
                                                                                            >
                                                                                                {detailAssessment.flagDescription}
                                                                                            </Typography>
                                                                                        </Grid>

                                                                                        <Grid item xs={12}>
                                                                                            <Typography
                                                                                                sx={{
                                                                                                    textAlign: "left",
                                                                                                    letterSpacing: "1px",
                                                                                                    color: "#000",
                                                                                                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                                                                                                    fontWeight: "500",
                                                                                                    lineHeight: "17px",
                                                                                                    fontSize: "14px",
                                                                                                    paddingTop: 1.5
                                                                                                }}
                                                                                            >
                                                                                                {detailAssessment.value}
                                                                                            </Typography>
                                                                                        </Grid>
                                                                                    </Grid>
                                                                                </FormLabel>



                                                                            </FormControl>
                                                                        )}</span>
                                                                    </AccordionDetails>
                                                                </Accordion>

                                                            ) : <span />)



                                                        }
                                                    </div>
                                                    {showPdfGenerator && <PdfGenerator style={{ display: 'none' }} logoSrc={cinq_1} titleText={'Hello from Title'} formData={formData} ></PdfGenerator>}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                </>
            )}
        </Box>
    );
}

export default SDOHMemberDetails;