import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/Agent";

const initialState = {
  isLoading: false,
  voluntaryAlignmentMembersList: [],
  voluntaryAlignmentMembersListCount: 0,
  errorMessage: "",
};

export const getVoluntaryAlignmentMembersList = createAsyncThunk(
  "voluntaryAlignmentMembersList",
  async ({ search, sortColumn, sortOrder, page }) => {
    const response =
      await agent.VoluntaryAlignmentAgent.getVoluntaryAlignmentList(
        search,
        sortColumn,
        sortOrder,
        page
      );
    return response;
  }
);

const voluntaryAlignmentMembersListSlice = createSlice({
  name: "voluntaryAlignmentMembersList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getVoluntaryAlignmentMembersList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getVoluntaryAlignmentMembersList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.voluntaryAlignmentMembersList =
          action.payload.voluntaryAlignmentMembers;
        state.voluntaryAlignmentMembersListCount = action.payload.count;
      })
      .addCase(getVoluntaryAlignmentMembersList.rejected, (state, action) => {
        state.isLoading = false;
        state.voluntaryAlignmentMembersList = [];
        state.voluntaryAlignmentMembersListCount = 0;
        state.errorMessage = action.error.message;
      });
  },
});

export default voluntaryAlignmentMembersListSlice.reducer;
