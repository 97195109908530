import { PagesPath } from "../PagesPath";
// import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
// import settings_active from '@mui/icons-material/Settings';
import icon from "../assets/file-document.svg";
import icon_active from "../assets/file-document-orange.svg";

export const HealthHomeTraingDocumentsJSON = {
  id: 2,
  name: "Health Home Training Documents",
  key: "HealthHomeTrainingDocuments",
  iconurl: icon,
  activeiconurl: icon_active,
  defaulturl: PagesPath.HealthHomeTrainingDocuments_URL
};
