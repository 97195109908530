import { axiosRequests } from "../AxiosRequest";

function getUserRoleMappingList() {
  
  return axiosRequests.get("/UserRoleMapping/GetUserRoleMapping");
  // return axiosRequests.get("/Members");
}

export const UserRoleMappingDataAgent = {
    getUserRoleMappingList: getUserRoleMappingList,
};