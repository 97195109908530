import { Search } from "@mui/icons-material";
import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { COLOR } from "../../../Styles";

const Searchbox = ({
  label,
  defaultValue,
  handleChange,
  readOnly,
  sx,
  onClick = null,
  cursor = 'pointer'
}) => {
  return (
    <TextField
      sx={sx}
      InputProps={{
        style: { height: "38px", alignItems: "center" },
        readOnly: readOnly,
        endAdornment: (
          <InputAdornment position="end">
            <Link
              onClick={onClick}
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                cursor: cursor
              }}
            >
              <Search
                fontSize="small"
                sx={{ color: COLOR.ORANGE, position: "inherit" }}
              />
            </Link>
          </InputAdornment>
        ),
      }}
      label={label}
      id="outlined-size-small"
      defaultValue={defaultValue ?? ""}
      onChange={(e) => handleChange(e)}
      onKeyDown={(e) => {
        if (e.key === "Enter") onClick();
      }}
      style={{
        width: "293px",
      }}
      color="orangeTheme"
      size="small"
    />
  );
};

export default Searchbox;
