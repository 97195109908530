import { Button, Chip, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Styles } from "../../Styles";
import DataGridHeader from "../../component/common/datagrid/DataGridHeader";
import DataGridCustom from "../../component/common/datagrid/DataGridCustom";
import MedicalCodingSuspectsStateUpdate from "./medicalCodingSuspectsStateUpdate";
import "./activeGapClosuresListCSS.css";
import { useGridApiRef } from "@mui/x-data-grid";
import NewRecaptureCodeModal from "./NewRecaptureCodeModal";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { format } from "date-fns";
import AuditStatusModal from "./AuditStatusModal";
import { axiosRequests } from "../../app/api/AxiosRequest";
import CodeDetailsModal from "./CodeDetailsModal";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AuditDocumentsOutlined from "../../assets/AuditDocuments.svg";

const MedicalCodingSuspectsGrid = ({
  memberDetail,
  forceUpdate,
  setForceUpdate,
  handleViewDocumentsOpen,
  isLoading,
  payerName = undefined,
  hideExcludeMember = false,
  isAuditor = false,
  auditStatusId = 1,
  priority = undefined,
}) => {
  const [readOnly, setReadOnly] = useState(false);
  const [title, setTitle] = useState("");
  const [rowId, setRowId] = useState(null);
  const [openNewRecaptureCodeModal, setOpenNewRecaptureModal] = useState(false);
  const [
    isMedicalCodingSuspectsModalOpen,
    setIsMedicalCodingSuspectsModalOpen,
  ] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowData, setRowData] = useState({});
  const [auditStatusModalOpen, setAuditStatusModalOpen] = useState(false);
  const [rows, setRows] = useState(memberDetail.medicalCodingSuspects);
  const [codeDetailsModalOpen, setCodeDetailsModalOpen] = useState(false);
  const [codeDetailsRow, setCodeDetailsRow] = useState({});
  const apiRef = useGridApiRef();
  const handleopenMedicalCodingSuspectStateUpdateOpen = (
    measureTitle,
    rowId,
    readOnly
  ) => {
    setTitle(measureTitle);
    setRowId(rowId);
    setReadOnly(readOnly);
    setIsMedicalCodingSuspectsModalOpen(true);
  };
  const handleSelectionChange = (selectionModel) => {
    const selectedIDs = new Set(selectionModel);
    const selectedData = rows.filter((row) => selectedIDs.has(row.id));
    setSelectedRows(selectedData);
  };

  const handleAuditAcceptForRow = (row) => {
    let requestBody = {
      ourId: memberDetail.ourId,
      medicalCodingSuspectId: row.id,
      medicalCodeSuspectDetailId: row.medicalCodeSuspectDetailId,
    };
    axiosRequests
      .post("/ActiveGapClosure/AuditAcceptForMedicalCodingSuspect", requestBody)
      .then((resp) => {
        if (resp) {
          console.log("Success!");
          setForceUpdate(forceUpdate + 1);
        }
      });

    return;
  };
  const handleAuditRejectForRow = (row) => {
    let requestBody = {
      ourId: memberDetail.ourId,
      medicalCodingSuspectId: row.id,
      medicalCodeSuspectDetailId: row.medicalCodeSuspectDetailId,
    };
    axiosRequests
      .post("/ActiveGapClosure/AuditRejectForMedicalCodingSuspect", requestBody)
      .then((resp) => {
        if (resp) {
          console.log("Success!");
          setForceUpdate(forceUpdate + 1);
        }
      });

    return;
  };

  useEffect(() => {
    setRows(memberDetail.medicalCodingSuspects);
  }, [memberDetail]);
  const onClose = () => {
    setIsMedicalCodingSuspectsModalOpen(false);
    setAuditStatusModalOpen(false);
    setOpenNewRecaptureModal(false);
    setCodeDetailsModalOpen(false);
  };
  const handleOpenNewRecaptureCodeModal = () => {
    setOpenNewRecaptureModal(true);
  };

  const handleAuditStatusModalOpen = (row) => {
    setRowData(row);
    setAuditStatusModalOpen(true);
  };
  const isRowSelectable = (params) => {
    return (
      params.row.status === "" ||
      !params.row.status ||
      params.row.status === "-"
    );
  };

  const handleCodeDetailsModalOpen = (row) => {
    setCodeDetailsModalOpen(true);
    setCodeDetailsRow(row);
  };
  const columnsMedicalCodingSuspectsTotalOpen = [
    {
      field: "icD10",
      headerName: "ICD 10 Code",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Code Status",
      flex: 1,
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 1,
    },
    {
      field: "activeConditionDiagnosed",
      headerClassName: "classMultilineHeader",
      headerName: "Active Conditions Diagnosed",
      flex: 1,
    },
    {
      field: "visitDate",
      headerName: "Visit Date",
      flex: 1,
      renderCell: (params) => {
        if (params.row.visitDate) {
          return <span>{format(params.row.visitDate, "MM/dd/yyyy")}</span>;
        }
      },
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        if (params.row.status.toLowerCase() === "accept") {
          return (
            <Tooltip
              onClick={() =>
                handleViewDocumentsOpen(
                  params.row.id,
                  "MedicalCoding",
                  params.row.medicalCodeSuspectDetailId
                )
              }
              style={{ cursor: "pointer" }}
              title={"View Documents"}
            >
              <DescriptionOutlinedIcon color="blackTheme" />
            </Tooltip>
          );
        } else if (params.row.status.toLowerCase() === "reject") {
          return <></>;
        } else {
          if (!selectedRows || selectedRows.length < 2) {
            return (
              <Tooltip
                onClick={() =>
                  handleopenMedicalCodingSuspectStateUpdateOpen(
                    params.row.icD10,
                    params.row.id,
                    params.row.ourId
                  )
                }
                title={"Update Status"}
                style={{ cursor: "pointer" }}
              >
                <EditOutlinedIcon color="blackTheme" />
              </Tooltip>
            );
          }
        }
      },
    },
  ];
  const columnsMedicalCodingSuspectsAuditAccept = [
    {
      field: "icD10",
      headerName: "ICD 10 Code",
      flex: 0.5,
      headerClassName: "classMultilineHeader",
      renderCell: (params) => {
        return (
          <span onClick={() => handleCodeDetailsModalOpen(params.row)}>
            <Typography
              style={{
                textDecoration: "underline",
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              {params.row.icD10}
            </Typography>
          </span>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Code Status",
      flex: 1,
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 1,
    },
    {
      field: "visitDate",
      headerName: "Visit Date",
      flex: 1,
      renderCell: (params) => {
        if (params.row.visitDate) {
          return <span>{format(params.row.visitDate, "MM/dd/yyyy")}</span>;
        }
      },
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        if (isAuditor && auditStatusId === 2) {
          if (params.row.status.toLowerCase() === "accept") {
            return (
              <Tooltip
                onClick={() => handleAuditStatusModalOpen(params.row)}
                title={"Audit Documents"}
              >
                <img
                  src={AuditDocumentsOutlined}
                  style={{ width: "24px", height: "24px", cursor: "pointer" }}
                />
              </Tooltip>
            );
          } else if (params.row.status.toLowerCase() === "reject") {
            return (
              <Stack direction="row" spacing={1}>
                <Button
                  color="orangeTheme"
                  size="small"
                  sx={{ textTransform: "none", color: "#F37021" }}
                  variant="outlined"
                  onClick={() => handleAuditAcceptForRow(params.row)}
                >
                  Accept
                </Button>
                <Button
                  color="blackTheme"
                  size="small"
                  sx={{ textTransform: "none" }}
                  variant="outlined"
                  onClick={() => handleAuditRejectForRow(params.row)}
                >
                  Reject
                </Button>
              </Stack>
            );
          }
        } else {
          if (params.row.status.toLowerCase() === "accept") {
            return (
              <Tooltip
                onClick={() =>
                  handleViewDocumentsOpen(
                    params.row.id,
                    "MedicalCoding",
                    params.row.medicalCodeSuspectDetailId
                  )
                }
                style={{ cursor: "pointer" }}
                title={"View Documents"}
              >
                <DescriptionOutlinedIcon color="blackTheme" />
              </Tooltip>
            );
          } else if (params.row.status.toLowerCase() === "reject") {
            return (
              // <Button
              //   color="blackTheme"
              //   onClick={() =>
              //     handleopenMedicalCodingSuspectStateUpdateOpen(params.row.id,true)
              //   }
              //   sx={{ textTransform: "none" }}
              //   variant="outlined"
              // >
              //   View Details
              // </Button>
              <></>
            );
          } else {
            if (!selectedRows || selectedRows.length < 2) {
              return (
                <Tooltip
                  onClick={() =>
                    handleopenMedicalCodingSuspectStateUpdateOpen(
                      params.row.icD10,
                      params.row.id,
                      params.row.ourId
                    )
                  }
                  title={"Update Status"}
                  style={{ cursor: "pointer" }}
                >
                  <EditOutlinedIcon color="blackTheme" />
                </Tooltip>
              );
            }
          }
        }
      },
    },
  ];
  const columnsMedicalCodingSuspectsAuditPending = [
    {
      field: "icD10",
      headerName: "ICD 10 Code",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <span onClick={() => handleCodeDetailsModalOpen(params.row)}>
            <Typography
              style={{
                textDecoration: "underline",
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              {params.row.icD10}
            </Typography>
          </span>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Code Status",
      flex: 1,
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 1,
    },
    {
      field: "visitDate",
      headerName: "Visit Date",
      flex: 1,
      renderCell: (params) => {
        if (params.row.visitDate) {
          return <span>{format(params.row.visitDate, "MM/dd/yyyy")}</span>;
        }
      },
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        if (isAuditor && auditStatusId === 2) {
          if (params.row.status.toLowerCase() === "accept") {
            return (
              <Tooltip
                onClick={() => handleAuditStatusModalOpen(params.row)}
                title={"Audit Documents"}
              >
                <img
                  src={AuditDocumentsOutlined}
                  style={{ width: "24px", height: "24px", cursor: "pointer" }}
                />
              </Tooltip>
            );
          } else if (params.row.status.toLowerCase() === "reject") {
            return (
              <Stack direction="row" spacing={1}>
                <Button
                  color="orangeTheme"
                  size="small"
                  sx={{ textTransform: "none", color: "#F37021" }}
                  variant="outlined"
                  onClick={() => handleAuditAcceptForRow(params.row)}
                >
                  Accept
                </Button>
                <Button
                  color="blackTheme"
                  size="small"
                  sx={{ textTransform: "none" }}
                  variant="outlined"
                  onClick={() => handleAuditRejectForRow(params.row)}
                >
                  Reject
                </Button>
              </Stack>
            );
          }
        } else {
          if (params.row.status.toLowerCase() === "accept") {
            return (
              <Tooltip
                onClick={() =>
                  handleViewDocumentsOpen(
                    params.row.id,
                    "MedicalCoding",
                    params.row.medicalCodeSuspectDetailId
                  )
                }
                style={{ cursor: "pointer" }}
                title={"View Documents"}
              >
                <DescriptionOutlinedIcon color="blackTheme" />
              </Tooltip>
            );
          } else if (params.row.status.toLowerCase() === "reject") {
            return (
              // <Button
              //   color="blackTheme"
              //   onClick={() =>
              //     handleopenMedicalCodingSuspectStateUpdateOpen(params.row.id,true)
              //   }
              //   sx={{ textTransform: "none" }}
              //   variant="outlined"
              // >
              //   View Details
              // </Button>
              <></>
            );
          } else {
            if (!selectedRows || selectedRows.length < 2) {
              return (
                <Tooltip
                  onClick={() =>
                    handleopenMedicalCodingSuspectStateUpdateOpen(
                      params.row.icD10,
                      params.row.id,
                      params.row.ourId
                    )
                  }
                  title={"Update Status"}
                  style={{ cursor: "pointer" }}
                >
                  <EditOutlinedIcon color="blackTheme" />
                </Tooltip>
              );
            }
          }
        }
      },
    },
  ];
  const columnsMedicalCodingSuspectsAuditReject = [
    {
      field: "icD10",
      headerName: "ICD 10 Code",
      flex: 0.5,
      headerClassName: "classMultilineHeader",
      renderCell: (params) => {
        return (
          <span onClick={() => handleCodeDetailsModalOpen(params.row)}>
            <Typography
              style={{
                textDecoration: "underline",
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              {params.row.icD10}
            </Typography>
          </span>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Code Status",
      headerClassName: "classMultilineHeader",
      flex: 0.5,
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 1,
    },
    {
      field: "visitDate",
      headerName: "Visit Date",
      flex: 0.5,
      renderCell: (params) => {
        if (params.row.visitDate) {
          return <span>{format(params.row.visitDate, "MM/dd/yyyy")}</span>;
        }
      },
    },
    {
      field: "rejectReasons",
      headerName: "Rejection Reason",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        if (params.row.status.toLowerCase() === "accept") {
          return (
            <Stack direction="row" spacing={2}>
              <Tooltip
                onClick={() =>
                  handleViewDocumentsOpen(
                    params.row.id,
                    "MedicalCoding",
                    params.row.medicalCodeSuspectDetailId
                  )
                }
                style={{ cursor: "pointer" }}
                title={"View Documents"}
              >
                <DescriptionOutlinedIcon color="blackTheme" />
              </Tooltip>
              <Tooltip
                onClick={() =>
                  handleopenMedicalCodingSuspectStateUpdateOpen(
                    params.row.icD10,
                    params.row.id,
                    params.row.ourId
                  )
                }
                title={"Update Status"}
                style={{ cursor: "pointer" }}
              >
                <EditOutlinedIcon color="blackTheme" />
              </Tooltip>
            </Stack>
          );
        } else if (params.row.status.toLowerCase() === "reject") {
          return (
            <Tooltip
              onClick={() =>
                handleopenMedicalCodingSuspectStateUpdateOpen(
                  params.row.icD10,
                  params.row.id,
                  params.row.ourId
                )
              }
              title={"Update Status"}
              style={{ cursor: "pointer" }}
            >
              <EditOutlinedIcon color="blackTheme" />
            </Tooltip>
          );
        }
      },
    },
  ];
  return (
    <div style={{ marginTop: "16px", display: "grid", height: 426 }}>
      {
        <DataGridCustom
          columns={
            auditStatusId === 0
              ? columnsMedicalCodingSuspectsTotalOpen
              : auditStatusId === 1
              ? columnsMedicalCodingSuspectsAuditAccept
              : auditStatusId === 2
              ? columnsMedicalCodingSuspectsAuditPending
              : columnsMedicalCodingSuspectsAuditReject
          }
          rows={memberDetail.medicalCodingSuspects}
          pageSize={5}
          apiRef={apiRef}
          className="pafGrid"
          checkboxSelection={true}
          isRowSelectable={isRowSelectable}
          onSelectionModelChanged={(newSelection) => {
            handleSelectionChange(newSelection);
          }}
          //getRowId={getRowId}
          loading={isLoading}
          sortingOrder={["asc", "desc"]}
          slots={{
            toolbar: DataGridHeader,
          }}
          slotProps={{
            toolbar: {
              headerText: "",
              fontSize: "",
              marginTop: "",
              marginBottom: "",
              height: "56px",
              children: (
                <div
                  style={{
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    fontSize: "16px",
                    width: "100%",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginLeft: "5px",
                  }}
                >
                  <Stack direction="row" spacing={1}>
                    <Typography
                      style={{
                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                        fontSize: "16px",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      Recapture Codes
                    </Typography>
                    <Chip
                      color="orangeTheme"
                      variant="contained"
                      style={{ color: "#fff" }}
                      label={memberDetail.medicalCodingSuspects.length}
                    ></Chip>
                    <Stack
                      direction="row"
                      spacing={1}
                      style={{ marginLeft: "auto" }}
                    >
                      {
                        <Button
                          variant="outlined"
                          color="orangeTheme"
                          style={{
                            fontFamily: Styles.FONT_FAMILY_POPPINS,
                            textTransform: "none",
                            float: "right",
                          }}
                          onClick={handleOpenNewRecaptureCodeModal}
                        >
                          <AddOutlinedIcon></AddOutlinedIcon>New ICD Codes
                        </Button>
                      }
                      {selectedRows.length > 1 && (
                        <Button
                          variant="outlined"
                          color="blackTheme"
                          style={{
                            fontFamily: Styles.FONT_FAMILY_POPPINS,
                            float: "right",
                            textTransform: "none",
                          }}
                          onClick={
                            handleopenMedicalCodingSuspectStateUpdateOpen
                          }
                        >
                          Bulk Status Update
                        </Button>
                      )}
                    </Stack>
                  </Stack>
                </div>
              ),
            },
          }}
        />
      }
      {isMedicalCodingSuspectsModalOpen && (
        <MedicalCodingSuspectsStateUpdate
          isOpen={isMedicalCodingSuspectsModalOpen}
          onClose={onClose}
          title={title}
          rowId={rowId}
          rows={selectedRows}
          ourId={memberDetail.ourId}
          forceUpdate={forceUpdate}
          setForceUpdate={setForceUpdate}
          memberDetail={memberDetail}
          payerName={payerName}
        />
      )}
      {openNewRecaptureCodeModal && (
        <NewRecaptureCodeModal
          isOpen={openNewRecaptureCodeModal}
          onClose={onClose}
          memberDetail={memberDetail}
          payerName={payerName}
          forceUpdate={forceUpdate}
          setForceUpdate={setForceUpdate}
          priority={priority}
        ></NewRecaptureCodeModal>
      )}
      {auditStatusModalOpen && (
        <AuditStatusModal
          isOpen={auditStatusModalOpen}
          onClose={onClose}
          rowData={rowData}
          forceUpdate={forceUpdate}
          setForceUpdate={setForceUpdate}
        />
      )}
      {codeDetailsModalOpen && (
        <CodeDetailsModal
          isOpen={codeDetailsModalOpen}
          onClose={onClose}
          rowData={codeDetailsRow}
          source="reacptureGrid"
        />
      )}
    </div>
  );
};

export default MedicalCodingSuspectsGrid;
