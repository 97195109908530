import React from "react";
import DataGridCustom from "../../../component/common/datagrid/DataGridCustom";
import DataGridHeader from "../../../component/common/datagrid/DataGridHeader";
import { Styles } from "../../../Styles";
import { format } from "date-fns";
import { Chip, Stack, styled, Typography } from "@mui/material";
import "./PDFExportCss.css";

const MultilineText = styled(Typography)({
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "normal",
  });

const RecaptureCodesPDF = ({ auditStatusId, memberDetail }) => {
  const columnsMedicalCodingSuspectsTotalOpen = [
    {
      field: "icD10",
      headerName: "ICD 10 Code",
      width: 180,
    },
    {
      field: "description",
      headerName: "Description",
      width: 180,
      renderCell: (params) => {
        return <MultilineText>{params.row.description}</MultilineText>;
      },
    },
    {
      field: "status",
      headerName: "Code Status",
      width: 180,
    },
    {
      field: "comments",
      headerName: "Comments",
      width: 180,
      renderCell: (params) => {
        return <MultilineText>{params.row.comments}</MultilineText>;
      },
    },
    {
      field: "activeConditionDiagnosed",
      headerClassName: "classMultilineHeader",
      headerName: "Active Conditions Diagnosed",
      width: 180,
    },
    {
      field: "visitDate",
      headerName: "Visit Date",
      width: 180,
      renderCell: (params) => {
        if (params.row.visitDate) {
          return <span>{format(params.row.visitDate, "MM/dd/yyyy")}</span>;
        }
      },
    },
  ];
  const columnsMedicalCodingSuspectsAuditAccept = [
    {
      field: "icD10",
      headerName: "ICD 10 Code",
      width: 300,
      
    },
    {
      field: "description",
      headerName: "Description",
      width: 300,
      renderCell: (params) => {
        return <MultilineText>{params.row.description}</MultilineText>;
      },
    },
    {
      field: "status",
      headerName: "Code Status",
      width: 300,
    },
    {
      field: "visitDate",
      headerName: "Visit Date",
      width: 300,
      renderCell: (params) => {
        if (params.row.visitDate) {
          return <span>{format(params.row.visitDate, "MM/dd/yyyy")}</span>;
        }
      },
    },
  ];
  const columnsMedicalCodingSuspectsAuditPending = [
    {
      field: "icD10",
      headerName: "ICD 10 Code",
      width: 300,
    },
    {
      field: "description",
      headerName: "Description",
      width: 300,
      renderCell: (params) => {
        return <MultilineText>{params.row.description}</MultilineText>;
      },
    },
    {
      field: "status",
      headerName: "Code Status",
      width: 300,
    },
    {
      field: "visitDate",
      headerName: "Visit Date",
      width: 300,
      renderCell: (params) => {
        if (params.row.visitDate) {
          return <span>{format(params.row.visitDate, "MM/dd/yyyy")}</span>;
        }
      },
    },
  ];
  const columnsMedicalCodingSuspectsAuditReject = [
    {
      field: "icD10",
      headerName: "ICD 10 Code",
      width: 100,
      headerClassName: "classMultilineHeader",
    },
    {
      field: "description",
      headerName: "Description",
      width: 500,
      renderCell: (params) => {
        return <MultilineText>{params.row.description}</MultilineText>;
      },
    },
    {
      field: "status",
      headerName: "Code Status",
      headerClassName: "classMultilineHeader",
      width: 100,
    },
    {
      field: "visitDate",
      headerName: "Visit Date",
      width: 100,
      renderCell: (params) => {
        if (params.row.visitDate) {
          return <span>{format(params.row.visitDate, "MM/dd/yyyy")}</span>;
        }
      },
    },
    {
      field: "rejectReasons",
      headerName: "Rejection Reason",
      width: 500,
      renderCell: (params) => {
        return <MultilineText>{params.row.rejectReasons}</MultilineText>;
      },
    },
  ];
  return (
    <div style={{ marginTop: "16px", display: "grid" }} className="page-break">
      {
        <DataGridCustom
          columns={
            auditStatusId === 0
              ? columnsMedicalCodingSuspectsTotalOpen
              : auditStatusId === 1
              ? columnsMedicalCodingSuspectsAuditAccept
              : auditStatusId === 2
              ? columnsMedicalCodingSuspectsAuditPending
              : columnsMedicalCodingSuspectsAuditReject
          }
          rows={memberDetail.medicalCodingSuspects}
          pageSize={100}
          hideFooter={true}
          //getRowId={getRowId}
          loading={false}
          sortingOrder={["asc", "desc"]}
          slots={{
            toolbar: DataGridHeader,
          }}
          slotProps={{
            toolbar: {
              headerText: "",
              fontSize: "",
              marginTop: "",
              marginBottom: "",
              height: "56px",
              children: (
                <div
                  style={{
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    fontSize: "16px",
                    width: "100%",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginLeft: "5px",
                  }}
                >
                  <Stack direction="row" spacing={1}>
                    <Typography
                      style={{
                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                        fontSize: "16px",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      Recapture Codes
                    </Typography>
                    <Chip
                      color="orangeTheme"
                      variant="contained"
                      style={{ color: "#fff" }}
                      label={memberDetail.medicalCodingSuspects.length}
                    ></Chip>
                  </Stack>
                </div>
              ),
            },
          }}
        />
      }
    </div>
  );
};

export default RecaptureCodesPDF;
