import agent from "../../../app/api/Agent";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  activeGapCategoryList: [],
  activeGapClosuresList: [],
  activeGapClosuresCount: [],
  activeGapClosuresListCount: 0,
  errorMessage: "",
};

export const getActiveGapClosuresList = createAsyncThunk(
  "activeGapClosuresList",
  async (requestBody) => {
    const response =
      await agent.ActiveGapClosuresAgent.getActiveGapClosuresList(requestBody);
    return response;
  }
);

const activeGapClosuresSlice = createSlice({
  name: "activeGapClosures",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActiveGapClosuresList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getActiveGapClosuresList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.activeGapCategoryList=action.payload.activeGapClosureCategoryDetails;
        state.activeGapClosuresList = action.payload.activeGapClosureDetails;
        state.activeGapClosuresCount = []; //action.payload.medAdhStatusCount;
        state.activeGapClosuresListCount = action.payload.totalRecords;
      })
      .addCase(getActiveGapClosuresList.rejected, (state, action) => {
        state.isLoading = false;
        state.activeGapCategoryList=[];
        state.activeGapClosuresList = [];
        state.activeGapClosuresCount = [];
        state.activeGapClosuresListCount = 0;
        state.errorMessage = action.error.message;
      });
  },
});

export default activeGapClosuresSlice.reducer;
