import React, { useState, useEffect } from "react";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
// import { Box } from "@mui/material";
import {
  Button,
  TextField,
  Modal,
  Box,
  Grid,
  CircularProgress,
  FormHelperText,
  Typography,
  Stack,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  Switch,
} from "@mui/material";
// import DataGridCustom from "../../component/common/datagrid/DataGridCustom";
import Fab from "@mui/material/Fab";
import { Styles } from "../../../Styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import AddUserFormHeader from "../Users/AddUserFormHeader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { PagesPath } from "../../../PagesPath";
import { axiosRequests } from "../../../app/api/AxiosRequest";
import AddUserRoleCSS from "./AddUserRoleCSS.css";

const AddUserRoleForm = ({ setAddUserRolesFormDisplay }) => {
  let { state } = useLocation();
  const [userType, setUserType] = React.useState("1");
  const [roleName, setRoleName] = React.useState("");
  const [roleDescription, setRoleDescription] = React.useState("");
  const [roleNameError, setRoleNameError] = React.useState("");
  const [roleDescriptionError, setRoleDescriptionError] = React.useState("");
  const [roleTypeError, setRoleTypeError] = React.useState("");
  const [roleId, setRoleId] = React.useState("");
  const [isEdit, setIsEdit] = React.useState(false);
  const [initialRoleType, setInitialRoleType] = useState(0);
  const [roleTypeDropdownData, setRoleTypeDropdownData] = useState([]);
  const [selectedRoleType, setSelectedRoleType] = useState(1);
  const [isPracticeAdmin, setIsPracticeAdmin] = useState(false);
  const [defaultRoleTypeData, setDefaultRoleTypeData] = useState([]);
  const [practiceAdminDropdownData, setPracticeAdminDropdownData] = useState(
    []
  );
  const [selectedPracticeAdminError, setSelectedPracticeAdminError] =
    useState("");
  const [selectedPracticeAdmin, setSelectedPracticeAdmin] = useState("");
  const [hasHealthHomeAccess, setHasHealthHomeAccess] = useState(false);
  const [showHealthHomeSwitch, setShowHealthHomeSwitch] = useState(false);
  const alphabetRegex = /^[a-zA-Z ]*$/;
  const navigate = useNavigate();
  const handleRadioChange = (event) => {
    setUserType(event.target.value);
    if (parseInt(event.target.value) === 2) {
      setRoleTypeDropdownData(
        defaultRoleTypeData.filter((item) => parseInt(item.roleTypeId) !== 1)
      );
      setSelectedRoleType(2);
    } else {
      setRoleTypeDropdownData(
        defaultRoleTypeData.filter((item) => parseInt(item.roleTypeId) !== 2)
      );
      setSelectedRoleType(1);
    }
  };

  const handleRoleNameChange = (e) => {
    setRoleName(e.target.value);
    setRoleNameError("");
  };
  const handleRoleDescriptionChange = (e) => {
    setRoleDescription(e.target.value);
    setRoleDescriptionError("");
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("userAccess"))) {
      var practiceAdmin = JSON.parse(
        localStorage.getItem("userAccess")
      ).roleType;
      if (parseInt(practiceAdmin) === 2) {
        setIsPracticeAdmin(true);
        setSelectedRoleType(3);
        setUserType(2);
        setSelectedPracticeAdmin(
          JSON.parse(localStorage.getItem("userAccess")).id
        );
      }
    }
  }, []);

  useEffect(() => {
    axiosRequests
      .get("/RoleManagementDetail/GetPracticeAdminList")
      .then((resp) => {
        if (state && state.formState) {
          var idList = resp.map((item) => item.practiceAdminId);
          if (!idList.includes(state.formState.practiceAdminId))
            setSelectedPracticeAdmin("");
        }
        setPracticeAdminDropdownData(resp);
      });
  }, []);

  useEffect(() => {
    if (state) {
      console.log(state.formState);
      setRoleName(state.formState.roleName);
      setRoleDescription(state.formState.roleDescription);
      setUserType(state.formState.type);
      setIsEdit(true);
      setRoleId(state.formState.roleId);
      setSelectedRoleType(state.formState.roleType);
      setInitialRoleType(parseInt(state.formState.roleType));
      setSelectedPracticeAdmin(state.formState.practiceAdminId);
      setHasHealthHomeAccess(state.formState.hasHealthHomeAccess);
      if (
        parseInt(state.formState.roleType) === 2 ||
        parseInt(state.formState.roleType) === 3
      ) {
        setShowHealthHomeSwitch(true);
      } else setShowHealthHomeSwitch(false);
    }
  }, []);
  useEffect(() => {
    axiosRequests
      .get("/RoleManagementDetail/GetRoleTypeDropdownDetails")
      .then((resp) => {
        setDefaultRoleTypeData(resp);
        if (state && state.formState) {
          setInitialRoleType(parseInt(state.formState.roleType));
          if (parseInt(state.formState.type) === 2) {
            setRoleTypeDropdownData(
              resp.filter((item) => parseInt(item.roleTypeId) !== 1)
            );
            setSelectedRoleType(state.formState.roleType);
          } else {
            setRoleTypeDropdownData(
              resp.filter((item) => parseInt(item.roleTypeId) !== 2)
            );
            setSelectedRoleType(state.formState.roleType);
          }
        } else {
          setRoleTypeDropdownData(
            resp.filter((item) => parseInt(item.roleTypeId) !== 2)
          );
        }
      });
  }, []);

  // useEffect(() => {
  //   if (parseInt(selectedRoleType) === 1) {
  //     setHasHealthHomeAccess(true);
  //   } else {
  //     setHasHealthHomeAccess(false);
  //   }
  //   // if (parseInt(selectedRoleType) === 2 || parseInt(selectedRoleType) === 3) {
  //   //   setShowHealthHomeSwitch(true);
  //   // }
  // }, [selectedRoleType]);

  const handleFormSubmit = () => {
    let hasError = false;

    if (!roleName) {
      setRoleNameError("Role Name is Required");
      hasError = true;
    }
    if (!roleDescription) {
      setRoleDescriptionError("Role Description is Required");
      hasError = true;
    }
    if (!selectedRoleType) {
      setRoleTypeError("Role Type is Required");
      hasError = true;
    }
    if (
      parseInt(userType) === 2 &&
      parseInt(selectedRoleType) === 3 &&
      !selectedPracticeAdmin
    ) {
      setSelectedPracticeAdminError("Practice Admin is Required");
      hasError = true;
    }

    if (hasError) return;

    // if(parseInt(selectedRoleType) === 2) setSelectedPracticeAdmin(0);

    var formData = {
      roleId: roleId,
      roleName: roleName,
      roleDescription: roleDescription,
      type: userType,
      roleType: selectedRoleType,
      hasHealthHomeAccess:
        parseInt(selectedRoleType) === 1 ? true : hasHealthHomeAccess,
      practiceAdminId:
        (parseInt(selectedRoleType) === 3 ||
          parseInt(selectedRoleType) === 4 ||
          parseInt(selectedRoleType) === 5) &&
        parseInt(userType) === 2
          ? selectedPracticeAdmin
          : 0,
    };
    if (isEdit) {
      axiosRequests
        .put("/RoleManagementDetail/UpdateRoleDetails", formData)
        .then((resp) => {
          if (resp === 1) {
            navigate(PagesPath.UserAdminConsoleURL, { state: { value: "2" } });
          }
        });
    } else {
      delete formData["roleId"];
      axiosRequests
        .post("/RoleManagementDetail/AddNewRoleDetails", formData)
        .then((resp) => {
          if (resp === 1) {
            navigate(PagesPath.UserAdminConsoleURL, { state: { value: "2" } });
          }
        });
    }
  };
  const handleRoleTypeChange = (e) => {
    setSelectedRoleType(e.target.value);
    setRoleTypeError("");
    // if(parseInt(e.target.value) === 2 || parseInt(e.target.value) === 3)
    if (parseInt(e.target.value) === 2 || parseInt(e.target.value) === 3) {
      setShowHealthHomeSwitch(true);
    } else {
      setShowHealthHomeSwitch(false);
    }
    //checkIfRoleNameExists(e.target.value);
  };
  const handleHealthHomeAccessChange = (e) => {
    setHasHealthHomeAccess(e.target.checked);
  };
  const handlePracticeAdminChange = (e) => {
    setSelectedPracticeAdmin(e.target.value);
    //setPracticeAdminError('');
    //checkIfRoleNameExists(e.target.value);
  };
  const handleBackClick = () => {
    navigate(PagesPath.UserAdminConsoleURL, { state: { value: "2" } });
  };
  return (
    <Box sx={{ width: "100%", p: { xs: 2, sm: 2 } }}>
      <AddUserFormHeader pageTitle={"User Roles"}></AddUserFormHeader>
      <Stack direction="row" spacing={1}>
        <ArrowBackOutlinedIcon
          onClick={() => handleBackClick()}
          style={{
            fontSize: "25px",
            fontWeight: "600",
            marginTop: "2px",
            width: "32px",
          }}
        />
        <Typography style={{ fontSize: "20px", fontWeight: "600" }}>
          Add User Role
        </Typography>
      </Stack>

      <div
        style={{
          width: "1270px",
          height: "534px",
          background: "rgba(226, 226, 226, 0.30)",
          padding: "16px",
        }}
      >
        <Grid container spacing={2} style={{ marginBottom: "29px" }}>
          <Grid item xs={12}>
            <TextField
              required
              label="Role Title"
              onChange={handleRoleNameChange}
              id="outlined-size-small"
              value={roleName}
              error={!!roleNameError}
              helperText={roleNameError}
              style={{
                width: "705px",
                height: "39px",
                borderRadius: "6px",
                backgroundColor: "#fff",
              }}
              color="orangeTheme"
              size="small"
            />
          </Grid>
        </Grid>
        <Grid style={{ marginBottom: "16px" }}>
          <TextField
            id="outlined-multiline-static"
            label="Role Description"
            value={roleDescription}
            error={!!roleDescriptionError}
            helperText={roleDescriptionError}
            onChange={handleRoleDescriptionChange}
            multiline
            className="roleDescription"
            color="orangeTheme"
            rows={5}
            variant="outlined"
          />
        </Grid>
        <Grid>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="user-type-group-label"
              name="user-type-buttons-group"
              value={userType}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                disabled={isPracticeAdmin}
                // style={{ display: isPracticeAdmin ? "none" : "" }}
                value="1"
                control={<Radio color="orangeTheme" />}
                label="Internal"
              />
              <FormControlLabel
                disabled={isPracticeAdmin}
                value="2"
                control={<Radio color="orangeTheme" />}
                label="External"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid container spacing={2}>
          <Grid item>
            <FormControl
              disabled={isPracticeAdmin && isEdit && initialRoleType === 2}
              // style={{ display: isPracticeAdmin ? "none" : "" }}
              required
              color="orangeTheme"
              sx={{
                maxWidth: "303px",
                minWidth: "303px",
                backgroundColor: "#fff",
                borderRadius: "6px",
              }}
              //disabled={isLoadingRoleName}
              size="small"
              // error={!!roleNameErrorText}
            >
              <InputLabel id="role-type-select-small-label">
                Role Type
              </InputLabel>

              <Select
                required
                value={selectedRoleType}
                labelId="role-type-select-small-label"
                id="role-type-select-small"
                onChange={(e) => handleRoleTypeChange(e)}
                label="Role Type"
              >
                {isPracticeAdmin
                  ? roleTypeDropdownData.map((item) => {
                      if (
                        isEdit &&
                        initialRoleType === 2 &&
                        parseInt(item.roleTypeId) === 2
                      ) {
                        return (
                          <MenuItem
                            className="dropdownText"
                            key={item.roleTypeId}
                            value={item.roleTypeId}
                          >
                            {item.roleType}
                          </MenuItem>
                        );
                      } else if (
                        parseInt(item.roleTypeId) !== 1 &&
                        parseInt(item.roleTypeId) !== 2
                      )
                        return (
                          <MenuItem
                            className="dropdownText"
                            key={item.roleTypeId}
                            value={item.roleTypeId}
                          >
                            {item.roleType}
                          </MenuItem>
                        );
                    })
                  : roleTypeDropdownData.map((item) => (
                      <MenuItem
                        className="dropdownText"
                        key={item.roleTypeId}
                        value={item.roleTypeId}
                      >
                        {item.roleType}
                      </MenuItem>
                    ))}
              </Select>
              <FormHelperText style={{ height: "0px" }}>
                {roleTypeError}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl
              disabled={isPracticeAdmin}
              style={{
                display: !(
                  (parseInt(selectedRoleType) === 3 ||
                    parseInt(selectedRoleType) === 4 ||
                    parseInt(selectedRoleType) === 5) &&
                  parseInt(userType) === 2
                )
                  ? "none"
                  : "",
              }}
              required
              color="orangeTheme"
              sx={{
                maxWidth: "303px",
                minWidth: "303px",
                backgroundColor: "#fff",
                borderRadius: "6px",
              }}
              //disabled={isLoadingRoleName}
              size="small"
              error={!!selectedPracticeAdminError}
            >
              <InputLabel id="role-type-select-small-label">
                Practice Administrator
              </InputLabel>

              <Select
                required
                value={selectedPracticeAdmin}
                labelId="role-type-select-small-label"
                id="role-type-select-small"
                onChange={(e) => handlePracticeAdminChange(e)}
                label="Practice Administrator"
              >
                {practiceAdminDropdownData.map((item) => (
                  <MenuItem
                    className="dropdownText"
                    key={item.practiceAdminId}
                    value={item.practiceAdminId}
                  >
                    {item.practiceAdminName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText style={{ height: "0px" }}>
                {selectedPracticeAdminError}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        {showHealthHomeSwitch && (
          <Grid container spacing={2} style={{ marginTop: "4px" }}>
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={hasHealthHomeAccess}
                      color="orangeTheme"
                      onChange={handleHealthHomeAccessChange}
                      size="small"
                    />
                  }
                  label="Has Health Home Learnings Access?"
                />
              </FormGroup>
            </Grid>
          </Grid>
        )}
      </div>
      <Grid
        container
        spacing={6}
        style={{ marginTop: "-39px", marginLeft: "55px" }}
      >
        <Grid item xs={8}></Grid>
        <Grid item xs={1}>
          <Link
            to={PagesPath.UserAdminConsoleURL}
            key="AddUserRole"
            state={{ value: "2" }}
            style={{
              textDecoration: "none",
              underline: "hover",
              color: "inherit",
            }}
          >
            <Button
              style={{
                borderColor: "black",
                color: "black",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
                textTransform: "none",
              }}
              variant="outlined"
            >
              Cancel
            </Button>
          </Link>
        </Grid>
        <Grid item xs={1}>
          <Button
            style={{
              color: "white",
              fontFamily: Styles.FONT_FAMILY_POPPINS,
              textTransform: "none",
            }}
            variant="contained"
            color="orangeTheme"
            onClick={handleFormSubmit}
            disableElevation
          >
            Save
          </Button>
        </Grid>
      </Grid>
      {/* <Button
                style={{
                    color: "#111827",
                    textTransform: "none",
                }}
                variant="outlined"
                color="orangeTheme"
                onClick={() => setAddUserFormDisplay(false)}
            >
                <AddIcon style={{ paddingTop: "5px", marginTop: "-4px" }} />
                Cancel
            </Button> */}
    </Box>
  );
};

export default AddUserRoleForm;
