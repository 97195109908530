import React from "react";
import PageHeader from "../../component/common/header/PageHeader";

const MembershipAndGrowthTrendsHeader = () => {
  const title = "Membership And Growth Trends";
  const sevenPillarTitle = "Practice VBC Revenue & Growth";
  return (
    
      <PageHeader title={title} sevenPillarTitle={sevenPillarTitle}/>
    
  );
};

export default MembershipAndGrowthTrendsHeader;
