import React from "react";
import PowerBiCustomDashboard from "../../component/common/powerbi/PowerBiCustomDashboard";

const ClaimsReduction = () => {
  return (
    <section id="bi-report">
      <PowerBiCustomDashboard groupId="0bcbb78e-88cf-48a2-990f-f152b3b54c8a" dashboardId="cd19a31f-3149-4958-9c76-8ebda00e7d88" />
    </section>
  );
};

export default ClaimsReduction;
