import { Box, Stack, Typography } from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import SDOHNewAssessmentForm from "../sdohNewAssessmentForm/SDOHNewAssessmentForm";

const SDOHNewAssessment = ({
  backAction,
  ourId,
  forceUpdate,
  setForceUpdate,
}) => {
  const handleSubmit = () => {
    setForceUpdate(forceUpdate + 1);
    console.log("Data submitted");
    backAction();
  };
  const handleAssessmentCancel = () => {
    backAction();
  };
  return (
    <Box sx={{ width: "100%", p: { xs: 2, sm: 2 } }}>
      <Stack direction="column" spacing={0.2}>
        <Stack direction="row" spacing={1}>
          <ArrowBackOutlinedIcon
            onClick={backAction}
            style={{
              fontSize: "25px",
              fontWeight: "600",
              marginTop: "2px",
              width: "32px",
            }}
          />
          <Typography style={{ fontSize: "20px", fontWeight: "300" }}>
            Assessment : Member Details
          </Typography>
        </Stack>
        <SDOHNewAssessmentForm
          source={"activeGapClosure"}
          ourId={ourId}
          handleSourceSubmit={()=>handleSubmit()}
          handleAssessmentCancel={handleAssessmentCancel}
        />
      </Stack>
    </Box>
  );
};

export default SDOHNewAssessment;
