import { axiosRequests } from "../AxiosRequest";

function getPillarsToolsScorecards() {
  return axiosRequests.get("/RoleMapping/GetToolsandScorecardMapping");
}
function getSevenPillarsList(){
  return axiosRequests.get("/UserAccess/GetSevenPillarsList");
}
export const PillarsToolsScorecardsAgent = {
  getPillarsToolsScorecards: getPillarsToolsScorecards,
  getSevenPillarsList:getSevenPillarsList,
};
