import { TextField } from "@mui/material";
import React from "react";

const MultilineTextFieldOutlined = ({
  label,
  rows,
  // defaultValue,
  value,
  helperText,
  onChange,
}) => {
  return (
    <TextField
      required
      label={label}
      id="outlined-size-small"
      // defaultValue={defaultValue ?? ""}
      error={!!helperText}
      // helperText={helperText}
      value={value}
      onChange={(e) => onChange(e)}
      style={{
        width: "615px",
        // height: "34px",
      }}
      color="orangeTheme"
      size="small"
      multiline
      rows={rows}
    />
  );
};

export default MultilineTextFieldOutlined;
