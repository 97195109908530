import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { PagesPath } from "../../../PagesPath";
import EducationConfigurationFormsHeader from "../EducationConfigurationFormsHeader";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Title } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { Styles } from "../../../Styles";
import { axiosRequests } from "../../../app/api/AxiosRequest";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import "./AddEventFormCSS.css";

const AddEventForm = () => {
  const navigate = useNavigate();
  let { state } = useLocation();
  const [title, setTitle] = useState("");
  const [url, setURL] = useState("");
  const [description, setDescription] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [titleError, setTitleError] = useState("");
  const [urlError, setURLError] = useState("");
  const [id, setId] = useState(0);
  const [descriptionError, setDescriptionError] = useState("");
  const [startDateError, setStartDateError] = useState(null);
  const [endDateError, setEndDateError] = useState(null);
  const urlRegex =
    /^(https?:\/\/)?(www\.)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\/.*)?$/;

  const handleBackClick = () => {
    localStorage.setItem("educationTabValue", "2");
    navigate(PagesPath.EducationConfiguration, {
      state: { educationTabValue: "2" },
    });
  };

  useEffect(() => {
    if (state) {
      setTitle(state.title);
      setDescription(state.description);
      setURL(state.url);
      setId(state.id);
      setStartDate(new Date(state.startDate));
      setEndDate(new Date(state.endDate));
    }
  }, []);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
    setTitleError("");
  };

  const handleURLChange = (e) => {
    setURL(e.target.value);
    setURLError("");
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    setDescriptionError("");
  };

  const handleFormSubmit = () => {
    let hasError = false;

    if (!title) {
      setTitleError("Title is required");
      hasError = true;
    }
    if (!description) {
      setDescriptionError("Description is required");
      hasError = true;
    }
    if (!url) {
      setURLError("URL is required");
      hasError = true;
    }
    if (!startDate) {
      setStartDateError("Start Date is required");
      hasError = true;
    }
    if (!endDate) {
      setEndDateError("End Date is required");
      hasError = true;
    }
    if (startDate && isNaN(new Date(startDate))) {
      setStartDateError("Start Date is invalid");
      hasError = true;
    }
    if (endDate && isNaN(new Date(endDate))) {
      setEndDateError("End Date is invalid");
      hasError = true;
    }
    if (startDate > endDate) {
      setEndDateError("End Date should be later than Start Date");
      hasError = true;
    }
    if (url && !urlRegex.test(url)) {
      setURLError("URL is invalid");
      hasError = true;
    }
    if (hasError) return;

    var formData = {
      id: -1,
      title: title,
      description: description,
      url: url,
      startDate: startDate,
      endDate: endDate,
    };
    if (id && id > 0) {
      formData.id = id;
    }
    axiosRequests
      .post("/EducationEvent/AddUpdateEducationEvent", formData)
      .then((resp) => {
        if (resp === 1) {
          localStorage.setItem("educationTabValue", "2");
          navigate(PagesPath.EducationConfiguration, {
            state: { educationTabValue: "2" },
          });
        }
      });
  };
  return (
    <Box sx={{ width: "100%", p: { xs: 2, sm: 2 } }}>
      <EducationConfigurationFormsHeader pageTitle="Add Event"></EducationConfigurationFormsHeader>
      <Stack direction="row" spacing={1}>
        <ArrowBackOutlinedIcon
          onClick={() => handleBackClick()}
          style={{
            fontSize: "25px",
            fontWeight: "600",
            marginTop: "2px",
            width: "32px",
          }}
        />
        <Typography style={{ fontSize: "20px", fontWeight: "600" }}>
          Add Event
        </Typography>
      </Stack>
      <div
        style={{
          width: "1270px",
          height: "534px",
          background: "rgba(226, 226, 226, 0.30)",
          padding: "16px",
        }}
      >
        <Stack direction="column" spacing={4}>
          <TextField
            required
            label="Title"
            onChange={handleTitleChange}
            id="outlined-size-small"
            value={title}
            error={!!titleError}
            helperText={titleError}
            style={{
              width: "705px",
              height: "39px",
              borderRadius: "6px",
              backgroundColor: "#fff",
            }}
            color="orangeTheme"
            size="small"
          />
          <TextField
            required
            label="URL"
            onChange={handleURLChange}
            id="outlined-size-small"
            value={url}
            error={!!urlError}
            helperText={urlError}
            style={{
              width: "705px",
              height: "39px",
              borderRadius: "6px",
              backgroundColor: "#fff",
            }}
            color="orangeTheme"
            size="small"
          />
          <TextField
            id="outlined-multiline-static"
            label="Description"
            value={description}
            error={!!descriptionError}
            helperText={descriptionError}
            onChange={handleDescriptionChange}
            multiline
            className="roleDescription"
            color="orangeTheme"
            rows={5}
            variant="outlined"
          />
          <Stack direction="row" spacing={2}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <FormControl
                required
                color="orangeTheme"
                //   sx={{ minWidth: "294px", maxWidth: "294px", height: "40px" }}
              >
                <DateTimePicker
                  className="dateCalendarPicker"
                  label="Start Date and Time"
                  minDate={new Date()}
                  slotProps={{
                    textField: {
                      error: !!startDateError,
                      required: true,
                      color: "orangeTheme",
                      sx: { backgroundColor: "#fff", width: "345px" },
                      //className: "datetimepickerClass",
                    },
                    popper: {
                      className: "datetimepickerClass",
                    },
                  }}
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                    setStartDateError("");
                    setEndDate(newValue);
                  }}
                />
                <FormHelperText style={{ color: "#d32f2f" }}>
                  {startDateError}
                </FormHelperText>
              </FormControl>
              <FormControl
                required
                color="orangeTheme"
                //   sx={{ minWidth: "294px", maxWidth: "294px", height: "40px" }}
              >
                <DateTimePicker
                  label="End Date and Time"
                  disabled={!startDate}
                  minDateTime={startDate}
                  slotProps={{
                    textField: {
                      error: !!endDateError,
                      className: "datetimepickerClass",
                      required: true,
                      color: "orangeTheme",
                      sx: { backgroundColor: "#fff", width: "345px" },
                    },
                    popper: {
                      className: "datetimepickerClass",
                    },
                  }}
                  value={endDate}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                    setEndDateError("");
                  }}
                />
                <FormHelperText style={{ color: "#d32f2f" }}>
                  {endDateError}
                </FormHelperText>
              </FormControl>
            </LocalizationProvider>
          </Stack>
        </Stack>
      </div>
      <Grid
        container
        spacing={6}
        style={{ marginTop: "-39px", marginLeft: "55px" }}
      >
        <Grid item xs={8}></Grid>
        <Grid item xs={1}>
          <Link
            to={PagesPath.EducationConfiguration}
            key="ProgramTable"
            onClick={handleBackClick}
            // state={{ educationTabValue: "1" }}
            style={{
              textDecoration: "none",
              underline: "hover",
              color: "inherit",
            }}
          >
            <Button
              style={{
                borderColor: "black",
                color: "black",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
                textTransform: "none",
              }}
              variant="outlined"
            >
              Cancel
            </Button>
          </Link>
        </Grid>
        <Grid item xs={1}>
          <Button
            style={{
              color: "white",
              fontFamily: Styles.FONT_FAMILY_POPPINS,
              textTransform: "none",
            }}
            variant="contained"
            color="orangeTheme"
            onClick={handleFormSubmit}
            disableElevation
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddEventForm;
