import { Button, TextField, Modal, Box, Grid, Backdrop } from "@mui/material";
import React, {useEffect, useRef, useState} from 'react';
import { Styles } from "../../Styles";
import cinq_1 from "../../assets/cinq_1.png";
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Moment from "react-moment";
import CloseIcon from '@mui/icons-material/Close';
import SignatureCanvas from 'react-signature-canvas';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import PdfTemplate from "./PdfTemplate";
import PdfGenerator from "./PDFGenerator";
import { format } from "date-fns";

import { axiosRequests } from "../../app/api/AxiosRequest";
// import PdfGenerator from "./PdfGenerator";
// import {PdfGenerator} from "./PdfGenerator.js";



const UpdateFormModal = ({ isOpen, onClose, rowData }) => {
    const sigCanvas = useRef({});
    const modalRef = useRef(null);
    useEffect(()=>{
        setSignBoxEmptyError('');
    },[isOpen])
    const clear = () => {
        sigCanvas.current.clear(); 
        setSigImage(null);
    }
    const [showPdfGenerator, setShowPdfGenerator] = useState(false);
    const [sigImage, setSigImage] = useState(null);
    const [formData, setFormData] = useState({});
    const [isSignBoxEmpty, setIsSignBoxEmpty] = useState(false);
    const [signBoxEmptyError, setSignBoxEmptyError] = useState('');
    if (!rowData) return null;
    
    const exportPDF = async () => {
        if(isOpen){
            let hasError=false;
            let isSignEmpty=sigCanvas.current.isEmpty();
            if(isSignEmpty){
                setIsSignBoxEmpty(true);
                setSignBoxEmptyError('Signature is Required');
                hasError=true;
            }
            else if(!isSignEmpty){
                setIsSignBoxEmpty(false);
                setSignBoxEmptyError('');
                if(!hasError) hasError=false;
            }
            if (hasError) return;
            const signatureImage= sigCanvas.current.toDataURL();
            await setSigImage(signatureImage);
            const canvas = await html2canvas(modalRef.current,{allowTaint: true});
            const image = canvas.toDataURL();
            const form ={
                practiceName: rowData.practiceName,
                providerName: rowData.providerName,
                mbinumber: rowData.mbinumber,
                memberName: rowData.memberName
            }
             setFormData(form);
             setShowPdfGenerator(true);
             const updateForm = {
                MBINumber: rowData.mbinumber,
                EnrollmentStatus: "YES"
             }
             setTimeout(()=>{
                axiosRequests.put('/VoluntaryAlignment/VoluntaryAlignmentUpdateStatus',updateForm).then(resp => {
                    if(resp===1){
                        window.location.reload();
                    }
                })
             },1000)
             
        }
            
       
       
    }




    return (
        <Modal open={isOpen} onClose={onClose} slotProps={{backdrop:{onClick:null}}}>
            <div ref={modalRef} id="pdf-content">
            <Box sx={{
                position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', width: '678px',
                bgcolor: 'background.paper', boxShadow: 24, p: 4, pt: 1, height: '560px'
            }}>
                <h3 style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: '16px' }}>Confirmation Of Main Doctor Or Other Healthcare Professional Form <span><CloseIcon onClick={onClose} style={{float:'right'}}/></span></h3>
                {/* <ModalClose variant="plain" sx={{ m: 1 }} /> */}
                <img style={{ width: '129px', height: '29px', margin: '0 auto', display: 'block' }} src={cinq_1}></img>
                <h5>By signing below I am confirming that my main doctor or other healthcare professional - or the main place I go to for routine medical care - {rowData.practiceName}.</h5>
                <Grid container style={{ marginBottom: '13px' }}>
                    <Grid item xs={6} style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: '14px' }}>
                       <span style={{fontWeight: 500, paddingRight: '6px'}}>Facility Name:</span>  {rowData.practiceName}
                    </Grid>
                    <Grid item xs={6} style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: '14px' }}>
                    <span style={{fontWeight: 500}}>Provider Name: </span>{rowData.providerName}
                    </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: '13px' }}>
                    <Grid item xs={6} style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: '14px' }}>
                    <span style={{fontWeight: 500}}>MBI Number:</span> {rowData.mbinumber}
                    </Grid>
                    <Grid item xs={6} style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: '14px' }}>
                    <span style={{fontWeight: 500}}>Patient Name:</span> {rowData.memberName}
                    </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: '13px' }}>
                    <Grid item xs={6} style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: '14px' }}>
                    <span style={{fontWeight: 500}}>Date:</span> {format(new Date(), "dd MMM yyyy")}
                    </Grid>
                    <Grid item xs={6} style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: '14px' }}>
                    <span style={{fontWeight: 500}}> E-Signature: </span> 
                        <SignatureCanvas ref={sigCanvas} penColor='#333333' backgroundColor= '#e2e2e2' velocityFilterWeight={0.3}
    canvasProps={{width: 265, height: 95, className: 'sigCanvas'}}/> <ReplayOutlinedIcon onClick={clear} style={{paddingLeft: '5px', marginLeft: '-29px'}}/>
    {isSignBoxEmpty ? <div style={{ fontSize: '12px', color:'#D32F2F'}}>{signBoxEmptyError}</div>: null}
                    </Grid>
                </Grid>
                {/* <h5>By signing below I am confirming that my main doctor or other healthcare professional - or the main place I go to for routine medical care - is Care Medical.</h5> */}
                <h5>Note: Completing and returning this form is voluntary. It won’t affect your Medicare benifits.</h5>

                <hr style={{width:'622px',opacity:'0.3',marginTop:'12px', marginBottom:'-4px'}}/>
                <Grid container spacing={6} style={{marginLeft: '-20px', marginTop:'-39px'}}>
                    <Grid item xs={7}></Grid>
                    <Grid item xs={2}>
                    <Button style={{borderColor: 'black', color:'black', fontFamily: Styles.FONT_FAMILY_POPPINS}} variant="outlined" onClick={onClose}>Cancel</Button>
                    </Grid>
                    <Grid item xs={2}>
                    <Button style={{color:'white', fontFamily: Styles.FONT_FAMILY_POPPINS}} variant="contained" color="orangeTheme" onClick={() => exportPDF()} disableElevation>Confirm</Button>
                    </Grid>
                </Grid>
            </Box>
            {showPdfGenerator && <PdfGenerator style={{display:'none'}} logoSrc={cinq_1} titleText={'Hello from Title'} formData={formData} signatureImage={sigImage}></PdfGenerator>}
            </div>
            
        </Modal>

    )
};
export default UpdateFormModal;