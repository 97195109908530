import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Styles } from "../../Styles";
import HealthHomeDocumentDetailsModal from "./HealthHomeDocumentDetailsModal";

const StyledCard = styled(Card)({
  width: "430px",
  minHeight: "300px",
  maxHeight: "300px",
  display: "flex",
  backgroundColor: "rgba(243, 112, 33, 0.10)",
  fontFamily: Styles.FONT_FAMILY_POPPINS,
  flexDirection: "column",
  justifyContent: "space-between",
  borderRadius: "8px",
  "&:hover": {
    borderBottom: "3px solid var(--Color-Secondary, #FF9336)",
    background: "rgba(243, 112, 33, 0.20)",
  },
});

const CardDescription = styled(Typography)({
  overflow: "hidden",
  textOverflow: "ellipsis",
  fontFamily: Styles.FONT_FAMILY_POPPINS,
  display: "-webkit-box",
  WebkitLineClamp: 4,
  WebkitBoxOrient: "vertical",
});
const HealthHomeDocumentNameCard = ({
  name,
  description,
  section,
  subSection,
  id,
}) => {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [detailsTitle, setDetailsTitle] = useState("");
  const [detailsDescription, setDetailsDescription] = useState("");
  const [cardId, setCardId] = useState(id);
  //const [detailsURL, setDetailsURL] = useState(url);

  const openDetailsModal = () => {
    setIsDetailsModalOpen(true);
  };

  const onClose = () => {
    setIsDetailsModalOpen(false);
  };

  return (
    <StyledCard>
      <CardContent>
        <Typography
          style={{ color: "#DC4F34", marginBottom: "-8px" }}
          variant="h6"
          component="div"
          gutterBottom
        >
          {name}
        </Typography>
        <Stack direction="row" spacing={3} style={{ marginBottom: "8px" }}>
          <Typography
            style={{ color: "#DC4F34", fontSize: "12px" }}
            component="div"
            gutterBottom
          >
            Section: {section}
          </Typography>
          <Typography
            style={{ color: "#DC4F34", fontSize: "12px" }}
            component="div"
            gutterBottom
          >
            Sub Section: {subSection}
          </Typography>
        </Stack>
        <CardDescription variant="body2" color="text.secondary">
          {description}
        </CardDescription>
      </CardContent>
      <Box display="flex" justifyContent="flex-start" p={2}>
        <Stack direction="row" spacing={1}>
          <Button
            size="small"
            style={{
              borderColor: "black",
              color: "black",
              fontFamily: Styles.FONT_FAMILY_POPPINS,
              textTransform: "none",
            }}
            onClick={() => openDetailsModal()}
            variant="outlined"
          >
            View Details
          </Button>
          {/* <a href={detailsURL} target="_blank">
            <Button
              style={{
                color: "#fff",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
                textTransform: "none",
              }}
              color="orangeTheme"
              variant="contained"
              size="small"
              disableElevation
            >
              Start Program
            </Button>
          </a> */}
        </Stack>
      </Box>
      {isDetailsModalOpen && (
        <HealthHomeDocumentDetailsModal
          isOpen={isDetailsModalOpen}
          onClose={onClose}
          rowId={cardId}
        />
      )}
    </StyledCard>
  );
};

export default HealthHomeDocumentNameCard;
