import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Styles } from "../../../Styles";
import CloseIcon from "@mui/icons-material/Close";
import { format } from "date-fns";
import { axiosRequests } from "../../../app/api/AxiosRequest";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PagesPath } from "../../../PagesPath";

const UserRoleMappingModal = ({
  isOpen,
  onClose,
  rowData,
  roleDropdownData,
  setRowUpdated,
}) => {
  const [selectedRoleName, setSelectedRoleName] = useState([]);
  const [roleNameErrorText, setRoleNameErrorText] = useState("");
  const navigate = useNavigate();

  const handleRoleNameChange = (e) => {
    setSelectedRoleName(
      typeof e.target.value === "string"
        ? e.target.value.split(",")
        : e.target.value
    );
  };

  useEffect(() => {
    if (rowData.roleId) {
      var mappedRoles = rowData.roleId
        .split(",")
        .filter((id) =>
          roleDropdownData.some((item) => item.roleId === parseInt(id))
        );
      setSelectedRoleName(
        mappedRoles.map(function (x) {
          return parseInt(x, 10);
        })
      );
    } else {
      setSelectedRoleName([]);
    }
  }, [isOpen]);

  const handleSubmitForm = () => {
    let hasError = false;

    if (!selectedRoleName || selectedRoleName.length <= 0) {
      setRoleNameErrorText("Role Selection is Required.");
      hasError = true;
    }

    if (hasError) return;

    let formData = {
      userId: rowData.id,
      roleId: selectedRoleName,
    };

    axiosRequests
      .put("/UserRoleMapping/UpdateUserRole", formData)
      .then((resp) => {
        if (resp >= 1) {
          setRowUpdated(true);
          onClose();
        }
      });
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      slotProps={{ backdrop: { onClick: null } }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "578px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          pt: 1,
          height: "246px",
        }}
      >
        <h3
          style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
        >
          Edit User Role Mapping
          <span>
            <CloseIcon onClick={onClose} style={{ float: "right" }} />
          </span>
        </h3>
        <hr
          style={{
            width: "522px",
            opacity: "0.3",
            marginTop: "-11px",
            marginBottom: "5px",
          }}
        />
        <Stack direction="column" spacing={3}>
          <Typography style={{ fontWeight: 500 }}>
            Select the role that you need to be mapped to{" "}
            <b>{rowData.firstName + " " + rowData.lastName}</b>
          </Typography>
          <FormControl
            className="practiceNameClass"
            required
            error={!!roleNameErrorText}
            color="orangeTheme"
            sx={{
              maxWidth: "auto",
              minWidth: "303px",
              backgroundColor: "#fff",
              borderRadius: "6px",
            }}
            size="small"
          >
            <InputLabel id="practice-name-select-small-label">
              Role Name
            </InputLabel>

            <Select
              required
              multiple
              value={selectedRoleName}
              className="roleNameDropdown"
              labelId="role-name-select-small-label"
              id="role-name-select-small"
              onChange={(e) => handleRoleNameChange(e)}
              renderValue={(selected) => `${selected.length} Roles Selected`}
              label="Select Roles"
            >
              {roleDropdownData.map((item) => (
                <MenuItem key={item.roleId} value={item.roleId}>
                  <Checkbox
                    style={{ fontSize: "14px" }}
                    color="orangeTheme"
                    checked={selectedRoleName.indexOf(item.roleId) > -1}
                  />
                  <ListItemText
                    style={{ fontSize: "14px" }}
                    primary={item.roleName}
                  />
                </MenuItem>
              ))}
            </Select>
            <FormHelperText style={{ height: "0px" }}>
              {roleNameErrorText}
            </FormHelperText>
          </FormControl>

          <Stack direction="row" spacing={3} style={{ alignSelf: "flex-end" }}>
            <Button
              style={{
                borderColor: "black",
                color: "black",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
              }}
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              style={{ color: "white", fontFamily: Styles.FONT_FAMILY_POPPINS }}
              variant="contained"
              color="orangeTheme"
              onClick={handleSubmitForm}
              disableElevation
            >
              Confirm
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default UserRoleMappingModal;
