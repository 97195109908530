import React, { useEffect, useState, useCallback } from "react";
import { useAppDispatch } from "../../../app/store";
import { Link, useNavigate } from "react-router-dom";
// import { Box } from "@mui/material";
import {
  Avatar,
  Button,
  Chip,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Loader from "../../../component/common/loader/Loader";
import { useSelector } from "react-redux";
// import DataGridCustom from "../../component/common/datagrid/DataGridCustom";
import Fab from "@mui/material/Fab";
// import { Styles } from "../../Styles";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { axiosRequests } from "../../../app/api/AxiosRequest";
import DataGridCustom from "../../../component/common/datagrid/DataGridCustom";
// import { getUsersList } from "./usersListSlice";
import { PagesPath } from "../../../PagesPath";
import DeleteConfirmation from "../DeleteConfirmation";
import { getRoleMappingList } from "./roleMappingSlice";
import { COLOR, Styles } from "../../../Styles";
import DataGridHeader from "../../../component/common/datagrid/DataGridHeader";
import { Search } from "@mui/icons-material";

const RoleMappingTable = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [mappedRoleIDs, setMappedRoleIDs] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const { isLoading, roleMappingData } = useSelector(
    (state) => state.roleMappingData
  );
  const getRoleMappingListData = useCallback(async () => {
    const response = await dispatch(getRoleMappingList());
    setMappedRoleIDs(response.payload.map((item) => parseInt(item.roleId)));
  }, [dispatch]);

  useEffect(() => {
    getRoleMappingListData();
  }, [getRoleMappingListData]);

  function getRowId(row) {
    return row.id; // + "_" + row.profileId;
  }

  const handleEditClick = (rowId) => {
    axiosRequests
      .get(`/RoleMapping/GetRoleMappingDetails?id=${rowId}`)
      .then((resp) => {
        navigate(PagesPath.AddRoleMappingURL, { state: { value: resp } });
      });
  };

  const viewRoleDetails = (rowId) => {
    axiosRequests
      .get(`/RoleMapping/GetRoleMappingDetails?id=${rowId}`)
      .then((resp) => {
        navigate(PagesPath.ViewRoleMappingURL, { state: { value: resp } });
      });
  };

  useEffect(() => {
    if (roleMappingData) {
      let filtered;
      if (searchText) {
        filtered = roleMappingData.filter((item) =>
          item.roleName.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredData(filtered);
      } else {
        setFilteredData(roleMappingData);
      }
    }
  }, [searchText, roleMappingData]);

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  }

  const columns = [
    {
      field: "roleName",
      headerName: "Role",
      flex: 1,
      renderCell: (params) => {
        return (
          <Link
            style={{
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <Tooltip title={params.row.roleName}>
              <div onClick={() => viewRoleDetails(params.row.id)}>
                <u>{params.row.roleName}</u>
              </div>
            </Tooltip>
          </Link>
        );
      },
    },
    {
      field: "payercount",
      headerName: "Payer Count",
      flex: 1,
      type: "number",
    },
    {
      field: "productcount",
      headerName: "Product Count",
      flex: 1,
      type: "number",
    },
    {
      field: "marketcount",
      headerName: "Market Count",
      flex: 1,
      type: "number",
      //cellClassName: 'facilityNameField'
    },
    {
      field: "regioncount",
      headerName: "Region Count",
      flex: 1,
      type: "number",
    },
    {
      field: "practicecount",
      headerName: "Practice Count",
      flex: 1,
      type: "number",
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <strong>
            <Link
              style={{
                textDecoration: "none",
                underline: "hover",
                color: "inherit",
              }}
            >
              <EditIcon
                onClick={() => handleEditClick(params.row.id)}
              ></EditIcon>
            </Link>
          </strong>
        );
      },
    },
  ];

  return (
    <div>
      <Stack direction="row" spacing={1}>
        <div style={{ marginLeft: "auto", float: "right" }}>
          <Link
            to={PagesPath.AddRoleMappingURL}
            key="AddUser"
            style={{
              textDecoration: "none",
              underline: "hover",
              color: "inherit",
            }}
            state={{
              mappedRoleIDs: mappedRoleIDs,
            }}
          >
            <Button
              style={{
                textTransform: "none",
              }}
              variant="outlined"
              color="orangeTheme"
            >
              <AddIcon style={{ paddingTop: "5px", marginTop: "-4px" }} />
              Add Role Mapping
            </Button>
          </Link>
        </div>
      </Stack>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <Grid item>
          <DataGridCustom
            columns={columns}
            rows={filteredData}
            getRowId={getRowId}
            loading={isLoading}
            slots={{
              toolbar: DataGridHeader,
            }}
            slotProps={{
              toolbar: {
                headerText: "",
                fontSize: "",
                marginTop: "",
                marginBottom: "",
                height: "56px",
                children: (
                  <div
                    style={{
                      fontFamily: Styles.FONT_FAMILY_POPPINS,
                      fontSize: "16px",
                      width: "100%",
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginLeft: "5px",
                    }}
                  >
                    <Stack direction="row" spacing={1}>
                      <Typography
                        style={{
                          fontFamily: Styles.FONT_FAMILY_POPPINS,
                          fontSize: "16px",
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      >
                        Total Mappings
                      </Typography>
                      <Chip
                        color="orangeTheme"
                        variant="contained"
                        style={{ color: "#fff" }}
                        label={roleMappingData.length}
                      ></Chip>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{ marginLeft: "auto" }}
                      >
                        <TextField
                          InputProps={{
                            style: { height: "38px", alignItems: "center" },
                            endAdornment: (
                              <InputAdornment position="end">
                                <Link
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                    //cursor: "pointer",
                                  }}
                                >
                                  <Search
                                    fontSize="small"
                                    sx={{
                                      color: COLOR.ORANGE,
                                      position: "inherit",
                                    }}
                                  />
                                </Link>
                              </InputAdornment>
                            ),
                          }}
                          label="Search User Roles"
                          id="outlined-size-small"
                          onChange={(e) => handleSearchChange(e)}
                          style={{
                            width: "293px",
                          }}
                          color="orangeTheme"
                          size="small"
                        />
                      </Stack>
                    </Stack>
                  </div>
                ),
              },
            }}
          />
        </Grid>
      )}
    </div>
  );
};

export default RoleMappingTable;
