
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../../../app/api/Agent";

const initialState = {
  isLoading: false,
  sdohStatusList: [],
  sdohChartsData:[],
  sdohPieChartsData:[],
  errorMessage: "",
};

export const getStatusList = createAsyncThunk(
  "sdohStatusList",
  async ({market,submarket,practice}) => {
    const response =
      await agent.SDOHAssessmentTrackingAgent.getStatusList(market,submarket,practice);
    return response;
  }
);

export const getChartsData = createAsyncThunk(
  "sdohChartsData",
  async ({market,submarket,practice}) => {
    const response =
      await agent.SDOHAssessmentTrackingAgent.getChartsData(market,submarket,practice);
    return response;
  }
);

export const getPieChartsData = createAsyncThunk(
  "sdohPieChartsData",
  async ({market,submarket,practice}) => {
    const response =
      await agent.SDOHAssessmentTrackingAgent.getPieChartsData(market,submarket,practice);
    return response;
  }
);

const SDOHMembersStatusSlice = createSlice({
  name: "sdohStatusList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStatusList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStatusList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sdohStatusList = action.payload;
        state.errorMessage="";
      })
      .addCase(getStatusList.rejected, (state, action) => {
        state.isLoading = false;
        state.sdohStatusList = [];
        state.errorMessage = action.error.message;
      })


      .addCase(getChartsData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getChartsData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sdohChartsData = action.payload;
        state.errorMessage="";
      })
      .addCase(getChartsData.rejected, (state, action) => {
        state.isLoading = false;
        state.sdohChartsData = [];
        state.errorMessage = action.error.message;
      })

      .addCase(getPieChartsData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPieChartsData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.sdohPieChartsData = action.payload;
        state.errorMessage="";
      })
      .addCase(getPieChartsData.rejected, (state, action) => {
        state.isLoading = false;
        state.sdohPieChartsData = [];
        state.errorMessage = action.error.message;
      });
  },
});

export default SDOHMembersStatusSlice.reducer;
