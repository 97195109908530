import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import { Styles } from "../../../Styles";

const ImageBlock = ({ img, text }) => {
  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        borderRadius: "4px",
        border: "1px solid #E95110",
        background: "#FFF",
        backgroundColor: "#FFF",
        p: 1,
      }}
    >
      <img src={img} alt="" />
      <Typography
        sx={{
          color: "#DC4F34",
          textAlign: "center",
          leading: "both",
          fontFamily: Styles.FONT_FAMILY_POPPINS,
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "500",
          lineHeight: "22px" /* 137.5% */,
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
};

export default ImageBlock;
