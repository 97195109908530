import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import TransitionOfCareResultsPageHeader from "./transitionOfCareResultsPageHeader";
import ScorecardComponent from "../../component/common/scorecard/scorecard";
const TransitionOfCareResultsPage = () => {
    const [tOCResultID,setTOCResultID]=useState(25);
    return (
        <Box >
            <TransitionOfCareResultsPageHeader></TransitionOfCareResultsPageHeader>
            <ScorecardComponent ScoreId={tOCResultID} />
        </Box>
    );
}

export default TransitionOfCareResultsPage;