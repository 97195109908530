import agent from "../../app/api/Agent";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    medicalAdherenceList: [],
    medicalAdherenceCount:[],
    medicalAdherenceListCount:0,
    errorMessage: "",
  };

  export const getMedicalAdherenceList = createAsyncThunk(
    "medicalAdherenceList",
    async (requestBody) => {
      const response =
        await agent.MedicalAdherenceToolAgent.getMedicalAdherenceList(requestBody);
      return response;
    }
  );

  const medicalAdherenceSlice = createSlice({
    name: "medicalAdherence",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(getMedicalAdherenceList.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(getMedicalAdherenceList.fulfilled, (state, action) => {
          state.isLoading = false;
          state.medicalAdherenceList = action.payload.medAdhMembersDetails;
          state.medicalAdherenceCount = action.payload.medAdhStatusCount;
          state.medicalAdherenceListCount = action.payload.totalRecords;
        })
        .addCase(getMedicalAdherenceList.rejected, (state, action) => {
          state.isLoading = false;
          state.medicalAdherenceList = [];
          state.medicalAdherenceCount = [];
          state.medicalAdherenceListCount = 0;
          state.errorMessage = action.error.message;
        });
    },
  });
  
  export default medicalAdherenceSlice.reducer;