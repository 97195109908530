import { Box, Stack, Typography } from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { useEffect, useState } from "react";
import { axiosRequests } from "../../app/api/AxiosRequest";
import Loader from "../../component/common/loader/Loader";
import ViewDocuments from "./viewDocuments";
import MemberDetailsPDF from "./PDFExport/MemberDetailsPDF";
import html2pdf from "html2pdf.js";
import MemberDemographicData from "./MemberDemographicData";
import OpenQualityMeasuresGrid from "./openQualityMeasuresGrid";
import MedicalCodingSuspectsGrid from "./medicalCodingSuspectsGrid";
import SDOHCodingSuspectsGrid from "./SDOHCodingSuspectsGrid";

const ActiveGapClosureMemberDetails = ({
  ourId,
  payerName,
  backAction,
  setShowMemberExcludedSnackbar,
  showMemberExcludedSnackbar,
  pageTitle = "Patient Assessment Form",
  forceUpdateParentGrid = undefined,
  setForceUpdateParentGrid = undefined,
  priority = undefined,
}) => {
  const AuditStatusIdArray = [
    { id: 0, pageTitle: "Patient Assessment Form", auditStatus: "Total Open" },
    {
      id: 1,
      pageTitle: "Attestations- Audit Accepted",
      auditStatus: "Audit Accepted",
    },
    {
      id: 2,
      pageTitle: "Attestations- Audit Pending",
      auditStatus: "Audit Pending",
    },
    {
      id: 3,
      pageTitle: "Attestations- Audit Rejected",
      auditStatus: "Audit Rejected",
    },
  ];
  //State Initialization Starts
  const [memberDetail, setMemberDetail] = useState({});
  var isAuditorFromStorage = localStorage.getItem("isAuditor");
  const isAuditor =
    isAuditorFromStorage && parseInt(isAuditorFromStorage) === 1 ? true : false;
  const [isLoadingDetail, setIsLoadingDetail] = useState(true);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [isViewDocumentsOpen, setIsViewDocumentsOpen] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [source, setSource] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [showPDFGenerator, setShowPDFGenerator] = useState(false);
  const [hideExcludeMember, setHideExcludeMember] = useState(true);
  const [auditStatusId, setAuditStatusId] = useState(0);
  const [rowDetailId, setRowDetailId] = useState(0);
  //State Initialization Ends
  //API Call Functions Start
  const getMemberDetails = (ourId, payerName) => {
    const auditStatusIdFromArray = AuditStatusIdArray.find(
      (item) => item.pageTitle.toLowerCase() === pageTitle.toLowerCase()
    ).id;
    setAuditStatusId(auditStatusIdFromArray);
    if (auditStatusIdFromArray === 0) {
      setHideExcludeMember(false);
    }
    return axiosRequests.get(
      `/ActiveGapClosure/GetActiveGapClosureMemberDetails?OurId=${ourId}&payerName=${payerName}&AuditStatus=${auditStatusIdFromArray}`
    );
  };
  //API Call Functions End

  //Initial Data Load Starts
  useEffect(() => {
    getMemberDetails(ourId, payerName).then((resp) => {
      setMemberDetail(resp);
      if (forceUpdate > 0) setForceUpdateParentGrid(forceUpdateParentGrid + 1);
      setIsLoadingDetail(false);
    });
  }, [forceUpdate]);
  //Initial Data Load Ends

  //State changes start
  useEffect(() => {
    if (!isLoadingDetail) setIsLoading(false);
  }, [isLoadingDetail]);
  //State Changes End

  //Modal Toggle FUnctions Starts

  const onClose = () => {
    setIsViewDocumentsOpen(false);
  };

  const handleViewDocumentsOpen = (rowId, source, detailId) => {
    //setTitle(measureTitle);
    setRowId(rowId);
    setSource(source);
    setRowDetailId(detailId);
    setIsViewDocumentsOpen(true);
  };
  //Modal Toggle FUnctions Ends

  //PDF Export Starts

  const handlePDFDownload = () => {
    setShowPDFGenerator(true);
    const element = document.getElementById("pdf-data");
    html2pdf().from(element).save(`${memberDetail.ourId}.pdf`);
  };

  //PDF Export Ends

  return (
    <Box sx={{ width: "100%", p: { xs: 2, sm: 2 } }}>
      {isLoading ? (
        <Loader isLoading={isLoading}></Loader>
      ) : (
        <Stack direction="column" spacing={0.2}>
          <Stack direction="row" spacing={1}>
            <ArrowBackOutlinedIcon
              onClick={backAction}
              style={{
                fontSize: "25px",
                fontWeight: "600",
                marginTop: "2px",
                width: "32px",
              }}
            />
            <Typography style={{ fontSize: "20px", fontWeight: "300" }}>
              {pageTitle}
            </Typography>
          </Stack>
          <MemberDemographicData
            memberDetail={memberDetail}
            payerName={payerName}
            backAction={backAction}
            setShowMemberExcludedSnackbar={setShowMemberExcludedSnackbar}
            priority={priority}
            hideExcludeMember={hideExcludeMember}
            isAuditor={isAuditor}
            auditStatusId={auditStatusId}
          />
          <OpenQualityMeasuresGrid
            memberDetail={memberDetail}
            forceUpdate={forceUpdate}
            setForceUpdate={setForceUpdate}
            handleViewDocumentsOpen={handleViewDocumentsOpen}
            onClose={onClose}
            isLoading={isLoading}
            hideExcludeMember={hideExcludeMember}
            isAuditor={isAuditor}
            auditStatusId={auditStatusId}
          />
          <MedicalCodingSuspectsGrid
            memberDetail={memberDetail}
            forceUpdate={forceUpdate}
            setForceUpdate={setForceUpdate}
            handleViewDocumentsOpen={handleViewDocumentsOpen}
            onClose={onClose}
            payerName={payerName}
            isLoading={isLoading}
            hideExcludeMember={hideExcludeMember}
            isAuditor={isAuditor}
            auditStatusId={auditStatusId}
            priority={priority}
          />

          <SDOHCodingSuspectsGrid
            memberDetail={memberDetail}
            forceUpdate={forceUpdate}
            setForceUpdate={setForceUpdate}
            handleViewDocumentsOpen={handleViewDocumentsOpen}
            onClose={onClose}
            payerName={payerName}
            isLoading={isLoading}
            hideExcludeMember={hideExcludeMember}
            isAuditor={isAuditor}
            auditStatusId={auditStatusId}
            priority={priority}
          />
        </Stack>
      )}

      {isViewDocumentsOpen && (
        <ViewDocuments
          isOpen={isViewDocumentsOpen}
          onClose={onClose}
          source={source}
          rowId={rowId}
          detailId={rowDetailId}
          ourId={ourId}
        />
      )}
      {showPDFGenerator && (
        <MemberDetailsPDF memberData={memberDetail}></MemberDetailsPDF>
      )}
    </Box>
  );
};

export default ActiveGapClosureMemberDetails;
