const SEVEN_PILLAR_URL = "/sevenpillar";
const NO_ACCESS_URL = `${SEVEN_PILLAR_URL}/NoAccess`;
const AvoidableInpatientStays_URL = `${SEVEN_PILLAR_URL}/AvoidableInpatientStays`;
const PracticeVBCRevenueAndGrowth_URL = `${SEVEN_PILLAR_URL}/PracticeVBCRevenueAndGrowth`;
const NonEmergentER_URL = `${SEVEN_PILLAR_URL}/NonEmergentER`;
const HighRiskFamilyMemberCare_URL = `${SEVEN_PILLAR_URL}/HighRiskFamilyMemberCare`;
const ConsumerCommunityServices_URL = `${SEVEN_PILLAR_URL}/ConsumerCommunityServices`;
const QualityCodingAndSatisfaction = `${SEVEN_PILLAR_URL}/QualityCodingAndSatisfaction`;
const SevenPillarEducationURL = `${SEVEN_PILLAR_URL}/Education`;
const SevenPillarHealthHomeEducationURL = `${SEVEN_PILLAR_URL}/HealthHomeEducation`

export const GetPagesPathForPillars = (pillarkey, key, isTool) => {
  return isTool
    ? `${SEVEN_PILLAR_URL}/${pillarkey}/tools/${key}`
    : `${SEVEN_PILLAR_URL}/${pillarkey}/scorecards/${key}`;
};

export const GetDefaultPagePath = (pillarKey) => {
  switch (pillarKey) {
    case "AvoidableInpatientStays":
      return PagesPath.CensusToolMarketHospitalPratice_URL;
    case "PracticeVBCRevenueAndGrowth":
      return PagesPath.VoluntaryAlignmentTracker_URL;
    case "NonEmergentER":
      return PagesPath.CensusToolMarketHospitalPraticeER_URL;
    case "HighRiskFamilyMemberCare":
      return PagesPath.CohortRegistryManagement_URL;
    case "ConsumerCommunityServices":
      return PagesPath.SDOHAssessmentDashboard_URL;
    case "QualityCodingAndSatisfaction":
      return PagesPath.ActiveGapClosuresList_URL;
    default:
      return "/";
  }
};

export const PagesPath = {
  LAYOUT_URL: "/",
  HOME_URL: "/",
  FAMILY_MEMBER_PROFILE_URL: "/FamilyMemberProfile",
  FAMILY_MEMBER_PROFILE_DETAIL_URL: "/FamilyMemberProfile/:id/:name",
  DE_ROSTERING_TRACKING: `${SEVEN_PILLAR_URL}DeRosteringTracking`,

  CensusToolMarketHospitalPratice_URL: `${AvoidableInpatientStays_URL}/tools/CensusToolMarketHospitalPratice`,
  IPAlertToolFromTexting_URL: `${AvoidableInpatientStays_URL}/tools/IPAlertToolFromTexting`,
  MedicationAdherenceActionSheets_URL: `${AvoidableInpatientStays_URL}/tools/MedicationAdherenceActionSheets`,
  FlyerTracking_URL: `${AvoidableInpatientStays_URL}/tools/FlyerTracking`,

  IPTrendsLOS_URL: `${AvoidableInpatientStays_URL}/scorecards/IPTrendsLOS`,
  NoAccess_URL: `${NO_ACCESS_URL}`,
  TOCTrends_URL: `${AvoidableInpatientStays_URL}/scorecards/TOCTrends`,
  PPA_RTrends_URL: `${AvoidableInpatientStays_URL}/scorecards/PPA_RTrends`,
  TopFlyers_URL: `${AvoidableInpatientStays_URL}/scorecards/TopFlyers`,
  FPTimeliness_URL: `${AvoidableInpatientStays_URL}/scorecards/FPTimeliness`,
  TimedFUs_URL: `${AvoidableInpatientStays_URL}/scorecards/TimedFUs`,
  MedicationAdh_URL: `${AvoidableInpatientStays_URL}/scorecards/MedicationAdh`,
  InpatientTrends_URL: `${AvoidableInpatientStays_URL}/scorecards/InpatientTrendsPage`,
  VoluntaryAlignmentTracker_URL: `${PracticeVBCRevenueAndGrowth_URL}/tools/VoluntaryAlignmentTracker`,
  DeRosteringTracking_URL: `${PracticeVBCRevenueAndGrowth_URL}/tools/DeRosteringTracking`,
  CommunityEventsTrackingAndResults_URL: `${PracticeVBCRevenueAndGrowth_URL}/tools/CommunityEventsTrackingAndResults`,
  RecertificationTracking_URL: `${PracticeVBCRevenueAndGrowth_URL}/tools/RecertificationTracking`,
  ReportPackagePage_URL: `${PracticeVBCRevenueAndGrowth_URL}/scorecards/ReportPackagePage`,
  // ACOReachReport_URL: `${PracticeVBCRevenueAndGrowth_URL}/scorecards/ACOReachReport`,
  HighRiskCareExcel_URL: `${PracticeVBCRevenueAndGrowth_URL}/scorecards/HighRiskCareExcel_URL`,

  MembershipAndGrowthTrends_URL: `${PracticeVBCRevenueAndGrowth_URL}/scorecards/MembershipAndGrowthTrends`,
  VBCPerformance_URL: `${PracticeVBCRevenueAndGrowth_URL}/scorecards/VBCPerformance`,
  EnrollmentsMSP_SSI_LIS_SNAP_URL: `${PracticeVBCRevenueAndGrowth_URL}/scorecards/EnrollmentsMSP_SSI_LIS_SNAP`,
  VoluntaryAlignment_URL: `${PracticeVBCRevenueAndGrowth_URL}/scorecards/VoluntaryAlignment`,
  Recertification_URL: `${PracticeVBCRevenueAndGrowth_URL}/scorecards/Recertification`,
  RecertificationDev_URL: `${PracticeVBCRevenueAndGrowth_URL}/scorecards/RecertificationDev`,
  BannerComponentURL: `${SEVEN_PILLAR_URL}/BannerSettings`,
  UserAdminConsoleURL: `${SEVEN_PILLAR_URL}/UserAdminConsole`,
  AddUserURL: `${SEVEN_PILLAR_URL}/UserAdminConsole/AddUser`,
  AddUserRolesURL: `${SEVEN_PILLAR_URL}/UserAdminConsole/AddUserRoles`,
  AddRoleMappingURL: `${SEVEN_PILLAR_URL}/UserAdminConsole/AddRoleMapping`,
  ViewRoleMappingURL: `${SEVEN_PILLAR_URL}/UserAdminConsole/ViewRoleMapping`,

  CensusToolMarketHospitalPraticeER_URL: `${NonEmergentER_URL}/tools/CensusToolMarketHospitalPraticeER`,
  ERAlertToolFromTexting_URL: `${NonEmergentER_URL}/tools/ERAlertToolFromTexting`,
  HospitalistRedirection_URL: `${NonEmergentER_URL}/tools/HospitalistRedirection`,

  ERTrends_URL: `${NonEmergentER_URL}/scorecards/ERTrends`,
  ERUti_URL: `${NonEmergentER_URL}/scorecards/ERUti`,
  PPETrends_URL: `${NonEmergentER_URL}/scorecards/PPETrends`,
  TopFlyers_URL: `${NonEmergentER_URL}/scorecards/TopFlyers`,
  ERDiversion_URL: `${NonEmergentER_URL}/scorecards/ERDiversion`,

  CohortRegistryManagement_URL: `${HighRiskFamilyMemberCare_URL}/tools/CohortRegistryManagement`,
  CreateCohortRegistryManagement_URL: `${HighRiskFamilyMemberCare_URL}/tools/CreateCohortRegistryManagement`,
  EditCohortRegistryManagement_URL: `${HighRiskFamilyMemberCare_URL}/tools/EditCohortRegistryManagement`,
  CreateCohortByImport_URL: `${HighRiskFamilyMemberCare_URL}/tools/CohortRegistryManagement/CreateCohortByImport`,
  ViewCohortRegistry_URL: `${HighRiskFamilyMemberCare_URL}/tools/ViewCohortRegistry`,
  ClinicalProfileExcel_URL: `${HighRiskFamilyMemberCare_URL}/scorecards/ClinicalProfileExcel_URL`,
  SDOHAssessmentDashboard_URL: `${ConsumerCommunityServices_URL}/tools/SDOHAssessment`,
  SDOHNewAssessmentForm_URL: `${ConsumerCommunityServices_URL}/tools/SDOHNewAssessmentForm`,
  SDOHMemberDetails_URL: `${ConsumerCommunityServices_URL}/tools/SDOHMemberDetails`,

  //Provider Education Section
  Programs_URL: `${SevenPillarEducationURL}/Programs`,
  Events_URL: `${SevenPillarEducationURL}/Events`,
  EducationConfiguration: `${SEVEN_PILLAR_URL}/EducationConfiguration`,
  AddProgram: `${SEVEN_PILLAR_URL}/EducationConfiguration/AddProgram`,
  AddEvent: `${SEVEN_PILLAR_URL}/EducationConfiguration/AddEvent`,

  //Active Gap Closure List
  ActiveGapClosuresList_URL: `${QualityCodingAndSatisfaction}/tools/ActiveGapClosuresListAndCaptureTool`,
  MedicationAdherenceActionLists_URL: `${QualityCodingAndSatisfaction}/tools/MedicationAdherenceActionLists`,

  //Health Home Education Configuration
  HealthHomeEducationConfiguration: `${SEVEN_PILLAR_URL}/HealthHomeEducationConfig`,
  HealthHomeTrainingPrograms: `${SEVEN_PILLAR_URL}/HealthHomeEducationConfig/TrainingPrograms`,
  HealthHomeTrainingDocuments: `${SEVEN_PILLAR_URL}/HealthHomeEducationConfig/TrainingDocuments`,
  AddTrainingProgram: `${SEVEN_PILLAR_URL}/HealthHomeEducationConfig/AddTrainingProgram`,
  AddTrainingDocument: `${SEVEN_PILLAR_URL}/HealthHomeEducationConfig/AddTrainingDocument`,

  HealthHomeTrainingPrograms_URL : `${SevenPillarHealthHomeEducationURL}/HealthHomeTrainingPrograms`,
  HealthHomeTrainingDocuments_URL : `${SevenPillarHealthHomeEducationURL}/HealthHomeTrainingDocuments`,


};
