import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { COLOR, Styles } from "../../../Styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { addDays } from "date-fns";
import axios from "axios";
import { axiosRequests } from "../../../app/api/AxiosRequest";
import Loader from "../../../component/common/loader/Loader";

const OutlinedButton = styled(Button)(() => ({
  borderRadius: "4px",
  padding: "6px",
  justifyContent: "center",
  color: COLOR.BLACK,
  borderColor: COLOR.BLACK,
  fontFamily: Styles.FONT_FAMILY_POPPINS,
  fontStyle: "normal",
  textTransform: "capitalize",
}));

const DefaultTypography = styled(Typography)({
  color: "#000",
  fontFamily: Styles.FONT_FAMILY_POPPINS,
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
});

const StyledFormArea = styled(Grid)({
  display: "flex",
  padding: "16px 8px",
  flexDirection: "column",
  alignItems: "center",
  gap: "16px",
  flex: "1 0 0",
  alignSelf: "stretch",
  borderRadius: "5px",
  background: "rgba(226, 226, 226, 0.30)",
});

const CohortByImport_Step2 = ({
  step,
  handleNextCall,
  handleBackCall,
  isDisabledMode,
  handleCancelCall,
  podName,
  setPODName,
  cohortName,
  setCohortName,
  cohortDescription,
  setCohortDescription,
  cohortOwner,
  setCohortOwner,
  cohortStartDate,
  setCohortStartDate,
  fileName,
}) => {
  const [podNameError, setPODNameError] = useState("");
  const [cohortNameError, setCohortNameError] = useState("");
  const [cohortDescriptionError, setCohortDescriptionError] = useState("");
  const [cohortOwnerError, setCohortOwnerError] = useState("");
  const [cohortStartDateError, setCohortStartDateError] = useState("");
  const [cohortOwnerDropdownValues, setCohortOwnerDropdownValues] =
    useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const handlePODNameChange = (e) => {
    setPODName(e.target.value);
    setPODNameError("");
  };
  const handleCohortNameChange = (e) => {
    setCohortName(e.target.value);
    setCohortNameError("");
  };
  const handleCohortDescriptionChange = (e) => {
    setCohortDescription(e.target.value);
    setCohortDescriptionError("");
  };
  const handleCohortOwnerChange = (e) => {
    setCohortOwner(e.target.value);
    setCohortOwnerError("");
  };
  const handleStartDateChange = (e) => {
    setCohortStartDate(e.target.value);
    setCohortStartDateError("");
  };

  useEffect(() => {
    axiosRequests.get("/cohort/GetCohortOwnerDetails").then((resp) => {
      setCohortOwnerDropdownValues(resp);
      setIsLoading(false);
    });
  }, []);
  const onNext = () => {
    let hasError = false;
    if (!podName || podName === "") {
      setPODNameError("POD Name is required");
      hasError = true;
    }
    if (!cohortName || cohortName === "") {
      setCohortNameError("Cohort Name is required");
      hasError = true;
    }
    if (!cohortDescription || cohortDescription === "") {
      setCohortDescriptionError("Cohort Description is required");
      hasError = true;
    }
    if (!cohortOwner || cohortOwner === "") {
      setCohortOwnerError("Cohort Owner is required");
      hasError = true;
    }
    if (!cohortStartDate || cohortStartDate === "") {
      setCohortStartDateError("Start Date is required");
      hasError = true;
    }
    if (hasError) return;
    handleNextCall();
  };
  // useEffect(() => {
  //   setCohortName(fileName);
  // }, [fileName]);
  return (
    <Box>
      {isLoading ? (
        <Loader isLoading={isLoading}></Loader>
      ) : (
        <>
          <StyledFormArea>
            <Stack direction="column" spacing={3}>
              <TextField
                required
                label="POD Name"
                onChange={handlePODNameChange}
                id="outlined-size-small"
                value={podName}
                error={!!podNameError}
                helperText={podNameError}
                style={{
                  width: "705px",
                  height: "40px",
                  borderRadius: "6px",
                  backgroundColor: "#fff",
                }}
                color="orangeTheme"
                size="small"
              />
              <TextField
                required
                label="Cohort Name"
                onChange={handleCohortNameChange}
                id="outlined-size-small"
                value={cohortName}
                error={!!cohortNameError}
                helperText={cohortNameError}
                style={{
                  width: "705px",
                  height: "40px",
                  borderRadius: "6px",
                  backgroundColor: "#fff",
                }}
                color="orangeTheme"
                size="small"
              />
              <TextField
                id="outlined-multiline-static"
                label="Description"
                value={cohortDescription}
                error={!!cohortDescriptionError}
                helperText={cohortDescriptionError}
                onChange={handleCohortDescriptionChange}
                multiline
                className="roleDescription"
                color="orangeTheme"
                rows={5}
                variant="outlined"
              />
              {/* <TextField
            required
            label="Cohort Owner"
            onChange={handleCohortOwnerChange}
            id="outlined-size-small"
            value={cohortOwner}
            error={!!cohortOwnerError}
            helperText={cohortOwnerError}
            style={{
              width: "705px",
              height: "40px",
              borderRadius: "6px",
              backgroundColor: "#fff",
            }}
            color="orangeTheme"
            size="small"
          /> */}
              <FormControl
                required
                color="orangeTheme"
                sx={{
                  maxWidth: "705px",
                  minWidth: "705px",
                  backgroundColor: "#fff",
                  borderRadius: "6px",
                }}
                size="small"
                error={!!cohortOwnerError}
              >
                <InputLabel id="role-type-select-small-label">
                  Cohort Owner
                </InputLabel>
                <Select
                  required
                  value={cohortOwner}
                  labelId="role-type-select-small-label"
                  id="role-type-select-small"
                  onChange={(e) => handleCohortOwnerChange(e)}
                  label="Cohort Owner"
                >
                  {cohortOwnerDropdownValues.map((item) => (
                    <MenuItem
                      className="dropdownText"
                      key={item.userId}
                      value={item.userId}
                    >
                      {item.cohortName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormControl
                  // className="practiceNameClass"
                  //className={props.isDisabledMode ? "disabledAccordian" : ""}
                  required
                  // disabled={props.isDisabledMode}
                  color="orangeTheme"
                  sx={{
                    maxWidth: "300px",
                    minWidth: "300px",
                    backgroundColor: "#fff",
                    borderRadius: "6px",
                    marginRight: "34%",
                    //border: isStartDateEmpty ? "1px solid #D32F2F" : "",
                  }}
                  size="small"
                >
                  <DatePicker
                    label="Start Date"
                    value={cohortStartDate}
                    color="orangeTheme"
                    slotProps={{
                      textField: {
                        className: "dateFieldClass",
                        color: "orangeTheme",
                        error: !!cohortStartDateError,
                      },
                    }}
                    minDate={addDays(new Date(1753, 0, 0), 1)}
                    onChange={handleStartDateChange}
                  />
                  <FormHelperText
                    style={{
                      height: "0px",
                      fontSize: "12px",
                      color: "rgb(211, 47, 47)",
                    }}
                  >
                    {cohortStartDateError}
                  </FormHelperText>
                </FormControl>
              </LocalizationProvider>
            </Stack>
          </StyledFormArea>
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              sx={{
                margintTop: "25px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  justifyContent: "end",
                  paddingTop: "7px",
                  paddingBottom: "7px",
                  paddingRight: "10px",
                }}
              >
                <OutlinedButton
                  onClick={handleCancelCall}
                  variant="text"
                  size="small"
                >
                  <DefaultTypography>Cancel</DefaultTypography>
                </OutlinedButton>

                <Button
                  style={{
                    variant: "outlined",
                    borderColor: COLOR.ORANGE,
                    borderRadius: "4px",
                    padding: "6px",
                    justifyContent: "center",
                    border: "1px solid #DC4F34",
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    fontStyle: "normal",
                    textTransform: "none",
                    boxShadow:
                      "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
                    ":hover": {},
                  }}
                  onClick={handleBackCall}
                >
                  <DefaultTypography style={{ color: COLOR.ORANGE }}>
                    Back
                  </DefaultTypography>
                </Button>

                <Button
                  style={{
                    color: "white",
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                  }}
                  variant="contained"
                  color="orangeTheme"
                  disableElevation
                  onClick={() => onNext()}
                >
                  Next
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default CohortByImport_Step2;
