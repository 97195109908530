import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import cinq_1 from "../../assets/cinq_1.png";
import { Styles } from "../../Styles";
import { axiosRequests } from "../../app/api/AxiosRequest";
import Loader from "../../component/common/loader/Loader";
import SearchCodes from "./SearchCodes";

const NewRecaptureCodeModal = ({
  isOpen,
  onClose,
  sourceGrid = "recaptureCode",
  memberDetail,
  payerName,
  forceUpdate,
  setForceUpdate,
  priority = undefined,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [icd10Codes, setIcd10Codes] = useState([]);
  const [icd10CodesError, setIcd10CodesError] = useState("");
  useEffect(() => {
    if (isOpen) {
      setIcd10Codes([]);
      setIcd10CodesError("");
    }
  }, [isOpen]);

  useEffect(() => {
    setIcd10CodesError("");
  }, [icd10Codes]);

  const filterOptions = (options, state) => {
    return options.filter(
      (option) =>
        option.code.toLowerCase().includes(state.inputValue.toLowerCase()) ||
        option.definition.toLowerCase().includes(state.inputValue.toLowerCase())
    );
  };

  const handleSave = () => {
    let hasError = false;
    if (icd10Codes.length === 0) {
      setIcd10CodesError("Please select atleast one code");
      hasError = true;
    }
    if (hasError) return;
    let requestBody = {
      ourId: memberDetail.ourId,
      payorName: payerName,
      icd10Codes: icd10Codes.join(","),
      priority: priority,
    };
    const apiUrl =
      sourceGrid === "sdohGrid"
        ? "/ActiveGapClosure/AddNewCodesForSDOHCodingSuspect"
        : "/ActiveGapClosure/AddNewCodesForMedicalCodingSuspect";
    axiosRequests.post(apiUrl, requestBody).then((resp) => {
      if (resp) {
        setForceUpdate(forceUpdate + 1);
        onClose();
      }
    });
  };
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      slotProps={{ backdrop: { onClick: null } }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "678px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          pt: 1,
          height: "auto",
        }}
      >
        <h3
          style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
        >
          {sourceGrid === "sdohGrid"
            ? "Add New SDOH Codes"
            : "Add New ICD10 Codes"}
          <span>
            <CloseIcon onClick={onClose} style={{ float: "right" }} />
          </span>
        </h3>
        <hr
          style={{
            width: "auto",
            opacity: "0.3",
            marginTop: "-8px",
            // marginBottom: "-4px",
          }}
        />
        {isLoading ? (
          <Loader isLoading={isLoading}></Loader>
        ) : (
          <Stack direction="column" spacing={2}>
            <img
              style={{
                width: "129px",
                height: "29px",
                margin: "0 auto",
                display: "block",
              }}
              src={cinq_1}
            ></img>
            <Typography>
              Are you sure you want to add new{" "}
              {sourceGrid === "sdohGrid" ? "SDOH" : "ICD10"} codes?
            </Typography>
            <SearchCodes
              memberDetail={memberDetail}
              payerName={payerName}
              setCodesArray={setIcd10Codes}
              source={sourceGrid}
            />
            {icd10CodesError && (
              <FormHelperText style={{ color: "#DC4F34" }}>
                {" "}
                {icd10CodesError}
              </FormHelperText>
            )}
            <hr
              style={{
                width: "auto",
                opacity: "0.3",
                //marginTop: "-8px",
                // marginBottom: "-4px",
              }}
            />
            <Stack direction="row" spacing={1} style={{ marginLeft: "auto" }}>
              <Button
                type="text"
                variant="text"
                color="blackTheme"
                style={{ textTransform: "none" }}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                type="text"
                variant="contained"
                color="orangeTheme"
                style={{ textTransform: "none", color: "#fff" }}
                onClick={handleSave}
              >
                Add
              </Button>
            </Stack>
          </Stack>
        )}
      </Box>
    </Modal>
  );
};

export default NewRecaptureCodeModal;
