import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import agent from "../../app/api/Agent";
import agent from "../../../app/api/Agent";

const initialState = {
  isLoading: false,
  roleMappingData: [],
  errorMessage: "",
};

export const getRoleMappingList = createAsyncThunk(
  "roleMappingData",
  async () => {
    const response =
      await agent.RoleMappingDataAgent.getRoleMappingList();
    return response;
  }
);

const getRoleMappingListSlice = createSlice({
  name: "roleMappingData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoleMappingList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRoleMappingList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.roleMappingData = action.payload;
      })
      .addCase(getRoleMappingList.rejected, (state, action) => {
        state.isLoading = false;
        state.roleMappingData = [];
        state.errorMessage = action.error.message;
      });
  },
});

export default getRoleMappingListSlice.reducer;
