import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Styles } from "../../Styles";
import CalendarClock from "../../assets/calendar-clock.svg";
import { format } from "date-fns";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { PagesPath } from "../../PagesPath";
import { Link } from "react-router-dom";
import { useState } from "react";
import DeleteEventModal from "./DeleteEventModal";
import { axiosRequests } from "../../app/api/AxiosRequest";

const DetailTitle = styled(Typography)({
  overflow: "hidden",
  color: "#DC4F34",
  textOverflow: "ellipsis",
  fontFamily: "Poppins",
  fontSize: "20px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  letterSpacing: "0.15px",
});
const DetailDescription = styled(Typography)({
  color: "#000",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "normal",
  letterSpacing: "0.014px",
  marginTop: "8px",
});
const EventTime = styled(Typography)({
  color: "#000",

  /* overline */
  fontFamily: "Poppins",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
  letterSpacing: "0.12px",
});

const EventDetailsModal = ({
  isOpen,
  onClose,
  eventDetails,
  disableAddEvent,
  setIsDeleted,
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const startDate = format(eventDetails.startDate, "dd MMM, yyyy");
  const endDate = format(eventDetails.endDate, "dd MMM, yyyy");
  const startTime = format(eventDetails.startDate, "h:mmaaa").toLowerCase();
  const endTime = format(eventDetails.endDate, "h:mmaaa").toLowerCase();
  const handleDeleteClick = () => {
    setDeleteModalOpen(true);
  };
  const handleDeleteModalClose = () => {
    setDeleteModalOpen(false);
    onClose();
  };
  const handleOpenEventClick = () => {
    axiosRequests.post(`/EducationProgram/UpdateActivityTracker?ActivityType=Events&ActivityID=${eventDetails.id}`).then(resp => {
      console.log("Updated Succesfully");
    })
  }
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      style={{ overflow: "initial" }}
      disableScrollLock
      slotProps={{ backdrop: { onClick: null } }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "678px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          pt: 1,
          height: "auto",
        }}
      >
        <h3
          style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
        >
          Event Details
          <span>
            <CloseIcon onClick={onClose} style={{ float: "right" }} />
          </span>
        </h3>
        <hr
          style={{
            width: "auto",
            opacity: "0.3",
            marginTop: "-10px",
            marginBottom: "14px",
          }}
        />
        <Stack direction="row">
          <DetailTitle>{eventDetails.title}</DetailTitle>
          <Stack
            direction="row"
            style={{
              marginLeft: "auto",
              display: disableAddEvent ? "none" : "",
            }}
            spacing={1}
          >
            <span>
              <Link
                to={PagesPath.AddEvent}
                state={{
                  id: eventDetails.id,
                  title: eventDetails.title,
                  description: eventDetails.description,
                  url: eventDetails.url,
                  startDate: new Date(eventDetails.startDate),
                  endDate: new Date(eventDetails.endDate),
                }}
                key="EditEvent"
                style={{
                  textDecoration: "none",
                  underline: "hover",
                  color: "inherit",
                  marginRight: "8px",
                }}
              >
                <EditIcon></EditIcon>
              </Link>
            </span>
            <span>
              <Link
                key="DeleteUser"
                style={{
                  textDecoration: "none",
                  underline: "hover",
                  color: "inherit",
                  marginRight: "8px",
                }}
              >
                <DeleteIcon onClick={handleDeleteClick}></DeleteIcon>
              </Link>
            </span>
          </Stack>
        </Stack>

        <DetailDescription>{eventDetails.description}</DetailDescription>

        <Button
          color="blackTheme"
          variant="text"
          disableTouchRipple
          style={{ cursor: "default", paddingLeft: "0px" }}
          disableRipple
        >
          <img src={CalendarClock}></img>
          <span style={{ marginLeft: "8px", marginRight: "24px" }}>
            <b>From: </b>
            {startDate} | {startTime}
          </span>
          <img src={CalendarClock}></img>
          <span style={{ marginLeft: "8px" }}>
            <b>To: </b>
            {endDate} | {endTime}
          </span>
        </Button>
        <Stack
          direction="row"
          spacing={1}
          style={{ marginTop: "8px", justifyContent: "flex-end" }}
        >
          <Button
            style={{
              borderColor: "black",
              color: "black",
              fontFamily: Styles.FONT_FAMILY_POPPINS,
              marginLeft: "23px",
              textTransform: "none",
            }}
            variant="text"
            onClick={onClose}
          >
            Close
          </Button>
          <a href={eventDetails.url} target="_blank">
            <Button
              style={{
                color: "white",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
                textTransform: "none",
              }}
              variant="contained"
              color="orangeTheme"
              onClick={handleOpenEventClick}
              disableElevation
            >
              Enroll For Event
            </Button>
          </a>
        </Stack>
        <DeleteEventModal
          isOpen={deleteModalOpen}
          onClose={handleDeleteModalClose}
          deleteData={eventDetails}
          setIsDeleted={setIsDeleted}
        />
      </Box>
    </Modal>
  );
};

export default EventDetailsModal;
