import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../../../app/api/Agent";

const initialState = {
  isListLoading: false,
  isLoading:false,
  sdohMemberList: [],
  memberDetails:{},
  checklist:[],
  errorMessage: "",
  raceList:[],
  ethnicityList:[],
  languageList:[],
};

export const getMembersList = createAsyncThunk(
  "sdohMemberList",
  async (formData) => {
    const response =
      await agent.SDOHAssessmentTrackingAgent.getMembersList(formData);
    return response;
  }
);

export const getMemberDetails = createAsyncThunk(
  "sdohMemberDetails",
  async ({market,submarket,practice,memberId}) => {
    const response =
      await agent.SDOHAssessmentTrackingAgent.getMemberDetails(market,submarket,practice,memberId);
    return response;
  }
);

export const getCheckList = createAsyncThunk(
  "sdohCheckList",
  async () => {
    const response =
      await agent.SDOHAssessmentTrackingAgent.getCheckList();
    return response;
  }
);

export const getRaceList = createAsyncThunk(
  "sdohRaceList",
  async () => {
    const response =
      await agent.SDOHAssessmentTrackingAgent.getRaceList();
    return response;
  }
);

export const getEthnicityList = createAsyncThunk(
  "sdohEthnicityList",
  async () => {
    const response =
      await agent.SDOHAssessmentTrackingAgent.getEthnicityList();
    return response;
  }
);


export const getLanguageList = createAsyncThunk(
  "sdohLanguageList",
  async () => {
    const response =
      await agent.SDOHAssessmentTrackingAgent.getLanguageList();
    return response;
  }
);

const sdohMemberListSlice = createSlice({
  name: "sdohMemberList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMembersList.pending, (state) => {
        state.isListLoading = true;
      })
      .addCase(getMembersList.fulfilled, (state, action) => {
        state.isListLoading = false;
        state.sdohMemberList = action.payload;
        state.errorMessage="";
      })
      .addCase(getMembersList.rejected, (state, action) => {
        state.isListLoading = false;
        state.sdohMemberList = [];
        state.errorMessage = action.error.message;
      })

      .addCase(getMemberDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMemberDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.memberDetails = action.payload;
        state.errorMessage="";
      })
      .addCase(getMemberDetails.rejected, (state, action) => {
        state.isLoading = false;
        state.memberDetails = {};
        state.errorMessage = action.error.message;
      })

      
      .addCase(getCheckList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCheckList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.checklist = action.payload;
        state.errorMessage="";
      })
      .addCase(getCheckList.rejected, (state, action) => {
        state.isLoading = false;
        state.checklist = [];
        state.errorMessage = action.error.message;
      })

      .addCase(getRaceList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRaceList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.raceList = action.payload;
        state.errorMessage="";
      })
      .addCase(getRaceList.rejected, (state, action) => {
        state.isLoading = false;
        state.raceList = [];
        state.errorMessage = action.error.message;
      })

      .addCase(getEthnicityList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getEthnicityList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.ethnicityList = action.payload;
        state.errorMessage="";
      })
      .addCase(getEthnicityList.rejected, (state, action) => {
        state.isLoading = false;
        state.ethnicityList = [];
        state.errorMessage = action.error.message;
      })

      .addCase(getLanguageList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getLanguageList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.languageList = action.payload;
        state.errorMessage="";
      })
      .addCase(getLanguageList.rejected, (state, action) => {
        state.isLoading = false;
        state.languageList = [];
        state.errorMessage = action.error.message;
      })

  },
});

export default sdohMemberListSlice.reducer;
