import React from "react";
import PageHeader from "../../component/common/header/PageHeader";

const EducationConfigurationHeader = () => {
  const title = "";
  const sevenPillarTitle = "Education Configuration";
  return (
    <>
      <PageHeader disablePracticeAdminLink={true} title={title} sevenPillarTitle={sevenPillarTitle} />
    </>
  );
};

export default EducationConfigurationHeader;
