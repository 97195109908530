import React, { useEffect, useLayoutEffect, useState } from "react";
import Header from "./header/Header";
import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { Link, NavLink, Outlet } from "react-router-dom";
import MiniDrawer from "./miniDrawer/MiniDrawer";
import MiniDrawerAdmin from "./miniDrawer/MiniDrawerAdmin";
import NoAccessPage from "./noAccess/NoAccessPage";
import Loader from "./loader/Loader";
import MiniDrawerEducation from "./miniDrawer/MiniDrawerEducation";

const SevenPillarEducationLayout = () => {
  const [noAccess, setNoAccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  useLayoutEffect(() => {
    var noAccess = localStorage.getItem("noAccess");
    if (parseInt(noAccess) === 1) {
      setNoAccess(true);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  }, []);
  return (
    <>
      {isLoading ? (
        <Loader isLoading={isLoading}/>
      ) : (
        <div>
          {noAccess ? (
            <NoAccessPage></NoAccessPage>
          ) : (
            <div>
              <MiniDrawerEducation />
              <Box
                component="main"
                className="content"
                sx={{
                  background: "#FAFAFA 0% 0% no-repeat padding-box",
                  opacity: 1,
                  width: "100%",
                  flexGrow: 1,
                  paddingLeft: "64px",
                }}
              >
                <Toolbar sx={{ minHeight: "30px" }} />
                <Box sx={{
                  p:1.5}}>
                        <Outlet></Outlet>
                </Box>
              </Box>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SevenPillarEducationLayout;
