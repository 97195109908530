import { axiosRequests } from "../AxiosRequest";

function getUsersList() {
  
  return axiosRequests.get("/UserManagementDetail/GetUserManagementDetails");
  // return axiosRequests.get("/Members");
}

export const UserDataAgent = {
  getUsersList: getUsersList,
};