import { Box, Grid } from "@mui/material";
import { format } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/store";
import CustomPaginationSlot from "../../component/common/datagrid/CustomPaginationSlot";
import DataGridCustom from "../../component/common/datagrid/DataGridCustom";
import Loader from "../../component/common/loader/Loader";
import {
  FamilyMemberProfilePillarReturn,
  PerformanceURL,
} from "../../webConfig";
import FamilyMemberProfileHeader from "./FamilyMemberProfileHeader";
import { getFamilyMemberProfileList } from "./familyMembersProfileSlice";

const FamilyMemberProfile = () => {
  const columns = [
    {
      field: "displayName",
      headerName: "Member Name",
      flex: 1,
      sortDirection: "desc",
      renderCell: (row) => {
        var hrefUrl = `${PerformanceURL}Member/Profile/${row.row.ourID}${familyReturnUrl}`;
        return (
          <a
            href={hrefUrl}
            style={{ underline: "hover", color: "inherit", whiteSpace: "pre" }}
            // target="_blank"
          >
            {row.row.displayName}
          </a>
        );
      },
    },
    {
      field: "phone",
      headerName: "Phone",
      sortable: false,
      flex: 1,
    },
    {
      field: "dateOfBirth",
      headerName: "Date Of Birth",
      type: "Date",
      flex: 1,
      sortable: false,
      valueGetter: (params) =>
        params.row.dateOfBirth &&
        format(new Date(params.row.dateOfBirth), "dd MMM yyyy"),
    },
    {
      field: "profileId",
      headerName: "Profile Id",
      flex: 1,
      sortable: false,
    },
  ];
  const navigate = useNavigate();
  const familyReturnUrl = FamilyMemberProfilePillarReturn;
  const dispatch = useAppDispatch();
  const [page, setPage] = useState(1);
  const [initialLoad, setInitialLoad] = useState(true);
  const [sortModel, setSortModel] = useState([
    { field: "displayName", sort: "asc" },
  ]);
  const [selectedRow, setRow] = useState({
    name: "",
    ourID: "",
  });
  const { isLoading, familyMemberList, familyMemberListCount, errorMessage } =
    useSelector((state) => state.familyMembersProfile);

  const searchValue = useSelector(
    (state) => state.familyMembersProfileSearch.searchValue
  );

  const getFamilyMemberProfileListData = useCallback(
    async (sortColumn, sortOrder, page) => {
      if (!sortColumn || !sortOrder) {
        sortModel[0].field = "displayName";
        sortModel[0].sort = "asc";
        setSortModel(sortModel);
      }

      const response = await dispatch(
        getFamilyMemberProfileList({ searchValue, sortColumn, sortOrder, page })
      );
    },
    [dispatch, searchValue]
  );

  useEffect(() => {
    getFamilyMemberProfileListData();
  }, [getFamilyMemberProfileListData]);

  useEffect(() => {
    setPage(1);
  }, [searchValue]);

  function getRowId(row) {
    return row.ourID + "_" + row.profileId;
  }

  const handlePageChange = (page) => {
    getFamilyMemberProfileListData(sortModel[0].field, sortModel[0].sort, page);
  };

  useEffect(() => {
    if (!initialLoad) {
      handlePageChange(page);
    } else {
      setInitialLoad(false);
    }

    // handlePageChange(page);
  }, [page]);

  const handleSortModelChange = (sortModel) => {
    let sort = null;
    if (sortModel.length > 0) {
      const { field, sort: sortOrder } = sortModel[0];
      sort = `${field}:${sortOrder}`;
      if (sortOrder === "asc") {
        sort = `${field}:desc`;
      }
    }
    setSortModel(sortModel);
    setPage(1);
    getFamilyMemberProfileListData(sortModel[0].field, sortModel[0].sort);
  };

  return (
    <Box sx={{ width: "100%", p: { xs: 2, sm: 2 } }}>
      <FamilyMemberProfileHeader />
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <Grid item>
          <DataGridCustom
            sortingOrder={["asc", "desc"]}
            columns={columns}
            sortingMode="server"
            rows={familyMemberList}
            sortModel={sortModel}
            getRowId={getRowId}
            loading={isLoading}
            onSortModelChange={(e) => handleSortModelChange(e)}
            slots={{
              pagination: CustomPaginationSlot,
              // props:{setPage: setPage}
            }}
            slotProps={{
              pagination: {
                setPage: setPage,
                paginationModel: {
                  count: Math.ceil(familyMemberListCount / 10),
                  page: page,
                  totalRows: familyMemberListCount,
                },
                colorTheme: "orangeTheme",
                className: "pageNumber",
              },
            }}
          />
        </Grid>
      )}
    </Box>
  );
};

export default FamilyMemberProfile;
