import React, { useState } from "react";
// import { Box } from "@mui/material";
import { Button, Grid } from "@mui/material";
import DataGridCustom from "../../component/common/datagrid/DataGridCustom";
import Fab from '@mui/material/Fab';
import { Styles } from "../../Styles";
import AddIcon from "@mui/icons-material/Add";
import UsersTable from "./Users/UsersTable";
import AddUserForm from "./Users/AddUserForm";

const Users = () => {
    const [addUserFormDisplay, setAddUserFormDisplay] = React.useState(false);

    return (
        <>
            {
                
                <UsersTable setAddUserFormDisplay={setAddUserFormDisplay}></UsersTable> 
            }

        </>
    )

}

export default Users;