import { axiosRequests } from "../AxiosRequest";

function getUserRolesList() {
  
  return axiosRequests.get("/RoleManagementDetail/GetRoleManagementDetails");
  // return axiosRequests.get("/Members");
}

export const UserRolesDataAgent = {
  getUserRolesList: getUserRolesList,
};