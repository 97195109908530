import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/Agent";

const initialState = {
  isLoading: false,
  pillars: [],
  errorMessage: "",
  isSevenPillarListLoading: false,
  sevenPillars:[],
  sevenPillarErrorMsg:""
};

export const getPillarsToolsScorecardsList = createAsyncThunk(
  "pillarsToolsScorecardsList",
  async () => {
    const response =
      await agent.PillarsToolsScorecardsAgent.getPillarsToolsScorecards();
    return response;
  }
);

export const getSevenPillarsList = createAsyncThunk(
  "sevenPillarsList",
  async () => {
    const response =
      await agent.PillarsToolsScorecardsAgent.getSevenPillarsList();
    return response;
  }
);

const pillarsToolsScorecardsSlice = createSlice({
  name: "pillarsToolsScorecardsList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPillarsToolsScorecardsList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getPillarsToolsScorecardsList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.pillars = action.payload;
      })
      .addCase(getPillarsToolsScorecardsList.rejected, (state, action) => {
        state.isLoading = false;
        state.pillars = [];
        state.errorMessage = action.error.message;
      })

      .addCase(getSevenPillarsList.pending, (state) => {
        state.isSevenPillarListLoading = true;
      })
      .addCase(getSevenPillarsList.fulfilled, (state, action) => {
        state.isSevenPillarListLoading = false;
        state.sevenPillars = action.payload;
      })
      .addCase(getSevenPillarsList.rejected, (state, action) => {
        state.isSevenPillarListLoading = false;
        state.sevenPillars = [];
        state.sevenPillarErrorMsg = action.error.message;
      });
  },
});

export default pillarsToolsScorecardsSlice.reducer;
