import { axiosRequests } from "../AxiosRequest";

function getTrainingProgramsList() {
  return axiosRequests.get("/TrainingProgram/GetTrainingProgramDetails");
}

function getTrainingDocumentsList() {
  return axiosRequests.get("/TrainingDocument/GetTrainingDocumentDetails");
}

export const HealthHomeEducationAgent = {
  getTrainingProgramsList: getTrainingProgramsList,
  getTrainingDocumentsList: getTrainingDocumentsList,
};
