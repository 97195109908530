import { Chip, styled } from "@mui/material";
import React from "react";
import { COLOR, Styles } from "../../../Styles";

const StyledMEChip = styled(Chip)({
  fontFamily: Styles.FONT_FAMILY_POPPINS,
  fontSize: "16px",
  marginTop: "auto",
  marginBottom: "auto",
  marginLeft: "5px",
  // color:"white",
  background: "#D9D9D9"

});
const SChip = ({ color = "orangeTheme", label, variant = "contained", className=undefined }) => {
  return (
    <StyledMEChip color={color} label={label} variant={variant} className={className}></StyledMEChip>
  );
};

export default SChip;