import {Pagination, Typography} from '@mui/material';
import React, { useCallback, useEffect, useState } from "react";

const CustomPaginationSlot = ({paginationModel,setPage, colorTheme, className})=>{
    // var finalRecord = 10;
    var totalRecords = paginationModel.totalRows;
    const [initialRecord, setInitialRecord]= useState(1);
    const [finalRecord, setFinalRecord]= useState(10);
    const handlePageRecords = (value) => {
        setInitialRecord((value-1)*10 +1);

        setFinalRecord((value*10<totalRecords)?value*10:totalRecords);
    }
    return (
        <>
        <Pagination count={paginationModel.count}
        page={paginationModel.page}
        color={colorTheme}
        className={className}
        onChange={(event,value)=> {
            handlePageRecords(value);
            setPage(value);
        }}
        />
        </>

    );
};

export default CustomPaginationSlot

