import React from "react";
import DataGridHeader from "../../../component/common/datagrid/DataGridHeader";
import { Styles } from "../../../Styles";
import { Chip, Stack, styled, Typography } from "@mui/material";
// import "./PDFExportCSS.css";
import "./PDFExportCss.css";
import { format } from "date-fns";
import DataGridCustom from "../../../component/common/datagrid/DataGridCustom";

const MultilineText = styled(Typography)({
  display: "-webkit-box",
  "-webkit-line-clamp": "2",
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "normal",
});

const SDOHCodesPDF = ({ auditStatusId, memberDetail }) => {
  const columnsSDOHCodingSuspectsTotalOpen = [
    {
      field: "sdohCode",
      headerName: "SDOH Code",
      width: 180,
    },
    {
      field: "description",
      headerName: "Description",
      width: 180,
      renderCell: (params) => {
        return <MultilineText>{params.row.description}</MultilineText>;
      },
    },
    {
      field: "status",
      headerName: "Code Status",
      width: 180,
    },
    {
      field: "comments",
      headerName: "Comments",
      width: 180,
      renderCell: (params) => {
        return <MultilineText>{params.row.comments}</MultilineText>;
      },
    },
    {
      field: "activeConditionDiagnosed",
      headerClassName: "classMultilineHeader",
      headerName: "SDOH Needs Identified",
      width: 180,
    },
    {
      field: "visitDate",
      headerName: "Visit Date",
      width: 180,
      renderCell: (params) => {
        if (params.row.visitDate) {
          return <span>{format(params.row.visitDate, "MM/dd/yyyy")}</span>;
        }
      },
    },
  ];
  const columnsSDOHCodingSuspectsAuditAccept = [
    {
      field: "sdohCode",
      headerName: "SDOH Code",
      width: 100,
    },
    {
      field: "description",
      headerName: "Description",
      width: 500,
      renderCell: (params) => {
        return <MultilineText>{params.row.description}</MultilineText>;
      },
    },
    {
      field: "status",
      headerName: "Code Status",
      width: 100,
    },
    {
      field: "visitDate",
      headerName: "Visit Date",
      width: 100,
      renderCell: (params) => {
        if (params.row.visitDate) {
          return <span>{format(params.row.visitDate, "MM/dd/yyyy")}</span>;
        }
      },
    },
    {
      field: "comments",
      headerName: "Comments",
      width: 500,
      renderCell: (params) => {
        return <MultilineText>{params.row.comments}</MultilineText>;
      },
    },
  ];
  const columnsSDOHCodingSuspectsAuditPending = [
    {
      field: "sdohCode",
      headerName: "SDOH Code",
      width: 100
    },
    {
      field: "description",
      headerName: "Description",
      width: 500,
      renderCell: (params) => {
        return <MultilineText>{params.row.description}</MultilineText>;
      },
    },
    {
      field: "status",
      headerName: "Code Status",
      width: 100
    },
    {
      field: "visitDate",
      headerName: "Visit Date",
      width: 100,
      renderCell: (params) => {
        if (params.row.visitDate) {
          return <span>{format(params.row.visitDate, "MM/dd/yyyy")}</span>;
        }
      },
    },
    {
      field: "comments",
      headerName: "Comments",
      width: 500,
      renderCell: (params) => {
        return <MultilineText>{params.row.comments}</MultilineText>;
      },
    },
  ];
  const columnsSDOHCodingSuspectsAuditReject = [
    {
      field: "sdohCode",
      headerName: "SDOH Code",
      width: 100
    },
    {
      field: "description",
      headerName: "Description",
      width: 500,
      renderCell: (params) => {
        return <MultilineText>{params.row.description}</MultilineText>;
      },
    },
    {
      field: "status",
      headerName: "Code Status",
      width: 100
    },
    {
      field: "visitDate",
      headerName: "Visit Date",
      width: 100,
      renderCell: (params) => {
        if (params.row.visitDate) {
          return <span>{format(params.row.visitDate, "MM/dd/yyyy")}</span>;
        }
      },
    },
    {
      field: "comments",
      headerName: "Comments",
      width: 500,
      renderCell: (params) => {
        return <MultilineText>{params.row.comments}</MultilineText>;
      },
    },
  ];
  return (
    <div style={{ marginTop: "16px", display: "grid" }} className="page-break">
      {
        <DataGridCustom
          columns={
            auditStatusId === 0
              ? columnsSDOHCodingSuspectsTotalOpen
              : auditStatusId === 1
              ? columnsSDOHCodingSuspectsAuditAccept
              : auditStatusId === 2
              ? columnsSDOHCodingSuspectsAuditPending
              : columnsSDOHCodingSuspectsAuditReject
          }
          rows={memberDetail.sdohCodingSuspects}
          pageSize={100}
          hideFooter
          //getRowId={getRowId}
          loading={false}
          sortingOrder={["asc", "desc"]}
          slots={{
            toolbar: DataGridHeader,
          }}
          slotProps={{
            toolbar: {
              headerText: "",
              fontSize: "",
              marginTop: "",
              marginBottom: "",
              height: "56px",
              children: (
                // <Stack direction="row" spacing={2}>
                <div
                  style={{
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    fontSize: "16px",
                    width: "100%",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginLeft: "5px",
                  }}
                >
                  <Stack direction="row" spacing={1}>
                    <Typography
                      style={{
                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                        fontSize: "16px",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      SDOH Recapture Codes
                    </Typography>
                    <Chip
                      color="orangeTheme"
                      variant="contained"
                      style={{ color: "#fff" }}
                      label={memberDetail.sdohCodingSuspects.length}
                    ></Chip>
                  </Stack>
                </div>

                // </Stack>
              ),
            },
          }}
        />
      }
    </div>
  );
};

export default SDOHCodesPDF;
