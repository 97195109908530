import React from "react";
import { Chip, Grid, makeStyles, Stack, styled, Typography } from "@mui/material";
import PDFHeader from "./PDFHeader";
import MemberDemographicData from "../MemberDemographicData";
import DataGridCustom from "../../../component/common/datagrid/DataGridCustom";
import DataGridHeader from "../../../component/common/datagrid/DataGridHeader";
import { format } from "date-fns";
import RecaptureCodesPDF from "./RecaptureCodesPDF";
import { Styles } from "../../../Styles";
import SDOHCodesPDF from "./SDOHCodesPDF";

const StyledPDFCanvas = styled(Grid)({
  width: "auto",
  height: "auto",
});

export const MultilineText = styled(Typography)({
  display: "-webkit-box",
  "-webkit-line-clamp": "2",
  "-webkit-box-orient": "vertical",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "normal",
});
const MemberDetailsPDF = ({ memberDetail, auditStatusId, priority = undefined }) => {
  const columnsOpenQualityMeasures = [
    {
      field: "measureTitle",
      headerName: "Measure Title",
      width: 400,
    },
    {
      field: "description",
      headerName: "Description",
      width: 400,
      renderCell: (params) => {
        return <MultilineText>{params.row.description}</MultilineText>;
      },
    },
    {
      field: "visitDate",
      headerName: "Visit Date",
      width: 400,
      renderCell: (params) => {
        if (params.row.visitDate) {
          return <span>{format(params.row.visitDate, "MM/dd/yyyy")}</span>;
        }
      },
    },
  ];
  return (
    <StyledPDFCanvas id="pdf-data" style={{ padding: "16px", width: "100%" }}>
      <PDFHeader />
      <MemberDemographicData
        memberDetail={memberDetail}
        hideUserActions={true}
        priority={priority}
      />
      {memberDetail.openQualityMeasures.length > 0 && (
        <div
        className="page-break"
          style={{
            display: "grid",
            marginTop: "16px",
            width: "auto",
          }}
        >
          <DataGridCustom
            hideFooter={true}
            columns={columnsOpenQualityMeasures}
            rows={memberDetail.openQualityMeasures}
            pageSize={100}
            loading={false}
            slots={{
              toolbar: DataGridHeader,
            }}
            slotProps={{
              toolbar: {
                headerText: "",
                fontSize: "",
                marginTop: "",
                marginBottom: "",
                height: "56px",
                children: (
                  <div
                    style={{
                      fontFamily: Styles.FONT_FAMILY_POPPINS,
                      fontSize: "16px",
                      width: "100%",
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginLeft: "5px",
                    }}
                  >
                    <Stack direction="row" spacing={1}>
                      <Typography
                        style={{
                          fontFamily: Styles.FONT_FAMILY_POPPINS,
                          fontSize: "16px",
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      >
                        Open Quality Measures
                      </Typography>
                      <Chip
                        color="orangeTheme"
                        variant="contained"
                        style={{ color: "#fff" }}
                        label={memberDetail.openQualityMeasures.length}
                      ></Chip>
                    </Stack>
                  </div>
                ),
              },
            }}
          />
        </div>
      )}
      {memberDetail.medicalCodingSuspects.length > 0 && (
        <RecaptureCodesPDF
          auditStatusId={auditStatusId}
          memberDetail={memberDetail}
        />
      )}
      {memberDetail.sdohCodingSuspects.length > 0 && (
        <SDOHCodesPDF
          auditStatusId={auditStatusId}
          memberDetail={memberDetail}
        />
      )}
      {/* {memberDetail.sdohCodingSuspects.length > 0 && (
        <div style={{ display: "grid", height: 426, marginTop: "16px" }}>
          <DataGridCustom
            hideFooter={true}
            columns={columnsSDOHRecaptureCodes}
            rows={memberDetail.sdohCodingSuspects}
            pageSize={100}
            loading={false}
            slots={{
              toolbar: DataGridHeader,
            }}
            slotProps={{
              toolbar: {
                headerText: "SDOH Coding Suspects",
                fontSize: "16px",
                marginTop: "auto",
                marginBottom: "auto",
                height: "56px",
              },
            }}
          />
        </div>
      )} */}
    </StyledPDFCanvas>
  );
};

export default MemberDetailsPDF;
