import React, { useEffect, useState } from "react";
import agent from "../../app/api/Agent";
import {
  Box,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import TrainingProgramsHeader from "../HealthHomeTrainingPrograms/TrainingProgramsHeader";
import Loader from "../../component/common/loader/Loader";
import { Link } from "react-router-dom";
import HealthHomeDocumentNameCard from "./HealthHomeDocumentNameCard";
import { ConstructionOutlined } from "@mui/icons-material";
import { axiosRequests } from "../../app/api/AxiosRequest";

const handleScrollToSection = (letter) => {
  document.getElementById(letter).scrollIntoView({ behavior: "smooth" });
};

const StyledLink = styled(Link)({
  cursor: "pointer",
});

const HealthHomeTrainingDocuments = () => {
  const [documentData, setDocumentData] = useState([]);
  const [filteredDocumentData, setFilteredDocumentData] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedSections, setSelectedSections] = useState([]);
  const [selectedSubSections, setSelectedSubSections] = useState([]);
  const [sectionSubSectionData, setSectionSubSectionData] = useState([]);
  const [allSubSections, setAllSubSections] = useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const allSections = sectionSubSectionData.map((item) => item.title);

  useEffect(() => {
    agent.HealthHomeEducationAgent.getTrainingDocumentsList().then((resp) => {
      setDocumentData(resp);
    });
    axiosRequests.get("/TrainingDocument/GetAllSections").then((resp) => {
      setSectionSubSectionData(resp);
    });
  }, []);

  useEffect(() => {
    if (selectedSections.length === 0) {
      setSelectedSubSections([]);
      setAllSubSections([]);
    }
  }, [selectedSections]);

  const handleSectionChange = (event) => {
    const value = event.target.value;
    setSelectedSections(
      value.includes("selectAll")
        ? selectedSections.length === allSections.length
          ? []
          : allSections
        : value
    );

    const subSections = sectionSubSectionData
      .filter(
        (section) =>
          value.includes(section.title) || value.includes("selectAll")
      )
      .flatMap((section) => section.subSections);
    var subSectionData = subSections.filter((item) => item.id > 0);
    //subSections.filter((item) => item.id > 0);
    console.log("Sub sections are ", subSectionData);
    setAllSubSections(subSectionData);
    var subSectionTitles = subSectionData.map((item) => item.title);
    var selectedSubSectionTitles = selectedSubSections.filter((item) =>
      subSectionTitles.includes(item)
    );
    setSelectedSubSections(selectedSubSectionTitles);
  };
  const handleSubSectionChange = (event) => {
    const value = event.target.value;
    setSelectedSubSections(
      value.includes("selectAll")
        ? selectedSubSections.length === allSubSections.length
          ? []
          : allSubSections.map((sub) => sub.title)
        : value
    );
  };

  // useEffect(() => {
  //   if (documentData) {
  //     var sections = documentData
  //       .map((item) => item.section)
  //       .filter(function (item, pos, self) {
  //         return self.indexOf(item) == pos;
  //       });
  //     var subSections = documentData
  //       .map((item) => item.subSection)
  //       .filter(function (item, pos, self) {
  //         return self.indexOf(item) == pos;
  //       });
  //     setSectionsArray(sections);
  //     setSubSectionsArray(subSections);
  //   }
  // }, [documentData]);

  useEffect(() => {
    var group = filteredData.reduce((acc, item) => {
      const firstLetter = item.title[0].toUpperCase();
      if (!acc[firstLetter]) {
        acc[firstLetter] = [];
      }
      acc[firstLetter].push(item);
      return acc;
    }, {});
    setGroupedData(group);
    setIsLoading(false);
  }, [filteredData]);

  useEffect(() => {
    if (filteredDocumentData) {
      let filtered;
      if (searchValue) {
        filtered = filteredDocumentData.filter(
          (item) =>
            item.title.toLowerCase().includes(searchValue.toLowerCase()) ||
            (item.description &&
              item.description
                .toLowerCase()
                .includes(searchValue.toLowerCase()))
        );
        setFilteredData(filtered);
      } else {
        setFilteredData(filteredDocumentData);
      }
    }
  }, [searchValue, filteredDocumentData]);

  useEffect(() => {
    if (
      selectedSections.length > 0 &&
      selectedSubSections.length > 0 &&
      documentData.length > 0
    ) {
      let filtered;
      filtered = documentData.filter(
        (item) =>
          selectedSections.includes(item.section) &&
          selectedSubSections.includes(item.subSection)
      );
      setFilteredDocumentData(filtered);
    }
  }, [selectedSections, selectedSubSections, documentData]);

  return (
    <Box sx={{ width: "100%" }}>
      <TrainingProgramsHeader
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        title="Training Documents"
      />
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <>
          <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
            <FormControl
              sx={{
                maxWidth: "303px",
                minWidth: "303px",
                backgroundColor: "#fff",
                borderRadius: "6px",
              }}
              color="orangeTheme"
              size="small"
            >
              <InputLabel>Sections</InputLabel>
              <Select
                multiple
                value={selectedSections}
                onChange={handleSectionChange}
                input={<OutlinedInput label="Sections" />}
                renderValue={(selected) => `${selected.length} Selected`}
              >
                <MenuItem value="selectAll">
                  <Checkbox
                    color="orangeTheme"
                    checked={selectedSections.length === allSections.length}
                    indeterminate={
                      selectedSections.length > 0 &&
                      selectedSections.length < sectionSubSectionData.length
                    }
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>

                {sectionSubSectionData.map((section) => (
                  <MenuItem key={section.id} value={section.title}>
                    <Checkbox
                      color="orangeTheme"
                      checked={selectedSections.indexOf(section.title) > -1}
                    />
                    <ListItemText primary={section.title} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Subsections Dropdown */}
            <FormControl
              sx={{
                maxWidth: "303px",
                minWidth: "303px",
                backgroundColor: "#fff",
                borderRadius: "6px",
              }}
              color="orangeTheme"
              size="small"
            >
              <InputLabel>Subsections</InputLabel>
              <Select
                multiple
                value={selectedSubSections}
                disabled={allSubSections.length === 0}
                onChange={handleSubSectionChange}
                input={<OutlinedInput label="Subsections" />}
                renderValue={(selected) => `${selected.length} Selected`}
              >
                <MenuItem value="selectAll">
                  <Checkbox
                    color="orangeTheme"
                    indeterminate={
                      selectedSubSections.length > 0 &&
                      allSubSections.length > selectedSubSections.length
                    }
                    checked={
                      selectedSubSections.length === allSubSections.length
                    }
                  />
                  <ListItemText primary="Select All" />
                </MenuItem>

                {allSubSections.map(
                  (sub) =>
                    sub.id !== 0 && (
                      <MenuItem key={sub.id} value={sub.title}>
                        <Checkbox
                          color="orangeTheme"
                          checked={selectedSubSections.indexOf(sub.title) > -1}
                        />
                        <ListItemText primary={sub.title} />
                      </MenuItem>
                    )
                )}
              </Select>
            </FormControl>
          </Stack>
          {(selectedSections.length === 0 ||
            selectedSubSections.length === 0) && (
            <Typography>
              Please select atleast one section and sub section
            </Typography>
          )}
          {selectedSections.length > 0 &&
            selectedSubSections.length > 0 &&
            filteredDocumentData.length === 0 && (
              <Typography>
                No data available for the selected configuration.
              </Typography>
            )}
          {selectedSections.length > 0 &&
            selectedSubSections.length > 0 &&
            filteredDocumentData.length > 0 && (
              <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
                <Typography>All Documents Index</Typography>
                {Object.keys(groupedData)
                  .sort()
                  .map((letter) => (
                    <StyledLink
                      key={letter}
                      onClick={() => handleScrollToSection(letter)}
                    >
                      {letter}
                    </StyledLink>
                  ))}
              </Stack>
            )}

          {selectedSections.length > 0 &&
            selectedSubSections.length > 0 &&
            filteredDocumentData.length > 0 &&
            Object.keys(groupedData)
              .sort()
              .map((letter) => (
                <Stack
                  direction="column"
                  key={letter}
                  id={letter}
                  style={{ marginBottom: "40px" }}
                >
                  <Typography variant="h4" gutterBottom>
                    {letter}
                  </Typography>
                  <Grid container spacing={2}>
                    {groupedData[letter].map((item, index) => (
                      <Grid
                        item
                        xs="auto"
                        sm="auto"
                        md="auto"
                        lg="auto"
                        key={index}
                      >
                        <HealthHomeDocumentNameCard
                          key={index}
                          name={item.title}
                          description={item.description}
                          section={item.section}
                          id={item.id}
                          subSection={item.subSection}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Stack>
              ))}
        </>
      )}
    </Box>
  );
};

export default HealthHomeTrainingDocuments;
