import React from "react";
import PageHeader from "../../component/common/header/PageHeader";

const EventsHeader = ({disableHeader = false}) => {
  const title = "Events";
  const sevenPillarTitle = "Provider Education";
  return (
    <div style={{display:disableHeader?'none':''}}>
      <PageHeader disableEducation={true} title={title} sevenPillarTitle={sevenPillarTitle}>
        <div style={{ marginLeft: "auto", float: "right" }}>
        
        </div>
       
      </PageHeader>
    </div>
  );
};

export default EventsHeader;

