import { Box, Chip, Grid, Stack, Tooltip, Typography } from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { axiosRequests } from "../../app/api/AxiosRequest";
import { useEffect, useState } from "react";
import Loader from "../../component/common/loader/Loader";
import DataGridHeader from "../../component/common/datagrid/DataGridHeader";
import DataGridCustom from "../../component/common/datagrid/DataGridCustom";
import PharmacyDataModal from "./PharmacyDataModal";
import PrescribingProviderModal from "./PrescribingProviderModal";
import { format } from "date-fns";

const MedicalAdherenceMemberDetails = ({
  memberId,
  practiceName,
  risk,
  medAdherenceMeasure,
  backAction,
}) => {
  const [memberDetails, setMemberDetails] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [prescribingProviderData, setPrescribingProviderData] = useState({});
  const [prescribingProviderModalOpen, setPrescribingProviderModalOpen] =
    useState(false);
  const [pharmacyData, setPharmacyData] = useState({});
  const [pharmacyModalOpen, setPharmacyModalOpen] = useState(false);
  useEffect(() => {
    axiosRequests
      .get(
        `/MedicationAdherence/GetMedAdhMembersDetails?memberId=${memberId}&practiceName=${practiceName.replace('&','%26')}`
      )
      .then((resp) => {
        setMemberDetails(resp);
        setIsLoading(false);
      });
  }, []);
  const getRowId = (row) => {
    return row.drugDetailId;
  };

  const openPrescribingProviderModal = (row) => {
    setPrescribingProviderData(row);
    setPrescribingProviderModalOpen(true);
  };
  const openPharmacyModal = (row) => {
    setPharmacyData(row);
    setPharmacyModalOpen(true);
  };
  function closeModal() {
    setPrescribingProviderModalOpen(false);
    setPharmacyModalOpen(false);
  }
  const columns = [
    {
      field: "drugName",
      headerName: "Drug Name",
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.drugName}>
            <span>{params.row.drugName}</span>
          </Tooltip>
        );
      },
    },
    {
      field: "form",
      headerName: "Form",
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Quantity/Days",
      flex: 1,
    },
    {
      field: "prescribingProvider",
      headerName: "Prescriber",
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => openPrescribingProviderModal(params.row)}
          >
            <Tooltip title={params.row.prescribingProvider}>
              <span>
                <u>{params.row.prescribingProvider}</u>
              </span>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "pharmacyName",
      headerName: "Pharmacy",
      flex: 1,
      renderCell: (params) => {
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => openPharmacyModal(params.row)}
          >
            <Tooltip title={params.row.pharmacyName}>
              <span>
                <u>{params.row.pharmacyName}</u>
              </span>
            </Tooltip>
          </div>
        );
      },
    },
    {
      field: "issuedDate",
      headerName: "Issued Date",
      flex: 1,
      renderCell: (params) =>
        params.row.issuedDate && params.row.issuedDate != ""
          ? format(new Date(params.row.issuedDate), "dd-MM-yyyy")
          : "",
    },
    {
      field: "lastRefillDate",
      headerName: "Last Fill Date",
      flex: 1,
      renderCell: (params) =>
        params.row.lastRefillDate && params.row.lastRefillDate != ""
          ? format(new Date(params.row.lastRefillDate), "dd-MM-yyyy")
          : "",
    },
  ];

  return (
    <Box sx={{ width: "100%", p: { xs: 2, sm: 2 } }}>
      {isLoading ? (
        <Loader isLoading={isLoading}></Loader>
      ) : (
        <Stack direction="column" spacing={0.2}>
          <Stack direction="row" spacing={1}>
            <ArrowBackOutlinedIcon
              onClick={backAction}
              style={{
                fontSize: "25px",
                fontWeight: "600",
                marginTop: "2px",
                width: "32px",
              }}
            />
            <Typography style={{ fontSize: "20px", fontWeight: "300" }}>
              Medication Adherence: Member Details
            </Typography>
          </Stack>
          <Grid container spacing={2}>
            <Grid item xs>
              <Typography sx={{ fontSize: "14px" }}>
                <b>Name:</b> {memberDetails.memberName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <Typography sx={{ fontSize: "14px" }}>
                <b>DOB:</b> {format(memberDetails.dob, "MMMM dd,yyyy")}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography sx={{ fontSize: "14px" }}>
                <b>Address:</b> {memberDetails.address}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography sx={{ fontSize: "14px" }}>
                <b>Provider NPI:</b> {memberDetails.providerNPI}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <Typography sx={{ fontSize: "14px" }}>
                <b>Gender:</b> {memberDetails.gender}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography sx={{ fontSize: "14px" }}>
                <b>Phone:</b> {memberDetails.phone}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography sx={{ fontSize: "14px" }}>
                <b>Provider Name:</b> {memberDetails.providerName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs>
              <Typography sx={{ fontSize: "14px" }}>
                <b>Medication Free Days Allowed: </b>
                {memberDetails.medicationFreeDaysAllowed} days
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography sx={{ fontSize: "14px" }}>
                <b>Email:</b> {memberDetails.email}
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography sx={{ fontSize: "14px" }}>
                <b>Practice Name:</b> {practiceName}
              </Typography>
            </Grid>
          </Grid>
          <Grid sx={{ marginBottom: "18px !important" }} container spacing={2}>
            <Grid item xs>
              <Typography sx={{ fontSize: "14px" }}>
                <b>Med. Adherence Measure: </b>
                {risk === "G" && (
                  <Chip
                    color="success"
                    variant="outlined"
                    label={medAdherenceMeasure}
                  ></Chip>
                )}
                {risk === "Y" && (
                  <Chip
                    color="warning"
                    variant="outlined"
                    label={medAdherenceMeasure}
                  ></Chip>
                )}
                {risk === "R" && (
                  <Chip
                    color="error"
                    variant="outlined"
                    label={medAdherenceMeasure}
                  ></Chip>
                )}
                {!(risk === "Y" || risk === "G" || risk === "R") && (
                  <Chip variant="outlined" label={medAdherenceMeasure}></Chip>
                )}
              </Typography>
            </Grid>
          </Grid>
          <DataGridCustom
            columns={columns}
            rows={memberDetails.drugDetails}
            getRowId={getRowId}
            loading={isLoading}
            sortingOrder={["asc", "desc"]}
            slots={{
              toolbar: DataGridHeader,
            }}
            slotProps={{
              toolbar: {
                headerText: "Medications List",
                fontSize: "16px",
                marginTop: "auto",
                marginBottom: "auto",
                height: "56px",
              },
            }}
          />
        </Stack>
      )}
      <PharmacyDataModal
        isOpen={pharmacyModalOpen}
        onClose={closeModal}
        rowData={pharmacyData}
      ></PharmacyDataModal>
      <PrescribingProviderModal
        isOpen={prescribingProviderModalOpen}
        onClose={closeModal}
        rowData={prescribingProviderData}
      ></PrescribingProviderModal>
    </Box>
  );
};

export default MedicalAdherenceMemberDetails;
