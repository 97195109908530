import MuiAppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import cinqCareLogo_OnlyC from "../../../assets/cinqCareLogo_OnlyC.png";
import { sevenpillarsAdmin } from "../../../sevenpillarjsondata/sevenPillarAdminMenuJson";
import SevenPillarSidebarAdminMenuItem from "../sevenPillarSidebarMenuItem/SevenPillarSidebarAdminMenuItem";
import ToolbarContent from "../toolbar/ToolbarContent";

const MiniDrawerAdmin = () => {
    const drawerWidth = 300;
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
    const AppBar = styled(MuiAppBar, {
        shouldForwardProp: (prop) => prop !== "open",
      })(({ theme, open }) => ({
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open
          ? {
              marginLeft: drawerWidth,
              width: `calc(100% - ${drawerWidth}px)`,
              transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
              }),
            }
          : {
              marginLeft: 64,
              width: `calc(100% - 64px)`,
            }),
      }));
      const Drawer = styled(MuiDrawer, {
        shouldForwardProp: (prop) => prop !== "open",
      })(({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: "nowrap",
        boxSizing: "border-box",
        ...(open && {
          ...openedMixin(theme),
          "& .MuiDrawer-paper": openedMixin(theme),
        }),
        ...(!open && {
          ...closedMixin(theme),
          "& .MuiDrawer-paper": closedMixin(theme),
        }),
      }));
      const DrawerHeader = styled("div")(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        justifyContent: "center",
        border: "none",
        width: "100%",
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
      }));
    return (
        <>
        <CssBaseline />
        <AppBar
        position="fixed"
        elevation={1}
        sx={{
          background: "#212121 0% 0% no-repeat padding-box",
          borderBottom: "1px solid #E2E2E2",
          opacity: "1",
        }}
        open={open}
      >
        <Toolbar>
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            disableFocusRipple={true}
            disableRipple={true}
            type="button"
            
            edge="start"
            sx={{
              marginRight: 5,
              
            }}
          >
            <FormatAlignLeftIcon
              sx={{
                color: "white",
              }}
            />
          </IconButton> */}
          <ToolbarContent />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent">
      <DrawerHeader>
      <IconButton
              onClick={() => navigate("/")}
              disableFocusRipple={true}
              disableRipple={true}
              type="button"
            >
              <img
                src={cinqCareLogo_OnlyC}
                style={{
                  width: "70px",
                  height: "70px",
                  float: "left",
                }}
              />
            </IconButton>
      </DrawerHeader>
      <List disablePadding={true} spacing={0} sx={{ padding: 0 }}>
          {sevenpillarsAdmin.map((pillar, index) => (
            <SevenPillarSidebarAdminMenuItem
              pillar={pillar}
              key={pillar.id}
              disablePadding
              miniDrawerOpen={open}
              sx={{ display: "block" }}
              
            />
          ))}
        </List>
      </Drawer>
    </>
    )
    
}

export default MiniDrawerAdmin;