import React, { useEffect, useState } from "react";
import agent from "../../app/api/Agent";
import { Box, Grid, Stack, styled, Typography } from "@mui/material";
import TrainingProgramsHeader from "./TrainingProgramsHeader";
import Loader from "../../component/common/loader/Loader";
import { Link } from "react-router-dom";
import HealthHomeProgramNameCard from "./HealthHomeTrainingProgramNameCard";

const handleScrollToSection = (letter) => {
  document.getElementById(letter).scrollIntoView({ behavior: "smooth" });
};

const StyledLink = styled(Link)({
  cursor: "pointer",
});

const HealthHomeTrainingPrograms = () => {
  const [programData, setProgramData] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    agent.HealthHomeEducationAgent.getTrainingProgramsList().then((resp) => {
      setProgramData(resp);
    });
  }, []);
  useEffect(() => {
    var group = filteredData.reduce((acc, item) => {
      const firstLetter = item.title[0].toUpperCase();
      if (!acc[firstLetter]) {
        acc[firstLetter] = [];
      }
      acc[firstLetter].push(item);
      return acc;
    }, {});
    setGroupedData(group);
    setIsLoading(false);
  }, [filteredData]);
  useEffect(() => {
    if (programData) {
      let filtered;
      if (searchValue) {
        filtered = programData.filter(
          (item) =>
            item.title.toLowerCase().includes(searchValue.toLowerCase()) ||
            (item.description &&
              item.description
                .toLowerCase()
                .includes(searchValue.toLowerCase()))
        );
        setFilteredData(filtered);
      } else {
        setFilteredData(programData);
      }
    }
  }, [searchValue, programData]);

  return (
    <Box sx={{ width: "100%" }}>
      <TrainingProgramsHeader
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <>
          <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
            <Typography>All Programs Index</Typography>
            {Object.keys(groupedData)
              .sort()
              .map((letter) => (
                <StyledLink
                  key={letter}
                  onClick={() => handleScrollToSection(letter)}
                >
                  {letter}
                </StyledLink>
              ))}
          </Stack>
          {Object.keys(groupedData)
            .sort()
            .map((letter) => (
              <Stack
                direction="column"
                key={letter}
                id={letter}
                style={{ marginBottom: "40px" }}
              >
                <Typography variant="h4" gutterBottom>
                  {letter}
                </Typography>
                <Grid container spacing={2}>
                  {groupedData[letter].map((item, index) => (
                    <Grid
                      item
                      xs="auto"
                      sm="auto"
                      md="auto"
                      lg="auto"
                      key={index}
                    >
                      <HealthHomeProgramNameCard
                        key={index}
                        name={item.title}
                        description={item.description}
                        url={item.url}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            ))}
        </>
      )}
    </Box>
  );
};

export default HealthHomeTrainingPrograms;
