import { Add, Remove } from "@mui/icons-material";
import {
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
// import { useLocation } from "react-router-dom";
import { Styles } from "../../../Styles";
import SubMenuItem from "../subMenuItem/SubMenuItem";
import { GetPagesPathForPillars, PagesPath } from "../../../PagesPath";
import { useMemo } from "react";
import { memo } from "react";

const SevenPillarSidebarSubMenuItem = ({
  isTools,
  title,
  listItems,
  parentMenuOpen,
  open,
  onCollapseClick,
  pathKey,
  //handleDrawerClose,
}) => {
  // const location = useLocation();

  return (
    <>
      {/* <div> */}
      <ListItem
        onClick={() => onCollapseClick()}
        disablePadding
        sx={{
          // width:"inherit",
          //justifyContent: "center",
          // display: parentMenuOpen ? "inline-flex" : "none",
          backgroundColor: "rgba(243, 111, 33, 0.08)",
          margin: "0px",
        }}
      >
        <ListItemText
          sx={{
            margin: "0px",
          }}
          // disablePadding
          primaryTypographyProps={{
            // width:"90%",
            display: parentMenuOpen ? "inline-flex" : "none",
            padding: "0px",

            pl: "20px",
            justifyContent: "flex-end",
            alignItems: "flex-start",
            gap: "207px",
            color: "#000",
            fontFamily: Styles.FONT_FAMILY_POPPINS,
            fontSize: "12px",
            fontStyle: Styles.FONT_STYLE_NORMAL,
            fontWeight: "600",
            lineHeight: "18px",
          }}
          primary={title}
        >
          {/* {title} */}
        </ListItemText>
        <IconButton
          sx={{
            display: parentMenuOpen ? "flex-end" : "none",
            float: "right",
            // width:"10%",
          }}
        >
          {open ? (
            <Remove
              sx={{
                color: "#000000",
                width: "10px",
                height: "10px",
              }}
            />
          ) : (
            <Add
              sx={{
                color: "#000000",
                width: "10px",
                height: "10px",
                // float: "right",
              }}
            />
          )}
        </IconButton>
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {listItems
            .filter((x) => x.key !== null)
            .map((list) => (
              <SubMenuItem
                // showOnNewTab={
                //   list.key === "IPAlertToolFromTexting" ||
                //   list.key === "ERAlertToolfromTexting"
                //     ? true
                //     : false
                // }
                showOnNewTab={false}
                name={list.name}
                key={list.id}
                sx={{ pl: 4 }}
                path={GetPagesPathForPillars(pathKey, list.key, isTools)}
                // handleDrawerClose={handleDrawerClose}
              />
            ))}
        </List>
      </Collapse>

      {/* </div> */}
    </>
  );
};

export default memo(SevenPillarSidebarSubMenuItem);
