import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  styled,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { COLOR, Styles } from "../../../Styles";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { axiosRequests } from "../../../app/api/AxiosRequest";
import Loader from "../../../component/common/loader/Loader";
import DataGridCustom from "../../../component/common/datagrid/DataGridCustom";
import DataGridHeader from "../../../component/common/datagrid/DataGridHeader";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { addDays, format } from "date-fns";
import { Link } from "react-router-dom";
import IPEventsDetailsModal from "../CreateCohortRegistryManagement/Popup/IPEventsDetailsModal";
import {
  getAdmissionTrendsDetail,
  getModalFilterDetails,
} from "../../../component/common/sdohAssessmentFilter/SDOHAssessmentFilterDetailsSlice";
import { useAppDispatch } from "../../../app/store";
import EventsDetailsModal from "../CreateCohortRegistryManagement/Popup/EventsDetailsModal";
import { formatCost } from "./CreateCohortByImport";
import MemberDetailsModal from "../CreateCohortRegistryManagement/Popup/MemberDetailsModal";
import Searchbox from "../../../component/common/searchbox/Searchbox";

const OutlinedButton = styled(Button)(() => ({
  borderRadius: "4px",
  padding: "6px",
  justifyContent: "center",
  color: COLOR.BLACK,
  borderColor: COLOR.BLACK,
  fontFamily: Styles.FONT_FAMILY_POPPINS,
  fontStyle: "normal",
  textTransform: "capitalize",
}));

const DefaultTypography = styled(Typography)({
  color: "#000",
  fontFamily: Styles.FONT_FAMILY_POPPINS,
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "normal",
});

const CohortByImport_Step1 = ({
  step,
  handleCancelCall,
  handleNextCall,
  setSelectedRows,
  selectedRows,
  setFileName,
  setCohortName,
  setPODName,
  admissionTrendValue = 1,
  setAdmissionTrendValue,
  customStartDate,
  setCustomStartDate,
  customEndDate,
  setCustomEndDate,
  isCustomDateRangeSelected,
  setIsCustomDateRangeSelected,
  isEditView,
  cohortId,
  preLoadedCohortData = undefined,
  setPreLoadedCohortData = undefined,
}) => {
  const CustomDateRangeOpts = {
    "By Admission Trends": [
      {
        id: 1,
        name: "2024",
      },
      { id: 2, name: "2023" },
    ],
    "By Event Year": [
      {
        id: 3,
        name: "0-3 Months",
      },
      { id: 4, name: "3-6 Months" },
      {
        id: 5,
        name: "0-6 Months",
      },
      { id: 6, name: "6-9 Months" },
      {
        id: 7,
        name: "0-9 Months",
      },
    ],
  };
  const menuItems = Object.entries(CustomDateRangeOpts).flatMap(
    ([groupLabel, options]) => [
      <MenuItem key={groupLabel} disabled>
        {groupLabel}
      </MenuItem>,
      ...options.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      )),
    ]
  );
  const dispatch = useAppDispatch();
  const [cohortFiles, setCohortFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [cohortDetails, setCohortDetails] = useState({});
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [customDateRange, setCustomDateRange] = useState(false);
  const [ipPopupConfirmationModalOpen, SetIpPopupConfirmationModalOpen] =
    useState(false);
  const [ipPopupConfirmationModalData, setIpPopupConfirmationModalData] =
    useState({});
  const [viewIpEventsPopupModalOpen, SetViewIpEventsPopupModalOpen] =
    useState(false);
  const [viewEventsPopupModalOpen, SetViewEventsPopupModalOpen] =
    useState(false);
  const [popupConfirmationModalOpen, SetPopupConfirmationModalOpen] =
    useState(false);
  const [popupConfirmationModalData, setPopupConfirmationModalData] = useState(
    {}
  );
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [defaultRowsSelected, setDefaultRowsSelected] = useState([]);

  useEffect(() => {
    if (preLoadedCohortData) {
      const selectedRowsPreLoaded = preLoadedCohortData
        .filter((row) => row.isSelected === 1)
        .map((row) => row.id);
      const preLoadedSet = new Set(selectedRowsPreLoaded);
      setDefaultRowsSelected(selectedRowsPreLoaded);
      var selected = preLoadedCohortData.filter((row) =>
        preLoadedSet.has(row.id)
      );
      setSelectedRows(selected);
    }
  }, [preLoadedCohortData]);
  useEffect(() => {
    if (
      cohortFiles &&
      cohortFiles.length > 0 &&
      customStartDate &&
      customEndDate
    ) {
      setIsLoading(true);
      var formData = new FormData();
      cohortFiles.forEach((file) => {
        formData.append("file", file);
      });
      formData.append("startDate", format(customStartDate, "yyyy-MM-dd"));
      formData.append("endDate", format(customEndDate, "yyyy-MM-dd"));
      if (cohortId && cohortId > 0) {
        formData.append("cohortId", cohortId);
        axiosRequests
          .postUpload("CohortImport/ImportForEdit", formData)
          .then((resp) => {
            setPreLoadedCohortData(undefined);
            setCohortDetails(resp.data);
            setIsError(false);
            setIsLoading(false);
            setFileName(cohortFiles[0].name);
            setCohortName(resp.data.cohortName);
            setPODName(resp.data.podName);
          })
          .catch((error) => {
            console.log(error);
            setCohortDetails({});
            setIsError(true);
            setErrorText("File Upload Failed.");
            setIsLoading(false);
          });
      } else {
        axiosRequests
          .postUpload("CohortImport/Import", formData)
          .then((resp) => {
            setPreLoadedCohortData(undefined);
            setCohortDetails(resp.data);
            setIsError(false);
            setIsLoading(false);
            setFileName(cohortFiles[0].name);
            setCohortName(resp.data.cohortName);
            setPODName(resp.data.podName);
          })
          .catch((error) => {
            console.log(error);
            setCohortDetails({});
            setIsError(true);
            setErrorText("File Upload Failed.");
            setIsLoading(false);
          });
      }
    }
  }, [cohortFiles, customStartDate, customEndDate]);
  useEffect(() => {
    if (
      cohortDetails &&
      cohortDetails.cohortMembersDetails &&
      cohortDetails.cohortMembersDetails.length > 0
    ) {
      var members = cohortDetails.cohortMembersDetails;
      const selectedRowsPreLoaded = members
        .filter((row) => row.isSelected === 1)
        .map((row) => row.id);
      const preLoadedSet = new Set(selectedRowsPreLoaded);
      setDefaultRowsSelected(selectedRowsPreLoaded);
      var selected = members.filter((row) => preLoadedSet.has(row.id));
      setSelectedRows(selected);
    }
  }, [cohortDetails]);
  const handleCohortFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setCohortFiles(newFiles);
    // setCohortFilesError("");
  };
  const handleCohortFileDelete = (fileToDelete) => {
    setCohortDetails({});
    const input = document.getElementById("claims-medical-file-input");
    document.getElementById("cohort-file-input").value = "";
    setIsError(false);
    setCohortFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToDelete)
    );
  };
  useEffect(() => {
    if (cohortDetails.cohortMembersDetails || preLoadedCohortData) {
      let filtered;
      if (searchValue) {
        if (preLoadedCohortData) {
          filtered = preLoadedCohortData.filter(
            (item) =>
              item.firstName
                .toLowerCase()
                .includes(searchValue.toLowerCase()) ||
              (item.lastName &&
                item.lastName.toLowerCase().includes(searchValue.toLowerCase()))
          );
        } else {
          filtered = cohortDetails.cohortMembersDetails.filter(
            (item) =>
              item.firstName
                .toLowerCase()
                .includes(searchValue.toLowerCase()) ||
              (item.lastName &&
                item.lastName.toLowerCase().includes(searchValue.toLowerCase()))
          );
        }
        setFilteredData(filtered);
      } else {
        if (preLoadedCohortData) {
          setFilteredData(preLoadedCohortData);
        } else {
          setFilteredData(cohortDetails.cohortMembersDetails);
        }
      }
    }
  }, [searchValue, cohortDetails.cohortMembersDetails, preLoadedCohortData]);

  const handleSelectionChange = (selectionModel) => {
    const selectedIDs = new Set(selectionModel);
    setIsError(false);
    setErrorText("");
    setDefaultRowsSelected(selectionModel);
    const selectedData = cohortDetails.cohortMembersDetails.filter((row) =>
      selectedIDs.has(row.id)
    );
    setSelectedRows(selectedData);
  };

  const handleCustomDateRangeChange = (e) => {
    setCustomDateRange(e.target.checked);
    setIsCustomDateRangeSelected(e.target.checked);
    setCustomStartDate(null);
    setCustomEndDate(null);
    setCohortDetails({});
    if (e.target.checked) {
      setAdmissionTrendValue(0);
    } else setAdmissionTrendValue(1);
  };

  useEffect(() => {
    if (admissionTrendValue === 1) {
      // admissionTrsValue = "2024"
      setCustomStartDate(format("2024-01-01", "yyyy-MM-dd"));
      setCustomEndDate(format("2024-12-31", "yyyy-MM-dd"));
    } else if (admissionTrendValue === 2) {
      // admissionTrsValue = "2023"
      setCustomStartDate(format("2023-01-01", "yyyy-MM-dd"));
      setCustomEndDate(format("2023-12-31", "yyyy-MM-dd"));
    } else if (admissionTrendValue === 3) {
      // admissionTrsValue = "0 - 3 Months"
      setCustomStartDate(format("2024-01-01", "yyyy-MM-dd"));
      setCustomEndDate(format("2024-03-31", "yyyy-MM-dd"));
    } else if (admissionTrendValue === 4) {
      // admissionTrsValue = "3 - 6 Months"
      setCustomStartDate(format("2024-03-01", "yyyy-MM-dd"));
      setCustomEndDate(format("2024-06-30", "yyyy-MM-dd"));
    } else if (admissionTrendValue === 5) {
      // admissionTrsValue = "0 - 6 Months"
      setCustomStartDate(format("2024-01-01", "yyyy-MM-dd"));
      setCustomEndDate(format("2024-06-30", "yyyy-MM-dd"));
    } else if (admissionTrendValue === 6) {
      // admissionTrsValue = "6 - 9 Months"
      setCustomStartDate(format("2024-06-01", "yyyy-MM-dd"));
      setCustomEndDate(format("2024-09-30", "yyyy-MM-dd"));
    } else if (admissionTrendValue === 7) {
      setCustomStartDate(format("2024-01-01", "yyyy-MM-dd"));
      setCustomEndDate(format("2024-09-30", "yyyy-MM-dd"));
    }
  }, [admissionTrendValue]);

  const handleAdmissionRangeChange = (e) => {
    setAdmissionTrendValue(e.target.value);
  };

  const onNext = () => {
    if (
      !selectedRows ||
      (selectedRows.length === 0 && cohortFiles.length === 0)
    ) {
      setIsError(true);
      setErrorText("Please upload a file to continue.");
    } else if (
      !selectedRows ||
      (selectedRows.length === 0 && cohortFiles.length > 0)
    ) {
      setIsError(true);
      setErrorText("Please select atleast 1 record to continue");
    } else handleNextCall();
  };

  const viewEventsDetails = (memberDtl) => {
    dispatch(
      getAdmissionTrendsDetail({
        startDate: format(customStartDate, "yyyy-MM-dd"),
        endDate: format(customEndDate, "yyyy-MM-dd"),
      })
    );
    setPopupConfirmationModalData(memberDtl);
    SetViewEventsPopupModalOpen(true);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerClassName: "classMultilineHeader",
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.firstName + " " + params.row.lastName}>
            <div>{params.row.firstName + " " + params.row.lastName}</div>
          </Tooltip>
        );
      },
    },
    {
      field: "insuranceId",
      headerName: "Insurance Id",
      flex: 1,
      headerClassName: "classMultilineHeader",
    },
    {
      field: "region",
      headerName: "Region",
      flex: 1,
      headerClassName: "classMultilineHeader",
    },
    {
      field: "payer",
      headerName: "Payer",
      flex: 1,
      headerClassName: "classMultilineHeader",
    },
    {
      field: "practiceName",
      headerName: "Practice",
      flex: 1,
      headerClassName: "classMultilineHeader",
    },
    {
      field: "memberDateOfBirth",
      headerName: "Date of Birth",
      flex: 1,
      headerClassName: "classMultilineHeader",
    },
    {
      field: "chronicCondition",
      headerName: "Chronic Conditions",
      flex: 1,
      headerClassName: "classMultilineHeader",
    },
    {
      field: "totalEvents",
      headerName: "Total Events",
      flex: 1,
      headerClassName: "classMultilineHeader",
      renderCell: (params) => {
        if (params.row.totalEvents != 0)
          return (
            //   <div style={{display:"flex", alignItems:"center"}}>
            <Link
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <div onClick={() => viewEventsDetails(params.row)}>
                <u>{params.row.totalEvents}</u>
              </div>
            </Link>
            //   </div>
          );
      },
    },
    {
      field: "totalIPEvents",
      headerName: "IP Events",
      flex: 1,
      headerClassName: "classMultilineHeader",
      renderCell: (params) => {
        if (params.row.totalIPEvents != 0)
          return (
            //   <div style={{display:"flex", alignItems:"center"}}>
            <Link
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <div onClick={() => viewIPEventsDetails(params.row)}>
                <u>{params.row.totalIPEvents}</u>
              </div>
            </Link>
            //   </div>
          );
      },
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      flex: 1,
      headerClassName: "classMultilineHeader",
      renderCell: (params) =>
        params.row.totalCost && formatCost(params.row.totalCost),
    },
    {
      field: "preview",
      headerName: "Preview",
      flex: 1,
      headerClassName: "classMultilineHeader",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "7px",
              marginLeft: "10px",
            }}
          >
            <Tooltip title="Show Member Details">
              <div onClick={() => openMemberDetailsModal(params.row)}>
                <VisibilityOutlinedIcon />
              </div>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    if (cohortFiles.length > 0) setIsFileUploaded(true);
    else setIsFileUploaded(false);
  }, [cohortFiles]);

  useEffect(() => {
    setAdmissionTrendValue(1);
  }, []);

  const viewIPEventsDetails = (memberDtl) => {
    dispatch(
      getAdmissionTrendsDetail({
        startDate: format(customStartDate, "yyyy-MM-dd"),
        endDate: format(customEndDate, "yyyy-MM-dd"),
      })
    );
    setIpPopupConfirmationModalData(memberDtl);
    SetViewIpEventsPopupModalOpen(true);
  };

  const handleCustomStartDateChange = (e) => {
    setCustomStartDate(format(e, "MM/dd/yyyy"));
  };

  const handleCustomEndDateChange = (e) => {
    setCustomEndDate(format(e, "MM/dd/yyyy"));
  };

  const closeModal = () => {
    SetViewIpEventsPopupModalOpen(false);
    SetViewEventsPopupModalOpen(false);
    SetPopupConfirmationModalOpen(false);
  };

  const openMemberDetailsModal = (memberDtl) => {
    setPopupConfirmationModalData(memberDtl);
    dispatch(
      getAdmissionTrendsDetail({
        startDate: format(customStartDate, "yyyy-MM-dd"),
        endDate: format(customEndDate, "yyyy-MM-dd"),
      })
    );
    SetPopupConfirmationModalOpen(true);
  };
  return (
    <Box>
      <Stack direction="column" spacing={2} style={{ marginBottom: "16px" }}>
        <DefaultTypography>
          Import File with Member List and Cohort Details
        </DefaultTypography>
        <input
          type="file"
          onChange={handleCohortFileChange}
          style={{ display: "none" }}
          id="cohort-file-input"
        />
        {!cohortFiles ||
          (cohortFiles.length === 0 && (
            <Button
              variant="contained"
              style={{
                textTransform: "none",
                width: "130px",
                height: "36px",
                color: "#fff",
              }}
              onClick={() =>
                document.getElementById("cohort-file-input").click()
              }
              color="orangeTheme"
            >
              <FileUploadOutlinedIcon /> Import File
            </Button>
          ))}
        {cohortFiles.map((file, index) => (
          <ListItem
            key={index}
            style={{
              width: "600px",
              background: "#EDEDED",
              borderRadius: "4px",
            }}
            secondaryAction={
              <IconButton
                edge="end"
                onClick={() => handleCohortFileDelete(file)}
              >
                <DeleteOutlineOutlinedIcon></DeleteOutlineOutlinedIcon>
              </IconButton>
            }
          >
            <Tooltip title={file.name}>
              <ListItemText
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                primary={file.name}
              ></ListItemText>
            </Tooltip>
          </ListItem>
        ))}
        <FormHelperText>
          Upload only one Excel file (.xlsx) at a time, new uploads will
          overwrite previous files. If you want to re-upload, please delete the
          above file.
        </FormHelperText>
        <Stack direction="row" spacing={2}>
          <Typography
            sx={{
              textAlign: "left",
              letterSpacing: "1px",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "14px",
              color: "#111827B8",
              fontFamily: Styles.FONT_FAMILY_POPPINS,
              marginTop: "auto !important",
              marginBottom: "auto !important",
            }}
          >
            Date Range
          </Typography>
          <FormGroup
            sx={{
              position: "flex",
              flexDirection: "row",
              justifyContent: "left",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              control={
                <Switch
                  color="orangeTheme"
                  checked={customDateRange}
                  onChange={handleCustomDateRangeChange}
                />
              }
              label="Custom Date Range"
              labelPlacement="end"
            />
          </FormGroup>
        </Stack>
        <Stack direction="row" spacing={2}>
          {!customDateRange && (
            <FormControl
              required
              color="orangeTheme"
              sx={{
                maxWidth: "303px",
                minWidth: "303px",
                backgroundColor: "#fff",
                borderRadius: "6px",
              }}
              //disabled={isLoadingRoleName}
              size="small"
              // error={!!roleNameErrorText}
            >
              <InputLabel id="role-type-select-small-label">
                Admission Trends
              </InputLabel>
              <Select
                required
                value={admissionTrendValue}
                labelId="role-type-select-small-label"
                id="role-type-select-small"
                onChange={(e) => handleAdmissionRangeChange(e)}
                label="Admission Trends"
              >
                {menuItems}
              </Select>
              {/* </Select> */}
            </FormControl>
          )}
          {customDateRange && (
            <Stack direction="row" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormControl
                  className="practiceNameClass"
                  required
                  color="orangeTheme"
                  sx={{
                    maxWidth: "180px",
                    minWidth: "160px",
                    backgroundColor: "#fff",
                    borderRadius: "6px",
                  }}
                  size="small"
                >
                  <DatePicker
                    label="Start Date"
                    value={customStartDate}
                    color="orangeTheme"
                    slotProps={{
                      textField: {
                        className: "dateFieldClass",
                        color: "orangeTheme",
                        // error: !!customStartDateError,
                      },
                    }}
                    minDate={addDays(new Date(1753, 0, 0), 1)}
                    onChange={handleCustomStartDateChange}
                  />
                </FormControl>
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormControl
                  className="practiceNameClass"
                  required
                  color="orangeTheme"
                  sx={{
                    maxWidth: "180px",
                    minWidth: "160px",
                    backgroundColor: "#fff",
                    borderRadius: "6px",
                  }}
                  size="small"
                >
                  <DatePicker
                    label="End Date"
                    value={customEndDate}
                    color="orangeTheme"
                    slotProps={{
                      textField: {
                        className: "dateFieldClass",
                        color: "orangeTheme",
                        //error: !!customStartDateError,
                      },
                    }}
                    minDate={addDays(new Date(1753, 0, 0), 1)}
                    onChange={handleCustomEndDateChange}
                  />
                </FormControl>
              </LocalizationProvider>
            </Stack>
          )}
        </Stack>
      </Stack>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <>
          {isError && (
            <FormHelperText style={{ color: "#DC4F34" }}>
              {errorText}
            </FormHelperText>
          )}
          {(cohortDetails.cohortMembersDetails &&
            cohortDetails.cohortMembersDetails.length > 0 &&
            isFileUploaded) ||
          preLoadedCohortData ? (
            <div style={{ marginTop: "16px", display: "grid", height: 680 }}>
              <DataGridCustom
                columns={columns}
                rows={filteredData}
                pageSize={10}
                className="pafGrid"
                checkboxSelection={true}
                loading={isLoading}
                rowSelectionModel={defaultRowsSelected}
                sortingOrder={["asc", "desc"]}
                slots={{
                  toolbar: DataGridHeader,
                }}
                onSelectionModelChanged={(newSelection) => {
                  handleSelectionChange(newSelection);
                }}
                slotProps={{
                  toolbar: {
                    headerText: "",
                    fontSize: "",
                    marginTop: "",
                    marginBottom: "",
                    height: "56px",
                    children: (
                      <div
                        style={{
                          fontFamily: Styles.FONT_FAMILY_POPPINS,
                          fontSize: "16px",
                          width: "100%",
                          marginTop: "auto",
                          marginBottom: "auto",
                          marginLeft: "5px",
                        }}
                      >
                        <Stack direction="row" spacing={1}>
                          <Typography
                            style={{
                              fontFamily: Styles.FONT_FAMILY_POPPINS,
                              fontSize: "16px",
                              marginTop: "auto",
                              marginBottom: "auto",
                            }}
                          >
                            Member Details
                          </Typography>
                          <Chip
                            color="orangeTheme"
                            variant="contained"
                            style={{ color: "#fff" }}
                            label={
                              preLoadedCohortData
                                ? preLoadedCohortData.length
                                : cohortDetails.cohortMembersDetails.length
                            }
                          ></Chip>
                          <Stack
                            direction="row"
                            spacing={1}
                            style={{ marginLeft: "auto", float: "right" }}
                          >
                            <Searchbox
                              cursor="default"
                              defaultValue={searchValue}
                              handleChange={(e) =>
                                setSearchValue(e.target.value)
                              }
                              label="Search Member"
                            ></Searchbox>
                          </Stack>
                        </Stack>
                      </div>
                    ),
                  },
                }}
              />
            </div>
          ) : !cohortDetails.cohortMembersDetails &&
            isFileUploaded &&
            errorText !== "File Upload Failed." ? (
            <FormHelperText style={{ color: "#DC4F34" }}>
              No Data
            </FormHelperText>
          ) : (
            <></>
          )}
        </>
      )}

      <Grid container sx={{ paddingTop: 2 }}>
        <Grid item xs={12}>
          <Stack direction="column" spacing={2}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                sx={{
                  margintTop: "25px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    justifyContent: "end",
                    paddingTop: "7px",
                    paddingBottom: "7px",
                  }}
                >
                  <OutlinedButton
                    onClick={handleCancelCall}
                    variant="text"
                    size="small"
                  >
                    <DefaultTypography>Cancel</DefaultTypography>
                  </OutlinedButton>
                  <Button
                    style={{
                      color: "white",
                      fontFamily: Styles.FONT_FAMILY_POPPINS,
                    }}
                    variant="contained"
                    color="orangeTheme"
                    disableElevation
                    onClick={() => onNext()}
                  >
                    Next
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
      </Grid>
      <IPEventsDetailsModal
        isOpen={viewIpEventsPopupModalOpen}
        onClose={closeModal}
        rowData={ipPopupConfirmationModalData}
        // startDateValue={format(customStartDate, "yyyy-MM-dd")}
        // endDateValue = {format(customEndDate, "yyyy-MM-dd")}
      ></IPEventsDetailsModal>
      <EventsDetailsModal
        isOpen={viewEventsPopupModalOpen}
        onClose={closeModal}
        rowData={popupConfirmationModalData}
        // startDateValue={format(customStartDate, "yyyy-MM-dd")}
        // endDateValue = {format(customEndDate, "yyyy-MM-dd")}
      ></EventsDetailsModal>
      <MemberDetailsModal
        isOpen={popupConfirmationModalOpen}
        onClose={closeModal}
        rowData={popupConfirmationModalData}
        Preview={1}
      ></MemberDetailsModal>
    </Box>
  );
};

export default CohortByImport_Step1;
