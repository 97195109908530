import {
  Box,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Grid,
  CardContent,
  Card,
  Tooltip,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import { format } from "date-fns";
import MemberDetailsModal from "./memberDetailsModal";
import ActiveGapClosuresListHeader from "./activeGapClosuresListHeader";
import { useCallback, useEffect, useState } from "react";
import NoAccessPage from "../../component/common/noAccess/NoAccessPage";
import { checkUserToolAccess } from "../../component/common/userAuthorization/checkUserAccess";
import { axiosRequests } from "../../app/api/AxiosRequest";
import { Styles } from "../../Styles";
import { getActiveGapClosuresList } from "./Slices/activeGapClosuresListSlice";
import { useAppDispatch } from "../../app/store";
import DataGridCustom from "../../component/common/datagrid/DataGridCustom";
import DataGridHeader from "../../component/common/datagrid/DataGridHeader";
import CustomPaginationSlot from "../../component/common/datagrid/CustomPaginationSlot";
import Searchbox from "../../component/common/searchbox/Searchbox";
import Loader from "../../component/common/loader/Loader";
import "./activeGapClosuresListCSS.css";
import ActiveGapClosureMemberDetails from "./activeGapClosuresMemberDetails";
import SDOHNewAssessment from "./SDOHNewAssessment";
import SDOHViewAssessment from "./SDOHViewAssessment";
import memberDetailsIcon from "../../assets/memberDetailsIcon.svg";
const ActiveGapClosuresList = () => {
  const ActiveGapClosuresListID = 64;
  const [accessObj] = useState(checkUserToolAccess(ActiveGapClosuresListID));
  const dispatch = useAppDispatch();
  //State initialization starts
  const [payerNameDropdownData, setPayerNameDropdownData] = useState([]);
  const [selectedPayerName, setSelectedPayerName] = useState([]);
  const [productNameDropdownData, setProductNameDropdownData] = useState([]);
  const [selectedProductName, setSelectedProductName] = useState([]);
  const [marketNameDropdownData, setMarketNameDropdownData] = useState([]);
  const [selectedMarketName, setSelectedMarketName] = useState([]);
  const [regionNameDropdownData, setRegionNameDropdownData] = useState([]);
  const [selectedRegionName, setSelectedRegionName] = useState([]);
  const [practiceNameDropdownData, setPracticeNameDropdownData] = useState([]);
  const [selectedPracticeName, setSelectedPracticeName] = useState([]);
  const [yearDropdownData, setYearDropdownData] = useState([]);
  const [selectedYear, setSelectedYear] = useState([]);
  const [pcpNameDropdownData, setPCPNameDropdownData] = useState([]);
  const [selectedPCPName, setSelectedPCPName] = useState([]);
  const [showDetailsPage, setShowDetailsPage] = useState(false);
  const [isLoadingRegionName, setIsLoadingRegionName] = useState(true);
  const [isLoadingPayerName, setIsLoadingPayerName] = useState(true);
  const [isLoadingProductName, setIsLoadingProductName] = useState(true);
  const [isLoadingMarketName, setIsLoadingMarketName] = useState(true);
  const [isLoadingPracticeName, setIsLoadingPracticeName] = useState(true);
  const [isLoadingPcpName, setIsLoadingPcpName] = useState(true);
  const [isAllFiltersSelected, setIsAllFiltersSelected] = useState(false);
  const [memberOurId, setMemberOurId] = useState(0);
  const [memberPayerName, setMemberPayerName] = useState("");
  const [showSDOHPage, setShowSDOHPage] = useState(false);
  const [isNewAssessment, setIsNewAssessment] = useState(false);
  const [sdohSubmittedDate, setSDOHSubmittedDate] = useState(false);
  const [memberRiskScore, setMemberRiskScore] = useState("");
  const [providerName, setProviderName] = useState("");
  const [practiceName, setPracticeName] = useState("");
  const [isApplyFilterDisable, setIsApplyFilterDisable] = useState(true);
  const [page, setPage] = useState(0);
  const [sortModel, setSortModel] = useState([
    { field: "memberName", sort: "asc" },
  ]);
  const [searchMemberName, setSearchMemberName] = useState("");
  const [searchMember, setSearchMember] = useState("");
  const [forceUpdate, setForceUpdate] = useState(0);
  const {
    isLoading,
    activeGapCategoryList,
    activeGapClosuresList,
    activeGapClosuresCount,
    errorMessage,
    activeGapClosuresListCount,
  } = useSelector((state) => state.activeGapClosures);
  //State initialization ends

  //Filter Functionalities
  const [isApplyFilter, setIsApplyFilter] = useState(false);
  const [priority, setPriority] = useState("");
  const [isClearSections, setIsClearSections] = useState(false);

  const [validatepayer, setValidatepayer] = useState("");
  const [validateproduct, setValidateproduct] = useState("");
  const [validatemarket, setValidatemarket] = useState("");
  const [validatesubmarket, setValidatesubmarket] = useState("");
  const [validatepractice, setValidatepractice] = useState("");
  const [validatepcp, setValidatepcp] = useState("");
  // setValidateStatus(statusVal);
  const [validateyear, setValidateyear] = useState("");
  const [validateForceUpdate, setValidateForceUpdate] = useState("");
  const [validatepagenumber, setValidatepagenumber] = useState("");
  const [validatesortcolumn, setValidatesortcolumn] = useState("");
  const [validatesortorder, setValidatesortorder] = useState("");
  const [validateMemberName, setValidateMemberName] = useState("");
  const [validateStatus, setValidateStatus] = useState("");
  const [showMemberExcludedSnackbar, setShowMemberExcludedSnackbar] =
    useState(false);
  const [isNoDataLoad, setIsNoDataLoad] = useState(false);
  const [pageTitle, setPageTitle] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [activeStatus, setActiveStatus] = useState("Total Open");
  const [activeBackgroundColor, setActiveBackgroundColor] = useState("rgba(253, 247, 247, 1)");
  const [activeBorderColor, setActiveBorderColor] = useState("rgba(211, 47, 47, 1)");
  const [popupConfirmationModalOpen, SetPopupConfirmationModalOpen] = useState(false);
  const [popupConfirmationModalData, setPopupConfirmationModalData] = useState({});
  const [isTotalOpen, setIsTotalOpen] = useState(true);
  const [isAuditPending, setIsAuditPending] = useState(false);
  const [isExcluded, setIsExcluded] = useState(false);
  const [isAuditAccepted, setIsAuditAccepted] = useState(false);
  const [isAuditRejected, setIsAuditRejected] = useState(false);
  const[isCountDisplay,setIsCountDisplay]=useState(false);

  //Select All initialization Starts
  const isAllPayersSelected =
    payerNameDropdownData.length > 0 &&
    selectedPayerName.length === payerNameDropdownData.length;
  const isAllProductsSelected =
    productNameDropdownData.length > 0 &&
    selectedProductName.length === productNameDropdownData.length;
  const isAllMarketsSelected =
    marketNameDropdownData.length > 0 &&
    selectedMarketName.length === marketNameDropdownData.length;
  const isAllRegionsSelected =
    regionNameDropdownData.length > 0 &&
    selectedRegionName.length === regionNameDropdownData.length;
  const isAllPracticeSelected =
    practiceNameDropdownData.length > 0 &&
    selectedPracticeName.length === practiceNameDropdownData.length;
  const isAllPcpsSelected =
    pcpNameDropdownData.length > 0 &&
    selectedPCPName.length === pcpNameDropdownData.length;
  const isAllYearsSelected =
    yearDropdownData.length > 0 &&
    selectedYear.length === yearDropdownData.length;
  //Select All initialization Ends

  //API Call Functions Start
  const getPayerNamesAPI = () => {
    return axiosRequests.get("/Master/GetPayerList");
  };

  const getProductNamesAPI = (payerIds) => {
    return axiosRequests.get(
      `/Master/GetProductList?payerIds=${payerIds.join(",")}`
    );
  };

  const getMarketNamesAPI = (payerIds, productIds) => {
    return axiosRequests.get(
      `/Master/GetMarketList?payerIds=${payerIds.join(
        ","
      )}&ProductIds=${productIds.join(",")}`
    );
  };

  const getRegionNamesAPI = (payerIds, productIds, marketIDs) => {
    return axiosRequests.get(
      `/Master/GetSubMarketList?marketIDs=${marketIDs.join(
        ","
      )}&payerIds=${payerIds.join(",")}&productIds=${productIds.join(",")}`
    );
  };

  const getPracticeNamesAPI = (payerIds, productIds, marketIDs, regionIDs) => {
    return axiosRequests.get(
      `/Master/GetPracticeList?marketIDs=${marketIDs.join(
        ","
      )}&subMarketIDs=${regionIDs.join(",")}&payerIds=${payerIds.join(
        ","
      )}&productIds=${productIds.join(",")}`
    );
  };

  const getPcpNamesAPI = (
    payerIds,
    productIds,
    marketIDs,
    regionIDs,
    practiceIDs
  ) => {
    let formData = {
      payerIds: `${payerIds.join(",")}`,
      productIds: `${productIds.join(",")}`,
      marketIds: `${marketIDs.join(",")}`,
      subMarketIds: `${regionIDs.join(",")}`,
      practiceIds: `${practiceIDs.join(",")}`,
    };
    return axiosRequests.post(`/Master/GetPcpList`, formData);
  };

  const getActiveGapClosuresListData = useCallback(
    async (requestBody) => {
      const response = await dispatch(getActiveGapClosuresList(requestBody));
    },
    [dispatch]
  );
  //API Call Functions End

  //Initial Data Load Functions Start
  useEffect(() => {
    const loadData = async () => {
      const today = new Date();
      const year = today.getFullYear();
      var selectedPayerIds,
        selectedProductIds,
        selectedMarketIDs,
        selectedRegionIDs,
        selectedPracticeIDs,
        selectedPcpIDs,
        selectedYearIDs;

      await getPayerNamesAPI().then((resp) => {
        let arr = [...resp];
        let temparr = [];
        arr = arr.map((detail) => {
          if (detail.payerName === 'AH ACO REACH' ||
            detail.payerName === 'AIP ACO REACH' ||
            detail.payerName === 'CINQ ACO REACH' ||
            detail.payerName === 'FIDELIS' ||
            detail.payerName === 'MOLINA'
          ) {
            temparr.push(detail);
          }
        });
        setPayerNameDropdownData(resp);
        selectedPayerIds = temparr.map((item) => item.payerId);
        setSelectedPayerName(selectedPayerIds);
      });
      await getProductNamesAPI(selectedPayerIds).then((resp) => {
        setProductNameDropdownData(resp);
        selectedProductIds = resp.map((item) => item.productId);
        setIsLoadingProductName(false);
        setSelectedProductName(selectedProductIds);
      });
      await getMarketNamesAPI(selectedPayerIds, selectedProductIds).then(
        (resp) => {
          setMarketNameDropdownData(resp);
          selectedMarketIDs = resp.map((item) => item.marketId);
          setIsLoadingMarketName(false);
          setSelectedMarketName(selectedMarketIDs);
        }
      );
      await getRegionNamesAPI(
        selectedPayerIds,
        selectedProductIds,
        selectedMarketIDs
      ).then((resp) => {
        selectedRegionIDs = resp.map((item) => item.id);
        setSelectedRegionName(selectedRegionIDs);
        setIsLoadingRegionName(false);
        setRegionNameDropdownData(resp);
      });
      await getPracticeNamesAPI(
        selectedPayerIds,
        selectedProductIds,
        selectedMarketIDs,
        selectedRegionIDs
      ).then((resp) => {
        selectedPracticeIDs = resp.map((item) => item.practiceId);
        setPracticeNameDropdownData(resp);
        setIsLoadingPracticeName(false);
        setSelectedPracticeName(selectedPracticeIDs);
      });
      await getPcpNamesAPI(
        selectedPayerIds,
        selectedProductIds,
        selectedMarketIDs,
        selectedRegionIDs,
        selectedPracticeIDs
      ).then((resp) => {
        // console.log("pcp-resp", resp);
        selectedPcpIDs = resp.map((item) => item.pcP_ID);
        setPCPNameDropdownData(resp);
        setIsLoadingPcpName(false);
        setSelectedPCPName(selectedPcpIDs);
        setIsNoDataLoad(true);
      });
      let yearIDs = [];

      yearIDs.push({ year: year });
      yearIDs.push({ year: year + 1 });
      setYearDropdownData(yearIDs);
      selectedYearIDs = yearIDs.map((item) => item.year);
      setSelectedYear(selectedYearIDs);
    };
    loadData();
  }, []);

  //Initial Data Load Functions End

  //Data Import Funtions Start
  const getProductList = (payerId) => {
    getProductNamesAPI(payerId).then((resp) => {
      setProductNameDropdownData(resp);
      setIsLoadingProductName(false);
      var IDs = resp.map((item) => item.productId);
      var selectedProduct = selectedProductName.filter((item) =>
        IDs.includes(item)
      );
      setSelectedProductName(selectedProduct);
      if (selectedProduct && selectedProduct.length > 0) {
        getMarketList(payerId, selectedProduct);
        if (selectedRegionName && selectedRegionName.length > 0) {
          getPracticeList(
            payerId,
            selectedProduct,
            selectedMarketName,
            selectedRegionName
          );
        } else {
          // setSelectedRegionName([]);
          // setSelectedPracticeName([]);
          setPracticeNameDropdownData([]);
          setIsLoadingPracticeName(true);
        }
      } else {
        setRegionNameDropdownData([]);
        setIsLoadingRegionName(true);
        setSelectedRegionName([]);
        setPracticeNameDropdownData([]);
        setIsLoadingPracticeName(true);
        setSelectedPracticeName([]);
      }
    });
  };

  const getMarketList = (payerId, productId) => {
    getMarketNamesAPI(payerId, productId).then((resp) => {
      setMarketNameDropdownData(resp);
      var IDs = resp.map((item) => item.marketId);
      var selected = selectedMarketName.filter((item) => IDs.includes(item));
      setSelectedMarketName(selected);
      if (selected && selected.length > 0) {
        getRegionList(payerId, productId, selected);
        if (selectedRegionName && selectedRegionName.length > 0) {
          getPracticeList(payerId, productId, selected, selectedRegionName);
        } else {
          // setSelectedRegionName([]);
          // setSelectedPracticeName([]);
          setPracticeNameDropdownData([]);
          setIsLoadingPracticeName(true);
        }
      } else {
        setRegionNameDropdownData([]);
        setIsLoadingRegionName(true);
        setSelectedRegionName([]);
        setPracticeNameDropdownData([]);
        setIsLoadingPracticeName(true);
        setSelectedPracticeName([]);
      }
    });
  };

  const getRegionList = (payerNames, productNames, marketNames) => {
    getRegionNamesAPI(payerNames, productNames, marketNames).then((resp) => {
      setRegionNameDropdownData(resp);
      setIsLoadingRegionName(false);
      var IDs = resp.map((item) => item.id);
      var selected = selectedRegionName.filter((item) => IDs.includes(item));
      setSelectedRegionName(selected);
      if (selected && selected.length > 0) {
        getPracticeList(
          selectedPayerName,
          selectedProductName,
          selectedMarketName,
          selected
        );
      } else {
        setPracticeNameDropdownData([]);
        setIsLoadingPracticeName(true);
        setSelectedPracticeName([]);
      }
    });
  };

  const getPracticeList = (
    payerNames,
    productNames,
    marketNames,
    regionNames
  ) => {
    getPracticeNamesAPI(
      payerNames,
      productNames,
      marketNames,
      regionNames
    ).then((resp) => {
      setPracticeNameDropdownData(resp);
      setIsLoadingPracticeName(false);
      var IDs = resp.map((item) => item.practiceId);
      var selected = selectedPracticeName.filter((item) => IDs.includes(item));
      setSelectedPracticeName(selected);
    });
  };

  const getPcpList = (
    payerNames,
    productNames,
    marketNames,
    regionNames,
    practiceNames
  ) => {
    getPcpNamesAPI(
      payerNames,
      productNames,
      marketNames,
      regionNames,
      practiceNames
    ).then((resp) => {
      setPCPNameDropdownData(resp);
      setIsLoadingPcpName(false);
      var IDs = resp.map((item) => item.pcP_ID);
      var selected = selectedPCPName.filter((item) => IDs.includes(item));
      setSelectedPCPName(selected);
    });
  };
  //Data Import Functions End

  //Grid Data Load Function Starts
  useEffect(() => {
    if ((isClearSections || isApplyFilter) && selectedPayerName &&
      selectedPayerName.length > 0 &&
      selectedProductName &&
      selectedProductName.length > 0 &&
      selectedMarketName &&
      selectedMarketName.length > 0 &&
      selectedRegionName &&
      selectedRegionName.length > 0 &&
      selectedPracticeName &&
      selectedPracticeName.length > 0 &&
      selectedPCPName &&
      selectedPCPName.length > 0 &&
      selectedYear &&
      selectedYear.length > 0
    ) {
      setIsClearSections(false);
      setIsApplyFilter(true);
      setIsApplyFilterDisable(false);
    }
    else {
      setIsApplyFilter(false);

      setIsApplyFilterDisable(true);
    }
    if (selectedPayerName &&
      selectedPayerName.length > 0 &&
      selectedProductName &&
      selectedProductName.length > 0 &&
      selectedMarketName &&
      selectedMarketName.length > 0 &&
      selectedRegionName &&
      selectedRegionName.length > 0 &&
      selectedPracticeName &&
      selectedPracticeName.length > 0 &&
      selectedPCPName &&
      selectedPCPName.length > 0 &&
      selectedYear &&
      selectedYear.length > 0
    ) {
    getActiveGapClosuresListCallValidate(1, "", sortModel[0].field, sortModel[0].sort, forceUpdate);
    }
    
  }, [selectedMarketName, selectedRegionName, selectedPracticeName, selectedPCPName, selectedYear, isApplyFilter, isClearSections]);

  const getActiveGapClosuresListCallValidate = (pageVal, searchVal, sortColumn, sortOrder, forceUpdateVal) => {
    var prevValue = isAllFiltersSelected;
    if (!isApplyFilter && !isClearSections &&
      (selectedPayerName &&
        selectedPayerName.length > 0 &&
        selectedProductName &&
        selectedProductName.length > 0 &&
        selectedMarketName &&
        selectedMarketName.length > 0 &&
        selectedRegionName &&
        selectedRegionName.length > 0 &&
        selectedPracticeName &&
        selectedPracticeName.length > 0 &&
        selectedPCPName &&
        selectedPCPName.length > 0 &&
        selectedYear &&
        selectedYear.length > 0)
    ) {
      //   setIsAllFiltersSelected(true);
      // } else {
      //   setIsAllFiltersSelected(false);
      setIsApplyFilterDisable(true);
      getActiveGapClosuresListCall(pageVal, searchVal, sortColumn, sortOrder,forceUpdateVal);
    }
    else if (selectedPayerName &&
      selectedPayerName.length > 0 &&
      selectedProductName &&
      selectedProductName.length > 0 &&
      selectedMarketName &&
      selectedMarketName.length > 0 &&
      selectedRegionName &&
      selectedRegionName.length > 0 &&
      selectedPracticeName &&
      selectedPracticeName.length > 0 &&
      selectedPCPName &&
      selectedPCPName.length > 0 &&
      selectedYear &&
      selectedYear.length > 0
    ) {
      setIsClearSections(false);
      setIsApplyFilterDisable(false);
    }
  }

  useEffect(() => {
    getActiveGapClosuresListCallValidate(
      page,
      searchMember,
      sortModel[0].field,
      sortModel[0].sort,
      forceUpdate
    );
  }, [page]);

  useEffect(() => {
    getActiveGapClosuresListCallValidate(
      1,
      searchMember,
      sortModel[0].field,
      sortModel[0].sort,
      forceUpdate
    );
  }, [searchMember]);

  useEffect(() => {
    setIsTotalOpen(false);
    setIsAuditAccepted(false);
    setIsAuditPending(false);
    setIsAuditRejected(false);
    setIsExcluded(false);
    setSortModel([{ field: 'memberName', sort: 'asc' }]);
    if (activeStatus == "Total Open"){
      setPageTitle("Patient Assessment Form");
      setIsTotalOpen(true);
    }
    else if (activeStatus == "Audit Accepted"){
      setPageTitle("Attestations- Audit Accepted");
      setIsAuditAccepted(true);
    }
    else if (activeStatus == "Audit Pending"){
      setPageTitle("Attestations- Audit Pending");
      setIsAuditPending(true);
    }
    else if (activeStatus == "Audit Rejected"){
      setPageTitle("Attestations- Audit Rejected");
      setIsAuditRejected(true);
    }
    else if (activeStatus == "Excluded"){
      setPageTitle("Attestations- Excluded");
      setIsExcluded(true);
    }
    else
      setPageTitle("Patient Assessment Form");

      setSearchMemberName("");
      setSearchMember("");
      setTimeout(() => {
        getActiveGapClosuresListCallValidate(
          1,
          "",
          sortModel[0].field,
          sortModel[0].sort,
          forceUpdate
        );
      })
  }, [activeStatus]);

  useEffect(()=>{
    setSearchMemberName("");
    setSearchMember("");
    getActiveGapClosuresListCallValidate(
      1,
      "",
      sortModel[0].field,
      sortModel[0].sort,
      forceUpdate
    );
  },[forceUpdate])
  
  useEffect(() => {
    if (activeGapCategoryList && activeGapCategoryList.length > 0){
      setIsCountDisplay(true);
      setCategoryList(activeGapCategoryList);
    }
  }, [activeGapCategoryList])

  const getActiveGapClosuresListCall = (pageVal, searchVal, sortColumn, sortOrder,forceUpdateVal) => {
    var payerIds,
      productIds,
      marketIDs,
      subMarketIDs,
      practiceIDs,
      pcpIDs,
      yearIDs,

    payerIds = selectedPayerName.join(",");
    productIds = selectedProductName.join(",");
    marketIDs = selectedMarketName.join(",");
    subMarketIDs = selectedRegionName.join(",");
    practiceIDs = selectedPracticeName.join(",");
    pcpIDs = selectedPCPName.join(",");
    yearIDs = selectedYear.join(",");

    if (sortColumn == "") sortColumn = "memberName"; //sortColumn,
    if (sortOrder == "") sortOrder = "asc"; //sortOrd
    if (
      forceUpdateVal != validateForceUpdate ||
      payerIds != validatepayer ||
      productIds != validateproduct ||
      marketIDs != validatemarket ||
      subMarketIDs != validatesubmarket ||
      practiceIDs != validatepractice ||
      pcpIDs != validatepcp ||
      yearIDs != validateyear ||
      (activeStatus != validateStatus) ||
      pageVal != validatepagenumber ||
      sortColumn != validatesortcolumn ||
      sortOrder != validatesortorder ||
      searchVal != validateMemberName
    ) {
      // setIsStatusLoading(true);
      setValidatepayer(payerIds);
      setValidateproduct(productIds);
      setValidatemarket(marketIDs);
      setValidatesubmarket(subMarketIDs);
      setValidatepractice(practiceIDs);
      setValidatepcp(pcpIDs);
      setValidateyear(yearIDs);
      setValidateForceUpdate(forceUpdateVal);
      setValidatepagenumber(pageVal);
      setValidatesortcolumn(sortColumn);
      setValidatesortorder(sortOrder);
      setValidateMemberName(searchVal);
      setValidateStatus(activeStatus);
      setPage(pageVal);
      setIsApplyFilter(false);
      setIsClearSections(false);
      var requestBody = {
        pageNumber: pageVal === 0 ? 1 : pageVal,
        rowsPerPage: 10,
        sortColumn:sortColumn,
          // !sortModel || sortModel.length === 0
          //   ? "memberName"
          //   : sortModel[0].field,
        sortOrder:sortOrder,
          // !sortModel || sortModel.length === 0 ? "asc" : sortModel[0].sort,
        payerIds: selectedPayerName.join(","),
        productIds: selectedProductName.join(","),
        marketIDs: selectedMarketName.join(","),
        subMarketIDs: selectedRegionName.join(","),
        practiceIDs: selectedPracticeName.join(","),
        pcpIDs: selectedPCPName.join(","),
        // yearIDs: selectedYear.join(","),
        memberName: searchVal ? searchVal : "",
        status: activeStatus
      };
      getActiveGapClosuresListData(requestBody);
    }
  };
  //Grid Data Load Function Ends

  //Refresh Grid if Snack bar is displayed
  useEffect(() => {
    if (showMemberExcludedSnackbar)
      setForceUpdate(forceUpdate + 1);
  }, [showMemberExcludedSnackbar]);

  //Refresh Grid Ends

  //Change functions start
  const handlePayerNameChange = (e) => {
    var value = e.target.value;

    if (value[value.length - 1] === "all") {
      var payerIDs = payerNameDropdownData.map((item) => item.payerId);
      var selectedValues =
        selectedPayerName.length === payerNameDropdownData.length
          ? []
          : payerNameDropdownData.map((item) => item.payerId);
      setSelectedPayerName(selectedValues);
      if (selectedValues && selectedValues.length > 0) {
        setIsLoadingProductName(false);
        setIsApplyFilter(true);
        getProductList(payerIDs);
      } else {
        setProductNameDropdownData([]);
        setMarketNameDropdownData([]);
        setSelectedMarketName([]);
        setRegionNameDropdownData([]);
        setSelectedRegionName([]);
        setPracticeNameDropdownData([]);
        setSelectedProductName([]);
        setSelectedPracticeName([]);
        setPCPNameDropdownData([]);
        setSelectedPCPName([]);
        setIsLoadingProductName(true);
        setIsLoadingMarketName(true);
        setIsLoadingRegionName(true);
        setIsLoadingPracticeName(true);
        setIsLoadingPcpName(true);
        setIsClearSections(true);
      }
      return;
    }
    setSelectedPayerName(typeof value === "string" ? value.split(",") : value);
    if (value && value.length > 0) {
      setIsLoadingProductName(false);
      setIsApplyFilter(true);
      getProductList(value);
    } else {
      setProductNameDropdownData([]);
      setMarketNameDropdownData([]);
      setSelectedMarketName([]);
      setRegionNameDropdownData([]);
      setSelectedRegionName([]);
      setPracticeNameDropdownData([]);
      setSelectedPracticeName([]);
      setSelectedProductName([]);
      setPCPNameDropdownData([]);
      setSelectedPCPName([]);
      setIsLoadingProductName(true);
      setIsLoadingMarketName(true);
      setIsLoadingRegionName(true);
      setIsLoadingPracticeName(true);
      setIsLoadingPcpName(true);
      setIsClearSections(true);
    }
    // setPayerNameErrorText("");
  };

  const handleProductNameChange = (e) => {
    var value = e.target.value;

    if (value[value.length - 1] === "all") {
      var productIDs = productNameDropdownData.map((item) => item.productId);
      var selectedValues =
        selectedProductName.length === productNameDropdownData.length
          ? []
          : productNameDropdownData.map((item) => item.productId);
      setSelectedProductName(selectedValues);
      if (selectedValues && selectedValues.length > 0) {
        setIsLoadingMarketName(false);
        setIsApplyFilter(true);
        getMarketList(selectedPayerName, productIDs);
      } else {
        setMarketNameDropdownData([]);
        setSelectedMarketName([]);
        setRegionNameDropdownData([]);
        setSelectedRegionName([]);
        setPracticeNameDropdownData([]);
        setSelectedPracticeName([]);
        setPCPNameDropdownData([]);
        setSelectedPCPName([]);
        setIsLoadingMarketName(true);
        setIsLoadingRegionName(true);
        setIsLoadingPracticeName(true);
        setIsLoadingPcpName(true);
        setIsClearSections(true);
      }
      return;
    }
    setSelectedProductName(
      typeof value === "string" ? value.split(",") : value
    );
    if (value && value.length > 0) {
      setIsLoadingMarketName(false);
      setIsApplyFilter(true);
      getMarketList(selectedPayerName, value);
    } else {
      setMarketNameDropdownData([]);
      setSelectedMarketName([]);
      setRegionNameDropdownData([]);
      setSelectedRegionName([]);
      setPracticeNameDropdownData([]);
      setSelectedPracticeName([]);
      setPCPNameDropdownData([]);
      setSelectedPCPName([]);
      setIsLoadingMarketName(true);
      setIsLoadingRegionName(true);
      setIsLoadingPracticeName(true);
      setIsLoadingPcpName(true);
      setIsClearSections(true);
    }
    // setProductNameErrorText("");
  };

  const handleMarketNameChange = (e) => {
    var value = e.target.value;

    if (value[value.length - 1] === "all") {
      var marketIDs = marketNameDropdownData.map((item) => item.marketId);
      var selectedValues =
        selectedMarketName.length === marketNameDropdownData.length
          ? []
          : marketNameDropdownData.map((item) => item.marketId);
      setSelectedMarketName(selectedValues);
      if (selectedValues && selectedValues.length > 0) {
        setIsApplyFilter(true);
        getRegionList(selectedPayerName, selectedProductName, selectedValues);
      } else {
        setIsClearSections(true);
        setRegionNameDropdownData([]);
        setSelectedRegionName([]);
        setPracticeNameDropdownData([]);
        setSelectedPracticeName([]);
        setPCPNameDropdownData([]);
        setSelectedPCPName([]);
        setIsLoadingRegionName(true);
        setIsLoadingPracticeName(true);
        setIsLoadingPcpName(true);
      }
      return;
    }
    setSelectedMarketName(typeof value === "string" ? value.split(",") : value);
    if (!value || value.length === 0) {
      setIsClearSections(true);
      setRegionNameDropdownData([]);
      setSelectedRegionName([]);
      setPracticeNameDropdownData([]);
      setSelectedPracticeName([]);
      setPCPNameDropdownData([]);
      setSelectedPCPName([]);
      setIsLoadingRegionName(true);
      setIsLoadingPracticeName(true);
      setIsLoadingPcpName(true);
    } else {
      setIsApplyFilter(true);
      getRegionList(selectedPayerName, selectedProductName, value);
    }
  };

  const handleRegionNameChange = (e) => {
    var value = e.target.value;

    if (value[value.length - 1] === "all") {
      var regionIDs = regionNameDropdownData.map((item) => item.id);
      var selectedValues =
        selectedRegionName.length === regionNameDropdownData.length
          ? []
          : regionNameDropdownData.map((item) => item.id);
      setSelectedRegionName(selectedValues);
      if (selectedValues && selectedValues.length > 0) {
        setIsApplyFilter(true);
        getPracticeList(
          selectedPayerName,
          selectedProductName,
          selectedMarketName,
          selectedValues
        );
      } else {
        setIsClearSections(true);
        setPracticeNameDropdownData([]);
        setSelectedPracticeName([]);
        setPCPNameDropdownData([]);
        setSelectedPCPName([]);
        setIsLoadingPracticeName(true);
        setIsLoadingPcpName(true);
      }
      return;
    }
    setSelectedRegionName(typeof value === "string" ? value.split(",") : value);
    if (!value || value.length === 0) {
      setIsClearSections(true);
      setPracticeNameDropdownData([]);
        setSelectedPracticeName([]);
      setPCPNameDropdownData([]);
      setSelectedPCPName([]);
      setIsLoadingPcpName(true);
    } else {
      setIsApplyFilter(true);
      getPracticeList(
        selectedPayerName,
        selectedProductName,
        selectedMarketName,
        value
      );
    }
  };

  const handlePracticeNameChange = (e) => {
    var value = e.target.value;

    if (value[value.length - 1] === "all") {
      var regionIDs = regionNameDropdownData.map((item) => item.practiceId);
      var selectedValues =
        selectedPracticeName.length === practiceNameDropdownData.length
          ? []
          : practiceNameDropdownData.map((item) => item.practiceId);
      setSelectedPracticeName(selectedValues);
      if (selectedValues && selectedValues.length > 0) {
        setIsApplyFilter(true);
        setSelectedPCPName([]);
        getPcpNamesAPI(
          selectedPayerName,
          selectedProductName,
          selectedMarketName,
          selectedRegionName,
          selectedValues
        ).then((resp) => {
          setPCPNameDropdownData(resp);
          setIsLoadingPcpName(false);
          // var IDs = resp.map((item) => item.pcP_ID);
          // var selected = selectedPCPName.filter((item) => IDs.includes(item));
          // setSelectedPCPName(selected);
        });
      } else {
        setIsClearSections(true);
        setPCPNameDropdownData([]);
        setSelectedPCPName([]);
        setIsLoadingPcpName(true);
      }
      return;
    }
    setSelectedPracticeName(typeof value === "string" ? value.split(",") : value);
    if (!value || value.length === 0) {
      setIsClearSections(true);
      setPCPNameDropdownData([]);
      setSelectedPCPName([]);
      setIsLoadingPcpName(true);
    } else {
      setSelectedPCPName([]);
      setIsClearSections(true);
      setIsApplyFilter(true);
      getPcpNamesAPI(
        selectedPayerName,
        selectedProductName,
        selectedMarketName,
        selectedRegionName,
        value
      ).then((resp) => {
        setPCPNameDropdownData(resp);
        setIsLoadingPcpName(false);
        // var IDs = resp.map((item) => item.pcP_ID);
        // var selected = selectedPCPName.filter((item) => IDs.includes(item));
        // setSelectedPCPName(selected);
      });
    }
  };

  const handlePcpNameChange = (e) => {
    var value = e.target.value;

    if (value[value.length - 1] === "all") {
      var practiceIDs = practiceNameDropdownData.map((item) => item.practiceId);
      var selectedValues =
        selectedPCPName.length === pcpNameDropdownData.length
          ? []
          : pcpNameDropdownData.map((item) => item.pcP_ID);
      setSelectedPCPName(selectedValues);
      if (selectedValues && selectedValues.length > 0) {
        setIsApplyFilter(true);
      }
      else {
        setIsClearSections(true);
      }
      return;
    }
    setSelectedPCPName(
      typeof value === "string" ? value.split(",") : value
    );
    if (value && value.length > 0) {
      setIsApplyFilter(true);
    }
    else {
      setIsClearSections(true);
    }
  }

  const handleYearChange = (e) => {
    var value = e.target.value;
    if (value[value.length - 1] === "all") {
      var selectedValues =
        selectedYear.length === yearDropdownData.length
          ? []
          : yearDropdownData.map((item) => item.year);
      setSelectedYear(selectedValues);
      if (selectedValues && selectedValues.length > 0) {
        setIsApplyFilter(true);
      }
      else {
        setIsClearSections(true);
      }
      return;
    }
    setSelectedYear(
      typeof value === "string" ? value.split(",") : value
    );
    if (value && value.length > 0) {
      setIsApplyFilter(true);
    }
    else {
      setIsClearSections(true);
    }
  }

  const handleApplyFilter = () => {
    setIsApplyFilter(false);
    setIsClearSections(false);
    setCategoryList([]);
    getActiveGapClosuresListCallValidate(
      1,
      "",
      sortModel[0].field,
      sortModel[0].sort,
      forceUpdate
    );
  };

  const handleClearFilterChange = () => {
    setSelectedPayerName([]);
    setSelectedProductName([]);
    setIsLoadingProductName(true);
    setSelectedMarketName([]);
    setIsLoadingMarketName(true);
    setSelectedRegionName([]);
    setIsLoadingRegionName(true);
    setSelectedPracticeName([]);
    setIsLoadingPracticeName(true);
    setSelectedPCPName([]);
    setIsLoadingPcpName(true);
    setSelectedYear([]);
    setIsApplyFilter(true);
    setIsClearSections(true);
    setIsApplyFilterDisable(true);
    setValidatepayer("");
    setValidateproduct("");
    setValidatemarket("");
    setValidatesubmarket("");
    setValidatepractice("");
    setValidatepcp("");
    setValidateyear("");
    setActiveStatus("Total Open");
    setIsTotalOpen(true);
    setCategoryList([]);
    // activeGapClosuresList([]);
    // setValidateStatus(statusVal);
    setValidatepagenumber("");
    setValidatesortcolumn("");
    setValidatesortorder("");
  };

  const handleSortModelChange = (sortModel) => {
    let sort = null;
    if (sortModel.length > 0) {
      const { field, sort: sortOrder } = sortModel[0];
      sort = `${field}:${sortOrder}`;
      if (sortOrder === "asc") {
        sort = `${field}:desc`;
      }
    }
    setSortModel(sortModel);
    setPage(1);
    getActiveGapClosuresListCallValidate(
      1,
      "",
      sortModel[0].field,
      sortModel[0].sort,
      forceUpdate
    );
  };

  //Change functions end
  const closePopupModal = () => {
    SetPopupConfirmationModalOpen(false);
  }

  const openMemberDetailsModal = (memberDtl) => {
    setPopupConfirmationModalData(memberDtl);
    SetPopupConfirmationModalOpen(true);
  }

  //SDOH Assessment Code Starts
  const handleNewSDOHAssessmentClick = (
    ourId,
    submittedDate,
    riskScore,
    practiceName,
    providerName
  ) => {
    setSDOHSubmittedDate(submittedDate);
    setMemberRiskScore(riskScore);
    setPracticeName(practiceName);
    setProviderName(providerName);
    setShowSDOHPage(true);
    setMemberOurId(ourId);
  };
  //SDOH Assessment Code Ends

  //Data Grid Columns Start
  const columnsTotalOpen = [
    // {
    //   field: "insuranceID",
    //   headerName: "Member Insurance Id",
    //   flex: 1,
    //   sortable: false,
    //   headerClassName: "classMultilineHeader",
    //   renderCell: (params) => {
    //     return (
    //       <Link
    //         style={{
    //           textDecoration: "none",
    //           color: "inherit",
    //         }}
    //       >
    //         <div
    //           onClick={() =>
    //             viewMemberDetails(params.row.ourId, params.row.payerName)
    //           }
    //         >
    //           <u>{params.row.insuranceID}</u>
    //         </div>
    //       </Link>
    //     );
    //   },
    // },
    {
      field: "memberName",
      headerName: "Member Name",
      headerClassName: "classMultilineHeader",
      flex: 1.5,
      renderCell: (params) => {
        return (
          <div style={{display:"flex", alignItems:"center"}}>
            <IconButton
              onClick={() => openMemberDetailsModal(params.row)}
              disableFocusRipple={true}
              disableRipple={true}
              type="button"
            >
              <img
                src={memberDetailsIcon}

              />
            </IconButton>
            <Link
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <div
                onClick={() =>
                  viewMemberDetails(params.row.ourId, params.row.payerName, params.row.priority)
                }
              >
                <u>{params.row.memberName}</u>
              </div>
            </Link>
          </div>
        );
      },
    },
    {
      field: "dob",
      headerName: "Member DOB",
      headerClassName: "classMultilineHeader",
      flex: 1,
      renderCell: (params) => {
          return <div>{format(params.row.dob, "dd MMM yyyy")}</div>;  //"dd/MM/yyyy"
      },
    },
    {
      field: "payerName",
      headerName: "Payer",
      headerClassName: "classMultilineHeader",
      flex: 1,
    },
    {
      field: "practiceName",
      headerName: "Practice Name",
      headerClassName: "classMultilineHeader",
      flex: 1,
    },
    {
      field: "providerName",
      headerName: "Provider Name",
      headerClassName: "classMultilineHeader",
      flex: 1,
    },
    
    {
      field: "priority",
      headerName: "Priority",
      headerClassName: "classMultilineHeader",
      flex: 1,
    },
    {
      field: "riskScore",
      headerName: "Risk Score",
      // sortable: false,
      type: "number",
      headerClassName: "classMultilineHeader",
      flex: .6,
    },
    {
      field: "openQualityMeasuresCount",
      // sortable: false,
      type: "number",
      headerName: "Open Quality Measures",
      headerClassName: "classMultilineHeader",
      flex: 1,
    },
    {
      field: "medicalCodingSuspectsCount",
      // sortable: false,
      type: "number",
      headerName : "Recapture Codes",
      headerClassName: "classMultilineHeader",
      flex: 1,
    },
    {
      field: "sdohCodingSuspectsCount",
      // sortable: false,
      type: "number",
      // headerName: "SDOH Coding Suspects Count",
      headerName : "SDOH Recapture Codes",
      headerClassName: "classMultilineHeader",
      flex: 1,
    },
    {
      field: "isSDOHAssessmentSubmitted",
      sortable: false,
      headerName: "SDOH Assessment",
      headerClassName: "classMultilineHeader",
      align: "center",
      flex: 1.1,
      renderCell: (params) => {
        if (params.row.isSDOHAssessmentSubmitted) {
          return (
            <Tooltip title="View Assessment">
              <div
                onClick={() => {
                  handleNewSDOHAssessmentClick(
                    params.row.ourId,
                    params.row.sdohSubmittedDate,
                    params.row.memberRiskScore,
                    params.row.practiceName,
                    params.row.providerName
                  );
                  setIsNewAssessment(false);
                }}
              >
                <VisibilityOutlinedIcon />
              </div>
            </Tooltip>
          );
        } else
          return (
            <Tooltip title="Start New Assessment">
              <div
                onClick={() => {
                  handleNewSDOHAssessmentClick(params.row.ourId);
                  setIsNewAssessment(true);
                }}
              >
                <AssignmentOutlinedIcon />
              </div>
            </Tooltip>
          );
      },
    },
  ];

  const columnsAuditPending = [
    {
      field: "memberName",
      headerName: "Member Name",
      headerClassName: "classMultilineHeader",
      flex: 1.5,
      renderCell: (params) => {
        return (
          <div style={{display:"flex", alignItems:"center"}}>
            <IconButton
              onClick={() => openMemberDetailsModal(params.row)}
              disableFocusRipple={true}
              disableRipple={true}
              type="button"
            >
              <img
                src={memberDetailsIcon}

              />
            </IconButton>
            <Link
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <div
                onClick={() =>
                  viewMemberDetails(params.row.ourId, params.row.payerName, params.row.priority)
                }
              >
                <u>{params.row.memberName}</u>
              </div>
            </Link>
          </div>
        );
      },
    },
    {
      field: "dob",
      headerName: "Member DOB",
      headerClassName: "classMultilineHeader",
      flex: 1,
      renderCell: (params) => {
          return <div>{format(params.row.dob, "dd MMM yyyy")}</div>;  //"dd/MM/yyyy"
      },
    },
    {
      field: "payerName",
      headerName: "Payer",
      headerClassName: "classMultilineHeader",
      flex: 1,
    },
    {
      field: "practiceName",
      headerName: "Practice Name",
      headerClassName: "classMultilineHeader",
      flex: 1,
    },
    {
      field: "providerName",
      headerName: "Provider Name",
      headerClassName: "classMultilineHeader",
      flex: 1,
    },
    {
      field: "priority",
      headerName: "Priority",
      headerClassName: "classMultilineHeader",
      flex: 1,
    },
    {
      field: "riskScore",
      headerName: "Risk Score",
      // sortable: false,
      type: "number",
      headerClassName: "classMultilineHeader",
      flex: .6,
    },
    {
      field: "auditPendingCount",
      // sortable: false,
      type: "number",
      headerName: "Audit Pending Count",
      headerClassName: "classMultilineHeader",
      flex: 1,
    },
    {
      field: "isSDOHAssessmentSubmitted",
      sortable: false,
      headerName: "SDOH Assessment",
      headerClassName: "classMultilineHeader",
      align: "center",
      flex: 1.1,
      renderCell: (params) => {
        if (params.row.isSDOHAssessmentSubmitted) {
          return (
            <Tooltip title="View Assessment">
              <div
                onClick={() => {
                  handleNewSDOHAssessmentClick(
                    params.row.ourId,
                    params.row.sdohSubmittedDate,
                    params.row.memberRiskScore,
                    params.row.practiceName,
                    params.row.providerName
                  );
                  setIsNewAssessment(false);
                }}
              >
                <VisibilityOutlinedIcon />
              </div>
            </Tooltip>
          );
        } else
          return (
            <Tooltip title="Start New Assessment">
              <div
                onClick={() => {
                  handleNewSDOHAssessmentClick(params.row.ourId);
                  setIsNewAssessment(true);
                }}
              >
                <AssignmentOutlinedIcon />
              </div>
            </Tooltip>
          );
      },
    },
  ];

  const columnsExcluded = [
    {
      field: "memberName",
      headerName: "Member Name",
      headerClassName: "classMultilineHeader",
      flex: 1.5,
    },
    {
      field: "dob",
      headerName: "Member DOB",
      headerClassName: "classMultilineHeader",
      flex: 1,
      renderCell: (params) => {
          return <div>{format(params.row.dob, "dd MMM yyyy")}</div>;  //"dd/MM/yyyy"
      },
    },
    {
      field: "payerName",
      headerName: "Payer",
      headerClassName: "classMultilineHeader",
      flex: 1,
    },
    {
      field: "practiceName",
      headerName: "Practice Name",
      headerClassName: "classMultilineHeader",
      flex: 1,
    },
    {
      field: "providerName",
      headerName: "Provider Name",
      headerClassName: "classMultilineHeader",
      flex: 1,
    },
    {
      field: "exclusionDate",
      headerName: "Exclusion Date",
      headerClassName: "classMultilineHeader",
      flex: 1,
      renderCell: (params) => {
        if(params.row.exclusionDate){
          return <div>{format(params.row.exclusionDate, "dd-MM-yyyy")}</div>;  //"dd/MM/yyyy"
        }
      },
    },
    {
      field: "exclusionReason",
      headerName: "Exclusion Reason",
      headerClassName: "classMultilineHeader",
      flex: 1.5,
    },
    {
      field: "isSDOHAssessmentSubmitted",
      sortable: false,
      headerName: "SDOH Assessment",
      headerClassName: "classMultilineHeader",
      align: "center",
      flex: 1.1,
      renderCell: (params) => {
        if (params.row.isSDOHAssessmentSubmitted) {
          return (
            <Tooltip title="View Assessment">
              <div
                onClick={() => {
                  handleNewSDOHAssessmentClick(
                    params.row.ourId,
                    params.row.sdohSubmittedDate,
                    params.row.memberRiskScore,
                    params.row.practiceName,
                    params.row.providerName
                  );
                  setIsNewAssessment(false);
                }}
              >
                <VisibilityOutlinedIcon />
              </div>
            </Tooltip>
          );
        } else
          return (
            <Tooltip title="Start New Assessment">
              <div
                onClick={() => {
                  handleNewSDOHAssessmentClick(params.row.ourId);
                  setIsNewAssessment(true);
                }}
              >
                <AssignmentOutlinedIcon />
              </div>
            </Tooltip>
          );
      },
    },
  ];

  const columnsAuditAccepted = [
    {
      field: "memberName",
      headerName: "Member Name",
      headerClassName: "classMultilineHeader",
      flex: 1.5,
      renderCell: (params) => {
        return (
          <div style={{display:"flex", alignItems:"center"}}>
            <IconButton
              onClick={() => openMemberDetailsModal(params.row)}
              disableFocusRipple={true}
              disableRipple={true}
              type="button"
            >
              <img
                src={memberDetailsIcon}

              />
            </IconButton>
            <Link
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <div
                onClick={() =>
                  viewMemberDetails(params.row.ourId, params.row.payerName, params.row.priority)
                }
              >
                <u>{params.row.memberName}</u>
              </div>
            </Link>
          </div>
        );
      },
    },
    {
      field: "dob",
      headerName: "Member DOB",
      headerClassName: "classMultilineHeader",
      flex: 1,
      renderCell: (params) => {
          return <div>{format(params.row.dob, "dd MMM yyyy")}</div>;  //"dd/MM/yyyy"
      },
    },
    {
      field: "payerName",
      headerName: "Payer",
      headerClassName: "classMultilineHeader",
      flex: 1,
    },
    {
      field: "practiceName",
      headerName: "Practice Name",
      headerClassName: "classMultilineHeader",
      flex: 1,
    },
    {
      field: "providerName",
      headerName: "Provider Name",
      headerClassName: "classMultilineHeader",
      flex: 1,
    },
    {
      field: "priority",
      headerName: "Priority",
      headerClassName: "classMultilineHeader",
      flex: 1,
    },
    {
      field: "riskScore",
      headerName: "Risk Score",
      // sortable: false,
      type: "number",
      headerClassName: "classMultilineHeader",
      flex: .6,
    },
    {
      field: "auditPendingCount",
      // sortable: false,
      type: "number",
      headerName: "Audit Accepted Count",
      headerClassName: "classMultilineHeader",
      flex: .8,
    },
    {
      field: "isSDOHAssessmentSubmitted",
      sortable: false,
      headerName: "SDOH Assessment",
      headerClassName: "classMultilineHeader",
      align: "center",
      flex: 1.1,
      renderCell: (params) => {
        if (params.row.isSDOHAssessmentSubmitted) {
          return (
            <Tooltip title="View Assessment">
              <div
                onClick={() => {
                  handleNewSDOHAssessmentClick(
                    params.row.ourId,
                    params.row.sdohSubmittedDate,
                    params.row.memberRiskScore,
                    params.row.practiceName,
                    params.row.providerName
                  );
                  setIsNewAssessment(false);
                }}
              >
                <VisibilityOutlinedIcon />
              </div>
            </Tooltip>
          );
        } else
          return (
            <Tooltip title="Start New Assessment">
              <div
                onClick={() => {
                  handleNewSDOHAssessmentClick(params.row.ourId);
                  setIsNewAssessment(true);
                }}
              >
                <AssignmentOutlinedIcon />
              </div>
            </Tooltip>
          );
      },
    },
  ];

  const columnsAuditRejected = [
    {
      field: "memberName",
      headerName: "Member Name",
      headerClassName: "classMultilineHeader",
      flex: 1.5,
      renderCell: (params) => {
        return (
          <div style={{display:"flex", alignItems:"center"}}>
            <IconButton
              onClick={() => openMemberDetailsModal(params.row)}
              disableFocusRipple={true}
              disableRipple={true}
              type="button"
            >
              <img
                src={memberDetailsIcon}

              />
            </IconButton>
            <Link
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              <div
                onClick={() =>
                  viewMemberDetails(params.row.ourId, params.row.payerName, params.row.priority)
                }
              >
                <u>{params.row.memberName}</u>
              </div>
            </Link>
          </div>
        );
      },
    },
    {
      field: "dob",
      headerName: "Member DOB",
      headerClassName: "classMultilineHeader",
      flex: 1,
      renderCell: (params) => {
          return <div>{format(params.row.dob, "dd MMM yyyy")}</div>;  //"dd/MM/yyyy"
      },
    },
    {
      field: "payerName",
      headerName: "Payer",
      headerClassName: "classMultilineHeader",
      flex: 1,
    },
    {
      field: "practiceName",
      headerName: "Practice Name",
      headerClassName: "classMultilineHeader",
      flex: 1,
    },
    {
      field: "providerName",
      headerName: "Provider Name",
      headerClassName: "classMultilineHeader",
      flex: 1,
    },
    {
      field: "priority",
      headerName: "Priority",
      headerClassName: "classMultilineHeader",
      flex: 1,
    },
    {
      field: "riskScore",
      headerName: "Risk Score",
      // sortable: false,
      type: "number",
      headerClassName: "classMultilineHeader",
      flex: .6,
    },
    {
      field: "auditPendingCount",
      // sortable: false,
      type: "number",
      headerName: "Audit Rejected Count",
      headerClassName: "classMultilineHeader",
      flex: .8,
    },
    
    {
      field: "isSDOHAssessmentSubmitted",
      sortable: false,
      headerName: "SDOH Assessment",
      headerClassName: "classMultilineHeader",
      align: "center",
      flex: 1.1,
      renderCell: (params) => {
        if (params.row.isSDOHAssessmentSubmitted) {
          return (
            <Tooltip title="View Assessment">
              <div
                onClick={() => {
                  handleNewSDOHAssessmentClick(
                    params.row.ourId,
                    params.row.sdohSubmittedDate,
                    params.row.memberRiskScore,
                    params.row.practiceName,
                    params.row.providerName
                  );
                  setIsNewAssessment(false);
                }}
              >
                <VisibilityOutlinedIcon />
              </div>
            </Tooltip>
          );
        } else
          return (
            <Tooltip title="Start New Assessment">
              <div
                onClick={() => {
                  handleNewSDOHAssessmentClick(params.row.ourId);
                  setIsNewAssessment(true);
                }}
              >
                <AssignmentOutlinedIcon />
              </div>
            </Tooltip>
          );
      },
    },
  ];

  //Data Grid Columns End

  function getRowId(row) {
    return row.ourId + "_" + row.practiceName + "_" + "_" + row.payerName + "_" + row.providerName;//row.ourId;
  }

  const viewMemberDetails = (memberId, payerName, priority) => {
    setMemberOurId(memberId);
    setMemberPayerName(payerName);
    setShowDetailsPage(true);
    setPriority(priority);
  };

  const handleMemberExcludedSnackbarClose = () => {
    setShowMemberExcludedSnackbar(false);
  };

  const categoryClick =(item) =>{
    setIsCountDisplay(false);
    setActiveStatus(item.category)
  }
  //HTML Content Starts
  return !accessObj.hasAccess ? (
    <NoAccessPage></NoAccessPage>
  ) : !showDetailsPage && !showSDOHPage ? (
    <Box sx={{ width: "100%" }}>
      <ActiveGapClosuresListHeader></ActiveGapClosuresListHeader>
      {/* <Stack
        direction="column"
        style={{ marginTop: "18px", marginBottom: "10px" }}
        spacing={1}
      > */}
      {/* Dropdowns Start */}
      <Grid container sx={{ paddingTop: 2 }}  >
        <Grid item xs={10} md={10.5} sx={{ display: "flex", flexWrap: "wrap", direction: "row", gap: "10px" }}>
          {/* <Stack direction="row" spacing={2}> */}
          <Grid item xs={2.8}>
            <FormControl
              className="spacingClass"
              required
              color="orangeTheme"
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                borderRadius: "6px",
              }}
              size="small"
            >
              <InputLabel id="product-name-select-small-label">
                Payer
              </InputLabel>

              <Select
                required
                multiple
                value={selectedPayerName}
                className="productNameDropdown"
                labelId="product-name-select-small-label"
                id="product-name-select-small"
                onChange={(e) => handlePayerNameChange(e)}
                renderValue={(selected) => `${selected.length} Selected`}
                label="Market"
              >
                <MenuItem value="all">
                  <Checkbox
                    color="orangeTheme"
                    checked={isAllPayersSelected}
                    indeterminate={
                      selectedPayerName.length > 0 &&
                      selectedPayerName.length < payerNameDropdownData.length
                    }
                  />

                  <ListItemText primary="Select All">Select All </ListItemText>
                </MenuItem>
                {payerNameDropdownData.map((item) => (
                  <MenuItem
                    className="dropdownText"
                    key={item.payerId}
                    value={item.payerId}
                  >
                    <Checkbox
                      style={{ fontSize: "14px" }}
                      color="orangeTheme"
                      checked={selectedPayerName.indexOf(item.payerId) > -1}
                    />
                    <ListItemText
                      style={{ fontSize: "14px" }}
                      primary={item.payerName}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2.8}>
            <FormControl
              className="spacingClass"
              required
              color="orangeTheme"
              disabled={isLoadingProductName}
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                borderRadius: "6px",
              }}
              size="small"
            >
              <InputLabel id="product-name-select-small-label">
                Product
              </InputLabel>

              <Select
                required
                multiple
                value={selectedProductName}
                className="productNameDropdown"
                labelId="product-name-select-small-label"
                id="product-name-select-small"
                onChange={(e) => handleProductNameChange(e)}
                renderValue={(selected) => `${selected.length} Selected`}
                label="Product"
              >
                <MenuItem value="all">
                  <Checkbox
                    color="orangeTheme"
                    checked={isAllProductsSelected}
                    indeterminate={
                      selectedProductName.length > 0 &&
                      selectedProductName.length <
                      productNameDropdownData.length
                    }
                  />

                  <ListItemText primary="Select All">Select All </ListItemText>
                </MenuItem>
                {productNameDropdownData.map((item) => (
                  <MenuItem
                    className="dropdownText"
                    key={item.productId}
                    value={item.productId}
                  >
                    <Checkbox
                      style={{ fontSize: "14px" }}
                      color="orangeTheme"
                      checked={selectedProductName.indexOf(item.productId) > -1}
                    />
                    <ListItemText
                      style={{ fontSize: "14px" }}
                      primary={item.productName}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2.8}>
            <FormControl
              className="spacingClass"
              disabled={isLoadingMarketName}
              required
              color="orangeTheme"
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                borderRadius: "6px",
              }}
              size="small"
            >
              <InputLabel id="product-name-select-small-label">
                Market
              </InputLabel>

              <Select
                required
                multiple
                value={selectedMarketName}
                className="productNameDropdown"
                labelId="product-name-select-small-label"
                id="product-name-select-small"
                onChange={(e) => handleMarketNameChange(e)}
                renderValue={(selected) => `${selected.length} Selected`}
                label="Market"
              >
                <MenuItem value="all">
                  <Checkbox
                    color="orangeTheme"
                    checked={isAllMarketsSelected}
                    indeterminate={
                      selectedMarketName.length > 0 &&
                      selectedMarketName.length < marketNameDropdownData.length
                    }
                  />

                  <ListItemText primary="Select All">Select All </ListItemText>
                </MenuItem>
                {marketNameDropdownData.map((item) => (
                  <MenuItem
                    className="dropdownText"
                    key={item.marketId}
                    value={item.marketId}
                  >
                    <Checkbox
                      style={{ fontSize: "14px" }}
                      color="orangeTheme"
                      checked={selectedMarketName.indexOf(item.marketId) > -1}
                    />
                    <ListItemText
                      style={{ fontSize: "14px" }}
                      primary={item.marketName}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2.8}>
            <FormControl
              className="spacingClass"
              required
              color="orangeTheme"
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                borderRadius: "6px",
              }}
              disabled={isLoadingRegionName}
              size="small"
            >
              <InputLabel id="product-name-select-small-label">
                Region
              </InputLabel>

              <Select
                required
                multiple
                value={selectedRegionName}
                className="productNameDropdown"
                labelId="product-name-select-small-label"
                id="product-name-select-small"
                onChange={(e) => handleRegionNameChange(e)}
                renderValue={(selected) => `${selected.length} Selected`}
                label="Region"
              >
                <MenuItem value="all">
                  <Checkbox
                    color="orangeTheme"
                    checked={isAllRegionsSelected}
                    indeterminate={
                      selectedRegionName.length > 0 &&
                      selectedRegionName.length < regionNameDropdownData.length
                    }
                  />

                  <ListItemText primary="Select All">Select All </ListItemText>
                </MenuItem>
                {regionNameDropdownData.map((item) => (
                  <MenuItem
                    className="dropdownText"
                    key={item.id}
                    value={item.id}
                  >
                    <Checkbox
                      style={{ fontSize: "14px" }}
                      color="orangeTheme"
                      checked={selectedRegionName.indexOf(item.id) > -1}
                    />
                    <ListItemText
                      style={{ fontSize: "14px" }}
                      primary={item.regionName}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2.8}>
            <FormControl
              className="spacingClass"
              required
              color="orangeTheme"
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                borderRadius: "6px",
              }}
              disabled={isLoadingPracticeName}
              size="small"
            >
              <InputLabel id="product-name-select-small-label">
                Practice
              </InputLabel>

              <Select
                required
                multiple
                value={selectedPracticeName}
                className="productNameDropdown"
                labelId="product-name-select-small-label"
                id="product-name-select-small"
                onChange={(e) => handlePracticeNameChange(e)}
                renderValue={(selected) => `${selected.length} Selected`}
                label="Practice"
              >
                <MenuItem value="all">
                  <Checkbox
                    color="orangeTheme"
                    checked={isAllPracticeSelected}
                    indeterminate={
                      selectedPracticeName.length > 0 &&
                      selectedPracticeName.length <
                      practiceNameDropdownData.length
                    }
                  />

                  <ListItemText primary="Select All">Select All </ListItemText>
                </MenuItem>
                {practiceNameDropdownData.map((item) => (
                  <MenuItem
                    className="dropdownText"
                    key={item.practiceId}
                    value={item.practiceId}
                  >
                    <Checkbox
                      style={{ fontSize: "14px" }}
                      color="orangeTheme"
                      checked={
                        selectedPracticeName.indexOf(item.practiceId) > -1
                      }
                    />
                    <ListItemText
                      style={{ fontSize: "14px" }}
                      primary={item.practiceName}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2.8}>
            <FormControl
              className="spacingClass"
              required
              color="orangeTheme"
              disabled={isLoadingPcpName}
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                borderRadius: "6px",
              }}
              size="small"
            >
              <InputLabel id="pcp-name-select-small-label">PCP</InputLabel>

              <Select
                required
                multiple
                value={selectedPCPName}
                className="pcpNameDropdown"
                labelId="pcp-name-select-small-label"
                id="pcp-name-select-small"
                onChange={(e) => handlePcpNameChange(e)}
                renderValue={(selected) => `${selected.length} Selected`}
                label="PCP"
              >
                <MenuItem value="all">
                  <Checkbox
                    color="orangeTheme"
                    checked={isAllPcpsSelected}
                    indeterminate={
                      selectedPCPName.length > 0 &&
                      selectedPCPName.length < selectedPCPName.length
                    }
                  />

                  <ListItemText primary="Select All">Select All </ListItemText>
                </MenuItem>
                {pcpNameDropdownData.map((item) => (
                  <MenuItem
                    className="dropdownText"
                    key={item.pcP_NPI}
                    value={item.pcP_ID}
                  >
                    <Checkbox
                      style={{ fontSize: "14px" }}
                      color="orangeTheme"
                      checked={selectedPCPName.indexOf(item.pcP_ID) > -1}
                    />
                    <ListItemText
                      style={{ fontSize: "14px" }}
                      primary={item.pcP_Name}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2.8}>
            <FormControl
              className="spacingClass"
              required
              color="orangeTheme"
              // disabled={isLoadingProductName}
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                borderRadius: "6px",
              }}
              size="small"
            >
              <InputLabel id="year-select-small-label">Year</InputLabel>

              <Select
                required
                multiple
                value={selectedYear}
                className="yearDropdown"
                labelId="year-select-small-label"
                id="year-select-small"
                onChange={(e) => handleYearChange(e)}
                renderValue={(selected) => `${selected.length} Selected`}
                label="Year"
              >
                <MenuItem value="all">
                  <Checkbox
                    color="orangeTheme"
                    checked={isAllYearsSelected}
                    indeterminate={
                      selectedYear.length > 0 &&
                      selectedYear.length < selectedYear.length
                    }
                  />

                  <ListItemText primary="Select All">Select All </ListItemText>
                </MenuItem>
                {yearDropdownData.map((item) => (
                  <MenuItem
                    className="dropdownText"
                    key={item.year}
                    value={item.year}
                  >
                    <Checkbox
                      style={{ fontSize: "14px" }}
                      color="orangeTheme"
                      checked={selectedYear.indexOf(item.year) > -1}
                    />
                    <ListItemText
                      style={{ fontSize: "14px" }}
                      primary={item.year}
                    />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {/* </Stack> */}
        <Grid item xs={2} md={1.5} alignContent="end" sx={{ display: "flex", flexWrap: "wrap", direction: "row", gap: "10px", flexDirection: "column" }}>
          <Stack direction="column" spacing={2} sx={{ justifyContent: "end" }}>
            <Button
              style={{
                textTransform: "none",
                color: "white",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
                fontSize: "12px",
                fontStyle: Styles.FONT_STYLE_NORMAL,
                fontWeight: "500",
                lineHeight: "22px",
              }}
              disabled={isApplyFilterDisable}
              variant="contained"
              color="orangeTheme"
              disableElevation
              onClick={handleApplyFilter}
            >
              Apply Filters
            </Button>
            <Button
              style={{
                textTransform: "none",
                color: "#111827", //"black",
                border: "1px solid",
                borderColor: "#000", //"black",
                textAlign: "center",
                leadingTrim: "both",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
                fontSize: "12px",
                fontStyle: Styles.FONT_STYLE_NORMAL,
                fontWeight: "500",
                lineHeight: "22px",
              }}
              variant="outlined"
              onClick={handleClearFilterChange}
            >
              Clear Filters
            </Button>
          </Stack>
        </Grid>
      </Grid>
      {/* Dropdowns End */}
      {/* Grid Section Starts */}

      {isClearSections ? (
        <div>
          <Grid
            container
            spacing={1.5}
            sx={{
              marginTop: 1,
            }}
          >
            <Grid item xs={12} className="titleClass">
              <Stack direction="column">
                <Stack
                  sx={{ marginLeft: "auto", marginRight: "auto" }}
                  direction="row"
                >
                  <Typography>
                    {/* Please select the appropriate fields from filter to load the
                                                    data. */}
                    Please select all filters to continue.
                  </Typography>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </div>
      ) : (
        !isApplyFilterDisable ? (
          <div >
            <Grid container spacing={1.5} sx={{
              marginTop: 1,
            }}
            >
              <Grid item xs={12} className="titleClass"  >
                <Stack direction="column">
                  <Stack
                    sx={{ marginLeft: "auto", marginRight: "auto" }}
                    direction="row"
                  >
                    <Typography>
                      Please choose Apply Filter to load Members list.
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </div>
        ) : (

          <div style={{ display: "grid" }}>
            <Grid container spacing={1.5}>
              <Grid item xs={12} sx={{ display: "flex", flexWrap: "wrap", direction: "row", gap: "10px" }}>
                {
                  categoryList?.map((item, index) => {

                    return (
                      <Grid item xs={2.3} sx={{
                        marginLeft: "auto", marginRight: "auto"
                      }}>
                        <Card
                          key={index}
                          variant="outlined"
                          sx={{
                                '& .MuiCardContent-root:last-child':{
                                  paddingBottom:'16px !important'
                                    },
                            background:
                              item.category === activeStatus
                                ? activeBackgroundColor//"rgba(243, 111, 33, 0.05)"
                                : "",
                            border: `1px solid ${(activeStatus === item.category) ?
                              activeBorderColor : "rgba(224, 224, 224, 1)"}`
                          }}
                        >
                          <CardContent
                            style={{ cursor: "pointer" }}
                            onClick={() => categoryClick(item)}
                          >
                            <Stack direction="row" spacing={2}
                              sx={{ justifyContent: "center", alignContent: "center" }}>
                              <Typography style={{ fontSize: "18px" ,
                                display: (item.category === activeStatus && isCountDisplay)
                              ? "block" : "none",
                              }}>
                                      {item.categoryCount}
                                    </Typography> 
                              <Typography style={{ fontSize: "18px" }}>
                                {item.category}
                              </Typography>
                            </Stack>
                          </CardContent>
                        </Card>
                      </Grid>
                    );
                  })
                }
              </Grid>
            </Grid>
            {isLoading ? (
              <Stack direction="column">
                <Stack
                  sx={{ marginLeft: "auto", marginRight: "auto" }}
                  direction="row"
                >
                  <Loader isLoading={isLoading} />
                </Stack>
              </Stack>
            ) : 
            isNoDataLoad ? 
            (
              <DataGridCustom
                // columns={columns}
                columns={(isTotalOpen) ? columnsTotalOpen :
                  (isAuditPending ? columnsAuditPending : (isExcluded ? columnsExcluded : (isAuditAccepted ? columnsAuditAccepted : columnsAuditRejected)))}
              
                rows={activeGapClosuresList}
                getRowId={getRowId}
                loading={isLoading}
                sortingOrder={["asc", "desc"]}
                sortingMode="server"
                slots={{
                  toolbar: DataGridHeader,
                  pagination: CustomPaginationSlot,
                }}
                slotProps={{
                  toolbar: {
                    headerText: "Member Details",
                    fontSize: "16px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    height: "56px",
                    children: (
                      <div
                        style={{
                          fontFamily: Styles.FONT_FAMILY_POPPINS,
                          fontSize: "16px",
                          marginTop: "auto",
                          marginBottom: "auto",
                          marginLeft: "auto",
                        }}
                      >
                        <Searchbox
                          defaultValue={searchMemberName}
                          handleChange={(e) => {
                            setSearchMemberName(e.target.value);
                          }}
                          onClick={() => {
                            setPage(1);
                            setSearchMember(searchMemberName);
                          }}
                          label="Search Member"
                        ></Searchbox>
                      </div>
                    ),
                  },
                  pagination: {
                    setPage: setPage,
                    paginationModel: {
                      count: Math.ceil(
                        parseInt(activeGapClosuresListCount) / 10
                      ),
                      page: page === 0 ? 1 : page,
                      totalRows: parseInt(activeGapClosuresListCount),
                    },
                    colorTheme: "orangeTheme",
                    className: "pageNumber",
                  },
                }}
                sortModel={sortModel}
                onSortModelChange={(e) => handleSortModelChange(e)}
              />
            ) 
            : (
              <></>
              // <div >
              //   <Grid container spacing={1.5} sx={{
              //     marginTop: 1,
              //   }}
              //   >
              //     <Grid item xs={12} className="titleClass">
              //       <Stack direction="column">
              //         <Stack
              //           sx={{
              //             marginLeft: "auto", marginRight: "auto",
              //             display: isNoDataLoad ? "block" : "none"
              //           }}
              //           direction="row">
              //           <Typography >No Data for the selected configuration.</Typography>
              //         </Stack>
              //       </Stack>
              //     </Grid>
              //   </Grid>
              // </div>
            )
            }
          </div>
        )
      )}

      {/* Grid Section Ends */}
      {/* </Stack> */}
      {showMemberExcludedSnackbar && (
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={showMemberExcludedSnackbar}
          onClose={handleMemberExcludedSnackbarClose}
          autoHideDuration={5000}
        >
          <Alert
            onClose={handleMemberExcludedSnackbarClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            Member Excluded Successfully!
          </Alert>
        </Snackbar>
      )}
        <MemberDetailsModal
          isOpen={popupConfirmationModalOpen}
          onClose={closePopupModal}
          rowData={popupConfirmationModalData}
        ></MemberDetailsModal>
    </Box>
  ) : showSDOHPage && !showDetailsPage ? (
    isNewAssessment ? (
      <SDOHNewAssessment
        backAction={() => setShowSDOHPage(false)}
        ourId={memberOurId}
        forceUpdate={forceUpdate}
        setForceUpdate={setForceUpdate}
      />
    ) : (
      <SDOHViewAssessment
        backAction={() => setShowSDOHPage(false)}
        ourId={memberOurId}
        submittedDate={sdohSubmittedDate}
        memberRiskScore={memberRiskScore}
        practiceName={practiceName}
        providerName={providerName}
      />
    )
  ) : (
    <ActiveGapClosureMemberDetails
      ourId={memberOurId}
      payerName={memberPayerName}
      backAction={() => setShowDetailsPage(false)}
      showMemberExcludedSnackbar={showMemberExcludedSnackbar}
      setShowMemberExcludedSnackbar={setShowMemberExcludedSnackbar}
      pageTitle={pageTitle}
      forceUpdateParentGrid={forceUpdate}
      priority={priority}
      setForceUpdateParentGrid={setForceUpdate}
    />
  );
  //HTML Content Ends
};

export default ActiveGapClosuresList;
