import React, { useEffect, useState } from "react";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
// import { Box } from "@mui/material";
import {
  Button,
  TextField,
  Modal,
  Box,
  Grid,
  CircularProgress,
  FormHelperText,
  Typography,
  Stack,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Radio,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
// import DataGridCustom from "../../component/common/datagrid/DataGridCustom";
import Fab from "@mui/material/Fab";
import { Styles } from "../../../Styles";
//  import { Styles } from "../../Styles";
import AddIcon from "@mui/icons-material/Add";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import UserAdminConsoleHeader from "../UserAdminConsoleHeader";
import AddUserFormHeader from "./AddUserFormHeader";
import { PagesPath } from "../../../PagesPath";
import { Link, redirect, useLocation, useNavigate } from "react-router-dom";
import { addDays, format, parseISO } from "date-fns";
import { axiosRequests } from "../../../app/api/AxiosRequest";
import "./AddUserFormCSS.css";

const AddUserForm = ({ setAddUserFormDisplay }) => {
  const navigate = useNavigate();
  const [value, setValue] = React.useState(new Date());
  let { state } = useLocation();
  const [userId, setUserId] = React.useState("");
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [dateOfBirth, setDateOfBirth] = React.useState(null);
  const [email, setEmail] = React.useState("");
  const [contactNumber, setContactNumber] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [city, setCity] = React.useState("");
  const [stateName, setStateName] = React.useState("");
  const [zipCode, setZipCode] = React.useState("");
  const [id, setId] = React.useState("");
  const [isEdit, setIsEdit] = React.useState(false);
  const [initialRoleType, setInitialRoleType] = useState(0);
  const [initialPraticeAdmin, setInitialPraticeAdmin] = useState(0);
  const [initialUserType, setInitialUserType] = useState("");
  const [userType, setUserType] = React.useState("1");
  const [roleTypeError, setRoleTypeError] = React.useState("");
  const [isPracticeAdmin, setIsPracticeAdmin] = useState(false);
  // const [isLearningUser, setIsPracticeAdmin] = useState(false);
  const [isGlobalAdmin, setIsGlobalAdmin] = useState(false);
  const [loggedInEmail, setLoggedInEmail] = useState("");
  const [roleTypeDropdownData, setRoleTypeDropdownData] = useState([]);
  const [selectedRoleType, setSelectedRoleType] = useState(1);
  const [practiceAdminDropdownData, setPracticeAdminDropdownData] = useState(
    []
  );
  const [defaultRoleTypeData, setDefaultRoleTypeData] = useState([]);
  const [selectedPracticeAdmin, setSelectedPracticeAdmin] = useState(0);

  //Error States Start
  const [userIdError, setUserIdError] = React.useState("");
  const [firstNameError, setFirstNameError] = React.useState("");
  const [lastNameError, setLastNameError] = React.useState("");
  const [dateOfBirthError, setDateOfBirthError] = React.useState("");
  const [emailError, setEmailError] = React.useState("");
  const [contactNumberError, setContactNumberError] = React.useState("");
  const [addressError, setAddressError] = React.useState("");
  const [cityError, setCityError] = React.useState("");
  const [stateNameError, setStateNameError] = React.useState("");
  const [zipCodeError, setZipCodeError] = React.useState("");
  const [selectedPracticeAdminError, setSelectedPracticeAdminError] =
    useState("");

  //Error States End

  //Regex for Input fields Start
  const zipRegex = /\b\d{5}\b/;
  const cityRegex = /^[a-zA-Z ]*$/;
  const stateRegex = /^[a-zA-Z ]*$/;
  const alphabetRegex = /^[a-zA-Z ]*$/;
  const useridRegex = /^[a-zA-Z. ]*$/;
  const phoneRegex = /\b\d{10}\b/;
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //Regex for Input fields end

  const handleRadioChange = (event) => {
    setUserType(event.target.value);
    if (parseInt(event.target.value) === 2) {
      setRoleTypeDropdownData(
        defaultRoleTypeData.filter((item) => parseInt(item.roleTypeId) !== 1)
      );
      setSelectedRoleType(2);
    } else {
      setRoleTypeDropdownData(
        defaultRoleTypeData.filter((item) => parseInt(item.roleTypeId) !== 2)
      );
      setSelectedRoleType(1);
    }
  };

  useEffect(() => {
    if (state) {
      setUserId(state.formState.userId);
      setFirstName(state.formState.firstName);
      setLastName(state.formState.lastName);
      state.formState.dateofbirth
        ? setDateOfBirth(new Date(state.formState.dateofbirth))
        : setDateOfBirth(null);
      setEmail(state.formState.email);
      setContactNumber(state.formState.contactNumber);
      setAddress(state.formState.address);
      setCity(state.formState.city);
      setStateName(state.formState.state);
      setZipCode(state.formState.pinCode);
      setId(state.formState.id);
      setUserType(state.formState.userType);
      setSelectedRoleType(parseInt(state.formState.userRoleType));
      setInitialUserType(state.formState.userType);
      setInitialRoleType(parseInt(state.formState.userRoleType));
      setInitialPraticeAdmin(parseInt(state.formState.practiceAdminId));
      setSelectedPracticeAdmin(parseInt(state.formState.practiceAdminId));
      setIsEdit(true);
    }
  }, []);

  // Input field change functions start here

  const handleUserIdChange = (e) => {
    setUserId(e.target.value);
    setUserIdError("");
  };
  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
    setFirstNameError("");
  };
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    setLastNameError("");
  };
  const handleAddressChange = (e) => {
    setAddress(e.target.value);
    setAddressError("");
  };
  const handleCityChange = (e) => {
    setCity(e.target.value);
    setCityError("");
  };
  const handleStateNameChange = (e) => {
    setStateName(e.target.value);
    setStateNameError("");
  };
  const handleZipCodeChange = (e) => {
    setZipCode(e.target.value);
    setZipCodeError("");
  };
  const handleContactNumberChange = (e) => {
    setContactNumber(e.target.value);
    setContactNumberError("");
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError("");
  };

  // Input field change functions end here

  useEffect(() => {
    axiosRequests
      .get("/RoleManagementDetail/GetPracticeAdminList")
      .then((resp) => {
        if (state && state.formState) {
          var idList = resp.map((item) => item.practiceAdminId);
          if (!idList.includes(state.formState.practiceAdminId))
            setSelectedPracticeAdmin("");
        }
        setPracticeAdminDropdownData(resp);
      });
  }, []);
  useEffect(() => {
    axiosRequests
      .get("/RoleManagementDetail/GetRoleTypeDropdownDetails")
      .then((resp) => {
        setDefaultRoleTypeData(resp);
        if (state && state.formState) {
          setInitialRoleType(parseInt(state.formState.userRoleType));
          setInitialUserType(state.formState.userType);
          setInitialPraticeAdmin(parseInt(state.formState.practiceAdminId));
          if (parseInt(state.formState.userType) === 2) {
            setRoleTypeDropdownData(
              resp.filter((item) => parseInt(item.roleTypeId) !== 1)
            );
            setSelectedRoleType(state.formState.userRoleType);
          } else {
            setRoleTypeDropdownData(
              resp.filter((item) => parseInt(item.roleTypeId) !== 2)
            );
            setSelectedRoleType(state.formState.userRoleType);
          }
        } else {
          setRoleTypeDropdownData(
            resp.filter((item) => parseInt(item.roleTypeId) !== 2)
          );
        }
      });
  }, []);

  const handleFormSubmit = () => {
    let hasError = false;
    if (!userId) {
      setUserIdError("User Id is Required");
      hasError = true;
    }
    if (userId && !useridRegex.test(userId)) {
      setUserIdError("Invalid User Id entered");
      hasError = true;
    }
    if (!firstName) {
      setFirstNameError("First Name is Required");
      hasError = true;
    }
    if (firstName && !alphabetRegex.test(firstName)) {
      setFirstNameError("Invalid Name Entered");
      hasError = true;
    }
    if (!lastName) {
      setLastNameError("Last Name is Required");
      hasError = true;
    }
    if (lastName && !alphabetRegex.test(lastName)) {
      setLastNameError("Invalid Name Entered");
      hasError = true;
    }
    if (!email) {
      setEmailError("Email is Required");
      hasError = true;
    }
    if (email && !emailRegex.test(email)) {
      setEmailError("Email is Invalid");
      hasError = true;
    }
    if (city && !alphabetRegex.test(city)) {
      setCityError("City is invalid");
      hasError = true;
    }
    if (stateName && !alphabetRegex.test(stateName)) {
      setStateNameError("State is Invalid");
      hasError = true;
    }
    if (zipCode && !zipRegex.test(zipCode)) {
      setZipCodeError("Zip Code is Invalid");
      hasError = true;
    }
    if (!contactNumber) {
      setContactNumberError("Contact Number is Required");
      hasError = true;
    }
    if (contactNumber && !phoneRegex.test(contactNumber)) {
      setContactNumberError("Contact Number is Invalid");
      hasError = true;
    }

    if (
      parseInt(userType) === 2 &&
      parseInt(selectedRoleType) === 3 &&
      !selectedPracticeAdmin
    ) {
      setSelectedPracticeAdminError("Practice Admin is Required");
      hasError = true;
    }

    if (hasError) return;

    const formData = {
      id: id,
      userId: userId,
      firstName: firstName,
      lastName: lastName,
      dateofbirth: dateOfBirth,
      email: email,
      contactNumber: contactNumber,
      address: address,
      city: city,
      state: stateName,
      pinCode: zipCode,
      userType: userType,
      userRoleType: selectedRoleType,
      practiceAdminId:
        (parseInt(selectedRoleType) === 3 ||
          parseInt(selectedRoleType) === 4 || parseInt(selectedRoleType) === 5) &&
        parseInt(userType) === 2
          ? selectedPracticeAdmin
          : 0,
    };
    if (isEdit) {
      if (
        parseInt(userType) === parseInt(initialUserType) &&
        parseInt(selectedRoleType) === parseInt(initialRoleType)
      )
        formData.practiceAdminId = initialPraticeAdmin;
      else if (parseInt(userType) === 1) formData.practiceAdminId = 0;

      axiosRequests
        .put("/UserManagementDetail/UpdateUserDetails", formData)
        .then((resp) => {
          if (resp === 1) {
            // redirect(PagesPath.UserAdminConsoleURL)
            navigate(PagesPath.UserAdminConsoleURL);
          }
        });
    } else {
      delete formData["id"];
      axiosRequests
        .post("/UserManagementDetail/AddNewUserDetails", formData)
        .then((resp) => {
          if (resp === 1) {
            // redirect(PagesPath.UserAdminConsoleURL)
            navigate(PagesPath.UserAdminConsoleURL);
          }
        });
    }
  };
  const handleRoleTypeChange = (e) => {
    setSelectedRoleType(e.target.value);
    setRoleTypeError("");
    //checkIfRoleNameExists(e.target.value);
  };
  const handlePracticeAdminChange = (e) => {
    setSelectedPracticeAdmin(e.target.value);
    setSelectedPracticeAdminError("");
    //checkIfRoleNameExists(e.target.value);
  };
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("userAccess"))) {
      var practiceAdmin = JSON.parse(
        localStorage.getItem("userAccess")
      ).roleType;
      setLoggedInEmail(JSON.parse(localStorage.getItem("userAccess")).email);
      if (parseInt(practiceAdmin) === 2) {
        setIsPracticeAdmin(true);
        setSelectedRoleType(3);
        setUserType(2);
        setSelectedPracticeAdmin(
          JSON.parse(localStorage.getItem("userAccess")).id
        );
      }
    }
  }, []);
  const handleBackClick = () => {
    localStorage.setItem("tabValue", 1);
    navigate(PagesPath.UserAdminConsoleURL, { state: { value: "1" } });
  };
  return (
    <Box sx={{ width: "100%", p: { xs: 2, sm: 2 } }}>
      <AddUserFormHeader pageTitle={"Users"}></AddUserFormHeader>
      <Stack direction="row" spacing={1}>
        <ArrowBackOutlinedIcon
          onClick={() => handleBackClick()}
          style={{
            fontSize: "25px",
            fontWeight: "600",
            marginTop: "2px",
            width: "32px",
          }}
        />
        <Typography style={{ fontSize: "20px", fontWeight: "600" }}>
          Add User
        </Typography>
      </Stack>
      {/* <Typography style={{ fontSize: "20px", fontWeight: "600" }}>
        Add User
      </Typography> */}
      <div
        style={{
          width: "1270px",
          height: "auto",
          background: "rgba(226, 226, 226, 0.30)",
          padding: "16px",
        }}
      >
        <Typography
          style={{ fontSize: "14px", fontWeight: "500", padding: "10px" }}
        >
          User Details
        </Typography>
        <Grid item xs={6} style={{ marginBottom: "27px" }}>
          <TextField
            required
            label="User ID"
            id="outlined-size-small"
            helperText={userIdError}
            error={!!userIdError}
            value={userId}
            style={{
              width: "303px",
              height: "39px",
              borderRadius: "6px",
              backgroundColor: "#fff",
            }}
            onChange={handleUserIdChange}
            color="orangeTheme"
            size="small"
          />
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: "27px" }}>
          <Grid item xs={4}>
            <TextField
              required
              label="First Name"
              id="outlined-size-small"
              onChange={handleFirstNameChange}
              helperText={firstNameError}
              error={!!firstNameError}
              value={firstName}
              style={{
                width: "303px",
                height: "39px",
                borderRadius: "6px",
                backgroundColor: "#fff",
              }}
              color="orangeTheme"
              size="small"
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              required
              label="Last Name"
              value={lastName}
              id="outlined-size-small"
              onChange={handleLastNameChange}
              helperText={lastNameError}
              error={!!lastNameError}
              style={{
                width: "303px",
                height: "39px",
                borderRadius: "6px",
                backgroundColor: "#fff",
              }}
              color="orangeTheme"
              size="small"
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date Of Birth"
              value={dateOfBirth}
              minDate={addDays(new Date(1753, 0, 0), 1)}
              disableFuture
              color="orangeTheme"
              slotProps={{
                textField: {
                  className: "dateFieldClass",
                  color: "orangeTheme",
                },
              }}
              onChange={(newValue) => {
                setValue(newValue);
                setDateOfBirth(newValue);
              }}
            />
          </LocalizationProvider>
        </Grid>
        <Grid item xs={6} style={{ marginTop: "15px", marginBottom: "10px" }}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="user-type-group-label"
              name="user-type-buttons-group"
              value={userType}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value="1"
                disabled={isPracticeAdmin}
                //style={{ display: isPracticeAdmin ? "none" : "" }}
                control={<Radio color="orangeTheme" />}
                label="Internal User"
              />
              <FormControlLabel
                value="2"
                disabled={isPracticeAdmin}
                control={<Radio color="orangeTheme" />}
                label="External User"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: "27px" }}>
          <Grid item xs={4}>
            <FormControl
              disabled={isPracticeAdmin && isEdit && initialRoleType === 2}
              className="practiceNameClass"
              //style={{ display: isPracticeAdmin ? "none" : "" }}
              required
              color="orangeTheme"
              sx={{
                maxWidth: "303px",
                minWidth: "303px",
                backgroundColor: "#fff",
                borderRadius: "6px",
              }}
              //disabled={isLoadingRoleName}
              size="small"
              // error={!!roleNameErrorText}
            >
              <InputLabel id="role-type-select-small-label">
                User Role Type
              </InputLabel>

              <Select
                required
                value={selectedRoleType}
                className="roleNameDropdown"
                labelId="role-name-select-small-label"
                id="role-name-select-small"
                onChange={(e) => handleRoleTypeChange(e)}
                label="User Role Type"
              >
                {isPracticeAdmin
                  ? roleTypeDropdownData.map((item) => {
                      if (
                        isEdit &&
                        initialRoleType === 2 &&
                        parseInt(item.roleTypeId) === 2
                      ) {
                        return (
                          <MenuItem
                            className="dropdownText"
                            key={item.roleTypeId}
                            value={item.roleTypeId}
                          >
                            {item.roleType}
                          </MenuItem>
                        );
                      } else if (
                        parseInt(item.roleTypeId) !== 1 &&
                        parseInt(item.roleTypeId) !== 2
                      )
                        return (
                          <MenuItem
                            className="dropdownText"
                            key={item.roleTypeId}
                            value={item.roleTypeId}
                          >
                            {item.roleType}
                          </MenuItem>
                        );
                    })
                  : roleTypeDropdownData.map((item) => (
                      <MenuItem
                        className="dropdownText"
                        key={item.roleTypeId}
                        value={item.roleTypeId}
                      >
                        {item.roleType}
                      </MenuItem>
                    ))}
              </Select>
              {/* <FormHelperText style={{ height: "0px" }}>
                    {roleTypeError}
              </FormHelperText> */}
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl
              disabled={isPracticeAdmin}
              style={{
                display: !(
                  (parseInt(selectedRoleType) === 3 ||
                    parseInt(selectedRoleType) === 4 || parseInt(selectedRoleType) === 5) &&
                  parseInt(userType) === 2
                )
                  ? "none"
                  : "",
              }}
              required
              color="orangeTheme"
              sx={{
                maxWidth: "303px",
                minWidth: "303px",
                backgroundColor: "#fff",
                borderRadius: "6px",
              }}
              //disabled={isLoadingRoleName}
              size="small"
              error={!!selectedPracticeAdminError}
            >
              <InputLabel id="role-type-select-small-label">
                Practice Administrator
              </InputLabel>

              <Select
                required
                value={selectedPracticeAdmin}
                className="roleNameDropdown"
                labelId="role-name-select-small-label"
                id="role-name-select-small"
                onChange={(e) => handlePracticeAdminChange(e)}
                label="Practice Administrator"
              >
                {practiceAdminDropdownData.map((item) => (
                  <MenuItem
                    className="dropdownText"
                    key={item.practiceAdminId}
                    value={item.practiceAdminId}
                  >
                    {item.practiceAdminName}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText style={{ height: "0px" }}>
                {selectedPracticeAdminError}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>

        <Typography
          style={{ fontSize: "14px", fontWeight: "500", padding: "10px" }}
        >
          Contact Details
        </Typography>

        <Grid container spacing={2} style={{ marginBottom: "27px" }}>
          <Grid item xs={4}>
            <TextField
              required
              label="Email"
              id="outlined-size-small"
              onChange={handleEmailChange}
              helperText={emailError}
              error={!!emailError}
              value={email}
              style={{
                width: "303px",
                height: "39px",
                borderRadius: "6px",
                backgroundColor: "#fff",
              }}
              color="orangeTheme"
              size="small"
            />
          </Grid>

          <Grid item xs={4}>
            <TextField
              required
              label="Contact Number"
              id="outlined-size-small"
              onChange={handleContactNumberChange}
              helperText={contactNumberError}
              error={!!contactNumberError}
              value={contactNumber}
              style={{
                width: "303px",
                height: "39px",
                borderRadius: "6px",
                backgroundColor: "#fff",
              }}
              color="orangeTheme"
              size="small"
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginBottom: "27px" }}>
          <Grid item xs={12}>
            <TextField
              label="User Address"
              id="outlined-size-small"
              value={address}
              onChange={handleAddressChange}
              helperText={""}
              style={{
                width: "705px",
                height: "39px",
                borderRadius: "6px",
                backgroundColor: "#fff",
              }}
              color="orangeTheme"
              size="small"
            />
          </Grid>
        </Grid>
        <Grid container spacing={32} style={{ marginBottom: "27px" }}>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="City"
              value={city}
              id="outlined-size-small"
              helperText={cityError}
              error={!!cityError}
              onChange={handleCityChange}
              style={{
                width: "196px",
                height: "39px",
                borderRadius: "6px",
                backgroundColor: "#fff",
              }}
              color="orangeTheme"
              size="small"
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="State"
              value={stateName}
              helperText={stateNameError}
              error={!!stateNameError}
              id="outlined-size-small"
              onChange={handleStateNameChange}
              style={{
                width: "196px",
                height: "39px",
                borderRadius: "6px",
                backgroundColor: "#fff",
              }}
              color="orangeTheme"
              size="small"
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              inputProps={{ maxLength: 5 }}
              label="Zip Code"
              value={zipCode}
              style={{
                width: "196px",
                height: "39px",
                borderRadius: "6px",
                backgroundColor: "#fff",
              }}
              helperText={zipCodeError}
              error={!!zipCodeError}
              id="outlined-size-small"
              onChange={handleZipCodeChange}
              color="orangeTheme"
              size="small"
            />
          </Grid>
        </Grid>
      </div>
      <Grid
        container
        spacing={6}
        style={{ marginTop: "-39px", marginLeft: "46px" }}
      >
        <Grid item xs={8}></Grid>
        <Grid item xs={1}>
          <Link
            to={PagesPath.UserAdminConsoleURL}
            key="AddUser"
            state={{ value: "1" }}
            style={{
              textDecoration: "none",
              underline: "hover",
              color: "inherit",
            }}
          >
            <Button
              style={{
                borderColor: "black",
                color: "black",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
                textTransform: "none",
              }}
              variant="outlined"
            >
              Cancel
            </Button>
          </Link>
        </Grid>
        <Grid item xs={1}>
          <Button
            style={{
              color: "white",
              fontFamily: Styles.FONT_FAMILY_POPPINS,
              textTransform: "none",
            }}
            variant="contained"
            color="orangeTheme"
            disableElevation
            onClick={handleFormSubmit}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddUserForm;
