import React, { useState, useEffect, useCallback } from "react";
import {
    Box,
    Grid,
    Modal,
    Stack,
    Button,
    Typography,
    FormControl,
    CircularProgress,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { Styles } from "../../Styles";
import { useAppDispatch } from "../../app/store";
import {
    getRaceList,
    getEthnicityList,
    getLanguageList,
} from "../sdohAssessmentDashboard/slices/SDOHMembersListSlice";
import { axiosRequests } from "../../app/api/AxiosRequest";
const DeclinePopupModal = ({ isOpen, onClose, rowData, information, message, handleDecline }) => {
    const dispatch = useAppDispatch();
    const [isLanguageEmpty, setIsLanguageEmpty] = useState();
    const [isEthnicityIdEmpty, setIsEthnicityIdEmpty] = useState();
    const [isRaceIdEmpty, setIsRaceIdEmpty] = useState();
    const [oldRaceId, setOldRaceId] = React.useState("");
    const [raceId, setRaceId] = React.useState("");
    const [raceIdErrorMessage, setRaceIdErrorMessage] = React.useState("");
    const [oldEthnicityId, setOldEthnicityId] = React.useState("");
    const [ethnicityId, setEthnicityId] = React.useState("");
    const [ethnicityIdErrorMessage, setEthnicityIdErrorMessage] =
        React.useState("");
    const [oldLanguageId, setOldLanguageId] = React.useState("");
    const [languageId, setLanguageId] = React.useState("");
    const [languageIdErrorMessage, setLanguageIdErrorMessage] =
        React.useState("");
    const [memberName, setMemberName] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [oldContactNumber, setOldContactNumber] = React.useState("");
    const [oldEmailAddress, setOldEmailAddress] = React.useState("");
    const { isLoading, raceList, ethnicityList, languageList, errorMessage } =
        useSelector((state) => state.sdohMemberList);

    const { memberDetail } = useSelector((state) => state.filterDetails);

    const getRaceListData = useCallback(async () => {
        await dispatch(getRaceList());
    }, [dispatch]);

    useEffect(() => {
        getRaceListData();
    }, [getRaceListData]);

    const getEthnicityListData = useCallback(async () => {
        await dispatch(getEthnicityList());
    }, [dispatch]);

    useEffect(() => {
        getEthnicityListData();
    }, [getEthnicityListData]);

    const getLanguageListData = useCallback(async () => {
        await dispatch(getLanguageList());
    }, [dispatch]);

    useEffect(() => {
        getLanguageListData();
    }, [getLanguageListData]);

    useEffect(() => {
        if (ethnicityId != "") {
            setIsEthnicityIdEmpty(false);
        }
    }, [ethnicityId])

    useEffect(() => {
        if (raceId != "") {
            setIsRaceIdEmpty(false);
        }
    }, [raceId])

    useEffect(() => {
        if (languageId != "") {
            setIsLanguageEmpty(false);
        }
    }, [languageId])

    const closePopup = () => {
        setIsRaceIdEmpty(false);
        setIsEthnicityIdEmpty(false);
        setIsLanguageEmpty(false);
        onClose();
    }

    useEffect(() => {
        if (memberDetail && memberDetail?.ourId != undefined) {
            axiosRequests
                .get(
                    `/SDOH/GetMemberEmailID?ourId=${memberDetail?.ourId
                    }`
                )
                .then((resp) => {
                    if (resp != undefined) {
                        setMemberName(resp?.memberName);
                        setContactNumber(resp?.contactNumber);
                        setEmailAddress(resp?.emailAddress);
                        setOldContactNumber(resp?.contactNumber);
                        setOldEmailAddress(resp?.emailAddress);
                        setOldRaceId(resp?.race);
                        setOldEthnicityId(resp?.ethnicity);
                        setOldLanguageId(resp?.language);
                    }
                });
        }

    }, [memberDetail]);

    const handleDeclineCall = () => {

        let hasError = false;
        if ((raceId == undefined || raceId == "") && (oldRaceId == undefined || oldRaceId == "")) {
            hasError = true;
            setIsRaceIdEmpty(true);
        }
        if ((ethnicityId == undefined || ethnicityId == "") && (oldEthnicityId == undefined || oldEthnicityId == "")) {
            hasError = true;
            setIsEthnicityIdEmpty(true);
        }
        if ((languageId == undefined || languageId == "") && (oldLanguageId == undefined || oldLanguageId == "")) {
            hasError = true;
            setIsLanguageEmpty(true);
        }

        if (!hasError) {
            const formData = {
                OurId: memberDetail.ourId,
                oldContactNumber: oldContactNumber,
                oldEmailAddress: oldEmailAddress,
                OldRace: oldRaceId,
                OldEthnicity: oldEthnicityId,
                OldLanguage: oldLanguageId,

                ContactNumber: contactNumber,
                EmailAddress: emailAddress,
                Race: raceId,
                Ethnicity: ethnicityId,
                Language: languageId
            };

            axiosRequests.post(`/SDOH/UpdateSDOHMemberDemographyDetail`, formData).then((resp) => {
                // console.log("demography-resp", resp);
                // props.onDataChange();
                handleDecline();
            })
        }

    }
    if (!rowData) return;
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            slotProps={{ backdrop: { onClick: null } }}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    width: "578px",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    pt: 1,
                    height: "auto",
                }}
            >
                <h3
                    style={{ fontSize: "16px", fontWeight: 600, lineHeight: "24px", color: "#000000" }}
                >
                    {information}
                    <span>
                        <CloseIcon onClick={closePopup} style={{ float: "right" }} />
                    </span>
                </h3>
                <hr
                    style={{
                        width: "auto",
                        opacity: "0.3",
                        marginTop: "-11px",
                        marginBottom: "5px",
                    }}
                />
                <Stack direction="column" sx={{ marginTop: '23px' }} spacing={2}>
                    <Typography style={{ fontWeight: 500, color: "#000000" }}>
                        <b>{message}</b>
                    </Typography>
                </Stack>
                {/* <hr
                    style={{
                        width: "auto",
                        opacity: "0.3",
                        marginTop: "16px",
                    }}
                /> */}
                <Grid container  >
                    <Grid item xs={11}>
                        <Stack spacing={2} sx={{ marginTop: '23px' }}>
                            <Grid container >
                                <Grid item xs={12}>
                                    <Typography sx={{ fontSize: "14px" }}>
                                        <b>Name :</b> {(memberName) ? memberName : memberDetail.memberName}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container >
                                <Grid item xs={5.5}>
                                    <Typography sx={{ fontSize: "14px" }}>
                                        <b>Phone :</b> {contactNumber}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6.5}>
                                    <Typography sx={{ fontSize: "14px" }}>
                                        <b>Email :</b> {emailAddress}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container >
                                <Grid item xs={5.5}>
                                    <Typography sx={{ fontSize: "14px" }}>
                                        <b>Race :</b> {oldRaceId}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6.5}>
                                    <Typography sx={{ fontSize: "14px" }}>
                                        <b>Ethnicity :</b> {oldEthnicityId}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container >
                                <Grid item xs={12}>
                                    <Typography sx={{ fontSize: "14px" }}>
                                        <b>Language :</b> {oldLanguageId}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container >
                                <Grid item xs={12}>
                                    <Typography style={{ fontWeight: 500, color: "#000000" }}>
                                        <b>Select Member Demographics</b>
                                    </Typography>
                                </Grid>
                            </Grid>



                            <Grid container >
                                <Grid item xs={12}>

                                    <FormControl
                                        error={raceIdErrorMessage}
                                        color="orangeTheme"
                                        sx={{ minWidth: "100%", maxWidth: "100%" }}
                                        size="small"
                                    >
                                        <InputLabel id="race_label">Race</InputLabel>
                                        <Select
                                            defaultValue=""
                                            required
                                            value={raceId}
                                            disabled={isLoading}
                                            labelId="race_label"
                                            id="race"
                                            onChange={(newValue) => {
                                                setRaceId(newValue.target.value);
                                            }}
                                            label="Race"
                                            sx={{
                                                width: "100%",
                                                border:
                                                    (isRaceIdEmpty ?
                                                        "1px solid #D32F2F" : "")
                                            }}
                                        >
                                            {isLoading ? (
                                                <MenuItem value="">
                                                    <CircularProgress size={24} />
                                                </MenuItem>
                                            ) : null}
                                            <MenuItem
                                                key={0}
                                                value={'I choose not to Answer'}
                                                name={0}
                                            >
                                                I choose not to Answer
                                            </MenuItem>
                                            {raceList.map((item) => (
                                                <MenuItem
                                                    key={item.id}
                                                    value={item.description}
                                                    name={item.id}
                                                    sx={{
                                                        display:
                                                            (item.description === 'I choose not to Answer') ? 'none' : 'block'
                                                    }}
                                                >
                                                    {item.description}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {isRaceIdEmpty ? <div style={{ fontSize: '12px', color: '#D32F2F', paddingLeft: '10px' }}><span style={{ fontWeight: 'bold' }}>Race </span>is Required</div> : null}
                                        {/* {errorText ? <FormHelperText>{errorText}</FormHelperText> : null} */}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container >
                                <Grid item xs={12}>

                                    <FormControl
                                        error={ethnicityIdErrorMessage}
                                        color="orangeTheme"
                                        sx={{ minWidth: "100%", maxWidth: "100%" }}
                                        size="small"
                                    >
                                        <InputLabel id="ethnicity_label">Ethnicity</InputLabel>
                                        <Select
                                            defaultValue=""
                                            required
                                            value={ethnicityId}
                                            disabled={isLoading}
                                            labelId="ethnicity_label"
                                            id="ethnicity"
                                            onChange={(newValue) => {
                                                setEthnicityId(newValue.target.value);
                                            }}
                                            label="Ethnicity"
                                            sx={{
                                                width: "100%",
                                                border:
                                                    (isEthnicityIdEmpty ?
                                                        "1px solid #D32F2F" : "")
                                            }}
                                        >
                                            {isLoading ? (
                                                <MenuItem value="">
                                                    <CircularProgress size={24} />
                                                </MenuItem>
                                            ) : null}
                                            <MenuItem
                                                key={0}
                                                value={'I choose not to Answer'}
                                                name={0}
                                            >
                                                I choose not to Answer
                                            </MenuItem>
                                            {ethnicityList.map((item) => (
                                                <MenuItem
                                                    key={item.id}
                                                    value={item.description}
                                                    name={item.id}
                                                    sx={{
                                                        display:
                                                            (item.description === 'I choose not to Answer') ? 'none' : 'block'
                                                    }}
                                                >
                                                    {item.description}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {isEthnicityIdEmpty ? <div style={{ fontSize: '12px', color: '#D32F2F', paddingLeft: '10px' }}><span style={{ fontWeight: 'bold' }}>Ethnicity </span>is Required</div> : null}
                                        {/* {errorText ? <FormHelperText>{errorText}</FormHelperText> : null} */}
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid container >
                                <Grid item xs={12}>

                                    <FormControl
                                        error={languageIdErrorMessage}
                                        color="orangeTheme"
                                        sx={{ minWidth: "100%", maxWidth: "100%" }}
                                        size="small"
                                    >
                                        <InputLabel id="language_label">Language</InputLabel>
                                        <Select
                                            defaultValue=""
                                            required
                                            value={languageId}
                                            disabled={isLoading}
                                            labelId="language_label"
                                            id="language"
                                            onChange={(newValue) => {
                                                setLanguageId(newValue.target.value);
                                            }}
                                            label="Language"
                                            sx={{
                                                width: "100%",
                                                border:
                                                    (isLanguageEmpty ?
                                                        "1px solid #D32F2F" : "")
                                            }}
                                        >
                                            {isLoading ? (
                                                <MenuItem value="">
                                                    <CircularProgress size={24} />
                                                </MenuItem>
                                            ) : null}
                                            <MenuItem
                                                key={0}
                                                value={'I choose not to Answer'}
                                                name={0}
                                            >
                                                I choose not to Answer
                                            </MenuItem>
                                            {languageList.map((item) => (
                                                <MenuItem key={item.id} value={item.description}
                                                    name={item.id}
                                                    sx={{
                                                        display:
                                                            (item.description === 'I choose not to Answer') ? 'none' : 'block'
                                                    }}>
                                                    {item.description}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        {isLanguageEmpty ? <div style={{ fontSize: '12px', color: '#D32F2F', paddingLeft: '10px' }}><span style={{ fontWeight: 'bold' }}>Language </span>is Required</div> : null}
                                        {/* {errorText ? <FormHelperText>{errorText}</FormHelperText> : null} */}
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Grid>
                </Grid>
                <Stack
                    direction="column"
                    sx={{
                        marginTop: 1,
                    }}
                ></Stack>
                <Stack direction="row" spacing={2} style={{ marginTop: '8px' }}>
                    <Button
                        style={{
                            textTransform: "none",
                            borderColor: "black",
                            color: "black",
                            fontFamily: Styles.FONT_FAMILY_POPPINS,
                            marginLeft: "auto",
                        }}
                        variant="outlined"
                        onClick={closePopup}
                    >
                        Close
                    </Button>
                    <Button
                        style={{
                            textTransform: "none",
                            color: "white",
                            fontFamily: Styles.FONT_FAMILY_POPPINS,
                        }}
                        variant="contained"
                        color="orangeTheme"
                        disableElevation
                        onClick={handleDeclineCall}
                    >
                        Decline
                    </Button>
                </Stack>
            </Box>
        </Modal>
    );
};

export default DeclinePopupModal;
