import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import ScorecardComponent from "../../component/common/scorecard/scorecard";
const AIPReachReport = () => {
    const [keyName,setKeyName]=useState("ACO Reach Report");
    const [isTab,setIsTab]=useState("Yes");
    return (
        <Box >
            <ScorecardComponent keyName={keyName} isTab={isTab} tabParentScorecardId= {73}/>
        </Box>
    );
}

export default AIPReachReport;
