import PageHeader from "../../component/common/header/PageHeader";

const IPAlertToolFromTextingHeader = () => {
  const title = "Inpatient Alert Follow Up";
  const sevenPillarTitle = "Avoidable Inpatient Stays";
  return (
    <>
      <PageHeader title={title} sevenPillarTitle={sevenPillarTitle} />
    </>
  );
};

export default IPAlertToolFromTextingHeader;
