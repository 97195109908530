import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Styles } from "../../../Styles";
import { axiosRequests } from "../../../app/api/AxiosRequest";

const AddSubSectionModal = ({ isOpen, onClose, forceUpdate, setForceUpdate }) => {
  const [sectionName, setSectionName] = useState("");
  const [sectionDropdownData, setSectionDropdownData] = useState([]);
  const [sectionNameError, setSectionNameError] = useState("");
  const [subSectionName, setSubSectionName] = useState("");
  const [subSectionNameError, setSubSectionNameError] = useState("");
  const handleSectionNameChange = (e) => {
    setSectionName(e.target.value);
    setSectionNameError("");
  };
  const handleSubSectionNameChange = (e) => {
    setSubSectionName(e.target.value);
    setSubSectionNameError("");
  };

  const handleSave = () => {
    let hasError = false;
    if (!sectionName || sectionName === "") {
      setSectionNameError("Section Name is required");
      hasError = true;
    }
    if (!subSectionName || subSectionName === "") {
      setSubSectionNameError("Section Name is required");
      hasError = true;
    }
    if (hasError) return;
    let requestBody = {
      id: 0,
      title: subSectionName,
      sectionId: sectionName,
    };
    axiosRequests.post("/TrainingDocument/AddUpdateSubSection", requestBody).then((resp)=>{
        console.log(resp);
        setForceUpdate(forceUpdate + 1);
        onClose();
    })
  };
  useEffect(() => {
    if (isOpen) {
      setSectionName("");
      setSectionNameError("");
      setSubSectionName("");
      setSubSectionNameError("");
      axiosRequests.get("/TrainingDocument/GetSections").then((resp) => {
        setSectionDropdownData(resp);
      });
    }
  }, [isOpen]);
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      slotProps={{ backdrop: { onClick: null } }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "25%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "530px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          pt: 1,
          height: "auto",
        }}
      >
        <h3
          style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
        >
          Add New Sub Section
          <span>
            <CloseIcon onClick={onClose} style={{ float: "right" }} />
          </span>
        </h3>
        <hr
          style={{
            width: "auto",
            opacity: "0.3",
            marginTop: "-10px",
            marginBottom: "14px",
          }}
        />
        <Stack direction="column" spacing={2}>
          <Typography style={{ fontSize: "16px", fontWeight: "400" }}>
            Please add the title of sub section you wanted to add, also select
            the section to which the sub section is part of
          </Typography>
          <FormControl
            required
            color="orangeTheme"
            sx={{
              maxWidth: "auto",
              minWidth: "auto",
              backgroundColor: "#fff",
              borderRadius: "6px",
            }}
            size="small"
            error={!!sectionNameError}
          >
            <InputLabel id="role-name-select-small-label">Section</InputLabel>

            <Select
              required
              value={sectionName}
              className="roleNameDropdown"
              labelId="role-name-select-small-label"
              id="role-name-select-small"
              onChange={(e) => handleSectionNameChange(e)}
              label="Section"
            >
              {sectionDropdownData.map((item) => (
                <MenuItem
                  className="dropdownText"
                  key={item.id}
                  value={item.id}
                >
                  {item.title}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText style={{ height: "0px" }}>
              {sectionNameError}
            </FormHelperText>
          </FormControl>
          <TextField
            required
            label="Sub Section Name"
            onChange={handleSubSectionNameChange}
            id="outlined-size-small"
            value={subSectionName}
            error={!!subSectionNameError}
            helperText={subSectionNameError}
            style={{
              width: "auto",
              height: "40px",
              borderRadius: "6px",
              backgroundColor: "#fff",
            }}
            color="orangeTheme"
            size="small"
          />
          <hr style={{ width: "auto", opacity: "0.3", marginTop: "15px" }} />
          <Grid
            container
            spacing={6}
            style={{ marginLeft: "8px", marginTop: "-39px" }}
          >
            <Grid item xs={5}></Grid>
            <Grid item xs={3}>
              <Button
                style={{
                  borderColor: "black",
                  color: "black",
                  fontFamily: Styles.FONT_FAMILY_POPPINS,
                  marginLeft: "23px",
                  textTransform: "none",
                }}
                variant="text"
                onClick={onClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                style={{
                  color: "white",
                  fontFamily: Styles.FONT_FAMILY_POPPINS,
                  textTransform: "none",
                }}
                variant="contained"
                color="orangeTheme"
                onClick={handleSave}
                disableElevation
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </Modal>
  );
};

export default AddSubSectionModal;
