import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Styles } from "../../Styles";

const PopupConfirmationModal = ({ isOpen, onClose, rowData, information, message, handleDecline}) => {

 
  if (!rowData) return;
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      slotProps={{ backdrop: { onClick: null } }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "578px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          pt: 1,
          height: "auto",
        }}
      >
        <h3
          style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
        >
          {information}
          <span>
            <CloseIcon onClick={onClose} style={{ float: "right" }} />
          </span>
        </h3>
        <hr
          style={{
            width: "auto",
            opacity: "0.3",
            marginTop: "-11px",
            marginBottom: "5px",
          }}
        />
        <Stack direction="column" sx={{marginTop:'23px'}} spacing={2}>
          <Typography style={{ fontWeight: 500 }}>
            {message}
          </Typography>
        </Stack>
        <hr
              style={{
                width: "auto",
                opacity: "0.3",
                marginTop: "16px",
              }}
            />
            <Stack direction="row" spacing={2} style={{ marginTop:'8px' }}>
              <Button
                style={{
                  textTransform: "none",
                  borderColor: "black",
                  color: "black",
                  fontFamily: Styles.FONT_FAMILY_POPPINS,
                  marginLeft: "auto", 
                }}
                variant="outlined"
                onClick={onClose}
              >
                Close
              </Button>
              <Button
                style={{
                  textTransform: "none",
                  color: "white",
                  fontFamily: Styles.FONT_FAMILY_POPPINS,
                }}
                variant="contained"
                color="orangeTheme"
                disableElevation
                onClick={handleDecline}
              >
                Decline
              </Button>
            </Stack>
      </Box>
    </Modal>
  );
};

export default PopupConfirmationModal;
