import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { getRedirectURL } from "./webConfig";

//Msal Configurations
export const msalConfig = {
  auth: {
    tenantId: "bd2998fb-89a2-4dc6-8738-a4ac1d538372",
    clientId: "414719a9-2ba1-445e-9715-cf68c74bdf00",
    authority:
      "https://login.microsoftonline.com/bd2998fb-89a2-4dc6-8738-a4ac1d538372", // allows sign-ins with any type of account
    redirectUri: getRedirectURL(),
    postLogoutRedirectUri: getRedirectURL(),
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    allowRedirectInIframe: true,
  },
};

// Authentication Parameters
export const authenticationScope = [
  "user.read",
  "openid",
  "profile",
  "email",
  "offline_access",
  "https://analysis.windows.net/powerbi/api/Dashboard.Read.All",
  "https://analysis.windows.net/powerbi/api/Report.Read.All",
  "https://analysis.windows.net/powerbi/api/App.Read.All",
  "https://analysis.windows.net/powerbi/api/Dataset.Read.All",
];

// Authentication Parameters
export const powerBiAuthenticationScope = [
  
  "https://analysis.windows.net/powerbi/api/Dashboard.Read.All",
  "https://analysis.windows.net/powerbi/api/Report.Read.All",
  "https://analysis.windows.net/powerbi/api/App.Read.All",
  "https://analysis.windows.net/powerbi/api/Dataset.Read.All",
  
];
// // Options
// const options = {
//   loginType: LoginType.Popup,
//   tokenRefreshUri: window.location.origin + "/auth.html",
// };

// export const authProvider = new MsalAuthProvider(
//   msalConfig,
//   authenticationParameters,
//   options
// );
