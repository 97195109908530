import { Box, Grid, Stack, Typography, styled } from "@mui/material";
import ProgramsHeader from "./ProgramsHeader";
import ProgramNameDescriptionCard from "./ProgramNameDescriptionCard";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { axiosRequests } from "../../app/api/AxiosRequest";
import agent from "../../app/api/Agent";
import Loader from "../../component/common/loader/Loader";

const StyledLink = styled(Link)({
  cursor: "pointer",
});

const handleScrollToSection = (letter) => {
  document.getElementById(letter).scrollIntoView({ behavior: "smooth" });
};

const Programs = () => {
  const [programData, setProgramData] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    agent.ProgramDataAgent.getProgramsList().then((resp) => {
      setProgramData(resp);
    });
  }, []);

  useEffect(() => {
    var group = filteredData.reduce((acc, item) => {
      const firstLetter = item.title[0].toUpperCase();
      if (!acc[firstLetter]) {
        acc[firstLetter] = [];
      }
      acc[firstLetter].push(item);
      return acc;
    }, {});
    setGroupedData(group);
    setIsLoading(false);
  }, [filteredData]);

  useEffect(()=>{
    if(programData){
      let filtered;
      if(searchValue){
        filtered = programData.filter((item)=>
          item.title.toLowerCase().includes(searchValue.toLowerCase()) || (item.description && item.description.toLowerCase().includes(searchValue.toLowerCase()))
        );
        setFilteredData(filtered);
      }
      else{
        setFilteredData(programData);
      }
    }
  },[searchValue, programData])

  return (
    <Box sx={{ width: "100%" }}>
      <ProgramsHeader searchValue={searchValue} setSearchValue={setSearchValue} />
      {isLoading ? (
        <Loader isLoading={isLoading}></Loader>
      ) : (
        <div>
          <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
            <Typography>All Programs Index</Typography>
            {Object.keys(groupedData)
              .sort()
              .map((letter) => (
                <StyledLink
                  key={letter}
                  onClick={() => handleScrollToSection(letter)}
                >
                  {letter}
                </StyledLink>
              ))}
          </Stack>
          {Object.keys(groupedData)
            .sort()
            .map((letter) => (
              <Stack
                direction="column"
                key={letter}
                id={letter}
                style={{ marginBottom: "40px" }}
              >
                <Typography variant="h4" gutterBottom>
                  {letter}
                </Typography>
                <Grid container spacing={2}>
                  {groupedData[letter].map((item, index) => (
                    <Grid
                      item
                      xs="auto"
                      sm="auto"
                      md="auto"
                      lg="auto"
                      key={index}
                    >
                      <ProgramNameDescriptionCard
                        key={index}
                        name={item.title}
                        description={item.description}
                        url={item.url}
                        id={item.id}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            ))}
        </div>
      )}
    </Box>
  );
};

export default Programs;
