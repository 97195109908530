import { Box } from "@mui/material";
import { format, getDay, parse, startOfWeek } from "date-fns";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import CustomButtonToolbar from "./CustomButtonToolbar";
import "./EventsStyles.css";
import EventsHeader from "./EventsHeader";
import { useEffect, useState } from "react";
import { axiosRequests } from "../../app/api/AxiosRequest";
import EventDetailsModal from "./EventDetailsModal";
import CustomDayCellWrapper from "./CustomDayCellWrapper";
import CustomEventWrapper from "./CustomEventWrapper";

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const Events = ({ disableHeader = false, disableAddEvent = true }) => {
  const [events, setEvents] = useState([]);
  const [currentView, setCurrentView] = useState("week");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [eventModalOpen, setEventModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);
  useEffect(() => {
    axiosRequests
      .get("/EducationEvent/GetEducationEventDetails")
      .then((resp) => {
        const fetchedEvents = resp.map((item) => ({
          ...item,
          start: new Date(item.startDate),
          end: new Date(item.endDate),
        }));
        setEvents(fetchedEvents);
        setIsDeleted(false);
      });
  }, [isDeleted]);

  const handleShowMore = (events, date) => {
    setCurrentView("week");
    setCurrentDate(startOfWeek(date));
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setEventModalOpen(true);
    // setEvent
  };

  const onClose = () => {
    setEventModalOpen(false);
  };
  return (
    <Box sx={{ width: "100%" }}>
      <EventsHeader disableHeader={disableHeader}> </EventsHeader>
      <Calendar
        step={60}
        events={events}
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        style={{ height: "100vh" }}
        views={["month", "week"]}
        view={currentView}
        date={currentDate}
        onSelectEvent={handleEventClick}
        onNavigate={(date) => setCurrentDate(date)}
        onView={(view) => setCurrentView(view)}
        components={{
          toolbar: (props) => (
            <CustomButtonToolbar
              {...props}
              disableAddEvent={disableAddEvent}
              currentView={currentView}
            ></CustomButtonToolbar>
          ),
          dateCellWrapper: CustomDayCellWrapper,
        event: CustomEventWrapper
        }}
        onShowMore={handleShowMore}
      ></Calendar>
      {eventModalOpen && (
        <EventDetailsModal
          isOpen={eventModalOpen}
          onClose={onClose}
          eventDetails={selectedEvent}
          disableAddEvent={disableAddEvent}
          setIsDeleted={setIsDeleted}
        />
      )}
    </Box>
  );
};

export default Events;
