import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import agent from "../../app/api/Agent";
import agent from "../../../app/api/Agent";

const initialState = {
  isLoading: false,
  userRoleMappingData: [],
  errorMessage: "",
};

export const getUserRoleMappingList = createAsyncThunk(
  "userRoleMappingData",
  async () => {
    const response =
      await agent.UserRoleMappingDataAgent.getUserRoleMappingList();
    return response;
  }
);

const getUserRoleMappingListSlice = createSlice({
  name: "userRoleMappingData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserRoleMappingList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserRoleMappingList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.userRoleMappingData = action.payload;
      })
      .addCase(getUserRoleMappingList.rejected, (state, action) => {
        state.isLoading = false;
        state.userRoleMappingData = [];
        state.errorMessage = action.error.message;
      });
  },
});

export default getUserRoleMappingListSlice.reducer;
