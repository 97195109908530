import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";
import { PagesPath } from "../../../PagesPath";
import { axiosRequests } from "../../../app/api/AxiosRequest";
import { COLOR, Styles } from "../../../Styles";
import education from "../../../assets/education.svg";
import practiceAdminTool from "../../../assets/practiceAdminTool.svg";
import familyMemberProfileImage from "../../../assets/profileMemberPage.svg";

const PageHeader = ({
  title,
  sevenPillarTitle,
  children,
  imgSrc,
  disablePracticeAdminLink = false,
  disableEducation = false,
}) => {
  const [isGlobalAdmin, setIsGlobalAdmin] = useState(false);
  const [isPracticeAdmin, setIsPracticeAdmin] = useState(false);
  useEffect(() => {
    var globalAdmin = localStorage.getItem("globalAdmin");
    var practiceAdmin = localStorage.getItem("practiceAdmin");
    if (parseInt(globalAdmin) === 1) {
      setIsGlobalAdmin(true);
    } else {
      setIsGlobalAdmin(false);
    }
    if (parseInt(practiceAdmin) === 1) {
      setIsPracticeAdmin(true);
    } else {
      setIsPracticeAdmin(false);
    }
  }, []);
  const downloadUserGuide = () =>{
    let userTypeID=1;
    if (sevenPillarTitle == 'User Configuration'){
      if (parseInt(localStorage.getItem("globalAdmin")) === 1){
        userTypeID= 3;
      }
      else if (parseInt(localStorage.getItem("practiceAdmin")) === 1){
        userTypeID= 2;
      }
    }
    else{
      userTypeID= 1;
    }
    axiosRequests
    .downloadFile(
      `UserGuide/DownloadUserGuidePDFFile?documentType=${userTypeID}`
    )
    .then((response) => {
      let filename = "";
      let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      let matches = filenameRegex.exec(
        response.headers["content-disposition"]
      );
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
      let url = window.URL.createObjectURL(new Blob([response.data]));
      saveAs(url, filename);
      // setOpen(true);
    })
    .catch((err) => {
      console.log(err);
    });

  }
  return (
    <>
      <Grid container>
        <Grid item xs={6} md={6} justifyContent="flex-end">
          <Stack direction="row" spacing={0.5} sx={{ alignItems: "center" }}>
            <Typography
              sx={{
                textAlign: "left",
                letterSpacing: "0px",
                color: "#565656",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
                fontSize: "15px",
                fontWeight: "500",
              }}
            >
              {!!sevenPillarTitle && sevenPillarTitle}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={6} md={6} justifyContent="flex-end">
          <Stack direction="row" spacing={2} sx={{ justifyContent: "end" }}>
            <Stack
              style={{ display: disableEducation ? "none" : "" }}
              direction="row"
              spacing={1}
            >
              <img src={education} alt="" />
              <Link
                // to={PagesPath.Programs_URL}
                onClick={() => downloadUserGuide()}
                key="programs"
                style={{
                  textDecoration: "none",
                  underline: "hover",
                  // color: "inherit",
                }}
              >
                <Typography
                  sx={{
                    textAlign: "left",
                    letterSpacing: "0px",
                    color: "#141B2A",
                    opacity: 1,
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  User Guide
                </Typography>
              </Link>
            </Stack>
            {!disablePracticeAdminLink && !disableEducation && (
              <Divider
                sx={{
                  margin: 1.5,
                  background: "black",
                }}
                style={{
                  display: !isGlobalAdmin && !isPracticeAdmin ? "none" : "",
                }}
                orientation="vertical"
                flexItem
              />
            )}
            {!disablePracticeAdminLink && (
              <Stack
                style={{
                  display: !isGlobalAdmin && !isPracticeAdmin ? "none" : "",
                }}
                direction="row"
                spacing={1}
              >
                <img src={practiceAdminTool} alt="" />
                <Link
                  to={PagesPath.UserAdminConsoleURL}
                  key="BannerComponentURL"
                  style={{
                    textDecoration: "none",
                    underline: "hover",
                    // color: "inherit",
                  }}
                >
                  <Typography
                    sx={{
                      textAlign: "left",
                      letterSpacing: "0px",
                      color: "#141B2A",
                      opacity: 1,
                      fontFamily: Styles.FONT_FAMILY_POPPINS,
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                  >
                    Practice Admin Tool
                  </Typography>
                </Link>
              </Stack>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: 2 }}>
        <Grid item xs={12}>
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                textAlign: "left",
                letterSpacing: "1px",
                color: "#000",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
              }}
            >
              {title}
            </Typography>
            {children}
          </Stack>
          {/* </Link> */}
        </Grid>
      </Grid>
    </>
  );
};

export default PageHeader;
