import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { ListItemButton, Typography } from "@mui/material";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { COLOR, Styles } from "../../../Styles";
import { useMemo } from "react";
import { memo } from "react";

const SubMenuItem = ({
  id,
  name,
  path,
  icon,
  //handleDrawerClose,
  showOnNewTab,
}) => {
  const locationPath = useLocation();
  const active = path === locationPath.pathname;
  const pathKey=locationPath.pathname.split("/");
  const pathKeyLength=pathKey.length-1;
  if(pathKeyLength>0)
    localStorage.setItem("pathKeyName",pathKey[pathKeyLength])
  return (
    <Link
      target={
        showOnNewTab !== undefined
          ? showOnNewTab === true
            ? "_blank"
            : ""
          : ""
      }
      // onClick={() => handleDrawerClose()}
      to={path}
      key={path}
      style={{ textDecoration: "none" }}
    >
      <ListItemButton key={id} sx={{ pl: 4 }}>
        {icon ? (
          icon
        ) : (
          <FiberManualRecordIcon
            sx={{
              color: active ? COLOR.ORANGE : "#C5C3C2",
              height: "7px",
              width: "7px",
            }}
            fontSize="small"
          />
        )}
        <Typography
          sx={{
            opacity: 1 /*open ? 1 : 0,*/,
            color: active ? COLOR.ORANGE : "#333",
            fontFamily: Styles.FONT_FAMILY_POPPINS,
            fontSize: "12px",
            fontStyle: Styles.FONT_STYLE_NORMAL,
            fontWeight: "400",
            lineHeight: "18px",
            ml: "5px",
            // width: "250px",
            //padding: 10px;
            //margin-bottom: 20px;
            wordWrap: "normal",
            whiteSpace: "break-spaces",
            overflowWrap: "anywhere",
          }}
        >
          {name}
        </Typography>
      </ListItemButton>
    </Link>
  );
};

export default memo(SubMenuItem);
