import React, { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { axiosRequests } from "../../app/api/AxiosRequest";
import {
  Autocomplete,
  Box,
  Chip,
  CircularProgress,
  TextField,
} from "@mui/material";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { set } from "date-fns";

const SearchCodes = ({ source, memberDetail, payerName, setCodesArray }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [debouncedInputValue] = useDebounce(inputValue, 200);
  

  const handleAdd = (event, newValue) => {
    if (newValue && !selectedItems.includes(newValue.code)) {
      setSelectedItems([...selectedItems, newValue.code]);
    }
  };

  useEffect(() => {
    setInputValue("");
  }, [selectedItems]);

  useEffect(() => {
    setCodesArray(selectedItems);
  }, [selectedItems]);
  const handleDelete = (item) => {
    setSelectedItems(selectedItems.filter((i) => i !== item));
    setInputValue("");
  };

  const fetchOptions = async (query) => {
    if (query.length < 3) {
      setOptions([]);
      return;
    }
    setIsLoading(true);
    const apiUrl =
      source === "sdohGrid"
        ? `/ActiveGapClosure/GetSDOHSuspectCodes?OurId=${memberDetail.ourId}&payorName=${payerName}&SearchCode=${query}`
        : `/ActiveGapClosure/GetMedicalCodingSuspectCodes?OurId=${memberDetail.ourId}&payorName=${payerName}&SearchCode=${query}`;
    await axiosRequests
      .get(apiUrl)
      .then((resp) => {
        setOptions(resp);
      })
      .catch((error) => console.log(error))
      .finally(setIsLoading(false));
  };

  useEffect(() => {
    if (debouncedInputValue) {
      fetchOptions(debouncedInputValue);
    }
  }, [debouncedInputValue]);
  return (
    <>
      <Autocomplete
        options={options}
        getOptionLabel={(option) => option.code + " - " + option.definition}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        blurOnSelect={true}
        onBlur={() => setInputValue("")}
        clearOnBlur={true}
        onChange={handleAdd}
        loading={isLoading}
        noOptionsText={
          inputValue.length < 3
            ? "Please enter atleast 3 characters"
            : "No Options Available"
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              source.toLowerCase() === "sdohgrid"
                ? "SDOH Needs Identified"
                : "Active Conditions Diagnosed"
            }
            color="blackTheme"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {isLoading ? (
                    <CircularProgress color="orangeTheme" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <Box sx={{ display: "flex", flexWrap: "wrap", mt: 2 }}>
        <span style={{ marginTop: "auto", marginBottom: "auto" }}>
          <b>Selected Codes: </b>
        </span>
        {selectedItems.map((item, index) => (
          <Chip
            size="small"
            key={index}
            color="orangeTheme"
            style={{ color: "#fff" }}
            label={item}
            deleteIcon={<CancelOutlinedIcon color="orangeTheme" style={{color: '#fff'}}/>}
            onDelete={() => handleDelete(item)}
            sx={{ m: 0.5, height: "24px" }}
          />
        ))}
      </Box>
    </>
  );
};

export default SearchCodes;
