import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  timelineOppositeContentClasses,
  TimelineSeparator,
} from "@mui/lab";
import {
  Button,
  Chip,
  Grid,
  Stack,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { Styles } from "../../Styles";
import "./activeGapClosuresListCSS.css";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { format } from "date-fns";
// import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { axiosRequests } from "../../app/api/AxiosRequest";
import { saveAs } from "file-saver";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const StyledAuditContent = styled(Grid)({
  display: "flex",
  padding: "8px",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "8px",
  height: "auto",
  flex: "1 0 0",
  borderRadius: "4px",
  border: "1px solid #E0E0E0",
  background: "#FAFAFA",
});

const StyledAuditInfoContainer = styled(Grid)({
  display: "flex",
  alignItems: "flex-start",
  gap: "8px",
  alignSelf: "stretch",
  height: "auto",
});

const StyledAuditInfo = styled(Typography)({
  flex: "1 0 0",
  color: "rgba(0, 0, 0, 0.87)",
  fontFamily: Styles.FONT_FAMILY_POPPINS,
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 500,
  lineHeight: "157%" /* 21.98px */,
  letterSpacing: "0.1px",
});
const AuditTimelines = ({ auditData, source }) => {
  const handleSupportingFileDownload = (fileId) => {
    axiosRequests
      .downloadFile(
        `ActiveGapClosure/DownloadMedicalCodingSuspectFile?MedicalCodingSuspectId=${fileId}`
      )
      .then((response) => {
        let filename = "";
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(
          response.headers["content-disposition"]
        );
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
        let url = window.URL.createObjectURL(new Blob([response.data]));
        saveAs(url, filename);
        // setOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.15,
          marginLeft: "-6%",
          marginTop: "16px",
        },
        maxHeight: "350px",
        overflowY: "scroll",
      }}
    >
      {auditData.map((event, index) => (
        <TimelineItem className="timelineItemClass" key={index}>
          <TimelineOppositeContent style={{ paddingTop: 24 }}>
            <Typography
              style={{
                fontSize: "12px",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
                marginRight: "-12px",
              }}
            >
              <b>{format(event.createdOn, "MM/dd/yyyy")}</b>
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot
              color={
                event.auditStatus.toLowerCase() === "auditaccept"
                  ? "orangeTheme"
                  : "blackTheme"
              }
              style={{
                width: "45px",
                backgroundColor:
                  event.auditStatus.toLowerCase() === "auditaccept"
                    ? "#DC4F34"
                    : "rgba(251, 212, 188, 1)",
              }}
            >
              <Chip
                className={
                  event.auditStatus.toLowerCase() === "auditaccept"
                    ? "auditTimelineChip"
                    : ""
                }
                label={
                  event.auditStatus.toLowerCase() === "auditaccept" ? (
                    <CheckOutlinedIcon
                      color="blackTheme"
                      style={{ color: "#fff" }}
                    />
                  ) : (
                    auditData.length - index
                  )
                }
                style={{
                  color:
                    event.auditStatus.toLowerCase() === "auditaccept"
                      ? "#fff"
                      : "#000",
                }}
              />
            </TimelineDot>
            {index < auditData.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent>
            <StyledAuditContent>
              <StyledAuditInfoContainer>
                <StyledAuditInfo>
                  Visit Date: {format(event.visitDate, "MM/dd/yyyy")}
                </StyledAuditInfo>
                <StyledAuditInfo>
                  Audit Status:{" "}
                  {event.auditStatus.toLowerCase() === "auditaccept"
                    ? "Accept"
                    : event.auditStatus.toLowerCase() === "auditpending"
                    ? "Pending"
                    : "Reject"}
                </StyledAuditInfo>
                <StyledAuditInfo>
                  Code Status: {event.codeStatus}
                </StyledAuditInfo>
              </StyledAuditInfoContainer>
              <StyledAuditInfoContainer>
                <StyledAuditInfo>
                  {source.toLowerCase() !== "sdohgrid" ? (
                    <>
                      {(event.auditStatus.toLowerCase() === "auditaccept" ||
                        event.auditStatus.toLowerCase() === "auditpending") &&
                        event.codeStatus.toLowerCase() === "accept" && (
                          <Tooltip
                            onClick={() =>
                              handleSupportingFileDownload(event.fileId)
                            }
                            title={"Download Supporting Document"}
                            style={{ cursor: "pointer" }}
                          >
                            <FileDownloadOutlinedIcon color="blackTheme" />
                          </Tooltip>
                        )}
                      {event.auditStatus.toLowerCase() === "auditreject" &&
                        event.codeStatus.toLowerCase() === "accept" && (
                          <Stack direction="column" spacing={1}>
                            <StyledAuditInfo>
                              Audit Reject Reasons:{" "}
                              {event.rejectReasons
                                ? event.rejectReasons
                                : " - "}
                            </StyledAuditInfo>
                            <Tooltip
                              onClick={() =>
                                handleSupportingFileDownload(event.fileId)
                              }
                              title={"Download Supporting Document"}
                              style={{ cursor: "pointer" }}
                            >
                              <FileDownloadOutlinedIcon color="blackTheme" />
                            </Tooltip>
                          </Stack>
                        )}
                      {(event.auditStatus.toLowerCase() === "auditaccept" ||
                        event.auditStatus.toLowerCase() === "auditreject" ||
                        event.auditStatus.toLowerCase() === "auditpending") &&
                        event.codeStatus.toLowerCase() === "reject" && (
                          <Stack direction="column" spacing={1}>
                            <StyledAuditInfo>
                              Comments:{" "}
                              {event.comments ? event.comments : " - "}
                            </StyledAuditInfo>
                            <StyledAuditInfo>
                              Active Conditions Diagnosed:{" "}
                              {event.activeConditionDiagnosed
                                ? event.activeConditionDiagnosed
                                : " - "}
                            </StyledAuditInfo>
                          </Stack>
                        )}
                    </>
                  ) : (
                    <>
                      {event.codeStatus.toLowerCase() === "accept" && (
                        <Stack direction="column" spacing={1}>
                          <StyledAuditInfo>
                            Comments: {event.comments ? event.comments : " - "}
                          </StyledAuditInfo>
                        </Stack>
                      )}
                      {event.codeStatus.toLowerCase() === "reject" && (
                        <Stack direction="column" spacing={1}>
                          <StyledAuditInfo>
                            Comments: {event.comments ? event.comments : " - "}
                          </StyledAuditInfo>
                          <StyledAuditInfo>
                            SDOH Needs Identified:
                            {event.activeConditionDiagnosed
                              ? event.activeConditionDiagnosed
                              : " - "}
                          </StyledAuditInfo>
                        </Stack>
                      )}
                    </>
                  )}
                </StyledAuditInfo>
              </StyledAuditInfoContainer>
            </StyledAuditContent>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  );
};

export default AuditTimelines;
