import React, { useEffect, useState, } from "react";

import { Box, Stack, Grid, Button, Typography } from "@mui/material";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { COLOR, Styles } from "../../../Styles";
// import SDOHMemberDetails from "../sdohAssessmentDashboard/SDOHMemberDetails";
import { Link, useNavigate } from "react-router-dom";
import back_arrow from "../../../assets/back_arrow.svg";
import ViewCohortMemberListDetails from "./ViewCohortMemberListDetails";
import { useSelector } from "react-redux";

const ViewCohortDetails = ({ backAction, cohortId,rowData }) => {
    const memberDetail = useSelector(
        (state) => state.filterDetails.memberDetail
    );

    const [memberName, setMemberName]=useState("");

    useEffect(()=>{
        if(memberDetail && memberDetail?.memberName != undefined){
            setMemberName(memberDetail?.memberName);
        }
    },[memberDetail])

    return (
        <Box sx={{ width: "100%" }}>
            <Stack direction="column" spacing={0.2}>
                <ViewCohortMemberListDetails
                    cohortId={cohortId}
                    rowData={rowData}
                    backAction={backAction}
                ></ViewCohortMemberListDetails>
            </Stack>
        </Box>
    );
};

export default ViewCohortDetails;
