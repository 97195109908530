import React from "react";
import PageHeader from "../../component/common/header/PageHeader";

const ActiveGapClosuresListHeader = () => {
  const title = "Patient Assessment Form and Diagnosis Attestation";
  const sevenPillarTitle = "Quality Coding and Satisfaction";
  return (
    <>
      <PageHeader title={title} sevenPillarTitle={sevenPillarTitle} />
    </>
  );
};

export default ActiveGapClosuresListHeader;
