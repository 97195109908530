import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import MuiPagination from "@mui/material/Pagination";

export default function DataGridCustom({
  columns,
  rows,
  loading = true,
  checkboxSelection = false,
  onRowClick = null,
  getRowId = null,
  onSortModelChange = null,
  sortingMode = null,
  sortModel = undefined,
  sortingOrder = ["asc", "desc"],
  onPageChange = undefined,
  slots = undefined,
  slotProps = undefined,
  pageSize = 10,
  onSelectionModelChanged = undefined,
  apiRef = undefined,
  className = "",
  isRowSelectable = undefined,
  hideFooter = false,
  rowSelectionModel = undefined,
}) {
  // function Pagination({ page, onPageChange, className,count }) {
  //   // const apiRef = useGridApiContext();
  //   //  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  //    return (
  //      <MuiPagination
  //        color="primary"
  //        className={className}
  //        count={count}
  //        page={page + 1}
  //        onChange={(event, newPage) => {
  //          onPageChange(event, newPage - 1);
  //        }}
  //      />
  //    );
  //  }

  //  function CustomPagination(props) {
  //   // props.pageCount=30;
  //    return <GridPagination ActionsComponent={Pagination} {...props} />;
  //  }

  return (
    <DataGrid
      hideFooter={hideFooter}
      sx={{
        marginTop: 2,
        "& .MuiDataGrid-columnHeader": {
          backgroundColor: "#F2F2F2",
          color: "#11182799",
        },
        "& .MuiDataGrid-overlayWrapper": {
          height: "34px !important",
        },
        "& .MuiDataGrid-virtualScroller": {
          overflow: "visible !important",
        },
      }}
      rows={rows}
      className={className}
      onRowSelectionModelChange={onSelectionModelChanged}
      rowSelectionModel={rowSelectionModel}
      columns={columns}
      loading={loading}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: pageSize,
          },
        },
      }}
      pagination
      apiRef={apiRef}
      sortModel={sortModel}
      pageSizeOptions={[10]}
      checkboxSelection={checkboxSelection}
      disableRowSelectionOnClick
      onRowClick={onRowClick}
      getRowId={getRowId}
      sortingOrder={sortingOrder}
      sortingMode={sortingMode}
      disableColumnMenu={true}
      onSortModelChange={onSortModelChange}
      onPageChange={onPageChange}
      slots={slots}
      slotProps={slotProps}
      isRowSelectable={isRowSelectable}
    />
  );
}
