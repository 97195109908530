import React, { useEffect, useState } from "react";
import SDOHCodingSuspectsStateUpdate from "./sdohCodingSuspectsStateUpdate";
import DataGridHeader from "../../component/common/datagrid/DataGridHeader";
import { Styles } from "../../Styles";
import { Button, Chip, Stack, Tooltip, Typography } from "@mui/material";
import DataGridCustom from "../../component/common/datagrid/DataGridCustom";
import "./activeGapClosuresListCSS.css";
import { useGridApiRef } from "@mui/x-data-grid";
import NewRecaptureCodeModal from "./NewRecaptureCodeModal";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { format } from "date-fns";
import { axiosRequests } from "../../app/api/AxiosRequest";
import CodeDetailsModal from "./CodeDetailsModal";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

const SDOHCodingSuspectsGrid = ({
  memberDetail,
  forceUpdate,
  setForceUpdate,
  handleViewDocumentsOpen,
  isLoading,
  payerName,
  hideExcludeMember = false,
  isAuditor = false,
  auditStatusId = 1,
  priority = undefined,
}) => {
  const [title, setTitle] = useState("");
  const [rowId, setRowId] = useState(null);
  const [readOnly, setReadOnly] = useState(false);
  const [openNewRecaptureCodeModal, setOpenNewRecaptureModal] = useState(false);
  const [isSDOHCodingSuspectsModalOpen, setIsSDOHCodingSuspectsModalOpen] =
    useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [codeDetailsModalOpen, setCodeDetailsModalOpen] = useState(false);
  const [codeDetailsRow, setCodeDetailsRow] = useState({});
  // const rows = useState(memberDetail.sdohCodingSuspects);
  const [rows, setRows] = useState(memberDetail.sdohCodingSuspects);
  const apiRef = useGridApiRef();
  const handleopenSDOHCodingSuspectStateUpdateOpen = (
    measureTitle,
    rowId,
    readOnly
  ) => {
    setTitle(measureTitle);
    setRowId(rowId);
    setReadOnly(readOnly);
    setIsSDOHCodingSuspectsModalOpen(true);
  };
  const isRowSelectable = (params) => {
    return (
      params.row.status === "" ||
      !params.row.status ||
      params.row.status === "-"
    );
  };
  const handleAuditAcceptForRow = (row) => {
    let requestBody = {
      ourId: memberDetail.ourId,
      medicalCodingSuspectId: row.id,
      medicalCodeSuspectDetailId: row.medicalCodeSuspectDetailId,
    };
    axiosRequests
      .post("/ActiveGapClosure/AuditAcceptForSDOHCodingSuspect", requestBody)
      .then((resp) => {
        if (resp) {
          console.log("Success!");
          setForceUpdate(forceUpdate + 1);
        }
      });

    return;
  };
  const handleAuditRejectForRow = (row) => {
    let requestBody = {
      ourId: memberDetail.ourId,
      medicalCodingSuspectId: row.id,
      medicalCodeSuspectDetailId: row.medicalCodeSuspectDetailId,
    };
    axiosRequests
      .post("/ActiveGapClosure/AuditRejectForSDOHCodingSuspect", requestBody)
      .then((resp) => {
        if (resp) {
          console.log("Success!");
          setForceUpdate(forceUpdate + 1);
        }
      });

    return;
  };
  const handleSelectionChange = (selectionModel) => {
    const selectedIDs = new Set(selectionModel);
    const selectedData = rows.filter((row) => selectedIDs.has(row.id));
    setSelectedRows(selectedData);
  };

  const handleCodeDetailsModalOpen = (row) => {
    setCodeDetailsModalOpen(true);
    setCodeDetailsRow(row);
  };

  useEffect(() => {
    setRows(memberDetail.sdohCodingSuspects);
  }, [memberDetail]);
  const onClose = () => {
    setIsSDOHCodingSuspectsModalOpen(false);
    setOpenNewRecaptureModal(false);
    setCodeDetailsModalOpen(false);
  };
  const handleOpenNewRecaptureCodeModal = () => {
    setOpenNewRecaptureModal(true);
  };
  const columnsSDOHCodingSuspectsTotalOpen = [
    {
      field: "sdohCode",
      headerName: "SDOH Code",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Code Status",
      flex: 1,
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 1,
    },
    {
      field: "activeConditionDiagnosed",
      headerClassName: "classMultilineHeader",
      headerName: "SDOH Needs Identified",
      flex: 1,
    },
    {
      field: "visitDate",
      headerName: "Visit Date",
      flex: 1,
      renderCell: (params) => {
        if (params.row.visitDate) {
          return <span>{format(params.row.visitDate, "MM/dd/yyyy")}</span>;
        }
      },
    },
    {
      field: "actions",
      headerName:
        isAuditor && auditStatusId === 2 ? "Audit Actions" : "Actions",
      flex: 1,
      renderCell: (params) => {
        if (isAuditor && auditStatusId === 2) {
          return (
            <Stack direction="row" spacing={1}>
              <Button
                color="orangeTheme"
                size="small"
                sx={{ textTransform: "none", color: "#F37021" }}
                variant="outlined"
                onClick={() => handleAuditAcceptForRow(params.row)}
              >
                Accept
              </Button>
              <Button
                color="blackTheme"
                size="small"
                sx={{ textTransform: "none" }}
                variant="outlined"
                onClick={() => handleAuditRejectForRow(params.row)}
              >
                Reject
              </Button>
            </Stack>
          );
        }
        if (
          params.row.status.toLowerCase() !== "accept" &&
          params.row.status.toLowerCase() !== "deny" &&
          params.row.status.toLowerCase() !== "reject"
        ) {
          if (!selectedRows || selectedRows.length < 2) {
            return (
              <Tooltip
                title={"Update Status"}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleopenSDOHCodingSuspectStateUpdateOpen(
                    params.row.sdohCode,
                    params.row.id,
                    params.row.ourId
                  )
                }
              >
                <EditOutlinedIcon color="blackTheme" />
              </Tooltip>
            );
          }
        }
      },
    },
  ];
  const columnsSDOHCodingSuspectsAuditAccept = [
    {
      field: "sdohCode",
      headerName: "SDOH Code",
      headerClassName: "classMultilineHeader",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <span onClick={() => handleCodeDetailsModalOpen(params.row)}>
            <Typography
              style={{
                textDecoration: "underline",
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              {params.row.sdohCode}
            </Typography>
          </span>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Code Status",
      headerClassName: "classMultilineHeader",
      flex: 0.5,
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 1,
    },
    {
      field: "visitDate",
      headerName: "Visit Date",
      flex: 1,
      renderCell: (params) => {
        if (params.row.visitDate) {
          return <span>{format(params.row.visitDate, "MM/dd/yyyy")}</span>;
        }
      },
    },

    {
      field: "actions",
      headerName:
        isAuditor && auditStatusId === 2 ? "Audit Actions" : "Actions",
      flex: 1,
      renderCell: (params) => {
        if (isAuditor && auditStatusId === 2) {
          return (
            <Stack direction="row" spacing={1}>
              <Button
                color="orangeTheme"
                size="small"
                sx={{ textTransform: "none", color: "#F37021" }}
                variant="outlined"
                onClick={() => handleAuditAcceptForRow(params.row)}
              >
                Accept
              </Button>
              <Button
                color="blackTheme"
                size="small"
                sx={{ textTransform: "none" }}
                variant="outlined"
                onClick={() => handleAuditRejectForRow(params.row)}
              >
                Reject
              </Button>
            </Stack>
          );
        }
        if (
          params.row.status.toLowerCase() !== "accept" &&
          params.row.status.toLowerCase() !== "deny" &&
          params.row.status.toLowerCase() !== "reject"
        ) {
          if (!selectedRows || selectedRows.length < 2) {
            return (
              <Tooltip
                title={"Update Status"}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleopenSDOHCodingSuspectStateUpdateOpen(
                    params.row.sdohCode,
                    params.row.id,
                    params.row.ourId
                  )
                }
              >
                <EditOutlinedIcon color="blackTheme" />
              </Tooltip>
            );
          }
        }
      },
    },
  ];
  const columnsSDOHCodingSuspectsAuditPending = [
    {
      field: "sdohCode",
      headerName: "SDOH Code",
      headerClassName: "classMultilineHeader",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <span onClick={() => handleCodeDetailsModalOpen(params.row)}>
            <Typography
              style={{
                textDecoration: "underline",
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              {params.row.sdohCode}
            </Typography>
          </span>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Code Status",
      headerClassName: "classMultilineHeader",
      flex: 0.5,
    },
    {
      field: "comments",
      headerName: "Comments",
      flex: 1,
    },
    {
      field: "visitDate",
      headerName: "Visit Date",
      flex: 1,
      renderCell: (params) => {
        if (params.row.visitDate) {
          return <span>{format(params.row.visitDate, "MM/dd/yyyy")}</span>;
        }
      },
    },

    {
      field: "actions",
      headerName:
        isAuditor && auditStatusId === 2 ? "Audit Actions" : "Actions",
      flex: 1,
      renderCell: (params) => {
        if (isAuditor && auditStatusId === 2) {
          return (
            <Stack direction="row" spacing={1}>
              <Button
                color="orangeTheme"
                size="small"
                sx={{ textTransform: "none", color: "#F37021" }}
                variant="outlined"
                onClick={() => handleAuditAcceptForRow(params.row)}
              >
                Accept
              </Button>
              <Button
                color="blackTheme"
                size="small"
                sx={{ textTransform: "none" }}
                variant="outlined"
                onClick={() => handleAuditRejectForRow(params.row)}
              >
                Reject
              </Button>
            </Stack>
          );
        }
        if (
          params.row.status.toLowerCase() !== "accept" &&
          params.row.status.toLowerCase() !== "deny" &&
          params.row.status.toLowerCase() !== "reject"
        ) {
          if (!selectedRows || selectedRows.length < 2) {
            return (
              <Tooltip
                title={"Update Status"}
                style={{ cursor: "pointer" }}
                onClick={() =>
                  handleopenSDOHCodingSuspectStateUpdateOpen(
                    params.row.sdohCode,
                    params.row.id,
                    params.row.ourId
                  )
                }
              >
                <EditOutlinedIcon color="blackTheme" />
              </Tooltip>
            );
          }
        }
      },
    },
  ];
  const columnsSDOHCodingSuspectsAuditReject = [
    {
      field: "sdohCode",
      headerName: "SDOH Code",
      headerClassName: "classMultilineHeader",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <span onClick={() => handleCodeDetailsModalOpen(params.row)}>
            <Typography
              style={{
                textDecoration: "underline",
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              {params.row.sdohCode}
            </Typography>
          </span>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Code Status",
      headerClassName: "classMultilineHeader",
      flex: 0.5,
    },

    {
      field: "comments",
      headerName: "Comments",
      flex: 1,
    },
    {
      field: "visitDate",
      headerName: "Visit Date",
      flex: 1,
      renderCell: (params) => {
        if (params.row.visitDate) {
          return <span>{format(params.row.visitDate, "MM/dd/yyyy")}</span>;
        }
      },
    },
    // {
    //   field: "rejectReasons",
    //   headerName: "Rejection Reason",
    //   flex: 1,
    // },
    {
      field: "actions",
      headerName:
        isAuditor && auditStatusId === 2 ? "Audit Actions" : "Actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <Tooltip
            title={"Update Status"}
            style={{ cursor: "pointer" }}
            onClick={() =>
              handleopenSDOHCodingSuspectStateUpdateOpen(
                params.row.sdohCode,
                params.row.id,
                params.row.ourId
              )
            }
          >
            <EditOutlinedIcon color="blackTheme" />
          </Tooltip>
        );
      },
    },
  ];
  return (
    <div style={{ marginTop: "16px", display: "grid", height: 426 }}>
      {
        <DataGridCustom
          columns={
            auditStatusId === 0
              ? columnsSDOHCodingSuspectsTotalOpen
              : auditStatusId === 1
              ? columnsSDOHCodingSuspectsAuditAccept
              : auditStatusId === 2
              ? columnsSDOHCodingSuspectsAuditPending
              : columnsSDOHCodingSuspectsAuditReject
          }
          rows={memberDetail.sdohCodingSuspects}
          pageSize={5}
          //getRowId={getRowId}
          loading={isLoading}
          sortingOrder={["asc", "desc"]}
          slots={{
            toolbar: DataGridHeader,
          }}
          apiRef={apiRef}
          className="pafGrid"
          checkboxSelection={true}
          onSelectionModelChanged={(newSelection) => {
            handleSelectionChange(newSelection);
          }}
          isRowSelectable={isRowSelectable}
          slotProps={{
            toolbar: {
              headerText: "",
              fontSize: "",
              marginTop: "",
              marginBottom: "",
              height: "56px",
              children: (
                // <Stack direction="row" spacing={2}>
                <div
                  style={{
                    fontFamily: Styles.FONT_FAMILY_POPPINS,
                    fontSize: "16px",
                    width: "100%",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginLeft: "5px",
                  }}
                >
                  <Stack direction="row" spacing={1}>
                    <Typography
                      style={{
                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                        fontSize: "16px",
                        marginTop: "auto",
                        marginBottom: "auto",
                      }}
                    >
                      SDOH Recapture Codes
                    </Typography>
                    <Chip
                      color="orangeTheme"
                      variant="contained"
                      style={{ color: "#fff" }}
                      label={memberDetail.sdohCodingSuspects.length}
                    ></Chip>
                    <Stack
                      direction="row"
                      spacing={1}
                      style={{ marginLeft: "auto" }}
                    >
                      {
                        <Button
                          variant="outlined"
                          color="orangeTheme"
                          style={{
                            textTransform: "none",
                            fontFamily: Styles.FONT_FAMILY_POPPINS,
                            float: "right",
                          }}
                          onClick={handleOpenNewRecaptureCodeModal}
                        >
                          <AddOutlinedIcon></AddOutlinedIcon>New SDOH Codes
                        </Button>
                      }
                      {selectedRows.length > 1 && (
                        <Button
                          variant="outlined"
                          color="blackTheme"
                          style={{
                            fontFamily: Styles.FONT_FAMILY_POPPINS,
                            float: "right",
                            textTransform: "none",
                          }}
                          onClick={handleopenSDOHCodingSuspectStateUpdateOpen}
                        >
                          Bulk Status Update
                        </Button>
                      )}
                    </Stack>
                  </Stack>
                </div>

                // </Stack>
              ),
            },
          }}
        />
      }
      {isSDOHCodingSuspectsModalOpen && (
        <SDOHCodingSuspectsStateUpdate
          isOpen={isSDOHCodingSuspectsModalOpen}
          onClose={onClose}
          title={title}
          rowId={rowId}
          ourId={memberDetail.ourId}
          forceUpdate={forceUpdate}
          source="sdohGrid"
          setForceUpdate={setForceUpdate}
          rows={selectedRows}
          memberDetail={memberDetail}
          payerName={payerName}
        />
      )}
      {openNewRecaptureCodeModal && (
        <NewRecaptureCodeModal
          isOpen={openNewRecaptureCodeModal}
          onClose={onClose}
          memberDetail={memberDetail}
          payerName={payerName}
          forceUpdate={forceUpdate}
          setForceUpdate={setForceUpdate}
          sourceGrid="sdohGrid"
          priority={priority}
        ></NewRecaptureCodeModal>
      )}
      {codeDetailsModalOpen && (
        <CodeDetailsModal
          isOpen={codeDetailsModalOpen}
          onClose={onClose}
          rowData={codeDetailsRow}
          source="sdohGrid"
        />
      )}
    </div>
  );
};

export default SDOHCodingSuspectsGrid;
