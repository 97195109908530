import React from "react";
import PageHeader from "../../component/common/header/PageHeader";

const EducationConfigurationFormsHeader = ({pageTitle}) => {
    const title = "";
     const sevenPillarTitle = "Education Configuration";
  return (
    <>
   
      <PageHeader title={title} sevenPillarTitle={sevenPillarTitle}/>
      
    </>
  );
};

export default EducationConfigurationFormsHeader;
