import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../app/store";
import { Button, Chip, Stack, Typography } from "@mui/material";
import Loader from "../../../component/common/loader/Loader";
import DataGridCustom from "../../../component/common/datagrid/DataGridCustom";
import DataGridHeader from "../../../component/common/datagrid/DataGridHeader";
import Searchbox from "../../../component/common/searchbox/Searchbox";
import { Styles } from "../../../Styles";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { getTrainingDocumentsList } from "./TrainingDocumentsSlice";
import { PagesPath } from "../../../PagesPath";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import DeleteRecord from "../DeleteRecord";

const TrainingPrograms = () => {
  const { isLoading, trainingDocumentsData } = useSelector(
    (state) => state.trainingDocumentsData
  );
  const [selectedRowForModal, setSelectedRowForModal] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [filteredDocuments, setfilteredDocuments] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useAppDispatch();

  const getTrainingDocumentsListData = useCallback(async () => {
    const response = await dispatch(getTrainingDocumentsList());
  }, [dispatch]);

  useEffect(() => {
    getTrainingDocumentsListData();
  }, [getTrainingDocumentsListData, forceUpdate]);

  const openDeleteModal = (row) => {
    setSelectedRowForModal(row);
    setIsDeleteModalOpen(true);
  };

  const closeModal = () => {
    setIsDeleteModalOpen(false);
  };

  const columns = [
    {
      field: "title",
      headerName: "Program Title",
      flex: 2,
    },
    {
      field: "section",
      headerName: "Section",
      flex: 1,
    },
    {
      field: "subSection",
      headerName: "Sub Section",
      flex: 1,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <strong>
            <Link
              to={PagesPath.AddTrainingDocument}
              state={{ formState: params.row }}
              key="AddProgram"
              style={{
                textDecoration: "none",
                underline: "hover",
                color: "inherit",
                marginRight: "8px",
              }}
            >
              <EditIcon></EditIcon>
            </Link>
            <Link
              key="DeleteUser"
              style={{
                textDecoration: "none",
                underline: "hover",
                color: "inherit",
                marginRight: "8px",
              }}
            >
              <DeleteIcon
                onClick={() => openDeleteModal(params.row)}
              ></DeleteIcon>
            </Link>
          </strong>
        );
      },
    },
  ];

  useEffect(() => {
    if (trainingDocumentsData) {
      let filtered;
      if (searchValue) {
        filtered = trainingDocumentsData.filter(
          (item) =>
            item.title.toLowerCase().includes(searchValue.toLowerCase()) ||
            (item.description &&
              item.description
                .toLowerCase()
                .includes(searchValue.toLowerCase()))
        );
        setfilteredDocuments(filtered);
      } else {
        setfilteredDocuments(trainingDocumentsData);
      }
    }
  }, [searchValue, trainingDocumentsData]);

  return (
    <div>
      <Stack direction="column" spacing={2}>
        {isLoading ? (
          <Loader isLoading={isLoading} />
        ) : (
          <div style={{ height: 680 }}>
            <DataGridCustom
              columns={columns}
              loading={isLoading}
              rows={filteredDocuments}
              sortingOrder={["asc", "desc"]}
              sortingMode="client"
              slots={{
                toolbar: DataGridHeader,
              }}
              slotProps={{
                toolbar: {
                  headerText: "",
                  fontSize: "",
                  marginTop: "",
                  marginBottom: "",
                  height: "56px",
                  children: (
                    <div
                      style={{
                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                        fontSize: "16px",
                        width: "100%",
                        marginTop: "auto",
                        marginBottom: "auto",
                        marginLeft: "5px",
                      }}
                    >
                      <Stack direction="row" spacing={1}>
                        <Typography
                          style={{
                            fontFamily: Styles.FONT_FAMILY_POPPINS,
                            fontSize: "16px",
                            marginTop: "auto",
                            marginBottom: "auto",
                          }}
                        >
                          Total Training Documents
                        </Typography>
                        <Chip
                          color="orangeTheme"
                          variant="contained"
                          style={{ color: "#fff" }}
                          label={trainingDocumentsData.length}
                        ></Chip>
                        <Stack
                          direction="row"
                          spacing={1}
                          style={{ marginLeft: "auto" }}
                        >
                          <Searchbox
                            cursor="default"
                            defaultValue={searchValue}
                            handleChange={(e) => setSearchValue(e.target.value)}
                            label="Search Document"
                          ></Searchbox>
                          <Link
                            to={PagesPath.AddTrainingDocument}
                            key="AddTrainingDocument"
                            style={{
                              textDecoration: "none",
                              underline: "hover",
                              color: "inherit",
                            }}
                          >
                            <Button
                              variant="outlined"
                              color="orangeTheme"
                              style={{
                                fontFamily: Styles.FONT_FAMILY_POPPINS,
                                textTransform: "none",
                                float: "right",
                              }}
                            >
                              <AddOutlinedIcon />
                              Add Training Document
                            </Button>
                          </Link>
                        </Stack>
                      </Stack>
                    </div>
                  ),
                },
              }}
            />
          </div>
        )}
      </Stack>
      {isDeleteModalOpen && (
        <DeleteRecord
          isOpen={isDeleteModalOpen}
          onClose={closeModal}
          forceUpdate={forceUpdate}
          rowData={selectedRowForModal}
          setForceUpdate={setForceUpdate}
          source="trainingdocument"
        />
      )}
    </div>
  );
};

export default TrainingPrograms;
