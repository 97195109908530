import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Stack,
  Typography,
  Link,
  Button,
  Stepper,
  Step,
  StepButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { COLOR, Styles } from "../../../Styles";
import back_arrow from "../../../assets/back_arrow.svg";
import { useAppDispatch } from "../../../app/store";
import CreateCohortRegistryHeader from "./CreateCohortRegistryHeader";
import { checkUserToolAccess } from "../../../component/common/userAuthorization/checkUserAccess";
import NoAccessPage from "../../../component/common/noAccess/NoAccessPage";
import { useSelector } from "react-redux";
import { PagesPath } from "../../../PagesPath";
import CreateCohortFilterandMemberList from "./CreateCohortFilterandMemberList";
import CreateCohortDetailsPage_Step2 from "./CreateCohortDetailsPage_Step2";
import CreateCohortPreviewPage from "./CreateCohortPreviewPage";

const CreateCohortRegistryManagement = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const CohortRegistry = 39;
  const [accessObj] = useState(checkUserToolAccess(CohortRegistry));
  const steps = ["Filters and Members List", "Details", "Preview"]; //'About',
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState([]);
  const arr = [];

  const { memberId } = useSelector((state) => state.filterDetails);

  const backToHome = () => {
    var idUrl = PagesPath.CohortRegistryManagement_URL;
    navigate(idUrl);
};

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  const handleReset = () => {
    setActiveStep(0);
    setCompleted([]);
  };

  const handleNext = () => {
    for (let i = 0; i <= activeStep; i++) {
      arr.push(true);
    }
    setCompleted(arr);
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const stepStyle = {
    paddingTop: 2,
    paddingLeft: 15,
    paddingRight: 15,
    "& .Mui-active": {
      "&.MuiStepIcon-root": {
        color: "warning.main",
      },
      "& .MuiStepConnector-line": {
        borderColor: "warning.main",
      },
    },
    "& .Mui-completed": {
      "&.MuiStepIcon-root": {
        color: "#ed6c02",
      },
      "& .MuiStepConnector-line": {
        borderColor: "secondary.main",
      },
    },
    " .MuiStepIcon-text": {
            fill: "white !important"
        }
  };
  return (
    <Box>
        <CreateCohortRegistryHeader />
      {!accessObj.hasAccess ? (
        <NoAccessPage></NoAccessPage>
      ) : (
        <>
            <Grid container>
              <Grid item xs={12}>
                <Link
                  onClick={backToHome} 
                  key="SDOHMemberDetail"
                  state={{ searchNameValue: "" }}
                  style={{
                    textDecoration: "none",
                    "&:hover": {
                      color: COLOR.ORANGE,
                    },
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                      alignContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    <img src={back_arrow} alt="" />
                    <Typography
                      sx={{
                        textAlign: "left",
                        letterSpacing: "1px",
                        color: "#000",
                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                      }}
                    >
                      Create New Cohort
                    </Typography>
                  </Stack>
                </Link>
              </Grid>
            </Grid>
          <Grid container style={{ justifyContent: "center" }} spacing={1.5}>
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                marginLeft: "1%",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
              }}
            >
              <Stack sx={{ width: "100%" }}>
                <Stepper nonLinear activeStep={activeStep} sx={stepStyle}>
                  {steps.map((label, index) => (
                    <Step key={label} completed={completed[index]}>
                      {index < 3 ? (
                        <StepButton>{label}</StepButton>
                      ) : (
                        <StepButton>{label}</StepButton>
                      )}
                    </Step>
                  ))}
                </Stepper>
                {allStepsCompleted() ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Stack direction="row" sx={{ pt: 2 }}>
                      <Stack sx={{ flex: "1 1 auto" }} />
                      <Button onClick={handleReset}>Reset</Button>
                    </Stack>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div style={{ display: activeStep == 0 ? "" : "none" }}>
                      <CreateCohortFilterandMemberList
                        step={activeStep}
                        handleNextCall={handleNext}
                        // ourId={ourId}
                        handleCancelCall={backToHome}
                      />
                    </div>
                    <div style={{ display: activeStep == 1 ? "" : "none" }}>
                      <CreateCohortDetailsPage_Step2
                      step={activeStep}
                      handleNextCall={handleNext}
                      handleBackCall={handleBack}
                      isDisabledMode={false}
                      // ourId={ourId}
                      handleCancelCall={backToHome}
                      />
                      
                    </div>
                    <div style={{ display: activeStep == 2 ? "" : "none" }}>
                    <CreateCohortPreviewPage
                      step={activeStep}
                      handleNextCall={handleNext}
                      handleBackCall={handleBack}
                      isDisabledMode={true}
                      // ourId={ourId}
                      handleCancelCall={backToHome}
                      />
                    </div>
                  </React.Fragment>
                )}
              </Stack>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default CreateCohortRegistryManagement;
