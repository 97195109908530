import {
  Button,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  styled,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useState } from "react";
import Searchbox from "../../component/common/searchbox/Searchbox";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import { PagesPath } from "../../PagesPath";
import { CalendarIcon } from "@mui/x-date-pickers";

const StyledButton = styled(Button)({
  textTransform: "none",
  width: "auto",
  height: "30px",
  borderColor: "#000",
  marginTop: "auto !important",
  marginBottom: "auto !important",
});
const StyledToggleButton = styled(ToggleButton)({
  textTransform: "none",
  width: "100px",
  height: "38px",
});

const CustomButtonToolbar = (props) => {
  const [calenderView, setCalendarView] = useState(props.currentView);

  const goToBack = () => {
    let mDate = props.date;
    let newDate = new Date(mDate.getFullYear(), mDate.getMonth() - 1, 1);
    props.onNavigate("PREV");
  };

  const goToNext = () => {
    let mDate = props.date;
    let newDate = new Date(mDate.getFullYear(), mDate.getMonth() + 1, 1);
    props.onNavigate("NEXT");
  };

  const goToCurrent = () => {
    let now = new Date();
    //let newDate = new Date(mDate.getFullYear(), mDate.getMonth()+1, 1);
    props.onNavigate("TODAY");
  };

  const goToWeekView = () => {
    props.onView("week");
  };

  const goToMonthView = () => {
    props.onView("month");
  };

  const handleChange = (e) => {
    if (e.target.value === "week") {
      setCalendarView("week");
      goToWeekView();
    } else if (e.target.value === "month") {
      setCalendarView("month");
      goToMonthView();
    }
  };

  return (
    <Stack direction="row" style={{ marginBottom: "10px" }} spacing={1}>
      <StyledButton color="blackTheme" variant="outlined" onClick={goToCurrent}>
        <CalendarIcon></CalendarIcon>
        <span style={{ marginLeft: "8px" }}>Today</span>
      </StyledButton>
      <ChevronLeftIcon
        style={{ marginTop: "auto", marginBottom: "auto", cursor: "pointer" }}
        onClick={goToBack}
      />
      <ChevronRightIcon
        style={{ marginTop: "auto", marginBottom: "auto" }}
        onClick={goToNext}
      />
      <Typography
        style={{ fontSize: "20px", marginTop: "auto", marginBottom: "auto" }}
      >
        {props.label}
      </Typography>

      <Stack direction="row" style={{ marginLeft: "auto" }} spacing={1}>
        <Searchbox
          sx={{
            marginTop: "auto !important",
            marginBottom: "auto !important",
            display: "none", //Hiding it as it not needed for now --27th June 2024
          }}
          label="Search Event"
        ></Searchbox>
        <ToggleButtonGroup
          color="orangeTheme"
          value={calenderView}
          onChange={handleChange}
          exclusive
        >
          <StyledToggleButton value="week">By Week</StyledToggleButton>
          <StyledToggleButton value="month">By Month</StyledToggleButton>
        </ToggleButtonGroup>
        <Link
          to={PagesPath.AddEvent}
          key="AddProgram"
          style={{
            textDecoration: "none",
            underline: "hover",
            color: "inherit",
          }}
        >
          <StyledButton
            variant="outlined"
            color="orangeTheme"
            style={{
              display: props.disableAddEvent ? "none" : "",
              width: "auto",
              height: "36px",
            }}
          >
            <AddIcon style={{ paddingTop: "5px", marginTop: "-4px" }} />
            Add Event
          </StyledButton>
        </Link>
      </Stack>
    </Stack>
  );
};

export default CustomButtonToolbar;
