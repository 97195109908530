import React from "react";
import PageHeader from "../../component/common/header/PageHeader";

const HealthHomeFormsHeader = () => {
  const title = "";
  const sevenPillarTitle = "Health Home Education Configurations";
  return (
    <>
      <PageHeader
        disablePracticeAdminLink={true}
        title={title}
        sevenPillarTitle={sevenPillarTitle}
      />
    </>
  );
};

export default HealthHomeFormsHeader;
