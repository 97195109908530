import React from "react";
import { Typography,Grid } from "@mui/material";
import { Styles } from "../../Styles";
import { format } from "date-fns";

const PdfTemplate = ({logoSrc, titleText, formData, signatureImage}) => (

    <div style={{padding: '20px'}}>
        <Grid container direction="column" alignItems="center">
            <Grid item>
            <img style={{ width: '129px', height: '29px', margin: '0 auto', display: 'block' }} src={logoSrc}></img>
            </Grid>
            <Grid item>
            <center><h5>Confirmation Of Main Doctor Or Other Healthcare Professional  Form</h5></center>
            <center><h5>By signing below I am confirming that my main doctor or other healthcare professional - or the main place I go to for routine medical care - is {formData.practiceName}.</h5></center>
            </Grid>
            <Grid container style={{ marginBottom: '13px' }}>
                    <Grid item xs={6} style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: '14px' }}>
                    <span style={{fontWeight: 500, paddingRight: '6px'}}>Facility Name:</span> {formData.practiceName}
                    </Grid>
                    <Grid item xs={6} style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: '14px' }}>
                    <span style={{fontWeight: 500}}>Provider Name: </span> {formData.providerName}
                    </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: '13px' }}>
                    <Grid item xs={6} style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: '14px' }}>
                    <span style={{fontWeight: 500}}>MBI Number:</span> {formData.mbinumber}
                    </Grid>
                    <Grid item xs={6} style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: '14px' }}>
                    <span style={{fontWeight: 500}}>Patient Name:</span> {formData.memberName}
                    </Grid>
                    </Grid>
                    <Grid container style={{ marginBottom: '13px' }}>
                    <Grid item xs={6} style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: '14px' }}>
                    <span style={{fontWeight: 500}}>Date:</span> {format(new Date(), "dd MMM yyyy")}
                    </Grid>
                    <Grid item xs={6} style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: '14px' }}>
                    <span style={{fontWeight: 500}}> E-Signature: </span> 
                        <img src={signatureImage}/> 
                        </Grid>
                </Grid>
        </Grid>
        {/* <h5>By signing below I am confirming that my main doctor or other healthcare professional - or the main place I go to for routine medical care - is Care Medical.</h5> */}
         <h5>Note: Completing and returning this form is voluntary. It won’t affect your Medicare benifits.</h5>

    </div>
)

export default PdfTemplate;