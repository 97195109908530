import React, { useEffect, useState, useCallback } from "react";
import { useAppDispatch } from "../../../app/store";
import { Link, useNavigate } from "react-router-dom";
// import { Box } from "@mui/material";
import {
  Avatar,
  Button,
  Chip,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Loader from "../../../component/common/loader/Loader";
import { useSelector } from "react-redux";
// import DataGridCustom from "../../component/common/datagrid/DataGridCustom";
import Fab from "@mui/material/Fab";
// import { Styles } from "../../Styles";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/EditOutlined";
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { axiosRequests } from "../../../app/api/AxiosRequest";
import DataGridCustom from "../../../component/common/datagrid/DataGridCustom";
import { getUsersList } from "./usersListSlice";
import { PagesPath } from "../../../PagesPath";
import DeleteConfirmation from "../DeleteConfirmation";
import UserDetailsModal from "./UserDetailsModal";
import DataGridHeader from "../../../component/common/datagrid/DataGridHeader";
import { COLOR, Styles } from "../../../Styles";
import { Search } from "@mui/icons-material";

const UsersTable = ({ setAddUserFormDisplay }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUserDetailsModalOpen, setIsUserDetailsModalOpen] = useState(false);
  const [selectedRowForModal, setSelectedRowForModal] = useState(null);
  const [isDeleted, setIsDeleted] = useState(false);
  const [userDetailsModalData, setUserDetailsModalData] = useState(null);
  const { isLoading, usersData } = useSelector((state) => state.usersData);
  const [filteredUsersData, setFilteredUsersData] = useState([]);
  const [searchText, setSearchText] = useState("");
  //var usersData = [];
  const getUsersListData = useCallback(async () => {
    const response = await dispatch(getUsersList());
  }, [dispatch]);

  const openModal = (row) => {
    setSelectedRowForModal(row);
    setIsModalOpen(true);
  };

  const openUserDetailsModal = (rowData) => {
    setUserDetailsModalData(rowData);
    setIsUserDetailsModalOpen(true);
  };

  function closeModal() {
    // setSelectedRow(row);
    setIsModalOpen(false);
  }

  function closeUserDetailsModal() {
    // setSelectedRow(row);
    setIsUserDetailsModalOpen(false);
  }

  const isRecordDeleted = () => {
    setIsDeleted(true);
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    getUsersListData();
  }, [getUsersListData]);

  useEffect(() => {
    getUsersListData();
    setIsDeleted(false);
  }, [isDeleted]);

  useEffect(() => {
    if (usersData) {
      let filtered;
      if (searchText) {
        filtered = usersData.filter((item) =>
          item.userName.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredUsersData(filtered);
      } else {
        setFilteredUsersData(usersData);
      }
    }
  }, [searchText, usersData]);

  const columns = [
    {
      field: "userId",
      headerName: "User ID",
      flex: 1,
      renderCell: (params) => {
        return (
          <Link
            key="AddUser"
            style={{
              textDecoration: "none",
              underline: "hover",
              color: "inherit",
              marginRight: "8px",
            }}
          >
            <div onClick={() => openUserDetailsModal(params.row)}>
              <u>{params.row.userId}</u>
            </div>
          </Link>
        );
      },
    },
    {
      field: "userName",
      headerName: "User Name",
      flex: 1,
    },
    {
      field: "userType",
      headerName: "User Type",
      flex: 1,
      renderCell: (params) => {
        if (params.row.userType === 1) {
          return <Typography>Internal</Typography>;
        } else {
          return <Typography>External</Typography>;
        }
      },
    },
    {
      field: "email",
      headerName: "Contact Email",
      flex: 2,

      //cellClassName: 'facilityNameField'
    },
    {
      field: "contactNumber",
      headerName: "Contact Number",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      align: "center",
      headerAlign: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <strong>
            <Link
              to={PagesPath.AddUserURL}
              state={{ formState: params.row }}
              key="AddUser"
              style={{
                textDecoration: "none",
                underline: "hover",
                color: "inherit",
                marginRight: "8px",
              }}
            >
              <EditIcon></EditIcon>
            </Link>
            <Link
              key="DeleteUser"
              style={{
                textDecoration: "none",
                underline: "hover",
                color: "inherit",
                marginRight: "8px",
              }}
            >
              <DeleteIcon onClick={() => openModal(params.row)}></DeleteIcon>
            </Link>
          </strong>
        );
      },
    },
  ];
  function getRowId(row) {
    return row.userId; // + "_" + row.profileId;
  }

  return (
    <div>
      <Stack direction="row" spacing={1}>
        <div style={{ marginLeft: "auto", float: "right" }}>
          <Link
            to={PagesPath.AddUserURL}
            key="AddUser"
            style={{
              textDecoration: "none",
              underline: "hover",
              color: "inherit",
            }}
          >
            <Button
              style={{
                textTransform: "none",
              }}
              variant="outlined"
              color="orangeTheme"
            >
              <AddIcon style={{ paddingTop: "5px", marginTop: "-4px" }} />
              Add User
            </Button>
          </Link>
        </div>
      </Stack>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <Grid item>
          <DataGridCustom
            columns={columns}
            rows={filteredUsersData}
            getRowId={getRowId}
            loading={isLoading}
            slots={{
              toolbar: DataGridHeader,
            }}
            slotProps={{
              toolbar: {
                headerText: "",
                fontSize: "",
                marginTop: "",
                marginBottom: "",
                height: "56px",
                children: (
                  <div
                    style={{
                      fontFamily: Styles.FONT_FAMILY_POPPINS,
                      fontSize: "16px",
                      width: "100%",
                      marginTop: "auto",
                      marginBottom: "auto",
                      marginLeft: "5px",
                    }}
                  >
                    <Stack direction="row" spacing={1}>
                      <Typography
                        style={{
                          fontFamily: Styles.FONT_FAMILY_POPPINS,
                          fontSize: "16px",
                          marginTop: "auto",
                          marginBottom: "auto",
                        }}
                      >
                        Total Users
                      </Typography>
                      <Chip
                        color="orangeTheme"
                        variant="contained"
                        style={{ color: "#fff" }}
                        label={usersData.length}
                      ></Chip>
                      <Stack
                        direction="row"
                        spacing={1}
                        style={{ marginLeft: "auto" }}
                      >
                        <TextField
                          InputProps={{
                            style: { height: "38px", alignItems: "center" },
                            endAdornment: (
                              <InputAdornment position="end">
                                <Link
                                  style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    display: "flex",
                                    //cursor: "pointer",
                                  }}
                                >
                                  <Search
                                    fontSize="small"
                                    sx={{
                                      color: COLOR.ORANGE,
                                      position: "inherit",
                                    }}
                                  />
                                </Link>
                              </InputAdornment>
                            ),
                          }}
                          label="Search Users"
                          id="outlined-size-small"
                          onChange={(e) => handleSearchChange(e)}
                          style={{
                            width: "293px",
                          }}
                          color="orangeTheme"
                          size="small"
                        />
                      </Stack>
                    </Stack>
                  </div>
                ),
              },
            }}
          />
        </Grid>
      )}
      <DeleteConfirmation
        isOpen={isModalOpen}
        onClose={closeModal}
        rowData={selectedRowForModal}
        modalFrom={"Users"}
        isDeleted={isRecordDeleted}
      ></DeleteConfirmation>
      <UserDetailsModal
        isOpen={isUserDetailsModalOpen}
        onClose={closeUserDetailsModal}
        rowData={userDetailsModalData}
      ></UserDetailsModal>
    </div>
  );
};

export default UsersTable;
