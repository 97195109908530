import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../../app/api/Agent";

const initialState = {
  isLoading: false,
  data: {},
  errorMessage: "",
};

export const getReportByGroupIdAndReportId = createAsyncThunk(
  "getReportByGroupIdAndReportId",
  async ({ groupId, reportId }) => {
    const response = await agent.PowerBiAgent.getReportByGroupIdAndReportId(
      groupId,
      reportId
    );
    return response;
  }
);

const powerBiReportSlice = createSlice({
  name: "powerBiReport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReportByGroupIdAndReportId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getReportByGroupIdAndReportId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.data = action.payload;
        state.errorMessage="";
      })
      .addCase(getReportByGroupIdAndReportId.rejected, (state, action) => {
        state.isLoading = false;
        state.data = {};
        state.errorMessage = action.error.message;
      });
  },
});

export default powerBiReportSlice.reducer;
