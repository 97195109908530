import { PagesPath } from "../PagesPath";
// import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
// import settings_active from '@mui/icons-material/Settings';
import calendar from "../assets/calendar.svg";
import calendar_active from "../assets/calendar_orange.svg";

export const EventJson = {
  id: 2,
  name: "Events",
  key: "Events",
  iconurl: calendar,
  activeiconurl: calendar_active,
  defaulturl: PagesPath.Events_URL,
};
