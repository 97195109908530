import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import cinq_1 from "../../assets/cinq_1.png";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Styles } from "../../Styles";
import { useEffect, useState } from "react";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { format } from "date-fns";
import { axiosRequests } from "../../app/api/AxiosRequest";
import VisitDateField from "./VisitDateField";
import SearchCodes from "./SearchCodes";
const MedicalCodingSuspectsStateUpdate = ({
  isOpen,
  onClose,
  title,
  rowId,
  ourId,
  forceUpdate,
  setForceUpdate,
  rows = [],
  memberDetail,
  payerName,
}) => {
  const [
    selectedMedicalCodingSuspectState,
    setSelectedMedicalCodingSuspectState,
  ] = useState("");
  const [isDisclaimerChecked, setIsDisclaimerChecked] = useState(false);
  const [showUploadFields, setShowUploadFields] = useState(false);
  const [showCommentsSection, setShowCommentsSection] = useState(false);
  const [denyComments, setDenyComments] = useState("");
  const [reportsFiles, setReportsFiles] = useState([]);
  const [claimsFiles, setClaimsFiles] = useState([]);
  const [reportsFilesError, setReportsFilesError] = useState("");
  const [claimsFilesError, setClaimsFilesError] = useState("");
  const [disclaimerError, setDisclaimerError] = useState("");
  const [commentsError, setCommentsError] = useState("");
  const [selectedValueError, setSelectedValueError] = useState("");
  const [visitDate, setVisitDate] = useState(new Date());
  const [visitDateError, setVisitDateError] = useState("");
  const maxDocumentText = "Only one document upload allowed.";
  const [icd10Codes, setIcd10Codes] = useState([]);
  const [icd10CodesError, setIcd10CodesError] = useState("");

  useEffect(() => {
    setIcd10CodesError("");
  }, [icd10Codes]);

  const handleMedicalCodingSuspectStateChange = (e) => {
    setSelectedMedicalCodingSuspectState(e.target.value);
    if (e.target.value && e.target.value.toLowerCase() === "accept") {
      setShowUploadFields(true);
    } else {
      setShowUploadFields(false);
    }
    if (e.target.value && e.target.value.toLowerCase() === "deny") {
      setShowCommentsSection(true);
    } else {
      setShowCommentsSection(false);
    }
    setSelectedValueError("");
    setIsDisclaimerChecked(false);
  };
  const handleDisclaimerCheckChange = (e) => {
    setIsDisclaimerChecked(e.target.checked);
    setDisclaimerError("");
  };

  const handleVisitDateChange = (e) => {
    setVisitDate(e);
    setVisitDateError("");
  };

  const handleDenyCommentsChange = (e) => {
    setDenyComments(e.target.value);
    setCommentsError("");
  };
  const handleReportsFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setReportsFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setReportsFilesError("");
  };
  const handleClaimsFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    setClaimsFiles((prevFiles) => [...prevFiles, ...newFiles]);
    setClaimsFilesError("");
  };

  const handleReportsFileDelete = (fileToDelete) => {
    const input = document.getElementById("reports-medical-file-input");
    document.getElementById("reports-medical-file-input").value = "";
    setReportsFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToDelete)
    );
  };

  const handleClaimsFileDelete = (fileToDelete) => {
    const input = document.getElementById("claims-medical-file-input");
    document.getElementById("claims-medical-file-input").value = "";
    setClaimsFiles((prevFiles) =>
      prevFiles.filter((file) => file !== fileToDelete)
    );
  };
  const handleSave = () => {
    let hasError = false;
    if (
      !selectedMedicalCodingSuspectState ||
      (selectedMedicalCodingSuspectState &&
        selectedMedicalCodingSuspectState === "")
    ) {
      setSelectedValueError("Current state is required");
      hasError = true;
    }
    if (!isDisclaimerChecked && selectedMedicalCodingSuspectState !== "") {
      setDisclaimerError("Please accept this checkbox before proceeding");
      hasError = true;
    }
    if (
      (!reportsFiles || reportsFiles.length <= 0) &&
      selectedMedicalCodingSuspectState.toLowerCase() === "accept"
    ) {
      setReportsFilesError("Please upload atleast one supporting document");
      hasError = true;
    }
    if (
      selectedMedicalCodingSuspectState &&
      selectedMedicalCodingSuspectState.toLowerCase() === "deny" &&
      denyComments === ""
    ) {
      setCommentsError("Comments are required in case of deny");
      hasError = true;
    }
    if (visitDate && visitDate > new Date()) {
      setVisitDateError("Invalid Visit Date");
      hasError = true;
    }
    if (hasError) return;
    if (selectedMedicalCodingSuspectState.toLowerCase() === "deny") {
      var formDataDeny = new FormData();
      let rowIDsToUpdate = [];
      if (rowId) rowIDsToUpdate.push(rowId);
      else if (rows && rows.length > 0) {
        let rowIDArray = rows.map((item) => item.id);
        rowIDsToUpdate.push(...rowIDArray);
      }
      rowIDsToUpdate.forEach((id) => {
        formDataDeny.append("medicalCodingSuspectIds", id);
      });
      formDataDeny.append("ourId", ourId);
      formDataDeny.append("visitDate", format(visitDate, "yyyy/MM/dd"));
      formDataDeny.append("status", "Reject");
      formDataDeny.append("comments", denyComments);
      formDataDeny.append("Icd10Codes", icd10Codes.join(","));
      formDataDeny.append("PayorName", payerName);
      axiosRequests
        .post("ActiveGapClosure/RejectStatusMedicalCodingSuspect", formDataDeny)
        .then((resp) => {
          if (resp) {
            setForceUpdate(forceUpdate + 1);
            onClose();
          }
        });
    } else {
      let rowIDsToUpdate = [];
      if (rowId) rowIDsToUpdate.push(rowId);
      else if (rows && rows.length > 0) {
        let rowIDArray = rows.map((item) => item.id);
        rowIDsToUpdate.push(...rowIDArray);
      }
      var formData = new FormData();
      rowIDsToUpdate.forEach((id) => {
        formData.append("medicalCodingSuspectIds", id);
      });

      formData.append("ourId", ourId);
      formData.append("visitDate", format(visitDate, "yyyy/MM/dd"));
      formData.append("isDisclaimerSubmitted", isDisclaimerChecked);
      reportsFiles.forEach((file) => {
        formData.append("evidenceFiles", file);
      });
      claimsFiles.forEach((file) => {
        formData.append("claimsFiles", file);
      });
      formData.append("comments", "");
      axiosRequests
        .postUpload("ActiveGapClosure/BulkUploadMedicalCodingFiles", formData)
        .then((resp) => {
          if (resp) {
            setForceUpdate(forceUpdate + 1);
            onClose();
          }
        });
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      slotProps={{ backdrop: { onClick: null } }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "678px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          pt: 1,
          height: "auto",
        }}
      >
        <h3
          style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
        >
          Recapture Codes State Change{" "}
          <span>
            <CloseIcon onClick={onClose} style={{ float: "right" }} />
          </span>
        </h3>
        <hr
          style={{
            width: "auto",
            opacity: "0.3",
            marginTop: "-8px",
            // marginBottom: "-4px",
          }}
        />
        <Stack direction="column" spacing={2}>
          <img
            style={{
              width: "129px",
              height: "29px",
              margin: "0 auto",
              display: "block",
            }}
            src={cinq_1}
          ></img>
          {!rows ||
            (rows.length < 2 && (
              <Typography>
                Are you sure you want to change the state for recapture code - “
                {title ? title : rows[0].measureTitle}”?
              </Typography>
            ))}
          {rows && rows.length > 1 && (
            <>
              <Typography>
                Are you sure you want to change the states for below recapture
                codes?
              </Typography>
              <List
                style={{
                  maxHeight: "60px",
                  overflowY: "scroll",
                  marginTop: "3px",
                }}
              >
                {rows.map((row, index) => (
                  <ListItem key={row.id}>
                    <ListItemText
                      style={{ marginTop: index === 0 ? "-20px" : "-20px" }}
                    >
                      <b>{row.icD10}</b>
                    </ListItemText>
                  </ListItem>
                ))}
              </List>
            </>
          )}
          <Stack
            direction="row"
            spacing={2}
            style={{
              width: "99%",
              marginTop: rows && rows.length > 0 ? "12px" : "",
            }}
          >
            <FormControl
              required
              color="orangeTheme"
              sx={{ width: "inherit" }}
              size="small"
              error={!!selectedValueError}
            >
              <InputLabel id="facility-name-select-small-label">
                Recapture Code State
              </InputLabel>

              <Select
                required
                value={selectedMedicalCodingSuspectState}
                onChange={(e) => handleMedicalCodingSuspectStateChange(e)}
                labelId="facility-name-select-small-label"
                id="facility-name-select-small"
                label="Recapture Code State"
              >
                <MenuItem value="Accept">Accept</MenuItem>
                <MenuItem value="Deny">Reject</MenuItem>
              </Select>
              {selectedValueError && (
                <FormHelperText>{selectedValueError}</FormHelperText>
              )}
            </FormControl>
            <VisitDateField
              error={!!visitDateError}
              errorHelperText={visitDateError}
              visitDate={visitDate}
              handleVisitDateChange={handleVisitDateChange}
            />
          </Stack>
          {showUploadFields && (
            <Stack direction="column" spacing={2}>
              {reportsFilesError && (
                <FormHelperText style={{ color: "#DC4f3b" }}>
                  <b>{reportsFilesError}</b>
                </FormHelperText>
              )}
              {claimsFilesError && (
                <FormHelperText style={{ color: "#DC4f3b" }}>
                  <b>{claimsFilesError}</b>
                </FormHelperText>
              )}
              <Stack
                direction="column"
                spacing={1}
                style={{ maxHeight: "200px" }}
              >
                <Typography>
                  <b> Upload Supporting Documents</b>
                </Typography>
                <input
                  type="file"
                  onChange={handleReportsFileChange}
                  style={{ display: "none" }}
                  id="reports-medical-file-input"
                />
                {(!reportsFiles || reportsFiles.length === 0) && (
                  <>
                    <Button
                      color="orangeTheme"
                      sx={{ textTransform: "none", width: "190px" }}
                      variant="outlined"
                      onClick={() =>
                        document
                          .getElementById("reports-medical-file-input")
                          .click()
                      }
                    >
                      <FileUploadOutlinedIcon
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                        color="orangeTheme"
                      ></FileUploadOutlinedIcon>
                      Upload Document
                    </Button>
                    <FormHelperText>{maxDocumentText}</FormHelperText>
                  </>
                )}
                <Stack direction="column" spacing={1}>
                  {reportsFiles.map((file, index) => (
                    <ListItem
                      key={index}
                      className="fileList"
                      secondaryAction={
                        <IconButton
                          edge="end"
                          style={{ marginTop: "-8px" }}
                          onClick={() => handleReportsFileDelete(file)}
                        >
                          <DeleteOutlineOutlinedIcon></DeleteOutlineOutlinedIcon>
                        </IconButton>
                      }
                    >
                      <Tooltip title={file.name}>
                        <ListItemText
                          style={{
                            marginTop: "auto",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          primary={file.name}
                        ></ListItemText>
                      </Tooltip>
                    </ListItem>
                  ))}
                </Stack>
                <Typography>
                  <b> Upload Claims</b>
                </Typography>
                <input
                  type="file"
                  onChange={handleClaimsFileChange}
                  style={{ display: "none" }}
                  id="claims-medical-file-input"
                />
                {(!claimsFiles || claimsFiles.length === 0) && (
                  <>
                    <Button
                      color="orangeTheme"
                      sx={{ textTransform: "none", width: "190px" }}
                      variant="outlined"
                      onClick={() =>
                        document
                          .getElementById("claims-medical-file-input")
                          .click()
                      }
                    >
                      <FileUploadOutlinedIcon
                        style={{ marginTop: "auto", marginBottom: "auto" }}
                        color="orangeTheme"
                      ></FileUploadOutlinedIcon>
                      Upload Document
                    </Button>
                    <FormHelperText>{maxDocumentText}</FormHelperText>
                  </>
                )}
                <Stack direction="column" spacing={1}>
                  {claimsFiles.map((file, index) => (
                    <ListItem
                      key={index}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          style={{ marginTop: "-8px" }}
                          onClick={() => handleClaimsFileDelete(file)}
                        >
                          <DeleteOutlineOutlinedIcon></DeleteOutlineOutlinedIcon>
                        </IconButton>
                      }
                    >
                      <Tooltip title={file.name}>
                        <ListItemText
                          style={{
                            marginTop: "-16px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                          primary={file.name}
                        ></ListItemText>
                      </Tooltip>
                    </ListItem>
                  ))}
                </Stack>
              </Stack>
              <Typography>
                <b>Date:</b> {format(new Date(), "dd MMM yyyy")}
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={isDisclaimerChecked}
                      color="orangeTheme"
                      onChange={(e) => handleDisclaimerCheckChange(e)}
                    />
                  }
                  label="By clicking here, I declare that the documents added
            are true and accurate to the best of my knowledge, information and
            belief."
                />
              </FormGroup>
              {disclaimerError && (
                <FormHelperText style={{ color: "#DC4f3b" }}>
                  <b>{disclaimerError}</b>
                </FormHelperText>
              )}
            </Stack>
          )}
          {showCommentsSection && (
            <Stack direction="column" spacing={2}>
              <TextField
                style={{
                  width: "auto !important",
                }}
                id="outlined-multiline-static"
                label="Comments For Rejection"
                value={denyComments}
                error={!!commentsError}
                helperText={commentsError}
                onChange={(e) => handleDenyCommentsChange(e)}
                multiline
                className="denyComments"
                color="orangeTheme"
                rows={3}
                variant="outlined"
              />
              <SearchCodes
                memberDetail={memberDetail}
                payerName={payerName}
                setCodesArray={setIcd10Codes}
                source={"recaptureGrid"}
              />
              <Typography>
                <b>Date:</b> {format(new Date(), "dd MMM yyyy")}
              </Typography>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={isDisclaimerChecked}
                      color="orangeTheme"
                      onChange={(e) => handleDisclaimerCheckChange(e)}
                    />
                  }
                  label="By clicking here, I declare that the documents added
            are true and accurate to the best of my knowledge, information and
            belief."
                />
              </FormGroup>
              {disclaimerError && (
                <FormHelperText style={{ color: "#DC4f3b" }}>
                  <b>{disclaimerError}</b>
                </FormHelperText>
              )}
            </Stack>
          )}
          <hr
            style={{
              width: "auto",
              opacity: "0.3",
            }}
          />
          <Stack direction="row" spacing={2} style={{ marginLeft: "auto" }}>
            <Button
              style={{
                textTransform: "none",
                borderColor: "black",
                color: "black",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
              }}
              variant="outlined"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              style={{
                textTransform: "none",
                color: "white",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
              }}
              onClick={handleSave}
              variant="contained"
              color="orangeTheme"
              disableElevation
            >
              Save
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default MedicalCodingSuspectsStateUpdate;
