import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddUserFormHeader from "../Users/AddUserFormHeader";
import { axiosRequests } from "../../../app/api/AxiosRequest";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { PagesPath } from "../../../PagesPath";
import Loader from "../../../component/common/loader/Loader";

const ViewRoleMapping = () => {
  let { state } = useLocation();
  const [roleName, setRoleName] = useState("");
  const [payerName, setPayerName] = useState("");
  const [productName, setProductName] = useState("");
  const [marketName, setMarketName] = useState("");
  const [regionName, setRegionName] = useState("");
  const [toolsScoreCardData, setToolsScorecardData] = useState([]);
  const [practiceName, setPracticeName] = useState("");
  const [expanded, setExpanded] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingRoleName, setIsLoadingRoleName] = useState(true);
  const [isLoadingPayerName, setIsLoadingPayerName] = useState(true);
  const [isLoadingProductName, setIsLoadingProductName] = useState(true);
  const [isLoadingMarketName, setIsLoadingMarketName] = useState(true);
  const [isLoadingRegionName, setIsLoadingRegionName] = useState(true);
  const [isLoadingPracticeName, setIsLoadingPracticeName] = useState(true);
  const [isLoadingToolScorecards, setIsLoadingToolsScorecards] = useState(true);

  const [toolsViewAccess, setToolsViewAccess] = useState([]);
  const [toolsEditAccess, setToolsEditAccess] = useState([]);
  const [scorecardViewAccess, setScorecardViewAccess] = useState([]);

  const navigate = useNavigate();
  const [payerIDs, setPayerIDs] = useState(state.value[0].payerId);
  const [productIDs, setProductIDs] = useState(state.value[0].productId);
  const [marketIDs, setMarketIDs] = useState(state.value[0].marketId);
  const [regionIDs, setRegionIDs] = useState(state.value[0].regionId);

  useEffect(() => {
    axiosRequests.get("/RoleMapping/GetRoleMappingList").then((resp) => {
      var roleName = resp.find(
        (item) => item.roleId === state.value[0].roleID
      ).roleName;
      setRoleName(roleName);
      setIsLoadingRoleName(false);
    });
  }, [state.value]);

  useEffect(() => {
    axiosRequests.get("/RoleMapping/GetPayerMappingList").then((resp) => {
      //var productName = resp.find(item => item.roleId === state.value[0].productId).roleName;
      var payerIds = state.value[0].payerId.split(",");
      const payerNames = payerIds.map((payerId) => {
        const payer = resp.find((r) => r.payerId === parseInt(payerId));
        return payer ? payer.payerName : payerId;
      });
      setPayerName(payerNames.join(", "));
      setIsLoadingPayerName(false);
    });
  }, [state.value]);

  useEffect(() => {
    axiosRequests
      .get(
        `/RoleMapping/GetProductMappingList?PayerId=${state.value[0].payerId.split(
          ","
        )}`
      )
      .then((resp) => {
        const productIDList = state.value[0].productId
          .split(",")
          .map((id) => parseInt(id));

        const products = resp.filter((product) =>
          productIDList.includes(product.productId)
        );

        const productNames = products.map((product) => product.productName);
        setProductName(productNames.join(", "));
        setIsLoadingProductName(false);
      });
  }, [state.value]);

  useEffect(() => {
    axiosRequests
      .get(
        `/RoleMapping/GetMarketMappingList?PayerId=${state.value[0].payerId.split(
          ","
        )}&ProductId=${state.value[0].productId.split(",")}`
      )
      .then((resp) => {
        const marketIDList = state.value[0].marketId
          .split(",")
          .map((id) => parseInt(id));

        const markets = resp.filter((market) =>
          marketIDList.includes(market.marketId)
        );

        const marketNames = markets.map((market) => market.marketName);
        setMarketName(marketNames.join(", "));
        setIsLoadingMarketName(false);
      });
  }, [state.value]);

  useEffect(() => {
    axiosRequests
      .get(
        `/RoleMapping/GetSubMarketMappingList?PayerId=${state.value[0].payerId.split(
          ","
        )}&ProductId=${state.value[0].productId.split(",")}&MarketId=${
          state.value[0].marketId
        }`
      )
      .then((resp) => {
        const regionIDList = state.value[0].regionId
          .split(",")
          .map((id) => parseInt(id));
        const regions = resp.filter((region) =>
          regionIDList.includes(region.subMarketId)
        );
        const regionNames = regions.map((region) => region.subMarketName);
        setRegionName(regionNames.join(", "));
        setIsLoadingRegionName(false);
      });
  }, [state.value]);

  useEffect(() => {
    axiosRequests
      .get("/RoleMapping/GetToolsandScorecardMapping")
      .then((resp) => {
        setToolsScorecardData(resp);

        setToolsViewAccess(
          state.value[0].viewTools.split(",").map(function (x) {
            return parseInt(x, 10);
          })
        );
        setToolsEditAccess(
          state.value[0].editTools.split(",").map(function (x) {
            return parseInt(x, 10);
          })
        );
        setScorecardViewAccess(
          state.value[0].viewScorecards.split(",").map(function (x) {
            return parseInt(x, 10);
          })
        );
        setIsLoadingToolsScorecards(false);
        //setRoleNameDropdownData(resp);
        //setIsLoadingRoleName(false);
      });
  }, [state.value]);

  useEffect(() => {
    axiosRequests
      .get(
        `/RoleMapping/GetPracticeMappingList?PayerId=${payerIDs}&ProductId=${productIDs}&marketId=${marketIDs}&regionId=${regionIDs}`
      )
      .then((resp) => {
        let practiceIDs = state.value[0].practiceId.split(",");

        const practiceNames = practiceIDs.map((practiceId) => {
          const practice = resp.find(
            (r) => r.practiceId === parseInt(practiceId)
          );
          return practice ? practice.practiceName : practiceId;
        });
        setPracticeName(practiceNames.join(", "));
        setIsLoadingPracticeName(false);
      });
  }, [state.value, marketIDs, regionIDs]);

  useEffect(() => {
    if (
      !isLoadingRoleName &&
      !isLoadingPayerName &&
      !isLoadingProductName &&
      !isLoadingMarketName &&
      !isLoadingRegionName &&
      !isLoadingToolScorecards &&
      !isLoadingPracticeName
    )
      setIsLoading(false);
  }, [
    isLoadingRoleName,
    isLoadingPayerName,
    isLoadingProductName,
    isLoadingMarketName,
    isLoadingRegionName,
    isLoadingToolScorecards,
    isLoadingPracticeName,
  ]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : null);
  };

  const handleBackClick = () => {
    navigate(PagesPath.UserAdminConsoleURL, { state: { value: "3" } });
  };

  return (
    <div>
      {isLoading ? (
        <Loader isLoading={isLoading} />
      ) : (
        <Box sx={{ width: "100%", p: { xs: 2, sm: 2 } }}>
          <AddUserFormHeader pageTitle={"Role Mapping"}></AddUserFormHeader>
          <Stack direction="row" spacing={1}>
            <ArrowBackOutlinedIcon
              onClick={() => handleBackClick()}
              style={{
                fontSize: "25px",
                fontWeight: "600",
                marginTop: "2px",
                width: "32px",
              }}
            />
            <Typography style={{ fontSize: "20px", fontWeight: "600" }}>
              View Role Mappings
            </Typography>
          </Stack>
          <div
            style={{
              width: "1270px",
              height: "auto",
              background: "rgba(226, 226, 226, 0.30)",
              //backgroundColor: "#E2E2E2",
              padding: "16px",
            }}
          >
            <Stack direction="column" spacing={2}>
              <Typography>
                <b>Role Name:</b> {roleName}
              </Typography>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "rgba(17, 24, 39, 0.72)",
                }}
              >
                Payer, Product, Market, Region Mapping
              </Typography>
              <Typography>
                <b>Payer Names:</b> {payerName}
              </Typography>
              <Typography>
                <b>Product Names:</b> {productName}
              </Typography>
              <Typography>
                <b>Market Names:</b> {marketName}
              </Typography>
              <Typography>
                <b>Region Names:</b> {regionName}
              </Typography>
              <Typography>
                <b>Practice Names:</b> {practiceName}
              </Typography>
              <Typography
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "rgba(17, 24, 39, 0.72)",
                }}
              >
                Seven Pillars
              </Typography>
              {toolsScoreCardData.map(
                (pillar, index) =>
                  pillar.key && (
                    <Accordion
                      key={index}
                      expanded={expanded === `panel-${index}`}
                      onChange={handleAccordionChange(`panel-${index}`)}
                    >
                      <AccordionSummary
                        aria-controls={`panel-${index}-content`}
                        id={`panel-${index}-header`}
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography style={{ fontWeight: 500 }}>
                          {pillar.name}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div>
                          <Typography>Tools Access Metric: </Typography>
                          <TableContainer component={Paper}>
                            <Table>
                              <TableHead
                                style={{ background: "rgba(17, 24, 39, 0.07)" }}
                              >
                                <TableRow>
                                  <TableCell>Tool Name</TableCell>
                                  <TableCell>View Mode</TableCell>
                                  <TableCell>Update Mode</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {pillar.tools.map(
                                  (tool, i) =>
                                    tool.key && (
                                      <TableRow key={i}>
                                        <TableCell>{tool.name}</TableCell>
                                        <TableCell>
                                          {toolsViewAccess.indexOf(tool.id) >
                                          -1 ? (
                                            <DoneOutlinedIcon />
                                          ) : (
                                            <Typography
                                              style={{
                                                color: "rgba(17, 24, 39, 0.72)",
                                              }}
                                            >
                                              NA
                                            </Typography>
                                          )}
                                        </TableCell>
                                        <TableCell>
                                          {toolsEditAccess.indexOf(tool.id) >
                                          -1 ? (
                                            <DoneOutlinedIcon />
                                          ) : (
                                            <Typography
                                              style={{
                                                color: "rgba(17, 24, 39, 0.72)",
                                              }}
                                            >
                                              NA
                                            </Typography>
                                          )}
                                        </TableCell>
                                      </TableRow>
                                    )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                          <br />
                          <Typography>Scorecards Access Metric: </Typography>
                          <TableContainer component={Paper}>
                            <Table>
                              <TableHead
                                style={{ background: "rgba(17, 24, 39, 0.07)" }}
                              >
                                <TableRow>
                                  <TableCell>Scorecard Name</TableCell>
                                  <TableCell>View Mode</TableCell>
                                  {/* <TableCell>Update Mode</TableCell> */}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {pillar.scorecards.map(
                                  (scorecard, i) =>
                                    scorecard.key && (
                                      <TableRow key={i}>
                                        <TableCell>{scorecard.name}</TableCell>
                                        <TableCell>
                                          {scorecardViewAccess.indexOf(
                                            scorecard.id
                                          ) > -1 ? (
                                            <DoneOutlinedIcon />
                                          ) : (
                                            <Typography
                                              style={{
                                                color: "rgba(17, 24, 39, 0.72)",
                                              }}
                                            >
                                              NA
                                            </Typography>
                                          )}
                                        </TableCell>
                                        {/* <TableCell>
                                <Switch color="orangeTheme" name={`scorecard-update-${i}`}></Switch>
                              </TableCell> */}
                                      </TableRow>
                                    )
                                )}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  )
              )}
            </Stack>
          </div>
        </Box>
      )}
    </div>
  );
};

export default ViewRoleMapping;
