import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Modal,
  Stack,
} from "@mui/material";
import { Styles } from "../../../Styles";
import { useState } from "react";
import { saveAs } from "file-saver";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { addDays, format } from "date-fns";
import agent from "../../../app/api/Agent";

const SDOHExcelExportTimelineModal = ({
  isOpen,
  onClose,
  setCustomModalCancel,
  formFilterData
}) => {
  const [customStartDate, setCustomStartDate] = useState(null);
  const [customEndDate, setCustomEndDate] = useState(null);
  const [customStartDateError, setCustomStartDateError] = useState("");
  const [customEndDateError, setCustomEndDateError] = useState("");
  const [open, setOpen] = useState(false);

  const handleCustomStartDateChange = (e) => {
    setCustomStartDate(e);
    setCustomStartDateError("");
  };

  const handleCustomEndDateChange = (e) => {
    setCustomEndDate(e);
    setCustomEndDateError("");
  };

  //Change Functions End Here

  //Apply Function Starts Here
  const handleApplyFilter = () => {
    var hasError = false;
      if (!customStartDate || customStartDate === "") {
        setCustomStartDateError("Start Date is Required");
        hasError = true;
      }
      if (!customEndDate || customEndDate === "") {
        setCustomEndDateError("End Date is Required");
        hasError = true;
      }
      if (
        customStartDate &&
        customStartDate < addDays(new Date(1753, 0, 0), 1)
      ) {
        setCustomStartDateError("Invalid Start Date");
        hasError = true;
      }
      if (customStartDate && customEndDate && customEndDate < customStartDate) {
        setCustomEndDateError("Invalid End Date");
        hasError = true;
      }
      if (hasError) return;

      download();
      setCustomModalCancel(false);
      setCustomStartDate(null);
      setCustomEndDate(null);
      onClose();
    
  };

  const closeTimelineModal = () => {
    setCustomStartDate(null);
    setCustomEndDate(null);
    setCustomModalCancel(true);
    onClose();
  };

  const download = () => {
    const formData = {
      Market: formFilterData.marketId.join(","),
      SubMarket: formFilterData.subMarketId.join(","),
      Practice: formFilterData.practiceId.join(","),
      StartDate: format(customStartDate, "yyyy-MM-dd"),
      EndDate:format(customEndDate, "yyyy-MM-dd")
  };

    agent.SDOHAssessmentTrackingAgent.exportSDOHMembersAssessmentDetails(formData)
      .then((response) => {
        let filename = "";
        let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(
          response.headers["content-disposition"]
        );
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
        let url = window.URL.createObjectURL(new Blob([response.data]));
        saveAs(url, filename);
        setOpen(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Apply FUnction Ends Here
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      slotProps={{
        backdrop: { onClick: null },
        // root: { height: "auto", overflowY: "visible" },
      }}
      sx={{ height: "auto", overflowY: "auto" }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "25%",
          left: "50%",
          transform: "translate(-50%,-50%)",
          width: "600px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          pt: 1,
          height: "auto",
          // overflowY: "auto",
        }}
      >
        <h3
          style={{ fontFamily: Styles.FONT_FAMILY_POPPINS, fontSize: "16px" }}
        >
          Export Timeline
          <span>
            <Close onClick={closeTimelineModal} style={{ float: "right" }} />
          </span>
        </h3>
        <hr
          style={{
            width: "530px",
            opacity: "0.3",
            marginTop: "-8px",
            // marginBottom: "-4px",
          }}
        />
        <Stack direction="column" sx={{ marginTop: "23px" }} spacing={2}>
        <Stack direction="row" spacing={2}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormControl
                  className="practiceNameClass"
                  required
                  color="orangeTheme"
                  sx={{
                    maxWidth: "180px",
                    minWidth: "160px",
                    backgroundColor: "#fff",
                    borderRadius: "6px",
                  }}
                  size="small"
                >
                  <DatePicker
                    label="Start Date"
                    value={customStartDate}
                    color="orangeTheme"
                    slotProps={{
                      textField: {
                        className: "dateFieldClass",
                        color: "orangeTheme",
                        error: !!customStartDateError,
                      },
                    }}
                    minDate={addDays(new Date(1753, 0, 0), 1)}
                    onChange={handleCustomStartDateChange}
                  />
                  <FormHelperText style={{ height: "0px" }}>
                    {customStartDateError}
                  </FormHelperText>
                </FormControl>
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <FormControl
                  className="practiceNameClass"
                  required
                  color="orangeTheme"
                  sx={{
                    maxWidth: "180px",
                    minWidth: "160px",
                    backgroundColor: "#fff",
                    borderRadius: "6px",
                  }}
                  size="small"
                >
                  <DatePicker
                    label="End Date"
                    value={customEndDate}
                    disabled={!customStartDate ? true : false}
                    minDate={customStartDate ? customStartDate : null}
                    color="orangeTheme"
                    slotProps={{
                      textField: {
                        className: "dateFieldClass",
                        color: "orangeTheme",
                        error: !!customEndDateError,
                      },
                    }}
                    onChange={handleCustomEndDateChange}
                  />
                  <FormHelperText style={{ height: "0px" }}>
                    {customEndDateError}
                  </FormHelperText>
                </FormControl>
              </LocalizationProvider>
            </Stack>
          
          <hr
            style={{
              width: "530px",
              opacity: "0.3",
              marginTop: "10px",
              // marginBottom: "-4px",
            }}
          />
          <Stack direction="row" spacing={2} style={{ marginLeft: "auto" }}>
            <Button
              style={{
                textTransform: "none",
                borderColor: "black",
                color: "black",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
              }}
              variant="outlined"
              onClick={closeTimelineModal}
            >
              Cancel
            </Button>
            <Button
              style={{
                textTransform: "none",
                color: "white",
                fontFamily: Styles.FONT_FAMILY_POPPINS,
              }}
              variant="contained"
              color="orangeTheme"
              disableElevation
              onClick={handleApplyFilter}
            >
              Export
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Modal>
  );
};

export default SDOHExcelExportTimelineModal;
