import React, { useState, useEffect } from "react";
import {
    Box,
    Grid,
    Stack,
    Typography,
    Button,
    TextField,
    FormControl, FormHelperText, CircularProgress,
} from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../app/store";
import { alpha, styled } from '@mui/material/styles';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { addDays, addMonths, addWeeks, format, subDays } from "date-fns";
import { COLOR, Styles } from "../../../Styles";
import {
    getSelectedRows, getCohortDetails
} from "../../../component/common/sdohAssessmentFilter/SDOHAssessmentFilterDetailsSlice";
import { axiosRequests } from "../../../app/api/AxiosRequest";
const EditCohortDetailsPage_Step2 = (props) => {
    const dispatch = useAppDispatch();
    const [podName, setPodName] = useState("");
    const [podNameError, setPodNameError] = useState("");
    const [isPodNameEmpty, setIsPodNameEmpty] = useState();
    const [customStartDate, setCustomStartDate] = useState(null);
    const [customStartDateError, setCustomStartDateError] = useState("");
    const [isStartDateEmpty, setIsStartDateEmpty] = useState(false);

    const [cohortName, setCohortName] = useState("");
    const [cohortNameError, setCohortNameError] = useState("");
    const [isCohortNameEmpty, setIsCohortNameEmpty] = useState();

    const [description, setDescription] = useState("");
    const [descriptionError, setDescriptionError] = useState("");
    const [cohortOwnerId, setCohortOwnerId] = useState("");
    const [cohortOwner, setCohortOwner] = useState("");
    const [cohortOwnerError, setCohortOwnerError] = useState("");
    const [isCohortOwnerEmpty, setIsCohortOwnerEmpty] = useState();
    const [ownerNameDropdownData, setOwnerNameDropdownData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { selectedRows } = useSelector(
        (state) => state.filterDetails
    );

    const { cohortDetails, cohortFilterDetails } = useSelector(
        (state) => state.filterDetails
    );

    useEffect(() => {
        if (cohortFilterDetails?.cohortDetails != undefined
            && cohortFilterDetails?.cohortDetails.cohortName != undefined
        ) {
            let fill_date = new Date(cohortFilterDetails?.cohortDetails.customStartDate);
            setPodName(cohortFilterDetails?.cohortDetails.podName);
            setCohortName(cohortFilterDetails?.cohortDetails.cohortName);
            setDescription(cohortFilterDetails?.cohortDetails.description);
            setCohortOwner(cohortFilterDetails?.cohortDetails.cohortOwner);
            setCohortOwnerId(cohortFilterDetails?.cohortDetails.cohortOwnerId);
            setCustomStartDate(fill_date);
        }
    }, [cohortFilterDetails])

    useEffect(() => {
        setIsCohortNameEmpty(false);
        setIsStartDateEmpty(false);
        setIsCohortOwnerEmpty(false);
        setIsPodNameEmpty(false);

        let emailId = localStorage.getItem("emailAddress")///"saravanakumar.k@cinq.care"
        setIsLoading(true);
        // ?EmailId=${emailId}
        axiosRequests.get(`/cohort/GetCohortOwnerDetails`).then(response => response)
            .then(data => {
                setOwnerNameDropdownData(data);
                setIsLoading(false);
                // setIsDropdownDataSet(true);
            })
    }, [props.step])

    const handlePodNameChange = (e) => {
        setPodName(e.target.value);
        setPodNameError("");
        let pod = e.target.value
        if (pod != "") {
            setIsPodNameEmpty(false);
        }
    };

    const handleCustomStartDateChange = (e) => {
        setCustomStartDate(e);
        setCustomStartDateError("");
        let startDate = e;
        if (startDate != "") {
            setIsStartDateEmpty(false);
        }
    };

    const handleCohortNameChange = (e) => {
        setCohortName(e.target.value);
        setCohortNameError("");
        let cohort = e.target.value
        if (cohort != "") {
            setIsCohortNameEmpty(false);
        }
    };


    const handleOwnerNameHighlight = () => {
        if (cohortOwner) {
            let ele = document.getElementsByClassName('ownerNameDropdown')[0];
            ele.classList.remove('Mui-focused');
            ele = document.getElementById('owner-name-select-small-label');
            ele.classList.remove('Mui-focused');
        }
    }

    const handleOwnerNameChange = (e) => {
        const ele = document.getElementsByClassName('ownerNameDropdown')[0];
        ele.classList.remove('Mui-focused');
        setCohortOwner(e.target.value);
        setCohortOwnerError("");
        let cohortOwner = e.target.value
        if (cohortOwner != "") {
            setIsCohortOwnerEmpty(false);
        }
    }

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
        setDescriptionError("");
    };


    const handleCohortOwnerChange = (e) => {
        setCohortOwner(e.target.value);
        setCohortOwnerError("");
        let cohortOwner = e.target.value
        if (cohortOwner != "") {
            setIsCohortOwnerEmpty(false);
        }
    };

    const handleBack = (e) => {
        let hasError = false;
        props.handleBackCall();
    }

    const handleNext = (e) => {
        let hasError = false;
        if (podName == undefined || podName == "") {
            hasError = true;
            setIsPodNameEmpty(true);
        }
        if (cohortName == undefined || cohortName == "") {
            hasError = true;
            setIsCohortNameEmpty(true);
        }

        if (cohortOwner == undefined || cohortOwner == "") {
            hasError = true;
            setIsCohortOwnerEmpty(true);
        }
        if (customStartDate == undefined || customStartDate == "") {
            hasError = true;
            setIsStartDateEmpty(true);
        }

        if (
            customStartDate && customStartDate == 'Invalid Date'
        ) {
            setCustomStartDateError("Invalid Start Date");
            hasError = true;
        }

        if (!hasError) {
            dispatch(getSelectedRows(selectedRows));
            let cohObj = {
                podName: podName,
                cohortName: cohortName,
                description: description,
                cohortOwner: cohortOwner,
                customStartDate: customStartDate,
                cohortOwnerId:cohortOwnerId,
            };
            dispatch(getCohortDetails(cohObj));
            props.handleNextCall();
        }
    }

    useEffect(() => {
        if (cohortName != "") {
            setIsCohortNameEmpty(false);
        }
    }, [cohortName])

    const onClose = () => {
        props.handleCancelCall();
    }

    const OutlinedButton = styled(Button)(() => ({
        borderRadius: "4px",
        padding: "6px",
        justifyContent: "center",
        color: COLOR.BLACK,
        borderColor: COLOR.BLACK,
        fontFamily: Styles.FONT_FAMILY_POPPINS,
        fontStyle: "normal",
        textTransform: "capitalize",
    }));

    const ContainedButton = styled(Button)(() => ({
        variant: "contained",
        borderRadius: "4px",
        padding: "6px",
        background: COLOR.PRIMARY_ORANGE,
        justifyContent: "center",
        color: COLOR.WHITE,
        fontFamily: Styles.FONT_FAMILY_POPPINS,
        fontStyle: "normal",
        textTransform: "none",
        boxShadow:
            "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
        ":hover": {
            background: COLOR.SECONDARY_ORANGE,
        },
    }));

    const DefaultTypography = styled(Typography)({
        color: "#000",
        fontFamily: Styles.FONT_FAMILY_POPPINS,
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",
    });

    return (
        <Box sx={{
            paddingTop: 2, width: "100%",
            display: "flex", justifyContent: "center"
        }}>
            <Grid container xs={7} sx={{
                paddingTop: 2,
                minHeight: "100%",
                // marginLeft: "1%",
                boxShadow: "0px 3px 46px 0px rgba(0, 0, 0, 0.12)",
                border: "1px solid rgba(0,0,0,.12)",
                backgroundColor: "white",
            }} >
                <Grid item xs={12} >
                    <Stack direction="column" spacing={1} sx={{ paddingLeft: "25px", paddingRight: "25px" }}
                    >
                        <TextField
                            style={{
                                width: "auto !important",
                                border:
                                    (isPodNameEmpty ?
                                        "1px solid #D32F2F" : "")
                            }}
                            className={(props.isDisabledMode) ? "disabledAccordian" : ""}
                            id="outlined-multiline-static"
                            label="POD Name"
                            value={podName}
                            required
                            // disabled={props.isDisabledMode}
                            error={!!podNameError}
                            helperText={podNameError}
                            onChange={(e) => handlePodNameChange(e)}
                            multiline
                            // className="denyComments"
                            color="orangeTheme"
                            rows={1}
                            variant="outlined"
                        />
                        {isPodNameEmpty ? <div style={{ fontSize: '12px', color: '#D32F2F', paddingLeft: '10px' }}><span style={{ fontWeight: 'bold' }}>POD Name </span>is Required</div> : null}
                        <TextField
                            style={{
                                width: "auto !important",
                                border:
                                    (isCohortNameEmpty ?
                                        "1px solid #D32F2F" : "")
                            }}

                            id="outlined-multiline-static"
                            label="Cohort Name"
                            className={(props.isDisabledMode) ? "disabledAccordian" : ""}
                            value={cohortName}
                            required
                            // disabled={props.isDisabledMode}
                            error={!!cohortNameError}
                            helperText={cohortNameError}
                            onChange={(e) => handleCohortNameChange(e)}
                            multiline
                            // className="denyComments"
                            color="orangeTheme"
                            rows={1}
                            variant="outlined"
                        />
                        {isCohortNameEmpty ? <div style={{ fontSize: '12px', color: '#D32F2F', marginTop: '0px !important', paddingLeft: '10px' }}><span style={{ fontWeight: 'bold' }}>Cohort Name </span>is Required</div> : null}
                        <TextField
                            style={{
                                width: "auto !important",
                            }}
                            id="outlined-multiline-static"
                            label="Description"
                            className="denyComments"
                            value={description}
                            // disabled={props.isDisabledMode}
                            error={!!descriptionError}
                            helperText={descriptionError}
                            onChange={(e) => handleDescriptionChange(e)}
                            multiline
                            color="orangeTheme"
                            rows={1}
                            variant="outlined"
                        />

                        {/* <TextField
                            style={{
                                width: "auto !important",
                                border:
                                    (isCohortOwnerEmpty ?
                                        "1px solid #D32F2F" : "")
                            }}
                            id="outlined-multiline-static"
                            label="Cohort Owner"
                            className= "disabledAccordian"
                            value={cohortOwner}
                            required
                            // disabled={props.isDisabledMode}
                            error={!!cohortOwnerError}
                            helperText={cohortOwnerError}
                            onChange={(e) => handleCohortOwnerChange(e)}
                            multiline
                            // className="denyComments"
                            color="orangeTheme"
                            rows={1}
                            variant="outlined"
                        />
                        {isCohortOwnerEmpty ? <div style={{ fontSize: '12px', color: '#D32F2F', paddingLeft: '10px' }}><span style={{ fontWeight: 'bold' }}>Cohort Owner </span>is Required</div> : null} */}
                        <FormControl error={!!cohortOwnerError} required color="orangeTheme"

                            sx={{
                                width: "auto !important",
                                border:
                                    (isCohortOwnerEmpty ?
                                        "1px solid #D32F2F" : "")
                            }} size="small">
                            <InputLabel id="owner-name-select-small-label">Cohort Owner</InputLabel>

                            <Select
                                required

                                value={cohortOwner}
                                disabled={isLoading}
                                className="ownerNameDropdown"
                                onBlur={handleOwnerNameHighlight}
                                labelId="owner-name-select-small-label"
                                id="owner-name-select-small"
                                onChange={(e) => handleOwnerNameChange(e)}
                                label="Cohort Owner"
                            >
                                {
                                    isLoading ? <MenuItem value=""><CircularProgress size={24} /></MenuItem> : null
                                }
                                {
                                    ownerNameDropdownData.map(item => (
                                        <MenuItem key={item.cohortName} value={item.cohortName}>
                                            {item.cohortName}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                            {
                                cohortOwnerError ? <FormHelperText></FormHelperText> : null
                            }
                        </FormControl>
                        {isCohortOwnerEmpty ? <div style={{ fontSize: '12px', color: '#D32F2F', paddingLeft: '10px' }}><span style={{ fontWeight: 'bold' }}>Cohort Owner </span>is Required</div> : null}

                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <FormControl
                                // className="practiceNameClass"
                                className={(props.isDisabledMode) ? "disabledAccordian" : ""}
                                required
                                // disabled={props.isDisabledMode}
                                color="orangeTheme"
                                sx={{
                                    maxWidth: "180px",
                                    minWidth: "160px",
                                    backgroundColor: "#fff",
                                    borderRadius: "6px",
                                    border:
                                        (isStartDateEmpty ?
                                            "1px solid #D32F2F" : "")
                                }}
                                size="small"
                            >
                                <DatePicker
                                    label="Start Date"
                                    value={customStartDate}
                                    // defaultValue={customStartDate}
                                    color="orangeTheme"
                                    slotProps={{
                                        textField: {
                                            className: "dateFieldClass",
                                            color: "orangeTheme",
                                            error: !!customStartDateError,
                                        },
                                    }}
                                    minDate={addDays(new Date(1753, 0, 0), 1)}
                                    onChange={handleCustomStartDateChange}
                                />
                                <FormHelperText style={{ height: "0px", fontSize: "12px", color: "rgb(211, 47, 47)" }}>
                                    {customStartDateError}
                                </FormHelperText>
                            </FormControl>
                        </LocalizationProvider>
                        {isStartDateEmpty ? <div style={{ fontSize: '12px', color: '#D32F2F', paddingLeft: '10px' }}><span style={{ fontWeight: 'bold' }}>Start Date </span>is Required</div> : null}

                        <Grid container spacing={1} >
                            <Grid item xs={12} sx={{ margintTop: "25px", display: "flex", justifyContent: "flex-end" }} >
                                {/* <Button style={{ borderColor: 'black', color: 'black', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="outlined" onClick={onClose}>Cancel</Button>
                                <Button style={{ color: 'white', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="contained" color="orangeTheme" disableElevation onClick={handleNext}>Next</Button> */}
                                {/* <Button style={{ borderColor: 'black', color: 'black', fontFamily: Styles.FONT_FAMILY_POPPINS }} variant="outlined" onClick={handleBack}>Back</Button> */}

                                <Stack direction="row" spacing={2} sx={{
                                    justifyContent: "end", paddingTop: "7px",
                                    paddingBottom: "7px", paddingRight: "10px"
                                }}>
                                    <OutlinedButton
                                        onClick={() => {
                                            onClose()
                                        }}
                                        variant="text"
                                        size="small"
                                    >
                                        <DefaultTypography>Cancel</DefaultTypography>
                                    </OutlinedButton>

                                    <Button style={{
                                        variant: "outlined",
                                        borderColor: COLOR.ORANGE,
                                        borderRadius: "4px",
                                        padding: "6px",
                                        justifyContent: "center",
                                        border: "1px solid #DC4F34",
                                        fontFamily: Styles.FONT_FAMILY_POPPINS,
                                        fontStyle: "normal",
                                        textTransform: "none",
                                        boxShadow:
                                            "0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)",
                                        ":hover": {
                                        },
                                    }} onClick={handleBack}><DefaultTypography style={{ color: COLOR.ORANGE, }}>Back</DefaultTypography></Button>

                                    {/* <ContainedButton
                                            onClick={handleNext}
                                            variant="outlined"
                                            size="small"
                                            color="orangeTheme"
                                            
                                        >
                                            <DefaultTypography style={{ color: COLOR.WHITE }}>
                                                Next
                                            </DefaultTypography>

                                        </ContainedButton> */}
                                    <Button style={{
                                        color: 'white', fontFamily: Styles.FONT_FAMILY_POPPINS,
                                    }}
                                        variant="contained" color="orangeTheme" disableElevation onClick={handleNext}>Next</Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};


export default EditCohortDetailsPage_Step2;

